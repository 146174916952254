import { get } from 'lodash';
import { lazy } from 'react';

const IncidentPage = lazy(() => import('./containers/IncidentPage'));
const PublicReportPage = lazy(() => import('./containers/PublicReportPage'));
const LegacyReportReadonlyPage = lazy(() => import('./containers/LegacyReportReadonlyPage'));
const ReportPage = lazy(() => import('./containers/ReportPage'));
const ReportHistoryPage = lazy(() => import('./containers/ReportHistoryPage'));
const ReportListPage = lazy(() => import('./containers/ReportListPage'));
const ReportProviderPage = lazy(() => import('./containers/ReportProviderPage'));
const ResolutionPage = lazy(() => import('./containers/ResolutionPage'));

const routes = {
  settings: {
    helpCategories: ['inspect v2'],
  },
  auth: ({ profile } = {}) => Boolean(profile) && get(profile, 'features.inspectV2.rollOut.value'),
  routes: [
    {
      path: '/questionnaire/:questionnaireSeriesId/report/:id',
      component: ReportProviderPage,
      routes: [
        {
          path: '/questionnaire/:questionnaireSeriesId/report/:id/readonly',
          component: LegacyReportReadonlyPage,
        },
        {
          path: '/questionnaire/:questionnaireSeriesId/report/:id/incident/:incidentId?',
          component: IncidentPage,
        },
        {
          path: '/questionnaire/:questionnaireSeriesId/report/:id/history',
          component: ReportHistoryPage,
        },
        {
          path: '/questionnaire/:questionnaireSeriesId/report/:id/resolution/:resolutionId',
          component: ResolutionPage,
        },
        {
          path: '/questionnaire/:questionnaireSeriesId/report/:id',
          component: ReportPage,
        },
      ],
    },
    {
      path: '/questionnaire/:questionnaireSeriesId',
      component: ReportListPage,
    },
  ],
};

export const inspectPublicRoutes = [
  { path: '/public/questionnaire/:questionnaireSeriesId/report/:id', component: PublicReportPage },
];

export default routes;
