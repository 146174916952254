const {
  RssModel
} = require('@rss/common');
const yup = require('yup');
class BioDetails extends RssModel {
  constructor(payload = {}) {
    super(payload);
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      riskGroup: yup.string().default(null).nullable().label('Risk Group'),
      category: yup.string().required().label('Category')
    });
  }
}
module.exports = BioDetails;