import { useMutation } from '@apollo/client';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { Box, List, Typography } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import { FORM_ACTION } from '@risk-and-safety/assessment-v2-common';
import { get, isEqual, toLower, toUpper, uniqWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useProfile } from '../hooks';
import LocationCheckBox from './LocationCheckBox';
import BuildingFloorSearch from './location-search/BuildingFloorSearch';
import BuildingRoomSearch from './location-search/BuildingRoomSearch';
import { REMOVE_MEMBER_BY_LABEL, UPSERT_MEMBER_BY_LABEL } from '../profile/graphql/mutation';
import { GET_ORGANIZATION_DETAILS } from '../profile/graphql/query';
import SubHeaderWithActions from './SubHeaderWithActions';
import { getRolesBasedOnLabel, getSortedUsages, hasWriteAccessToNode } from '../shared/helper';

const {
  EDGE: { LOCATED },
  NODE: { FLOOR, ROOM },
} = RELATIONSHIP_LABEL;

const hasRelationWithLocation = (edges = [], id) => !!edges.find((e) => e.from === id && e.isActive);

const formatLocations = (locations = [], usages = []) => {
  return locations.map((location) => {
    const { building, node } = location;
    return {
      building: building.name,
      [toLower(node.label)]: toUpper(node.label) === ROOM ? node.roomNumber : node.name,
      nodeId: node.id,
      location,
      ...Object.assign(
        {},
        ...usages.map((usage) => ({
          [usage.node.id]: hasRelationWithLocation(node.locationEdges, usage.node.id),
        })),
      ),
    };
  });
};

const ManageLocation = ({
  canEdit,
  currentUsage,
  locations,
  orgNode,
  redirectBackUrl,
  selectedNodeId,
  type,
  usages,
  onSave,
  onRemove,
}) => {
  const sortedUsages = getSortedUsages(usages, currentUsage);
  const formattedLocations = formatLocations(locations, sortedUsages);
  const selectedLocation = get(
    formattedLocations.find(({ location }) => location.node.id === selectedNodeId),
    'location',
  );
  const [location, setLocation] = useState(get(selectedLocation, 'node') || null);
  const [locationEdges, setLocationEdges] = useState(get(selectedLocation, 'node.locationEdges', []));
  const [locationId, setLocationId] = useState(selectedNodeId);
  const isAdd = !selectedLocation;

  const { id: orgId, label: orgLabel } = orgNode;
  const {
    profile: { relationships },
    rolePermissions,
  } = useProfile();

  const suggestions =
    locations &&
    uniqWith(
      locations.map((loc) => ({
        primaryName: get(loc, 'building.name'),
        buildingId: get(loc, 'building.id'),
      })),
      isEqual,
    );

  const showUsages = locationId && sortedUsages.length && !!getRolesBasedOnLabel(orgLabel).length;

  const getLocationEdge = (id, role) => locationEdges.find((edge) => edge.from === id && edge.label === role);

  const getIsActive = (id, role) => (getLocationEdge(id, role) || {}).isActive || false;

  const handleLocationChange = (checked, { id, label }, role) => {
    const edges = getLocationEdge(id, role)
      ? locationEdges.map((locationEdge) =>
          locationEdge.from === id && locationEdge.label === role
            ? { ...locationEdge, isActive: checked }
            : locationEdge,
        )
      : locationEdges.concat({
          from: id,
          to: location.id,
          label: role,
          isActive: checked,
          sourceNodeLabel: label,
          targetNodeLabel: type,
          edgeId: null,
        });
    setLocationEdges(edges);
  };

  const handleSelect = (selLocationId) => {
    if (currentUsage && selLocationId) {
      setLocationEdges([
        {
          from: get(currentUsage, 'node.id'),
          to: selLocationId,
          label: LOCATED,
          isActive: true,
          sourceNodeLabel: get(currentUsage, 'node.label'),
          targetNodeLabel: type,
          edgeId: null,
        },
      ]);
    }
    if (selLocationId) setLocationId(selLocationId);
  };

  const refetchQueries = [{ query: GET_ORGANIZATION_DETAILS, variables: { id: orgId } }];

  const [removeMemberByLabel, { loading: removeLoading }] = useMutation(REMOVE_MEMBER_BY_LABEL, {
    onCompleted: () => onRemove && onRemove(locationId),
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [upsertMemberByLabel, { loading: upsertLoading }] = useMutation(UPSERT_MEMBER_BY_LABEL, {
    onCompleted: () => onSave && onSave(locationId),
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const handleSave = () => {
    const locEdges = getLocationEdge(orgId, LOCATED)
      ? locationEdges
      : [
          ...locationEdges,
          {
            from: orgId,
            to: location.id,
            label: LOCATED,
            isActive: true,
            sourceNodeLabel: orgLabel,
            targetNodeLabel: type,
            edgeId: null,
          },
        ];
    const filteredLocationEdges = locEdges.filter((edge) =>
      hasWriteAccessToNode(rolePermissions, relationships, edge.sourceNodeLabel, edge.from, sortedUsages, orgId),
    );
    upsertMemberByLabel({
      variables: {
        action: isAdd ? FORM_ACTION.ADD_MEMBER : FORM_ACTION.UPDATE_MEMBER,
        edges: filteredLocationEdges,
      },
    });
    setLocation(null);
  };

  const building = selectedLocation?.building?.name;
  const roomNumber = selectedLocation?.node?.roomNumber;

  return (
    <List component="div" className="w-full" disablePadding>
      <SubHeaderWithActions
        redirectBackUrl={redirectBackUrl}
        removeConfig={{
          title: 'location',
          content:
            selectedLocation && `Are you sure you want to remove ${building} - ${roomNumber} from ${orgNode?.name}?`,
        }}
        loading={{ upsertLoading, removeLoading }}
        onSave={locationId ? handleSave : null}
        canEdit={canEdit}
        onRemove={
          !isAdd
            ? () =>
                removeMemberByLabel({
                  variables: { edges: get(location, 'locationEdges') },
                })
            : null
        }
      />
      <div className="px-16 pt-16 sm:px-24">
        <FuseAnimate delay={100}>
          <Typography variant="h6" className="flex font-normal">
            {isAdd ? 'Add Location' : `Manage ${building} - ${roomNumber || selectedLocation.node.name}`}
          </Typography>
        </FuseAnimate>
        <hr className="pb-16" />
        {isAdd && (
          <div className="pb-24">
            {isAdd && type === ROOM && (
              <BuildingRoomSearch
                autoFocus
                filter={
                  isAdd ? locations && locations.filter(({ edge: { endDate } }) => !endDate).map((p) => p.node.id) : []
                }
                showSuggestions={isAdd}
                suggestionsLimit={10}
                suggestions={suggestions}
                onChange={(room) => {
                  if (room.roomId) {
                    setLocation({ id: room.roomId, name: room.roomNumber });
                    handleSelect(room.roomId);
                    !currentUsage && setLocationEdges([]);
                  } else {
                    setLocationId(selectedNodeId);
                  }
                }}
              />
            )}
            {isAdd && type === FLOOR && (
              <BuildingFloorSearch
                autoFocus
                onChange={({ floorId, floorName }) => {
                  setLocation({ id: floorId, name: floorName });
                  handleSelect(floorId);
                  !currentUsage && setLocationEdges([]);
                }}
              />
            )}
          </div>
        )}
        {showUsages
          ? sortedUsages.map(({ node, multipleGroups }) => (
              <Box key={node.id}>
                <LocationCheckBox
                  disabled={
                    !hasWriteAccessToNode(rolePermissions, relationships, node.label, node.id, sortedUsages, orgId)
                  }
                  isUsage
                  usage={{ ...node, multipleGroups: !!multipleGroups }}
                  checked={getIsActive(node.id, LOCATED)}
                  onChange={(checked, role) => handleLocationChange(checked, node, role)}
                />
              </Box>
            ))
          : !isAdd && (
              <Typography color="textSecondary" className="mt-4 text-base">
                There are no documents associated to this {toLower(orgLabel)}
              </Typography>
            )}
      </div>
    </List>
  );
};

ManageLocation.propTypes = {
  canEdit: PropTypes.bool,
  currentUsage: PropTypes.shape({ id: PropTypes.string, label: PropTypes.string }),
  locations: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object)),
  orgNode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  redirectBackUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectedNodeId: PropTypes.string,
  type: PropTypes.string,
  usages: PropTypes.arrayOf(
    PropTypes.shape({ node: PropTypes.objectOf(PropTypes.any), edge: PropTypes.objectOf(PropTypes.any) }),
  ),
};

ManageLocation.defaultProps = {
  canEdit: false,
  currentUsage: null,
  locations: [],
  onSave: null,
  onRemove: null,
  redirectBackUrl: null,
  selectedNodeId: null,
  type: 'ROOM',
  usages: [],
};

export default ManageLocation;
