import { Icon, IconButton, Typography, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import ButtonProgress from './ButtonProgress';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundColor: theme.palette.background.default,
  },
}));

const NavToolbar = ({ backText, disableBack, disableNext, loading, nextText, onBack, onNext, title }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        'sticky top-0 z-99 flex h-64 justify-between rounded-t-20 border-b-1 px-16 py-8 print:hidden',
        classes.toolbar,
      )}>
      <div className="flex items-center">
        {onBack && (
          <div>
            <Tooltip title={backText} arrow>
              <IconButton data-cy="e2e-back" onClick={onBack} disabled={disableBack}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </Tooltip>
          </div>
        )}

        {title && (
          <div className="ml-16">
            <Typography variant="h6" color="textSecondary" className="font-normal">
              {title}
            </Typography>
          </div>
        )}
      </div>

      {onNext && (
        <div className="mr-8 flex items-center justify-start space-x-12">
          <ButtonProgress
            variant="contained"
            color="primary"
            data-cy={`e2e-${nextText}-btn`}
            loading={loading}
            onClick={onNext}
            disabled={disableNext}>
            {nextText}
          </ButtonProgress>
        </div>
      )}
    </div>
  );
};

NavToolbar.propTypes = {
  title: PropTypes.string,
  backText: PropTypes.string,
  disableBack: PropTypes.bool,
  disableNext: PropTypes.bool,
  loading: PropTypes.bool,
  nextText: PropTypes.string,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

NavToolbar.defaultProps = {
  title: '',
  backText: '',
  disableBack: false,
  disableNext: false,
  loading: false,
  nextText: 'Continue',
  onBack: null,
  onNext: null,
};

export default NavToolbar;
