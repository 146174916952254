/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-boolean-value */
import { useLazyQuery } from '@apollo/client';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { LinearProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ORDER_STATUS } from '@risk-and-safety/library';
import { SHORT_DATE_FORMAT, SHORT_DATE_PLACEHOLDER } from '@rss/common';
import { format } from 'date-fns';
import { startCase, upperCase } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { HeaderSearch, ReactTable } from '../components';
import { GET_ORDERS } from '../graphql/query';
import { FilterFooter, Header, StatusChip } from '../../components';
import { ORDER_STATUS_MAP } from '../helper/order.helper';
import { useAdvancedSearch } from '../../hooks';

const getFiltersForFooter = (searchFilter) => {
  if (!searchFilter) {
    return { filters: [], total: 0 };
  }
  const filters = [];
  let total = 0;
  if (searchFilter.status?.length) {
    searchFilter.status?.map((s) => filters.push(upperCase(s)));
    total += 1;
  }
  if (searchFilter.fromDate || searchFilter.toDate) {
    searchFilter.fromDate && filters.push(`From: ${format(new Date(searchFilter.fromDate), 'P')}`);
    searchFilter.toDate && filters.push(`From: ${format(new Date(searchFilter.toDate), 'P')}`);
    total += 1;
  }

  return { filters, total };
};

function OrderListPage({ store }) {
  const history = useHistory();
  const isShop = history.location.pathname.includes('shop');
  const INIT_SEARCH_PARAMS = {
    orderId: '',
    status: history?.location?.state?.orderStatus ? [history?.location?.state?.orderStatus] : [],
    fromDate: '',
    toDate: '',
    storeId: store._id,
    myOrders: !!isShop,
    createdByUserId: '',
  };

  const { advancedSearch, setAdvancedSearch, advancedSearchQueryString } = useAdvancedSearch(INIT_SEARCH_PARAMS);
  const { filters, total } = getFiltersForFooter(advancedSearch);

  const { control, getValues, reset } = useForm({
    mode: 'onChange',
    defaultValues: { ...INIT_SEARCH_PARAMS, ...advancedSearch },
  });

  const [getOrders, { data: orderData, loading }] = useLazyQuery(GET_ORDERS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const search = {
      ...INIT_SEARCH_PARAMS,
      ...advancedSearch,
      myOrders: !!isShop,
    };
    setAdvancedSearch(search);
    getOrders({
      variables: search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { Header: 'Order #', accessor: 'orderId', className: 'w-192' },
    { Header: 'Account', accessor: 'account', className: 'w-320' },
    { Header: 'Quantity', accessor: 'quantity', className: 'w-128' },
    {
      Header: 'Status',
      Cell: ({ row }) => (
        <StatusChip label={upperCase(row.original?.status)} color={ORDER_STATUS_MAP[row.original?.status]} />
      ),
    },
    { Header: 'Receiving Type', accessor: 'receivingType', Cell: ({ value }) => startCase(value) },
  ];
  const data = orderData
    ? orderData.orders?.map((o) => ({
        orderId: o._id,
        account: o.account?.administeredBy?.name,
        quantity: o.items.reduce((acc, curr) => acc + curr.quantity, 0),
        status: o.status,
        receivingType: o.receivingType,
      }))
    : [];

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        contentCard: 'overflow-hidden',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
      }}
      header={
        <Header
          title={isShop ? 'My Orders' : 'Store Orders'}
          backArrow={
            isShop
              ? {
                  link: `../${store._id}`,
                  name: 'Shop',
                }
              : {
                  link: `../${store._id}`,
                  name: 'Store',
                }
          }
          search={
            <HeaderSearch
              name="orderId"
              control={control}
              onReset={() => {
                setAdvancedSearch(INIT_SEARCH_PARAMS);
                reset();
                getOrders({ variables: { ...INIT_SEARCH_PARAMS } });
              }}
              onChange={() => {
                setAdvancedSearch({ ...advancedSearch, ...getValues() });
                getOrders({ variables: { ...advancedSearch, ...getValues() } });
              }}
              onSubmit={() => {
                setAdvancedSearch({ ...advancedSearch, ...getValues() });
                getOrders({ variables: { ...advancedSearch, ...getValues() } });
              }}
              placeholder="Search by order number">
              <Controller
                name="status"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    multiple
                    options={Object.keys(ORDER_STATUS)}
                    getOptionLabel={(option) => upperCase(option)}
                    onChange={(_, v) => onChange(v)}
                    value={value || []}
                    renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Order Status" />}
                  />
                )}
              />

              <div className="mt-16 flex gap-24">
                <Controller
                  name="fromDate"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <KeyboardDatePicker
                      label="From"
                      autoOk
                      disableToolbar
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format={SHORT_DATE_FORMAT}
                      placeholder={SHORT_DATE_PLACEHOLDER}
                      value={value || null}
                      onChange={onChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
                <Controller
                  name="toDate"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <KeyboardDatePicker
                      label="To"
                      autoOk
                      disableToolbar
                      fullWidth
                      inputVariant="outlined"
                      variant="inline"
                      format={SHORT_DATE_FORMAT}
                      placeholder={SHORT_DATE_PLACEHOLDER}
                      value={value || null}
                      onChange={onChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </div>
            </HeaderSearch>
          }
        />
      }
      content={
        <FuseScrollbars className="flex-grow overflow-x-auto">
          {loading ? (
            <LinearProgress className="clear-both" key={0} />
          ) : (
            <div className="flex min-h-full flex-col justify-between">
              <ReactTable
                columns={columns}
                data={data}
                onClickRow={({ orderId }) =>
                  history.push(
                    `/${isShop ? 'shop' : 'store'}/${store._id}/order/${orderId}?${advancedSearchQueryString}`,
                  )
                }
              />
              {advancedSearch && <FilterFooter filters={filters} total={total} classes={{ root: '' }} />}
            </div>
          )}
        </FuseScrollbars>
      }
    />
  );
}

OrderListPage.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderListPage;
