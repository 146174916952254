import React, { lazy } from 'react';

import PathDataProvider from './hooks/PathDataProvider';
import AdvanceSearchDataProvider from './hooks/AdvanceSearchDataProvider';

const AdminPage = lazy(() => import('./containers/AdminPage'));
const DashboardPage = lazy(() => import('./containers/DashboardPage'));
const FormPage = lazy(() => import('./containers/FormPage'));
const ReportsPage = lazy(() => import('./containers/ReportsPage'));

const routes = {
  settings: {
    helpCategories: ['bsas'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/bsas/admin',
      component: AdminPage,
    },
    {
      path: '/bsas/reports',
      component: ReportsPage,
    },
    {
      path: [
        '/bsas/fundingapplication/:id',
        '/bsas/fundingapplication',
        '/bsas/lossprevention/:id',
        '/bsas/fundingprogram/:id',
        '/bsas/proposalstatus/:id',
        '/bsas/fundingapplication',
      ],
      component: () => (
        <PathDataProvider>
          <FormPage />
        </PathDataProvider>
      ),
    },
    {
      path: ['/bsas/dashboard', '/bsas'],
      component: () => (
        <AdvanceSearchDataProvider>
          <DashboardPage />
        </AdvanceSearchDataProvider>
      ),
    },
  ],
};

export default routes;
