import gql from 'graphql-tag';

import { ATTACHMENT_FRAGMENT } from '../../graphql/attachment.fragments';

export const QUESTIONNAIRE_FRAGMENT = gql`
  fragment Questionnaire on InspectQuestionnaire {
    _id
    seriesId
    name
    program {
      id
      name
    }
    subject {
      type
      filters
      questions
    }
    incidentQuestions {
      key
      templateId
      type
      options
      limit
      inputType
      label
      required
    }
    categories
    active
    createdBy {
      userId
      firstName
      lastName
    }
    settings {
      adminReview
      assignReviewers
      hasTicketingSystem
      isSelfInspection
      isShareEnabled
      complianceOptions
      defaultPrivateNotes
      defaultPrivateComments
      updateResponsiblePeople
      updateNotesAndComments
      print {
        header
        includeThumbnails
        includePeopleNames
        includePrivateNotesAndComments
        showNoncompliantOnly
        includeResolutions
      }
      incidentDetails {
        templateId
        includeActionPlan
        includeDaysToResolve
        includeCorrectedOnSite
        includeRequiresVerification
        locationFilters {
          status
          type
        }
        routingGroups {
          id
          status
        }
      }
    }
    reminders {
      report {
        revise {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        review {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        resolve {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        done {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
      }
      resolution {
        notResolved {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        overdue {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        verification {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        denied {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
      }
    }
    createdDate
  }
`;

export const SUBJECT_FRAGMENT = gql`
  fragment Subject on SubjectDetails {
    details
    notes {
      _id
      isPrivate
      attachments {
        ...Attachment
      }
      comment
      createdBy {
        userId
        firstName
        lastName
      }
      createdDate
      lastModifiedBy {
        userId
        firstName
        lastName
      }
      lastModifiedDate
    }
    responses
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const RESPONSE_COMMENT_FRAGMENT = gql`
  fragment ResponseComment on InspectResponseComment {
    _id
    comment
    attachments {
      ...Attachment
    }
    createdBy {
      userId
      firstName
      lastName
    }
    createdDate
    lastModifiedBy {
      userId
      firstName
      lastName
    }
    lastModifiedDate
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const REPORT_FRAGMENT = gql`
  fragment Report on InspectReport {
    _id
    inspectionDate
    createdBy {
      campusCode
    }
    status
    assignedReviewers {
      email
      firstName
      lastName
      userId
    }
    categories {
      _id
      key
      responses {
        _id
        key
        values
        createdBy {
          userId
        }
        lastModifiedBy {
          userId
        }
        incidents {
          _id
          actionPlan
          attachments {
            ...Attachment
          }
          daysToResolve
          correctedOnSite
          comments {
            _id
            isPrivate
            text
            createdBy {
              userId
              firstName
              lastName
            }
            lastModifiedBy {
              userId
              firstName
              lastName
            }
            lastModifiedDate
            createdDate
          }
          questions {
            key
          }
          responses {
            key
            values
          }
          requiresVerification
          createdDate
          createdBy {
            userId
            firstName
            lastName
          }
          lastModifiedBy {
            userId
            firstName
            lastName
          }
          locations {
            buildingId
            roomId
          }
          routingGroups
        }
        comment {
          ...ResponseComment
        }
      }
    }
    subject {
      ...Subject
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${RESPONSE_COMMENT_FRAGMENT}
  ${SUBJECT_FRAGMENT}
`;

export const RESOLUTION_FRAGMENT = gql`
  fragment Resolution on InspectResolution {
    _id
    incident
    permissions
    reportId
    trackingNumber
    question {
      key
      label
    }
    status
    log {
      attachments {
        ...Attachment
      }
      comment
      createdBy {
        userId
        firstName
        lastName
      }
      createdDate
      status
    }
    reportedBy {
      userId
      firstName
      lastName
    }
    createdDate
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const QUESTIONNAIRE_TEMPLATE_FRAGMENT = gql`
  fragment QuestionnaireTemplate on InspectQuestionnaireTemplate {
    _id
    name
    active
    subject {
      type
      filters
      questions
    }
    incidentQuestions {
      key
      templateId
      type
      options
      defaultValue
      inputType
      limit
      label
      keywords
      required
    }
    categories {
      type
      label
      key
      required
      questions {
        key
        templateId
        type
        limit
        options
        defaultValue
        inputType
        label
        keywords
        required
        incidentDetails {
          daysToResolve
          actionPlan
          requiresVerification
          questions
        }
      }
    }
    settings {
      incidentDetails {
        includeActionPlan
        includeDaysToResolve
        includeCorrectedOnSite
        includeRequiresVerification
        locationFilters {
          status
        }
        routingGroups {
          status
        }
      }
    }
    lastModifiedBy {
      userId
      firstName
      lastName
    }
    lastModifiedDate
    version
  }
`;

export const QT_CONTENT_FRAGMENT = gql`
  fragment QTContents on InspectQTContents {
    _id
    name
    domainId
    parent {
      type
    }
    incidentQuestions {
      key
      templateId
      type
      options
      defaultValue
      inputType
      limit
      label
      keywords
      required
    }
    categories {
      type
      label
      key
      required
      questions {
        key
        templateId
        type
        limit
        options
        defaultValue
        inputType
        label
        keywords
        required
        incidentDetails {
          daysToResolve
          actionPlan
          requiresVerification
          routingGroups
          questions
        }
      }
    }
    subject {
      questions
    }
    settings {
      complianceOptions
      defaultPrivateComments
      defaultPrivateNotes
      adminReview
      assignReviewers
      hasTicketingSystem
      isSelfInspection
      incidentDetails {
        includeActionPlan
        includeDaysToResolve
        includeCorrectedOnSite
        includeRequiresVerification
        templateId
        locationFilters {
          status
        }
        routingGroups {
          id
          status
        }
      }
    }
    reminders {
      report {
        revise {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        review {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        resolve {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        done {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
      }
      resolution {
        notResolved {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        overdue {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        verification {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
        denied {
          isEnabled
          frequency {
            occurrences
            interval
          }
        }
      }
    }
    lastModifiedBy {
      userId
      firstName
      lastName
    }
    lastModifiedDate
  }
`;
