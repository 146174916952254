import {
  Button,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { DateHelper } from '@rss/common';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import AttachmentHelper from './AttachmentHelper';
import AttachFile from '../AttachFile';
import DialogTemplate from '../DialogTemplate';
import { QUESTION_VARIANT } from './Helper';

const ADDED_MSG = 'Attachment successfully uploaded.';
const REMOVED_MSG = 'Attachment successfully removed.';
const CONFIRM_MSG = 'Are you sure you want to remove this file?';
const UPDATE_MSG = 'Attachment has been updated';

const FqAttachments = ({ disabled, onChange, value, questionKey, label, optional, variant, attachmentUrl }) => {
  const [open, setOpen] = useState(false);
  const [removedId, setRemovedId] = useState(null);
  const [attachmentDetails, setAttachmentDetails] = useState([]);

  const handleDrop = (files) => onChange([...value, ...files], questionKey, ADDED_MSG);

  const handleRemove = (confirmed) => {
    if (confirmed) {
      const remainingAttachments = value.filter((attachment) => attachment._id !== removedId);
      onChange(remainingAttachments, questionKey, REMOVED_MSG);
    }
    setOpen(false);
  };

  const handleDelete = (id) => {
    setOpen(true);
    setRemovedId(id);
  };

  const toggleEdit = (attachment) => {
    setAttachmentDetails({
      [attachment._id]: attachment._id,
      [`${attachment._id}_title`]: attachment.title,
      [`${attachment._id}_description`]: attachment.description,
    });
  };

  const handleUpdate = (attachment) => {
    const updatedFiles = value.map((file) => {
      const result = { ...file };
      if (result._id === attachment._id) {
        result.title = attachmentDetails[`${attachment._id}_title`];
        result.description = attachmentDetails[`${attachment._id}_description`];
      }
      return result;
    });
    onChange(updatedFiles, questionKey, UPDATE_MSG);
    toggleEdit(attachment);
    setAttachmentDetails([]);
  };

  return (
    <>
      <div
        className={clsx(
          'z-0 mt-8 px-8 pb-24 pb-16',
          `${variant === QUESTION_VARIANT.COMPACT ? 'px-8' : 'px-16 sm:px-24'}`,
          value.length ? 'pb-16' : 'pb-24 print:hidden',
        )}>
        <div className={clsx('col-span-4 rounded border border-gray-100 p-16', value.length && 'pb-0')}>
          <AttachFile label={label} onChange={handleDrop} optional={optional} disabled={disabled} />
          {value.length ? (
            <List>
              {value.map((attachment) => {
                return (
                  <Fragment key={attachment._id}>
                    <ListItem alignItems="flex-start">
                      <ListItemIcon className="min-w-40">
                        <Icon color="primary">attach_file</Icon>
                      </ListItemIcon>
                      <ListItemText
                        className="max-w-96 truncate sm:max-w-prose"
                        primary={
                          <Tooltip title={attachment.filename} arrow>
                            <div>{attachment.filename}</div>
                          </Tooltip>
                        }
                        secondary={
                          <Typography component="span" className="grid grid-flow-row space-y-6 text-grey-400">
                            <div>{`Date Attached: ${DateHelper.formatDate(attachment.uploadDate)}`}</div>
                            {attachment.title && !attachmentDetails[attachment._id] && (
                              <div>{`Title: ${attachment.title}`}</div>
                            )}
                            {attachment.description && !attachmentDetails[attachment._id] && (
                              <Tooltip title={attachment.description} arrow placement="top">
                                <div className="max-w-256 truncate">{`Description: ${attachment.description}`}</div>
                              </Tooltip>
                            )}
                          </Typography>
                        }
                      />
                      {!attachmentDetails[attachment._id] && (
                        <ListItemSecondaryAction className="top-24 sm:top-32 print:hidden">
                          {!disabled && (
                            <Tooltip id="tooltip-icon-edit" title="Edit file" arrow>
                              <IconButton aria-label="Edit" onClick={() => toggleEdit(attachment)}>
                                <Icon>edit</Icon>
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip id="tooltip-icon-download" title="Download file" arrow>
                            <IconButton
                              aria-label="Download"
                              onClick={() =>
                                AttachmentHelper.handleDownload(
                                  attachment.filename,
                                  attachment.token,
                                  attachment._id,
                                  attachmentUrl,
                                )
                              }>
                              <Icon>cloud_download</Icon>
                            </IconButton>
                          </Tooltip>
                          {!disabled && (
                            <Tooltip id="tooltip-icon-delete" title="Delete file" arrow>
                              <IconButton
                                aria-label="Delete"
                                className="text-danger-800"
                                onClick={() => handleDelete(attachment._id)}>
                                <Icon>delete</Icon>
                              </IconButton>
                            </Tooltip>
                          )}
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                    {attachmentDetails[attachment._id] && (
                      <div className="z-0 grid gap-12 px-16 sm:grid-cols-2 sm:px-24">
                        <div className="col-span-6">
                          <TextField
                            id={`file-${attachment._id}-title`}
                            label="Title"
                            value={attachmentDetails[`${attachment._id}_title`]}
                            onChange={(event) =>
                              setAttachmentDetails({
                                ...attachmentDetails,
                                ...{ [`${attachment._id}_title`]: event.target.value },
                              })
                            }
                            variant="outlined"
                            fullWidth
                          />
                        </div>

                        <div className="col-span-6">
                          <TextField
                            id={`file-${attachment._id}-description`}
                            label="Description"
                            value={attachmentDetails[`${attachment._id}_description`]}
                            onChange={(event) =>
                              setAttachmentDetails({
                                ...attachmentDetails,
                                ...{ [`${attachment._id}_description`]: event.target.value },
                              })
                            }
                            multiline
                            variant="outlined"
                            fullWidth
                          />
                        </div>

                        <div className="col-span-6 text-right">
                          <Button
                            variant="contained"
                            color="default"
                            className="mr-16"
                            onClick={() => setAttachmentDetails([])}>
                            Cancel
                          </Button>
                          <Button variant="contained" color="primary" onClick={() => handleUpdate(attachment)}>
                            Save
                          </Button>
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </List>
          ) : null}
        </div>
      </div>
      <DialogTemplate
        open={open}
        color="secondary"
        content={<p className="pt-10 pb-2 text-base">{CONFIRM_MSG}</p>}
        primaryActions={
          <>
            <Button variant="contained" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button color="secondary" variant="contained" onClick={(res) => handleRemove(res)}>
              Remove
            </Button>
          </>
        }
      />
    </>
  );
};

FqAttachments.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  optional: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  attachmentUrl: PropTypes.string,
};

FqAttachments.defaultProps = {
  disabled: false,
  label: '',
  optional: false,
  value: [],
  variant: QUESTION_VARIANT.STANDARD,
  attachmentUrl: undefined,
};

export default FqAttachments;
