import { List, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import WorkspaceEmpty from './assets/workspace_empty.svg';

const EmptyStateMessage = ({ action, classes, image, message }) => (
  <List classes={{ root: classes.root }}>
    <ListItem className="justify-center pt-32">
      <img src={image} alt="Empty" />
    </ListItem>
    <ListItem key="empty">
      <ListItemText className="text-gray whitespace-pre-line text-center" primary={message} />
    </ListItem>
    {action && <ListItem className="justify-center">{action}</ListItem>}
  </List>
);

EmptyStateMessage.propTypes = {
  action: PropTypes.element,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  image: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

EmptyStateMessage.defaultProps = {
  action: null,
  classes: { root: '' },
  image: WorkspaceEmpty,
  message: '',
};

export default EmptyStateMessage;
