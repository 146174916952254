import { Icon, ListItemIcon, ListItemText, Menu, MenuItem, ThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from '../store/fuse/settingsSlice';
import IconWithTooltip from './IconWithToolTip';

const MenuActions = ({ disabled, menuItems, menuHandler }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const toolbarTheme = useSelector(selectToolbarTheme);

  return (
    <ThemeProvider theme={toolbarTheme}>
      <IconWithTooltip
        icon="more_vert"
        title="Actions"
        placement="bottom"
        disabled={disabled}
        onClick={(ev) => {
          ev.stopPropagation();
          setAnchorEl(ev.currentTarget);
        }}
      />
      {!disabled && (
        <Menu id="menu" open={Boolean(anchorEl)} anchorEl={anchorEl} keepMounted onClose={() => setAnchorEl(null)}>
          {menuItems.map((menuAction) => (
            <MenuItem
              data-cy={`e2e-${menuAction.label.toLowerCase()}-btn`}
              key={menuAction.label}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(null);
                menuHandler(menuAction);
              }}>
              {menuAction.icon && (
                <ListItemIcon className="min-w-40">
                  <Icon>{menuAction.icon}</Icon>
                </ListItemIcon>
              )}
              <ListItemText primary={menuAction.label} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </ThemeProvider>
  );
};

MenuActions.propTypes = {
  disabled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        icon: PropTypes.string,
      }),
      PropTypes.element,
    ]),
  ).isRequired,
  menuHandler: PropTypes.func.isRequired,
};

MenuActions.defaultProps = {
  disabled: false,
};

export default MenuActions;
