const clone = require('clone');
const tv4 = require('tv4');
class Storage {
  constructor(opt) {
    this.protection = null;
    this.requirements = null;
    this.remarks = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    Object.keys(this).forEach(prop => {
      this[prop] = opt[prop] || null;
    });
  }
  validate() {
    return tv4.validateMultiple(this, Storage.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'storage.schema.json',
      type: 'object',
      properties: {
        protection: {
          type: ['string', 'null']
        },
        requirements: {
          type: ['string', 'null']
        },
        remarks: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = Storage;