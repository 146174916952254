/* eslint-disable camelcase */
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { get, uniq } from 'lodash';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import config from '../config';
import { useProfile, useSecurity, useTenants } from '../hooks';
import { showMessage } from '../store/fuse/messageSlice';
import { setUserData } from './store/userSlice';

const useIdToken = () => {
  const [idToken, setIdToken] = useState(null);
  const [idp, setIdp] = useState(localStorage.getItem(config.SELECTED_IDP_KEY));

  useEffect(() => {
    if (idToken) {
      const provider = idToken?.identities?.[0].providerName;

      if (provider) {
        localStorage.setItem(config.SELECTED_IDP_KEY, provider);
        setIdp(provider);
      }
    }
  }, [idToken]);

  return { idp, idToken, setIdToken };
};

function Auth({ children }) {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const { idToken } = useIdToken();

  const dispatch = useDispatch();
  const history = useHistory();
  const ldClient = useLDClient();
  const { browserSecurity } = useSecurity();

  const showLoginErrors = useCallback(
    ({ error, error_description }) => {
      if (error_description) {
        let message = error_description;
        if (error === 'access_denied' && error_description.includes('Error in SAML Identity Provider')) {
          message = 'Authentication error, please contact service desk';
        } else if (error_description.includes('with error ')) {
          [, message] = error_description.split('with error ');
        }

        dispatch(
          showMessage({
            message,
            variant: 'error',
          }),
        );
        localStorage.removeItem(config.SELECTED_IDP_KEY);
        history.push({ pathname: '/login' });
      }
    },
    [dispatch, history],
  );

  useEffect(() => {
    if (!idToken) {
      if (!browserSecurity.isAuthenticated) {
        browserSecurity.login();
      }
    }
  }, [idToken, browserSecurity, setWaitAuthCheck]);

  const { profile, rolePermissions } = useProfile({ skip: false });
  const TENANTS = useTenants();

  useEffect(() => {
    if (profile) {
      const campus = TENANTS.getCampus(profile.campusCode);

      dispatch(setUserData({ data: { campus, profile, rolePermissions } }));
      setWaitAuthCheck(false);

      const visitor = { id: profile.userId };
      if (!get(profile, 'features.pendo.disablePersonalInfo.value')) {
        visitor.firstName = profile.firstName;
        visitor.lastName = profile.lastName;
        visitor.email = profile.email;
        visitor.roles = uniq(profile.relationships.map((r) => `${r.edge.label}:${r.node.label}`)) || [];
      }

      if (campus) {
        // eslint-disable-next-line no-undef
        aptrinsic('identify', visitor, {
          id: profile.campusCode,
          name: campus.name,
        });

        // Initialize LaunchDarkly
        if (ldClient) {
          ldClient.identify({
            kind: 'multi',
            campus: {
              key: profile.campusCode,
              campus: campus.name,
            },
            user: {
              key: profile.userId,
              firstName: profile.firstName,
              lastName: profile.lastName,
              email: profile.email,
            },
          });
        }
      }
    }
  }, [dispatch, profile, rolePermissions]);

  return waitAuthCheck ? <FuseSplashScreen /> : children;
}

export default Auth;

Auth.propTypes = {
  children: PropTypes.node.isRequired,
};
