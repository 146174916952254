import { has } from 'lodash';

export function formatFundValueToString(value) {
  if (typeof value === 'string') {
    return value;
  }
  if (has(value, 'kfsChartCode')) {
    return `${value.kfsChartCode} ${value.kfsAcctNumber}`;
  }
  if (has(value, 'budgetCode')) {
    return `${value.budgetCode} ${value.accountCode}-${value.fundCode}`;
  }
  if (has(value, 'speedType')) {
    return `${`${value.businessUnit} ` || ''}${value.speedType || ''}`;
  }
  if (has(value, 'businessUnit')) {
    return `${value.businessUnit}${value.account || '*****'}${value.fund}${value.departmentId}${value.project}${
      value.activityPeriod || '**'
    }${value.function || '**'}${value.flexField || '******'}`;
  }
  return '';
}
