import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

import { SEARCH_ORGANIZATIONS } from '../graphql/relationship.query';
import { useProfile } from '../hooks';
import Search from './Search';

const {
  NODE: { DEPARTMENT, FACILITY, GROUP, PARTY, UNIT },
} = RELATIONSHIP_LABEL;

const OrganizationSearch = ({
  autoFocus,
  facilityType,
  filter,
  label,
  organizationType,
  organizationParentId,
  onClear,
  onSelect,
  searchTerm,
  required,
}) => {
  const { profile } = useProfile();

  const [searchOrg, { data = {}, loading }] = useLazyQuery(SEARCH_ORGANIZATIONS);

  let items = data.searchOrganizations || [];
  items = filter.length ? items.filter((i) => filter.every((fdata) => fdata !== i.id)) : items;

  return (
    <Search
      id={`${organizationType}-search`}
      isLoading={loading}
      autoFocus={autoFocus}
      autocomplete
      label={label}
      onClear={onClear}
      value={searchTerm || ''}
      itemToString={(org) => org.name}
      required={required}
      onChange={({ inputValue }) => {
        const variables = {
          organizationType,
          campusCode: profile.campusCode,
          searchTerm: inputValue,
          displayCount: 20, // 20 is same default for building/user search
        };
        if (organizationParentId) {
          variables.to = organizationParentId;
        }
        if (facilityType) {
          variables.facilityType = facilityType;
        }
        searchOrg({ variables });
      }}
      onSelect={onSelect}
      items={items.map((o) => ({ ...o, key: o.id }))}
      renderItem={(org) => (
        <Typography variant="body2" align="left">
          <span>{org.name} </span>
          {organizationType === FACILITY && org.address && (
            <Typography variant="caption" color="textSecondary">
              ({org.address})
            </Typography>
          )}
          {org.description && (
            <Typography variant="caption" color="textSecondary">
              {org.description}
            </Typography>
          )}
          {org.owner && !org.description && (
            <Typography variant="caption" color="textSecondary">
              ({org.owner.firstName} {org.owner.lastName})
            </Typography>
          )}
        </Typography>
      )}
    />
  );
};

OrganizationSearch.propTypes = {
  autoFocus: PropTypes.bool,
  facilityType: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  organizationType: PropTypes.oneOf([DEPARTMENT, FACILITY, GROUP, PARTY, UNIT]).isRequired,
  organizationParentId: PropTypes.string,
  onClear: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  required: PropTypes.bool,
};

OrganizationSearch.defaultProps = {
  autoFocus: true,
  facilityType: null,
  filter: [],
  label: 'Search by Name',
  organizationParentId: null,
  onClear: () => {},
  searchTerm: '',
  required: false,
};

export default OrganizationSearch;
