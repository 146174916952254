import { Icon, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { COMPLIANCE_STATUS } from '@risk-and-safety/inspect-v2-common';
import React from 'react';

const buttons = [
  { icon: 'done', value: COMPLIANCE_STATUS.COMPLIANT, classes: 'border-success-500 text-success-500', label: 'Done' },
  {
    icon: 'clear',
    value: COMPLIANCE_STATUS.NOT_COMPLIANT,
    classes: 'border-danger-500 text-danger-500',
    label: 'Not compliant',
  },
  { text: 'N/A', value: COMPLIANCE_STATUS.NOT_APPLICABLE, classes: 'border-warning-500 text-warning-500' },
  { text: 'N/O', value: COMPLIANCE_STATUS.NOT_OBSERVED, classes: 'border-black text-black' },
];

function ComplianceButton({ disabled, selected, type, onClick }) {
  const button = buttons.find(({ value }) => value === type);

  return (
    <IconButton
      aria-label={button.label}
      disabled={(!selected && disabled) || !onClick}
      className={`h-40 w-40 p-0 ${selected ? clsx('border-2 border-solid', button?.classes) : null} ${
        button?.text ? 'text-lg' : null
      } ${disabled ? 'pointer-events-none cursor-pointer' : null}`}
      onClick={onClick}
    >
      {button.icon ? <Icon>{button.icon}</Icon> : button.text}
    </IconButton>
  );
}

ComplianceButton.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(COMPLIANCE_STATUS)).isRequired,
  onClick: PropTypes.func,
};

ComplianceButton.defaultProps = {
  disabled: false,
  selected: false,
  onClick: null,
};

function ComplianceButtons({ disabled, options, value, onSelect }) {
  return (
    <div className="flex items-center">
      {buttons
        .filter((button) => options.includes(button.value))
        .map((button) => (
          <div className="mx-20 mb-16" key={button.value}>
            <ComplianceButton
              type={button.value}
              selected={value === button.value}
              disabled={disabled || !onSelect}
              onClick={() => {
                onSelect(button.value);
              }}
            />
          </div>
        ))}
    </div>
  );
}

ComplianceButtons.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COMPLIANCE_STATUS))),
  value: PropTypes.oneOf(Object.values(COMPLIANCE_STATUS)),
  onSelect: PropTypes.func,
};

ComplianceButtons.defaultProps = {
  disabled: false,
  options: buttons.map(({ value }) => value),
  value: null,
  onSelect: () => null,
};

export { ComplianceButton };
export default ComplianceButtons;
