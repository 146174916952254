import { useQuery } from '@apollo/client';
import FusePageSimple from '@fuse/core/FusePageSimple';
import Divider from '@material-ui/core/Divider';
import React, { useContext, useState } from 'react';
import debounce from 'lodash/debounce';
import { useLocation, useRouteMatch } from 'react-router-dom';

import AppContext from '../../AppContext';
import ArticleList from '../components/ArticleList';
import CategoryDropdown from '../components/CategoryDropdown';
import ContactUs from '../components/ContactUs';
import SearchBar from '../components/SearchBar';
import Title from '../components/Title';
import { HELP_CATEGORIES } from '../graphql/query';
import { useProfile, useTenants } from '../../hooks';
import { getCategories, getCategoryIds, getSelectedCategory } from '../utils';

const DEBOUNCE_TIME = 400;

const Help = () => {
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const { pathname, search } = useLocation();
  const { params } = useRouteMatch();
  const { campusCode, idps = [] } = useProfile().profile;
  const TENANTS = useTenants();
  const isExternalUser = idps.includes('cognito');
  const isHC = TENANTS.getCampus(campusCode)?.isHC;
  const isCdphUser = idps.includes('cdph.ca.gov');

  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState(getCategories(routes, pathname, params, search));

  const { data } = useQuery(HELP_CATEGORIES, {
    variables: { categories },
  });

  const { helpCategories, helpCategoriesByName } = data || {};

  const allCategories = helpCategories?.result || [];

  return (
    <FusePageSimple
      classes={{
        header: 'min-h-160 h-160 sm:h-256 sm:min-h-256',
      }}
      header={
        <div className="flex flex-1 flex-col items-center justify-evenly">
          <Title />
          <div className="flex w-full max-w-md flex-col space-y-4 px-16 sm:flex-row sm:space-y-0 md:max-w-lg">
            <CategoryDropdown
              selectedCategory={getSelectedCategory(allCategories, categories)}
              allCategories={allCategories}
              onCategoryChange={(category) => setCategories(category)}
            />
            <Divider orientation="vertical" flexItem />
            <SearchBar
              classes={{ paper: 'h-40 sm:h-48 sm:rounded-l-none' }}
              onSearchTermChange={debounce((searchText) => setSearchTerm(searchText), DEBOUNCE_TIME)}
            />
          </div>
        </div>
      }
      content={
        <>
          <ArticleList categoryIds={getCategoryIds(helpCategoriesByName, categories)} searchTerm={searchTerm} />
          <ContactUs isExternalUser={isHC ? !isCdphUser : isExternalUser} />
        </>
      }
    />
  );
};

export default Help;
