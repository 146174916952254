import { RelationshipHelper, RELATIONSHIP_LABEL } from '@rss/common';
import { get, toLower } from 'lodash';
import pluralize from 'pluralize';
import React, { lazy } from 'react';

import { hasOrgLevelAdminRole, hasRoleAdmin } from '../shared/helper';
import { campusOrgTypes, domainOrgTypes, orgTypes } from '../shared/profile-config';
import RoutingDetailsPage from './containers/RoutingDetailsPage';

const AdminToolsPage = lazy(() => import('./containers/AdminToolsPage'));
const ManageRolesPage = lazy(() => import('./containers/ManageRolesPage'));

const MergeRoomPage = lazy(() => import('./containers/MergeRoomPage'));
const OrganizationListPage = lazy(() => import('./containers/OrganizationListPage'));

const {
  EDGE: { ACCOUNT_MANAGER, FIRE_CODE_ADMIN, LOCATION_MANAGER, USER_ADMIN },
  NODE: { CAMPUS },
} = RELATIONSHIP_LABEL;

const routes = {
  settings: {
    helpCategories: ['account-management'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      // FIXME remove /configuration route once all navbars are updated to navigate to use /admin-tools
      path: ['/admin-tools', '/configuration'],
      component: () => <AdminToolsPage />,
    },
    {
      path: ['/account-management/role/:role'],
      auth: ({ profile: { campusCode, relationships, features: featureSettings }, rolePermissions }) =>
        get(featureSettings, 'profile.showManageRoles.value', false) &&
        hasOrgLevelAdminRole({
          orgNode: { id: campusCode, label: CAMPUS },
          relationships,
          featureSettings,
          rolePermissions,
        }),
      component: () => <ManageRolesPage usage="campus" showRightNav={false} />,
    },
    {
      path: ['/account-management/roles/:role', '/account-management/roles', '/campus/roles/:role', '/campus/roles'],
      auth: ({ profile: { campusCode, relationships, features: featureSettings }, rolePermissions }) =>
        get(featureSettings, 'profile.showManageRoles.value', false) &&
        (hasOrgLevelAdminRole({
          orgNode: { id: campusCode, label: CAMPUS },
          relationships,
          featureSettings,
          rolePermissions,
        }) ||
          hasRoleAdmin({ campusCode, relationships, features: featureSettings })),
      component: () => <ManageRolesPage usage="campus" />,
    },
    {
      path: ['/domain/:domainId/roles/:role', '/domain/:domainId/roles'],
      auth: ({ profile: { features: featureSettings } }) =>
        get(featureSettings, 'platform.enableRoleRestructuring.value', false),
      component: () => <ManageRolesPage usage="domain" />,
    },
    {
      path: ['/account-management/users', '/campus/users'],
      auth: ({ profile: { campusCode, relationships } }) =>
        RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, [ACCOUNT_MANAGER, USER_ADMIN]),
      component: lazy(() => import('./containers/UserAccountsPage')),
    },
    {
      path: [
        '/account-management/locations/building/:id/:menu/manage/:locationId?',
        '/account-management/locations/building/:id',
        '/campus/locations/building/:id/:menu/manage/:locationId?',
        '/campus/locations/building/:id',
      ],
      auth: ({ profile: { campusCode, relationships } }) =>
        RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, [ACCOUNT_MANAGER, LOCATION_MANAGER]) ||
        RelationshipHelper.hasAnyOfRoles(relationships, [FIRE_CODE_ADMIN]),
      component: lazy(() => import('./containers/LocationDetailsPage')),
    },
    {
      path: ['/account-management/locations/room/merge'],
      auth: ({ profile: { campusCode, relationships } }) =>
        RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, [LOCATION_MANAGER]),
      component: () => <MergeRoomPage backUrl="/account-management/locations" />,
    },
    {
      path: ['/campus/locations/room/merge'],
      auth: ({ profile: { campusCode, relationships } }) =>
        RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, [LOCATION_MANAGER]),
      component: () => <MergeRoomPage backUrl="/campus/locations" />,
    },
    {
      path: [
        '/account-management/locations/building',
        '/account-management/locations',
        '/campus/locations/building',
        '/campus/locations',
      ],
      auth: ({ profile: { campusCode, relationships } }) =>
        RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, [ACCOUNT_MANAGER, LOCATION_MANAGER]) ||
        RelationshipHelper.hasAnyOfRoles(relationships, [FIRE_CODE_ADMIN]),
      component: lazy(() => import('./containers/LocationsPage')),
    },
    {
      exact: true,
      path: '/domain/:domainId/routing_lists/:id/:type/manage/:routingGroupId/',
      component: () => <RoutingDetailsPage />,
    },
    {
      exact: true,
      path: '/domain/:domainId/routing_lists/:id/',
      component: () => <RoutingDetailsPage />,
    },
    {
      path: [
        '/account-management/:type/:id/:menu/manage/:nodeId?/room/:room?',
        '/account-management/:type/:id/:menu/manage/:userId?',
        '/account-management/:type/:id',
        '/campus/:type/:id/:menu/manage/:nodeId?/room/:room?',
        '/campus/:type/:id/:menu/manage/:userId?',
        '/campus/:type/:id',
        '/domain/:domainId/:type/:id/:menu/manage/:userId?',
        '/domain/:domainId/:type/:id',
      ],
      component: lazy(() => import('../profile/containers/OrganizationDetailPage')),
    },
    {
      path: ['/account-management/programs', '/domain/:domainId/programs'],
      component: lazy(() => import('./containers/ProgramListPage')),
    },
    {
      path: ['/account-management/routing_lists', '/domain/:domainId/routing_lists'],
      component: lazy(() => import('./containers/RoutingListPage')),
    },
    ...orgTypes.map((org) => ({
      path: `/account-management/${toLower(pluralize(org))}`,
      component: () => <OrganizationListPage type={org} />,
    })),

    ...campusOrgTypes.map((org) => ({
      path: `/campus/${toLower(pluralize(org))}`,
      component: () => <OrganizationListPage type={org} />,
    })),

    ...domainOrgTypes.map((org) => ({
      path: `/domain/:domainId/${toLower(pluralize(org))}`,
      component: () => <OrganizationListPage type={org} />,
    })),
  ],
};

export default routes;
