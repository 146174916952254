module.exports = [{
  label: 'sec',
  value: 'sec'
}, {
  label: 'min',
  value: 'min'
}, {
  label: 'hr',
  value: 'hr'
}, {
  label: 'day',
  value: 'day'
}];