import { useLazyQuery } from '@apollo/client';
import { Button } from '@material-ui/core';
import FuseLoading from '@fuse/core/FuseLoading';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Article from './Article';
import EmptyStateMessage from '../../components/EmptyStateMessage';
import ErrorMessage from '../../components/errors/ErrorMessage';
import { HELP_BY_CATEGORY } from '../graphql/query';

const MAX_RESULTS = 20;

const ArticleList = ({ categoryIds, searchTerm }) => {
  const [count, setCount] = useState(MAX_RESULTS);
  const [articles, setArticles] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [loadArticles, { data, loading, error }] = useLazyQuery(HELP_BY_CATEGORY, {
    variables: {
      helpInput: {
        categories: categoryIds,
        searchTerm,
        maxResults: count,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCount(MAX_RESULTS);
    setArticles([]);
    setHasMore(true);
    loadArticles();
  }, [categoryIds, searchTerm]);

  useEffect(() => {
    if (data) {
      const newArticles = data.helpByCategory.result;
      setArticles((prevArticles) => [...prevArticles, ...newArticles]);
      setHasMore(newArticles.length < count ? false : true);
    }
  }, [data]);

  const handleShowMore = () => {
    const newCount = count + MAX_RESULTS;
    setCount(newCount);
    loadArticles({
      variables: {
        helpInput: {
          categories: categoryIds,
          searchTerm,
          maxResults: newCount,
        },
      },
    });
  };

  if (error) return <ErrorMessage />;
  if (loading && articles.length === 0) return <FuseLoading />;

  if (!articles.length && !loading)
    return <EmptyStateMessage classes={{ root: 'mt-32 md:mt-4' }} message="No Articles Found" />;

  return (
    <div className="mx-auto flex w-full max-w-xl flex-1 flex-shrink-0 flex-col px-16 py-24 sm:px-24 sm:py-32">
      {articles.map((article) => (
        <Article article={article} key={article.sys_id} />
      ))}
      {loading ? (
        <FuseLoading />
      ) : (
        hasMore && (
          <Button onClick={handleShowMore} color="primary">
            Show More
          </Button>
        )
      )}
    </div>
  );
};

ArticleList.propTypes = {
  categoryIds: PropTypes.arrayOf(PropTypes.shape({ sys_id: PropTypes.string })),
  searchTerm: PropTypes.string,
};

ArticleList.defaultProps = {
  categoryIds: [],
  searchTerm: '',
};

export default ArticleList;
