import { current } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const ADVANCED_SEARCH = 'advancedSearch';

function retrieveSearchState(history) {
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });

  return queryParams[ADVANCED_SEARCH];
}

function getSearchQueryString(history) {
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const searchQuery = pick(queryParams, ADVANCED_SEARCH);

  return qs.stringify(searchQuery);
}

export default function useAdvancedSearch(initialState = {}) {
  const history = useHistory();

  const [advancedSearch, setAdvancedSearch] = useState({ ...initialState, ...retrieveSearchState(history) });

  const getExistingHashString = useCallback(() => {
    return history.location.hash || '';
  }, [history.location.hash]);

  const getExistingQueryString = useCallback(() => {
    const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    delete queryParams[ADVANCED_SEARCH];

    return qs.stringify(queryParams);
  }, [history.location.search]);

  const registerSearchState = useCallback(
    (state) => {
      if (history?.location) {
        const existingString = getExistingQueryString();
        const existingHash = getExistingHashString();

        const searchString = qs.stringify({ [ADVANCED_SEARCH]: state });

        const search = existingString.length ? `${existingString}&${searchString}` : searchString;
        const currentSearch = getSearchQueryString(history);

        if (search !== currentSearch) {
          history.replace({ search, hash: existingHash });
        }
      }
    },
    [history, getExistingQueryString, getExistingHashString, getSearchQueryString],
  );

  useEffect(() => {
    registerSearchState(advancedSearch);
  }, [advancedSearch, registerSearchState]);

  return {
    advancedSearch,
    setAdvancedSearch,
    getExistingQueryString,
    advancedSearchQueryString: getSearchQueryString(history),
  };
}
