module.exports = {
  TYPES: {
    PICKUP: 'PICKUP',
    DELIVERY: 'DELIVERY',
    SELF_CHECKOUT: 'SELF_CHECKOUT'
  },
  OPTIONS: [{
    label: 'Pick up',
    value: 'PICKUP'
  }, {
    label: 'Delivery',
    value: 'DELIVERY'
  }]
};