const {
  ObjectIdSchema,
  RssModel,
  RELATIONSHIP_LABEL: {
    NODE
  }
} = require('@rss/common');
const {
  format
} = require('date-fns');
const {
  ObjectId
} = require('bson');
const yup = require('yup');
const {
  ORDER_RECEIVING,
  ORDER_STATUS
} = require('../constants');
const FundReferenceSchema = require('./fund-reference.schema');
class Order extends RssModel {
  constructor(opt = {}) {
    super(opt);
    if (!opt.history?.length) {
      this.history = [{
        user: this.createdBy,
        status: this.receivingType === ORDER_RECEIVING.TYPES.SELF_CHECKOUT ? ORDER_STATUS.SELF_CHECKOUT : ORDER_STATUS.ORDER_SUBMITTED,
        date: new Date()
      }];
    }
    const totals = this.getTotalCosts();
    this.totalCost = totals.totalCost;
    this.totalSubsidy = totals.totalSubsidy;
    this.refunds = [];
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: yup.string().default(() => this.generateId()).required(),
      status: yup.string().oneOf(Object.values(ORDER_STATUS)).default(ORDER_STATUS.ORDER_SUBMITTED).required(),
      history: yup.array().of(yup.object({
        user: yup.object({
          userId: yup.string().required(),
          firstName: yup.string().default(null).nullable(),
          lastName: yup.string().default(null).nullable(),
          email: yup.string().default(null).nullable()
        }),
        status: yup.string().oneOf(Object.values(ORDER_STATUS)).required(),
        date: yup.date().default(new Date()).required()
      })).ensure(),
      storeId: new ObjectIdSchema(),
      programName: yup.string().default('').nullable(),
      account: yup.object({
        id: new ObjectIdSchema(),
        administeredBy: yup.object({
          id: yup.string().required(),
          label: yup.string().oneOf([NODE.INVENTORY]).required(),
          name: yup.string().required(),
          ownerLastName: yup.string().default('').nullable()
        })
      }),
      fund: FundReferenceSchema.Fund.concat(yup.object({
        id: new ObjectIdSchema().default(() => new ObjectId()).required()
      })),
      receivingType: yup.string().oneOf(Object.values(ORDER_RECEIVING.TYPES)).required(),
      deliveryLocation: yup.object({
        id: new ObjectIdSchema().default(() => new ObjectId()).required(),
        buildingId: yup.string().required(),
        buildingName: yup.string().required(),
        floorId: yup.string(),
        floorName: yup.string(),
        roomId: yup.string().required(),
        roomNumber: yup.string().required(),
        sublocationId: yup.string(),
        sublocationName: yup.string()
      }).nullable(),
      items: yup.array().of(yup.object({
        _id: new ObjectIdSchema(),
        quantity: yup.number().integer().min(1).default(1),
        inventoryItems: yup.array().of(yup.object({
          _id: new ObjectIdSchema(),
          barcode: yup.string(),
          isRefunded: yup.bool().default(false)
        })).ensure(),
        salesTax: yup.number().min(0).default(0),
        product: yup.object({
          _id: new ObjectIdSchema(),
          name: yup.string().required(),
          description: yup.string().nullable().defined(),
          library: yup.object({
            _id: new ObjectIdSchema(),
            catalog: yup.string().nullable()
          }).required(),
          price: yup.number().min(0).default(0),
          fee: yup.number().min(0).default(0),
          taxExempt: yup.bool().default(false),
          subsidy: yup.number().min(0).default(0),
          purposeCode: yup.string().default(null).nullable().defined(),
          sku: yup.string().default(null).nullable()
        })
      })).min(1).required(),
      totalCost: yup.number().min(0).default(0),
      // price + fee for all items,
      totalSubsidy: yup.number().min(0).default(0),
      // subsidy for all items,
      createdBy: yup.object({
        userId: yup.string().required(),
        firstName: yup.string().default(null).nullable(),
        lastName: yup.string().default(null).nullable(),
        email: yup.string().default(null).nullable()
      }),
      createdDate: yup.date().default(new Date()).required(),
      lastModifiedBy: yup.object({
        userId: yup.string().required(),
        firstName: yup.string().default(null).nullable(),
        lastName: yup.string().default(null).nullable(),
        email: yup.string().default(null).nullable()
      }),
      lastModifiedDate: yup.date().default(new Date()).required(),
      shopper: yup.object({
        userId: yup.string().required(),
        firstName: yup.string().default(null).nullable(),
        lastName: yup.string().default(null).nullable(),
        email: yup.string().default(null).nullable()
      }),
      notes: yup.string().default(''),
      refunds: yup.array().default([])
    });
  }
  getTotalCosts() {
    const result = this.items.reduce((acc, curr) => {
      const tax = curr.product.taxExempt ? 0 : curr.quantity * curr.product.price * curr.salesTax;
      acc.totalCost += curr.quantity * (curr.product.price + curr.product.fee) + tax;
      acc.totalSubsidy += curr.quantity * curr.product.subsidy;
      return acc;
    }, {
      totalCost: 0,
      totalSubsidy: 0
    });
    return {
      totalCost: Math.round((result.totalCost + Number.EPSILON) * 100) / 100,
      totalSubsidy: Math.round((result.totalSubsidy + Number.EPSILON) * 100) / 100
    };
  }
  generateId() {
    const date = format(new Date(), 'MM-dd-yyyy');
    // get last 3-byte incrementing counter from ObjectId
    const id = new ObjectId().toString().slice(-6);
    this._id = `${date}-${id}`;
  }
}
module.exports = Order;