"use strict";

var Diff = require('deep-diff');
var _require = require('lodash'),
  get = _require.get,
  set = _require.set,
  find = _require.find;
var BARCODE_PATTERN = require('../constant/chemical/barcode-pattern.constant');
var HAZARD_PICTOGRAMS = require('../constant/chemical/hcode-to-pictogram.constant');
var TENANTS = require('../constant/core/tenants.constant');
module.exports.validateError = function (validate) {
  var errorMessages = validate.errors.map(function (err) {
    return "".concat(err.message, ", ").concat(err.schemaPath);
  }).join('\n');
  return module.exports.error({
    message: "failed to validate model ".concat(errorMessages)
  });
};
module.exports.error = function (_ref) {
  var message = _ref.message,
    _ref$status = _ref.status,
    status = _ref$status === void 0 ? 400 : _ref$status;
  return Object.assign(new Error(message), {
    status: status
  });
};
module.exports.pictograms = function (ghs) {
  return Array.from(ghs.reduce(function (acc, curr) {
    if (curr && curr.hazardCode) {
      (HAZARD_PICTOGRAMS[curr.hazardCode] || []).forEach(function (pictogram) {
        return acc.add(pictogram);
      });
    }
    return acc;
  }, new Set())).sort();
};
module.exports.campusCodeToTenantCode = function (campusCode) {
  var result = TENANTS.find(function (tenant) {
    return tenant.campusList.some(function (item) {
      return item.campusCode === campusCode;
    });
  });
  return result ? result.tenantCode : null;
};
module.exports.getTenant = function (tenantCode) {
  if (!tenantCode) return null;
  return find(TENANTS, {
    tenantCode: tenantCode
  });
};
module.exports.getCampus = function (campusCode) {
  if (!campusCode) return null;
  var tenantCode = module.exports.campusCodeToTenantCode(campusCode);
  if (tenantCode) {
    var tenant = module.exports.getTenant(tenantCode);
    if (tenant) return find(tenant.campusList, {
      campusCode: campusCode
    });
  }
  return null;
};
module.exports.toTitleCase = function (item) {
  var withSpaces = (item || '').replace(/_/g, ' ');
  return withSpaces.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
module.exports.getBarcodePattern = function (_ref2) {
  var tenantCode = _ref2.tenantCode,
    campusCode = _ref2.campusCode;
  var match = BARCODE_PATTERN.DEFAULT;
  if (tenantCode && BARCODE_PATTERN[tenantCode] && campusCode && BARCODE_PATTERN[tenantCode][campusCode]) {
    match = BARCODE_PATTERN[tenantCode][campusCode];
  }
  return match;
};
module.exports.getBarcodeFormats = function (tenant, campus) {
  var barcode = BARCODE_PATTERN.formats[tenant] ? BARCODE_PATTERN.formats[tenant][campus] : null;
  if (!barcode) {
    return BARCODE_PATTERN.formats.DEFAULT;
  }
  return barcode;
};
module.exports.getDiff = function (original, latest) {
  var diff = {};
  Diff.observableDiff(original, latest, function (change) {
    /* Apply all changes for change.kind of:
      - N: indicates a newly added property/element
      - D: indicates a property/element was deleted
      - E: indicates a property/element was edited
    */
    if (['N', 'E', 'A'].includes(change.kind)) {
      var path = change.path.join('.');
      set(diff, path, get(latest, path));
    }
  });
  return diff;
};
module.exports.validateLabel = function (obj) {
  return !Object.values(obj).some(function (v) {
    return v === Object(v);
  });
};

// @DEPREICATED
// use date helper instead
module.exports.validateDate = function (date) {
  var year = new Date(date).getFullYear();
  return year && year > 999 && year < 3000;
};
module.exports.notFoundError = function () {
  return Object.assign(new Error('Not Found'));
};
module.exports.invalidFormatError = function () {
  return Object.assign(new Error('Invalid Format'));
};
module.exports.setErrorCode = function (err) {
  var error = Object.assign(err);
  if (/(\w*Not Found\w*)/gi.test(error.message)) {
    error.status = 404;
  } else if (/(\w*Invalid\w*)/gi.test(error.message)) {
    error.status = 400;
  }
  return error;
};
module.exports.convertNormalizedAmount = function (quantity, conversion) {
  var literToGallon = 0.264172;
  var literToCubicFeet = 0.0353147;
  var gramToPound = 0.00220462;
  switch (conversion) {
    case 'gramToPound':
      return quantity * gramToPound;
    case 'literToCubicFeet':
      return quantity * literToCubicFeet;
    case 'literToGallon':
      return quantity * literToGallon;
    default:
  }
  return quantity;
};