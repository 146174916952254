import { useQuery } from '@apollo/client';
import { FormControl, InputLabel, LinearProgress, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { FIND_BUILDING_DETAILS_BY_ID_NEPTUNE } from '../../graphql/relationship.query';

const FloorSelect = ({ buildingId, isDisabled, label, selectedFloor, onSelect }) => {
  const { data = {}, loading } = useQuery(FIND_BUILDING_DETAILS_BY_ID_NEPTUNE, {
    variables: { buildingId },
    skip: !buildingId,
  });

  const { findBuildingDetailsById, buildingDetail } = data;
  const { floors } = findBuildingDetailsById || buildingDetail || {};

  return (
    <FormControl variant="outlined" className="m-1 w-full">
      <InputLabel htmlFor="floor-select">{label}</InputLabel>
      <Select
        value={selectedFloor.floorId || ''}
        disabled={isDisabled}
        label="floor-select"
        inputProps={{
          id: 'floor-select',
        }}
        variant="outlined"
        onChange={(event) => onSelect(floors.find((f) => f.floorId === event.target.value))}>
        {floors &&
          floors.map(({ floorId, floorName }) => (
            <MenuItem key={floorId} value={floorId}>
              {floorName}
            </MenuItem>
          ))}
      </Select>
      {loading && <LinearProgress />}
    </FormControl>
  );
};

FloorSelect.propTypes = {
  buildingId: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selectedFloor: PropTypes.shape({ floorId: PropTypes.string, floorName: PropTypes.string }),
};

FloorSelect.defaultProps = {
  buildingId: '',
  isDisabled: false,
  label: 'Select Floor',
  selectedFloor: {
    floorId: '',
    floorName: '',
  },
};

export default FloorSelect;
