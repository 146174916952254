import { CircularProgress, Dialog, DialogContent, Typography } from '@material-ui/core';
import proptTypes from 'prop-types';
import React from 'react';

function DialogProgress({ loading }) {
  return (
    <Dialog open={loading}>
      <DialogContent className="mt-20 flex flex-col items-center">
        <CircularProgress />

        <Typography variant="subtitle2" className="mt-20 mb-5">
          Saving ...
        </Typography>
        <Typography vairant="caption" className="mb-20">
          Please wait. This may take some time.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

DialogProgress.propTypes = {
  loading: proptTypes.bool,
};

DialogProgress.defaultProps = {
  loading: false,
};

export default DialogProgress;
