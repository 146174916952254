import FuseAnimate from '@fuse/core/FuseAnimate';
import { Avatar, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import { REPORT_STATUS, SUBJECT_MAP } from '@risk-and-safety/inspect-v2-common';
import { DateHelper, SHORT_DATE_FORMAT } from '@rss/common';
import { upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { useProfile } from '../../../hooks';
import InspectionDateDialog from './InspectionDateDialog';

const AVATAR_DISPLAY_COUNT = 2;

function ReportHeader({
  activeUsers,
  animate,
  overline,
  questionnaire,
  report,
  subject,
  subjectUrl,
  onEditInspectionDate,
}) {
  const [showInspectionDateDialog, setShowInspectionDateDialog] = useState(false);
  const { profile = {} } = useProfile({ skip: true });

  const usersOnDocument = activeUsers.filter(({ id }) => profile.userId !== id);
  const avatars = usersOnDocument.slice(0, AVATAR_DISPLAY_COUNT);
  const overflowUsers = usersOnDocument.slice(AVATAR_DISPLAY_COUNT);

  return (
    <>
      <div className="flex w-full flex-1 flex-col">
        <div className="flex">
          {overline && (
            <AnimatedWrapper animate={animate} animation="transition.slideRightIn" delay={300}>
              {overline}
            </AnimatedWrapper>
          )}
        </div>
        <div className="flex h-full flex-col items-center justify-between sm:flex-row">
          <div className="flex max-w-full items-center">
            <AnimatedWrapper animate={animate} animation="transition.expandIn" delay={300}>
              <Icon className="mr-8 hidden text-32 md:block">{SUBJECT_MAP[questionnaire.subject.type]}</Icon>
            </AnimatedWrapper>
            <AnimatedWrapper animate={animate} animation="transition.slideLeftIn" delay={300}>
              <div className="flex min-w-0 flex-col ">
                <div className="flex flex-row items-center">
                  <Typography variant="h1" className="mr-4 truncate text-20 font-medium">
                    {questionnaire.name}
                  </Typography>
                  {!questionnaire.active && report?.status !== REPORT_STATUS.DONE && (
                    <Tooltip
                      placement="top-start"
                      title={`This report was created from a previous version of ${questionnaire.name}.`}>
                      <Icon className="text-warning-400">warning</Icon>
                    </Tooltip>
                  )}
                </div>
                <Typography component={subjectUrl ? Link : undefined} to={subjectUrl || undefined}>
                  <span className="flex items-center text-white">
                    {subject.name}: {subject.description}
                    {subjectUrl && (
                      <Icon fontSize="small" className="ml-4">
                        launch
                      </Icon>
                    )}
                  </span>
                </Typography>
              </div>
            </AnimatedWrapper>
          </div>

          <AnimatedWrapper animate={animate} animation="transition.slideRightIn" delay={300}>
            <div className="flex flex-col justify-center pr-16">
              <div className="flex -space-x-3 overflow-hidden">
                {avatars
                  .filter(({ firstName }) => Boolean(firstName))
                  .map((user) => (
                    <Tooltip key={user.id} title={`${user.firstName} ${user.lastName}`}>
                      <Avatar
                        key={user.id}
                        className="mb-2 h-32 w-32 bg-primary-A200 text-sm text-primary-900 ring-4 ring-primary-900">
                        {user.firstName[0]}
                        {user.lastName[0]}
                      </Avatar>
                    </Tooltip>
                  ))}

                {overflowUsers.length > 0 && (
                  <Tooltip
                    title={
                      <>
                        {overflowUsers.map((user) => (
                          <div key={user.id}>
                            {user.firstName} {user.lastName}
                          </div>
                        ))}
                      </>
                    }>
                    {/* eslint-disable-next-line max-len */}
                    <Avatar className="mb-2 h-32 w-32 bg-primary-100 text-sm text-primary-900 ring-4 ring-primary-900">
                      +{overflowUsers.length}
                    </Avatar>
                  </Tooltip>
                )}
              </div>
              {report && (
                <>
                  <Typography className="hidden sm:block">
                    Status: <span className="font-bold">{upperCase(report.status)}</span>
                  </Typography>
                  <div className="hidden flex-row items-center sm:flex">
                    <Typography>
                      Inspection Date:{' '}
                      <span className="font-bold">
                        {DateHelper.formatDate(report.inspectionDate, SHORT_DATE_FORMAT)}
                      </span>
                    </Typography>
                    {Boolean(onEditInspectionDate) && (
                      <IconButton size="small" onClick={() => setShowInspectionDateDialog(true)}>
                        <Icon>edit</Icon>
                      </IconButton>
                    )}
                  </div>
                </>
              )}
            </div>
          </AnimatedWrapper>
        </div>
      </div>
      {showInspectionDateDialog && (
        <InspectionDateDialog
          inspectionDate={report.inspectionDate}
          onClose={() => setShowInspectionDateDialog(false)}
          onSave={(date) => {
            setShowInspectionDateDialog(false);
            onEditInspectionDate(date);
          }}
        />
      )}
    </>
  );
}

ReportHeader.propTypes = {
  activeUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  animate: PropTypes.bool,
  inspectionDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
  overline: PropTypes.node,
  questionnaire: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    program: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    subject: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  }).isRequired,
  report: PropTypes.shape({
    inspectionDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]),
    status: PropTypes.string.isRequired,
  }),
  subject: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  subjectUrl: PropTypes.string,
  onEditInspectionDate: PropTypes.func,
};

ReportHeader.defaultProps = {
  activeUsers: [],
  animate: true,
  inspectionDate: null,
  overline: null,
  report: null,
  subjectUrl: null,
  onEditInspectionDate: null,
};

function AnimatedWrapper({ animate, children, ...props }) {
  const Component = animate ? FuseAnimate : Fragment;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...(animate ? props : {})}>{children}</Component>;
}

AnimatedWrapper.propTypes = {
  animate: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

AnimatedWrapper.defaultProps = {
  animate: false,
};

export default ReportHeader;
