import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { IconEmpty } from '../../../components';
import { PersonSubjectDetails } from './PersonSubjectDetails';

export function SubjectDetails({ details, reportId, subjectType }) {
  return subjectType === 'USER' ? (
    <PersonSubjectDetails reportId={reportId} />
  ) : (
    <dl>
      {details.map((detail) => (
        <div key={detail.label} className="mb-16">
          <Typography key={detail.label} variant="subtitle1" className="mb-4" role="term">
            {detail.label}
          </Typography>
          <dd>
            <dl>
              {detail.values.map((value) => (
                <div key={value.label} className="mb-16 ml-32">
                  <dt className="mr-2 font-medium" role="term">
                    {value.label}
                  </dt>
                  <dd>
                    {value.values.length ? (
                      value.values.join(', ')
                    ) : (
                      <IconEmpty classes={{ icon: '-ml-4' }} title={`${value.label} not found`} />
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </dd>
        </div>
      ))}
    </dl>
  );
}

SubjectDetails.propTypes = {
  details: PropTypes.arrayOf(
    PropTypes.objectOf({
      label: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.objectOf({
          label: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    }),
  ),
  subjectType: PropTypes.string.isRequired,
  reportId: PropTypes.string,
};

SubjectDetails.defaultProps = {
  details: [],
  reportId: '',
};
