import { lazy } from 'react';

import { hasClientAdminRole } from '../shared/helper';

const CampusPage = lazy(() => import('./containers/CampusPage'));
const ClientListPage = lazy(() => import('./containers/ClientListPage'));
const IdpPage = lazy(() => import('./containers/IdpPage'));

const routes = {
  auth: ({ profile } = {}) => hasClientAdminRole(profile),
  routes: [
    {
      path: ['/rss-tools/clients/campus/:campusCode'],
      component: CampusPage,
    },
    {
      path: ['/rss-tools/clients/idp/:campusCode'],
      component: IdpPage,
    },
    {
      path: ['/rss-tools/clients'],
      component: ClientListPage,
    },
  ],
};

export default routes;
