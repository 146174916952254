/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client';
import { ClickAwayListener, Icon } from '@material-ui/core';
import { SpeedDial, SpeedDialAction } from '@material-ui/lab';
import { startCase } from 'lodash';
import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import TableWithHeader from './TableWithHeader';
import { REMOVE_FUND, REMOVE_DELIVERY_LOCATION } from '../graphql/query';
import { formatFundValueToString } from '../helper/account.helper';

const actions = [
  { icon: <Icon>local_shipping</Icon>, name: 'Add Location', type: 'location' },
  { icon: <Icon>credit_card</Icon>, name: 'Add Fund', type: 'fund' },
];

function AccountDetail({ account, store }) {
  const [open, setOpen] = useState(false);
  const { url } = useRouteMatch();
  const history = useHistory();
  const { state } = useLocation();
  const [removeStoreAccountFund] = useMutation(REMOVE_FUND);
  const [removeStoreAccountDeliveryLocation] = useMutation(REMOVE_DELIVERY_LOCATION);

  return (
    <div className="flex-grow p-28">
      <div className="pb-48">
        <TableWithHeader
          enableCheckbox
          icon="credit_card"
          title="Funds"
          columns={[
            {
              Header: 'Account',
              accessor: 'value',
              className: 'w-320',
              Cell: ({ value }) => formatFundValueToString(value),
            },
            { Header: 'Name', accessor: 'name' },
            {
              Header: 'Type',
              accessor: 'type',
              Cell: ({ value }) => startCase(value.toLowerCase()),
            },
            {
              Header: 'Default',
              accessor: 'default',
              className: 'p-0 w-68',
              // eslint-disable-next-line react/prop-types
              Cell: ({ value }) => value && <Icon className="text-success-500">check</Icon>,
            },
          ]}
          data={account.funds}
          noDataMsg="Please add at least one fund to this account to make purchases."
          onEdit={(value) => {
            history.push(`${url}/fund/${value.id}`);
          }}
          onRemove={(values) => {
            values.map((value) =>
              removeStoreAccountFund({
                variables: { id: account._id, fundId: value.id, storeId: store?._id },
              }),
            );
          }}
        />
      </div>

      <div className="pb-48">
        <TableWithHeader
          enableCheckbox
          icon="local_shipping"
          title="Delivery / Transfer Locations"
          noDataMsg="Please add at least one location to this account to make purchases"
          columns={[
            { Header: 'Building', accessor: 'buildingName', className: 'w-320' },
            { Header: 'Room', accessor: 'roomNumber' },
            { Header: 'Location', accessor: 'sublocationName' },
            {
              Header: 'Default',
              accessor: 'default',
              className: 'p-0 w-68',
              // eslint-disable-next-line react/prop-types
              Cell: ({ value }) => value && <Icon className="text-success-500">check</Icon>,
            },
          ]}
          data={account.deliveryLocations}
          onEdit={(value) => {
            history.push(`${url}/location/${value.id}`);
          }}
          onRemove={(values) => {
            values.map((value) =>
              removeStoreAccountDeliveryLocation({
                variables: { id: account._id, deliveryLocationId: value.id, storeId: store?._id },
              }),
            );
          }}
        />
      </div>

      <div className="pb-48">
        <TableWithHeader
          icon="group"
          title="Members"
          columns={[
            {
              Header: 'Name',
              accessor: 'firstName',
              className: 'w-360',
              Cell: ({ cell }) => {
                const member = cell.row.original;
                return `${member.lastName}, ${member.firstName}`;
              },
            },
            { Header: 'Email', accessor: 'email' },
          ]}
          data={account.administeredBy.members}
        />
      </div>

      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <SpeedDial
          ariaLabel="Add"
          className="absolute bottom-16 right-20"
          icon={<Icon>more_vert</Icon>}
          onClick={() => setOpen(!open)}
          open={open}>
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => {
                history.push(`${url}/${action.type}`, state);
              }}
            />
          ))}
        </SpeedDial>
      </ClickAwayListener>
    </div>
  );
}

AccountDetail.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccountDetail;
