const clone = require('clone');
const tv4 = require('tv4');
class HealthSymptoms {
  constructor(opt) {
    this.general = null;
    this.inhalation = null;
    this.skin = null;
    this.eye = null;
    this.ingestion = null;
    this.fire = null;
    this.explosion = null;
    this.exposure = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    Object.keys(this).forEach(prop => {
      this[prop] = opt[prop] || null;
    });
  }
  validate() {
    return tv4.validateMultiple(this, HealthSymptoms.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'health-symptoms.schema.json',
      type: 'object',
      properties: {
        general: {
          type: ['string', 'null']
        },
        inhalation: {
          type: ['string', 'null']
        },
        skin: {
          type: ['string', 'null']
        },
        eye: {
          type: ['string', 'null']
        },
        ingestion: {
          type: ['string', 'null']
        },
        fire: {
          type: ['string', 'null']
        },
        explosion: {
          type: ['string', 'null']
        },
        exposure: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = HealthSymptoms;