import FuseAnimate from '@fuse/core/FuseAnimate';
import { Button, Icon, IconButton, ListSubheader, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ButtonProgress from './ButtonProgress';
import DialogTemplate from './DialogTemplate';

const SubHeaderWithActions = ({
  canEdit,
  loading: { removeLoading, upsertLoading },
  redirectBackUrl,
  removeConfig,
  saveLabel,
  onRemove,
  onSave,
}) => {
  const [isRemove, setIsRemove] = useState(false);

  const history = useHistory();
  const { content, title } = removeConfig || {};

  return (
    <ListSubheader component="div" className="z-20 flex h-64 justify-between rounded-t-2xl border-b-1 py-8">
      <div>
        {redirectBackUrl && (
          <IconButton onClick={() => history.push(redirectBackUrl)}>
            <Icon> arrow_back</Icon>
          </IconButton>
        )}
      </div>
      <div className="flex items-center justify-start space-x-8">
        {onRemove && (
          <FuseAnimate animation="transition.expandIn" delay={100}>
            <IconButton disabled={!canEdit} onClick={() => setIsRemove(true)}>
              <Tooltip title={`Remove ${title}`} placement="top" arrow>
                <Icon aria-label={`remove-${title}`}>delete</Icon>
              </Tooltip>
            </IconButton>
          </FuseAnimate>
        )}
        <ButtonProgress
          loading={upsertLoading}
          color="primary"
          variant="contained"
          disabled={!(canEdit && onSave)}
          onClick={onSave}>
          {saveLabel}
        </ButtonProgress>
      </div>
      {isRemove && content && (
        <DialogTemplate
          open={isRemove}
          color="secondary"
          content={<div className="pt-16 text-base">{content}</div>}
          primaryActions={
            <>
              <Button variant="contained" onClick={() => setIsRemove(false)}>
                Cancel
              </Button>
              <ButtonProgress
                loading={onRemove && removeLoading}
                color="secondary"
                variant="contained"
                onClick={onRemove}>
                Remove
              </ButtonProgress>
            </>
          }
        />
      )}
    </ListSubheader>
  );
};

SubHeaderWithActions.propTypes = {
  canEdit: PropTypes.bool,
  loading: PropTypes.objectOf(PropTypes.any),
  redirectBackUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  removeConfig: PropTypes.shape({ content: PropTypes.string, title: PropTypes.string }),
  saveLabel: PropTypes.string,
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
};

SubHeaderWithActions.defaultProps = {
  canEdit: false,
  loading: {},
  redirectBackUrl: null,
  removeConfig: {},
  saveLabel: 'Save',
  onRemove: null,
  onSave: null,
};

export default SubHeaderWithActions;
