import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PRODUCT_FOR_SHOPPING } from '../graphql/query';
import ProductDetail from './ProductDetail';
import { ErrorMessage } from '../../components/errors';
import { addToCart } from '../../store/app/shoppingCartSlice';

function ShopProduct({ store }) {
  const dispatch = useDispatch();
  const { productId } = useParams();

  const { loading, data, error } = useQuery(PRODUCT_FOR_SHOPPING, {
    variables: { storeId: store._id, id: productId },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <FuseLoading />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  const onAddToCart = (quantity) => {
    dispatch(
      addToCart({
        storeId: store._id,
        product: data?.product,
        quantity: parseInt(quantity, 10),
        salesTax: store.salesTax,
      }),
    );
  };

  return (
    <div className="flex-grow p-28">
      <ProductDetail store={store} product={data?.product} enableShoppingOption onAddToCart={onAddToCart} />
    </div>
  );
}

ShopProduct.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShopProduct;
