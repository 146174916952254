"use strict";

module.exports = [{
  label: 'mmHg',
  value: 'mmHg'
}, {
  label: 'hPa',
  value: 'hPa'
}, {
  label: 'kPa',
  value: 'kpa'
}, {
  label: 'psi',
  value: 'psi'
}, {
  label: 'mbar',
  value: 'mbar'
}];