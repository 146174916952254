/* eslint-disable react/prop-types */
import FuseNavigation from '@fuse/core/FuseNavigation';
import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { navbarCloseMobile } from '../../store/fuse/navbarSlice';
import { selectNavigation } from '../../store/fuse/navigationSlice';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  function handleItemClick(item) {
    mdDown && dispatch(navbarCloseMobile());
  }

  return (
    <FuseNavigation
      className={clsx('navigation', props.className)}
      navigation={navigation}
      layout={props.layout}
      dense={props.dense}
      active={props.active}
      onItemClick={handleItemClick}
    />
  );
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
