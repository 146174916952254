module.exports = {
  FACILITY_TYPES: [
    { type: 'ABC', description: 'Alternative Birthing Center' },
    { type: 'ADHC', description: 'Adult Day Health Care' },
    { type: 'APH', description: 'Acute Psychiatric Hospital' },
    { type: 'ASC', description: 'Ambulatory Surgical Center' },
    { type: 'ASC/SURGC', description: 'Surgical Clinic (ASC/SURGC)' },
    { type: 'CDC', description: 'Chronic Dialysis Clinic (CDC)' },
    { type: 'CDRH', description: 'Chemical Dependency Recovery Hospital' },
    { type: 'CLHF', description: 'Congregate Living Health Facility' },
    { type: 'CMHC', description: 'Community Mental Health Center' },
    { type: 'COMTYC', description: 'Community Clinic (COMTYC)' },
    { type: 'CORF', description: 'Comprehensive Outpatient Rehabilitation Facility' },
    { type: 'CTC', description: 'Correctional Treatment Center' },
    { type: 'ESRD', description: 'End Stage Renal Disease' },
    { type: 'ESRD/CDC', description: 'Chronic Dialysis Clinic (ESRD)' },
    { type: 'FQHC', description: 'Federally Qualified Health Center' },
    { type: 'FREEC', description: 'Free Clinic' },
    { type: 'GACH', description: 'General Acute Care Hospital' },
    { type: 'HHA', description: 'Home Health Agency' },
    { type: 'HOFA', description: 'Hospice Facility' },
    { type: 'HOSPICE', description: 'Hospice' },
    { type: 'HSC', description: 'H & S Code 1277' },
    { type: 'ICF', description: 'Intermediate Care Facility' },
    { type: 'ICFDD', description: 'Intermediate Care Facility/Developmentally Disabled' },
    { type: 'ICFDDH', description: 'Intermediate Care Facility/Developmentally Disabled - Habilitative' },
    { type: 'ICFDDN', description: 'Intermediate Care Facility/Developmentally Disabled - Nursing' },
    { type: 'OPT/SP', description: 'Outpatient/Speech Pathologist' },
    { type: 'PDHRCF', description: 'Pediatric Day Health & Respite Care Facility' },
    { type: 'PHF', description: 'Psychiatric Health Facility' },
    { type: 'PPSPSCH', description: 'Prospective Payment System - Psychiatric Unit' },
    { type: 'PPSREHB', description: 'Prospective Payment System - Rehabilitation Unit' },
    { type: 'PSYCHC', description: 'Psychology Clinic' },
    { type: 'PTIP', description: 'Physical Therapist Independent Practice' },
    { type: 'REFRLAG', description: 'Referral Agency' },
    { type: 'REHABC', description: 'Rehabilitation Clinic' },
    { type: 'RHC', description: 'Rural Health Clinic' },
    { type: 'RHC/COMTYC', description: 'Community Clinic (RHC/COMTYC)' },
    { type: 'RHC/MD', description: 'Rural Health Clinic(MD)' },
    { type: 'RHC/OP', description: 'Rural Health Clinic(OP)' },
    { type: 'SNF', description: 'Skilled Nursing Facility' },
    { type: 'SURGC', description: 'Surgical Clinic (SURGC)' },
    { type: 'TRANS', description: 'Transplant Center' },
    { type: 'UNLICEN', description: 'Unlicensed Facility' },
  ],
};
