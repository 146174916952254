"use strict";

module.exports = {
  STANDARD: {
    label: 'Standard',
    value: 'STANDARD'
  },
  OVERSHOES: {
    label: 'Overshoes',
    value: 'OVERSHOES'
  },
  RESTRICTED_BULK: {
    label: 'Restricted Bulk',
    value: 'RESTRICTED_BULK'
  },
  RESTRICTED_INDIVIDUAL: {
    label: 'Restricted Individual',
    value: 'RESTRICTED_INDIVIDUAL'
  }
};