import { useQuery } from '@apollo/client';
import { FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { LIST_PROGRAMS } from '../graphql/relationship.query';
import { useProfile } from '../hooks';
import ErrorMessage from './errors/ErrorMessage';

const getProgramInfo = (programs, pId) => programs.find((p) => p.id === pId);

const ProgramSelect = ({ id, domainId, value, onSelect, disabled, required, errors, errorText }) => {
  const { profile } = useProfile();
  const [selectedProgram, setSelectedProgram] = useState(value || '');
  const [programs, setPrograms] = useState([]);

  const { loading, error: programsError } = useQuery(LIST_PROGRAMS, {
    variables: { search: { domainId } },
    skip: !domainId || !profile,
    onCompleted: ({ listPrograms }) => {
      setPrograms(listPrograms);
      setSelectedProgram(value);
    },
  });

  useEffect(() => {
    setSelectedProgram(value);
  }, [value]);

  const handleSelect = (event) => {
    const programId = event.target.value;
    onSelect({ id: programId, name: getProgramInfo(programs, programId).name });
    setSelectedProgram(programId);
  };

  const selectId = `${id}_program`;

  if (programsError) return <ErrorMessage />;

  return (
    <FormControl
      variant="outlined"
      disabled={Boolean(!domainId || !programs.length || disabled)}
      required={required}
      error={Boolean(domainId && programs.length && errors.length)}
      fullWidth>
      <InputLabel htmlFor={selectId}>Program</InputLabel>
      <Select
        label="Program"
        value={selectedProgram || ''}
        onChange={(event) => handleSelect(event)}
        inputProps={{
          name: selectId,
          id: selectId,
        }}>
        {programs &&
          programs.map((p) => (
            <MenuItem key={p.id} value={p.id}>
              {p.name}
            </MenuItem>
          ))}
      </Select>
      {domainId && programs.length ? errors && errorText && <FormHelperText>{errorText}</FormHelperText> : null}
      {loading && <LinearProgress />}
    </FormControl>
  );
};

ProgramSelect.propTypes = {
  disabled: PropTypes.bool,
  domainId: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  errorText: PropTypes.string,
  id: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
};

ProgramSelect.defaultProps = {
  disabled: false,
  domainId: null,
  errors: [],
  errorText: '',
  id: '',
  required: false,
  value: '',
};

export default ProgramSelect;
