import { head, toLower } from 'lodash';
import qs from 'qs';
import { matchRoutes } from 'react-router-config';

const ALL_CATEGORY = 'ALL';

export const omitTypeName = (data) => {
  if (!data) return null;
  const dataToBeOmitted = (key, value) => (key === '__typename' ? undefined : value);
  return JSON.parse(JSON.stringify(data), dataToBeOmitted);
};

export const getCategoriesFromSearch = (search) => {
  const searchData = qs.parse(search, { ignoreQueryPrefix: true });
  return searchData ? searchData.categories?.split(',') : [];
};

export const getRoutePath = (path) => path.replace('/help', '');

export const getHelpCategoriesFromRoute = (routes, routePath, params) => {
  const [matched] = matchRoutes(routes, routePath);
  return matched?.route?.settings?.helpCategories || (params?.category && [params.category]) || [ALL_CATEGORY];
};

export const getCategoryIds = (helpCategoriesByName, categoriesFromRoute) => {
  const categoriesByName = helpCategoriesByName?.result || [];
  return head(categoriesFromRoute) === ALL_CATEGORY ? null : omitTypeName(categoriesByName);
};

export const getSelectedCategory = (allCategories, categoriesFromRoute) => {
  const { full_category } =
    allCategories.find((item) => toLower(item?.full_category || '') === toLower(head(categoriesFromRoute) || '')) || {};
  return full_category;
};

export const getCategories = (routes, path, params, search) => {
  const routePath = getRoutePath(path);
  const categoriesFromRoute = getHelpCategoriesFromRoute(routes, routePath, params);
  const categoriesFromSearch = getCategoriesFromSearch(search);
  return categoriesFromSearch?.length ? categoriesFromSearch : categoriesFromRoute;
};
