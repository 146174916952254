/* eslint-disable react/no-array-index-key */
import { Fab, Icon, Tooltip, Typography } from '@material-ui/core';
import { Store, STORE_PERMISSION } from '@risk-and-safety/library';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ShareableLink } from '../../components';
import { formatFundValueToString } from '../helper/account.helper';
import ReactTable from './ReactTable';

const SettingDetail = ({ store }) => {
  const history = useHistory();

  return (
    <div className="table-responsive mb-24">
      <div className="flex items-center pb-16">
        <Icon color="action">info</Icon>
        <Typography className="h2 mx-12 font-light" color="textSecondary">
          General
        </Typography>
      </div>
      <div className="mb-24 ml-36">
        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Description</Typography>
          <Typography>{store.description}</Typography>
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Hours</Typography>
          {store.hours.from && (
            <Typography>
              Open from {format(new Date(store.hours.from), 'h:mm a')}
              {store.hours.to ? ` to ${format(new Date(store.hours.to), 'h:mm a')}` : ''}
            </Typography>
          )}
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Catalogs</Typography>
          {store.catalogs.length > 0 && (
            <div className="ml-8 mt-8">
              <ReactTable
                columns={[
                  { Header: 'Catalog', accessor: 'value', className: 'w-256' },
                  { Header: 'Purpose code', accessor: 'purposeCode' },
                  {
                    Header: 'Markup percent',
                    accessor: 'markupRate',
                    Cell: ({ value }) => numeral(value).format('0.00%'),
                  },
                ]}
                data={store.catalogs.map((c) => ({ ...c, purposeCode: c.purposeCode?.value }))}
              />
            </div>
          )}
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Sales Tax</Typography>
          <Typography>{numeral(store.salesTax).format('0.00%')}</Typography>
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Shopping enabled</Typography>
          <div className="mt-8 flex items-center">
            <Icon className={`${store.settings.enableStoreFront ? 'text-success-500' : 'text-error-500'} mr-8`}>
              {store.settings.enableStoreFront ? 'check_circle' : 'cancel_circle'}
            </Icon>
            <Typography color={store.settings.enableStoreFront ? 'inherit' : 'error'}>
              Storefront {!store.settings.enableStoreFront && 'not '}enabled for shopping.
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">shopping_cart</Icon>
        <Typography className="h2 mx-12 font-light" color="textSecondary">
          Order Settings
        </Typography>
      </div>

      <div className="mb-24 ml-36">
        <div className="mb-16">
          <div className="flex items-center">
            <Icon className={`${store.settings.delivery ? 'text-success-500' : 'text-error-500'} mr-8`}>
              {store.settings.delivery ? 'check_circle' : 'cancel_circle'}
            </Icon>
            <Typography className="text-15 font-semibold">Delivery</Typography>
          </div>
          {store.settings.delivery ? (
            <Typography className="pl-32 pt-8">
              Users can select delivery when placing orders and will specify a dropoff location.
            </Typography>
          ) : (
            <Typography className="pl-32 pt-8">Delivery is currently not available.</Typography>
          )}
        </div>

        <div className="mb-16">
          <div className="flex items-center">
            <Icon className={`${store.settings.pickup ? 'text-success-500' : 'text-error-500'} mr-8`}>
              {store.settings.pickup ? 'check_circle' : 'cancel_circle'}
            </Icon>
            <Typography className="text-15 font-semibold">Pickup</Typography>
          </div>
          {store.settings.pickup ? (
            <Typography className="pl-32 pt-8">
              Users can select pickup when placing orders and will receive a notification once the order is ready for
              pickup at the store front.
            </Typography>
          ) : (
            <Typography className="pl-32 pt-8">Pickup is currently not available.</Typography>
          )}
        </div>

        <div className="mb-16">
          <div className="flex items-center">
            <Icon className={`${store.settings.selfCheckout ? 'text-success-500' : 'text-error-500'} mr-8`}>
              {store.settings.selfCheckout ? 'check_circle' : 'cancel_circle'}
            </Icon>
            <Typography className="text-15 font-semibold">Self Checkout</Typography>
          </div>
          {store.settings.selfCheckout ? (
            <>
              <Typography className="pl-32 pt-8">
                Users associated to accounts are able to make purchases at a dedicated self-checkout station. Use the
                generated URL for the self-checkout station.
              </Typography>
              <ShareableLink link={`${window.location.origin}/marketplace/shop/${store._id}`} />
            </>
          ) : (
            <Typography className="pl-32 pt-8">Self Checkout is currently not available.</Typography>
          )}
        </div>
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">monetization_on</Icon>
        <Typography className="h2 mx-12 font-light" color="textSecondary">
          Store Account Fund
        </Typography>
      </div>
      <div className="mb-24 ml-36">
        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Receiving Fund</Typography>
          <Typography>
            {store.receivingFund?.type?.replace(/_/g, ' ')}: {formatFundValueToString(store.receivingFund?.value)}
          </Typography>
        </div>
        {store.subsidyFund?.value && store.subsidyFund?.type && (
          <div className="mb-16">
            <Typography className="mb-4 text-15 font-semibold">Subsidy Fund</Typography>
            <Typography>
              {store.subsidyFund?.type?.replace(/_/g, ' ')}: {formatFundValueToString(store.subsidyFund?.value)}
            </Typography>
          </div>
        )}
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">explore</Icon>
        <Typography className="h2 mx-12 font-light" color="textSecondary">
          Contact
        </Typography>
      </div>
      <div className="mb-24 ml-36">
        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Address</Typography>
          <Typography>
            {store.address?.split('\n').map((item, index) => (
              <span key={`address_${index}`} className="block">
                {item}
              </span>
            ))}
          </Typography>
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Telephone</Typography>
          <Typography>{store.phone}</Typography>
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Email</Typography>
          <Typography>{store.email}</Typography>
        </div>
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">account_circle</Icon>
        <Typography className="h2 mx-12 font-light" color="textSecondary">
          Owner
        </Typography>
      </div>
      <div className="mb-24 ml-36">
        <Typography>
          {store.inventory?.owner?.firstName} {store.inventory?.owner?.lastName}
        </Typography>

        <Typography color="textSecondary">{store.inventory?.owner?.email}</Typography>
      </div>

      {store?.permissions?.includes(STORE_PERMISSION.MANAGE) && (
        <Tooltip title="Edit" arrow>
          <Fab
            color="primary"
            onClick={() => history.push(`/store/${store._id}/settings/detail/edit`)}
            aria-label="Edit"
            className="absolute bottom-16">
            <Icon>edit</Icon>
          </Fab>
        </Tooltip>
      )}
    </div>
  );
};

SettingDetail.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default SettingDetail;
