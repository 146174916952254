import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Chip, Icon, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import HeaderSearch from '../components/HeaderSearch';
import { getCartCount } from '../helper/cart.helper';
import { FIND_ACCOUNTS_FOR_USER } from '../graphql/query';
import { ErrorMessage } from '../../components/errors';
import { initializeCart } from '../../store/app/shoppingCartSlice';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.paper,
  },
}));

function ShopPage({ route, store }) {
  const cart = useSelector(({ app }) => app.shoppingCart);
  const dispatch = useDispatch();
  const { panel } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const { loading, data, error } = useQuery(FIND_ACCOUNTS_FOR_USER, {
    variables: { storeId: store._id, administeredByLabel: 'INVENTORY', filterEmpty: false },
  });

  const { q } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { control, setValue, getValues } = useForm({ defaultValues: { searchTerm: q || '' } });

  useEffect(() => {
    dispatch(initializeCart({ storeId: store._id }));
  }, [dispatch, store]);

  useEffect(() => {
    setValue('searchTerm', q || '');
  }, [q, setValue]);

  if (loading) {
    return <FuseLoading />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  const accounts = data.findAccountsForUser
    .filter((item) => !!item.account?._id)
    .map((item) => ({ ...item.account, name: item.name }));

  if (accounts.length === 0) {
    return <Redirect to={`/shop/${store._id}/account`} />;
  }

  return (
    <FusePageSimple
      innerScroll
      sidebarInner
      classes={{
        contentWrapper: 'flex',
        content: clsx('flex-1 flex item-center overflow-x-auto ', classes.content),
        header: 'min-h-128 h-128',
        wrapper: 'min-h-0',
      }}
      header={
        <div className="flex flex-1 flex-wrap justify-between p-12 md:p-24 print:hidden">
          <div className="flex w-full flex-col sm:w-auto">
            <div className={clsx('flex items-center', !panel ? 'invisible' : 'visible')}>
              <Typography
                className="flex normal-case sm:mb-4"
                role="button"
                color="inherit"
                onClick={() =>
                  history.push(
                    panel === 'product'
                      ? {
                          pathname: `/shop/${store._id}/search`,
                          search: qs.stringify({ q }, { addQueryPrefix: true }),
                        }
                      : `/shop/${store._id}`,
                  )
                }>
                <Icon className="text-20">arrow_back</Icon>
                <span className="mx-4">{panel === 'product' ? 'Search' : 'Shop'}</span>
              </Typography>
            </div>
            <div className="mt-8 flex items-center space-x-8 sm:mt-0">
              <Icon component="span" className="text-24 md:text-32">
                store
              </Icon>
              <Typography className="mx-12 truncate text-16 sm:text-24">{store.name}</Typography>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-center">
            <HeaderSearch
              name="searchTerm"
              control={control}
              onChange={() => history.push(`/shop/${store._id}/search?q=${getValues('searchTerm')}`)}
              placeholder="Search by name, product number"
            />
          </div>
          {(store.settings.delivery || store.settings.pickup) && (
            <div className="flex w-80 items-center print:hidden">
              <Link to={`/shop/${store._id}/cart`} style={{ textDecoration: 'none', color: 'white' }}>
                <Icon className="text-3xl">shopping_cart</Icon>
                <Chip
                  size="small"
                  color="secondary"
                  label={getCartCount(cart[store._id] || { items: [] })}
                  className="-mt-40"
                />
              </Link>
            </div>
          )}
        </div>
      }
      content={<>{renderRoutes(route.routes, { accounts, store })}</>}
    />
  );
}

ShopPage.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShopPage;
