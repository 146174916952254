import { useMutation } from '@apollo/client';
import { Button, DialogContentText, FormHelperText, Grid, Icon, TextField } from '@material-ui/core';
import { RELATIONSHIP_LABEL, RELATIONSHIP_SOURCE } from '@rss/common';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { object, string } from 'yup';

import { UPSERT_BUCKET } from '../graphql/relationship.query';
import { PROFILE_QUERY } from '../graphql/profile.query';
import { getDisplayName, inlineErrorMessages, mapYupValidationErrorToErrorMap } from '../shared/helper';
import DomainSelect from './DomainSelect';
import DialogTemplate from './DialogTemplate';
import FqMultiSelect from './flexible-questionnaire/FqMultiSelect';

const {
  NODE: { BUCKET },
} = RELATIONSHIP_LABEL;

const BUCKET_SCOPE = object().shape({
  id: string().required().label('Bucket scope'),
  label: string(),
});

const BUCKET_SCHEMA = object().shape({
  id: string(),
  name: string().required().label('Name').max(250),
  description: string().label('Description').max(500),
  type: string().required().label('Type'),
});

const BUCKET_INPUT_SCHEMA = object().shape({
  scope: BUCKET_SCOPE,
  bucket: BUCKET_SCHEMA,
});

const ManageBucketDialog = ({
  closeDialog,
  description,
  scopeType,
  isOpen,
  onSaveComplete,
  title,
  tenantCode,
  campusCode,
  bucketInput,
}) => {
  const orgLabel = BUCKET;

  const [errors, setErrors] = useState(null);
  const [updatedBucket, setUpdatedBucket] = useState(bucketInput);

  const orgTypeDisplayName = getDisplayName(orgLabel);

  const getRefetchQueries = () => {
    return [{ query: PROFILE_QUERY }];
  };

  const clear = () => {
    setUpdatedBucket({});
    setErrors(null);
    closeDialog();
  };

  const [upsertBucket, { loading }] = useMutation(UPSERT_BUCKET, {
    refetchQueries: getRefetchQueries,
    onError: (error) => {
      if (/INACTIVE_BUCKET_EXISTS/.test(error.message)) {
        return setErrors({
          'bucket.name': [
            <span>
              The Bucket Name you entered was previously active. You must enter a unique bucket name. For assistance
              reactivating the existing bucket contact the{' '}
              <a href="mailto:service@RiskandSafetySolutions.com">Service Desk</a>.
            </span>,
          ],
        });
      }

      if (/BUCKET_EXISTS/.test(error.message)) {
        return setErrors({
          'bucket.name': ['The Bucket Name you entered already exists. You must enter a unique bucket name.'],
        });
      }

      return setErrors({
        server: (
          <>
            <Icon className="mr-5">warning</Icon>An error has occured while saving the {orgTypeDisplayName}.
          </>
        ),
      });
    },
    onCompleted: (data) => {
      clear();
      onSaveComplete(data.upsertBucket);
    },
  });

  const isFormValid = async () => {
    setErrors(null);
    try {
      await BUCKET_INPUT_SCHEMA.validate(updatedBucket, { abortEarly: false });
      setErrors(null);
      return true;
    } catch (err) {
      setErrors(mapYupValidationErrorToErrorMap(err));
      return false;
    }
  };

  return (
    <DialogTemplate
      open={isOpen}
      title={title}
      loading={loading}
      contentScrollable
      content={
        <div className="space-y-16">
          {description && <DialogContentText className="whitespace-pre-wrap">{description}</DialogContentText>}

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              {scopeType === 'DOMAIN' && (
                <DomainSelect
                  autoFocus
                  tenantCode={tenantCode}
                  campusCode={campusCode}
                  value={updatedBucket?.scope?.id ?? ''}
                  variant={campusCode ? 'domain' : 'campus'}
                  required
                  onSelect={(e) =>
                    setUpdatedBucket({
                      ...updatedBucket,
                      scope: { id: e.domainId, label: scopeType },
                    })
                  }
                  errors={errors?.['scope.id'] || []}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="bucket-name"
                label={`Name`}
                type="input"
                variant="outlined"
                autoComplete="off"
                fullWidth
                required
                value={updatedBucket?.bucket?.name || ''}
                onChange={(event) =>
                  setUpdatedBucket({
                    ...updatedBucket,
                    bucket: { ...updatedBucket?.bucket, name: event.target.value },
                  })
                }
                inputProps={{ 'aria-label': `Enter ${orgTypeDisplayName} Name` }}
                error={!!errors?.['bucket.name']?.length}
                helperText={inlineErrorMessages(errors?.['bucket.name'])}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="bucket-description"
                label={`Description`}
                type="input"
                variant="outlined"
                autoComplete="off"
                fullWidth
                value={updatedBucket?.bucket?.description || ''}
                onChange={(event) =>
                  setUpdatedBucket({
                    ...updatedBucket,
                    bucket: { ...updatedBucket?.bucket, description: event.target.value },
                  })
                }
                inputProps={{ 'aria-label': `Enter ${orgTypeDisplayName} Description` }}
                error={!!errors?.['bucket.description']?.length}
                helperText={inlineErrorMessages(errors?.['bucket.description'])}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="-m-8">
                <FqMultiSelect
                  label={'Type'}
                  limit={1}
                  value={updatedBucket?.bucket?.type ? [updatedBucket.bucket.type] : null}
                  onChange={(e) =>
                    setUpdatedBucket({
                      ...updatedBucket,
                      bucket: { ...updatedBucket?.bucket, type: e?.[0] },
                    })
                  }
                  options={['COHORT']}
                  placeholder="Select Type"
                  valueType={'string'}
                  questionKey={'type'}
                  variant="compact"
                  disabled={false}
                  error={!!errors?.['bucket.type']?.length}
                />
                {!isEmpty(errors?.['bucket.type']) && (
                  <FormHelperText error className="-mt-4 pl-24">
                    {inlineErrorMessages(errors?.['bucket.type'])}
                  </FormHelperText>
                )}
              </div>
            </Grid>
            {errors && errors?.server && (
              <Grid item xs={12}>
                <div className="flex items-center text-danger-800">{errors?.server}</div>
              </Grid>
            )}
          </Grid>
        </div>
      }
      primaryActions={
        <>
          <Button variant="contained" disabled={loading} onClick={clear}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={async (event) => {
              event.preventDefault();
              if (await isFormValid()) {
                upsertBucket({ variables: updatedBucket });
              }
            }}>
            Create
          </Button>
        </>
      }
    />
  );
};

ManageBucketDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onSaveComplete: PropTypes.func,
  closeDialog: PropTypes.func.isRequired,
  scopeType: PropTypes.string.isRequired,
  tenantCode: PropTypes.string,
  campusCode: PropTypes.string,
  bucketInput: PropTypes.shape({
    scope: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    }),
    bucket: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string,
      source: PropTypes.string,
    }),
  }),
};

ManageBucketDialog.defaultProps = {
  description: null,
  campusCode: null,
  onSaveComplete: () => {},
};

export default ManageBucketDialog;
