const {
  ObjectIdSchema
} = require('@rss/common');
const yup = require('yup');
module.exports = yup.object().shape({
  _id: new ObjectIdSchema(),
  name: yup.string().trim().min(1, 'Name is Required').required(),
  campusCode: yup.string().required(),
  tenantCode: yup.string(),
  stockroom: yup.bool().default(false).required()
});