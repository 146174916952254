"use strict";

module.exports = {
  DONE: 'DONE',
  DONE_FOR_RP: 'DONE_FOR_RP',
  MERGE_REPORT: 'MERGE_REPORT',
  NIGHTLY_EMAIL: 'NIGHTLY_EMAIL',
  READY_FOR_VERIFICATION_EMAIL: 'READY_FOR_VERIFICATION_EMAIL',
  RESEND_TO_RP: 'RESEND_TO_RP',
  ROLLBACK: 'ROLLBACK',
  SEND_PUBLIC_REPORT: 'SEND_PUBLIC_REPORT',
  SEND_TO_RP: 'SEND_TO_RP',
  SUBMIT_FOR_REVIEW: 'SUBMIT_FOR_REVIEW'
};