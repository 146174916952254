const { ObjectId } = require('bson');
const tv4 = require('tv4');
const { PersonReference } = require('@rss/common');

const ASSESSMENT_STATUS = require('../constants/assessment-status.constant');
const AssessmentApplicationWindow = require('./assessment.application-window');

class Assessment {
  constructor(opt) {
    this._id = null;
    this.templateId = null;
    this.seriesId = null;
    this.name = null;
    this.campusCode = null;
    this.status = null;
    this.comments = null;
    this.createdDate = null;
    this.createdBy = null;
    this.lastUpdatedDate = null;
    this.lastUpdatedBy = null;
    this.privateComments = null;
    this.responses = null;
    this.events = [];
    this.internalSectionResponses = null;
    this.sectionsEdited = [];
    this.report = null;
    this.expirationDate = null;
    this.rosterResponses = null;
    this.locationResponses = null;
    this.latest = true;
    this.applicationWindow = null;

    this.fillObject(opt);
  }

  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.templateId = opt.templateId;
    this.seriesId = new ObjectId(opt.seriesId);
    this.name = opt.name;
    this.campusCode = opt.campusCode;
    this.status = opt.status || null;
    this.campusCode = opt.campusCode;
    this.comments = opt.comments || null;
    this.createdDate = opt.createdDate ? new Date(opt.createdDate) : new Date();
    this.createdBy = new PersonReference(opt.createdBy);
    this.lastUpdatedDate = new Date(opt.lastUpdatedDate) || null;
    this.lastUpdatedBy = opt.lastUpdatedBy;
    this.privateComments = opt.privateComments || null;
    this.responses = opt.responses || null;
    this.events = opt.events || [];
    this.internalSectionResponses = opt.internalSectionResponses || null;
    this.sectionsEdited = opt.sectionsEdited || [];
    this.report = opt.report || null;
    this.voucher = opt.voucher || null;
    this.expirationDate = opt.expirationDate ? new Date(opt.expirationDate) : null;
    this.rosterResponses = opt.rosterResponses || null;
    this.locationResponses = opt.locationResponses || null;
    this.latest = opt.latest || false;
    this.applicationWindow = new AssessmentApplicationWindow(opt.applicationWindow);
  }

  validate() {
    return tv4.validateMultiple(this, Assessment.schema());
  }

  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'assessment.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        _id: { type: 'object' },
        templateId: { type: 'string' },
        seriesId: { type: 'object' },
        name: { type: 'string' },
        campusCode: { type: 'string' },
        status: { enum: [...Object.keys(ASSESSMENT_STATUS), null] },
        comments: { type: ['object', 'null'] },
        createdDate: { type: 'object' },
        createdBy: { $ref: 'person-reference.schema.json' },
        lastUpdatedDate: { type: 'object' },
        lastUpdatedBy: { type: 'object' },
        privateComments: { type: ['object', 'null'] },
        responses: { type: ['object', 'null'] },
        internalSectionResponses: { type: ['object', 'null'] },
        sectionsEdited: { type: ['array', 'null'] },
        report: { type: ['object', 'null'] },
        voucher: { type: ['array', 'null'] },
        events: { type: ['array', 'null'] },
        reportCategoryDescription: { type: ['object'] },
        expirationDate: { type: ['object', 'null'] },
        rosterResponses: { type: ['object', 'null'] },
        locationResponses: { type: ['object', 'null'] },
        latest: { type: 'boolean' },
        applicationWindow: { type: ['null', 'object'] },
      },
      required: [
        '_id',
        'templateId',
        'seriesId',
        'name',
        'campusCode',
        'status',
        'createdDate',
        'createdBy',
        'lastUpdatedDate',
        'lastUpdatedBy',
      ],
    };
  }
}

tv4.addSchema(Assessment.schema());
tv4.addSchema(PersonReference.schema());

module.exports = Assessment;
