/* eslint-disable react/jsx-props-no-spreading */
import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import gql from 'graphql-tag';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { QUESTION_VARIANT } from '../flexible-questionnaire/Helper';
import Search from '../Search';
import LibraryTagSearch from './LibraryTagSearch';

const LIBRARY_ITEMS_BY_TAGS = gql`
  query libraryItemsByTags($catalog: CatalogType!, $tags: [String], $terms: String) {
    libraryItemsByTags(catalog: $catalog, tags: $tags, terms: $terms) {
      _id
      name
      catalog
      description
      details
      tags
    }
  }
`;

const LibraryTypeSearch = ({
  floatingText,
  helperText,
  hintText,
  id,
  label,
  value,
  followup,
  followupLabel,
  onSelect,
  libraryType,
  showTagFilter,
  tagLabel,
  disabled,
  variant,
}) => {
  const selectId = `${id}-select`;
  const [selectedTag, setSelectedTag] = useState('ALL');
  const [libraryItems, setLibraryItems] = useState([]);

  const [libraryItemsByTags, { called, data, loading }] = useLazyQuery(LIBRARY_ITEMS_BY_TAGS);

  useEffect(() => {
    if (called && data && data?.libraryItemsByTags) {
      setLibraryItems(
        data?.libraryItemsByTags?.map((libraryItem) => ({
          key: libraryItem._id,
          ...libraryItem,
        })) || [],
      );
    }
  }, [called, data, setLibraryItems]);

  const handleSelected = (val) => {
    setLibraryItems([]);
    onSelect(val);
  };

  return (
    <div
      data-cy="library_type_search"
      autoComplete="off"
      className="flex-end mx-8 flex flex-wrap"
      aria-labelledby={selectId}
      id={selectId}>
      {showTagFilter && (
        <div className="w-full p-8 sm:w-1/4">
          <LibraryTagSearch
            catalog={libraryType}
            tagLabel={tagLabel}
            onSelect={(tag) => setSelectedTag(tag)}
            selectedTag={selectedTag}
            disabled={disabled}
          />
        </div>
      )}

      <div className={clsx('w-full', showTagFilter && 'p-8 sm:w-3/4')}>
        <Search
          disableUnderline={false}
          id={id}
          variant="outlined"
          label={variant === QUESTION_VARIANT.STANDARD ? label || floatingText : ''}
          autocomplete
          isLoading={loading}
          placeholder={hintText}
          helperText={helperText}
          itemToString={(libraryItem) => (libraryItem ? `${libraryItem.name} - ${get(libraryItem, followup)}` : '')}
          onSelect={(val) => handleSelected(val)}
          value={value}
          clearOnSelect={!!followup}
          disabled={disabled}
          onChange={({ inputValue }) => {
            libraryItemsByTags({
              variables: {
                terms: inputValue,
                catalog: libraryType,
                tags: selectedTag === 'ALL' ? [] : [selectedTag],
              },
              skip: !inputValue.length >= 2 && !libraryType,
            });
          }}
          items={libraryItems}
          renderItem={(libraryItem) => (
            <Typography variant="body1">{`${libraryItem.name} - ${followupLabel}: ${get(
              libraryItem,
              followup,
            )}`}</Typography>
          )}
        />
      </div>
    </div>
  );
};

LibraryTypeSearch.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  tagLabel: PropTypes.string,
  floatingText: PropTypes.string,
  onSelect: PropTypes.func,
  hintText: PropTypes.string,
  helperText: PropTypes.string,
  libraryType: PropTypes.string,
  followup: PropTypes.string,
  followupLabel: PropTypes.string,
  disabled: PropTypes.bool,
  showTagFilter: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

LibraryTypeSearch.defaultProps = {
  id: 'library-type-search',
  value: '',
  label: '',
  tagLabel: 'Select',
  floatingText: 'Search Items',
  helperText: '',
  hintText: '',
  onSelect: noop,
  libraryType: '',
  followup: '',
  followupLabel: 'Type',
  disabled: false,
  showTagFilter: true,
  variant: QUESTION_VARIANT.STANDARD,
};

export default LibraryTypeSearch;
