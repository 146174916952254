import { useQuery } from '@apollo/client';
import BRANDING_QUERY from '../graphql/branding.query';

export default function useBranding() {
  const { loading, error, data } = useQuery(BRANDING_QUERY, { notifyOnNetworkStatusChange: true });

  return {
    loading,
    error,
    branding: data?.branding,
  };
}
