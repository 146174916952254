import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { startCase } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = ({ color, variant }) =>
  makeStyles((theme) => {
    const variantColor = `${variant}${color === 'default' ? '' : startCase(color)}`;
    const backgroundColor = theme.overrides?.MuiButton?.[variantColor]?.backgroundColor || theme.palette?.[color]?.main;
    const contrastText =
      theme.overrides?.MuiButton?.[variantColor]?.color ||
      theme.palette?.[color]?.contrastText ||
      theme.palette.common.black;

    return {
      button: {
        '&$disabled': {
          backgroundColor,
        },
      },
      disabled: {},
      progress: {
        color: contrastText,
      },
    };
  });

export default function ButtonProgress({ children, classes, className, color, disabled, loading, variant, ...props }) {
  const localClasses = useStyles({ color, variant })();
  const buttonClasses = loading ? { ...classes, root: localClasses.button, disabled: localClasses.disabled } : classes;
  return (
    <div className="flex items-center">
      <div className="relative m-1 flex-grow">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Button
          variant={variant}
          color={color}
          disabled={disabled || loading}
          classes={buttonClasses}
          className={className}
          {...props}>
          <div className={clsx(loading && 'invisible')}>{children}</div>
        </Button>
        {loading && (
          <CircularProgress size={24} className={clsx(localClasses.progress, 'absolute inset-1/2 -ml-12 -mt-12')} />
        )}
      </div>
    </div>
  );
}

ButtonProgress.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.objectOf(PropTypes.any), // will be deprecated after transition to classname.
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};

ButtonProgress.defaultProps = {
  classes: {},
  className: '',
  color: 'primary',
  disabled: false,
  loading: false,
  variant: 'contained',
};
