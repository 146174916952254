/* eslint-disable max-len */
import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectIdSchema } from '@rss/common';
import { FundReferenceSchema } from '@risk-and-safety/library';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { ObjectId } from 'bson';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';

import { ADD_FUND, UPDATE_FUND, VALIDATE_FUND } from '../graphql/query';
import { ButtonProgress } from '../../components';
import FundValueEdit from './FundValueEdit';

function AccountFund({ store, account }) {
  const fundTypes = store.settings.fund?.types || [];
  const { fundId } = useParams();
  const history = useHistory();
  const { state } = useLocation();

  const { url: accountLink } = useRouteMatch(['/shop/:storeId/account', '/store/:storeId/account']);

  const navigateBack = () => history.push(`${accountLink}/${account._id}`, state);

  const { refetch } = useQuery(VALIDATE_FUND, {
    skip: true,
    fetchPolicy: 'network-only',
  });

  const [addStoreAccountFund, { loading: addLoading }] = useMutation(ADD_FUND, {
    onCompleted: navigateBack,
  });

  const [updateStoreAccountFund, { loading: updateLoading }] = useMutation(UPDATE_FUND, {
    onCompleted: navigateBack,
  });

  const validateFund = async (context, form) => {
    const isFormValid = await yup
      .object({ value: FundReferenceSchema.ValueSchema(form.type || fundTypes[0]) })
      .isValid({ ...form });
    if (!isFormValid) {
      return true;
    }

    const res = await refetch({ ...form });
    if (res?.data?.validateFund.valid) {
      return true;
    }
    return context.createError({
      message: res?.data?.validateFund.error,
      path: context.path,
    });
  };

  const fund = account.funds.find((item) => item.id === fundId);
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      id: fund?.id || new ObjectId().toString(),
      type: fund?.type || store.settings.fund.types[0],
      name: fund?.name || '',
      value: fund?.value || {},
      validator: fund?.validator || store.settings.fund.validator,
      default: fund?.default || false,
    },
    resolver: yupResolver(
      yup.object({
        id: new ObjectIdSchema().required(),
        type: yup.string().required(),
        name: yup.string().required('Name is a required field'),
        value: yup.object().when('type', (type) => {
          return FundReferenceSchema.ValueSchema(type || fundTypes[0]).test(
            'validate-fund',
            null,
            async (value, context) => validateFund(context, getValues()),
          );
        }),
        default: yup.boolean().default(false),
      }),
    ),
  });

  const onSave = async (value) => {
    if (fund) {
      updateStoreAccountFund({ variables: { id: account._id, fund: value, storeId: store?._id } });
    } else {
      addStoreAccountFund({ variables: { id: account._id, fund: value, storeId: store?._id } });
    }
  };

  return (
    <div className="flex-grow pt-3">
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSave)} className="p-24">
        <div className="absolute left-48 top-136 flex h-64 items-center">
          <IconButton onClick={navigateBack}>
            <Icon>arrow_back</Icon>
          </IconButton>
        </div>
        <div className="absolute right-48 top-136 flex h-64 items-center">
          <ButtonProgress loading={addLoading || updateLoading} type="submit" variant="contained" color="primary">
            Save
          </ButtonProgress>
        </div>
        <div className="flex items-center">
          <Typography className="mb-16 text-20 font-medium" color="textSecondary">
            Fund
          </Typography>
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-8 mt-8"
              label="Name"
              variant="outlined"
              fullWidth
              required
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <FormControl className="mb-16 mt-8 w-full" variant="outlined" required error={errors?.catalog}>
              <InputLabel id="type-label">Type</InputLabel>
              <Select {...field} labelId="type-label" label="Type">
                {store.settings.fund.types.map((type) => (
                  <MenuItem value={type} key={type}>
                    {startCase(type.toLowerCase())}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {watch('type') && (
          <Controller
            name="value"
            control={control}
            render={({ field }) => {
              const fundValue = getValues();
              return (
                <>
                  <FundValueEdit
                    value={fundValue.value}
                    type={fundValue.type || fundTypes[0]}
                    field={field}
                    errors={errors}
                  />
                  {errors?.value && errors?.value?.type === 'validate-fund' && (
                    <FormHelperText className="text-red-500">
                      {errors?.value?.message || 'Fund is not valid'}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        )}

        <Controller
          name="default"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
              label="Default"
            />
          )}
        />
      </form>
    </div>
  );
}

AccountFund.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccountFund;
