import { useMutation } from '@apollo/client';
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogActions,
  Icon,
  IconButton,
  Toolbar,
  Typography,
  TextField,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { string } from 'yup';

import { ButtonProgress } from '../../../components';
import { GET_RESOLUTIONS, UPDATE_TRACKING_NUMBER } from '../../graphql/query';

const trackingNumberSchema = string().required();

function TrackingNumberDialog({ reportId, resolutionId, trackingNumber: originalTrackingNumber, onClose }) {
  const [errors, setErrors] = useState({});
  const [trackingNumber, setTrackingNumber] = useState(originalTrackingNumber || '');

  const [updateTrackingNumber, { error, loading }] = useMutation(UPDATE_TRACKING_NUMBER, {
    refetchQueries: [{ query: GET_RESOLUTIONS, variables: { reportId } }],
    onError: () => setErrors({ ...errors, error }),
    onCompleted: onClose,
  });

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <AppBar position="static" className="mb-12 rounded-t-2xl">
        <Toolbar className="flex w-full justify-between">
          <Typography variant="h6" color="inherit">
            Tracking Number
          </Typography>

          <IconButton color="inherit" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>

      <form
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          try {
            const validTrackingNumber = trackingNumberSchema.validateSync(trackingNumber);
            updateTrackingNumber({
              variables: {
                resolutionId,
                reportId,
                trackingNumber: validTrackingNumber,
              },
            });
          } catch (err) {
            setErrors({ trackingNumber: 'Please enter a tracking number' });
          }
        }}>
        <DialogContent classes={{ root: 'px-16' }}>
          <TextField
            variant="outlined"
            id="tracking-number"
            name="tracking-number"
            label="Tracking Number"
            value={trackingNumber}
            error={errors?.trackingNumber}
            helperText={errors?.trackingNumber}
            autoFocus
            onChange={(event) => {
              if (!isEmpty(errors)) {
                setErrors({});
              }

              setTrackingNumber(event.target.value);
            }}
            fullWidth
          />
        </DialogContent>

        <DialogActions className="flex-end p-16">
          <ButtonProgress type="submit" loading={loading}>
            Save
          </ButtonProgress>
        </DialogActions>
      </form>
      {!isEmpty(errors) && (
        <Typography className="mx-16 mb-16" color="error">
          Unable to update the tracking number.
        </Typography>
      )}
    </Dialog>
  );
}

TrackingNumberDialog.propTypes = {
  reportId: PropTypes.string.isRequired,
  resolutionId: PropTypes.string.isRequired,
  trackingNumber: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

TrackingNumberDialog.defaultProps = {
  trackingNumber: '',
};

export default TrackingNumberDialog;
