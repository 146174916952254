import { inspectPublicRoutes } from '../inspect/routes';
import { procedurePublicRoutes } from '../procedure/routes';
import { storePublicRoutes } from '../marketplace/routes';

const routes = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [...inspectPublicRoutes, ...procedurePublicRoutes, ...storePublicRoutes],
};

export default routes;
