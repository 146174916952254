import { useMutation, useQuery } from '@apollo/client';
import {
  Chip,
  Fade,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Order } from '@risk-and-safety/library';
import { SHORT_DATE_FORMAT } from '@rss/common';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BarcodeInput from './BarcodeInput';
import { ITEM_BY_BARCODE_OR_SKU, FULFILL_ORDER, GET_ORDERS, TRANSACTIONS } from '../graphql/query';
import { getCartCount } from '../helper/cart.helper';
import { BARCODE_SCAN_ERROR } from '../helper/order.helper';
import { ButtonProgress } from '../../components';

function OrderFulfill({ store, order }) {
  const history = useHistory();

  const [items, setItems] = useState([]);
  const [invalidItems, setInvalidItems] = useState([]);
  const [barcode, setBarcode] = useState(null);
  const [notes, setNotes] = useState('')

  const navigateBack = () => history.push(`/store/${store._id}/order/${order._id}`);

  const { data, loading } = useQuery(ITEM_BY_BARCODE_OR_SKU, {
    variables: { barcode, inventoryId: store.inventoryId, storeId: store._id },
    skip: !barcode,
    fetchPolicy: 'network-only',
    onError: (err) => {
      setInvalidItems([...new Set([...invalidItems, { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.NOT_FOUND }])]);
    },
  });

  useEffect(() => {
    if (data && barcode && !loading) {
      const { inventoryItemByBarcode, productBySku, inventoryItemWithoutBarcode } = data;

      const searchProductId = inventoryItemByBarcode?.product?._id || productBySku?._id;

      const scannedIds = items?.map((i) => i._id);

      const unscannedItems = inventoryItemWithoutBarcode?.filter((u) => !scannedIds.includes(u._id));

      const firstElem = unscannedItems[0];
      let itemWithoutBarcode =
        productBySku?.barcodeRequired === false
          ? { ...firstElem, sku: productBySku.sku }
          : { product: productBySku, _id: productBySku._id, barcode: productBySku.sku };

      const item = inventoryItemByBarcode?.product?._id ? inventoryItemByBarcode : itemWithoutBarcode;
      if (!item) {
        setInvalidItems([...invalidItems, { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.NOT_FOUND }]);
        setBarcode(null);
        return;
      }

      const productOnOrder = order.items.find((i) => i._id === searchProductId);
      if (!productOnOrder) {
        setInvalidItems([...invalidItems, { ...item, error: BARCODE_SCAN_ERROR.WRONG_PRODUCT }]);
        setBarcode(null);
        return;
      }
      const scannedProductItems = items.filter((i) => i.product?._id === productOnOrder._id);

      if (scannedProductItems.length === productOnOrder.quantity) {
        setInvalidItems([...invalidItems, { ...inventoryItemByBarcode, error: BARCODE_SCAN_ERROR.ENOUGH }]);
        setBarcode(null);
        return;
      }
      setItems([...items, item]);
      setBarcode(null);
    }
  }, [barcode, data, invalidItems, items, order, setInvalidItems, setItems, setBarcode, loading]);

  const [fulfillOrder, { loading: fulfillOrderLoading }] = useMutation(FULFILL_ORDER, {
    onCompleted: navigateBack,
    refetchQueries: [
      {
        query: TRANSACTIONS,
        variables: {
          storeId: store._id,
          cursor: '',
          startDate: format(new Date(new Date().setDate(1)), SHORT_DATE_FORMAT),
          endDate: format(new Date(new Date().setDate(new Date().getDate() + 1)), SHORT_DATE_FORMAT),
        },
      },
      {
        query: GET_ORDERS,
        variables: {
          createdByUserId: '',
          fromDate: '',
          toDate: '',
          myOrders: false,
          orderId: '',
          status: [],
          storeId: store._id,
        },
      },
    ],
  });

  return (
    <div className="mt-64 flex flex-grow px-28">
      <div className="flex-grow">
        <div className="absolute left-48 top-136 flex h-64 items-center">
          <IconButton onClick={navigateBack}>
            <Icon>arrow_back</Icon>
          </IconButton>
        </div>

        <div className="flex items-center justify-between pb-8">
          <Typography className="inline">Scan items to fulfill this order</Typography>
          <div className="inline">
            <Typography color="primary" className="inline pr-12 text-28">
              {items.length} / {getCartCount(order)}
            </Typography>
            <Typography color="primary" className="inline text-16">
              items fulfilled
            </Typography>
          </div>
        </div>

        <BarcodeInput
          campusCode={store.campusCode}
          placeholder="Scan items matching matching products that have been ordered"
          onValidBarcode={(result) => {
            if (
              invalidItems.some((i) => i.barcode === result) ||
              items.some((i) => (i.product.sku ? false : i.barcode === result))
            ) {
              setInvalidItems([...invalidItems, { _id: result, barcode: result, error: BARCODE_SCAN_ERROR.DUPLICATE }]);
            } else {
              setBarcode(result);
            }
          }}
        />

        {invalidItems.map((err) => (
          <Fade in key={err._id}>
            <Alert
              severity="error"
              onClose={() => setInvalidItems(invalidItems.filter((i) => i._id !== err._id))}
              className="mb-2">
              {err.error} - {err.barcode}
            </Alert>
          </Fade>
        ))}

        <Typography className="mx-12 pt-20 font-light" variant="h5" color="textSecondary">
          Order Detail
        </Typography>

        <Table className="simple">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="center" className="w-96">
                QUANTITY
              </TableCell>
              <TableCell align="center" className="w-160">
                ITEMS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item) => {
              const productItems = items.filter(
                (i) => i.product?._id === item._id || i.product?._id === item.product?._id,
              );
              return (
                <TableRow key={item._id}>
                  <TableCell className="flex">
                    {productItems.length === item.quantity ? (
                      <Icon className="text-success-500">check_circle</Icon>
                    ) : (
                      <div className="mr-24" />
                    )}
                    <div className="ml-12">
                      <Typography>{item.product.name}</Typography>
                      {item.product?.sku && <Typography>Scan code: {item.product?.sku}</Typography>}
                      <Typography variant="caption" color="textSecondary">
                        {item.product.description}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {productItems.length}/{item.quantity}
                  </TableCell>
                  <TableCell align="right">
                    {productItems.map((i) => (
                      <Chip
                        key={i._id}
                        label={i.barcode || i.sku}
                        className="mb-6"
                        onDelete={() => setItems(items.filter((it) => it._id !== i._id))}
                      />
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Typography className="mx-12 pt-20 font-light" variant="h5" color="textSecondary">
          Notes
        </Typography>

        <div className="mx-12">
          <TextField
            value={notes || ''}
            inputProps={{ maxLength: 50 }}
            fullWidth
            onChange={({ target: { value } }) => setNotes(value)}
            helperText={`${notes.length} / 50 characters`}
          />
        </div>
      </div>
      <div className="ml-32 flex flex-col items-end justify-start space-y-20 border-l-1 border-dashed pl-32">
        <ButtonProgress
          loading={fulfillOrderLoading}
          variant="contained"
          color="primary"
          size="large"
          disabled={items.length !== getCartCount(order)}
          onClick={() => fulfillOrder({ variables: { storeId: store._id, orderId: order._id, items, notes } })}
          type="submit"
          className="w-200">
          Fulfill Order
        </ButtonProgress>
      </div>
    </div>
  );
}

OrderFulfill.propTypes = {
  order: PropTypes.shape(Order).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderFulfill;
