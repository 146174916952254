import { useQuery } from '@apollo/client';
import { chain, isEqual, sortBy, uniqWith } from 'lodash';
import gql from 'graphql-tag';

export const FIND_COURSES_BY_CAMPUS = gql`
  query findCoursesByCampus($campusCode: String!) {
    findCoursesByCampus(campusCode: $campusCode) {
      activityCode
      activityName
      classificationName
    }
  }
`;

const getCoursesInitialState = (courses) => {
  let data = {};
  courses.forEach((c) => {
    data = { ...data, [c.activityCode]: 'none' };
  });
  return data;
};

export default function useCampusCourses(campusCode) {
  const { data, loading, error } = useQuery(FIND_COURSES_BY_CAMPUS, {
    variables: { campusCode },
  });

  const campusCourses = sortBy(data?.findCoursesByCampus || [], ['classificationName', 'activityName']);

  const coursesForFilter = uniqWith(
    campusCourses.map((course) => ({
      value: course.activityCode,
      label: course.activityName,
      classificationName: course.classificationName,
    })),
    isEqual,
  );

  const classificationsForFilter = chain(campusCourses)
    .map((course) => course.classificationName)
    .uniq()
    .orderBy((classification) => classification, 'asc')
    .map((classification) => ({ value: classification, label: classification }))
    .value();

  const coursesInitialState = getCoursesInitialState(campusCourses);

  return {
    campusCourses,
    coursesForFilter,
    classificationsForFilter,
    coursesInitialState,
    loading,
    error,
  };
}
