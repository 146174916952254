import gql from 'graphql-tag';

export const SAVE_ARTICLE_FEEDBACK = gql`
  mutation saveArticleFeedBack($feedbackInput: FeedbackInput!) {
    saveArticleFeedBack(feedbackInput: $feedbackInput) {
      result {
        sys_id
        useful
        comments
      }
    }
  }
`;
