import gql from 'graphql-tag';

export const FIND_GROUPS_INVENTORIES = gql`
  query getGroupsForInventories($inventories: [String]!) {
    getGroupsForInventories(inventories: $inventories)
  }
`;

export const FIND_RELATED_PEOPLE = gql`
  query relatedPeople {
    relatedPeople {
      _id
      type
      label
    }
  }
`;

export const FIND_RELATED_PEOPLE_NEPTUNE = gql`
  query relatedPeopleNeptune {
    relatedPeopleNeptune {
      id
      firstName
      lastName
      email
    }
  }
`;

export const FIND_PEOPLE_WITH_ROLE = gql`
  query findPeopleWithRole($to: String!, $edgeLabel: [String], $page: Int) {
    findPeopleWithRole(to: $to, edgeLabel: $edgeLabel, page: $page) {
      node
      edge
    }
  }
`;

export const FIND_ADJACENT_TO = gql`
  query findAdjacentTo($to: String!, $fromLabel: String, $fromProperties: String, $edgeLabel: [String], $page: Int) {
    findAdjacentTo(
      to: $to
      fromLabel: $fromLabel
      fromProperties: $fromProperties
      edgeLabel: $edgeLabel
      page: $page
    ) {
      node
      edge
    }
  }
`;

export const SEARCH_BUILDINGS_NEPTUNE = gql`
  query searchBuildingsNeptune($campusCode: String!, $searchTerm: String, $maxResults: Int) {
    searchBuildingsNeptune(campusCode: $campusCode, searchTerm: $searchTerm, maxResults: $maxResults) {
      address
      city
      state
      postalCode
      county
      buildingId
      primaryName
    }
  }
`;

export const SEARCH_ROOMS_NEPTUNE = gql`
  query searchRoomsNeptune($buildingId: String!, $searchTerm: String, $maxResults: Int) {
    searchRoomsNeptune(buildingId: $buildingId, searchTerm: $searchTerm, maxResults: $maxResults) {
      roomId
      roomNumber
      floor {
        id
        name
      }
    }
  }
`;

export const FIND_ROOM_BY_ID = gql`
  query findRoomDetailById($roomId: String!) {
    findRoomDetailById(roomId: $roomId) {
      id
      name
      roomNumber
      roomKey
      size
      source
      campusCode
      active
      floor {
        id
        name
        floorKey
      }
      building {
        id
        name
        buildingKey
      }
      mergedRoom {
        roomId
        floorId
        buildingId
        mergedDate
        mergedBy
      }
    }
  }
`;

export const SEARCH_ORGANIZATIONS = gql`
  query searchOrganizations(
    $ownerId: String
    $buildingId: String
    $districtId: String
    $facilityType: String
    $campusCode: String!
    $to: String
    $organizationType: String!
    $userId: String
    $delegateId: String
    $page: Int
    $displayCount: Int
    $searchTerm: String
    $sort: [OrganizationSort]
    $excludeTags: Boolean
    $status: [String]
  ) {
    searchOrganizations(
      ownerId: $ownerId
      buildingId: $buildingId
      districtId: $districtId
      facilityType: $facilityType
      campusCode: $campusCode
      to: $to
      organizationType: $organizationType
      userId: $userId
      delegateId: $delegateId
      page: $page
      displayCount: $displayCount
      searchTerm: $searchTerm
      sort: $sort
      excludeTags: $excludeTags
      status: $status
    )
  }
`;

export const SEARCH_DEPARTMENTS = gql`
  query searchDepartments($searchTerm: String) {
    searchDepartments(searchTerm: $searchTerm)
  }
`;

export const FIND_BUILDING_DETAILS_BY_ID_NEPTUNE = gql`
  query findBuildingDetailsById($buildingId: String!) {
    findBuildingDetailsById(buildingId: $buildingId) {
      floors {
        floorId
        floorName
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($nodes: [JSON]!, $edges: [JSON]!) {
    createOrganization(nodes: $nodes, edges: $edges)
  }
`;

export const UPSERT_ORGANIZATION = gql`
  mutation upsertOrganization($node: JSON!) {
    upsertOrganization(node: $node)
  }
`;

export const UPSERT_ROUTING_LIST = gql`
  mutation upsertRoutingList($campusCode: String!, $domainId: String!, $name: String!, $id: String) {
    upsertRoutingList(campusCode: $campusCode, domainId: $domainId, name: $name, id: $id)
  }
`;

export const UPSERT_ROUTING_GROUP = gql`
  mutation upsertRoutingGroup(
    $campusCode: String!
    $domainId: String!
    $routingListId: String!
    $name: String!
    $id: String
  ) {
    upsertRoutingGroup(
      campusCode: $campusCode
      domainId: $domainId
      routingListId: $routingListId
      name: $name
      id: $id
    )
  }
`;

export const DEACTIVATE_ROUTING = gql`
  mutation inactivateRouting($campusCode: String!, $domainId: String!, $id: ID!, $type: RoutingType) {
    inactivateRouting(campusCode: $campusCode, domainId: $domainId, id: $id, type: $type)
  }
`;

export const CREATE_NODE = gql`
  mutation createNode($nodes: [JSON]!, $edges: [JSON]!) {
    createNode(nodes: $nodes, edges: $edges)
  }
`;

export const UPSERT_NODE = gql`
  mutation upsertNode($node: JSON!) {
    upsertNode(node: $node)
  }
`;

export const CREATE_EDGE = gql`
  mutation createEdge($from: JSON!, $to: JSON!, $edge: JSON!) {
    createEdge(from: $from, to: $to, edge: $edge)
  }
`;

export const DEACTIVATE_EDGES = gql`
  mutation deactivateEdges($edges: [EdgeInput]!) {
    deactivateEdges(edges: $edges)
  }
`;

export const DEACTIVATE_NODE_EDGES = gql`
  mutation deactivateNodeEdges($nodeId: String!, $edgeLabel: [String]) {
    deactivateNodeEdges(nodeId: $nodeId, edgeLabel: $edgeLabel)
  }
`;

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails($id: String!, $firstName: String!, $lastName: String!, $email: String!) {
    updateUserDetails(id: $id, firstName: $firstName, lastName: $lastName, email: $email) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const TOGGLE_USER_STATUS = gql`
  mutation toggleUserStatus($id: String!) {
    toggleUserStatus(id: $id)
  }
`;

export const UPSERT_PROGRAM = gql`
  mutation UpsertProgram($domainId: String!, $program: ProgramInputType!) {
    upsertProgram(domainId: $domainId, program: $program) {
      id
      name
      createdDate
    }
  }
`;

export const LIST_PROGRAMS = gql`
  query ListPrograms($search: ListProgramsSearch!, $sort: [ListProgramsSort]) {
    listPrograms(search: $search, sort: $sort) {
      id
      name
      createdDate
      tenant {
        tenantCode
        name
      }
      campus {
        campusCode
        name
      }
      domain {
        domainId
        name
      }
    }
  }
`;

export const UPSERT_BUCKET = gql`
  mutation UpsertBucket($scope: BucketScopeInputType!, $bucket: BucketInputType!) {
    upsertBucket(scope: $scope, bucket: $bucket) {
      id
      name
      description
      type
      source
      active
      createdDate
    }
  }
`;

export const LIST_BUCKETS = gql`
  query ListBuckets($scopeType: BucketScope!, $search: ListBucketsSearch!, $sort: [ListBucketsSort]) {
    listBuckets(scopeType: $scopeType, search: $search, sort: $sort) {
      id
      name
      description
      type
      source
      active
      createdDate
      lastUpdatedDate
      scope {
        id
        label
        name
      }
    }
  }
`;

export const FIND_ROUTING_LIST = gql`
  query FindRoutingList($search: RoutingListSearch!, $sort: [ListSortInput]) {
    findRoutingList(search: $search, sort: $sort) {
      id
      name
      active
      lastUpdatedBy {
        firstName
        lastName
      }
      lastUpdatedDate
      domain {
        domainId
        name
      }
    }
  }
`;

export const GET_ROUTING_DETAILS = gql`
  query GetRoutingDetailsByIdAndType($id: String!, $type: String!, $domainId: String) {
    getByIdAndType(id: $id, type: $type, domainId: $domainId) {
      id
      name
      active
      lastUpdatedBy {
        firstName
        lastName
      }
      domain {
        domainId
        name
      }
      routingGroups {
        id
        name
        lastUpdatedBy {
          firstName
          lastName
        }
      }
      routingList
      members
      label
    }
  }
`;

export const LIST_FACILITY_TYPES = gql`
  query listFacilityTypes($campusCode: String!) {
    listFacilityTypes(campusCode: $campusCode)
  }
`;

export const GET_USER_DEPARTMENTS = gql`
  query findUserDepartments($userId: String!) {
    findUserDepartments(userId: $userId) {
      edge
      node
    }
  }
`;
