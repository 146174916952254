/* eslint-disable react/jsx-props-no-spreading */
import { Chip, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography color="textSecondary" className={selectProps.classes.noOptionsMessage} {...innerProps}>
    {children}
  </Typography>
);

NoOptionsMessage.defaultProps = {
  innerProps: {},
};

NoOptionsMessage.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.objectOf(PropTypes.any),
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

inputComponent.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export const Control = ({ children, selectProps, innerRef, innerProps }) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.isMulti ? selectProps.classes.multiinput : selectProps.classes.input,
        inputRef: innerRef,
        children,
        ...innerProps,
      },
    }}
    {...selectProps.textFieldProps}
  />
);

Control.propTypes = {
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.func.isRequired,
  innerProps: PropTypes.objectOf(PropTypes.any).isRequired,
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const Option = ({ children, innerRef, innerProps, isFocused, isSelected }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}>
    {children}
  </MenuItem>
);

Option.defaultProps = {
  innerRef: () => {},
};

Option.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  innerRef: PropTypes.func,
  innerProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const Placeholder = ({ children, innerProps, selectProps }) => (
  <>
    {children && (
      <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
        {children}
      </Typography>
    )}
  </>
);

Placeholder.defaultProps = {
  innerProps: {},
};

Placeholder.propTypes = {
  children: PropTypes.node.isRequired,
  innerProps: PropTypes.objectOf(PropTypes.any),
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const SingleValue = ({ children, innerProps, selectProps }) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

SingleValue.defaultProps = {
  children: [],
  innerProps: {},
};

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.objectOf(PropTypes.any),
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const MultiValue = ({ children, isFocused, removeProps, selectProps }) => (
  <Chip
    tabIndex={-1}
    label={children}
    className={classNames(selectProps.classes.chip, {
      [selectProps.classes.chipFocused]: isFocused,
    })}
    onDelete={(event) => {
      removeProps.onClick();
      removeProps.onMouseDown(event);
    }}
  />
);

MultiValue.propTypes = {
  children: PropTypes.node.isRequired,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.objectOf(PropTypes.any).isRequired,
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const ValueContainer = ({ children, selectProps }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>
);

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const Menu = ({ children, innerProps, selectProps }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

Menu.defaultProps = {
  innerProps: {},
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.objectOf(PropTypes.any).isRequired,
  innerProps: PropTypes.objectOf(PropTypes.any),
};
