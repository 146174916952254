import { FqType } from '@rss/common';

const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

const types = [
  'ADJUVANT',
  'ALGAECIDE',
  'ANTI-FOULANT(MARINE)',
  'ANTIMICROBIAL',
  'AVICIDE',
  'BACTERICIDE',
  'DEFOLIANT',
  'DESICCANT',
  'DISINFECTANT',
  'FERTILIZER',
  'FUNGICIDE',
  'GROWTH REGULATOR',
  'HERBICIDE',
  'INSECTICIDE',
  'MITICIDE',
  'MOLLUSCICIDE',
  'NEMATICIDE',
  'REPELLENT',
  'SLIMICIDE',
  'VIRUCIDE',
  'VERTEBRATE CONTROL',
  'SPECIAL ACTIVITY',
  'INSECT GROWTH REGULATOR',
  'UNSPECIFIED PESTICIDE',
];
// 'POISON/DANGER','DANGER (ONLY)','WARNING','CAUTION','NONE',

const specialStatuses = [
  'UNDER RE-EVALUATION',
  'RESTRICTED MATERIAL',
  'FEDERALLY RESTRICTED',
  'CALIFORNIA RESTRICTED',
  'CONDITIONAL REGISTRATION',
];

const cdprEnvHazards = [
  'WATER (DO NOT APPLY DIRECTLY TO WATER)',
  'WILDLIFE',
  'MAMMALS',
  'BIRDS',
  'FISH',
  'BEES',
  'DOMESTIC ANIMALS AND/OR LIVESTOCK',
  'CHILDREN/HUMANS',
  'DRIFT(AVOID)',
  'OTHER',
  'ENDANGERED SPECIES/THREATEN (CA RESTRICTIONS)',
  'AQUATIC ORGANISMS',
  'GROUND WATER',
  'REPTILES/AMPHIBIANS',
];

export const CaPesticideLibraryItemTemplate = [
  {
    type: FqType.LABEL,
    key: 'header',
    label: '### **Overview**',
    altLabel: 'Overview',
  },
  {
    type: FqType.INPUT,
    key: 'name',
    label: 'Name',
    required: true,
    disabled: true,
  },
  {
    type: FqType.MULTISELECT,
    key: 'tags',
    label: 'Type',
    required: true,
    disabled: true,
    valueType: 'string',
    options: types,
  },
  {
    type: FqType.GROUP,
    key: 'details',
    required: true,
    disabled: null,
    questions: [
      { type: FqType.INPUT, key: 'epaCaRegNumber', label: 'EPA/CA Reg No #', required: true, disabled: true },
      {
        type: FqType.LABEL,
        key: 'company-section',
        label: '### **Company**',
        altLabel: 'Company',
      },
      {
        type: FqType.GROUP,
        key: 'company',
        required: true,
        disabled: true,
        questions: [
          {
            type: FqType.INPUT,
            key: 'number',
            label: 'Company ID',
            required: false,
            disabled: true,
          },
          {
            type: FqType.INPUT,
            key: 'name',
            label: 'Name',
            required: false,
            disabled: true,
          },
          {
            type: FqType.INPUT,
            key: 'street',
            label: 'Street',
            required: false,
            disabled: true,
          },
          {
            type: FqType.INPUT,
            key: 'city',
            label: 'City',
            required: false,
            disabled: true,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'state',
            label: 'State',
            required: false,
            disabled: true,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'zipCode',
            label: 'Zipcode',
            required: false,
            disabled: true,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'poBoxNumber',
            label: 'PO Box',
            required: false,
            disabled: true,
            className: 'w-full sm:w-1/2',
          },
        ],
      },
      {
        type: FqType.LABEL,
        key: 'active-ingredients-section',
        label: '### **Active Ingredients**',
        altLabel: 'Active Ingredients',
      },
      {
        type: FqType.ADD_ANOTHER,
        key: 'activeIngredients',
        disabled: true,
        questions: [
          {
            type: FqType.INPUT,
            key: 'activeIngredient',
            label: 'Active Ingredient',
            required: true,
            disabled: true,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'activeIngredientPercent',
            label: 'Active Ingredient Percent',
            required: false,
            disabled: true,
            inputProps: { type: 'number', max: 100 },
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.RADIO,
            key: 'activeIngredientStatus',
            label: 'Active Ingredient Status',
            required: false,
            disabled: true,
            className: 'w-full sm:w-1/2',
            options: [
              { label: 'Active', value: 'true' },
              { label: 'Inactive', value: 'false' },
            ],
          },
        ],
      },

      {
        type: FqType.INPUT,
        key: 'specificGravitiyIndicator',
        label: 'Specific Gravity Indicator',
        required: false,
        disabled: true,
      },
      {
        type: FqType.INPUT,
        key: 'specificGravitiy',
        label: 'Specific Gravity',
        required: false,
        disabled: true,
      },
      {
        type: FqType.LABEL,
        key: 'formulations-section',
        label: '### **Formulations**',
        altLabel: 'Formulations',
      },
      {
        type: FqType.MULTISELECT,
        key: 'formulations',
        label: 'Formulations',
        required: false,
        disabled: true,
        valueType: 'string',
        options: [],
        addNew: false,
      },
      {
        type: FqType.LABEL,
        key: 'hazard-info-section',
        label: '### **Hazard Info**',
        altLabel: 'Hazard Info',
      },
      {
        type: FqType.GROUP,
        key: 'hazardInfo',
        required: true,
        disabled: null,
        questions: [
          {
            type: FqType.RADIO,
            key: 'generalHazardTierClassification',
            label: 'General Hazard Tier Classification',
            required: false,
            disabled: null,
            options: [
              { label: 'Red', value: 'Red' },
              { label: 'Yellow', value: 'Yellow' },
              { label: 'Green', value: 'Green' },
            ],
          },
          {
            type: FqType.MULTISELECT,
            key: 'specialStatus',
            label: 'Special Status',
            required: false,
            disabled: true,
            valueType: 'string',
            options: specialStatuses,
            addNew: false,
          },
          {
            type: FqType.MULTISELECT,
            key: 'cdprEnvHazards',
            label: 'CDPR Environment Hazards',
            required: false,
            disabled: true,
            valueType: 'string',
            options: cdprEnvHazards,
            addNew: false,
          },
          {
            type: FqType.RADIO,
            key: 'waterSensitiveRestriction',
            label: 'Water Sensitive Restriction',
            required: false,
            disabled: null,
            options: YES_NO_OPTIONS,
          },
          {
            type: FqType.RADIO,
            key: 'storageTemperatureRange',
            label: 'Storage Temperature Range',
            required: false,
            disabled: null,
            options: [
              { label: 'Room Temperature', value: 'Room Temperature' },
              { label: 'Fridge', value: 'Fridge' },
              { label: 'Freezer', value: 'Freezer' },
              { label: 'Other', value: 'Other' },
            ],
          },
          {
            type: FqType.DEPENDENT,
            key: 'storageTemperatureRangeOther',
            parentKey: 'storageTemperatureRange',
            parentValue: 'Other',
            label: '',
            questions: [
              {
                type: 'input',
                key: 'details',
                label: 'Please specify:',
              },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'signalWord',
            label: 'Acute Toxicity / Signal Word',
            required: false,
            disabled: true,
            options: [
              { label: 'Poison/Danger', value: 'POISON/DANGER' },
              { label: 'Danger (Only)', value: 'DANGER (ONLY)' },
              { label: 'Warning', value: 'WARNING' },
              { label: 'Caution', value: 'CAUTION' },
              { label: 'No Signal Word', value: 'NONE' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'cancer',
            label: 'Cancer',
            required: false,
            disabled: null,
            options: [
              { label: 'Known / Probable', value: 'Known / Probable' },
              { label: 'Possible', value: 'Possible' },
              { label: 'Unclassifiable / Not Likely', value: 'Unclassifiable / Not Likely' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'reproductiveOrDevelopmentalToxicity',
            label: 'Reproductive or Developmental Toxicity',
            required: false,
            disabled: null,
            options: [
              { label: 'Listed', value: 'Listed' },
              { label: 'Not Listed', value: 'Not Listed' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'endocrineDisruption',
            label: 'Endocrine Disruption',
            required: false,
            disabled: null,
            options: [
              { label: 'Yes (listed)', value: 'Yes (listed)' },
              { label: 'Maybe (weak)', value: 'Maybe (weak)' },
              { label: 'No (not listed)', value: 'No (not listed)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'waterPollutionPotential',
            label: 'Water Pollution Potential',
            required: false,
            disabled: null,
            options: [
              { label: '303d listed', value: '303d listed' },
              { label: 'Not listed', value: 'Not listed' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToBirds',
            label: 'Hazards to Birds',
            required: false,
            disabled: null,
            valueType: 'string',
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LC50 or LD50)', value: 'Toxic / Moderate (LC50 or LD50)' },
              { label: 'No Warning / Low (LC50 or LD50)', value: 'No Warning / Low (LC50 or LD50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToAquaticLife',
            label: 'Hazards to Aquatic Life',
            required: false,
            disabled: null,
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LC50)', value: 'Toxic / Moderate (LC50)' },
              { label: 'No Warning / Low (LC50)', value: 'No Warning / Low (LC50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToBees',
            label: 'Hazards to Bees',
            required: false,
            disabled: null,
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LD50)', value: 'Toxic / Moderate (LD50)' },
              { label: 'No Warning / Low (LD50)', value: 'No Warning / Low (LD50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToWildlife',
            label: 'Hazards to Wildlife',
            required: false,
            disabled: null,
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LC50 or LD50)', value: 'Toxic / Moderate (LC50 or LD50)' },
              { label: 'No Warning / Low (LC50 or LD50)', value: 'No Warning / Low (LC50 or LD50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'soilMobility',
            label: 'Soil Mobility',
            required: false,
            disabled: null,
            options: [
              {
                label: 'GUS ≥2 or DPR classifies AI as exceeding SNVs',
                value: 'GUS ≥2 or DPR classifies AI as exceeding SNVs',
              },
              {
                label: 'GUS <2 and Not listed by DPR as exceeding SNVs',
                value: 'GUS <2 and Not listed by DPR as exceeding SNVs',
              },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'persistentBioaccumulativeToxicSubstances',
            label: 'Persistent, Bioaccumulative, Toxic Substances (PBT)',
            required: false,
            disabled: null,
            options: [
              { label: 'Listed', value: 'Listed' },
              { label: 'Not Listed', value: 'Not Listed' },
            ],
          },
          {
            type: FqType.TEXTAREA,
            key: 'hazardsStatement',
            label: 'Hazards Statement',
            required: false,
            disabled: null,
          },
        ],
      },
      {
        type: FqType.LABEL,
        key: 'pest-section',
        label: '### **Pests**',
        altLabel: 'Pests',
      },
      {
        type: FqType.MULTISELECT,
        key: 'pests',
        label: 'Pests',
        required: false,
        disabled: true,
        valueType: 'string',
        options: [],
        addNew: false,
      },
    ],
  },
];
