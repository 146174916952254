import {
  Button,
  Chip,
  Divider,
  Fade,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Store, CATALOG_TYPE } from '@risk-and-safety/library';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

import BarcodeInput from '../BarcodeInput';
import CartPrice from '../CartPrice';
import { BARCODE_SCAN_ERROR, MAX_QUANTITY_SERVICE } from '../../helper/order.helper';

const ScanCheckoutItems = ({
  items,
  invalidItems,
  onItemChange,
  onInvalidItemChange,
  isSelfCheckout,
  onBarcodeScan,
  onCheckout,
  store,
  scanLoading,
}) => {
  return (
    <>
      <BarcodeInput
        campusCode={store.campusCode}
        placeholder="Scan product items you want to purchase"
        onValidBarcode={(value) => {
          if (items.find((i) => i.barcode === value)) {
            onInvalidItemChange([
              ...new Set([...invalidItems, { _id: value, barcode: value, error: BARCODE_SCAN_ERROR.DUPLICATE }]),
            ]);
          } else {
            onBarcodeScan(value);
          }
        }}
      />

      <div className="mb-12">
        {invalidItems.map((invalidItem) => (
          <Fade in key={invalidItem._id}>
            <Alert
              severity="error"
              onClose={() => onInvalidItemChange(invalidItems.filter((i) => i._id !== invalidItem._id))}
              className="mb-2">
              {invalidItem.error} - {invalidItem.barcode}
            </Alert>
          </Fade>
        ))}
      </div>

      <div className="flex flex-wrap">
        <div className="mr-8 flex-grow">
          <Paper className="rounded-8 pb-16 shadow-1 ">
            <List subheader={<ListSubheader component="div">Scanned Items</ListSubheader>}>
              {items.map((item, index) => {
                return (
                  <div key={item._id || item.barcode}>
                    <ListItem className="flex items-start justify-between pt-20">
                      <div>
                        <div className="flex items-center">
                          <Typography className="text-16">{item.product.name}</Typography>
                          <Chip label={item.barcode} className="ml-10" />
                        </div>

                        <Typography color="textSecondary" className="mt-6">
                          {item.product.description}
                        </Typography>
                        <div className="pt-16">
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() =>
                              onItemChange(
                                items.filter((_, idx) => {
                                  return idx !== index;
                                }),
                              )
                            }
                            className="align-middle">
                            Remove
                          </Button>
                        </div>
                      </div>

                      <div className="flex">
                        <div className="flex">
                          {(item.product.catalog === CATALOG_TYPE.SERVICES ||
                            item?.product?.barcodeRequired === false) && (
                            <div>
                              <Typography color="textSecondary">Quantity</Typography>
                              <FormControl variant="outlined">
                                <Select
                                  value={item.product.quantity ?? 1}
                                  onChange={({ target: { value } }) => {
                                    const newItemList = items.map((i) => {
                                      return { ...i, product: { ...i.product } };
                                    });
                                    newItemList[index].product.quantity = value;
                                    onItemChange(newItemList);
                                  }}
                                  className="h-44">
                                  {Array.from(
                                    {
                                      length:
                                        item.product.catalog === CATALOG_TYPE.SERVICES
                                          ? MAX_QUANTITY_SERVICE
                                          : item?.product?.availableStock,
                                    },
                                    (_, i) => i + 1,
                                  ).map((option) => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          )}
                          <div className="pl-32">
                            <Typography color="textSecondary">Item Price</Typography>
                            <Typography variant="subtitle1">{numeral(item.product.price).format('$0,0.00')}</Typography>
                          </div>
                        </div>
                      </div>
                    </ListItem>
                    {index < items.length - 1 && <Divider className="mt-20" />}
                  </div>
                );
              })}

              {!items.length && (
                <div className="flex justify-center">
                  <Typography color="textSecondary" className="pt-20">
                    You must scan at least one item in this store to proceed to checkout
                  </Typography>
                </div>
              )}
            </List>
          </Paper>
        </div>
        <div className="ml-8 min-w-256 flex-none">
          <CartPrice storeId={store._id} items={items} />
          <Button
            variant="contained"
            color="primary"
            className="w-full"
            disabled={scanLoading || !items.length}
            onClick={() => onCheckout()}>
            Checkout
          </Button>
        </div>
      </div>
    </>
  );
};

ScanCheckoutItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  invalidItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemChange: PropTypes.func.isRequired,
  onInvalidItemChange: PropTypes.func.isRequired,
  isSelfCheckout: PropTypes.bool,
  onBarcodeScan: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
  scanLoading: PropTypes.bool.isRequired,
  store: PropTypes.shape(Store).isRequired,
};

ScanCheckoutItems.defaultProps = {
  isSelfCheckout: false,
};

export default ScanCheckoutItems;
