import { AppBar, Link, ThemeProvider, Toolbar, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFooterTheme } from '../../../store/fuse/settingsSlice';

function FooterLayout1(props) {
  const footerTheme = useSelector(selectFooterTheme);

  return (
    <ThemeProvider theme={footerTheme}>
      <AppBar
        id="fuse-footer"
        className="relative z-10 items-center"
        color="default"
        style={{ backgroundColor: footerTheme.palette.background.default }}
        elevation={0}>
        <Toolbar className="min-h-48 md:min-h-64 p-8 sm:px-12 flex flex-col overflow-x-auto">
          <Typography variant="caption">
            Copyright &copy; The Regents of the University of California. All rights reserved.
          </Typography>
          <Link href="https://riskandsafety.com/privacy/" target="_blank" variant="caption" underline="none">
            Privacy Policy
          </Link>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(FooterLayout1);
