const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const yup = require('yup');

// Adjust properties of this class when the details of
// library item and inventory item
// diverge from each other in actual use cases

class InventoryPPEDetails extends RssModel {
  constructor(payload = {}) {
    super(payload);
    Object.assign(this, this.schema().cast(payload, {
      abortEarly: false,
      stripUnknown: true
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      altered: yup.string().default(null).nullable(),
      size: yup.string().default(null).nullable(),
      style: yup.string().default(null).nullable(),
      laundryLocation: yup.object().shape({
        _id: new ObjectIdSchema()
      }).default(null).nullable()
    });
  }
}
module.exports = InventoryPPEDetails;