import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Icon, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Header } from '../../components';
import { ErrorMessage } from '../../components/errors';
import { STORE_WITH_INVENTORY } from '../graphql/query';

function SettingPage({ route }) {
  const { storeId, tab, panel } = useParams();
  const history = useHistory();

  const { loading, data, error } = useQuery(STORE_WITH_INVENTORY, {
    variables: { id: storeId },
  });

  if (loading) {
    return <FuseLoading />;
  }

  if (error) {
    return <ErrorMessage />;
  }
  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        contentCard: 'overflow-hidden',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">settings</Icon>}
          title={data.store.name}
          backArrow={{
            link: `/store/${storeId}`,
            name: 'Manage Store',
          }}
        />
      }
      contentToolbar={
        !panel ? (
          <Tabs
            value={tab}
            onChange={(e, value) => history.push(`/store/${storeId}/settings/${value}`)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: 'w-full h-64' }}>
            <Tab className="h-64 normal-case" label="Detail" value="detail" />
            <Tab className="h-64 normal-case" label="Locations" value="location" />
          </Tabs>
        ) : (
          <div />
        )
      }
      content={<div className="flex-grow p-28">{renderRoutes(route.routes, { store: data.store })}</div>}
    />
  );
}

SettingPage.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SettingPage;
