import { Hidden, Icon, IconButton, MobileStepper, Tab, Tabs } from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';

function Wizard({ currentStep, steps, visitedSteps, onChange }) {
  if (steps.length > 5) {
    throw new Error('Error Wizard should not have more than 5 steps');
  }

  const hasNext = currentStep < steps.length - 1;
  const hasPrev = currentStep > 0;

  return (
    <>
      <Hidden smDown>
        <Tabs
          value={currentStep}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
          classes={{
            root: 'w-full h-64',
          }}>
          {steps.map((step, index) => (
            <Tab
              key={step}
              className="h-64 normal-case"
              classes={{ wrapper: 'flex flex-row' }}
              disabled={!visitedSteps.includes(steps[index]) || index > step}
              label={
                <>
                  {visitedSteps.includes(steps[index]) && <Icon className="mr-16">done</Icon>} {step.toUpperCase()}
                </>
              }
              onClick={() => onChange(index)}
            />
          ))}
        </Tabs>
      </Hidden>
      <Hidden mdUp>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={currentStep}
          backButton={
            <IconButton disabled={!hasPrev} onClick={() => onChange(currentStep - 1)}>
              <Icon>chevron_left</Icon>
            </IconButton>
          }
          nextButton={
            <IconButton
              disabled={!hasNext || !visitedSteps.includes(steps[currentStep + 1])}
              onClick={() => onChange(currentStep + 1)}>
              <Icon>chevron_right</Icon>
            </IconButton>
          }
        />
      </Hidden>
    </>
  );
}

Wizard.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  visitedSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
};

Wizard.defaultProps = {
  onChange: () => null,
};

export default Wizard;
