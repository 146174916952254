import { BrowserSecurity } from '@risk-and-safety/browser-security';

export default function useSecurity() {
  const defaultLocationUtils = {
    assign: (url) => window.location.assign(url),
    get href() {
      return window.location.href;
    },
  };

  const browserSecurity = new BrowserSecurity(defaultLocationUtils, '/auth');
  return {
    browserSecurity,
  };
}
