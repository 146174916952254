/* eslint-disable max-len */
import { useMutation } from '@apollo/client';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { RELATIONSHIP_LABEL } from '@rss/common';
import { FORM_ACTION } from '@risk-and-safety/assessment-v2-common';
import { flatten, isEqual, uniqWith } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { PROFILE_QUERY } from '../../graphql/profile.query';
import { useProfile } from '../../hooks';
import { REMOVE_MEMBER_BY_LABEL, UPSERT_MEMBER_BY_LABEL } from '../../profile/graphql/mutation';
import { GET_ORGANIZATION_DETAILS } from '../../profile/graphql/query';
import { ErrorMessage } from '../errors';
import { profileConfig } from '../../shared/profile-config';
import {
  getSortedUsages,
  getDisplayName,
  hasWriteAccessToNode,
  hasAdminAccessToLabel,
  hasWriteAccessToLabel,
} from '../../shared/helper';
import PersonSearch from '../PersonSearch';
import SubHeaderWithActions from '../SubHeaderWithActions';
import UsageRoleSelect from './UsageRoleSelect';
import { formatPeople } from './utils';
import OrganizationRoleSelect from './OrganizationRoleSelect';
import { NodeEdgeShape, NodeShape, PersonShape } from './prop-types';

const {
  EDGE: { OWNER },
  EDGE_TYPE: { EVENT },
  NODE: { USER },
} = RELATIONSHIP_LABEL;

const getFormattedPersonEdge = (node, userId, role, edge) => ({
  from: userId,
  to: node.id,
  label: edge?.label || role,
  isActive: edge?.isActive || true,
  sourceNodeLabel: USER,
  targetNodeLabel: node.label,
  edgeId: edge?.id || edge?.edgeId || null,
});

const getPersonToNodeEdges = (edges, role, userId, node) => {
  const existing = edges.find(({ node: roleNode, edge }) => roleNode.id === node.id && edge.label === role);
  if (!existing) return edges.concat({ node, edge: getFormattedPersonEdge(node, userId, role) });
  if (existing.isActive) return edges;

  return edges.map((e) =>
    e?.node?.id === node.id && e?.edge?.label === role ? { node: e.node, edge: { ...e.edge, isActive: true } } : e,
  );
};

const ManageRoster = ({
  currentUsage,
  campusCode,
  filter,
  members,
  orgNode,
  onSave,
  onRemove,
  redirectBackUrl,
  suggestions,
  showSuggestions,
  usages,
  userId,
  onPersonSearch,
  canEdit,
}) => {
  const { id: orgId, label: orgLabel } = orgNode;

  const { defaultRole: defaultOrgRole } = profileConfig[orgLabel] || {};

  const { profile = {}, rolePermissions } = useProfile();
  const { pathname } = useLocation();
  const { domainId } = useParams();
  const { rssTools } = useSelector(({ app }) => app);
  const isRssTools = pathname?.startsWith('/rss-tools/');

  const [campus, setCampus] = useState(campusCode);
  const [error, setError] = useState(false);
  const sortedUsages = getSortedUsages(usages, currentUsage);
  const formattedPeople = formatPeople(members, sortedUsages, orgNode);
  const [personId, setPersonId] = useState(userId);
  const selectedPerson = formattedPeople.find((person) => person.id === (personId || userId));

  const [personEdges, setPersonEdges] = useState(
    selectedPerson
      ? [
          ...(selectedPerson?.person?.usageRoles || []).map((r) => ({
            node: r.node,
            edge: getFormattedPersonEdge(r.node, userId, r.edge.label, r.edge),
          })),
          ...(selectedPerson?.person?.orgRoles || []).map((r) => ({
            node: orgNode,
            edge: getFormattedPersonEdge(orgNode, userId, r.edge.label, r.edge),
          })),
        ]
      : [],
  );

  const isAdd = !selectedPerson;

  const orgDisplayLabel = getDisplayName(orgLabel);
  const isSelf = userId === profile.userId;

  const rosterTitle = isAdd
    ? `Add Person to ${orgDisplayLabel}`
    : `${selectedPerson?.firstName} ${selectedPerson?.lastName}`;

  const getSelectedRoles = (id, edges = []) =>
    edges.filter(({ node, edge }) => node.id === id && edge.isActive).map(({ edge }) => edge.label);

  const getSelectedRole = (id) =>
    (personEdges.find(({ node, edge: { isActive } }) => node.id === id && isActive) || {}).label;

  const hasOwnerRole = (id) => {
    return personEdges?.some(({ node, edge }) => node?.id === id && edge?.label === OWNER && edge?.isActive);
  };

  const isAdmin = hasAdminAccessToLabel(rolePermissions, profile.relationships, orgLabel, orgId);
  const hasWriteAccess = hasWriteAccessToLabel(rolePermissions, profile.relationships, orgLabel, orgId);

  const [upsertMemberByLabel, { loading: upsertLoading, error: upsertMemberError }] = useMutation(
    UPSERT_MEMBER_BY_LABEL,
    {
      onCompleted: () => onSave && onSave(personId),
      refetchQueries: [{ query: GET_ORGANIZATION_DETAILS, variables: { id: orgId } }, { query: PROFILE_QUERY }],
      awaitRefetchQueries: true,
      onError: () => {
        setError(true);
      },
    },
  );

  const [removeMemberByLabel, { loading: removeLoading }] = useMutation(REMOVE_MEMBER_BY_LABEL, {
    onCompleted: () => onRemove && onRemove(),
    refetchQueries: [
      (!isSelf || isAdmin) && { query: GET_ORGANIZATION_DETAILS, variables: { id: orgId } },
      { query: PROFILE_QUERY },
    ].filter(Boolean),
    awaitRefetchQueries: true,
  });

  const filterMembers = currentUsage
    ? currentUsage?.childNodes
        ?.filter(({ edge: { endDate, type } }) => type !== EVENT || !!endDate)
        .map((member) => member.node.id)
    : members?.filter(({ edge: { endDate } }) => !endDate).map((member) => member.node.id);

  useEffect(() => {
    if (campusCode) {
      setCampus(campusCode);
    }
  }, [campusCode]);

  const handleClear = () => {
    setPersonEdges([]);
    setPersonId(null);
  };

  const handleSave = () => {
    const filteredEdges = personEdges.filter(
      ({ edge: { edgeId, isActive } }) => edgeId !== null || (edgeId === null && isActive),
    );
    if (onPersonSearch) onPersonSearch(personId);
    const uniqueEdges = uniqWith(filteredEdges, isEqual);
    upsertMemberByLabel({
      variables: {
        action: isAdd ? FORM_ACTION.ADD_MEMBER : FORM_ACTION.UPDATE_MEMBER,
        edges: uniqueEdges.map(({ edge }) => edge),
      },
    });
  };

  const handleRemove = () => {
    removeMemberByLabel({
      variables: {
        edges: currentUsage
          ? personEdges.filter(({ edge }) => edge?.to === currentUsage?.node?.id).map(({ edge }) => edge)
          : personEdges.map(({ edge }) => edge),
      },
    });
  };

  if (error || upsertMemberError) {
    return <ErrorMessage message="An error occured while adding a member." />;
  }

  const handleOrgElevatedRoleChange = (event, node) => {
    const { checked, name: role } = event?.target || {};
    let edges = personEdges || [];
    if (checked) {
      edges = getPersonToNodeEdges(edges, role, personId, node);
      // deactivate default role when atleast one elevated role is selected
      edges = edges.map((e) =>
        e.node.id === node.id && e.edge.label === defaultOrgRole ? { ...e, edge: { ...e.edge, isActive: false } } : e,
      );
    } else {
      edges = personEdges.map((e) =>
        e.node.id === node.id && e.edge.label === role ? { ...e, edge: { ...e?.edge, isActive: false } } : e,
      );
      const hasElevatedRoles = edges.some((e) => e.node.id === node.id && e.edge.isActive);
      // assign default Role if no elevated role is selected
      if (!hasElevatedRoles) {
        edges = getPersonToNodeEdges(edges, defaultOrgRole, personId, node);
      }
    }

    /* Updating usage roles when org role is changed
       TODO: if decided upon documents not assuming org roles, then need to remove below logic (TBD) */
    if (sortedUsages.length) {
      const usagesAssumingOrgRole = sortedUsages.filter(
        (u) =>
          edges.some((e) => e.node.id === u.node.id && e.edge.isActive) &&
          profileConfig[u.node.label]?.shouldTakeOrgRole,
      );
      const orgRoles = getSelectedRoles(orgId, edges);
      usagesAssumingOrgRole.forEach((usage) => {
        const { roles } = profileConfig[usage.node.label] || {};
        const hasElevatedRoles = selectedPerson && selectedPerson[usage.node.id].some((r) => roles.includes(r));
        if (!hasElevatedRoles) {
          edges = edges.map((e) =>
            e?.node?.id === usage.node.id ? { ...e, edge: { ...e?.edge, isActive: false } } : e,
          );
          orgRoles.forEach((orgRole) => {
            edges = getPersonToNodeEdges(edges, orgRole, personId, usage.node);
          });
        }
      });
    }

    setPersonEdges(edges);
  };

  /* TODO: if decided upon documents not assuming org roles then
  need to remove logic which considers shouldTakeOrgRole (TBD) */
  const handleUsageElevatedRoleChange = (event, usage) => {
    const { checked, name: role } = event?.target || {};

    const { node } = usage;
    const { defaultRole, shouldTakeOrgRole } = profileConfig[node.label] || {};
    const selectedOrgRoles = selectedPerson ? selectedPerson.orgRoles.map((r) => r.edge.label) : [];

    const defaultRoles = shouldTakeOrgRole ? selectedOrgRoles : [defaultRole];

    let edges = personEdges;

    if (checked) {
      edges = getPersonToNodeEdges(personEdges, role, personId, node);
      // deactivate default role to usage when atleast one elevated role is selected
      edges = edges.map((e) =>
        e?.node?.id === node.id && defaultRoles.includes(e?.edge.label)
          ? { ...e, edge: { ...e.edge, isActive: false } }
          : e,
      );
    } else {
      edges = edges.map((e) =>
        e?.node?.id === node.id && e?.edge?.label === role ? { ...e, edge: { ...e.edge, isActive: false } } : e,
      );
      const hasElevatedRoles = edges.some(({ node: roleNode, edge }) => roleNode.id === node.id && edge.isActive);
      if (!hasElevatedRoles) {
        // assign default Role to usage if no elevated role is selected
        edges = flatten(defaultRoles.map((dr) => getPersonToNodeEdges(edges, dr, personId, node)));
      }
    }
    setPersonEdges(edges);
  };

  /* TODO: if decided upon documents not assuming org roles then
   need to remove logic which considers shouldTakeOrgRole */
  const handleUsageDefaultRoleChange = (event, usage) => {
    const { checked, name: defaultRole } = event?.target || {};

    const { node } = usage;
    const { shouldTakeOrgRole } = profileConfig[node.label] || {};
    const selectedOrgRoles = getSelectedRoles(orgId, personEdges);

    const defaultRoles = shouldTakeOrgRole ? selectedOrgRoles : [defaultRole];

    let edges = personEdges;
    if (checked) {
      defaultRoles.forEach((dRole) => {
        edges = getPersonToNodeEdges(edges, dRole, personId, node);
      });
    } else {
      edges = edges.map((e) => (e.node.id === node.id ? { ...e, edge: { ...e.edge, isActive: false } } : e));
    }
    setPersonEdges(edges);
  };

  const handlePersonSelect = (person) => {
    const id = person.id || person.userId;
    const selPerson = formattedPeople.find((p) => p.id === id);
    let edges = [];
    // selected person org edges
    if (selPerson) {
      edges.push(
        ...((selPerson?.person?.orgRoles || []).map((r) => ({
          node: orgNode,
          edge: getFormattedPersonEdge(r.node, id, r.edge.label || defaultOrgRole, r.edge),
        })) || []),
      );
    } else {
      edges.push({ node: orgNode, edge: getFormattedPersonEdge(orgNode, id, defaultOrgRole) });
    }

    // selected person usage edges
    edges.push(
      ...((selPerson?.person?.usageRoles || []).map((r) => ({
        node: r.node,
        edge: getFormattedPersonEdge(r.node, id, r.edge.label, r.edge),
      })) || []),
    );
    if (currentUsage) {
      edges = edges.concat({
        node: currentUsage.node,
        edge: getFormattedPersonEdge(currentUsage.node, id, getSelectedRole(currentUsage.node.id) || defaultOrgRole),
      });
    }
    setPersonId(id);
    setPersonEdges(edges);
  };

  return (
    <div className="w-full">
      <SubHeaderWithActions
        redirectBackUrl={redirectBackUrl}
        removeConfig={
          selectedPerson
            ? {
                title: 'person',
                // eslint-disable-next-line max-len
                content: `${selectedPerson?.lastName}, ${selectedPerson?.firstName}  will be removed from ${orgNode?.name}`,
              }
            : null
        }
        canEdit={canEdit}
        loading={{ upsertLoading, removeLoading }}
        onSave={personId && !hasOwnerRole(orgId) ? handleSave : null}
        onRemove={hasOwnerRole(orgId) || isAdd || !userId ? null : handleRemove}
      />
      <div className="px-16 pt-16 sm:px-24">
        <FuseAnimate delay={100}>
          <div className="flex flex-col sm:flex-row">
            <p className="text-xl font-normal">{rosterTitle}</p>
            {selectedPerson?.email && (
              <span className="pb-6 text-sm opacity-75 sm:pb-0 sm:pt-6 sm:pl-6">{selectedPerson.email}</span>
            )}
          </div>
        </FuseAnimate>
        <hr />
        {isAdd && (
          <div className="pt-16">
            <PersonSearch
              id="person-search-dialog"
              autoFocus
              campusCode={campus}
              filter={[...(filterMembers || []), ...filter]}
              minCharSearch={2}
              suggestionLimit={10}
              showSuggestions={
                isRssTools ? showSuggestions && rssTools.campusCode === profile.campusCode : showSuggestions
              }
              suggestions={suggestions}
              onSelect={handlePersonSelect}
              onClear={handleClear}
            />
          </div>
        )}
        {personId && (
          <div className="mb-12 pt-12">
            <OrganizationRoleSelect
              isOwner={hasOwnerRole(orgId)}
              label={orgLabel}
              selectedRoles={getSelectedRoles(orgId, personEdges)}
              onElevatedRoleChange={(e) => handleOrgElevatedRoleChange(e, orgNode)}
              adminAccess={isAdmin}
              writeAccess={hasWriteAccess}
            />

            {Boolean(sortedUsages?.length) && (
              <div className="pt-12">
                <p className="text-lg font-medium">Document and Inventory Access</p>
                {sortedUsages.map((usage) => {
                  const isOwner = selectedPerson && selectedPerson?.[usage?.node?.id]?.includes(OWNER);
                  const disabled =
                    isOwner ||
                    !(
                      hasWriteAccessToNode(
                        rolePermissions,
                        profile.relationships,
                        usage.node.label,
                        usage.node.id,
                        sortedUsages,
                        orgId,
                      ) ||
                      (domainId &&
                        hasWriteAccessToNode(
                          rolePermissions,
                          profile.relationships,
                          usage.node.label,
                          usage.node.id,
                          sortedUsages,
                          domainId,
                        ))
                    );

                  return (
                    <Fragment key={usage.edge.id}>
                      <UsageRoleSelect
                        disabled={disabled}
                        isOwner={isOwner}
                        orgRoles={getSelectedRoles(orgId, personEdges)}
                        selectedRoles={getSelectedRoles(usage?.node?.id, personEdges)}
                        usage={usage}
                        onElevatedRoleChange={(e) => handleUsageElevatedRoleChange(e, usage)}
                        onDefaultRoleChange={(e) => handleUsageDefaultRoleChange(e, usage)}
                      />
                    </Fragment>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ManageRoster.propTypes = {
  canEdit: PropTypes.bool,
  campusCode: PropTypes.string,
  currentUsage: PropTypes.objectOf(PropTypes.any),
  members: PropTypes.arrayOf(PropTypes.any),
  orgNode: NodeShape.isRequired,
  onPersonSearch: PropTypes.func,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  redirectBackUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showSuggestions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PersonShape),
  usages: PropTypes.arrayOf(NodeEdgeShape),
  userId: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
};

ManageRoster.defaultProps = {
  filter: [],
  canEdit: false,
  campusCode: null,
  currentUsage: null,
  members: null,
  redirectBackUrl: null,
  showSuggestions: false,
  suggestions: null,
  usages: [],
  userId: null,
  onPersonSearch: null,
  onSave: null,
  onRemove: null,
};

export default ManageRoster;
