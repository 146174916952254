import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const PathDataContext = React.createContext();

const PathDataProvider = ({ children }) => {
  const [pathData, setPathData] = useState({ loading: true });

  return (
    <PathDataContext.Provider
      value={{
        pathData,
        setPathData,
      }}>
      {children}
    </PathDataContext.Provider>
  );
};

PathDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PathDataProvider;
