const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
class Band {
  constructor(opt) {
    this._id = null;
    this.name = null;
    this.suffix = null;
    this.family = [];
    this.cas = [];
    this.ghs = [];
    this.excludes = [];
    this.tenant = null;
    this.campus = null;
    this.class = null;
    this.hideFromUser = false;
    this.requireSop = [];
    this.useRules = false;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.name = opt.name || '';
    this.suffix = opt.suffix || null;
    this.family = opt.family ? opt.family.map(item => new ObjectId(item)) : [];
    this.cas = opt.cas ? opt.cas : [];
    this.ghs = opt.ghs ? opt.ghs : [];
    this.excludes = opt.excludes ? opt.excludes.map(item => new ObjectId(item)) : [];
    this.tenant = opt.tenant || 'GLOBAL';
    this.campus = opt.campus || 'GLOBAL';
    this.class = opt.class || null;
    this.hideFromUser = opt.hideFromUser || false;
    this.requireSop = opt.requireSop || [];
    this.useRules = !!opt.useRules;
  }
  validate() {
    return tv4.validateMultiple(this, Band.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'band.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        name: {
          type: 'string'
        },
        suffix: {
          type: ['string', 'null']
        },
        family: {
          type: 'array',
          item: {
            type: 'object'
          }
        },
        cas: {
          type: 'array',
          item: {
            type: 'string'
          }
        },
        ghs: {
          type: 'array',
          item: {
            type: 'string'
          }
        },
        excludes: {
          type: 'array',
          item: {
            type: 'object'
          }
        },
        tenant: {
          type: 'string'
        },
        campus: {
          type: 'string'
        },
        class: {
          type: ['string', 'null']
        },
        hideFromUser: {
          type: 'boolean'
        },
        requireSop: {
          type: 'array',
          item: {
            type: 'string'
          }
        },
        useRules: {
          type: 'boolean'
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = Band;