import { FormLabel, Icon, IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import { MARKDOWN_OPTIONS } from '../../shared/helper';
import { displayLabel, QUESTION_VARIANT } from './Helper';

const FqLabel = ({ question, variant, disabled }) => {
  const { info, label, key, required } = question;

  const markdownLabel = [label?.trim(), required ? '<span className="text-danger-800 text-14 ml-2">*</span>' : ''].join(
    '',
  );
  const labelDisabled = disabled || question?.disabled || false;
  return (
    <>
      {displayLabel(question) && (
        <FormLabel
          disabled={labelDisabled}
          htmlFor={key || label}
          className={clsx(
            `${variant === QUESTION_VARIANT.COMPACT ? 'mx-8 -mb-4' : 'mr-8 ml-24 text-sm'}`,
            'items-middle pre-line inline-block flex leading-normal text-black',
          )}>
          <Markdown options={MARKDOWN_OPTIONS} className="pre-wrap">
            {markdownLabel}
          </Markdown>
          {info && info.trim() && (
            <Tooltip
              classes={{
                tooltip: 'max-w-screen-sm pre-line text-black inline-block bg-gray-50 shadow',
                arrow: 'text-gray-50',
              }}
              className="ml-5 p-0"
              title={<Markdown options={MARKDOWN_OPTIONS}>{info}</Markdown>}
              placement="bottom"
              interactive
              arrow>
              <IconButton color="inherit">
                <Icon fontSize="small">info_outlined</Icon>
              </IconButton>
            </Tooltip>
          )}
        </FormLabel>
      )}
    </>
  );
};

FqLabel.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  disabled: PropTypes.bool,
};

FqLabel.defaultProps = {
  variant: QUESTION_VARIANT.STANDARD,
  disabled: false,
};

export default memo(FqLabel);
