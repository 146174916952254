import { Card, CardActions, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  header: {
    borderColor: ({ headerVariant }) => {
      const color = theme.palette[headerVariant];
      if (!color) {
        return color;
      }
      return `${color[800] || color} !important`;
    },
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

function Panel({ action, children, classes, description, headerVariant, title, footer }) {
  const localClasses = useStyles({ headerVariant });

  return (
    <Card className={clsx('w-full shadow-1', classes.root)}>
      <CardHeader
        classes={{ root: clsx('border-b-2 h-64', localClasses.header), action: 'self-center m-0 pt-6' }}
        title={<Typography className="text-18">{title}</Typography>}
        subheader={description}
        action={action}
      />
      <CardContent className={clsx('flex flex-row flex-wrap p-0', localClasses.cardContent)}>
        <div className="w-full">{children}</div>
      </CardContent>
      {/* TODO: Should this be flex row?  */}
      {/* TODO: Should it support multiple children so we can properly use flex per item */}
      {footer && <CardActions className={clsx('flex flex-col', classes.footer)}>{footer}</CardActions>}
    </Card>
  );
}

Panel.propTypes = {
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    footer: PropTypes.string,
  }),
  description: PropTypes.string,
  headerVariant: PropTypes.oneOf(['primary', 'success', 'error', 'default']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.node,
};

Panel.defaultProps = {
  action: null,
  classes: {},
  description: null,
  headerVariant: 'primary',
  title: '',
  footer: '',
};

export default Panel;
