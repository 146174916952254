module.exports = [{
  label: 'μg',
  value: 'μg',
  type: 'mass',
  conversionFactor: 0.000001,
  cis: 'ug',
  suggestedPhysicalStates: ['SOLID']
}, {
  label: 'mg',
  value: 'mg',
  type: 'mass',
  conversionFactor: 0.001,
  cis: 'mg',
  suggestedPhysicalStates: ['SOLID']
}, {
  label: 'g',
  value: 'g',
  type: 'mass',
  conversionFactor: 1,
  cis: 'g',
  suggestedPhysicalStates: ['SOLID']
}, {
  label: 'kg',
  value: 'kg',
  type: 'mass',
  conversionFactor: 1000,
  cis: 'kg',
  suggestedPhysicalStates: ['SOLID']
}, {
  label: 'μL',
  value: 'μL',
  type: 'volume',
  conversionFactor: 0.000001,
  cis: 'ul',
  suggestedPhysicalStates: ['LIQUID']
}, {
  label: 'mL',
  value: 'mL',
  type: 'volume',
  conversionFactor: 0.001,
  cis: 'ml',
  suggestedPhysicalStates: ['LIQUID', 'GAS']
}, {
  label: 'L',
  value: 'L',
  type: 'volume',
  conversionFactor: 1,
  cis: 'l',
  suggestedPhysicalStates: ['LIQUID', 'GAS']
}, {
  label: 'oz',
  value: 'oz',
  type: 'mass',
  conversionFactor: 28.3495,
  cis: 'oz',
  suggestedPhysicalStates: ['SOLID']
}, {
  label: 'lb',
  value: 'lb',
  type: 'mass',
  conversionFactor: 453.592,
  cis: 'lbs',
  suggestedPhysicalStates: ['SOLID']
}, {
  label: 'ton',
  value: 'ton',
  type: 'mass',
  conversionFactor: 907185,
  cis: 'ton (English)',
  suggestedPhysicalStates: ['SOLID', 'LIQUID']
}, {
  label: 'tonne (metric)',
  value: 'tonne',
  type: 'mass',
  conversionFactor: 1000000,
  cis: 'ton (Metric)',
  suggestedPhysicalStates: ['SOLID', 'LIQUID']
}, {
  label: 'cm3',
  value: 'cm3',
  type: 'volume',
  conversionFactor: 0.001,
  cis: 'cm3',
  suggestedPhysicalStates: ['LIQUID', 'GAS']
}, {
  label: 'm3',
  value: 'm3',
  type: 'volume',
  conversionFactor: 1000,
  cis: 'm3',
  suggestedPhysicalStates: ['LIQUID', 'GAS']
}, {
  label: 'fluid oz',
  value: 'fluid oz',
  type: 'volume',
  conversionFactor: 0.0295735,
  cis: 'fl oz',
  suggestedPhysicalStates: ['LIQUID', 'GAS']
}, {
  label: 'pint',
  value: 'pint',
  type: 'volume',
  conversionFactor: 0.473176,
  cis: 'pints',
  suggestedPhysicalStates: ['LIQUID']
}, {
  label: 'qt',
  value: 'qt',
  type: 'volume',
  conversionFactor: 0.946353,
  cis: 'qts',
  suggestedPhysicalStates: ['LIQUID']
}, {
  label: 'gal',
  value: 'gal',
  type: 'volume',
  conversionFactor: 3.78541,
  cis: 'gal',
  suggestedPhysicalStates: ['LIQUID']
}, {
  label: 'ft3',
  value: 'ft3',
  type: 'volume',
  conversionFactor: 28.3168,
  cis: 'ft3',
  suggestedPhysicalStates: ['GAS']
}, {
  label: 'mmHg',
  value: 'mmHg',
  type: 'volume',
  conversionFactor: 1,
  cis: 'mmHg',
  suggestedPhysicalStates: ['GAS']
}, {
  label: 'other',
  value: 'other',
  type: 'other',
  suggestedPhysicalStates: []
}];