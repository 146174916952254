"use strict";

var _require = require('../constant/inspect/inspect-finding-status.constant'),
  IN_PROGRESS = _require.IN_PROGRESS,
  NOT_RESOLVED = _require.NOT_RESOLVED,
  READY_FOR_VERIFICATION = _require.READY_FOR_VERIFICATION;
module.exports.hasUnresolvedIncident = function (document) {
  return document.findings && document.findings.some(function (finding) {
    return finding.incidents.some(function (incident) {
      return [NOT_RESOLVED, IN_PROGRESS, READY_FOR_VERIFICATION].includes(incident.status);
    });
  });
};
module.exports.hasUnresolvedIncidentForRP = function (document) {
  return document.findings && document.findings.some(function (finding) {
    return finding.incidents.some(function (incident) {
      return [NOT_RESOLVED, IN_PROGRESS].includes(incident.status);
    });
  });
};