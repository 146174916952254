import { FqType } from '@rss/common';

const YES_NO_OPTIONS = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

const types = [
  'ACARICIDE',
  'ALGAECIDE',
  'ALGICIDE',
  'ANTIBIOTIC',
  'ANTIFOULANT',
  'ANTIFOULING',
  'ANTIMICROBIAL',
  'ATTRACTANT',
  'AVICIDE',
  'BACTERIOCIDE',
  'BACTERIOCIDE/BACTERIOSTAT',
  'BACTERIOSTAT',
  'BIOCHEMICAL PESTICIDE',
  'BIOCIDE',
  'CHEMOSTERILANT',
  'CONTRACEPTIVE',
  'DEFOLIANT',
  'DESICCANT',
  'DISINFECTANT',
  'FEEDING DEPRESSANT',
  'FERTILIZER',
  'FIRE RETARDANT',
  'FUMIGANT',
  'FUNGICIDE',
  'FUNGICIDE AND NEMATICIDE',
  'FUNGICIDE/FUNGISTAT',
  'FUNGISTAT',
  'HERBICIDE',
  'HERBICIDE AQUATIC',
  'HERBICIDE TERRESTRIAL',
  'INDUSTRIAL CHEMICAL',
  'INSECT GROWTH REGULATOR',
  'INSECTICIDE',
  'INSECTICIDE SYNERGIST',
  'MATING DISRUPTANT',
  'MECHANICAL',
  'MEDICAL WASTE TREATMENT',
  'MICROBIAL PESTICIDE',
  'MICROBICIDE',
  'MICROBICIDE/MICROBISTAT',
  'MICROBISTAT',
  'MITICIDE',
  'MOLLUSCICIDE',
  'MOLLUSCICIDE AND TADPOLE SHRIMP',
  'N/A',
  'NEMATICIDE',
  'NONVIABLE MICROBIAL/TRANSGENIC PLANT',
  'PLANT GROWTH REGULATOR',
  'PLANT GROWTH STIMULATOR',
  'POISON, MULTIPLE DOSE',
  'POISON, SINGLE DOSE',
  'REGULATOR',
  'REPELLENT',
  'REPELLENT OR FEEDING DEPRESSANT',
  'RODENTICIDE',
  'SANITIZER',
  'SEX ATTRACTANT OR FEEDING STIMULANT',
  'SLIMACIDE',
  'SLIMACIDES',
  'SOIL FUMIGANT',
  'SPORICIDE',
  'STERILIZER',
  'TADPOLE SHRIMPICIDE',
  'TERMITICIDE',
  'TUBERCULOCIDE',
  'VIRUCIDE',
  'WATER PURIFIER BACTERIACIDAL',
  'WATER PURIFIER BACTERIASTATIC',
  'WATER PURIFIER BACTERIOSTAT',
];

export const PesticideLibraryItemTemplate = [
  {
    type: FqType.LABEL,
    key: 'header',
    label: '### **Overview**',
    altLabel: 'Overview',
  },
  {
    type: FqType.INPUT,
    key: 'name',
    label: 'Name',
    required: true,
    disabled: null,
  },
  {
    type: FqType.MULTISELECT,
    key: 'alternativeNames',
    label: 'Alternative Names',
    required: false,
    disabled: null,
    valueType: 'string',
    options: [],
    addNew: true,
  },
  {
    type: FqType.MULTISELECT,
    key: 'tags',
    label: 'Type',
    required: true,
    disabled: null,
    valueType: 'string',
    options: types,
  },
  {
    type: FqType.GROUP,
    key: 'details',
    required: true,
    disabled: null,
    questions: [
      { type: FqType.INPUT, key: 'epaNumber', label: 'EPA Number', required: true, disabled: true },
      {
        type: FqType.LABEL,
        key: 'company-section',
        label: '### **Company**',
        altLabel: 'Company',
      },
      {
        type: FqType.GROUP,
        key: 'company',
        required: true,
        disabled: null,
        questions: [
          {
            type: FqType.INPUT,
            key: 'number',
            label: 'Company ID',
            required: false,
            disabled: null,
          },
          {
            type: FqType.INPUT,
            key: 'name',
            label: 'Name',
            required: false,
            disabled: null,
          },
          {
            type: FqType.INPUT,
            key: 'street',
            label: 'Street',
            required: false,
            disabled: null,
          },
          {
            type: FqType.INPUT,
            key: 'city',
            label: 'City',
            required: false,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'state',
            label: 'State',
            required: false,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'zipCode',
            label: 'Zipcode',
            required: false,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'poBoxNumber',
            label: 'PO Box',
            required: false,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
        ],
      },
      {
        type: FqType.LABEL,
        key: 'active-ingredients-section',
        label: '### **Active Ingredients**',
        altLabel: 'Active Ingredients',
      },
      {
        type: FqType.ADD_ANOTHER,
        key: 'activeIngredients',
        questions: [
          {
            type: FqType.INPUT,
            key: 'activeIngredient',
            label: 'Active Ingredient',
            required: true,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'epaPesticideChemicalCode',
            label: 'EPA Pesticide Chemical Code',
            required: false,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'casNumber',
            label: 'CAS Number',
            required: false,
            disabled: null,
            className: 'w-full sm:w-1/2',
          },
          {
            type: FqType.INPUT,
            key: 'activeIngredientPercent',
            label: 'Active Ingredient Percent',
            required: false,
            disabled: null,
            inputProps: { type: 'number', max: 100 },
            className: 'w-full sm:w-1/2',
          },
        ],
      },
      {
        type: FqType.LABEL,
        key: 'hazard-info-section',
        label: '### **Hazard Info**',
        altLabel: 'Hazard Info',
      },
      {
        type: FqType.GROUP,
        key: 'hazardInfo',
        required: true,
        disabled: null,
        questions: [
          {
            type: FqType.RADIO,
            key: 'generalHazardTierClassification',
            label: 'General Hazard Tier Classification',
            required: false,
            disabled: null,
            options: [
              { label: 'Red', value: 'Red' },
              { label: 'Yellow', value: 'Yellow' },
              { label: 'Green', value: 'Green' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'restrictedUse',
            label: 'Fed Restricted',
            required: false,
            disabled: null,
            options: YES_NO_OPTIONS,
          },
          {
            type: FqType.RADIO,
            key: 'stateRestricted',
            label: 'State Restricted',
            required: false,
            disabled: null,
            options: YES_NO_OPTIONS,
          },
          {
            type: FqType.RADIO,
            key: 'waterSensitiveRestriction',
            label: 'Water sensitive restriction',
            required: false,
            disabled: null,
            options: YES_NO_OPTIONS,
          },
          {
            type: FqType.RADIO,
            key: 'storageTemperatureRange',
            label: 'Storage Temperature range',
            required: false,
            disabled: null,
            options: [
              { label: 'Room Temperature', value: 'Room Temperature' },
              { label: 'Fridge', value: 'Fridge' },
              { label: 'Freezer', value: 'Freezer' },
              { label: 'Other', value: 'Other' },
            ],
          },
          {
            type: FqType.DEPENDENT,
            key: 'storageTemperatureRangeOther',
            parentKey: 'storageTemperatureRange',
            parentValue: 'Other',
            label: '',
            questions: [
              {
                type: 'input',
                key: 'details',
                label: 'Please specify:',
              },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'signal',
            label: 'Acute Toxicity / Signal Word',
            required: false,
            disabled: null,
            options: [
              { label: 'Danger', value: 'Danger' },
              { label: 'Warning', value: 'Warning' },
              { label: 'Caution', value: 'Caution' },
              { label: 'No Signal Word', value: 'No Signal Word' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'cancer',
            label: 'Cancer',
            required: false,
            disabled: null,
            options: [
              { label: 'Known / Probable', value: 'Known / Probable' },
              { label: 'Possible', value: 'Possible' },
              { label: 'Unclassifiable / Not Likely', value: 'Unclassifiable / Not Likely' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'reproductiveOrDevelopmentalToxicity',
            label: 'Reproductive or Developmental Toxicity',
            required: false,
            disabled: null,
            options: [
              { label: 'Listed', value: 'Listed' },
              { label: 'Not Listed', value: 'Not Listed' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'endocrineDisruption',
            label: 'Endocrine Disruption',
            required: false,
            disabled: null,
            options: [
              { label: 'Yes (listed)', value: 'Yes (listed)' },
              { label: 'Maybe (weak)', value: 'Maybe (weak)' },
              { label: 'No (not listed)', value: 'No (not listed)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'waterPollutionPotential',
            label: 'Water Pollution potential',
            required: false,
            disabled: null,
            options: [
              { label: '303d listed', value: '303d listed' },
              { label: 'Not listed', value: 'Not listed' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToBirds',
            label: 'Hazards to birds',
            required: false,
            disabled: null,
            valueType: 'string',
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LC50 or LD50)', value: 'Toxic / Moderate (LC50 or LD50)' },
              { label: 'No warning / Low (LC50 or LD50)', value: 'No warning / Low (LC50 or LD50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToAquaticLife',
            label: 'Hazards to aquatic life',
            required: false,
            disabled: null,
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LC50)', value: 'Toxic / Moderate (LC50)' },
              { label: 'No warning / Low (LC50)', value: 'No warning / Low (LC50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToBees',
            label: 'Hazards to bees',
            required: false,
            disabled: null,
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LD50)', value: 'Toxic / Moderate (LD50)' },
              { label: 'No warning / Low (LD50)', value: 'No warning / Low (LD50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'hazardsToWildlife',
            label: 'Hazards to wildlife',
            required: false,
            disabled: null,
            options: [
              { label: 'Extremely toxic', value: 'Extremely toxic' },
              { label: 'Toxic / Moderate (LC50 or LD50)', value: 'Toxic / Moderate (LC50 or LD50)' },
              { label: 'No warning / Low (LC50 or LD50)', value: 'No warning / Low (LC50 or LD50)' },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'soilMobility',
            label: 'Soil mobility',
            required: false,
            disabled: null,
            options: [
              {
                label: 'GUS ≥2 or DPR classifies AI as exceeding SNVs',
                value: 'GUS ≥2 or DPR classifies AI as exceeding SNVs',
              },
              {
                label: 'GUS <2 and Not listed by DPR as exceeding SNVs',
                value: 'GUS <2 and Not listed by DPR as exceeding SNVs',
              },
            ],
          },
          {
            type: FqType.RADIO,
            key: 'persistentBioaccumulativeToxicSubstances',
            label: 'Persistent, Bioaccumulative, Toxic Substances (PBT)',
            required: false,
            disabled: null,
            options: [
              { label: 'Listed', value: 'Listed' },
              { label: 'Not Listed', value: 'Not Listed' },
            ],
          },
          {
            type: FqType.TEXTAREA,
            key: 'hazardsStatement',
            label: 'Hazards Statement',
            required: false,
            disabled: null,
          },
        ],
      },
      {
        type: FqType.LABEL,
        key: 'labels-section',
        label: '### **Labels**',
        altLabel: 'Labels',
      },
      {
        type: FqType.EXTERNAL_FILE,
        key: 'pdfFiles',
        disabled: false,
        baseTemplateUrl: 'https://www3.epa.gov/pesticides/chem_search/ppls',
      },
      {
        type: FqType.LABEL,
        key: 'formulations-section',
        label: '### **Formulations**',
        altLabel: 'Formulations',
      },
      {
        type: FqType.MULTISELECT,
        key: 'formulations',
        label: 'Formulations',
        required: false,
        disabled: null,
        valueType: 'string',
        options: [],
        addNew: true,
      },
      {
        type: FqType.LABEL,
        key: 'pest-section',
        label: '### **Pests**',
        altLabel: 'Pests',
      },
      {
        type: FqType.MULTISELECT,
        key: 'pests',
        label: 'Pests',
        required: false,
        disabled: true,
        valueType: 'string',
        options: [],
        addNew: true,
      },
    ],
  },
];
