/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, FormLabel, Icon, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import { MARKDOWN_OPTIONS } from '../shared/helper';

const useStyles = makeStyles((theme) => ({
  disabledText: {
    fontSize: '0.65rem',
  },
  uploadButton: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  highlight: {
    background: 'rgba(30, 136, 229, 0.1)',
    border: '2px dashed rgba(30, 136, 229, 0.9)',
  },
  textLabel: {
    ...theme.typography.subtitle1,
  },
  rejected: {
    paddingTop: 8,
    color: theme.palette.danger.main,
    fontSize: theme.spacing(1.5),
    textAlign: 'left',
  },
}));

function normalizeFileNames(files) {
  return files.map((file) => {
    const { name } = file;
    const lastDot = name.lastIndexOf('.');
    const fileName = name.substr(0, lastDot);
    const ext = name.substr(lastDot + 1)?.toLowerCase() || '';
    return new File([file], `${fileName}.${ext}`, { type: file.type });
  });
}

const onRejected = (accept, files, maxSize) => {
  return files.reduce((errors, { name, size }) => {
    if (size > maxSize) {
      return errors.concat(`File ${name} exceeds the maximum size allowed.`);
    }
    const normalizedExt = name.split('.').pop().toLowerCase();
    if (name && !accept.replace('image/*', '.bmp,.jpg,.jpeg,.gif,.png,.eps,.raw').includes(normalizedExt)) {
      return errors.concat(`The type of ${name} is not supported. Please try a different file type.`);
    }
    return errors;
  }, []);
};

function Upload({
  accept,
  buttonLabel,
  children,
  disabled,
  id,
  label,
  maxSize,
  multiple,
  onUpload,
  optional,
  variant,
}) {
  const [errorMessages, setErrorMessages] = useState([]);
  const classes = useStyles();
  const uploadInput = useRef(null);

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept,
    disabled,
    onDropAccepted: (files) => {
      onUpload(normalizeFileNames(files));
      setErrorMessages([]);
    },
    onDropRejected: (files) => {
      setErrorMessages(
        onRejected(
          accept,
          files.map((f) => f.file),
          maxSize,
        ),
      );
    },
    maxSize,
    multiple,
    noClick: true,
  });
  const markdownLabel = [
    label?.trim(),
    !optional ? '<span className="text-danger-800 text-14 ml-2">*</span>' : '',
  ].join('');

  return (
    <>
      {variant === 'dropzone' && (
        <>
          <Typography component="div">
            <Markdown options={MARKDOWN_OPTIONS} className="pre-wrap hidden text-14 print:block">
              {markdownLabel}
            </Markdown>
          </Typography>
          <div
            {...getRootProps()}
            className={clsx(
              'flex flex-col items-center px-16 py-32 print:hidden',
              isDragActive ? classes.highlight : '',
              disabled ? '' : 'rounded border-2 border-dashed',
            )}>
            <div>
              <Button
                onClick={open}
                className={clsx('mt-8', classes.uploadButton)}
                disabled={disabled}
                startIcon={<Icon className="mr-5">{disabled ? 'cloud_off' : 'cloud_upload'}</Icon>}>
                {buttonLabel}
                {disabled && <span className={clsx('ml-8', classes.disabledText)}>Disabled</span>}
              </Button>
            </div>
            <div>
              {label && (
                <FormLabel id="uploadLabel" className={classes.textLabel} component="legend">
                  <Markdown options={MARKDOWN_OPTIONS} className="pre-wrap text-14">
                    {markdownLabel}
                  </Markdown>
                </FormLabel>
              )}
              <input {...getInputProps()} aria-labelledby="uploadLabel" />
              {optional && !label && (
                <Typography variant="caption" gutterBottom>
                  Optional
                </Typography>
              )}
            </div>
            {children}
          </div>
        </>
      )}
      {variant === 'icon' && (
        <>
          <input
            accept={accept}
            className="hidden"
            id={`icon_button_file_${id}`}
            type="file"
            ref={uploadInput}
            multiple={multiple}
            onChange={(data) => {
              const files = normalizeFileNames([...data.target.files]);
              const errors = onRejected(accept, files, maxSize);
              if (errors.length) {
                setErrorMessages(errors);
                return null;
              }
              setErrorMessages([]);
              return onUpload(files);
            }}
          />
          <label htmlFor={`icon_button_file_${id}`}>
            <Tooltip title="Add Attachment">
              <IconButton
                component="span"
                onKeyUp={(e) => {
                  e.preventDefault();
                  if (['Enter', ' ', 'Spacebar'].includes(e.key)) {
                    uploadInput.current.click();
                  }
                }}>
                <Icon>attach_file</Icon>
              </IconButton>
            </Tooltip>
          </label>
          {children}
        </>
      )}
      {Boolean(errorMessages.length) &&
        errorMessages.map((message) => (
          <Typography className={classes.rejected} key={message}>
            {message}
          </Typography>
        ))}
    </>
  );
}

Upload.propTypes = {
  accept: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  onUpload: PropTypes.func,
  optional: PropTypes.bool,
  variant: PropTypes.oneOf(['dropzone', 'icon']),
};

Upload.defaultProps = {
  accept: 'image/*,.csv,.xls,.xlsx,.doc,.docx,.pdf,.txt',
  buttonLabel: 'Upload',
  children: null,
  disabled: false,
  id: '',
  label: '',
  maxSize: 50000000,
  multiple: true,
  onUpload: () => {},
  optional: false,
  variant: 'dropzone',
};

export default Upload;
