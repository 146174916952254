"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _require = require('./domain-type.constant'),
  CAMPUS_EHS = _require.CAMPUS_EHS,
  MEDICAL_CENTER_EHS = _require.MEDICAL_CENTER_EHS,
  RISK_SERVICE = _require.RISK_SERVICE;
var tenants = [{
  tenantCode: 'UC',
  name: 'University of California',
  campusList: [{
    campusCode: '00',
    name: 'University of California, Office of the President',
    shortName: 'UCOP',
    idp: 'ucop.edu',
    idps: ['ucop.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucop.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '00_CAMPUS_EHS',
      name: 'Environment, Health & Safety (EHS)',
      type: CAMPUS_EHS
    }, {
      id: '00_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '01',
    name: 'University of California, Berkeley',
    shortName: 'UCB',
    displayName: 'UC Berkeley',
    idp: 'berkeley.edu',
    idps: ['berkeley.edu', 'cognito'],
    uri: 'urn:mace:incommon:berkeley.edu',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'Please visit {link} to update name and email.',
      link: 'https://calnet.berkeley.edu/directory/update/'
    }, {
      text: ' Please visit {link} to update lab roster personnel and locations.',
      link: 'https://app.ehs.berkeley.edu/lab/'
    }],
    domains: [{
      id: '01_CAMPUS_EHS',
      name: 'EH&S',
      type: CAMPUS_EHS
    }, {
      id: '01_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '02',
    name: 'University of California, San Francisco',
    shortName: 'UCSF',
    idp: 'ucsf.edu',
    idps: ['ucsf.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucsf.edu',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'To update your name and email address, visit {link}.',
      link: 'https://ucsf.service-now.com/ucsfit?id=ucsf_index'
    }],
    domains: [{
      id: '02_CAMPUS_EHS',
      name: 'Office of Environment, Health and Safety',
      type: CAMPUS_EHS
    }, {
      id: '02_MEDICAL_CENTER_EHS',
      name: 'UCSF Health’s Environment of Care',
      type: MEDICAL_CENTER_EHS
    }, {
      id: '02_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '03',
    name: 'University of California, Davis',
    shortName: 'UCD',
    displayName: 'UC Davis',
    institution: '004834',
    idp: 'ucdavis.edu',
    idps: ['ucdavis.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucdavis.edu',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'To update your name and email address, visit {link}.',
      link: 'https://itcatalog.ucdavis.edu/service/computing-accounts'
    }],
    domains: [{
      id: '03_CAMPUS_EHS',
      name: 'Safety Services',
      type: CAMPUS_EHS
    }, {
      id: '03_MEDICAL_CENTER_EHS',
      name: 'Med Ctr EH&S',
      type: MEDICAL_CENTER_EHS
    }, {
      id: '03_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '03_H',
    name: 'University of California, Davis Health System',
    shortName: 'UC Davis Health',
    institution: '004834',
    idp: 'ucdavis.edu',
    idps: ['ucdavis.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucdavis.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '03_H_MEDICAL_CENTER_EHS',
      name: 'Med Ctr EH&S',
      type: MEDICAL_CENTER_EHS
    }]
  }, {
    campusCode: '04',
    name: 'University of California, Los Angeles',
    shortName: 'UCLA',
    idp: 'ucla.edu',
    idps: ['ucla.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucla.edu',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'To update your name and email address, visit {link}.',
      link: 'https://accounts.iam.ucla.edu/#/'
    }],
    domains: [{
      id: '04_CAMPUS_EHS',
      name: 'Environment, Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: '04_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }, {
      id: '04_MEDICAL_CENTER_EHS',
      name: 'Medical Center EH&S',
      type: MEDICAL_CENTER_EHS
    }]
  }, {
    campusCode: '05',
    name: 'University of California, Riverside',
    shortName: 'UCR',
    displayName: 'UC Riverside',
    idp: 'ucr.edu',
    idps: ['ucr.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucr.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '05_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: '05_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '06',
    name: 'University of California, San Diego',
    shortName: 'UCSD',
    displayName: 'UC San Diego',
    idp: 'ucsd.edu',
    idps: ['ucsd.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucsd.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '06_CAMPUS_EHS',
      name: 'Environment, Health & Safety (EH&S)',
      type: CAMPUS_EHS
    }, {
      id: '06_MEDICAL_CENTER_EHS',
      name: 'Environmental Health & Safety',
      type: MEDICAL_CENTER_EHS
    }, {
      id: '06_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '07',
    name: 'University of California, Santa Cruz',
    shortName: 'UCSC',
    displayName: 'UC Santa Cruz',
    idp: 'ucsc.edu',
    idps: ['ucsc.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucsc.edu',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'To update your name and email address, visit {link}.',
      link: 'https://its.ucsc.edu/get-help/index.html'
    }],
    domains: [{
      id: '07_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: '07_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '08',
    name: 'University of California, Santa Barbara',
    shortName: 'UCSB',
    displayName: 'UC Santa Barbara',
    idp: 'ucsb.edu',
    idps: ['ucsb.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucsb.edu',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'To update your name and email address, visit {link}.',
      link: 'https://www.im.ucsb.edu/iam/myprofile'
    }],
    domains: [{
      id: '08_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: '08_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '09',
    name: 'University of California, Irvine',
    shortName: 'UCI',
    displayName: 'UC Irvine',
    idp: 'uci.edu',
    idps: ['uci.edu', 'cognito'],
    uri: 'urn:mace:incommon:uci.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '09_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: '09_MEDICAL_CENTER_EHS',
      name: 'Occupational & Environmental Health Services',
      type: MEDICAL_CENTER_EHS
    }, {
      id: '09_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '10',
    name: 'University of California, Merced',
    shortName: 'UCM',
    displayName: 'UC Merced',
    idp: 'ucmerced.edu',
    idps: ['ucmerced.edu', 'cognito'],
    uri: 'urn:mace:incommon:ucmerced.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '10_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }, {
      id: '10_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '94',
    name: 'Lawrence Berkeley National Laboratory',
    shortName: 'LBNL',
    idp: 'lbl.gov',
    idps: ['lbl.gov', 'cognito'],
    uri: 'urn:mace:incommon:lbl.gov',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '94_CAMPUS_EHS',
      name: 'Environment, Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: '94_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '98',
    name: 'UC Hastings College of Law',
    shortName: 'UCHS',
    idp: 'uchastings.edu',
    idps: ['uchastings.edu', 'cognito'],
    uri: 'https://shibboleth.uchastings.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: '98_CAMPUS_EHS',
      name: 'Environment, Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'ANR',
    name: 'University of California, Agriculture and Natural Resources',
    displayName: 'University of California, Agriculture and Natural Resources',
    shortName: 'UC ANR',
    idp: 'ucanr.edu',
    idps: ['ucanr.edu', 'cognito'],
    uri: 'https://ucanridp.ucanr.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'ANR_RISK_MGMT',
      name: 'Risk Mgmt - Safety',
      type: RISK_SERVICE
    }, {
      id: 'ANR_CAMPUS_EHS',
      name: 'Environment, Health & Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'CSU',
  name: 'California State University',
  campusList: [{
    campusCode: 'CSU01',
    name: 'California State University, Office of the Chancellor',
    shortName: 'CSUOC',
    idp: 'co.calstate.edu',
    idps: ['co.calstate.edu', 'cognito'],
    uri: 'https://idp-co.calstate.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: []
  }, {
    campusCode: 'CSU05',
    name: 'California State University, East Bay',
    shortName: 'CSUEB',
    displayName: 'Cal State East Bay',
    idp: 'csueastbay.edu',
    idps: ['csueastbay.edu', 'cognito'],
    uri: 'https://vince.csueastbay.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU05_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU06',
    name: 'California State University, Monterey Bay',
    shortName: 'CSUMB',
    displayName: 'Cal State Monterey Bay',
    idp: 'csumb.edu',
    idps: ['csumb.edu', 'cognito'],
    uri: 'https://sso.csumb.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU06_CAMPUS_EHS',
      name: 'Environmental Health, Safety and Risk Management',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU07',
    name: 'California Maritime Academy',
    shortName: 'CSUM',
    idp: 'csum.edu',
    idps: ['csum.edu', 'cognito'],
    uri: 'https://cma-shibboleth.csum.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU07_CAMPUS_EHS',
      name: 'Health & Safety Management',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU10',
    name: 'California State Polytechnic University, Pomona',
    shortName: 'CPP',
    displayName: 'Cal Poly Pomona',
    idp: 'cpp.edu',
    idps: ['cpp.edu', 'cognito'],
    uri: 'https://idp.cpp.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU10_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU15',
    name: 'California Polytechnic State University, San Luis Obispo',
    shortName: 'CPSLO',
    displayName: 'Cal Poly SLO',
    idp: 'calpoly.edu',
    idps: ['calpoly.edu', 'cognito'],
    uri: 'https://idp.calpoly.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU15_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU20',
    name: 'California State University, Chico',
    shortName: 'Chico State',
    idp: 'csuchico.edu',
    idps: ['csuchico.edu', 'cognito'],
    uri: 'https://shibboleth.csuchico.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU20_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU25',
    name: 'California State University, Fresno',
    shortName: 'Fresno State',
    idp: 'csufresno.edu',
    idps: ['csufresno.edu', 'cognito'],
    uri: 'https://shib-idp.its.csufresno.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU25_CAMPUS_EHS',
      name: 'Environmental Health, Safety, and Risk Management',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU30',
    name: 'Cal Poly Humboldt',
    shortName: 'HSU',
    displayName: 'Humboldt State',
    idp: 'humboldt.edu',
    idps: ['humboldt.edu', 'cognito'],
    uri: 'https://sso.humboldt.edu/idp/metadata',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU30_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU35',
    name: 'California State University, Bakersfield',
    shortName: 'CSUB',
    displayName: 'CSU Bakersfield',
    idp: 'csub.edu',
    idps: ['csub.edu', 'cognito'],
    uri: 'https://sts.windows.net/ab5a62b4-a6bf-460e-8a86-dec0f8001704/',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU35_CAMPUS_EHS',
      name: 'Safety, Risk & Sustainability',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU40',
    name: 'California State University, Long Beach',
    shortName: 'CSULB',
    displayName: 'CSU Long Beach',
    idp: 'csulb.edu',
    idps: ['csulb.edu', 'cognito'],
    uri: 'https://its-shib.its.csulb.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU40_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU45',
    name: 'California State University, Los Angeles',
    shortName: 'Cal State LA',
    idp: 'calstatela.edu',
    idps: ['calstatela.edu', 'cognito'],
    uri: 'https://idpp.calstatela.edu/idp',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU45_CAMPUS_EHS',
      name: 'Risk Management And Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU50',
    name: 'California State University, Fullerton',
    shortName: 'CSUF',
    displayName: 'CSU Fullerton',
    idp: 'fullerton.edu',
    idps: ['fullerton.edu', 'cognito'],
    uri: 'https://shibboleth.fullerton.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU50_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU55',
    name: 'California State University, Dominguez Hills',
    shortName: 'CSUDH',
    displayName: 'CSU Dominguez HIlls',
    idp: 'csudh.edu',
    idps: ['csudh.edu', 'cognito'],
    uri: 'https://idp.csudh.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU55_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU60',
    name: 'California State University, Sacramento',
    shortName: 'CSUS',
    displayName: 'Sacramento State',
    idp: 'csus.edu',
    idps: ['csus.edu', 'cognito'],
    uri: 'https://idp.csus.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU60_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU63',
    name: 'California State University, San Bernardino',
    shortName: 'CSUSB',
    displayName: 'CSU San Bernardino',
    idp: 'csusb.edu',
    idps: ['csusb.edu', 'cognito'],
    uri: 'https://idp.csusb.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU63_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU65',
    name: 'San Diego State University',
    shortName: 'SDSU',
    idp: 'sdsu.edu',
    idps: ['sdsu.edu', 'cognito'],
    uri: 'https://idp.sdsu.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU65_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU68',
    name: 'California State University, San Marcos',
    shortName: 'CSUSM',
    displayName: 'CSU San Marcos',
    idp: 'csusm.edu',
    idps: ['csusm.edu', 'cognito'],
    uri: 'https://idp.csusm.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU68_CAMPUS_EHS',
      name: 'Safety, Health & Sustainability',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU70',
    name: 'California State University, Northridge',
    shortName: 'CSUN',
    idp: 'csun.edu',
    idps: ['csun.edu', 'cognito'],
    uri: 'urn:mace:incommon:csun.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU70_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU73',
    name: 'California State University, Channel Islands',
    shortName: 'CI',
    displayName: 'CSU Channel Islands',
    idp: 'csuci.edu',
    idps: ['csuci.edu', 'cognito'],
    uri: 'https://mckinley.csuci.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU73_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU75',
    name: 'San Francisco State University',
    shortName: 'SFSU',
    displayName: 'SF State',
    idp: 'sfsu.edu',
    idps: ['sfsu.edu', 'cognito'],
    uri: 'https://idp.sfsu.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU75_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: 'CSU75_MEDICAL_CENTER_EHS',
      name: 'Medical Center',
      type: MEDICAL_CENTER_EHS
    }]
  }, {
    campusCode: 'CSU80',
    name: 'San Jose State',
    shortName: 'SJSU',
    idp: 'sjsu.edu',
    idps: ['sjsu.edu', 'cognito'],
    uri: 'https://idp01.sjsu.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU80_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU85',
    name: 'Sonoma State University',
    shortName: 'SSU',
    idp: 'sonoma.edu',
    idps: ['sonoma.edu', 'cognito'],
    uri: 'https://login.sonoma.edu/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU85_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU90',
    name: 'California State University, Stanislaus',
    shortName: 'Stanislaus State',
    idp: 'csustan.edu',
    idps: ['csustan.edu', 'cognito'],
    uri: 'https://idp.csustan.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU90_CAMPUS_EHS',
      name: 'Health & Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CSU94',
    name: 'California State University, Moss Landing Marine Laboratories',
    shortName: 'MLML',
    idp: 'mlml.calstate.edu',
    idps: ['mlml.calstate.edu', 'cognito'],
    uri: 'urn:mace:incommon:mlml.calstate.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CSU94_CAMPUS_EHS',
      name: 'Safety & Risk Management',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'CHOC_T',
  name: "Children's Hospital of Orange County",
  campusList: [{
    campusCode: 'CHOC',
    name: "Children's Hospital of Orange County",
    shortName: 'CHOC',
    idp: 'choc.org',
    idps: ['choc.org'],
    uri: 'https://choc.vmwareidentity.com/SAAS/API/1.0/GET/metadata/idp.xml',
    isHC: false,
    domains: [{
      id: 'CHOC_MEDICAL_CENTER_EHS',
      name: 'EHS',
      type: MEDICAL_CENTER_EHS
    }]
  }]
}, {
  tenantCode: 'MARS',
  name: 'Mars Global Campus',
  campusList: [{
    campusCode: 'MMM',
    name: 'Mars Global Campus',
    shortName: 'Mars Campus',
    idp: 'cognito',
    idps: ['cognito'],
    uri: 'urn.mars:mmm.mars.sol',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'MMM_CAMPUS_EHS',
      name: 'EHS',
      type: CAMPUS_EHS
    }, {
      id: 'MMM_MEDICAL_CENTER_EHS',
      name: 'Health System',
      type: MEDICAL_CENTER_EHS
    }, {
      id: 'MMM_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: 'DEIMOS',
    name: 'Deimos Campus',
    shortName: 'Deimos Campus',
    idp: 'deimos.mars.sol',
    idps: ['cognito'],
    uri: 'urn.mars:deimos.mars.sol',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'DEIMOS_CAMPUS_EHS',
      name: 'EHS',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'HAWAII',
  name: 'University of Hawaii',
  campusList: [{
    campusCode: 'UHuh',
    name: 'University of Hawaii',
    shortName: 'UH',
    displayName: 'UH Manoa',
    idp: 'hawaii.edu',
    idps: ['hawaii.edu'],
    uri: 'https://idp.hawaii.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'UHuh_CAMPUS_EHS',
      name: 'Environmental Health & Safety Office',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'USC_T',
  name: 'University of Southern California',
  campusList: [{
    campusCode: 'USC',
    name: 'University of Southern California',
    shortName: 'USC',
    idp: 'usc.edu',
    idps: ['usc.edu'],
    uri: 'https://shibboleth.usc.edu/shibboleth-idp',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'USC_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'MONTGOMERY_T',
  name: 'Montgomery County',
  campusList: [{
    campusCode: 'MONTGOMERY',
    name: 'Montgomery County',
    displayName: 'Montgomery County',
    shortName: 'MONTGOMERY',
    idp: 'montgomerycountymd.gov',
    idps: ['montgomerycountymd.gov', 'cognito'],
    uri: 'https://ex01.montgomerycountymd.gov/ssoidp2/shibboleth',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'MONTGOMERY_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'HOWARD_T',
  name: 'Howard University',
  campusList: [{
    campusCode: 'HOWARD',
    name: 'Howard University',
    shortName: 'HOWARD',
    displayName: 'Howard University',
    idp: 'howard.edu',
    idps: ['howard.edu'],
    uri: 'https://sts.windows.net/02ac0c07-b75f-46bf-9b13-3630ba94bb69/',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'HU_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'COLGATE_T',
  name: 'Colgate University',
  campusList: [{
    campusCode: 'COLGATE',
    name: 'Colgate University',
    shortName: 'Colgate',
    displayName: 'Colgate University',
    idp: 'colgate.edu',
    idps: ['colgate.edu'],
    uri: 'https://identity.colgate.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'COLGATE_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'NOAA_T',
  name: 'National Oceanic and Atmospheric Administration',
  campusList: [{
    campusCode: 'NOAA',
    name: 'National Oceanic and Atmospheric Administration',
    shortName: 'NOAA',
    idp: 'noaa.gov',
    idps: ['noaa.gov', 'cognito'],
    uri: 'noaa-online-idp',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'NOAA_CAMPUS_EHS',
      name: 'Environmental Safety and Sustainability',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'PSU_T',
  name: 'Penn State University',
  campusList: [{
    campusCode: 'PSU',
    name: 'Penn State University',
    shortName: 'Penn State',
    idp: 'psu.edu',
    idps: ['psu.edu', 'cognito'],
    uri: 'urn:mace:incommon:psu.edu',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'PSU_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'UN',
  name: 'University of Nevada',
  campusList: [{
    campusCode: 'UNR',
    name: 'University of Nevada, Reno',
    shortName: 'UNR',
    idp: 'unr.edu',
    idps: ['unr.edu', 'cognito'],
    uri: 'https://idp2.unr.edu/idp/shibboleth',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'UNR_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'JHU',
  name: 'Johns Hopkins University',
  campusList: [{
    campusCode: 'JHUAPL',
    name: 'Johns Hopkins University - Applied Physics Lab',
    shortName: 'JHUAPL',
    displayName: 'Johns Hopkins APL',
    idp: 'jhuapl.edu',
    idps: ['jhuapl.edu'],
    uri: 'http://www.okta.com/exk4rw9zudtgk0zub4h7',
    isHC: false,
    mobileWebView: 'Blackberry',
    domains: [{
      id: 'JHUAPL_CAMPUS_EHS',
      name: 'EHS',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'UA',
  name: 'University of Alaska',
  campusList: [{
    campusCode: 'UAA',
    name: 'University of Alaska, Anchorage',
    shortName: 'UAA',
    idp: 'alaska.edu',
    idps: ['alaska.edu'],
    uri: 'http://federation.alaska.edu/adfs/services/trust',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'UAA_CAMPUS_EHS',
      name: 'EHSRMS-EM',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'LJI',
  name: 'La Jolla Institute',
  campusList: [{
    campusCode: 'LJII',
    name: 'La Jolla Institute for Immunology',
    shortName: 'LJII',
    displayName: 'LJI',
    idp: 'lji.org',
    idps: ['lji.org'],
    uri: 'http://auth.lji.org/saml-idp/6i2snraixo4lkyxd/metadata',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'LJII_CAMPUS_EHS',
      name: 'Environmental Health & Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'CAGOV',
  name: 'California State Government',
  campusList: [{
    campusCode: 'CDPH',
    name: 'California Department of Public Health',
    shortName: 'CDPH',
    idp: 'cdph.ca.gov',
    idps: ['cdph.ca.gov', 'cognito', 'Google'],
    uri: 'https://sts.windows.net/1f311b51-f6d9-4153-9bac-55e0ef9641b8',
    isHC: true,
    mobileWebView: null,
    idleTimeoutInMinutes: 30,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'CDPH_CHCQ',
      name: 'Center for Healthcare Quality',
      type: MEDICAL_CENTER_EHS
    }]
  }, {
    campusCode: 'CDPH.TRAINING',
    name: 'TEST-CDPH',
    shortName: 'TEST-CDPH',
    idp: 'cdph.ca.gov.training',
    idps: ['cdph.ca.gov.training', 'cognito'],
    uri: 'https://sts.windows.net/1f311b51-f6d9-4153-9bac-55e0ef9641b8/.training',
    isHC: true,
    mobileWebView: null,
    idleTimeoutInMinutes: 30,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'CDPH_CHCQ_TRAINING',
      name: 'Center for Healthcare Quality',
      type: MEDICAL_CENTER_EHS
    }]
  }]
}, {
  tenantCode: 'RSS',
  name: 'Risk and Safety Solutions',
  campusList: [{
    campusCode: 'YOLO',
    name: 'Risk and Safety Solutions Davis, CA',
    shortName: 'RSS Davis',
    idp: 'Google',
    idps: ['Google', 'cognito'],
    uri: 'https://auth.riskandsafety.com/rss/yolo',
    isHC: true,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    mobileWebView: null,
    domains: [{
      id: 'YOLO_RISK_SERVICE',
      name: 'Risk Services',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: 'DEMO',
    name: 'Risk and Safety Solutions Demo',
    shortName: 'RSS Demo',
    idp: 'cognito',
    idps: ['cognito'],
    uri: 'https://auth.riskandsafety.com/rss/demo',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'DEMO_RISK_SERVICE',
      name: 'Risk Services',
      type: RISK_SERVICE
    }, {
      id: 'DEMO_CAMPUS_EHS',
      name: 'Environmental Health and Safety',
      type: CAMPUS_EHS
    }]
  }, {
    campusCode: 'CST',
    name: 'Risk and Safety Solutions Training',
    shortName: 'RSS Training',
    idp: 'cognito',
    idps: ['cognito'],
    uri: 'https://auth.riskandsafety.com/rss/cst',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CST_RISK_SERVICE',
      name: 'Risk Services',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: '03_TRAINING',
    name: 'University of California, Davis RSS Training',
    shortName: 'UCD RSS Training',
    displayName: 'RSS Training',
    idp: 'ucdavis.edu.training',
    idps: ['ucdavis.edu.training'],
    uri: 'urn:mace:incommon:ucdavis.edu.training',
    isHC: false,
    mobileWebView: null,
    dataSourceDescription: [{
      text: 'To update your name and email address, visit {link}.',
      link: 'https://itcatalog.ucdavis.edu/service/computing-accounts'
    }],
    domains: [{
      id: '03_TRAINING_RISK_SERVICE',
      name: 'Risk Services (Training)',
      type: RISK_SERVICE
    }]
  }, {
    campusCode: 'CI',
    name: 'Risk and Safety Solutions CI',
    shortName: 'Risk and Safety Solutions CI',
    displayName: 'Risk and Safety CI',
    idp: 'rssci.com',
    idps: ['rssci.com', 'cognito'],
    uri: 'https://auth.riskandsafety.com/rss/ci',
    isHC: false,
    mobileWebView: null,
    domains: [{
      id: 'CI_CAMPUS_EHS',
      name: 'Environment, Health & Safety',
      type: CAMPUS_EHS
    }, {
      id: 'CI_RISK_MGMT',
      name: 'Risk Management',
      type: RISK_SERVICE
    }, {
      id: 'CI_MEDICAL_CENTER_EHS',
      name: 'Medical Center',
      type: MEDICAL_CENTER_EHS
    }]
  }, {
    campusCode: 'VAULT',
    name: 'Risk and Safety Course Vault',
    shortName: 'VAULT',
    idp: 'cognito',
    idps: ['cognito'],
    uri: 'https://auth.riskandsafety.com/rss/vault',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'VAULT_RISK_SERVICE',
      name: 'Risk Services',
      type: RISK_SERVICE
    }]
  }]
}, {
  tenantCode: 'UOP_T',
  name: 'University of the Pacific',
  campusList: [{
    campusCode: 'UOP',
    name: 'University of the Pacific',
    shortName: 'UOP',
    displayName: 'Pacific',
    idp: 'pacific.edu',
    idps: ['pacific.edu', 'cognito'],
    uri: 'http://www.okta.com/exk1jjmblxcFl1L7m1d8',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'UOP_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'OXY_T',
  name: 'Occidental College',
  campusList: [{
    campusCode: 'OXY',
    name: 'Occidental College',
    shortName: 'OXY',
    displayName: 'Occidental College',
    idp: 'oxy.edu',
    idps: ['oxy.edu'],
    uri: 'https://sts.windows.net/2320ddbf-7cca-4a06-ad80-adbaec09adac/',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'OXY_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'CARB_T',
  name: 'California Air Resources Board',
  campusList: [{
    campusCode: 'CARB',
    name: 'California Air Resources Board',
    shortName: 'CARB',
    idp: 'arb.ca.gov',
    idps: ['arb.ca.gov', 'cognito', 'Google'],
    uri: 'https://sts.windows.net/9de5aaee-7788-40b1-a438-c0ccc98c87cc/',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'CARB_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'BSU_T',
  name: 'Ball State University',
  campusList: [{
    campusCode: 'BSU',
    name: 'Ball State University',
    shortName: 'BSU',
    idp: 'bsu.edu',
    idps: ['bsu.edu', 'cognito', 'google'],
    uri: 'http://federate.bsu.edu/sso',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'BSU_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'UW_T',
  name: 'University of Waterloo',
  campusList: [{
    campusCode: 'UW',
    name: 'University of Waterloo',
    shortName: 'UW',
    idp: 'uwaterloo.ca',
    idps: ['uwaterloo.ca', 'cognito', 'google'],
    uri: 'http://adfs.uwaterloo.ca/adfs/services/trust',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'UW_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'SDSMT_T',
  name: 'South Dakota School of Mines',
  campusList: [{
    campusCode: 'SDSMT',
    name: 'South Dakota School of Mines',
    shortName: 'SDSMT',
    idp: 'sdsmt.edu',
    idps: ['sdsmt.edu', 'cognito'],
    uri: 'https://sts.windows.net/867083e6-0b14-435f-9b4e-8be26f01d84e/',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'SDSMT_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'NIH',
  name: 'National Institutes of Health',
  campusList: [{
    campusCode: 'NIAID',
    name: 'National Institute of Allergy and Infectious Diseases',
    shortName: 'NIAID',
    idp: 'nih.gov',
    idps: ['nih.gov', 'cognito'],
    uri: 'https://sts.windows.net/14b77578-9773-42d5-8507-251ca2dc2b06/',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'NIAID_CAMPUS_EHS',
      name: 'Risk Mgmt - Safety',
      type: CAMPUS_EHS
    }]
  }]
}, {
  tenantCode: 'KISCO_T',
  name: 'Kisco Senior Living',
  campusList: [{
    campusCode: 'KISCO',
    name: 'Kisco Senior Living',
    shortName: 'KISCO',
    idp: 'kiscosl.com',
    idps: ['kiscosl.com', 'cognito'],
    uri: 'https://sts.windows.net/b7ee390c-5678-4d3a-bcd0-eb689bdf76b6/',
    isHC: false,
    mobileWebView: null,
    idleTimeoutInMinutes: 20,
    idleTimeoutWarningInMinutes: 1,
    domains: [{
      id: 'KISCO_RISK_SERVICE',
      name: 'Risk Services',
      type: RISK_SERVICE
    }]
  }]
}, {
  tenantCode: 'GLOBAL',
  name: 'Global Tenant',
  campusList: [{
    campusCode: 'GLOBAL',
    name: 'Global Campus',
    shortName: 'Global',
    idp: '',
    idps: [],
    uri: '',
    isHC: false,
    domains: []
  }]
}];
var getTenants = function getTenants() {
  return tenants;
};
var getTenantByCampusCode = function getTenantByCampusCode(campusCode) {
  return tenants.find(function (t) {
    return t.campusList.some(function (c) {
      return c.campusCode === campusCode;
    });
  });
};
var getCampus = function getCampus(campusCode) {
  var tenant = getTenantByCampusCode(campusCode);
  if (!tenant) return null;
  return tenant.campusList.find(function (c) {
    return c.campusCode === campusCode;
  });
};
var getTenant = function getTenant(tenant) {
  return tenants.find(function (t) {
    return t.tenantCode === tenant;
  });
};
var institutionByCampusCode = function institutionByCampusCode(campusCode) {
  var tenant = getTenantByCampusCode(campusCode);
  var campus = getCampus(campusCode);
  if (!campus) return null;
  return tenant.campusList.filter(function (c) {
    return c.institution && c.institution === campus.institution;
  });
};
var isMainCampus = function isMainCampus(campusCode) {
  return !campusCode.match(/(_H)|(_h)/);
};

// maintain default export as tenant with only main campuses
module.exports = tenants.reduce(function (acc, cur) {
  return acc.concat(_objectSpread(_objectSpread({}, cur), {}, {
    campusList: cur.campusList.filter(function (c) {
      return isMainCampus(c.campusCode);
    })
  }));
}, []);
module.exports.getMainCampusCode = function (campusCode) {
  var campuses = institutionByCampusCode(campusCode);
  if (!campuses || !campuses.length) return campusCode;
  return campuses.find(function (c) {
    return isMainCampus(c.campusCode);
  }).campusCode;
};
module.exports.getTenants = getTenants;
module.exports.getCampus = getCampus;
module.exports.getTenant = getTenant;
module.exports.getTenantByCampusCode = getTenantByCampusCode;
module.exports.isMainCampus = isMainCampus;
module.exports.institutionByCampusCode = institutionByCampusCode;
module.exports.campusCodeToTenantCode = function (campusCode) {
  var tenant = getTenantByCampusCode(campusCode);
  return tenant ? tenant.tenantCode : null;
};
module.exports.getCampusListByTenantCode = function (tenantCode) {
  var matchTenant = getTenant(tenantCode);
  return matchTenant ? matchTenant.campusList : [];
};

// TODO :: change to flatmap when we move to node 12
module.exports.findCampusByCampusCode = function (campusCode) {
  var match = null;
  var matchTenant = null;
  tenants.find(function (tenant) {
    match = tenant.campusList.find(function (campus) {
      return campus.campusCode === campusCode;
    });
    matchTenant = function (_ref) {
      var tenantCode = _ref.tenantCode,
        name = _ref.name;
      return {
        tenantCode: tenantCode,
        name: name
      };
    }(tenant);
    return match;
  });
  return _objectSpread(_objectSpread({}, match), match && {
    tenant: matchTenant
  });
};
module.exports.findCampusByUri = function (uri) {
  var match = null;
  var matchTenant = null;
  tenants.find(function (tenant) {
    match = tenant.campusList.find(function (campus) {
      return campus.uri === uri;
    });
    matchTenant = function (_ref2) {
      var tenantCode = _ref2.tenantCode,
        name = _ref2.name;
      return {
        tenantCode: tenantCode,
        name: name
      };
    }(tenant);
    return match;
  });
  return _objectSpread(_objectSpread({}, match), match && {
    tenant: matchTenant
  });
};
var domains = tenants.reduce(function (acc, cur) {
  return acc.concat.apply(acc, _toConsumableArray(cur.campusList.reduce(function (accCampus, curCampus) {
    return accCampus.concat.apply(accCampus, _toConsumableArray(curCampus.domains.map(function (d) {
      return _objectSpread(_objectSpread({}, d), {}, {
        campus: {
          campusCode: curCampus.campusCode,
          name: curCampus.name,
          shortName: curCampus.shortName
        },
        tenant: {
          tenantCode: cur.tenantCode,
          name: cur.name
        }
      });
    })));
  }, [])));
}, []);
var getDomain = function getDomain(domainId) {
  return domains.find(function (d) {
    return d.id === domainId;
  });
};
module.exports.getCampusByDomainId = function (domainId) {
  var matchDomain = getDomain(domainId);
  return matchDomain ? getCampus(matchDomain.campus.campusCode) : null;
};
module.exports.domains = domains;
module.exports.getDomain = getDomain;
module.exports.getCampusByDomainId = getDomain;