import { lazy } from 'react';

import { hasConfigManagerRole } from '../shared/helper';

const ManageTemplateDetailsPage = lazy(() => import('./containers/ManageTemplateDetailsPage'));

const routes = {
  auth: ({ profile }) => hasConfigManagerRole(profile),
  routes: [
    {
      path: [
        '/rss-tools/notification-template/create',
        '/rss-tools/notification-template/:id/customize/create',
        '/rss-tools/notification-template/:id/:campusCode',
      ],
      component: ManageTemplateDetailsPage,
    },
  ],
};

export default routes;
