const {
  RssModel
} = require('@rss/common');
const yup = require('yup');
class PPEDetails extends RssModel {
  constructor(opt = {}) {
    super(opt);
    if (opt.defaults) {
      this.defaults = opt.defaults;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      questions: yup.array().of(yup.object().shape({
        type: yup.string(),
        items: yup.array().of(yup.object().shape({
          label: yup.string(),
          value: yup.string()
        }))
      })),
      defaults: yup.object().noUnknown(false)
    });
  }
}
module.exports = PPEDetails;