import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import { CONTAINER_TYPE } from '@risk-and-safety/chemical';
import { CATALOG_TYPE } from '@risk-and-safety/library';
import { Divider, LinearProgress, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import qs from 'qs';
import numeral from 'numeral';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useHistory } from 'react-router-dom';

import { PRODUCTS_FOR_SHOPPING } from '../graphql/query';
import config from '../../config';

const useStyles = makeStyles({
  item: {
    textDecoration: 'none!important',
    color: 'inherit',
  },
});

function ShopSearch({ store }) {
  const history = useHistory();
  const classes = useStyles();

  const { q } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { loading, data, fetchMore, refetch } = useQuery(PRODUCTS_FOR_SHOPPING, {
    variables: { keyword: q, storeId: store._id, offset: 0, limit: config.LIMIT },
  });

  useEffect(() => {
    refetch({
      variables: { keyword: q },
    });
  }, [q, refetch]);

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div className="flex flex-grow flex-col space-y-14 overflow-x-auto px-28">
      {data?.products.length === 0 && (
        <div className="flex h-full w-full flex-auto items-center justify-center">
          <Typography color="textSecondary" variant="h5">
            No products were found matching your search &quot;{q}&quot;. Try searching by CAS number or chemical name.
          </Typography>
        </div>
      )}

      <InfiniteScroll
        hasMore={data?.products?.length % config.LIMIT === 0 && data?.products?.length > 0}
        loadMore={() => fetchMore({ variables: { offset: data?.products?.length } })}
        useWindow={false}
        loader={<LinearProgress className="clear-both" key={0} />}>
        <div>
          {data?.products.map((product) => {
            return (
              <div key={product._id}>
                <div className="flex space-x-24 py-14">
                  <div className="w-192 flex-none">
                    <Link to={`/shop/${store._id}/product/${product._id}${history.location.search}`}>
                      {product.images && product.images.length > 0 && <img alt="Product" src={product.images[0]} />}
                      {!product.images ||
                        (product.images.length === 0 && (
                          <img
                            alt="Product"
                            // eslint-disable-next-line max-len
                            src={`http://${config.RSS_APP_BUCKET}.s3.us-west-2.amazonaws.com/library/public/no-product.png`}
                          />
                        ))}
                    </Link>
                  </div>
                  <div className="flex-1 pt-14">
                    <Typography className="mb-4 text-18">
                      <Link
                        to={`/shop/${store._id}/product/${product._id}${history.location.search}`}
                        className={classes.item}>
                        {product.name}
                      </Link>
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      {product.description}
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      <span className="inline-block pr-8">Product Number:</span> {product.productNumber}
                    </Typography>
                    <Typography className="text-16" color="textSecondary">
                      {product.catalog === CATALOG_TYPE.CHEMICAL &&
                        `${product?.details?.size || ''}${product?.details?.units || ''} ${
                          CONTAINER_TYPE.find((item) => item.value === product?.details?.type)?.label || ''
                        }`}
                    </Typography>
                    <Typography className="text-20">
                      <Link
                        to={`/shop/${store._id}/product/${product._id}${history.location.search}`}
                        className={classes.item}
                        color="inherit">
                        {numeral(product?.price).format('$0,0.00')}
                      </Link>
                    </Typography>
                  </div>
                </div>
                <Divider key={`divider-${product._id}`} />
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
}

ShopSearch.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShopSearch;
