import { useQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { RELATIONSHIP_LABEL } from '@rss/common';
import { StoreAccount } from '@risk-and-safety/library';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core';
import { ObjectId } from 'bson';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { FIND_SUBLOCATION_BY_INVENTORY, ADD_DELIVERY_LOCATION, UPDATE_DELIVERY_LOCATION } from '../graphql/query';
import { ButtonProgress, BuildingSearch, RoomSearch } from '../../components';

const SCHEMA = new StoreAccount().schema();
const SCHEMA_DELIVERY_LOCATIONS = SCHEMA.fields.deliveryLocations.innerType;

function AccountDeliveryLocation({ route, store, account }) {
  const { locationId } = useParams();
  const history = useHistory();

  const navigateBack = () => history.goBack();

  const isInventory = account.administeredBy.label === RELATIONSHIP_LABEL.NODE.INVENTORY;

  const { data } = useQuery(FIND_SUBLOCATION_BY_INVENTORY, {
    variables: { inventoryId: account.administeredBy.id },
    skip: !isInventory,
  });

  const usedSublocations = account.deliveryLocations.map((item) => item.sublocationId);
  const filterSublocations =
    data?.findSublocationByInventory.filter((item) => !usedSublocations.includes(item._id)) || [];

  const buildings =
    uniqBy(
      filterSublocations.map((item) => ({
        buildingId: item.buildingId,
        buildingName: item.buildingName,
      })),
      'buildingId',
    ) || [];

  const [addStoreAccountDeliveryLocation, { loading: addLoading }] = useMutation(ADD_DELIVERY_LOCATION, {
    onCompleted: navigateBack,
  });

  const [updateStoreAccountDeliveryLocation, { loading: updateLoading }] = useMutation(UPDATE_DELIVERY_LOCATION, {
    onCompleted: navigateBack,
  });

  const location = account.deliveryLocations.find((item) => item.id === locationId);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      id: location?.id || new ObjectId().toString(),
      buildingId: location?.buildingId || '',
      buildingName: location?.buildingName || '',
      floorId: location?.floorId || '',
      floorName: location?.floorName || '',
      roomId: location?.roomId || '',
      roomNumber: location?.roomNumber || '',
      sublocationId: location?.sublocationId || '',
      sublocationName: location?.sublocationName || '',
      default: location?.default || false,
    },
    resolver: yupResolver(SCHEMA_DELIVERY_LOCATIONS),
  });

  const onSave = (value) => {
    if (location) {
      updateStoreAccountDeliveryLocation({
        variables: { id: account._id, deliveryLocation: value, storeId: store._id },
      });
    } else {
      addStoreAccountDeliveryLocation({ variables: { id: account._id, deliveryLocation: value, storeId: store._id } });
    }
  };
  return (
    <div className="flex-grow pt-3">
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSave)} className="p-24">
        <div className="absolute left-48 top-136 flex h-64 items-center">
          <IconButton onClick={navigateBack}>
            <Icon>arrow_back</Icon>
          </IconButton>
        </div>
        <div className="absolute right-48 top-136 flex h-64 items-center">
          <ButtonProgress loading={addLoading || updateLoading} type="submit" variant="contained" color="primary">
            Save
          </ButtonProgress>
        </div>
        <div className="flex items-center">
          <Typography className="mb-24 text-20 font-medium" color="textSecondary">
            Delivery / Transfer Location
          </Typography>
        </div>

        {isInventory && (
          <>
            <div className="mb-20">
              <Controller
                name="buildingId"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl
                      disabled={buildings.length === 0}
                      className="w-full"
                      variant="outlined"
                      error={!!(errors?.buildingName || errors?.buildingId)}
                      required>
                      <InputLabel id="building-label">Building</InputLabel>
                      <Select
                        {...field}
                        labelId="building-label"
                        label="Building"
                        onChange={(event) => {
                          const building = buildings.find((item) => item.buildingId === event.target.value);
                          setValue('buildingId', building.buildingId);
                          setValue('buildingName', building.buildingName);
                          setValue('floorId', '');
                          setValue('floorName', '');
                          setValue('roomId', '');
                          setValue('roomNumber', '');
                          setValue('sublocationId', '');
                          setValue('sublocationName', '');
                        }}>
                        {buildings?.map((item) => (
                          <MenuItem value={item.buildingId} key={item.buildingId}>
                            {item.buildingName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
              />

              {buildings.length === 0 && (
                <FormHelperText id="building-error-text" className="text-red-500">
                  There is no other available sublocation associated with the inventory.
                </FormHelperText>
              )}
            </div>

            <div className="mb-20">
              <Controller
                name="roomId"
                control={control}
                render={({ field }) => {
                  const rooms =
                    uniqBy(
                      filterSublocations
                        .filter((item) => item.buildingId === watch().buildingId)
                        .map((item) => ({
                          floorId: item.floorId,
                          floorName: item.floorName,
                          roomId: item.roomId,
                          roomNumber: item.roomNumber,
                        })),
                      'roomId',
                    ) || [];

                  return (
                    <FormControl
                      disabled={rooms.length === 0}
                      className="w-full"
                      variant="outlined"
                      error={!!(errors?.roomNumber || errors?.roomId)}
                      required>
                      <InputLabel id="room-label">Room</InputLabel>
                      <Select
                        {...field}
                        labelId="room-label"
                        label="Room"
                        onChange={(event) => {
                          const room = rooms.find((item) => item.roomId === event.target.value);
                          setValue('floorId', room.floorId);
                          setValue('floorName', room.floorName);
                          setValue('roomId', room.roomId);
                          setValue('roomNumber', room.roomNumber);
                          setValue('sublocationId', '');
                          setValue('sublocationName', '');
                        }}>
                        {rooms?.map((item) => (
                          <MenuItem value={item.roomId} key={item.roomId}>
                            {item.roomNumber}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </div>
            <div className="mb-20">
              <Controller
                name="sublocationId"
                control={control}
                render={({ field }) => {
                  const sublocations = filterSublocations.filter((item) => item.roomId === watch().roomId) || [];
                  return (
                    <FormControl
                      disabled={sublocations.length === 0}
                      className="w-full"
                      variant="outlined"
                      error={!!(errors?.sublocationName || errors?.sublocationId)}>
                      <InputLabel id="sublocation-label">Sublocation</InputLabel>
                      <Select
                        {...field}
                        labelId="sublocation-label"
                        label="Sublocation"
                        onChange={(event) => {
                          const sublocation = sublocations.find((item) => item._id === event.target.value);
                          setValue('sublocationId', sublocation._id);
                          setValue('sublocationName', sublocation.name);
                        }}>
                        {sublocations?.map((item) => (
                          <MenuItem value={item._id} key={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }}
              />
            </div>
          </>
        )}

        {!isInventory && (
          <>
            <div className="mb-20">
              <Controller
                name="buildingName"
                control={control}
                render={({ field: { value } }) => (
                  <>
                    <BuildingSearch
                      aria-label="search-building"
                      floatingText="Search Building"
                      hintText="Search Building"
                      value={{ primaryName: value }}
                      onSelect={(selected) => {
                        setValue('buildingId', selected.buildingId);
                        setValue('buildingName', selected.primaryName);
                      }}
                      onClear={() => {
                        setValue('buildingId', '');
                        setValue('buildingName', '');
                        setValue('floorId', '');
                        setValue('floorName', '');
                        setValue('roomId', '');
                        setValue('roomNumber', '');
                        setValue('sublocationId', '');
                        setValue('sublocationName', '');
                      }}
                    />
                    {!!errors.buildingId && (
                      <FormHelperText id="search-building-error-text" className="text-red-500">
                        Building is required
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>

            <div className="mb-12">
              <Controller
                name="roomNumber"
                control={control}
                render={({ field: { value } }) => (
                  <>
                    <RoomSearch
                      aria-label="search-room"
                      floatingText="Search Room"
                      hintText="Search Room"
                      buildingId={watch().buildingId}
                      isDisabled={!watch().buildingId}
                      value={{ roomNumber: value }}
                      onSelect={(selected) => {
                        setValue('floorId', '');
                        setValue('floorName', selected.floor.name);
                        setValue('roomId', selected.roomId);
                        setValue('roomNumber', selected.roomNumber);
                      }}
                      onClear={() => {
                        setValue('floorId', '');
                        setValue('floorName', '');
                        setValue('roomId', '');
                        setValue('roomNumber', '');
                        setValue('sublocationId', '');
                        setValue('sublocationName', '');
                      }}
                    />
                    {!!errors.roomId && (
                      <FormHelperText id="search-building-error-text" className="text-red-500">
                        Room is required
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>
          </>
        )}

        <Controller
          name="default"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
              label="Default"
            />
          )}
        />
      </form>
    </div>
  );
}

AccountDeliveryLocation.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccountDeliveryLocation;
