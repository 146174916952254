"use strict";

module.exports = {
  SLIP_RESISTANT_SHOES: {
    label: 'Slip-Resistant Shoes',
    value: 'SLIP_RESISTANT_SHOES'
  },
  FLAME_RESISTANT_LAB_COAT: {
    label: 'Flame Resistant Lab Coat',
    value: 'FLAME_RESISTANT_LAB_COAT'
  }
};