const {
  RELATIONSHIP_LABEL: {
    EDGE
  },
  RelationshipHelper
} = require('@rss/common');
const {
  get
} = require('lodash');
module.exports.getInventoryPermissions = (inventoryId, user, campusCode = '') => {
  let isMember = false;
  let isManager = false;
  let isOwner = false;
  let isChemAdmin = false;
  let isChemAdminReadOnly = false;
  let isInventorySpecialist = false;
  if (inventoryId) {
    isMember = RelationshipHelper.hasRelationshipWithRole(inventoryId.toString(), user.relationships, EDGE.MEMBER);
    isManager = RelationshipHelper.hasRelationshipWithRole(inventoryId.toString(), user.relationships, [EDGE.INVENTORY_MANAGER, EDGE.DELEGATE]);
    isOwner = RelationshipHelper.hasRelationshipWithRole(inventoryId.toString(), user.relationships, EDGE.OWNER);
  }
  if (campusCode) {
    isChemAdmin = RelationshipHelper.hasRelationshipWithRole(campusCode, user.relationships, EDGE.CHEMICAL_ADMIN);
    isChemAdminReadOnly = RelationshipHelper.hasRelationshipWithRole(campusCode, user.relationships, EDGE.CHEMICAL_ADMIN_READ_ONLY);
    isInventorySpecialist = RelationshipHelper.hasRelationshipWithRole(campusCode, user.relationships, [EDGE.INVENTORY_SPECIALIST]);
  }
  return {
    isMember,
    isManager: isManager || isInventorySpecialist,
    isOwner,
    isChemAdmin,
    isChemAdminReadOnly
  };
};
module.exports.isColleague = (inventory, user) => {
  if (inventory.colleagues && Array.isArray(inventory.colleagues)) {
    return inventory.colleagues.some(c => RelationshipHelper.hasRelationship(user.relationships, c._id.toString()));
  }
  return false;
};
module.exports.isMemberOfInventory = (inventoryId, user, campusCode = '') => {
  const permissions = module.exports.getInventoryPermissions(inventoryId, user, campusCode);
  return permissions.isMember || permissions.isManager || permissions.isOwner || permissions.isChemAdmin || permissions.isChemAdminReadOnly;
};
module.exports.isManagerOfInventory = (inventoryId, user, campusCode = '') => {
  const permissions = module.exports.getInventoryPermissions(inventoryId, user, campusCode);
  return permissions.isManager || permissions.isOwner || permissions.isChemAdmin;
};
module.exports.isOwnerOfInventory = (inventoryId, user) => {
  const permissions = module.exports.getInventoryPermissions(inventoryId, user);
  return permissions.isOwner;
};
module.exports.isMemberOrColleagueOfInventory = (inventory, user) => {
  const permissions = module.exports.getInventoryPermissions(inventory._id, user, inventory.campusCode);
  return permissions.isMember || permissions.isManager || permissions.isOwner || permissions.isChemAdmin || permissions.isChemAdminReadOnly || module.exports.isColleague(inventory, user);
};
module.exports.isChemInfo = user => RelationshipHelper.hasAnyOfRoles(user.relationships, [EDGE.CHEM_INFO]);
module.exports.hasRoleWithReadAccessToInventory = (inventoryId, user) => get(user, 'inventoriesWithReadAccess', []).includes(inventoryId.toString());