"use strict";

module.exports = {
  RECOMMENDED: {
    label: 'Recommended',
    value: 'RECOMMENDED'
  },
  COMPLETED: {
    label: 'Completed',
    value: 'COMPLETED'
  },
  EXPIRED: {
    label: 'Expired',
    value: 'EXPIRED'
  },
  NEEDS_RENEWAL: {
    label: 'Needs Renewal',
    value: 'NEEDS_RENEWAL'
  },
  ORDER_IN_PROGRESS: {
    label: 'Order in Progress',
    value: 'ORDER_IN_PROGRESS'
  }
};