import { useMutation, useQuery } from '@apollo/client';
import FuseAnimate from '@fuse/core/FuseAnimate';
import FuseLoading from '@fuse/core/FuseLoading';
import {
  Button,
  Checkbox,
  Fab,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import clsx from 'clsx';
import { includes } from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PROFILE_QUERY } from '../graphql/profile.query';
import {
  CREATE_EDGE,
  DEACTIVATE_EDGES,
  FIND_PEOPLE_WITH_ROLE,
  GET_ROUTING_DETAILS,
} from '../graphql/relationship.query';
import { showMessage } from '../store/fuse/messageSlice';
import { getDisplayName, sortData } from '../shared/helper';
// eslint-disable-next-line import/no-cycle
import { DialogTemplate, PersonDisplay, PersonSearch } from '.';
import { ErrorMessage } from './errors';

const {
  NODE: { GLOBAL, ROUTING_GROUP, TENANT, USER },
} = RELATIONSHIP_LABEL;

export const formatPeople = (people = []) => {
  return people.map((person) => {
    const {
      node: { id, email, firstName, lastName },
    } = person;
    return {
      nodeId: id,
      name: `${lastName}, ${firstName}`,
      email,
      firstName,
      lastName,
    };
  });
};

const RoleCard = ({ campusCode, isEditable, parent, role, tenantCode, members, addTitle, domainId }) => {
  const [message, setMessage] = useState(null);
  const [orderBy, setOrderBy] = useState('asc');
  const [orderByProperty, setOrderByProperty] = useState('name');
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [personId, setPersonId] = useState(null);
  const labelScope = [TENANT, GLOBAL].includes(parent.label);

  const dispatch = useDispatch();
  const roleName = `${getDisplayName(role)}`;

  useEffect(() => {
    if (role) setSelectedUserIds([]);
    if (message) {
      dispatch(
        showMessage({
          message,
          autoHideDuration: 15 * 1000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        }),
      );
      setMessage(null);
    }
  }, [dispatch, message, role]);

  const { data, loading, error } = useQuery(FIND_PEOPLE_WITH_ROLE, {
    skip: parent.label === ROUTING_GROUP,
    variables: { edgeLabel: [role], to: parent.id, page: 0 },
  });
  const { findPeopleWithRole: usersList } = data || { findPeopleWithRole: members || [] };

  const getRefetchQueries = () =>
    parent.label === ROUTING_GROUP
      ? [{ query: GET_ROUTING_DETAILS, variables: { id: parent.id, type: parent.label, domainId } }]
      : [
          {
            query: FIND_PEOPLE_WITH_ROLE,
            variables: { edgeLabel: [role], to: parent.id, page: 0 },
          },
          { query: PROFILE_QUERY },
        ];

  const [createEdge] = useMutation(CREATE_EDGE, {
    onCompleted: () => setPersonId(null),
    onError: () => setMessage('An error has occured while assigning role'),
    refetchQueries: getRefetchQueries,
  });

  const [deactivateEdges] = useMutation(DEACTIVATE_EDGES, {
    onError: () => setMessage('An error has occured while removing role'),
    refetchQueries: getRefetchQueries,
  });

  const rows = [
    isEditable && { id: 'actions', label: '', width: 20 },
    { id: 'name', label: 'Name', width: 200 },
    { id: 'email', align: 'inherit', label: 'Email', width: 0 },
  ].filter(Boolean);

  const handleSelect = (event, id) => {
    let newSelected = [];
    const selectedEdges = [];
    newSelected = !includes(selectedUserIds, id)
      ? newSelected.concat(selectedUserIds, id)
      : selectedUserIds.filter((item) => item !== id);
    const userIds = users.map((user) => user.nodeId);
    const selectedUserIdsList = newSelected.filter((selUser) => userIds.find((userId) => userId === selUser));
    (selectedUserIdsList || []).forEach((selUser) => {
      (users || []).forEach((user) => {
        if (user.nodeId === selUser) {
          selectedEdges.push(user);
        }
      });
    });
    setSelectedUserIds(newSelected);
    setSelectedUsers(selectedEdges);
  };

  const handleSortByProperty = (property) => {
    const isAsc = orderByProperty === property && orderBy === 'asc';
    setOrderBy(isAsc ? 'desc' : 'asc');
    setOrderByProperty(property);
  };

  const users = sortData(formatPeople(usersList), orderByProperty, orderBy);

  if (loading) return <FuseLoading />;
  if (error) return <ErrorMessage />;
  const personSearchVariant = labelScope && campusCode && campusCode === 'ALL' ? 'campus' : 'person';

  return (
    <>
      <div className="flex w-full flex-col">
        <Table className="mb-64" stickyHeader aria-label="people list" size="small">
          <TableHead>
            <TableRow className="h-64">
              {rows.map(({ id, label, width }) => (
                <TableCell
                  className={clsx(
                    'whitespace-no-wrap h-64 min-h-64',
                    id === 'actions' && selectedUserIds.length && 'whitespace-pre-wrap  pl-16 pr-0',
                  )}
                  key={id}
                  component={label === '' ? 'td' : 'th'}
                  width={width}
                  sortDirection={orderByProperty === id ? orderBy : false}>
                  {id === 'actions' && selectedUserIds.length ? (
                    <IconButton size="small" className="text-center" onClick={() => setIsConfirmationOpen(true)}>
                      <Tooltip title="Remove person(s)" placement="top" arrow>
                        <Icon className="m-2">delete</Icon>
                      </Tooltip>
                    </IconButton>
                  ) : null}
                  {label && (
                    <TableSortLabel
                      active={orderByProperty === id}
                      direction={orderByProperty === id ? orderBy : 'asc'}
                      onClick={() => handleSortByProperty(id)}>
                      {label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(users || []).length ? (
              users.map((user) => {
                const isUserSelected = selectedUserIds.indexOf(user.nodeId) !== -1;
                return (
                  <TableRow
                    key={user.nodeId}
                    tabIndex={-1}
                    role="checkbox"
                    className={clsx('py-16', isEditable && 'cursor-pointer')}
                    selected={isUserSelected}
                    onClick={(event) => handleSelect(event, user.nodeId)}>
                    {isEditable && (
                      <TableCell className="py-4 pl-16 pr-0 text-center" padding="none">
                        <Checkbox
                          color="primary"
                          checked={isUserSelected}
                          size="small"
                          inputProps={{ 'aria-label': user.nodeId }}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <PersonDisplay firstName={user.firstName} lastName={user.lastName} />
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={rows.length}>
                  <Typography align="center" variant="body2" color="textSecondary">
                    No {pluralize(getDisplayName(role))} found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {isEditable && (
          <div className="ml-auto mr-56 pb-8 pr-8">
            <FuseAnimate animation="transition.expandIn" delay={600}>
              <Tooltip title={addTitle} arrow>
                <Fab
                  onClick={() => setIsAddDialogOpen(true)}
                  color="primary"
                  aria-label="add"
                  className="absolute bottom-16">
                  <Icon>add</Icon>
                </Fab>
              </Tooltip>
            </FuseAnimate>
          </div>
        )}
      </div>
      {isAddDialogOpen && (
        <DialogTemplate
          open={isAddDialogOpen}
          title={`Add ${getDisplayName(role)}`}
          titleContent={
            <div className="space-y-16">
              <PersonSearch
                id="person-search-dialog"
                autoFocus={!labelScope}
                campusCode={campusCode}
                tenantCode={tenantCode}
                clearOnSelect={false}
                floatingText="Search for person"
                variant={personSearchVariant}
                onSelect={(person) => setPersonId(person.id || person.userId)}
                filter={(usersList || []).filter(({ edge: { endDate } }) => !endDate).map((user) => user.node.id)}
              />
            </div>
          }
          primaryActions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setPersonId(null);
                  setIsAddDialogOpen(false);
                }}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={!personId}
                onClick={() => {
                  setIsAddDialogOpen(false);
                  createEdge({
                    variables: {
                      from: { id: personId, label: USER },
                      to: { id: parent.id, label: parent.label },
                      edge: { label: role },
                    },
                  });
                }}>
                Add
              </Button>
            </>
          }
        />
      )}
      {isConfirmationOpen && (
        <DialogTemplate
          open={isConfirmationOpen}
          color="secondary"
          content={
            <>
              <p className="pt-10 pb-2 text-base">
                Are you sure you want to remove the {roleName} role for the following user(s)?
              </p>
              {selectedUsers.length && (
                <ul className="mt-8 mb-10 ml-28 list-disc space-y-12">
                  {selectedUsers.map((user) => (
                    <li key={user.nodeId}>
                      <p className="text-md">
                        <PersonDisplay firstName={user.firstName} lastName={user.lastName} />
                      </p>
                    </li>
                  ))}
                </ul>
              )}
            </>
          }
          primaryActions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setIsConfirmationOpen(false);
                  setSelectedUserIds([]);
                  setSelectedUsers([]);
                }}>
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setIsConfirmationOpen(false);
                  setSelectedUserIds([]);
                  setSelectedUsers([]);
                  deactivateEdges({
                    variables: {
                      edges: selectedUsers.map((user) => ({
                        from: { id: user.nodeId, label: USER },
                        to: { id: parent.id, label: parent.label },
                        edge: { label: role },
                      })),
                    },
                  });
                }}>
                Remove
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

RoleCard.propTypes = {
  campusCode: PropTypes.string,
  isEditable: PropTypes.bool,
  parent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
  tenantCode: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.object),
  addTitle: PropTypes.string,
  domainId: PropTypes.string,
};

RoleCard.defaultProps = {
  campusCode: null,
  isEditable: false,
  tenantCode: null,
  members: [],
  addTitle: 'Assign Role',
  domainId: null,
};

export default RoleCard;
