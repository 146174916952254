import FuseAnimate from '@fuse/core/FuseAnimate';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const Title = () => (
  <FuseAnimate animation="transition.slideUpIn" duration={400} delay={100}>
    <Typography variant="h1" color="inherit" className="flex flex-1 flex-grow-0 text-28 sm:text-52">
      We&apos;re here to help
    </Typography>
  </FuseAnimate>
);

export default Title;
