const ObjectId = require('bson').ObjectID;
const clone = require('clone');
class FamilyReference {
  constructor(opt) {
    this._id = null;
    this.density = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = opt._id ? new ObjectId(opt._id) : null;
    this.density = opt.density || {};
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'family-reference.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        density: {
          type: 'object'
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = FamilyReference;