import { useLazyQuery } from '@apollo/client';
import { CircularProgress, Icon, InputAdornment, TextField } from '@material-ui/core';
import { BARCODE_PATTERN } from '@risk-and-safety/chemical';
import { TENANTS } from '@rss/common';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useBarcodeScanner from '../hooks/useBarcodeScanner';
import { VALIDATE_BARCODE } from '../graphql/query';

const isBarcodePatternValid = (campusCode, tenantCode, barcode) => {
  return barcode && get(BARCODE_PATTERN, `${tenantCode}.${campusCode}`, BARCODE_PATTERN.DEFAULT).test(barcode);
};

function BarcodeInput({
  campusCode,
  label,
  placeholder,
  onValidBarcode,
  onInvalidDuplicateBarcode,
  onInvalidPatternBarcode,
}) {
  const [currentBarcode, setCurrentBarcode] = useState();
  const tenantCode = TENANTS.getTenantByCampusCode(campusCode).tenantCode;
  const [validateBarcode, { loading }] = useLazyQuery(VALIDATE_BARCODE, {
    onCompleted: (data) => {
      if (data?.validateBarcode) {
        onValidBarcode(currentBarcode);
      } else {
        onInvalidDuplicateBarcode(currentBarcode);
      }
      setCurrentBarcode('');
    },
    fetchPolicy: 'network-only',
  });

  useBarcodeScanner({
    onScan: (value) => {
      setCurrentBarcode(value);
      if (onInvalidPatternBarcode && !isBarcodePatternValid(campusCode, tenantCode, value)) {
        onInvalidPatternBarcode(value);
        setCurrentBarcode('');
        return;
      }
      if (onInvalidDuplicateBarcode) {
        validateBarcode({ variables: { barcode: value } });
      } else {
        onValidBarcode(value);
        setCurrentBarcode('');
      }
    },
  });

  return (
    <TextField
      id="barcode"
      name="barcode"
      label={label}
      placeholder={placeholder}
      autoFocus
      autoComplete="off"
      value={currentBarcode || ''}
      onKeyPress={({ key, target: { value } }) => {
        if (key === 'Enter' && value.length) {
          if (onInvalidPatternBarcode && !isBarcodePatternValid(campusCode, tenantCode, value)) {
            onInvalidPatternBarcode(value);
            setCurrentBarcode('');
            return;
          }
          if (onInvalidDuplicateBarcode) {
            validateBarcode({ variables: { barcode: value } });
          } else {
            onValidBarcode(value);
            setCurrentBarcode('');
          }
        }
      }}
      onChange={({ target: { value } }) => {
        if (!loading) {
          setCurrentBarcode(value);
        }
        if (isBarcodePatternValid(campusCode, tenantCode, value)) {
          if (onInvalidDuplicateBarcode) {
            validateBarcode({ variables: { barcode: value } });
          } else {
            onValidBarcode(value);
            setCurrentBarcode('');
          }
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon className="text-gray-300">qr_code_scanner</Icon>
          </InputAdornment>
        ),
        endAdornment: <InputAdornment position="end">{loading && <CircularProgress size={20} />}</InputAdornment>,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      className="mb-16 mt-8"
      variant="outlined"
      fullWidth
    />
  );
}

BarcodeInput.propTypes = {
  campusCode: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onValidBarcode: PropTypes.func.isRequired,
  onInvalidPatternBarcode: PropTypes.func,
  onInvalidDuplicateBarcode: PropTypes.func,
};

BarcodeInput.defaultProps = {
  label: '',
  placeholder: '',
  onInvalidPatternBarcode: null,
  onInvalidDuplicateBarcode: null,
};

export default BarcodeInput;
