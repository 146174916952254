import { LibraryItem } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import LibraryTypeSearch from '../library-type-search/LibraryTypeSearch';
// eslint-disable-next-line import/no-cycle
import FqFollowup from './FqFollowup';
import { QUESTION_VARIANT } from './Helper';

const SHOULD_SAVE = true;

const buildLink = (libraryType, id) => `/library/${libraryType.toLowerCase()}/${id.toString()}`;

const FqLibraryType = ({
  onChange,
  value,
  libraryType,
  showTagFilter,
  showDetailsLink,
  followup,
  followupLabel,
  question,
  placeHolder,
  disabled,
  tagLabel,
  shouldValidate,
  variant,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelected = (val) => {
    if (val) {
      const model = { ...new LibraryItem(val), _id: val._id.toString() };
      const item = {
        ...model,
        key: `${model._id}_${value ? value.length : 0}`,
        link: showDetailsLink && buildLink(libraryType, val._id.toString()),
      };
      const onChangeValue = value ? [...value, item] : [item];
      if (!item.details) {
        item.details = val?.details;
      }
      setSelectedItem(item);
      onChange(onChangeValue, question.key);
    }
  };

  const handleChange = (val) => {
    onChange(val, question.key, SHOULD_SAVE);
    setSelectedItem(null);
  };

  return (
    <div className="my-8">
      {!disabled && (
        <LibraryTypeSearch
          value={followup ? '' : value}
          onChange={onChange}
          onSelect={(val) => handleSelected(val)}
          libraryType={libraryType}
          showTagFilter={showTagFilter}
          hintText={placeHolder}
          followup={followup}
          followupLabel={followupLabel}
          disabled={disabled}
          tagLabel={tagLabel}
          label={question.label}
          variant={variant}
        />
      )}
      <FqFollowup
        selectedItem={selectedItem}
        question={question}
        followupKey={followup}
        followupKeyLabel={followupLabel}
        value={(value || []).map((v) => ({
          ...v,
          link: showDetailsLink && buildLink(libraryType, v._id.toString()),
        }))}
        onChange={handleChange}
        disabled={disabled}
        shouldValidate={shouldValidate}
        variant={variant}
        showTagFilter={showTagFilter}
      />
    </div>
  );
};

FqLibraryType.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  libraryType: PropTypes.string.isRequired,
  followup: PropTypes.string,
  followupLabel: PropTypes.string,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  placeHolder: PropTypes.string,
  tagLabel: PropTypes.string,
  disabled: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  showTagFilter: PropTypes.bool,
  showDetailsLink: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FqLibraryType.defaultProps = {
  followup: null,
  value: [],
  placeHolder: '',
  disabled: false,
  tagLabel: 'Select',
  shouldValidate: false,
  showTagFilter: true,
  showDetailsLink: false,
  followupLabel: 'Type',
  variant: QUESTION_VARIANT.STANDARD,
};

export default React.memo(FqLibraryType);
