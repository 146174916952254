import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import BuildingSearch from './BuildingSearch';
import RoomSearch from './RoomSearch';

const BuildingRoomSearch = ({
  autoFocus,
  buildingId,
  buildingName,
  filter,
  onChange,
  roomId,
  roomNumber,
  showSuggestions,
  suggestions,
  suggestionsLimit,
}) => {
  const [building, setBuilding] = useState({ buildingId, buildingName });
  const [room, setRoom] = useState({ roomId, roomNumber });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange({ ...building, ...room }), [building, room]);
  return (
    <div className="grid flex-1 grid-cols-1 flex-wrap gap-16 sm:grid-cols-2 sm:gap-8">
      <BuildingSearch
        aria-label="search-building"
        floatingText="Search Building"
        hintText="Search Building"
        autoFocus={autoFocus}
        onSelect={(selected) => {
          selected && setBuilding({ buildingId: selected.buildingId, buildingName: selected.primaryName });
        }}
        onClear={() => {
          setBuilding({ buildingId: null, buildingName: null });
          setRoom({ roomId: null, roomNumber: null });
        }}
        value={building.buildingName}
        suggestions={suggestions}
        showSuggestions={showSuggestions}
        suggestionsLimit={suggestionsLimit}
      />
      <RoomSearch
        aria-label="search-room"
        buildingId={building.buildingId}
        isDisabled={!building.buildingId && !building.buildingName}
        floatingText="Search Room"
        hintText="Search Room"
        autoFocus={autoFocus}
        filter={filter}
        onSelect={(selected) => {
          selected && setRoom({ roomId: selected.roomId, roomNumber: selected.roomNumber });
        }}
        onClear={() => setRoom({ roomId: null, roomNumber: null })}
        suggestions={
          suggestions && building.buildingId
            ? (suggestions.find((s) => s.buildingId === building.buildingId) || {}).rooms
            : null
        }
        showSuggestions={showSuggestions}
        suggestionsLimit={suggestionsLimit}
        value={room.roomNumber}
      />
    </div>
  );
};

BuildingRoomSearch.propTypes = {
  autoFocus: PropTypes.bool,
  buildingId: PropTypes.string,
  buildingName: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  roomId: PropTypes.string,
  roomNumber: PropTypes.string,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      buildingId: PropTypes.string,
      buildingName: PropTypes.string,
      rooms: PropTypes.arrayOf(
        PropTypes.shape({
          roomId: PropTypes.string,
          roomNumber: PropTypes.string,
        }),
      ),
    }),
  ),
  showSuggestions: PropTypes.bool,
  suggestionsLimit: PropTypes.number,
};

BuildingRoomSearch.defaultProps = {
  autoFocus: false,
  buildingId: '',
  buildingName: '',
  filter: [],
  roomId: '',
  roomNumber: '',
  suggestions: null,
  showSuggestions: false,
  suggestionsLimit: 20,
};

export default BuildingRoomSearch;
