const clone = require('clone');
const tv4 = require('tv4');
class SubstanceComponent {
  constructor(opt) {
    this.cas = null;
    this.name = null;
    this.min = null;
    this.max = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.cas = opt.cas || null;
    this.name = opt.name || null;
    this.min = parseFloat(opt.min) || null;
    this.max = parseFloat(opt.max) || null;
  }
  validate() {
    return tv4.validateMultiple(this, SubstanceComponent.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'substance-component.schema.json',
      type: 'object',
      properties: {
        cas: {
          type: ['string', 'null']
        },
        name: {
          type: ['string', 'null']
        },
        min: {
          type: ['number', 'null']
        },
        max: {
          type: ['number', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = SubstanceComponent;