import { combineReducers } from '@reduxjs/toolkit';
import auth from '../auth/store';
import fuse from './fuse';
import app from './app';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    fuse,
    app,
    ...asyncReducers,
  });

  /*
	Reset the redux store except for shoppingcart when user logged out
	 */
  if (action.type === 'auth/user/userLoggedOut') {
    state = { app: { shoppingCart: { ...state.app.shoppingCart } } };
  }

  return combinedReducer(state, action);
};

export default createReducer;
