import { useMutation } from '@apollo/client';
import { ObjectId } from 'bson';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import config from '../config';
import Upload from './Upload';

const PRESIGNED_URLS = gql`
  mutation preSignedUrls($id: ID!, $feature: String!, $fileNames: [String]) {
    fetchPreSignedUrls(id: $id, feature: $feature, fileNames: $fileNames)
  }
`;

const SCAN_FILES = gql`
  mutation scanFiles($id: ID!, $feature: String!, $fileNames: [String]) {
    fetchScanFiles(id: $id, feature: $feature, fileNames: $fileNames)
  }
`;

const SIGNED_URLS = gql`
  mutation signedUrls($id: ID!, $feature: String!, $fileNames: [String]) {
    fetchSignedUrls(id: $id, feature: $feature, fileNames: $fileNames)
  }
`;

const AttachFileS3 = ({
  accept,
  buttonLabel,
  children,
  disabled,
  feature,
  id,
  label,
  multiple,
  optional,
  onChange,
  variant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [getPresignedUrls] = useMutation(PRESIGNED_URLS);
  const [scanFiles] = useMutation(SCAN_FILES);
  const [getSignedUrls] = useMutation(SIGNED_URLS);

  const handleUpload = async (files) => {
    const driveData = {
      variables: { id: new ObjectId(), feature, fileNames: files.map((f) => f.name) },
    };
    const presignedUrlData = await getPresignedUrls(driveData);
    const uploadedFiles = await Promise.all(
      presignedUrlData.data.fetchPreSignedUrls
        .filter((d) => !d.error)
        .map((d) => {
          const data = new FormData();
          const file = files.find((f) => f.name === d.fileMetadata.originalname);
          if (d && d.fields) {
            for (const key in d.fields) {
              if (d.fields[key]) {
                data.append(key, d.fields[key]);
              }
            }
          }

          data.append('file', file, file.name);
          const options = {
            method: 'POST',
            body: data,
            accept: 'application/json',
          };
          return fetch(d.url, options)
            .then(() => ({
              _id: d.fileMetadata._id,
              name: d.fileMetadata.name,
              url: `https://rss-drive-safe-${config.ENV}.s3.us-west-2.amazonaws.com/${d.fields.key}`,
            }))
            .catch(() => ({}));
        }),
    );
    driveData.variables.fileNames = uploadedFiles.map((file) => file.name);
    await new Promise((r) => setTimeout(() => r(), 1000));
    const signedUrls = await getSignedUrls(driveData);
    onChange(uploadedFiles.map((d, i) => ({ _id: d._id, url: signedUrls.data.fetchSignedUrls[i]?.signedUrl })));
  };

  return (
    <Upload
      accept={accept}
      buttonLabel={buttonLabel}
      label={label}
      multiple={multiple}
      disabled={disabled || isLoading}
      id={id}
      optional={optional}
      onUpload={handleUpload}
      variant={variant}>
      {children}
    </Upload>
  );
};

AttachFileS3.propTypes = {
  accept: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  feature: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  variant: PropTypes.oneOf(['dropzone', 'icon']),
};

AttachFileS3.defaultProps = {
  accept: 'image/*,.csv,.xls,.xlsx,.doc,.docx,.pdf,.txt',
  buttonLabel: 'Upload File',
  children: null,
  disabled: false,
  id: '',
  label: 'Drag and drop some files here, or click to select files',
  multiple: true,
  optional: false,
  onChange: () => null,
  variant: 'dropzone',
};

export default AttachFileS3;
