"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.didUserComplete = didUserComplete;
exports.filterIncompleteSections = filterIncompleteSections;
exports.getEventDate = getEventDate;
exports.hasCertifiedOrAcknowledged = hasCertifiedOrAcknowledged;
exports.hasOutcomes = hasOutcomes;
exports.hasSomeResponse = hasSomeResponse;
exports.isAssessmentFormComplete = isAssessmentFormComplete;
exports.isSectionComplete = isSectionComplete;
var _lodash = require("lodash");
var _moment = _interopRequireDefault(require("moment"));
var _edgeEvent = _interopRequireDefault(require("../constant/relationship/edge-event.constant"));
var _label = _interopRequireDefault(require("../constant/relationship/label.constant"));
var _relationship = _interopRequireDefault(require("./relationship.helper"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var EVENT = _label["default"].EDGE_TYPE.EVENT,
  _RELATIONSHIP_LABEL$N = _label["default"].NODE,
  BUA = _RELATIONSHIP_LABEL$N.BUA,
  GROUP = _RELATIONSHIP_LABEL$N.GROUP,
  LAB_HAZARD = _RELATIONSHIP_LABEL$N.LAB_HAZARD,
  ROOM = _RELATIONSHIP_LABEL$N.ROOM;
function hasCertifiedOrAcknowledged() {
  var events = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return events.some(function (e) {
    return [_edgeEvent["default"].ACKNOWLEDGED, _edgeEvent["default"].CERTIFIED].includes(e);
  });
}
var hasAssessmentCompleted = function hasAssessmentCompleted(assessment, type) {
  switch (type) {
    case LAB_HAZARD:
      return Boolean(assessment.expirationDate);
    case BUA:
      return !['AMEND', 'AMENDMENT_DRAFT', 'DRAFT', 'RENEWAL_DRAFT'].includes(assessment.status);
    default:
      break;
  }
  return false;
};
function didUserComplete(assessment, userId, isAdmin) {
  var _ref = assessment || {},
    _ref$relationships = _ref.relationships,
    relationships = _ref$relationships === void 0 ? [] : _ref$relationships,
    status = _ref.status,
    _ref$template$assessm = _ref.template.assessmentTypeConfig,
    assessmentTypeConfig = _ref$template$assessm === void 0 ? {} : _ref$template$assessm;
  if (isAdmin) {
    return hasAssessmentCompleted(assessment, assessmentTypeConfig.type);
  }
  var events = relationships.filter(function (relationship) {
    return relationship.node.id === userId && relationship.edge.type === EVENT;
  }).map(function (l) {
    return l.edge.label;
  });
  if (assessmentTypeConfig.type === BUA) {
    return !['AMEND', 'AMENDMENT_DRAFT', 'DRAFT', 'RENEWAL_DRAFT'].includes(status) && !hasCertifiedOrAcknowledged(events);
  }
  return hasCertifiedOrAcknowledged(events);
}
function hasOutcomes(assessment) {
  var report = assessment.report.outcomes || assessment.report.summary;
  return Object.values(report).some(function (value) {
    return value.length;
  });
}
function hasSomeResponse(assessment) {
  var responses = assessment.responses;
  return responses && Object.keys(responses).length;
}
function isSectionComplete(assessment, section) {
  var responses = assessment.responses;
  if (section.questions.every(function (question) {
    return !question.required;
  })) {
    return true;
  }

  // TODO: check nested questions
  return responses && responses[section.key] && section.questions.every(function (question) {
    var questionResponses = responses[section.key][question.key];
    if (Array.isArray(questionResponses)) {
      return questionResponses.length > 0;
    }
    return questionResponses || !question.required;
  });
}
function filterIncompleteSections(template, assessment) {
  return template.questions.filter(function (section) {
    return !isSectionComplete(assessment, section);
  });
}
function getEventDate(assessment, eventType) {
  var events = assessment.relationships.filter(function (r) {
    return (0, _lodash.get)(r, 'edge.type') === EVENT && (0, _lodash.get)(r, 'edge.label') === eventType;
  });
  var lastEventDate = events.length && new Date(Math.max.apply(Math, _toConsumableArray(events.map(function (e) {
    return new Date(e.edge.startDate);
  }))));
  return lastEventDate ? (0, _moment["default"])(lastEventDate) : '';
}
function isAssessmentFormComplete(assessment) {
  var relationships = assessment.relationships,
    template = assessment.template;
  var incompleteSections = filterIncompleteSections(template, assessment);
  if (template.parentType === GROUP) {
    var hasLocations = _relationship["default"].hasAnyOfTypes(relationships, [ROOM]);
    return hasLocations && !incompleteSections.length;
  }
  return !incompleteSections.length;
}
var _default = exports["default"] = {
  didUserComplete: didUserComplete,
  filterIncompleteSections: filterIncompleteSections,
  getEventDate: getEventDate,
  hasCertifiedOrAcknowledged: hasCertifiedOrAcknowledged,
  hasOutcomes: hasOutcomes,
  hasSomeResponse: hasSomeResponse,
  isAssessmentFormComplete: isAssessmentFormComplete,
  isSectionComplete: isSectionComplete
};