import { Icon, Link as Href, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const ActionCardContent = ({ icon, title, description, variant }) => (
  <div className="flex items-center">
    <Icon className={clsx('my-12', variant === 'large' ? 'mx-24 text-40' : 'mx-20 text-28')} color="primary">
      {icon}
    </Icon>
    <div className="text-wrap">
      <Typography className={clsx('text-18', variant === 'large' ? 'pb-8' : 'pb-2')}>{title}</Typography>
      {description && (
        <Typography color="textSecondary" className="mr-6">
          {description}
        </Typography>
      )}
    </div>
  </div>
);

ActionCardContent.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  variant: PropTypes.string,
};

const ActionCard = ({ icon, link, title, description, variant, external }) => {
  return (
    <Paper className={clsx('mb-16 rounded-8 py-16 shadow-1', variant === 'large' && 'min-w-256')}>
      {!external ? (
        <Link to={link} style={{ textDecoration: 'none' }}>
          <ActionCardContent icon={icon} title={title} description={description} variant={variant} />
        </Link>
      ) : (
        <Href href={link} style={{ textDecoration: 'none' }}>
          <ActionCardContent icon={icon} title={title} description={description} variant={variant} />
        </Href>
      )}
    </Paper>
  );
};

ActionCard.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  variant: PropTypes.string,
  external: PropTypes.bool,
};

ActionCard.defaultProps = {
  variant: 'large',
  external: false,
};

export default ActionCard;
