import FuseAnimate from '@fuse/core/FuseAnimate';
import { Icon, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import AccessDenied from './AccessDenied';

const ErrorMessage = ({ message }) => {
  return /403/.test(message) ? (
    <AccessDenied />
  ) : (
    <div className="flex flex-1 flex-col items-center justify-center p-16">
      <div className="max-w-512 space-y-16 text-center">
        <FuseAnimate delay={100}>
          <Typography variant="h1" className="font-medium text-gray-500">
            <Icon className="-mb-8 text-96" color="error">
              whatshot
            </Icon>
            Oops!
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography variant="h5" color="textSecondary">
            {message}
          </Typography>
        </FuseAnimate>

        <FuseAnimate delay={500}>
          <Typography color="textSecondary">
            If you need help, please contact <a href="mailto:service@riskandsafety.com">service@RiskandSafety.com</a>.
          </Typography>
        </FuseAnimate>
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  message: 'The application has generated an error that we did not anticipate.',
};

export default ErrorMessage;
