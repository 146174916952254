import { RESOLUTION_STATUS, REPORT_STATUS } from '@risk-and-safety/inspect-v2-common';
import { DateHelper } from '@rss/common';
import { differenceInDays, formatDistanceToNow } from 'date-fns';
import { difference, remove, sortBy } from 'lodash';
import moment from 'moment';

const templateLabelToAction = {
  REVIEW_REPORT: REPORT_STATUS.REVIEW,
  REVISE_REPORT: REPORT_STATUS.DRAFT,
  RESOLVE_REPORT: REPORT_STATUS.RESOLUTION,
  DONE: REPORT_STATUS.DONE,
};

export function reminderToNotification(reminder) {
  return {
    email: {
      subject: reminder?.email?.subject || '',
      html: reminder?.email?.bodyHtml || '',
      to: reminder?.details?.toRecipients?.map((recipient) => recipient.email) || [],
    },
    ...reminder,
  };
}

export function resolveDate(createdDate, daysToResolve) {
  const today = moment();
  const dueDate = moment(createdDate).add(daysToResolve, 'days');
  const diff = today.diff(dueDate, 'days');
  const absDiff = Math.abs(diff);
  const due = `Due ${DateHelper.formatDate(dueDate)}`;

  if (diff < 0) {
    return `${due} ${absDiff} day${absDiff === 1 ? ' ' : 's'} remaining to resolve`;
  }

  if (diff === 0) {
    return `${due} Due today`;
  }

  return `${due} ${diff} day${absDiff === 1 ? ' ' : 's'} overdue`;
}

export function getLogWithPrevStatus(resolutionLog) {
  const logWithPrevStatus = [];
  let prevStatus;
  resolutionLog.forEach((event) => {
    const { status } = event;
    if (status !== RESOLUTION_STATUS.COMMENT) {
      logWithPrevStatus.push({ ...event, prevStatus });
      prevStatus = status;
    } else {
      logWithPrevStatus.push({ ...event });
    }
  });
  return logWithPrevStatus;
}

function buildAttachmentString(attachments) {
  return attachments?.length ? `${attachments.length} attachment${attachments.length > 1 ? 's' : ''}` : '';
}

export function buildEventText(event) {
  const { attachments, comment, status } = event;

  const hasAttachment = Boolean(attachments?.length);
  const changedStatus = status && status !== RESOLUTION_STATUS.COMMENT;
  const attachmentString = buildAttachmentString(attachments);

  if (changedStatus && comment && hasAttachment) {
    return `changed the status, added a comment, and uploaded ${attachmentString}`;
  }
  if (changedStatus && comment) {
    return 'changed the status and added a comment';
  }
  if (changedStatus && hasAttachment) {
    return `changed the status and uploaded ${attachmentString}`;
  }
  if (comment && hasAttachment) {
    return `added a comment and ${attachmentString}`;
  }
  if (changedStatus) {
    return 'changed the status';
  }
  if (comment) {
    return 'added a comment';
  }
  if (hasAttachment) {
    return `uploaded ${attachmentString}`;
  }
  return 'did something';
}

export function isMoreThanAWeekAgo(dateString) {
  return differenceInDays(new Date(), new Date(dateString)) > 7;
}

export function buildTimestamp(dateString) {
  const date = new Date(dateString);
  if (isMoreThanAWeekAgo(dateString)) {
    return DateHelper.formatDate(date, DateHelper.DATETIME_FORMAT);
  }
  return formatDistanceToNow(date, { addSuffix: true, includeSeconds: true });
}

export function combineEventsAndReminders(events, reminders) {
  const doneReminders = sortBy(
    reminders.filter((r) => r.status === 'DONE'),
    (r) => new Date(r.details?.sendDate),
  );

  const notifications = doneReminders.map(reminderToNotification);
  const seen = [];

  const eventsWithReminders = events.map((e) => ({
    ...e,
    notifications: difference(notifications, seen).reduce((acc, notification) => {
      const alreadyExists = Boolean(acc.find((n) => n.details.templateLabel === notification.details.templateLabel));
      if (!alreadyExists && templateLabelToAction[notification.details.templateLabel] === e.action) {
        acc.push(notification);
        seen.push(notification);
      }
      return acc;
    }, []),
  }));

  const transientEvents = doneReminders.reduce((acc, reminder) => {
    if (
      ['OVERDUE_RESOLUTIONS', 'NOT_RESOLVED_REMINDER', 'READY_FOR_VERIFICATION'].includes(
        reminder.details?.templateLabel,
      )
    ) {
      acc.push({
        date: reminder?.details?.sendDate,
        description: 'An email reminder was sent',
        notifications: [reminderToNotification(reminder)],
      });
    }
    return acc;
  }, []);

  return sortBy([...eventsWithReminders, ...transientEvents], (event) => new Date(event.date));
}
