import { COMPLIANCE_STATUS } from '@risk-and-safety/inspect-v2-common';
import { cloneDeep } from 'lodash';

export const FILTERS = {
  COMPLIANT_INCIDENTS: 'showNoncompliantOnly',
  NAMES: 'includePeopleNames',
  PRIVATE_COMMENTS: 'includePrivateNotesAndComments',
  RESOLUTIONS: 'includeResolutions',
  THUMBNAILS: 'includeThumbnails',
};

function redact(obj) {
  return {
    ...obj,
    createdBy: { firstName: '--', lastName: '--' },
    lastModifiedBy: { firstName: '--', lastName: '--' },
  };
}

export function applyFilters(report, questionnaire, resolutions, filters = {}) {
  const filteredReport = {
    ...report,
    subject: {
      ...report.subject,
      notes: (() => {
        let tmpNotes = report.subject.notes;
        if (!filters[FILTERS.PRIVATE_COMMENTS]) {
          tmpNotes = tmpNotes.filter(({ isPrivate }) => !isPrivate);
        }

        if (!filters[FILTERS.NAMES]) {
          tmpNotes = tmpNotes.map(redact);
        }

        if (!filters[FILTERS.THUMBNAILS]) {
          tmpNotes = tmpNotes.map((note) => ({ ...note, attachments: [] }));
        }

        return tmpNotes;
      })(),
    },
    categories: report.categories.map((category) => ({
      ...category,
      responses: category.responses.reduce((responses, response) => {
        if (
          filters[FILTERS.COMPLIANT_INCIDENTS] &&
          response.values.some((r) => r.value === COMPLIANCE_STATUS.COMPLIANT)
        ) {
          return responses;
        }

        let tmpResponse = cloneDeep(response);

        if (!filters[FILTERS.NAMES]) {
          tmpResponse = redact(tmpResponse);
        }

        tmpResponse.incidents = tmpResponse.incidents.map((incident) => {
          let tmpIncident = incident;
          if (!filters[FILTERS.NAMES]) {
            tmpIncident = redact(tmpIncident);
            tmpIncident.comments = tmpIncident.comments.map(redact);
          }

          if (!filters[FILTERS.PRIVATE_COMMENTS]) {
            tmpIncident.comments = tmpIncident.comments.filter(({ isPrivate }) => !isPrivate);
          }

          if (!filters[FILTERS.THUMBNAILS]) {
            tmpIncident = { ...tmpIncident, attachments: [] };
          }
          return tmpIncident;
        });

        responses.push(tmpResponse);

        return responses;
      }, []),
    })),
  };

  const filteredQuestionnaire = {
    ...questionnaire,
    categories: questionnaire.categories
      .map((category) => {
        // we should be making the assumption keys are unique to the questionnaire, not category
        // do we want to drop this logic of scoping to category first?
        let tmpCategory = cloneDeep(category);
        const reportCategory = filteredReport.categories.find(({ key }) => key === category.key);
        if (!reportCategory?.responses?.length) {
          tmpCategory = null;
        } else {
          tmpCategory.questions = category.questions.filter((question) =>
            reportCategory.responses.some(({ key }) => key === question.key),
          );
        }
        return tmpCategory;
      })
      .filter(Boolean),
  };

  const filteredResolutions =
    !filters[FILTERS.RESOLUTIONS] || !resolutions
      ? []
      : resolutions.map((res) => ({
          ...res,
          log: res.log.map((event) => {
            let tmpEvent = cloneDeep(event);

            if (!filters[FILTERS.NAMES]) {
              tmpEvent = redact(tmpEvent);
            }

            if (!filters[FILTERS.THUMBNAILS]) {
              tmpEvent = { ...tmpEvent, attachments: [] };
            }

            return tmpEvent;
          }),
        }));

  return { report: filteredReport, questionnaire: filteredQuestionnaire, resolutions: filteredResolutions };
}
