import gql from 'graphql-tag';

const BRANDING_QUERY = gql`
  query branding {
    branding {
      appList {
        code
        contextUrl
        cssClass
        displayName
        shortName
        sort
      }
      brand {
        favicon
        signoutContextUrl
        suiteLandingUrl
        suiteTitle
      }
      faviconUrl
      footerLogoUrl
      privacyPolicyUrl
      serviceMap
    }
  }
`;

export default BRANDING_QUERY;
