import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { FormattedDate, RichTextEditor } from '../../../components';

export function IncidentSummary({ className, incident }) {
  return (
    <div className={className}>
      <div className="flex w-full flex-col items-start sm:flex-row sm:items-center">
        <Typography className="italic">
          Reported on <FormattedDate date={incident.createdDate} /> by {incident.createdBy.firstName}{' '}
          {incident.createdBy.lastName}
        </Typography>

        {incident.requiresVerification && <div className="ml-5"> | Requires Verification</div>}

        {incident.correctedOnSite && <div className="ml-5"> | Corrected on Site</div>}
      </div>

      {incident.actionPlan && (
        <div className="flex w-full flex-col pt-5">
          <Typography variant="body1" className="-mb-5">
            Action Plan
          </Typography>
          <RichTextEditor value={incident.actionPlan} />
        </div>
      )}
    </div>
  );
}

IncidentSummary.propTypes = {
  className: PropTypes.string,
  incident: PropTypes.objectOf(PropTypes.any).isRequired,
};

IncidentSummary.defaultProps = {
  className: '',
};
