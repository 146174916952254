import { useMutation } from '@apollo/client';
import { Button, DialogContentText, TextField } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import { ObjectId } from 'bson';
import { get, noop, toUpper } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PROFILE_QUERY } from '../graphql/profile.query';
import { CREATE_ORGANIZATION } from '../graphql/relationship.query';
import { useProfile } from '../hooks';
import { GET_ORGANIZATION_DETAILS } from '../profile/graphql/query';
import { getDisplayName, inlineErrorMessages } from '../shared/helper';
import { profileConfig } from '../shared/profile-config';
import { showMessage } from '../store/fuse/messageSlice';
import DialogTemplate from './DialogTemplate';
import OrganizationSearch from './OrganizationSearch';
import PersonSearch from './PersonSearch';

const {
  EDGE: { CHILD_ORGANIZATION, OWNER },
  NODE: { CAMPUS, GROUP, APPLICATOR_GROUP, USER },
} = RELATIONSHIP_LABEL;

const ManageOrganizationDialog = ({
  canAdminCreateForUser,
  closeDialog,
  description,
  filter,
  isOpen,
  onSaveComplete,
  orgNode,
  orgType,
  title,
  userId: createdByUserId,
  variant,
}) => {
  const { id: orgId, name: orgName } = orgNode || {};

  const { pathname } = useLocation();
  const { rssTools } = useSelector(({ app }) => app);
  const isRssTools = pathname?.startsWith('/rss-tools/');

  const [errors, setErrors] = useState(null);
  const [message, setMessage] = useState(null);
  const [name, setName] = useState((variant === 'text' && orgName) || '');
  const [userIdForCreate, setUserIdForCreate] = useState(createdByUserId);
  const [selectedNode, setSelectedNode] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      dispatch(
        showMessage({
          message,
          autoHideDuration: 5 * 1000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'error',
        }),
      );
    }
  }, [dispatch, message]);

  const {
    profile: { userId, campusCode },
  } = useProfile();

  const orgTypeDisplayName = getDisplayName(orgType);
  const orgTypeUpperCase = toUpper(orgType);
  const { relatedOrgType } = get(profileConfig, orgType, {});

  const getRefetchQueries = () => {
    if (variant === 'text') {
      const query = [{ query: PROFILE_QUERY }];
      return orgId ? [{ query: GET_ORGANIZATION_DETAILS, variables: { id: orgId } }, ...query] : query;
    }
    if (variant === 'search' && orgId) return [{ query: GET_ORGANIZATION_DETAILS, variables: { id: orgId } }];
    return null;
  };

  const [createOrganization, { loading: createOrgLoading }] = useMutation(CREATE_ORGANIZATION, {
    refetchQueries: getRefetchQueries,
    onError: (error) => {
      if (/ORG_EXISTS/.test(error.message)) {
        setErrors({ name: ['Name already exists. Please choose a different name.'] });
      } else {
        setMessage(`An error has occured while saving the ${orgTypeDisplayName}`);
      }
    },
    onCompleted: (data) => {
      clear();
      onSaveComplete(data.createOrganization);
    },
  });

  const nodeId = new ObjectId().toString();

  let edges = [
    {
      to: { id: (isRssTools && rssTools.campusCode) || campusCode, label: CAMPUS },
      from: { id: orgId || nodeId, label: orgTypeUpperCase },
      edge: { label: CHILD_ORGANIZATION },
    },
  ];

  switch (orgTypeUpperCase) {
    // TODO:  need to add UNIT case and tie unit to party
    case APPLICATOR_GROUP:
    case GROUP:
      if (!orgId || canAdminCreateForUser) {
        edges = edges.concat([
          {
            to: { id: orgId || nodeId, label: GROUP },
            from: { id: userIdForCreate || userId, label: USER },
            edge: { label: OWNER },
          },
        ]);
      }
      break;
    default:
      break;
  }

  if (get(selectedNode, 'id')) {
    edges = edges.concat([
      {
        to: { id: orgId, label: orgTypeUpperCase },
        from: { id: selectedNode.id, label: selectedNode.label },
        edge: { label: CHILD_ORGANIZATION },
      },
    ]);
  }

  const clear = () => {
    setName('');
    setSelectedNode({});
    setErrors(null);
    closeDialog();
  };

  return (
    <DialogTemplate
      open={isOpen}
      title={title}
      loading={createOrgLoading}
      contentScrollable
      content={
        <div className="space-y-16">
          {description && <DialogContentText className="whitespace-pre-wrap">{description}</DialogContentText>}
          {variant === 'text' && (
            <TextField
              id="organization-name"
              placeholder={`Enter ${orgTypeDisplayName} Name`}
              label={`${orgTypeDisplayName} Name`}
              type="input"
              variant="outlined"
              autoFocus
              autoComplete="off"
              fullWidth
              value={name || ''}
              error={Boolean(errors?.name?.length)}
              helperText={inlineErrorMessages(errors?.name)}
              onChange={(event) => setName(event.target.value)}
              inputProps={{ maxLength: 250, 'aria-label': `Enter ${orgTypeDisplayName} Name` }}
              data-cy="e2e-org-name-input"
            />
          )}
          {canAdminCreateForUser && (
            <PersonSearch
              id="person_search_dialog"
              campusCode={(isRssTools && rssTools.campusCode) || null}
              onSelect={(person) => setUserIdForCreate(person?.userId)}
              onClear={() => setUserIdForCreate(null)}
              label="Owner"
              placeholder="Search Owner"
            />
          )}
          {variant === 'search' && (
            <OrganizationSearch
              label="Search by Name / Owner"
              filter={filter}
              onSelect={(selected) => setSelectedNode(selected)}
              organizationType={relatedOrgType || orgType}
              onClear={() => setSelectedNode({})}
            />
          )}
        </div>
      }
      primaryActions={
        <>
          <Button variant="contained" onClick={clear} disabled={createOrgLoading}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={
              (!name && !get(selectedNode, 'id')) || (canAdminCreateForUser && !userIdForCreate) || createOrgLoading
            }
            onClick={() => {
              setErrors(null);
              createOrganization({
                variables: {
                  nodes: [
                    {
                      id: orgId || nodeId,
                      label: orgTypeUpperCase,
                      name: name || orgName,
                    },
                  ],
                  edges,
                },
              });
            }}>
            {orgId ? 'Save' : 'Create'}
          </Button>
        </>
      }
    />
  );
};

ManageOrganizationDialog.propTypes = {
  canAdminCreateForUser: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  description: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool.isRequired,
  onSaveComplete: PropTypes.func,
  orgType: PropTypes.string.isRequired,
  orgNode: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string.isRequired,
  userId: PropTypes.string,
  variant: PropTypes.string,
};

ManageOrganizationDialog.defaultProps = {
  canAdminCreateForUser: false,
  description: null,
  filter: [],
  onSaveComplete: noop,
  orgNode: {},
  userId: null,
  variant: 'text',
};

export default ManageOrganizationDialog;
