import uniq from 'lodash/uniq';

export const formatPeople = (people = [], usages = [], orgNode = {}) => {
  let users = [];

  users = people?.length
    ? Object.values(
        people.reduce((acc, curr) => {
          const { node, edge, usageRoles } = curr;
          acc[node.id] = !acc[node.id]
            ? { ...node, orgRoles: [{ node: orgNode, edge }], usageRoles }
            : {
                ...acc[node.id],
                orgRoles: [...acc[node.id].orgRoles, { node: orgNode, edge }],
              };
          return acc;
        }, []),
      )
    : [];

  return users.map((person) => {
    const { id, email, firstName, lastName, orgRoles, usageRoles } = person;
    return {
      id,
      name: `${lastName}, ${firstName}`,
      email,
      firstName,
      lastName,
      orgRoles: uniq(orgRoles),
      ...(usages || []).reduce((acc, curr) => {
        acc[curr.node.id] = (usageRoles || [])
          .filter(({ node }) => node.id === curr.node.id)
          .map(({ edge }) => edge.label);
        return acc;
      }, {}),
      person,
    };
  });
};
