import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showMessage } from '../store/fuse/messageSlice';

const messageParams = {
  autoHideDuration: 5000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export function useToast() {
  const dispatch = useDispatch();

  const showError = useCallback(
    (message) => {
      if (!message) {
        throw new Error('Missing error message');
      }

      dispatch(
        showMessage({
          ...messageParams,
          message,
          variant: 'error',
        }),
      );
    },
    [dispatch],
  );

  return { showError };
}
