"use strict";

var showInGallons = ['Combustible Liquid', 'Combustible Liquid: II', 'Combustible Liquid: IIIA', 'Corrosive', 'Corrosive Liquefied', 'Cryogenic', 'Flammable Liquid', 'Flammable Liquid: IA', 'Flammable Liquid: IB, IC', 'Flammable Liquid: IA, IB, IC', 'Flammable Liquid: IB, IC & Combustible Liquid: II & Combustible Liquid: IIIA'];
var MS = ['Toxic', 'Highly Toxic', 'Oxidizers', 'Oxidizer: 1', 'Oxidizer: 2', 'Oxidizer: 3', 'Oxidizer: 4', 'Unstable Reactive', 'Unstable Reactive: 1', 'Unstable Reactive: 2', 'Unstable Reactive: 3', 'Unstable Reactive: 4', 'Water Reactive: 1', 'Water Reactive: 2', 'Water Reactive: 3'];
var H8 = ['Highly Toxic', 'Sensitizer (CFC2001)', 'Toxic'];
var showInGallons2001 = showInGallons.concat(['Oxidizing Gas', 'Flammable Gas', 'Flammable Gas Liquefied', 'Irritant (CFC2001)', 'Other Health Hazard Material (CFC2001)']);
module.exports = {
  2001: {
    B: showInGallons2001,
    H8: showInGallons2001.concat(H8),
    M: showInGallons2001.concat(MS),
    S: showInGallons2001.concat(MS)
  },
  2016: {
    A: showInGallons,
    B: showInGallons,
    E: showInGallons,
    F: showInGallons,
    H1: showInGallons,
    H2: showInGallons,
    H3: showInGallons,
    H4: showInGallons,
    H5: showInGallons,
    I: showInGallons,
    L: showInGallons,
    M: showInGallons.concat(MS),
    R: showInGallons,
    S: showInGallons.concat(MS)
  }
};