import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CampusSelect from './CampusSelect';
import { useTenants } from '../hooks';
import { inlineErrorMessages } from '../shared/helper';

const DomainSelect = ({
  id,
  tenantCode,
  campusCode,
  showAll,
  value,
  variant,
  display,
  required,
  disabled,
  errors,
  onSelect,
  autoFocus,
  size,
}) => {
  const TENANTS = useTenants();
  const [selectedTenant, setSelectedTenant] = useState(tenantCode);
  const [selectedCampus, setSelectedCampus] = useState(campusCode);
  const [selectedDomain, setSelectedDomain] = useState(value);

  useEffect(() => {
    setSelectedDomain(value);
  }, [value]);

  useEffect(() => {
    setSelectedCampus(campusCode);
  }, [campusCode]);

  const handleSelect = (tenant, campus, domain) => {
    onSelect({ tenantCode: tenant, campusCode: campus, domainId: domain?.id, domainName: domain?.name });
    setSelectedTenant(tenant);
    setSelectedCampus(campus);
    setSelectedDomain(domain.id);
  };

  const selectId = `${id}_domain`;
  const domainList = TENANTS.getCampus(selectedCampus)?.domains || [];

  const domainVariant = (
    <FormControl
      variant="outlined"
      fullWidth
      required={required}
      disabled={!selectedTenant || !selectedCampus || disabled}
      error={Boolean(errors?.length)}
      size={size}>
      <InputLabel htmlFor={selectId}>Domain</InputLabel>
      <Select
        autoFocus={autoFocus}
        data-cy="e2e-selectTenant"
        variant="outlined"
        label="Domain"
        value={selectedDomain || ''}
        onChange={(event) => {
          handleSelect(
            selectedTenant,
            selectedCampus,
            domainList.find((domain) => domain.id === event.target.value),
          );
        }}
        inputProps={{
          name: selectId,
          id: selectId,
        }}>
        {showAll && (
          <MenuItem key="ALL" value="ALL">
            All Campuses
          </MenuItem>
        )}
        {domainList &&
          domainList.map((domainItem) => (
            <MenuItem data-cy={`e2e-${domainItem.id}`} key={domainItem.name} value={domainItem.id}>
              {domainItem.name}
            </MenuItem>
          ))}
      </Select>
      {!isEmpty(errors) && <FormHelperText>{inlineErrorMessages(errors)}</FormHelperText>}
    </FormControl>
  );

  if (variant === 'domain') {
    return domainVariant;
  }

  return (
    <div className={`grid w-full grid-flow-row md:grid-flow-${display} gap-8`}>
      <CampusSelect
        tenantCode={selectedTenant}
        value={selectedCampus}
        variant={variant}
        onSelect={(e) => handleSelect(e.tenantCode, e.campusCode, null)}
        size={size}
      />
      <div>{domainVariant}</div>
    </div>
  );
};

DomainSelect.propTypes = {
  id: PropTypes.string,
  tenantCode: PropTypes.string,
  campusCode: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['domain', 'campus', 'tenant']),
  display: PropTypes.oneOf(['row', 'col']),
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showAll: PropTypes.bool,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  autoFocus: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

DomainSelect.defaultProps = {
  id: '',
  variant: 'domain',
  display: 'row',
  value: '',
  showAll: false,
  tenantCode: '',
  campusCode: '',
  required: false,
  disabled: false,
  errors: [],
  autoFocus: false,
  size: 'medium',
};

export default DomainSelect;
