import { lazy } from 'react';

const DashboardPage = lazy(() => import('./containers/DashboardPage'));
const RiskCalculatorPage = lazy(() => import('./containers/RiskCalculatorPage'));
const RiskExtendTerminatePage = lazy(() => import('./containers/RiskExtendTerminatePage'));
const RiskInsurancePage = lazy(() => import('./containers/RiskInsurancePage'));
const UCIPApplicationPage = lazy(() => import('./containers/UCIPApplicationPage'));
const UCIPCalculatorPage = lazy(() => import('./containers/UCIPCalculatorPage'));
const UCIPExtendTerminatePage = lazy(() => import('./containers/UCIPExtendTerminatePage'));

const routes = {
  settings: {
    helpCategories: ['cli'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: ['/cli/forms/riskinsuranceform/:id', '/cli/forms/riskinsuranceform'],
      component: RiskInsurancePage,
    },
    {
      path: ['/cli/forms/extendterminateform/:id', '/cli/forms/extendterminateform'],
      component: RiskExtendTerminatePage,
    },
    {
      path: ['/cli/forms/ucipapplicationform/:id', '/cli/forms/ucipapplicationform'],
      component: UCIPApplicationPage,
    },
    {
      path: ['/cli/forms/ucipextendterminateform/:id', '/cli/forms/ucipextendterminateform'],
      component: UCIPExtendTerminatePage,
    },
    {
      path: ['/cli/premiumcalculators/riskcalculator'],
      component: RiskCalculatorPage,
    },
    {
      path: ['/cli/premiumcalculators/ucipcalculator'],
      component: UCIPCalculatorPage,
    },
    {
      path: ['/cli/dashboard', '/cli'],
      component: DashboardPage,
    },
  ],
};

export default routes;
