import { useQuery } from '@apollo/client';
import { MenuItem, TextField } from '@material-ui/core';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const LIBRARY_TAGS_LIST = gql`
  query listTags($catalog: CatalogType!) {
    listTags(catalog: $catalog)
  }
`;

const LibraryTagSearch = ({ catalog, onSelect, selectedTag, tagLabel, disabled }) => {
  const [tags, setTags] = useState([]);

  useQuery(LIBRARY_TAGS_LIST, {
    onCompleted: ({ listTags }) => {
      setTags(listTags);
    },
    variables: { catalog },
  });

  return (
    <TextField
      select
      variant="outlined"
      id="library_tag_search-label"
      fullWidth
      label={tagLabel}
      disabled={disabled || !tags.length}
      value={selectedTag}
      inputProps={{
        'aria-labelledby': 'library_tag_search-label',
      }}
      onChange={(event) => onSelect(event.target.value)}>
      <MenuItem value="ALL" key="ALL">
        ALL
      </MenuItem>
      {(tags || []).map((tag) => (
        <MenuItem key={tag} value={tag}>
          {tag}
        </MenuItem>
      ))}
    </TextField>
  );
};

LibraryTagSearch.propTypes = {
  catalog: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedTag: PropTypes.string,
  tagLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

LibraryTagSearch.defaultProps = {
  selectedTag: 'ALL',
  tagLabel: 'Select',
  disabled: false,
};

export default LibraryTagSearch;
