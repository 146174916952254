import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import FqMultiSelect from '../../components/flexible-questionnaire/FqMultiSelect';

const AVAILABLE_PARENTS = gql`
  query availableParents($userId: String, $templateId: String!) {
    availableParents(userId: $userId, templateId: $templateId)
  }
`;

const AssessmentFacilitySearch = ({ onChange, question, values, labelVariant, placeholder, templateId }) => {
  const { data, loading, error } = useQuery(AVAILABLE_PARENTS, {
    variables: { templateId },
  });
  const [selectedValue, setSelectedValue] = useState(values);
  if (loading) return <FuseLoading />;

  const facilityOptions = data?.availableParents.map(({ node }) => ({
    label: `${node.name} - ${node.address} ${node.city} ${node.state}`,
    value: node.id,
  }));

  return (
    <div className="w-full py-16">
      <FqMultiSelect
        error={error}
        label={question.label}
        labelVariant={labelVariant}
        limit={1}
        value={[selectedValue]}
        onChange={(e) => {
          const [selected] = e || [];
          setSelectedValue(selected);
          onChange(selected?.value, question.key);
        }}
        options={facilityOptions}
        valueType="object"
        questionKey={question.key}
        placeholder={placeholder}
      />
    </div>
  );
};

AssessmentFacilitySearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  labelVariant: PropTypes.oneOf(['default', 'long']),
  placeholder: PropTypes.string,
  templateId: PropTypes.string,
};

AssessmentFacilitySearch.defaultProps = {
  values: null,
  labelVariant: 'default',
  placeholder: '',
  templateId: '',
};

export default AssessmentFacilitySearch;
