import {
  AppBar,
  Toolbar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  ThemeProvider,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { SHORT_DATE_FORMAT, SHORT_DATE_PLACEHOLDER } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectMainTheme } from '../../../store/fuse/settingsSlice';

function InspectionDateDialog({ inspectionDate: originalInspectionDate, onClose, onSave }) {
  const [inspectionDate, setInspectionDate] = useState(originalInspectionDate);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const mainTheme = useSelector(selectMainTheme);

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog open aria-labelledby="form-dialog-title" maxWidth="xs" disableEnforceFocus={isPickerOpen}>
        <AppBar position="static" className="mb-16 rounded-t-2xl">
          <Toolbar className="flex w-full">
            <Typography variant="h6" color="inherit">
              Inspection Date
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent className="overflow-visible">
          <KeyboardDatePicker
            KeyboardButtonProps={{
              'aria-label': 'change inspection date',
            }}
            autoOk
            disableToolbar
            fullWidth
            inputVariant="outlined"
            variant="inline"
            format={SHORT_DATE_FORMAT}
            onClose={() => setIsPickerOpen(false)}
            onOpen={() => setIsPickerOpen(true)}
            placeholder={SHORT_DATE_PLACEHOLDER}
            id="date-inspection"
            value={inspectionDate}
            onChange={(date) => setInspectionDate(new Date(date))}
          />
        </DialogContent>
        <DialogActions className="flex-end p-16">
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
          <Button color="primary" variant="contained" onClick={() => onSave(inspectionDate)}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

InspectionDateDialog.propTypes = {
  inspectionDate: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Date)]).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default InspectionDateDialog;
