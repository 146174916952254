import FuseAnimate from '@fuse/core/FuseAnimate';
import { Hidden, Icon, IconButton, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({
  backArrow: { link, name },
  headerWithMetaData,
  icon,
  search,
  subtitle,
  title,
  onMenuAction,
  rightSideContent,
}) => {
  const theme = useTheme();
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <>
      <div
        className={clsx(
          'flex w-full flex-1 flex-col sm:flex-row print:flex-row',
          link ? 'py-16 sm:py-0' : 'pt-24 sm:items-center sm:pt-0',
        )}>
        <div className={clsx('print:align-center flex flex-col', !search && 'w-full')}>
          {link && (
            <FuseAnimate animation="transition.slideRightIn" delay={300}>
              <Link
                className="mt-8 flex self-start normal-case sm:my-8 sm:mt-24 sm:mb-12 print:hidden"
                role="button"
                to={link}>
                <Icon className="text-20">arrow_back</Icon>
                {name && <span className="mx-4">{name} </span>}
              </Link>
            </FuseAnimate>
          )}
          <div className="mt-8 flex items-center space-x-8 self-stretch sm:mt-0 print:hidden">
            {icon && (
              <FuseAnimate animation="transition.expandIn" delay={300}>
                {icon}
              </FuseAnimate>
            )}
            {title && (
              <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                <div className="flex flex-col">
                  <Tooltip title={title} placement="top" arrow>
                    <Typography
                      className={clsx(
                        'mx-12 truncate text-16 sm:text-24',
                        search && (isMobileResolution ? 'max-w-256' : 'max-w-360'),
                      )}>
                      {title}
                    </Typography>
                  </Tooltip>
                  {subtitle && (
                    <Typography variant="subtitle2" className="mx-12">
                      {subtitle}
                    </Typography>
                  )}
                </div>
              </FuseAnimate>
            )}
            {headerWithMetaData && (
              <div
                className={clsx(
                  'grid grid-flow-row',
                  search || isMobileResolution ? 'max-w-256 print:max-w-full' : 'w-full',
                )}>
                {headerWithMetaData}
              </div>
            )}
          </div>
        </div>
        {search && <div className="flex flex-1 items-center justify-center print:hidden">{search}</div>}
        {rightSideContent && <div className="justify-content-end flex">{rightSideContent}</div>}
      </div>
      {onMenuAction && (
        <Hidden mdUp>
          <div className="mt-4 flex items-center sm:mt-24 print:hidden">
            <IconButton onClick={onMenuAction} aria-label="open right sidebar">
              <Icon>menu</Icon>
            </IconButton>
          </div>
        </Hidden>
      )}
    </>
  );
};

Header.propTypes = {
  backArrow: PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    link: PropTypes.string,
  }),
  headerWithMetaData: PropTypes.node,
  icon: PropTypes.node,
  search: PropTypes.node,
  rightSideContent: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onMenuAction: PropTypes.func,
};

Header.defaultProps = {
  backArrow: {},
  headerWithMetaData: null,
  icon: null,
  search: null,
  title: null,
  subtitle: null,
  onMenuAction: null,
  rightSideContent: null,
};

export default Header;
