import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

import config from '../../config';
import { showMessage } from '../../store/fuse/messageSlice';
import { setDefaultSettings, setInitialSettings } from '../../store/fuse/settingsSlice';

export const setUserData = (user) => async (dispatch, getState) => {
  // Set User Settings
  if (user.data.settings) {
    dispatch(setDefaultSettings(user.data.settings));
  }

  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
  const { user } = getState().auth;
  const newUser = {
    ...user,
    data: {
      ...user.data,
      shortcuts,
    },
  };

  dispatch(updateUserData(user));

  return dispatch(setUserData(newUser));
};

export const logoutUser = (browserSecurity) => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  localStorage.removeItem(IDLE_KEY.IDLE_MINUTES_KEY);
  localStorage.removeItem(IDLE_KEY.IDLE_TIMEOUT_KEY);
  localStorage.removeItem(config.SELECTED_IDP_KEY);
  browserSecurity.logout('/');

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  dispatch(showMessage({ message: 'User data saved' }));
};

const initialState = {
  role: null, // guest
  rssTool: {
    campusCode: '',
    tenantCode: '',
  },
  data: {
    displayName: 'User',
    email: null,
    campus: null,
    profile: null,
    rolePermissions: [],
    shortcuts: [],
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      const { profile, rolePermissions } = payload.data;
      const displayName =
        profile?.firstName && profile?.lastName ? `${profile.firstName} ${profile.lastName}` : state.data.displayName;
      const email = profile?.email || state.data.email;
      return {
        ...state,
        ...payload,
        role: {
          ...(state.role || {}),
          profile,
          rolePermissions,
        },
        data: {
          ...state.data,
          ...payload.data,
          displayName,
          email,
        },
      };
    },
    userLoggedOut: () => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
