import PropTypes from 'prop-types';
import React from 'react';

import types from './logo.types';

const LOGOS = {
  rssColored: `${process.env.PUBLIC_URL}/images/rss-colored.svg`,
  rssWhite: `${process.env.PUBLIC_URL}/images/rss-white.svg`,
};
function Logo({ alt, brand, className }) {
  return <img data-cy="e2e-rss-logo" src={LOGOS[brand]} alt={alt} className={className} />;
}

Logo.propTypes = {
  alt: PropTypes.string,
  brand: PropTypes.oneOf([types.RSS_COLORED, types.RSS_WHITE]).isRequired,
  className: PropTypes.string,
};

Logo.defaultProps = {
  alt: 'Logo',
  className: null,
};

export default Logo;
