import { CATALOG_TYPE } from '@risk-and-safety/library';
import { RELATIONSHIP_LABEL } from '@rss/common';
import { get, toUpper } from 'lodash';
import React, { lazy } from 'react';

import {
  convertToSingular,
  hasConfigManagerRole,
  hasRssDataAdminL2Role,
  hasRssDataAdminRole,
  hasRssToolAccessToLabel,
  hasServiceDeskRole,
} from '../shared/helper';
import { profileConfig } from '../shared/profile-config';

const RssToolsPage = lazy(() => import('./RssToolsPage'));
const BucketListPage = lazy(() => import('../account-management/containers/BucketListPage'));
const CreateQuestionnairePage = lazy(() => import('../inspect/containers/CreateQuestionnairePage'));
const DataCleanupPage = lazy(() => import('../account-management/containers/DataCleanupPage'));
const DataCleanupAdminL2Page = lazy(() => import('../account-management/containers/DataCleanupAdminL2Page'));
const NotificationTemplatesPage = lazy(() => import('../notification-template/containers/NotificationTemplatesPage'));
const LocationDetailsPage = lazy(() => import('../account-management/containers/LocationDetailsPage'));
const LocationsPage = lazy(() => import('../account-management/containers/LocationsPage'));
const OrganizationListPage = lazy(() => import('../account-management/containers/OrganizationListPage'));
const ProgramListPage = lazy(() => import('../account-management/containers/ProgramListPage'));
const RoutingListPage = lazy(() => import('../account-management/containers/RoutingListPage'));
const RoutingDetailsPage = lazy(() => import('../account-management/containers/RoutingDetailsPage'));
const UserAccountsPage = lazy(() => import('../account-management/containers/UserAccountsPage'));
const ManageRolesPage = lazy(() => import('../account-management/containers/ManageRolesPage'));
const MergeRoomPage = lazy(() => import('../account-management/containers/MergeRoomPage'));
const QuestionPage = lazy(() => import('../inspect/containers/QuestionPage'));
const QuestionnaireListPage = lazy(() => import('../inspect/containers/QuestionnaireListPage'));
const QuestionnairePage = lazy(() => import('../inspect/containers/QuestionnairePage'));
const QuestionnaireTemplateListPage = lazy(() => import('../inspect/containers/QuestionnaireTemplateListPage'));
const QuestionnaireTemplatePage = lazy(() => import('../inspect/containers/QuestionnaireTemplatePage'));
const LibraryItemListPage = lazy(() => import('../library/containers/LibraryItemListPage'));
const LibraryItemDetailsPage = lazy(() => import('../library/containers/LibraryItemDetailsPage'));
const NotFound = lazy(() => import('../components/errors/NotFound'));
const OrganizationDetailPage = lazy(() => import('../profile/containers/OrganizationDetailPage'));

const { NODE } = RELATIONSHIP_LABEL;
const orgTypes = Object.entries(profileConfig)
  .filter(([, value]) => value.includeUnderRSSTools)
  .map(([key]) => key);

function hasPermission({ relationships } = {}, rolePermissions, orgType) {
  const orgConfig = profileConfig[orgType] || { rssToolAccess: [] };

  return (
    relationships && hasRssToolAccessToLabel(rolePermissions, relationships, orgType, orgConfig?.rssToolAccess ?? [])
  );
}

const routes = {
  settings: {
    helpCategories: ['rss-tools'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: ['/rss-tools/data-cleanup'],
      auth: ({ profile }) => hasRssDataAdminRole(profile),
      component: () => <DataCleanupPage />,
    },
    {
      path: ['/rss-tools/data-cleanup-admin-l2'],
      auth: ({ profile }) => hasRssDataAdminL2Role(profile),
      component: () => <DataCleanupAdminL2Page />,
    },
    {
      path: [
        '/rss-tools/template/:templateId/edit/:questionSection/question/:questionKey',
        '/rss-tools/questionnaires/:seriesId/edit/:questionSection/question/:questionKey',
      ],
      auth: ({ profile }) => hasConfigManagerRole(profile),
      component: () => <QuestionPage />,
    },
    {
      path: ['/rss-tools/questionnaires/create'],
      auth: ({ profile }) => hasConfigManagerRole(profile),
      component: () => <CreateQuestionnairePage />,
    },
    {
      path: ['/rss-tools/questionnaires/:seriesId'],
      auth: ({ profile }) => hasConfigManagerRole(profile),
      component: () => <QuestionnairePage />,
    },
    {
      path: ['/rss-tools/questionnaires'],
      auth: ({ profile }) => hasConfigManagerRole(profile),
      component: () => <QuestionnaireListPage />,
    },
    {
      path: ['/rss-tools/template/:templateId'],
      auth: ({ profile }) => hasConfigManagerRole(profile),
      component: () => <QuestionnaireTemplatePage />,
    },
    {
      path: ['/rss-tools/template'],
      auth: ({ profile }) => hasConfigManagerRole(profile),
      component: () => <QuestionnaireTemplateListPage />,
    },
    {
      path: ['/rss-tools/roles/:role', '/rss-tools/roles'],
      auth: ({ profile }) => hasConfigManagerRole(profile) || hasServiceDeskRole(profile),
      component: () => <ManageRolesPage usage="rss-tools" isRssTools />,
    },
    {
      path: '/rss-tools/users',
      auth: ({ profile }) => hasServiceDeskRole(profile),
      component: () => <UserAccountsPage isRssTools />,
    },
    {
      path: ['/rss-tools/locations/building/:id/:menu/manage/:locationId?', '/rss-tools/locations/building/:id'],
      auth: ({ profile }) => hasServiceDeskRole(profile),
      component: () => <LocationDetailsPage isRssTools />,
    },
    {
      path: ['/rss-tools/locations/room/merge'],
      auth: ({ profile }) => hasServiceDeskRole(profile),
      component: () => <MergeRoomPage backUrl="/rss-tools/locations" isRssTools />,
    },
    {
      path: '/rss-tools/locations',
      auth: ({ profile }) => hasServiceDeskRole(profile),
      component: () => <LocationsPage isRssTools />,
    },
    {
      path: '/rss-tools/library/:catalog/:id',
      component: ({ match: { params } }) => {
        const catalog = toUpper(params.catalog);
        return CATALOG_TYPE[catalog] ? <LibraryItemDetailsPage /> : <NotFound />;
      },
    },
    {
      path: '/rss-tools/library/:catalog',
      component: ({ match: { params } }) => {
        const catalog = toUpper(params.catalog);
        return CATALOG_TYPE[catalog] ? <LibraryItemListPage catalog={catalog} /> : <NotFound />;
      },
    },
    {
      exact: true,
      path: '/rss-tools/routing_lists/:id/:type/manage/:routingGroupId/',
      component: () => <RoutingDetailsPage isRssTools />,
    },
    {
      exact: true,
      path: '/rss-tools/routing_lists/:id/',
      component: () => <RoutingDetailsPage isRssTools />,
    },
    {
      path: [
        '/rss-tools/:type/:id/:menu/manage/:nodeId?/room/:room?',
        '/rss-tools/:type/:id/:menu/manage/:userId?',
        '/rss-tools/:type/:id',
      ],
      component: ({ match: { params } }) => {
        const type = convertToSingular(toUpper(params.type));
        return orgTypes.includes(type) ? <OrganizationDetailPage isRssTools /> : <NotFound />;
      },
    },
    {
      path: '/rss-tools/notification-template',
      auth: ({ profile }) =>
        get(profile, 'features.platform.notificationTemplateV2.value', false) && hasConfigManagerRole(profile),
      component: () => <NotificationTemplatesPage />,
    },
    {
      path: '/rss-tools/programs',
      auth: ({ profile, rolePermissions } = {}) => hasPermission(profile, rolePermissions, NODE.PROGRAM),
      component: () => <ProgramListPage isRssTools />,
    },
    {
      path: '/rss-tools/buckets',
      auth: ({ profile, rolePermissions } = {}) => hasPermission(profile, rolePermissions, NODE.BUCKET),
      component: () => <BucketListPage isRssTools />,
    },
    {
      path: '/rss-tools/routing_lists',
      auth: ({ profile, rolePermissions } = {}) => hasPermission(profile, rolePermissions, NODE.ROUTING_LIST),
      component: () => <RoutingListPage isRssTools />,
    },
    {
      path: '/rss-tools/:type',
      component: ({ match: { params } }) => {
        const type = convertToSingular(toUpper(params.type));
        return orgTypes.includes(type) ? <OrganizationListPage type={type} isRssTools /> : <NotFound />;
      },
    },
    {
      path: ['/rss-tools'],
      component: () => <RssToolsPage />,
    },
  ],
};

export default routes;
