import { FormControl, makeStyles } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SHORT_DATE_FORMAT } from '@rss/common';
import { addYears, format, isDate, isValid, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { QUESTION_VARIANT } from './Helper';

const useStyles = makeStyles({
  textField: {
    '& .MuiOutlinedInput-input': { fontSize: '1.4rem' },
    '& .MuiFormHelperText-root': { fontSize: '1.4rem', marginLeft: '0', letterSpacing: '0' },
  },
  popper: {
    '& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel, & .MuiPickersCalendarHeader-label, & .MuiPickersYear-yearButton':
      { fontSize: '1.4rem' },
  },
});

// FIXME: Should combine with FqDateTimePicker and dynamically determine the component
const defaultMinDate = addYears(new Date(), -120);
const defaultMaxDate = addYears(new Date(), 100);
const parseToDate = (value) => value && new Date(value);

const getMaxDateMessage = (message, maxDate, dateFormat) =>
  message || `Date must be on or before ${format(maxDate ? new Date(maxDate) : defaultMaxDate, dateFormat)}`;

const getMinDateMessage = (message, minDate, dateFormat) =>
  message || `Date must be on or after ${format(minDate ? new Date(minDate) : defaultMinDate, dateFormat)}`;

export const getErrorMessage = (error, maxDateMessage, maxDate, dateFormat, minDateMessage, minDate) => {
  return () => {
    switch (error) {
      case 'maxDate':
        return getMaxDateMessage(maxDateMessage, maxDate, dateFormat);
      case 'minDate':
        return getMinDateMessage(minDateMessage, minDate, dateFormat);
      case 'disableFuture':
        return 'Date cannot be in the future';
      case 'disablePast':
        return 'Date cannot be in the past';
      case 'invalidDate':
        return 'Invalid date format';
      default:
        return error;
    }
  };
};

const FqDatePicker = ({
  autoFocus,
  dateFormat,
  disableFuture,
  disablePast,
  disabled,
  fullWidth,
  label,
  labelVariant,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  onChange,
  propagateInvalid, // Pass invalid dates to onChange for validation outside of FQ
  questionKey,
  required,
  value,
  variant,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(null);

  const errorMessage = useMemo(getErrorMessage(error, maxDateMessage, maxDate, dateFormat, minDateMessage, minDate), [
    error,
  ]);

  const handleOnChange = (val) => {
    if (val) {
      const selectedDate = isDate(val) ? val : new Date();
      if (isValid(selectedDate)) {
        onChange(startOfDay(selectedDate).toISOString(), questionKey);
      } else if (propagateInvalid) {
        onChange(selectedDate.toString(), questionKey);
      }
    } else {
      onChange(null, questionKey);
    }
  };

  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      <FormControl fullWidth={fullWidth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            autoFocus={autoFocus}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            data-testid={label}
            label={labelVariant !== 'long' ? label : null}
            format={dateFormat}
            id={questionKey}
            maxDate={parseToDate(maxDate) || defaultMaxDate}
            minDate={parseToDate(minDate) || defaultMinDate}
            value={(value && new Date(value)) || null}
            onChange={handleOnChange}
            onError={setError}
            slotProps={{
              textField: { required, helperText: propagateInvalid ? '' : errorMessage, className: classes.textField },
              openPickerButton: { sx: { marginRight: '4px' } },
              openPickerIcon: { sx: { fontSize: '2.4rem', color: 'black' } },
              popper: { className: classes.popper },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

FqDatePicker.propTypes = {
  autoFocus: PropTypes.bool,
  dateFormat: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDateMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  propagateInvalid: PropTypes.bool,
  questionKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FqDatePicker.defaultProps = {
  autoFocus: false,
  dateFormat: SHORT_DATE_FORMAT,
  disableFuture: false,
  disablePast: false,
  disabled: false,
  fullWidth: true,
  label: 'Select a date',
  labelVariant: 'default',
  maxDate: defaultMaxDate,
  maxDateMessage: null,
  minDate: defaultMinDate,
  minDateMessage: null,
  propagateInvalid: false,
  required: false,
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
};

export default FqDatePicker;
