/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  state: null,
  options: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    autoHideDuration: 6000,
    message: 'An error occurred',
    variant: 'error',
  },
};
const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.state = true;
      const message = action.payload?.message?.replace(/.+status code 400 *:/, '');
      state.options = {
        ...initialState.options,
        ...action.payload,
        message: message?.includes('status code 500') ? 'An error occurred' : message,
      };
    },
    hideMessage: (state, action) => {
      state.state = null;
    },
  },
});

export const { hideMessage, showMessage } = messageSlice.actions;

export default messageSlice.reducer;
