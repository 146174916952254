module.exports = {
  ACKNOWLEDGE: 'ACKNOWLEDGE',
  ADMIN_SUBMIT: 'ADMIN_SUBMIT',
  APPROVE: 'APPROVE',
  ADD_LOCATION: 'ADD_LOCATION',
  ADD_MEMBER: 'ADD_MEMBER',
  ADD_PEOPLE: 'ADD_PEOPLE',
  ADMIN_CREATE: 'ADMIN_CREATE',
  AMEND: 'AMEND',
  ASSIGN: 'ASSIGN',
  ASSIGN_PEOPLE: 'ASSIGN_PEOPLE',
  ASSIGN_ADMIN: 'ASSIGN_ADMIN',
  ASSIGN_REVIEWER: 'ASSIGN_REVIEWER',
  ARCHIVE: 'ARCHIVE',
  CANCEL: 'CANCEL',
  COMPLETE: 'COMPLETE',
  COMPLETE_URA: 'COMPLETE_URA',
  COMMENT: 'COMMENT',
  CREATE: 'CREATE',
  CLONE: 'CLONE',
  CATEGORIZE: 'CATEGORIZE',
  DEACTIVATE: 'DEACTIVATE',
  DELETE: 'DELETE',
  DECISION_RECOMMENDATION: 'DECISION_RECOMMENDATION',
  EDIT: 'EDIT',
  EDIT_PEOPLE: 'EDIT_PEOPLE',
  EDIT_LOCATIONS: 'EDIT_LOCATIONS',
  EDIT_OWNERS: 'EDIT_OWNERS',
  EDIT_INTERNAL: 'EDIT_INTERNAL',
  EXPIRE: 'EXPIRE',
  INELIGIBLE: 'INELIGIBLE',
  MOVE_TO_BIOSAFETY: 'MOVE_TO_BIOSAFETY',
  PI_REVISE: 'PI_REVISE',
  PRIVATE_COMMENT: 'PRIVATE_COMMENT',
  PREVIEW: 'PREVIEW',
  REVISE: 'REVISE',
  READ: 'READ',
  READ_INTERNAL: 'READ_INTERNAL',
  RECOMPLETE: 'RECOMPLETE',
  RENAME: 'RENAME',
  REMOVE_LOCATION: 'REMOVE_LOCATION',
  REMOVE_MEMBER: 'REMOVE_MEMBER',
  REMOVE_PEOPLE: 'REMOVE_PEOPLE',
  REACTIVATE: 'REACTIVATE',
  REVOKE: 'REVOKE',
  DENY: 'DENY',
  RETURN_TO_EVALUATOR: 'RETURN_TO_EVALUATOR',
  SAVE: 'SAVE',
  SEND_JOB_REMINDER: 'SEND_JOB_REMINDER',
  SEND_RECERTIFICATION_REMINDER: 'SEND_RECERTIFICATION_REMINDER',
  SUBMIT: 'SUBMIT',
  RESUBMIT: 'RESUBMIT',
  SUBMIT_TO_MANAGER: 'SUBMIT_TO_MANAGER',
  SEND_FOR_REVISION: 'SEND_FOR_REVISION',
  START_RENEWAL: 'START_RENEWAL',
  START_AMENDMENT: 'START_AMENDMENT',
  SUBMIT_TO_IBC: 'SUBMIT_TO_IBC',
  SET_REMINDER: 'SET_REMINDER',
  UNASSIGN: 'UNASSIGN',
  UPDATE_GENERAL_DETAILS: 'UPDATE_GENERAL_DETAILS',
  UPDATE_MEMBER: 'UPDATE_MEMBER',
  VIEW_ACTIVE: 'VIEW_ACTIVE',
  VIEW_NEXT_STEPS: 'VIEW_NEXT_STEPS',
  VIEW_VERSIONS: 'VIEW_VERSIONS',
  VIEW_NOTIFICATIONS: 'VIEW_NOTIFICATIONS',
  WITHDRAW: 'WITHDRAW',
  DOWNLOAD: 'DOWNLOAD',
  ADMIN_DOWNLOAD: 'ADMIN_DOWNLOAD',

  UPDATE_APPROVAL: 'UPDATE_APPROVAL',
  UPDATE_EXPIRATION_DATE: 'UPDATE_EXPIRATION_DATE',
  UPDATE_STATUS: 'UPDATE_STATUS',
  TRANSFER: 'TRANSFER',
};
