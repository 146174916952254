import { FqType } from '@rss/common';

const types = [
  'Alters cell tropism',
  'Animal Primary Cells',
  'Bacteria',
  'CRISPR/Cas9',
  'Dog circovirus genome',
  'Drug resistance',
  'Established Cell Lines',
  "Eukaryotic virus's genome",
  'Fungi',
  'Gene Drive',
  'Human Primary Cells',
  'Immune Modulating',
  'Invertebrates',
  'Non-hazardous nucleic acids',
  'Oncogene',
  'Parasites',
  'Plant Virus',
  'Plants',
  'Prions',
  'RacPyV genome',
  'Suppress gene function',
  'Toxins',
  'Vertebrates',
  'Viral Vector',
  'Virulence factor',
  'Virus',
];

export const BiologicalLibraryItemTemplate = [
  {
    type: FqType.LABEL,
    key: 'header',
    label: '### **Overview**',
    required: false,
    disabled: null,
  },
  {
    type: FqType.INPUT,
    key: 'name',
    label: 'Name',
    required: true,
    disabled: null,
  },
  {
    type: FqType.MULTISELECT,
    key: 'tags',
    label: 'Type',
    required: true,
    disabled: null,
    valueType: 'string',
    limit: 1,
    options: types,
  },
  {
    type: FqType.GROUP,
    key: 'details',
    label: '### **Details**',
    required: false,
    disabled: null,
    questions: [
      {
        type: FqType.DROPDOWN,
        key: 'category',
        label: 'Category',
        required: true,
        disabled: null,
        valueType: 'string',
        options: [
          'Agents',
          'Animals',
          'Cells-Tissues-Body Fluids',
          'Hazardous Proteins',
          'Microorganism',
          'Nucleic Acids',
          'Plants',
        ],
      },
      {
        type: FqType.DROPDOWN,
        key: 'riskGroup',
        label: 'Risk Group',
        required: false,
        disabled: null,
        valueType: 'string',
        options: ['1', '1P', '2', '2P', '3', 'N/A'],
      },
    ],
  },
];
