const {
  PersonReference
} = require('@rss/common');
const clone = require('clone');
const tv4 = require('tv4');
class Audit {
  constructor(opt) {
    this.type = null;
    this.rule = null;
    this.message = null;
    this.actionMessage = null;
    this.field = null;
    this.value = null;
    this.fieldType = null;
    this.ignoreBy = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.type = opt.type;
    this.rule = opt.rule;
    this.message = opt.message;
    this.actionMessage = opt.actionMessage;
    this.field = opt.field;
    this.value = opt.value;
    this.fieldType = opt.fieldType;
    this.ignoreBy = opt.ignoreBy ? new PersonReference(opt.ignoreBy) : null;
  }
  validate() {
    return tv4.validateMultiple(this, Audit.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'audit.schema.json',
      type: 'object',
      properties: {
        type: {
          type: ['string', 'null']
        },
        rule: {
          type: ['string', 'null']
        },
        message: {
          type: ['string', 'null']
        },
        actionMessage: {
          type: ['string', 'null']
        },
        field: {
          type: ['string', 'null']
        },
        value: {
          type: ['string', 'null']
        },
        fieldType: {
          type: ['string', 'null']
        },
        ignoreBy: {
          $ref: 'person-reference.schema.json'
        }
      },
      required: Object.keys(this)
    };
  }
}
tv4.addSchema(PersonReference.schema());
module.exports = Audit;