const ObjectId = require('bson').ObjectID;
const tv4 = require('tv4');
const InventoryReference = require('./inventory-reference');
class ColleagueRequest {
  constructor(opt) {
    this._id = null;
    this.requestedBy = null;
    this.approvedBy = null;
    this.status = null;
    if (opt) {
      this.fillObject(opt);
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.requestedBy = new InventoryReference(opt.requestedBy);
    this.approvedBy = new InventoryReference(opt.approvedBy);
    this.status = opt.status || 'PENDING';
  }
  validate() {
    return tv4.validateMultiple(this, ColleagueRequest.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'colleague-request.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        requestedBy: {
          $ref: 'inventory-reference.schema.json'
        },
        approvedBy: {
          $ref: 'inventory-reference.schema.json'
        },
        status: {
          type: 'string'
        }
      },
      required: ['_id', 'requestedBy', 'approvedBy', 'status']
    };
  }
}
tv4.addSchema(InventoryReference.schema());
module.exports = ColleagueRequest;