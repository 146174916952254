import { List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { kebabCase, toLower, toUpper } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getDisplayName, getRedirectUrl, getRoleLabel } from '../shared/helper';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    pointerEvents: 'none',
  },
}));

const RolesList = ({ divider, onRoleClick, roles, selectedRole }) => {
  const [role, setRole] = useState({});

  const { url } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();

  const handleListItemClick = (roleObj) => {
    const { roleName, roleScope } = roleObj;
    const name = getRoleLabel(roleName);
    setRole({ roleName: name, roleScope });
    onRoleClick({ roleName: name, roleScope });
    history.push(getRedirectUrl(url, `/roles/${kebabCase(toLower(roleName))}`));
  };

  const getActiveClass = (roleName) => {
    const activeRole = toUpper(roleName);
    if (getRoleLabel(role.roleName) === activeRole || getRoleLabel(selectedRole) === activeRole) return classes.active;
    return '';
  };

  return (
    <List>
      {(roles || []).length ? (
        roles.map((roleObj) => {
          const { roleName, roleScope } = roleObj;
          return (
            <ListItem
              key={`${roleName}-${roleScope}`}
              button
              divider={divider}
              className={clsx('rounded-l-full', getActiveClass(roleName))}
              onClick={() => handleListItemClick(roleObj)}>
              <ListItemText primary={getDisplayName(roleName)} />
            </ListItem>
          );
        })
      ) : (
        <ListItem alignItems="center">
          <Typography color="textSecondary">No Roles Found</Typography>
        </ListItem>
      )}
    </List>
  );
};

RolesList.propTypes = {
  divider: PropTypes.bool,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleName: PropTypes.string,
      roleScope: PropTypes.string,
    }).isRequired,
  ),
  selectedRole: PropTypes.string,
  onRoleClick: PropTypes.func,
};

RolesList.defaultProps = {
  divider: true,
  selectedRole: null,
  roles: [],
  onRoleClick: () => null,
};

export default RolesList;
