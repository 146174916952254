import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 32,
    width: 143,
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut,
    }),
    '& img': {
      height: 25,
      width: 143,
      maxWidth: 'initial',
    },
  },
}));

function Logo() {
  const classes = useStyles();

  return (
    <div className={clsx('logo-icon flex items-center overflow-hidden', classes.root)}>
      <img src={`${process.env.PUBLIC_URL}/images/rss-white.svg`} alt="logo" />
    </div>
  );
}

export default Logo;
