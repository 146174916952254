const settingsConfig = {
  layout: {
    style: 'layout1',
    config: {
      footer: {
        style: 'static',
        display: false,
      },
    },
  },
  customScrollbars: false,
  direction: 'ltr',
  theme: {
    main: 'default',
    navbar: 'defaultDark',
    toolbar: 'mainThemeLight',
    footer: 'mainThemeLight',
  },
};

export default settingsConfig;
