import { FormControl, makeStyles } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isValid } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { QUESTION_VARIANT } from './Helper';

const useStyles = makeStyles({
  textField: {
    '& .MuiOutlinedInput-input': { fontSize: '1.4rem' },
    '& .MuiFormHelperText-root': { fontSize: '1.4rem', marginLeft: '0', letterSpacing: '0' },
  },
  popper: { '& .MuiMenuItem-root, & .MuiButton-root': { fontSize: '1.4rem' } },
});

const FqTimePicker = ({
  ampm,
  autoFocus,
  disabled,
  fullWidth,
  label,
  labelVariant,
  minutesStep,
  onChange,
  propagateInvalid,
  questionKey,
  required,
  value,
  variant,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(null);

  const handleOnChange = (val) => {
    if (!val) {
      onChange(null, questionKey);
    } else if (isValid(val)) {
      onChange(val.toISOString(), questionKey);
    } else if (propagateInvalid) {
      onChange(val.toString(), questionKey);
    }
  };

  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      <FormControl fullWidth={fullWidth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            ampm={ampm}
            data-testid={label}
            autoFocus={autoFocus}
            disabled={disabled}
            id={questionKey}
            label={labelVariant !== 'long' ? label : null}
            minutesStep={minutesStep}
            onChange={handleOnChange}
            onError={setError}
            value={value && isValid(new Date(value)) ? new Date(value) : null}
            slotProps={{
              textField: {
                required,
                helperText: !propagateInvalid && error === 'invalidDate' ? 'Invalid time format' : '',
                className: classes.textField,
              },
              openPickerButton: { sx: { marginRight: '4px' } },
              openPickerIcon: { sx: { fontSize: '2.4rem', color: 'black' } },
              popper: { className: classes.popper },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

FqTimePicker.propTypes = {
  ampm: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  minutesStep: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  propagateInvalid: PropTypes.bool,
  questionKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FqTimePicker.defaultProps = {
  ampm: true,
  autoFocus: false,
  disabled: false,
  fullWidth: true,
  label: 'Select a date',
  labelVariant: 'default',
  minutesStep: 1,
  propagateInvalid: false,
  required: false,
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
};

export default FqTimePicker;
