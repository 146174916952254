/* eslint-disable import/no-cycle */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  IconButton,
  Link,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { FlexibleQuestionnaire } from '@rss/common';
import React from 'react';
import PropTypes from 'prop-types';

import FollowupQuestions from './FollowupQuestions';
import ChipLabel from './ChipLabel';
import { QUESTION_VARIANT } from './flexible-questionnaire/Helper';

const { isValid } = new FlexibleQuestionnaire();

const FollowupQuestionPanel = ({
  selectedItem,
  followupQuestions,
  id,
  panelExpanded,
  onPanelChange,
  onDone,
  onChange,
  onRemove,
  panelSummary,
  disabled,
  shouldValidate,
  variant,
}) => {
  const theme = useTheme();

  return (
    <Accordion
      square
      className="shadow-none"
      expanded={panelExpanded}
      TransitionProps={{ unmountOnExit: true }}
      onChange={(evt, expanded) => onPanelChange(id, panelExpanded)}>
      <AccordionSummary
        classes={{ root: 'pl-2' }}
        expandIcon={
          <Tooltip id="tooltip-expand" title="Expand" placement="top" arrow>
            <Icon>expand_more</Icon>
          </Tooltip>
        }>
        {selectedItem && (
          <div className="sm:inline-flex sm:items-start">
            <p className="max-w-sm pr-36 sm:w-full sm:pr-8">{panelSummary.primaryText}</p>
            {panelSummary.secondaryText && <span>{panelSummary.secondaryText}</span>}
            {shouldValidate && !isValid(followupQuestions, selectedItem) && (
              <ChipLabel
                classes={{ root: 'sm:ml-24 sm:pt-0 ml-0 pt-8' }}
                title="Incomplete"
                color={theme.colors.red[900]}
              />
            )}
          </div>
        )}
        <div className="absolute top-8 right-48 flex items-end items-center print:hidden">
          {panelSummary.detailsLink && (
            <Link
              role="button"
              target="_blank"
              data-cy="e2e-version-link"
              className="mr-20 h-20"
              href={panelSummary.detailsLink}
              rel="noreferrer">
              <Tooltip id="tooltip-view" title="View Details" placement="top" arrow>
                <Icon fontSize="small" color="primary">
                  open_in_new
                </Icon>
              </Tooltip>
            </Link>
          )}
          {!disabled && (
            <Tooltip id="tooltip-delete" title="Delete" placement="top" arrow>
              <IconButton aria-label="delete" className="h-32 w-32 text-danger-800" onClick={() => onRemove(id)}>
                <Icon fontSize="small">delete</Icon>
              </IconButton>
            </Tooltip>
          )}
        </div>
      </AccordionSummary>

      <AccordionDetails classes={{ root: 'p-0 mx-8' }}>
        <div id={id || 'follow-up-panel'} className="w-full">
          {followupQuestions.map((question) => (
            <FollowupQuestions
              key={question.key}
              question={question}
              onDone={onDone}
              selectedItem={selectedItem}
              onChange={onChange}
              disabled={disabled}
              shouldValidate={shouldValidate}
              variant={variant}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

FollowupQuestionPanel.propTypes = {
  selectedItem: PropTypes.objectOf(PropTypes.any).isRequired,
  followupQuestions: PropTypes.arrayOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  panelExpanded: PropTypes.bool.isRequired,
  onPanelChange: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  panelSummary: PropTypes.shape({
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    detailsLink: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FollowupQuestionPanel.defaultProps = {
  disabled: false,
  shouldValidate: false,
  variant: QUESTION_VARIANT.STANDARD,
};

export default React.memo(FollowupQuestionPanel);
