"use strict";

module.exports = {
  AMENDMENT_DRAFT: 'Amendment Draft',
  APPROVED: 'Approved',
  BIOSAFETY_REVIEW: 'Biosafety Review',
  DEACTIVATED: 'Deactivated',
  DRAFT: 'Draft',
  EXPIRED: 'Expired',
  IBC_REVIEW: 'IBC Review',
  PI_REVISE: 'PI Revise',
  RENEWAL_DRAFT: 'Renewal Draft'
};