import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Card, Icon } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import gql from 'graphql-tag';
import { get, take } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import config from '../config';
import { useProfile } from '../hooks';
import { FIND_RELATED_PEOPLE, FIND_RELATED_PEOPLE_NEPTUNE } from '../graphql/relationship.query';
import CampusSelect from './CampusSelect';
import InviteUserDialog from './InviteUserDialog';
import PersonDisplay from './PersonDisplay';
import Search from './Search';

const SEARCH_PEOPLE = gql`
  query search($campusCode: String!, $searchTerm: String!, $maxResults: Int) {
    people(campusCode: $campusCode, searchTerm: $searchTerm, maxResults: $maxResults) {
      userId
      firstName
      lastName
      email
    }
  }
`;

// HACK: make relatedPeopleNeptune to return consistent person format
function PersonSearch({
  InputLabelProps,
  allowUserInvite,
  autoFocus,
  campusCode,
  clearOnSelect,
  disabled,
  disableUnderline,
  filter,
  id,
  inputVariant,
  label,
  labelVariant,
  maxResults,
  onClear,
  onSelect,
  placeholder,
  required,
  showSuggestions,
  suggestionLimit,
  suggestions,
  tenantCode,
  value,
  variant,
}) {
  const { profile = {} } = useProfile();
  const [campus, setCampus] = useState();
  const [fullCampus, setFullCampus] = useState();
  const [inviteUser, setInviteUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [searchPeople, search] = useLazyQuery(SEARCH_PEOPLE);
  const suggestedPeople = useQuery(config.NEPTUNE ? FIND_RELATED_PEOPLE_NEPTUNE : FIND_RELATED_PEOPLE, {
    skip: !showSuggestions || !!suggestions,
  });

  useEffect(() => {
    if (campusCode || profile.campusCode) {
      setCampus(campusCode || profile.campusCode);
      const c = profile.relationships?.find(
        (r) => r.edge.label === RELATIONSHIP_LABEL.EDGE.AFFILIATED && r.node.label === RELATIONSHIP_LABEL.NODE.CAMPUS,
      );
      if (c?.node) {
        setFullCampus(c.node);
      }
    }
  }, [campusCode, profile]);

  const { data = {}, loading } = search.called ? search : suggestedPeople;
  const { people, relatedPeople, relatedPeopleNeptune } = data;

  let suggestionsList = showSuggestions ? relatedPeople || relatedPeopleNeptune || suggestions || [] : [];

  suggestionsList = suggestionLimit ? take(suggestionsList, suggestionLimit) : suggestionsList;

  const results = people || [];

  const filteredResults = (results.length ? results : suggestionsList)
    .filter((p) => filter.every((userId) => userId !== (p.id || p._id || p.userId)))
    .map((p) => ({ key: p.id || p._id || p.userId, ...p }));

  return (
    <div className="flex-grow space-y-16">
      {variant !== 'person' && (
        <CampusSelect
          id={`${id}-campus-select`}
          variant={variant}
          value={campus}
          tenantCode={tenantCode || ''}
          onSelect={(event) => {
            setCampus(event.campusCode);
            setFullCampus(event.campus);
            setSearchTerm('');
            searchPeople({ variables: { campusCode: event.campusCode, searchTerm: '', maxResults } });
          }}
        />
      )}
      <div className="flex-grow">
        <Search
          InputLabelProps={InputLabelProps}
          autoFocus={autoFocus}
          autocomplete
          clearOnSelect={clearOnSelect}
          disableUnderline={disableUnderline}
          disabled={disabled}
          id={id}
          isLoading={loading}
          items={filteredResults}
          label={label}
          labelVariant={labelVariant}
          placeholder={placeholder}
          required={required}
          value={value || ''}
          variant={inputVariant}
          openMenuOnFocus={false}
          itemToString={(person) => {
            if (!person) {
              return '';
            }
            const { firstName, lastName } = person;
            return `${lastName}, ${firstName}`;
          }}
          onChange={({ inputValue }) => {
            setSearchTerm(inputValue);
            searchPeople({ variables: { campusCode: campus, searchTerm: inputValue, maxResults } });
          }}
          onSelect={(selectedUser) => onSelect(selectedUser, fullCampus)}
          onClear={(e) => {
            setSearchTerm('');
            onClear(e);
          }}
          resultProps={{ title: showSuggestions && filteredResults.length && !results.length ? 'Suggestions' : null }}
          renderItem={(person) => {
            const { firstName, lastName, email } = person.label || person;
            return <PersonDisplay firstName={firstName} lastName={lastName} email={email} />;
          }}
        />
        {get(profile, 'features.platform.userInvitation.value') &&
          searchTerm &&
          allowUserInvite &&
          !results.length &&
          !loading && (
            <Card className="absolute z-10 w-full p-10">
              <Button
                onClick={() => {
                  setInviteUser(true);
                }}
                endIcon={<Icon>person_add</Icon>}
                color="primary">
                Invite a new user
              </Button>
              {inviteUser && (
                <InviteUserDialog
                  onCancel={() => {
                    setInviteUser(false);
                    setSearchTerm('');
                  }}
                  onClose={() => {
                    setInviteUser(false);
                    setSearchTerm('');
                  }}
                  reportId={allowUserInvite}
                />
              )}
            </Card>
          )}
      </div>
    </div>
  );
}

PersonSearch.propTypes = {
  InputLabelProps: PropTypes.objectOf(PropTypes.any),
  allowUserInvite: PropTypes.string,
  autoFocus: PropTypes.bool,
  campusCode: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  disabled: PropTypes.bool,
  filter: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  inputVariant: PropTypes.string,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  maxResults: PropTypes.number,
  onClear: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showSuggestions: PropTypes.bool,
  suggestionLimit: PropTypes.number,
  suggestions: PropTypes.arrayOf(PropTypes.any),
  tenantCode: PropTypes.string,
  value: PropTypes.objectOf(PropTypes.any),
  variant: PropTypes.oneOf(['campus', 'person', 'tenant']),
};

PersonSearch.defaultProps = {
  InputLabelProps: null,
  allowUserInvite: '',
  autoFocus: false,
  campusCode: null,
  clearOnSelect: false,
  disableUnderline: false,
  disabled: false,
  filter: [],
  id: null,
  inputVariant: 'outlined',
  label: 'Search Person',
  labelVariant: 'default',
  maxResults: 15,
  onClear: () => null,
  placeholder: 'Search Person',
  required: false,
  showSuggestions: false,
  suggestionLimit: null,
  suggestions: null,
  tenantCode: null,
  value: null,
  variant: 'person',
};

export default PersonSearch;
