const familySortCriteria = {
  ALPHABETICAL_ASC: {
    label: 'Alphabetical A-Z',
    value: 'ALPHABETICAL_ASC'
  },
  ALPHABETICAL_DESC: {
    label: 'Alphabetical Z-A',
    value: 'ALPHABETICAL_DESC'
  }
};
const containerSortCriteria = {
  RECEIVED_DATE_ASC: {
    label: 'Received Date (most recent first)',
    value: 'RECEIVED_DATE_ASC'
  },
  RECEIVED_DATE_DESC: {
    label: 'Received Date (oldest first)',
    value: 'RECEIVED_DATE_DESC'
  },
  OPENED_DATE_ASC: {
    label: 'Opened Date (most recent first)',
    value: 'OPENED_DATE_ASC'
  },
  OPENED_DATE_DESC: {
    label: 'Opened Date (oldest first)',
    value: 'OPENED_DATE_DESC'
  },
  UNOPENED: {
    label: 'Unopened',
    value: 'UNOPENED'
  }
};
module.exports.FAMILY_SORT_CRITERIA = familySortCriteria;
module.exports.CONTAINER_SORT_CRITERIA = containerSortCriteria;