module.exports = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  INACTIVE_NOT_RENEWED: 'INACTIVE NOT RENEWED',
  INACTIVE_VOLUNTARY_CANCELLATION: 'INACTIVE VOLUNTARY CANCELLATION',
  INACTIVE_CANCELLATION: 'INACTIVE CANCELLATION',
  INACTIVE_SUSPENDED: 'INACTIVE SUSPENDED',
  INACTIVE_INVALID_DATA: 'INACTIVE INVALID DATA',
  ACTIVE_SUSPENDED: 'ACTIVE SUSPENDED',
  INACTIVE_CONDITIONAL: 'INACTIVE CONDITIONAL'
};