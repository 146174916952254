import { convertToRaw, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import sanitizeHtml from 'sanitize-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function initEditor(html) {
  return html ? EditorState.createWithContent(stateFromHTML(html)) : EditorState.createEmpty();
}

function cleanHtml(currentContent) {
  const html = draftToHtml(convertToRaw(currentContent));
  return sanitizeHtml(html, {
    allowedTags: ['p', 'span', 'b', 'i', 'ins', 'em', 'u', 'strong', 'ul', 'ol', 'li'],
    allowedAttributes: {},
  });
}

function RichTextEditor({ placeholder, value, onChange }) {
  const [editorState, setEditorState] = useState(initEditor(value));

  useEffect(() => {
    setEditorState(initEditor(value));
  }, [value]);

  return (
    <Editor
      handlePastedText={() => false}
      wrapperClassName={onChange ? 'border rounded border-gray-100' : ''}
      editorClassName={onChange ? 'p-8 min-h-96' : ''}
      placeholder={placeholder}
      readOnly={!onChange}
      toolbarHidden={!onChange}
      toolbar={{
        options: ['inline', 'list', 'history'],
        inline: { options: ['bold', 'italic', 'underline'] },
        list: { options: ['unordered', 'ordered'] },
        history: {
          options: ['undo', 'redo'],
        },
      }}
      editorState={editorState}
      onBlur={() => {
        if (editorState.getLastChangeType() !== null) {
          const currentContent = editorState.getCurrentContent();
          onChange(cleanHtml(currentContent), currentContent.getPlainText());
        }
      }}
      onEditorStateChange={(newEditorState) => {
        setEditorState(newEditorState);
      }}
    />
  );
}

RichTextEditor.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

RichTextEditor.defaultProps = {
  placeholder: '',
  onChange: null,
  value: '',
};

export default RichTextEditor;
