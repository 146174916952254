import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Hidden,
  Icon,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { object, string } from 'yup';
import { GET_INSPECTION_REPORT, INVITE_USER_TO_REPORT } from '../inspect/graphql/query';
import DialogHeader from './DialogHeader';

const SEND_INVITE = gql`
  mutation sendInvite($invite: InvitationInput!) {
    sendInvite(invite: $invite)
  }
`;

const formSchema = object().shape({
  firstName: string().trim().required('First name is required.'),
  lastName: string().trim().required('Last name is required.'),
  email: string().trim().email('Invalid email.').required('Email is required.'),
});

const InviteUserDialog = ({ onCancel, onClose, reportId }) => {
  const [invite, setInvite] = useState({ firstName: '', lastName: '', email: '' });
  const [errors, setErrors] = useState({});
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [sendInvite, { loading }] = useMutation(SEND_INVITE, {
    onCompleted: (data) => {
      if (data.sendInvite.error) {
        return setShowDuplicate(true);
      }
      return onClose(data.sendInvite);
    },
    onError: () => {
      setShowDuplicate(true);
    },
  });

  const [sendInspectInvite] = useMutation(INVITE_USER_TO_REPORT, {
    onCompleted: (data) => {
      return onClose();
    },
    refetchQueries: [{ query: GET_INSPECTION_REPORT, variables: { id: reportId } }],
  });

  const submitForm = () => {
    try {
      formSchema.validateSync(invite, { abortEarly: false });
      if (reportId) {
        sendInspectInvite({ variables: { reportId, invite } });
      } else if (!loading) {
        sendInvite({ variables: { invite } });
      }
    } catch (e) {
      setErrors(
        e.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {}),
      );
    }
  };

  return (
    <Dialog open>
      <DialogHeader>
        <Typography variant="subtitle1" color="inherit">
          Invite New User
        </Typography>
        <IconButton aria-label="Close" className="absolute right-10 top-10 p-10 text-white" onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      </DialogHeader>
      <DialogContent>
        <DialogContentText className="mt-5">Please enter a name and email to send the invite to</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="first"
              required
              fullWidth
              error={errors.firstName && !invite.firstName}
              autoComplete="new-password"
              onChange={(e) => setInvite({ ...invite, firstName: e.target.value })}
              label="First Name"
              helperText={!invite.firstName && errors.firstName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="last"
              required
              fullWidth
              error={!invite.lastName && errors.lastName}
              autoComplete="new-password"
              onChange={(e) => setInvite({ ...invite, lastName: e.target.value })}
              label="Last Name"
              helperText={!invite.lastName && errors.lastName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              required
              fullWidth
              error={errors.email}
              autoComplete="new-password"
              onChange={(e) => {
                if (errors.email) {
                  setErrors({ ...errors, email: false });
                }
                if (showDuplicate) {
                  setShowDuplicate(false);
                }
                setInvite({ ...invite, email: e.target.value });
              }}
              type="email"
              label="Email"
              helperText={errors.email}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="m-10">
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!loading) {
              submitForm();
            }
          }}
          variant="contained"
          color="primary"
          startIcon={<Icon>send</Icon>}>
          Send <Hidden smDown>Invitation</Hidden>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InviteUserDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  reportId: PropTypes.string,
};

InviteUserDialog.defaultProps = {
  reportId: '',
};

export default InviteUserDialog;
