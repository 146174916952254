import { ServerStyleSheets } from '@material-ui/core/styles';
import { ANALYTICS_ROLE, RELATIONSHIP_LABEL, RelationshipHelper } from '@rss/common';
import cookie from 'cookie';
import { isValid } from 'date-fns';
import { saveAs } from 'file-saver';
import { get, isEmpty, isEqual, pull, snakeCase, sortBy, startCase, toLower, toUpper, uniqWith } from 'lodash';
import { singular } from 'pluralize';

import config from '../config';
import { profileConfig, roleTypes } from './profile-config';

const {
  EDGE: {
    ANALYTICS_ADMIN,
    ASSESSMENT_ADMIN,
    ASSESSMENT_READ_ONLY,
    ACCOUNT_MANAGER,
    AFFILIATED,
    BSAS_ADMIN,
    CHEMICAL_ADMIN,
    CHEMICAL_ADMIN_READ_ONLY,
    CLI_ADMIN,
    CLIENT_ADMIN,
    CONFIGURATION_MANAGER,
    RSS_DATA_ADMIN,
    RSS_DATA_ADMIN_LII,
    EHS_DEPARTMENT_ADMIN,
    EFR_ADMIN,
    FACILITY_ADMIN,
    ROLE_ADMIN,
    IBC_MEMBER,
    IPM_COORDINATOR,
    IPMC_MEMBER,
    INSPECT_ADMIN,
    LHA_READ_ONLY,
    LOCATION_MANAGER,
    LOTO_PROCEDURES_TEMPLATE_ADMIN,
    MEMBER,
    NFPA_ADMIN,
    PPE_COORDINATOR,
    PPE_MANAGEMENT,
    PROGRAM_ADMIN,
    SERVICE_DESK,
    SPH_PROCEDURES_ADMIN,
    SPH_PROCEDURES_TEMPLATE_ADMIN,
    SOP_PROCEDURES_ADMIN,
    SOP_PROCEDURES_TEMPLATE_ADMIN,
    USER_ADMIN,
    WSSP_PROCEDURES_ADMIN,
    WPVP_PROCEDURES_ADMIN,
    WPVP_PROCEDURES_TEMPLATE_ADMIN,
    DISTRICT_ADMIN,
  },
  EDGE_TYPE: { ROLE },
  NODE,
  NODE: {
    BUCKET,
    BUA_CONFIG,
    CAMPUS,
    DOMAIN,
    ERGO,
    FACILITY,
    FITTEST_DOCUMENT,
    FLEX_CONFIG,
    DISTRICT,
    GLOBAL,
    GROUP,
    INVENTORY,
    LAB_HAZARD,
    LAB_HAZARD_CONFIG,
    PESTICIDE_USE,
    BUA,
    APPLICATOR_GROUP,
    PROGRAM,
    PESTICIDE_CONFIG,
    RESPIRATOR_CONFIG,
    ROUTING_LIST,
    QUESTIONNAIRE_SERIES,
    TENANT,
    UNIT_RISK,
  },
} = RELATIONSHIP_LABEL;

export const getImpersonateId = () => cookie.parse(document.cookie)[config.SPOOF_USER_KEY] || null;

export const getHeaders = async () => {
  const headers = {};
  const userId = getImpersonateId();
  if (userId) {
    headers['impersonate-id'] = userId;
  }
  return headers;
};

export const fetchData = async (url, options = {}) => {
  const headers = await getHeaders();
  return fetch(url, {
    ...options,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  });
};

const appCss = () => {
  return [...document.styleSheets]
    .map((styleSheet) => {
      try {
        return [...styleSheet.cssRules]
          .map((rule) => {
            // This allows us to pull from icon cdn, otherwise the icons are broken.
            if (rule.cssText.includes('icon')) {
              return null;
            }

            return rule.cssText;
          })
          .join('');
      } catch (e) {
        return null;
      }
    })
    .filter(Boolean)
    .join('\n');
};

export const exportAsPDF = async (title, documentId = 'root', url = null) => {
  const filename = `${title.replace(/\s/g, '_')}__${new Date().toLocaleDateString().replace(/\//g, '_')}.pdf`;
  let response = null;
  if (documentId) {
    const body = document.getElementById(documentId).innerHTML.replace(/src="\//g, `src="${window.location.origin}/`);
    const sheets = new ServerStyleSheets();
    sheets.collect(body);
    const css = sheets.getStyleElement(body);
    const template = `
    <html lang="en">
    <style>${css}</style>
    <style>${appCss()}</style>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <body>${body}</body>
  </html>`;
    response = await fetch(`${config.PRINTABLE_URL}/pdf/from/html`, {
      body: JSON.stringify({ template }),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    });
  } else {
    response = await fetch(`${config.PRINTABLE_URL}/pdf/from/url`, {
      body: JSON.stringify({ url: url || window.location.href }),
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    });
  }

  const blob = await response.blob();
  saveAs(blob, filename);
  return true;
};

export const anchorToRightMenu = (anchors) => {
  return Object.keys(anchors).map((key) => ({
    value: key,
    label: anchors[key],
    hash: anchors[key],
  }));
};

export const getDisplayName = (label) => {
  switch (toUpper(label)) {
    case ASSESSMENT_ADMIN:
      return 'Admin';
    case ASSESSMENT_READ_ONLY:
      return 'Read Only';
    case IPM_COORDINATOR:
      return 'IPM Coordinator';
    case IPMC_MEMBER:
      return 'IPM Committee Member';
    case PPE_COORDINATOR:
      return 'PPE Coordinator';
    case IBC_MEMBER:
      return 'IBC Member';
    case NFPA_ADMIN:
      return 'NFPA Admin';
    case BSAS_ADMIN:
      return 'BSAS Admin';
    case CLI_ADMIN:
      return 'CLI Admin';
    case ERGO:
      return ERGO;
    case EHS_DEPARTMENT_ADMIN:
      return 'EHS Department Admin';
    case EFR_ADMIN:
      return 'EFR Admin';
    case FITTEST_DOCUMENT:
      return 'Fit Test Document';
    case UNIT_RISK:
      return 'Unit Risk Assessment';
    case LAB_HAZARD:
      return 'Lab Hazard Assessment';
    case PESTICIDE_USE:
      return 'Pesticide Use Request';
    case BUA:
      return BUA;
    case LOTO_PROCEDURES_TEMPLATE_ADMIN:
      return 'LOTO Procedures Template Admin';
    case SPH_PROCEDURES_TEMPLATE_ADMIN:
      return 'SPH Procedures Template Admin';
    case SPH_PROCEDURES_ADMIN:
      return 'SPH Procedures Admin';
    case SOP_PROCEDURES_TEMPLATE_ADMIN:
      return 'SOP Procedures Template Admin';
    case SOP_PROCEDURES_ADMIN:
      return 'SOP Procedures Admin';
    case WPVP_PROCEDURES_ADMIN:
      return 'WPVP Procedures Admin';
    case WSSP_PROCEDURES_ADMIN:
      return 'WSSP Procedures Admin';
    case WPVP_PROCEDURES_TEMPLATE_ADMIN:
      return 'WPVP Procedures Template Admin';
    case INVENTORY:
      return 'Chemical Inventory';
    case INSPECT_ADMIN:
      return 'Admin';
    case QUESTIONNAIRE_SERIES:
      return 'Questionnaire';
    case DISTRICT_ADMIN:
      return 'District Admin';
    case APPLICATOR_GROUP:
      return 'Applicator Group';
    case BUA_CONFIG:
      return 'Biological Use Authorization';
    case LAB_HAZARD_CONFIG:
      return 'Lab Hazard';
    case RESPIRATOR_CONFIG:
      return 'RMEQ';
    case PESTICIDE_CONFIG:
      return 'Pesticide';
    case BUCKET:
      return 'Bucket';
    case FLEX_CONFIG:
      return 'Program Flex';
    case LHA_READ_ONLY:
      return 'LHA Read Only';
    case PPE_MANAGEMENT:
      return 'PPE Inventory';
    case ROUTING_LIST:
      return 'Routing List';
    default:
      if (label && label.includes('AX_') && ANALYTICS_ROLE.EDGE_ROLES[label]) {
        return ANALYTICS_ROLE.EDGE_ROLES[label];
      }

      return startCase(toLower(singular(label)));
  }
};

export const getRoleLabel = (name) => toUpper(snakeCase(toLower(name)));

export const getRolesBasedOnLabel = (nodeLabel) => {
  const label = toUpper(nodeLabel);
  return get(profileConfig, `${label}.roles`, []);
};

export const getSortedUsages = (usages, currentUsage) => {
  const sortedUsages = sortBy(usages, ['node.label', 'node.name']);
  return currentUsage ? [currentUsage].concat(pull(sortedUsages, currentUsage)) : sortedUsages;
};

export const camelcase = (input) => {
  const string = input || '';
  return string
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/^(.)|\s(.)/g, ($1) => {
      return $1.toUpperCase();
    });
};

export const roleHasPermission = (
  rolesWithAccess,
  relationships,
  targetId,
  nodeLabel,
  parentId,
  usages = null,
  excludeGlobalRoles = false,
) => {
  const userRelationships = relationships.filter(
    (r) => !excludeGlobalRoles || ![AFFILIATED, excludeGlobalRoles && GLOBAL].includes(get(r, 'node.label')),
  );

  return rolesWithAccess.some((roleWithAccess) =>
    userRelationships.some((r) => {
      const hasAccessToTarget =
        ((targetId && get(r, 'node.id') === targetId) || (parentId && get(r, 'node.id') === parentId)) &&
        get(r, 'edge.label') === roleWithAccess.role &&
        (get(roleWithAccess, 'to.label') === nodeLabel || get(roleWithAccess, 'to.label') === get(r, 'node.label'));
      const hasAdminAccess =
        !hasAccessToTarget && get(roleWithAccess, 'to.label') === null && get(r, 'edge.label') === roleWithAccess.role;
      const hasAccessToTargetChild =
        !hasAdminAccess &&
        (usages || []).some(
          ({ node }) =>
            node.id === r.node.id && r.edge.label === roleWithAccess.role && r.node.label === roleWithAccess.to.label,
        );
      return hasAccessToTarget || hasAdminAccess || hasAccessToTargetChild;
    }),
  );
};

export const hasRssToolAccessToLabel = (rolePermissions, relationships, nodeLabel, rssToolRoles = []) => {
  const label = toUpper(nodeLabel);
  const rolesWithAdminAccess = [
    ...get(rolePermissions, `${label}.ADMIN`, []),
    ...get(rolePermissions, `${label}.READ`, []),
    ...get(rolePermissions, `${label}.WRITE`, []),
  ].filter((item) => isEmpty(rssToolRoles) || rssToolRoles.includes(item.role));
  return roleHasPermission(rolesWithAdminAccess, relationships, null, label);
};

export const hasAdminAccessToLabel = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  usages = null,
  parentId,
  excludeGlobalRoles = false,
) => {
  const label = toUpper(nodeLabel);
  const rolesWithAdminAccess = get(rolePermissions, `${label}.ADMIN`, []);
  return roleHasPermission(rolesWithAdminAccess, relationships, targetId, label, parentId, usages, excludeGlobalRoles);
};

export const hasWriteAccessToLabel = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  usages = null,
  parentId,
  excludeGlobalRoles = false,
) => {
  const label = toUpper(nodeLabel);
  const rolesWithWriteAccess = get(rolePermissions, `${label}.WRITE`, []);
  return roleHasPermission(rolesWithWriteAccess, relationships, targetId, label, parentId, usages, excludeGlobalRoles);
};

export const hasAccessToNodeTransfer = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  excludeRoles = [],
) => {
  const label = toUpper(nodeLabel);
  let rolesWithAdminAccess = get(rolePermissions, `${label}.ADMIN`, []);
  rolesWithAdminAccess = excludeRoles.length
    ? rolesWithAdminAccess.filter((r) => !r.role.includes(excludeRoles))
    : rolesWithAdminAccess;
  return roleHasPermission(rolesWithAdminAccess, relationships, targetId, label);
};

export const getAccessRolesBasedOnLabel = (rolePermissions, label, targetId = null, readAccess) => {
  const allowedRolesForLabel = get(rolePermissions, `${label}.ALLOWED_ROLES`);
  const allowedRolesAccess =
    (targetId &&
      Object.values(allowedRolesForLabel || []).flatMap((roleAccess) =>
        Object.values(roleAccess).flatMap((role) => role),
      )) ||
    [];
  const readAccessRoles = readAccess ? get(rolePermissions, `${label}.READ`, []) : [];
  const rolesWithAccess = uniqWith(
    [
      ...readAccessRoles,
      ...get(rolePermissions, `${label}.ADMIN`, []),
      ...get(rolePermissions, `${label}.WRITE`, []),
      ...allowedRolesAccess,
    ],
    isEqual,
  );
  return { rolesWithAccess, allowedRolesForLabel };
};

export const hasReadAccessToTarget = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  usages = null,
  parentId,
) => {
  const label = toUpper(nodeLabel);
  const { rolesWithAccess } = getAccessRolesBasedOnLabel(rolePermissions, label, targetId, true);
  const hasAccessToParentOrTarget = roleHasPermission(
    rolesWithAccess,
    relationships,
    targetId,
    label,
    parentId,
    usages,
  );
  return hasAccessToParentOrTarget;
};

export const hasWriteAccessToNode = (
  rolePermissions,
  relationships,
  nodeLabel,
  targetId = null,
  usages = null,
  parentId,
  targetRole = null,
) => {
  const label = toUpper(nodeLabel);
  const { allowedRolesForLabel, rolesWithAccess } = getAccessRolesBasedOnLabel(rolePermissions, label, targetId, false);

  const targetRoleWithWriteAccess =
    targetRole &&
    get(allowedRolesForLabel || {}, `${targetRole}.WRITE`, []).some((access) => {
      return relationships.some((rel) => get(rel, 'edge.label') === access.role);
    });

  const hasAccessToParentOrTarget = roleHasPermission(
    rolesWithAccess,
    relationships,
    targetId,
    label,
    parentId,
    usages,
  );
  return targetRole ? targetRoleWithWriteAccess : hasAccessToParentOrTarget;
};

export const filterOrganizationsByMetaData = (node) => {
  return node.label !== GROUP || isEmpty(node.tags);
};

export const getLoggedInUser = () => {
  const item = window.localStorage.getItem('RSS:USER') ?? {};
  return JSON.parse(item);
};

export const hasImpersonateAccess = () => {
  const user = getLoggedInUser();

  return Boolean(user.rss);
};

export const descendingComparator = (a, b, orderByKey, dataType) => {
  let aValue = a[orderByKey];
  let bValue = b[orderByKey];

  if (dataType === 'date') {
    aValue = isValid(new Date(a[orderByKey])) ? new Date(a[orderByKey]) : new Date(0);
    bValue = isValid(new Date(b[orderByKey])) ? new Date(b[orderByKey]) : new Date(0);
  } else {
    aValue = aValue ? aValue.toString().toLowerCase() : aValue;
    bValue = bValue ? bValue.toString().toLowerCase() : bValue;
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
};

export const sheetFilesReader = async (files, parserOptions) => {
  if (!files) {
    return null;
  }

  const headers = await getHeaders();
  const formData = new FormData();
  files.map((file, index) => formData.append(`file${index}`, file));
  formData.append('options', JSON.stringify(parserOptions));

  const data = await fetch(`${config.PRINTABLE_URL}/xlsx/to/json`, {
    body: formData,
    headers,
    method: 'POST',
  });

  const result = await data.json();
  return result;
};

export const exportToExcel = async (filename, sheets, options = {}) => {
  const response = await fetchData(`${config.PRINTABLE_URL}/xlsx/from/json`, {
    body: JSON.stringify({ filename, data: sheets, options }),
    method: 'POST',
  });

  const blob = await response.blob();
  saveAs(blob, filename);
};

export const canViewOrganization = (features, label) => {
  const nodeLabel = toUpper(label);
  if (!Object.keys(NODE).includes(nodeLabel)) {
    return false;
  }
  switch (nodeLabel) {
    case APPLICATOR_GROUP:
      return get(features, 'organization.showApplicatorGroups.value', false);
    case GROUP:
      return get(features, 'organization.showGroups.value', true);
    case PROGRAM:
      return get(features, 'organization.showPrograms.value', true);
    case FACILITY:
      return get(features, 'organization.showFacilities.value', false);
    case DISTRICT:
      return get(features, 'organization.showFacilities.value', false);
    default:
      return true;
  }
};

export const canManageOrg = (
  { features, relationships } = {},
  rolePermissions,
  orgType,
  targetId,
  excludeGlobalRoles = false,
) => {
  return (
    relationships &&
    canViewOrganization(features, orgType) &&
    (hasAdminAccessToLabel(rolePermissions, relationships, orgType, targetId, null, null, excludeGlobalRoles) ||
      hasWriteAccessToLabel(rolePermissions, relationships, orgType, targetId, null, null, excludeGlobalRoles))
  );
};

export const isExternalRole = (type) => !type || type === 'INTERNAL-EXTERNAL';

export const isFeatureSettingOn = (features = [], featureSettings) =>
  features.some((feature) => get(featureSettings, `${feature}.showFeature.value`, false));

export const hasOrgLevelAdminRole = ({ orgNode: { id, label }, relationships, featureSettings, rolePermissions }) =>
  Object.entries(get(rolePermissions, `${label}.ALLOWED_ROLES`, {})).some(
    ([key, value]) =>
      isExternalRole(value.TYPE) &&
      isFeatureSettingOn(value.FEATURES, featureSettings) &&
      get(featureSettings, value?.FEATURE_FLAG, true) &&
      RelationshipHelper.hasRelationshipWithRole(id, relationships, key),
  );

export const hasDomainLevelAdminRole = ({ campusCode, relationships, featureSettings, rolePermissions, domainId }) => {
  return Object.entries(get(rolePermissions, 'DOMAIN.ALLOWED_ROLES', {})).some(
    ([key, value]) =>
      isExternalRole(value.TYPE) &&
      isFeatureSettingOn(value.FEATURES, featureSettings) &&
      get(featureSettings, value?.FEATURE_FLAG, true) &&
      RelationshipHelper.hasRelationshipWithRole(domainId, relationships, key),
  );
};

export const mapYupValidationErrorToErrorMap = ({ inner }) => {
  return inner.reduce((acc, err) => {
    return { ...acc, [err.path]: [...(acc[err.path] || []), err.message] };
  }, {});
};

export const inlineErrorMessages = (errorString) => {
  const errorStrings = Array.isArray(errorString) ? errorString : [errorString];
  const errorMsg = errorStrings.filter(Boolean).reduce((acc, curr) => {
    if (typeof curr === 'string') {
      const msg = /\.\s*$/.test(curr) ? curr.trim() : curr.trim().concat('.');
      return [acc, msg];
    }

    return [acc, curr];
  }, []);

  return errorMsg;
};

export const convertToSingular = (type) => singular(type || '');

export const getParentBasedOnType = (roleType, tenantCode, campusCode, domainId) => {
  switch (toUpper(roleType)) {
    case GLOBAL:
      return { id: GLOBAL, label: GLOBAL };
    case TENANT:
      return { id: tenantCode, label: TENANT };
    case DOMAIN:
      return { id: domainId, label: DOMAIN };
    case CAMPUS:
    default:
      return { id: campusCode, label: CAMPUS };
  }
};

export const getInternalRoles = (rolePermissions, relationships) => {
  const roles = [];
  // list user global roles
  const userGlobalRoles = uniqWith(
    (relationships || [])
      .filter((rel) => rel.edge.type === ROLE && rel.edge.label !== AFFILIATED && rel.node.label === GLOBAL)
      .map((rel) => rel.edge.label),
    isEqual,
  );

  Object.entries(rolePermissions).forEach(([key]) => {
    Object.entries(get(rolePermissions, `${key}.ALLOWED_ROLES`, [])).forEach(([index, value]) => {
      const hasAccess = [...get(value, `ADMIN`, []), ...get(value, `WRITE`, [])].some((roleWithAccess) =>
        userGlobalRoles.some((userRole) => userRole === roleWithAccess.role),
      );
      if ([roleTypes.INTERNAL, roleTypes.INTERNAL_EXTERNAL].includes(value.TYPE || '') && hasAccess) {
        roles.push({ roleName: index, roleScope: key });
      }
    });
  });
  return sortBy(roles, ['roleName']);
};

export const getRolesBasedOnOrg = ({ id, label }, relationships, featureSettings, rolePermissions, type) => {
  const roles = [];
  // list all user roles for the given organization with id and label
  const userRoles = uniqWith(
    (relationships || [])
      .filter(
        (rel) =>
          rel.node.id === id && rel.node.label === label && rel.edge.type === ROLE && rel.edge.label !== AFFILIATED,
      )
      .map((rel) => rel.edge.label),
    isEqual,
  );

  // list all unique roles with admin or write access
  const roleWithWriteOrAdminAccess = uniqWith(
    [...get(rolePermissions, `${label}.ADMIN`, []), ...get(rolePermissions, `${label}.WRITE`, [])],
    isEqual,
  );

  const hasWriteAccessToNodeRes = roleWithWriteOrAdminAccess.some((roleWithAccess) =>
    userRoles.some((userRole) => userRole === roleWithAccess.role),
  );

  // list all allowed roles that can be assign under given organization
  let allowedRoles = Object.entries(get(rolePermissions, `${label}.ALLOWED_ROLES`, {})).filter(([key, value]) =>
    get(featureSettings, value?.FEATURE_FLAG, true),
  );

  // filter all allowed roles with feature type if provided
  allowedRoles =
    type && type.trim() ? allowedRoles.filter(([key, value]) => value?.FEATURES?.includes(type)) : allowedRoles;

  // build roles list
  if (hasWriteAccessToNodeRes) {
    allowedRoles.forEach(([key, value]) => {
      if (isExternalRole(value.TYPE) && isFeatureSettingOn(value.FEATURES, featureSettings)) {
        roles.push({ roleName: key, roleScope: label });
      }
    });
  } else {
    (userRoles || []).map((userRole) =>
      allowedRoles.forEach(([key, value]) => {
        if (
          isExternalRole(value.TYPE) &&
          isFeatureSettingOn(value.FEATURES, featureSettings) &&
          ((value.WRITE || []).some((w) => w.role === userRole) || (value.READ || []).some((w) => w.role === userRole))
        ) {
          roles.push({ roleName: key, roleScope: label });
        }
      }),
    );
  }

  return sortBy(
    uniqWith(
      roles.filter((admin) => admin.roleName !== AFFILIATED),
      isEqual,
    ),
    ['roleName'],
  );
};

export const sortData = (data = [], col, dir, fallbackCols = [], colDataType) =>
  [...data].sort((a, b) => {
    const [fallbackCol, ...fallbackOthers] = fallbackCols;

    const compare =
      dir === 'desc' ? descendingComparator(a, b, col, colDataType) : -descendingComparator(a, b, col, colDataType);

    if (compare !== 0 || !fallbackOthers.length) {
      return compare;
    }

    return sortData(data, fallbackCol, 'desc', fallbackOthers);
  });

export const parseSearchParams = (value) => {
  const params = { ...value };

  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });

  return { ...params };
};

export const hasChemicalAdminRole = ({ campusCode, relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, CHEMICAL_ADMIN);

export const hasChemicalAdminReadOnlyRole = ({ campusCode, relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, CHEMICAL_ADMIN_READ_ONLY);

export const hasConfigManagerRole = ({ relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(GLOBAL, relationships, CONFIGURATION_MANAGER);

export const hasServiceDeskRole = ({ relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(GLOBAL, relationships, SERVICE_DESK);

export const hasRoleAdmin = ({ campusCode, relationships, features } = {}) =>
  get(features, 'platform.enableRoleRestructuring.value', false) &&
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, ROLE_ADMIN);

export const hasAccountManagerRole = ({ campusCode, relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, ACCOUNT_MANAGER);

export const hasUserAdminRole = ({ campusCode, features, relationships } = {}) =>
  get(features, 'platform.enableRoleRestructuring.value', false) &&
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, USER_ADMIN);

export const hasRssDataAdminRole = ({ relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(GLOBAL, relationships, RSS_DATA_ADMIN);

export const hasRssDataAdminL2Role = ({ relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(GLOBAL, relationships, RSS_DATA_ADMIN_LII);

export const hasDistrictAdminRole = ({ campusCode, relationships } = {}) =>
  RelationshipHelper.hasAnyOfRoles(relationships, DISTRICT_ADMIN) ||
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, AFFILIATED);

export const hasFacilityAdminRole = ({ campusCode, relationships, features } = {}) =>
  (get(features, 'platform.enableRoleRestructuring.value', false) &&
    RelationshipHelper.hasAnyOfRoles(relationships, FACILITY_ADMIN)) ||
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, AFFILIATED);

export const hasClientAdminRole = ({ relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(GLOBAL, relationships, CLIENT_ADMIN);

export const hasAnalyticsAdminRole = ({ relationships } = {}) =>
  RelationshipHelper.hasRelationshipWithRole(GLOBAL, relationships, ANALYTICS_ADMIN);

export const hasLocationManagerRole = ({ campusCode, relationships } = {}) => {
  return RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, LOCATION_MANAGER);
};

export const hasPpeCoordinatorRole = ({ campusCode, relationships } = {}) => {
  return RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, PPE_COORDINATOR);
};

export const hasProgramRoleToDomain = ({ relationships } = {}, domainId) =>
  relationships.some(
    ({ node, edge, parentClientNode = {} }) =>
      parentClientNode?.id === domainId && node.label === PROGRAM && [PROGRAM_ADMIN, MEMBER].includes(edge.label),
  );

export const getAncestorCampus = (ancestors = []) => {
  const { id, name } = ancestors?.find((a) => a.label === CAMPUS) || {};
  if (id && name) {
    return { campusCode: id, name };
  }

  return null;
};

export const buildAddress = ({ address, city, state, zipcode, zipCode }) => {
  return [address, city, state]
    .filter(Boolean)
    .join(', ')
    .concat(` ${zipcode || zipCode || ''}`)
    .trim();
};

export const removeSpecialCharacters = (value) => value.replace(/[^a-zA-Z ]/g, '');

export const buildPageTitle = (titleText, campusShortName, domainName) => {
  if (domainName) {
    return `${titleText} (${domainName})`;
  }
  if (campusShortName) {
    return `${campusShortName} ${titleText}`;
  }
  return titleText;
};

export const mapOrganizationNode = ({ node, edge, owner }) => ({
  id: node.id,
  name: node.name,
  roles: node.roles || [],
  label: edge && edge.label ? edge.label : node.label,
  createdDate: node.createdDate ? new Date(node.createdDate) : null,
  owner: owner ? `${owner.lastName}, ${owner.firstName}` : null,
  district: node.district,
  type: node.type,
  license: node.license,
  code: node.code,
  address: buildAddress(node),
  active: node.active,
});

export const getUserDisplayName = ({ firstName, lastName }) => [lastName, firstName].filter(Boolean).join(', ');

export const mapOrgListByRoles = (relationships, type) => {
  const filteredRelationships = relationships.filter(
    ({ node }) => RelationshipHelper.hasNodeLabel(node.label, type) && filterOrganizationsByMetaData(node),
  );
  const response = filteredRelationships?.length
    ? Object.values(
        filteredRelationships.reduce((acc, curr) => {
          const { node, edge, owner } = curr;
          acc[node.id] = !acc[node.id]
            ? { node: { ...node, roles: [edge.label] }, edge, owner }
            : {
                node: {
                  ...acc[node.id].node,
                  roles: [...acc[node.id].node.roles, edge.label],
                },
                edge,
                owner,
              };
          return acc;
        }, []),
      )
    : [];
  return response.map((item) => mapOrganizationNode(item));
};

export const MARKDOWN_OPTIONS = {
  wrapper: 'div',
  forceWrapper: true,
  overrides: {
    p: {
      component: 'p',
      props: {
        className: 'py-2',
      },
    },
    ul: {
      component: 'ul',
      props: {
        className: 'list-disc mx-28',
      },
    },
    ol: {
      component: 'ol',
      props: {
        className: 'list-decimal mx-28',
      },
    },
    li: {
      component: 'li',
      props: {
        className: 'my-10',
      },
    },
    a: {
      component: 'a',
      props: {
        role: 'button',
        target: '_blank',
        className: 'text-primary-500',
      },
    },
  },
};

export const parseArrayToString = (arrayOfString) => {
  const array = Array.isArray(arrayOfString) ? arrayOfString : [arrayOfString];
  return array.filter(Boolean).reduce((acc, curr, currIndex, source) => {
    if (!acc) {
      return curr;
    }

    return acc.concat(currIndex === source.length - 1 ? ', and ' : ', ').concat(curr);
  }, null);
};

export const hasMatch = (text, term) => {
  if (!term) {
    return false;
  }

  const terms = (term.toLowerCase().split(/\s+/) || []).filter(Boolean);
  const inputText = text.trim().toLowerCase();

  return terms.every((item) => inputText.includes(item));
};

export const getBaseUrl = (url, defaultBaseUrl = '/profile/') => {
  if (url.includes('account-management/')) {
    return '/account-management/';
  }
  if (url.includes('campus/')) {
    return '/campus/';
  }
  if (url.includes('domain/')) {
    const baseUrl = new RegExp('(/domain/[^/.]+)').exec(url)?.[0];
    if (baseUrl) {
      return `${baseUrl}/`;
    }
  }
  if (url.includes('rss-tools/')) {
    return '/rss-tools/';
  }
  return defaultBaseUrl;
};

export const getRedirectUrl = (url, uri, defaultBaseUrl = '/profile/') => {
  return [getBaseUrl(url, defaultBaseUrl), uri].join('/').replace(new RegExp('/+', 'ig'), '/');
};

export const isTenantsAPIEnabled = (user) => get(user, 'features.[client-config].useTenantsAPI.value');

export const GLOBAL_TENANT = {
  tenantCode: 'GLOBAL',
  name: 'Global Tenant',
  campusList: [
    {
      campusCode: 'GLOBAL',
      name: 'Global Campus',
      shortName: 'Global',
      idp: '',
      idps: [],
      uri: '',
      domains: [],
    },
  ],
};
