import { ApolloClient, InMemoryCache, createHttpLink, from, useMutation, useQuery } from '@apollo/client';

import { authMiddleware } from '../../services/apollo';
import config from '../../config';

const httpLink = createHttpLink({ uri: `${config.INSPECTION_TOOL_URL}/graphql`, credentials: 'include' });

const DoogleClient = new ApolloClient({
  link: from([authMiddleware, httpLink]),
  cache: new InMemoryCache(),
});

export function useDoogleQuery(query, opts = {}) {
  return useQuery(query, { ...opts, client: DoogleClient });
}

export function useDoogleMutation(mutation, opts = {}) {
  return useMutation(mutation, { ...opts, client: DoogleClient });
}
