import { useMutation } from '@apollo/client';
import { Button, DialogContentText, Grid, Icon, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { object, string } from 'yup';
import { RELATIONSHIP_LABEL } from '@rss/common';

import { PROFILE_QUERY } from '../graphql/profile.query';
import { UPSERT_ROUTING_GROUP, DEACTIVATE_ROUTING } from '../graphql/relationship.query';
import { inlineErrorMessages, mapYupValidationErrorToErrorMap } from '../shared/helper';
import DialogTemplate from './DialogTemplate';
import IconWithTooltip from './IconWithToolTip';

const {
  NODE: { ROUTING_GROUP },
} = RELATIONSHIP_LABEL;

const ROUTING_GROUP_SCHEMA = object().shape({
  id: string(),
  name: string().required().label('Routing Group Name'),
});

const ManageRoutingGroupDialog = ({
  closeDialog,
  description,
  isOpen,
  onSaveComplete,
  onDeleteComplete,
  campusCode,
  title,
  routingList,
  data: routingGroup,
  isCreate,
}) => {
  const [errors, setErrors] = useState(null);
  const [name, setName] = useState(routingGroup?.name || '');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    if (routingGroup && !isOpen) {
      setName(routingGroup.name);
    }
  }, [isOpen, routingGroup]);

  const getRefetchQueries = () => {
    return [{ query: PROFILE_QUERY }];
  };

  const clear = () => {
    setName('');
    setErrors(null);
    closeDialog();
  };

  const [upsertRoutingGroup, { loading }] = useMutation(UPSERT_ROUTING_GROUP, {
    refetchQueries: getRefetchQueries,
    onError: (error) => {
      if (/ROUTING_GROUP_EXISTS/.test(error.message)) {
        return setErrors({
          name: ['Routing group name is already in use. Please provide a new name.'],
        });
      }

      return setErrors({
        server: (
          <>
            <Icon className="mr-5">warning</Icon>An error has occured while saving the routing group.
          </>
        ),
      });
    },
    onCompleted: (data) => {
      clear();
      onSaveComplete(data.upsertRoutingGroup);
    },
  });

  const [deactivateRouting, { loading: deleteLoading }] = useMutation(DEACTIVATE_ROUTING, {
    onCompleted: () => {
      setOpenDeleteDialog(false);
      onDeleteComplete();
    },
    onError: () => {
      setOpenDeleteDialog(false);
      return setErrors({
        server: (
          <>
            <Icon className="mr-5">warning</Icon>An error has occured while deleting the routing group.
          </>
        ),
      });
    },
    refetchQueries: [{ query: PROFILE_QUERY }],
    awaitRefetchQueries: true,
  });

  const isFormValid = async () => {
    setErrors(null);
    return ROUTING_GROUP_SCHEMA.validate({ name }, { abortEarly: false })
      .then(() => true)
      .catch((err) => {
        setErrors(mapYupValidationErrorToErrorMap(err));
        return false;
      });
  };

  return (
    <>
      <DialogTemplate
        open={isOpen}
        title={title}
        loading={loading}
        contentScrollable
        content={
          <div className="space-y-16">
            {description && <DialogContentText className="whitespace-pre-wrap">{description}</DialogContentText>}

            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="routing-group-name"
                  placeholder="Enter Routing Group Name"
                  label="Routing Group Name"
                  type="input"
                  variant="outlined"
                  autoFocus
                  autoComplete="off"
                  fullWidth
                  required
                  value={name || ''}
                  onChange={(event) => setName(event.target.value)}
                  inputProps={{ maxLength: 250, 'aria-label': `Enter Routing Group Name` }}
                  data-cy="e2e-routing-group-name-input"
                  error={!!errors?.name?.length}
                  helperText={inlineErrorMessages(errors?.name)}
                />
              </Grid>
              {errors && errors?.server && (
                <Grid item xs={12}>
                  <div className="flex items-center text-danger-800">{errors?.server}</div>
                </Grid>
              )}
            </Grid>
          </div>
        }
        primaryActions={
          <>
            <Button variant="contained" onClick={clear}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={async (event) => {
                event.preventDefault();
                if (await isFormValid()) {
                  upsertRoutingGroup({
                    variables: {
                      id: routingGroup?.id,
                      name,
                      domainId: routingList.domain.domainId,
                      campusCode,
                      routingListId: routingList?.id,
                    },
                  });
                }
              }}>
              {!isCreate ? 'Save' : 'Create'}
            </Button>
          </>
        }
        secondaryActions={
          !isCreate && (
            <IconWithTooltip
              icon="delete"
              title="Delete"
              placement="bottom"
              disabled={loading}
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            />
          )
        }
      />
      {openDeleteDialog && (
        <DialogTemplate
          open={openDeleteDialog}
          loading={deleteLoading}
          content={`Are you sure you want to delete ${routingGroup.name} from routing list?`}
          primaryActions={
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenDeleteDialog(false);
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={deleteLoading}
                onClick={() =>
                  deactivateRouting({
                    variables: {
                      domainId: routingList?.domain?.domainId,
                      campusCode,
                      id: routingGroup?.id,
                      type: ROUTING_GROUP,
                    },
                  })
                }>
                Delete
              </Button>
            </>
          }
        />
      )}
    </>
  );
};

ManageRoutingGroupDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSaveComplete: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  title: PropTypes.string.isRequired,
  campusCode: PropTypes.string.isRequired,
  routingList: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    domain: PropTypes.shape({
      domainId: PropTypes.string,
    }),
  }).isRequired,
  isCreate: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

ManageRoutingGroupDialog.defaultProps = {
  description: null,
  isCreate: false,
  data: {},
  onSaveComplete: () => {},
  onDeleteComplete: () => {},
};

export default ManageRoutingGroupDialog;
