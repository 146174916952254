import { Button, Typography } from '@material-ui/core';
import { trimEnd, truncate } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const MAX_LINES = 10;

function ShowMore({ text, endAdornment }) {
  const ref = useRef();
  const [enableToggle, setEnableToggle] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [limit, setLimit] = useState(null);

  useEffect(() => {
    const height = ref.current.offsetHeight;
    const lineHeight = trimEnd(window.getComputedStyle(ref.current).getPropertyValue('line-height'), 'px');
    const currentLines = height / lineHeight;

    if (currentLines > MAX_LINES && !limit) {
      setEnableToggle(true);
      setIsExpanded(false);
      const charsPerLine = text.length / currentLines;
      setLimit(Math.floor(charsPerLine * MAX_LINES));
    } else if (currentLines < MAX_LINES) {
      setLimit(null);
      setEnableToggle(false);
      setIsExpanded(true);
    }
  }, [text, limit]);

  return (
    <div>
      <Typography ref={ref}>
        {isExpanded ? text : truncate(text, { length: limit || 99999 })} {endAdornment}
        {enableToggle && (
          <Button
            size="small"
            color="primary"
            className="float-right ml-8 p-0"
            onClick={() => setIsExpanded(!isExpanded)}>
            {`Show ${isExpanded ? 'less' : 'more'}`}
          </Button>
        )}
      </Typography>
    </div>
  );
}

ShowMore.propTypes = {
  text: PropTypes.string.isRequired,
  endAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ShowMore.defaultProps = {
  endAdornment: null,
};

export default ShowMore;
