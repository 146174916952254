/* eslint-disable react/prop-types */
import {
  Checkbox,
  Icon,
  IconButton,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useRowSelect, useTable } from 'react-table';

const useStyles = makeStyles((theme) => ({
  highlight: {
    transitionProperty: 'background-color',
    transitionDuration: theme.transitions.duration.short,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
  },
}));

function TableWithHeader({
  icon,
  title,
  noDataMsg,
  columns,
  data,
  onEdit,
  onRemove,
  enableCheckbox,
  allowMultipleEdit,
}) {
  const classes = useStyles();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } = useTable(
    {
      columns: useMemo(() => columns, [columns]),
      data: useMemo(() => data, [data]),
    },
    useRowSelect,
    (hooks) => {
      if (enableCheckbox) {
        hooks.visibleColumns.push((col) => [
          {
            id: 'selection',
            Header: ({ row, getToggleAllRowsSelectedProps }) => (
              <Checkbox
                inputProps={{ 'aria-label': `selection` }}
                {...getToggleAllRowsSelectedProps({ title: 'Toggle All Rows' })}
              />
            ),
            Cell: ({ row }) => (
              <Checkbox
                inputProps={{ 'aria-label': `selection` }}
                {...row.getToggleRowSelectedProps({ title: 'Toggle Row' })}
              />
            ),
            className: 'w-48 p-0 pl-4',
          },
          ...col,
        ]);
      }
    },
  );

  return (
    <div className="flex flex-col">
      <div
        className={clsx(
          'flex h-60 items-center rounded p-14',
          classes.highlight,
          selectedFlatRows.length > 0 ? 'bg-primary-50' : '',
        )}>
        {selectedFlatRows.length > 0 ? (
          <Typography variant="subtitle1" component="div" className="mx-6 flex-1">
            {selectedFlatRows.length} selected
          </Typography>
        ) : (
          <div className="flex items-center">
            <Icon color="action">{icon}</Icon>
            <Typography className="mx-12 text-20 font-light" color="textSecondary">
              {title}
            </Typography>
          </div>
        )}

        {(selectedFlatRows.length === 1 || (allowMultipleEdit && selectedFlatRows.length > 0)) && (
          <Tooltip title="Edit">
            <IconButton
              aria-label="edit"
              onClick={() =>
                onEdit(
                  allowMultipleEdit
                    ? selectedFlatRows.map((d) => d.original)
                    : selectedFlatRows.map((d) => d.original)[0],
                )
              }>
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
        )}

        {selectedFlatRows.length > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={() => onRemove(selectedFlatRows.map((d) => d.original))}>
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>
        )}
      </div>

      <div>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                    <Typography className="font-semibold">{column.render('Header')}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  className={clsx(
                    classes.highlight,
                    enableCheckbox ? 'cursor-pointer' : '',
                    row.isSelected ? 'bg-primary-50' : '',
                  )}
                  {...row.getRowProps()}
                  onClick={() => {
                    if (enableCheckbox) {
                      row.toggleRowSelected();
                    }
                  }}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>

      {data.length === 0 && noDataMsg && (
        <div className="flex h-full min-h-128 w-full flex-auto items-center justify-center bg-gray-50  bg-opacity-20">
          <Typography color="textSecondary" variant="h6" className="font-light">
            {noDataMsg}
          </Typography>
        </div>
      )}
    </div>
  );
}

TableWithHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  noDataMsg: PropTypes.string,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  enableCheckbox: PropTypes.bool,
  allowMultipleEdit: PropTypes.bool,
};

TableWithHeader.defaultProps = {
  noDataMsg: '',
  onEdit: () => {},
  onRemove: () => {},
  enableCheckbox: false,
  allowMultipleEdit: false,
};

export default TableWithHeader;
