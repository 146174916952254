import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { pick, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTenants } from '../hooks';
import { GLOBAL_TENANT } from '../shared/helper';

const TenantSelect = ({ id, includeGlobal, tenantCode, onSelect, disabled, size }) => {
  const TENANTS = useTenants();
  const [selectedTenant, setSelectedTenant] = useState(tenantCode);

  let tenantList = TENANTS.getTenantList();

  if (includeGlobal) {
    tenantList = orderBy([...tenantList, pick(GLOBAL_TENANT, 'tenantCode', 'name')], 'tenantCode');
  }

  useEffect(() => {
    setSelectedTenant(tenantCode);
  }, [tenantCode]);

  const handleSelect = (event) => {
    const tenant = event.target.value;
    onSelect(tenant);
    setSelectedTenant(tenant);
  };
  const selectId = `${id}_tenant`;

  return (
    <FormControl variant="outlined" fullWidth size={size}>
      <InputLabel htmlFor={selectId}>Tenant</InputLabel>
      <Select
        data-cy="e2e-selectTenant"
        variant="outlined"
        label="Tenant"
        value={selectedTenant || ''}
        disabled={disabled}
        onChange={handleSelect}
        size={size}
        inputProps={{
          name: selectId,
          id: selectId,
        }}>
        {tenantList &&
          tenantList.map((tenant) => (
            <MenuItem data-cy={`e2e-${tenant}`} key={tenant.tenantCode} value={tenant.tenantCode}>
              {tenant.tenantCode}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

TenantSelect.defaultProps = {
  id: '',
  includeGlobal: false,
  disabled: false,
  size: 'medium',
};

TenantSelect.propTypes = {
  id: PropTypes.string,
  includeGlobal: PropTypes.bool,
  tenantCode: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default TenantSelect;
