/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import { useMutation } from '@apollo/client';
import { Order, ORDER_STATUS, STORE_PERMISSION } from '@risk-and-safety/library';
import FuseDialog from '@fuse/core/FuseDialog';
import {
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider,
  Box,
  Grid,
} from '@material-ui/core';
import { format } from 'date-fns';
import { orderBy, startCase } from 'lodash';
import numeral from 'numeral';
import qs from 'qs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { CANCEL_ORDER, COMPLETE_ORDER } from '../graphql/query';
import { ButtonProgress, StatusChip } from '../../components';
import { ORDER_STATUS_MAP, getOrderTax } from '../helper/order.helper';
import { closeDialog, openDialog } from '../../store/fuse/dialogSlice';

function OrderDetail({ store, order, refundData }) {
  const { params } = useRouteMatch('/:type/:storeId/order/:orderId');
  const dispatch = useDispatch();
  const [completeOrder, { loading: completeOrderLoading }] = useMutation(COMPLETE_ORDER);
  const [cancelOrderConfirmed, { loading: cancelOrderLoading }] = useMutation(CANCEL_ORDER);

  const storeActions = [];
  const cancelOrder = () =>
    dispatch(
      openDialog({
        children: (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to cancel this order?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => dispatch(closeDialog())}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(closeDialog());
                  cancelOrderConfirmed({ variables: { orderId: order._id } });
                }}
                color="secondary"
                autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </>
        ),
      }),
    );

  if (store.permissions.includes(STORE_PERMISSION.WRITE)) {
    switch (order.status) {
      case ORDER_STATUS.ORDER_SUBMITTED:
        storeActions.push({
          type: 'Button',
          loading: false,
          color: 'primary',
          onClick: () => window.print(),
          label: 'Print Packing Slip',
        });

        storeActions.push({
          type: 'Link',
          to: {
            pathname: `/store/${store._id}/order/${order._id}/fulfill`,
            search: qs.stringify({ orders: [order._id] }, { encodeValuesOnly: true }),
          },
          label: 'Fulfill Order',
        });

        storeActions.push({
          type: 'Button',
          loading: cancelOrderLoading,
          color: 'default',
          onClick: cancelOrder,
          label: 'Cancel Order',
        });
        break;
      case ORDER_STATUS.READY_FOR_PICK_UP:
      case ORDER_STATUS.READY_FOR_DELIVERY:
        storeActions.push({
          type: 'Button',
          loading: completeOrderLoading,
          color: 'primary',
          onClick: () =>
            completeOrder({
              variables: {
                storeId: store._id,
                orderId: order._id,
                status:
                  order.status === ORDER_STATUS.READY_FOR_PICK_UP ? ORDER_STATUS.PICKED_UP : ORDER_STATUS.DELIVERED,
              },
            }),
          label: order.status === ORDER_STATUS.READY_FOR_PICK_UP ? 'PICKED UP' : 'DELIVERED',
        });
        break;
      default:
        if (refundData.findValidRefundItems.length)
          storeActions.push({
            type: 'Link',
            to: {
              pathname: `/store/${store._id}/order/${order._id}/refund`,
              fromOrderDetail: true,
            },
            label: 'Refund Items',
          });
        break;
    }
  }

  const refundTotal =
    order?.refunds?.reduce((accumulator, currentValue) => accumulator + currentValue.refundTotal, 0) ?? 0;

  return (
    <div className="mt-64 flex flex-grow px-28 print:mx-3">
      <div className="flex-grow">
        <div className="mb-10 flex">
          <Typography color="textSecondary">
            <span>Order Date:</span> {format(new Date(order.createdDate), 'P')}
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography color="textSecondary">ORDER # {order._id}</Typography>
        </div>
        <div className="sm:flex sm:items-start sm:justify-between">
          <div className="mb-20 flex flex-col sm:mr-20">
            <Typography className="font-light" variant="h5" color="textSecondary">
              {order.account?.administeredBy?.name} - {startCase(order.receivingType)}
            </Typography>

            <Typography color="textSecondary">
              Order created by: {order.createdBy?.firstName} {order.createdBy?.lastName}{' '}
              {order.createdBy?.email ? `(${order.createdBy?.email})` : ''}
            </Typography>
            {order.shopper?.userId !== order.createdBy.userId && (
              <Typography color="textSecondary">
                Order requested by: {order.shopper?.firstName} {order.shopper?.lastName}{' '}
                {order.shopper?.email ? `(${order.shopper?.email})` : ''}
              </Typography>
            )}
            {order.deliveryLocation && (
              <Typography color="textSecondary">
                {order.deliveryLocation.buildingName} {order.deliveryLocation.roomNumber} -{' '}
                {order.deliveryLocation.sublocationName}
              </Typography>
            )}
            {order.fund?.name && (
              <Typography color="textSecondary">
                <span>Fund:</span> {order.fund?.name}
              </Typography>
            )}
            <Typography color="textSecondary">
              <span>Order Date:</span> {format(new Date(order.createdDate), 'P')}
            </Typography>
          </div>

          <div className="mb-20 min-w-96 sm:ml-20 print:w-1/4">
            <Grid container space={2}>
              <Grid xs={12} item>
                <Typography className="font-bold">Order Summary</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography color="textSecondary">Subtotal:</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography align="right" color="textSecondary">
                  {numeral(order.items.reduce((acc, curr) => acc + curr.quantity * curr.product.price, 0)).format(
                    '$0,0.00',
                  )}
                </Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography color="textSecondary">Fee:</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography align="right" color="textSecondary">
                  {numeral(order.items.reduce((acc, curr) => acc + curr.quantity * curr.product.fee, 0)).format(
                    '$0,0.00',
                  )}
                </Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography color="textSecondary">Tax:</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography align="right" color="textSecondary">
                  {numeral(getOrderTax(order)).format('$0,0.00')}
                </Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography className="font-bold">Grand Total:</Typography>
              </Grid>
              <Grid xs={6} item>
                <Typography className="font-bold" align="right">
                  {numeral(order.totalCost).format('$0,0.00')}
                </Typography>
              </Grid>
              {order?.refunds?.length > 0 && (
                <>
                  <Grid xs={6} item>
                    <Typography className="font-bold text-green-800">Refund Total:</Typography>
                  </Grid>
                  <Grid xs={6} item>
                    <Typography className="font-bold text-green-800" align="right">
                      {numeral(refundTotal).format('$0,0.00')}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>

        <div className="mb-20 print:hidden">
          <Table className="simple">
            <TableHead>
              <TableRow>
                <TableCell align="left">Status</TableCell>
                <TableCell align="right" className="w-96">
                  Updated On
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(order.history, ['date'], ['desc']).map((item) => (
                <TableRow key={item.status}>
                  <TableCell>
                    <StatusChip label={item.status} color={ORDER_STATUS_MAP[item.status]} />
                  </TableCell>
                  <TableCell align="right">{format(new Date(item.date), 'P')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <Typography variant="h6">Purchases</Typography>

        <Table className="simple">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell className="hidden print:block">Location</TableCell>
              <TableCell align="center" className="w-160">
                Quantity
              </TableCell>
              <TableCell align="right" className="w-160 print:hidden">
                Price
              </TableCell>
              <TableCell align="right" className="w-160 print:hidden">
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order.items.map((item) => (
              <TableRow key={item._id}>
                <TableCell style={{ wordWrap: 'break-word' }}>
                  <Typography>{item.product.name}</Typography>
                  <Typography className="text-pretty text-balance text-wrap max-w-256" color="textSecondary">
                    {item.product.description}
                  </Typography>
                </TableCell>
                <TableCell align="right" className="hidden print:block">
                  {item.productLocations?.map((location) => `${location?.roomNumber}-${location?.sublocationName}`)}
                </TableCell>
                <TableCell align="center">{item.quantity}</TableCell>
                <TableCell align="right" className="w-160 print:hidden">
                  {numeral(item.product.price).format('$0,0.00')}
                </TableCell>
                <TableCell align="right" className="w-160 print:hidden">
                  {numeral(item.quantity * item.product.price).format('$0,0.00')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {order?.refunds?.length > 0 && (
          <Typography variant="h6" className="mt-10">
            Refunds
          </Typography>
        )}
        {order?.refunds?.map((refund, index) => (
          <div key={index} className="pt-10">
            <Typography color="textSecondary">
              Refunded Created By: {refund.createdBy.firstName} {refund.createdBy.lastName} On{' '}
              {format(new Date(refund.createdDate), 'P')}
            </Typography>

            <Table className="simple">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell className="w-256">Reasons</TableCell>
                  <TableCell align="center" className="w-160">
                    Quantity
                  </TableCell>
                  <TableCell align="right" className="w-160">
                    Price
                  </TableCell>
                  <TableCell align="right" className="w-160">
                    Total
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {refund.refundedItems.map((item) => (
                  <TableRow key={item.productId}>
                    <TableCell>
                      <Typography variant="body1" className="mb-0">
                        {item.productName}
                      </Typography>
                      <Typography color="textSecondary">{item.description}</Typography>
                    </TableCell>
                    <TableCell style={{ wordWrap: 'break-word' }}>
                      <p className="text-pretty text-balance text-wrap max-w-256">{item?.reasons?.join(', ') ?? ''}</p>
                    </TableCell>
                    <TableCell align="center" className="w-160">
                      {item.quantity}
                    </TableCell>
                    <TableCell align="right" className="w-160">
                      {numeral(item.price).format('$0,0.00')}
                    </TableCell>
                    <TableCell align="right" className="w-160">
                      {numeral(item.quantity * item.price).format('$0,0.00')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ))}
      </div>

      {params.type === 'store' && storeActions.length > 0 && (
        <div className="ml-32 flex flex-col items-end justify-start space-y-20 border-l-1 border-dashed pl-32 print:hidden">
          {storeActions.map((action, index) => {
            if (action.type === 'Link') {
              return (
                <Link key={index} to={action.to} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" size="large" className="w-200">
                    {action.label}
                  </Button>
                </Link>
              );
            }
            if (action.type === 'Button') {
              return (
                <ButtonProgress
                  key={index}
                  loading={action.loading}
                  variant="contained"
                  color={action.color}
                  size="large"
                  onClick={action.onClick}
                  className="w-200">
                  {action.label}
                </ButtonProgress>
              );
            }
            return '';
          })}
        </div>
      )}
      {params.type === 'shop' && order.status === ORDER_STATUS.ORDER_SUBMITTED && (
        <div className="ml-32 flex flex-col items-end justify-start space-y-20 border-l-1 border-dashed pl-32 print:hidden">
          <ButtonProgress
            loading={cancelOrderLoading}
            variant="contained"
            color="default"
            size="large"
            onClick={cancelOrder}
            className="w-200">
            Cancel Order
          </ButtonProgress>
        </div>
      )}
      <FuseDialog />
    </div>
  );
}

OrderDetail.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  order: PropTypes.shape(Order).isRequired,
};

OrderDetail.defaultProps = {};

export default OrderDetail;
