const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const {
  ObjectId
} = require('bson');
const yup = require('yup');
const CATALOG_TYPE = require('../catalog-type.constant');
const FUND_TYPE = require('../constants/fund-type.constant');
const FundReferenceSchema = require('./fund-reference.schema');
class Store extends RssModel {
  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema().default(() => new ObjectId()).required(),
      programId: yup.string().required(),
      inventoryId: new ObjectIdSchema().required(),
      campusCode: yup.string().required(),
      name: yup.string().trim().min(1, 'Name is Required').required(),
      description: yup.string().trim().default(null).nullable().defined(),
      hours: yup.object({
        // saving as dates for easier validation - only displaying time to user
        from: yup.date().default(null).nullable().defined(),
        to: yup.date().default(null).nullable().defined()
      }).required(),
      catalogs: yup.array().of(yup.object({
        value: yup.string().oneOf(Object.values(CATALOG_TYPE)).required(),
        // purposeCode as object in case we add purposeCode.validator or a custom label
        purposeCode: yup.object({
          value: yup.string().default(null).nullable().defined()
        }),
        markupRate: yup.number().min(0).max(1).default(0).defined()
      })).ensure(),
      salesTax: yup.number().default(0.0).defined(),
      address: yup.string().default(null).nullable().defined(),
      phone: yup.string().default(null).nullable().defined(),
      email: yup.string().default(null).nullable().defined(),
      settings: yup.object({
        delivery: yup.boolean().default(false).required(),
        pickup: yup.boolean().default(false).required(),
        selfCheckout: yup.boolean().default(false).required(),
        enableStoreFront: yup.boolean().default(false).required(),
        fund: yup.object({
          validator: yup.string().required(),
          types: yup.object().when('validator', validator => yup.array().of(yup.string().oneOf(Object.keys(FUND_TYPE).filter(t => t.includes(validator.toUpperCase())))).min(1))
        }),
        account: yup.object({
          administeredBy: yup.array().of(yup.string()).min(1)
        }),
        journal: yup.object({
          fileType: yup.string().required(),
          transformer: yup.string().required()
        }).nullable().defined()
      }),
      receivingFund: FundReferenceSchema.Fund.nullable().defined(),
      subsidyFund: FundReferenceSchema.SubsidyFund.nullable().defined(),
      createdBy: yup.object({
        userId: yup.string().required()
      }),
      createdDate: yup.date().default(new Date()).required(),
      lastModifiedBy: yup.object({
        userId: yup.string().required()
      }),
      lastModifiedDate: yup.date().default(new Date()).required()
    });
  }
}
module.exports = Store;