import { Chip, Icon, Typography } from '@material-ui/core';
import { useDrive } from '@risk-and-safety/drive-client';
import { RESOLUTION_STATUS } from '@risk-and-safety/inspect-v2-common';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Thumbnail, RichTextEditor } from '../../../components';
import config from '../../../config';
import { buildEventText } from '../../helper/incident.helper';
import { RelativeTime } from '../RelativeTime';

export function ResolutionEvent({ event }) {
  const { get } = useDrive('inspect-v2', {
    graphUrl: `${config.GRAPH_URL}/graphql`,
    operations: { get: 'getInspectResolutionAttachment' },
  });

  // HACK: this is to account for reports that were migrated in to the sytesm
  if (event.status === 'MIGRATED') {
    return (
      <div className="mb-16 border-b-1 border-grey-50">
        <span className="text-grey-200">
          Resolution MIGRATED to system <RelativeTime dateString={event.createdDate} />
        </span>
      </div>
    );
  }

  return (
    <div className="mb-16 border-b-1 border-grey-50">
      <div>
        <span className="mr-2 font-bold">{`${event.createdBy.firstName} ${event.createdBy.lastName}`}</span>{' '}
        <span className="mr-4">{buildEventText(event)}</span> <RelativeTime dateString={event.createdDate} />
      </div>
      <div>
        {event.status !== RESOLUTION_STATUS.COMMENT && (
          <div className="mb-8 mt-6">
            <Chip
              className="my-8"
              size="small"
              color="primary"
              label={startCase(event.prevStatus || RESOLUTION_STATUS.NOT_RESOLVED)}
            />
            <Icon className="mx-4 text-base">arrow_forward</Icon>
            <Chip className="my-8" size="small" color="primary" label={startCase(event.status)} />
          </div>
        )}
        {Boolean(event.comment) && <RichTextEditor value={event.comment} />}
        {Boolean(event.attachments.length) && (
          <div className="-ml-8 mb-8 flex w-full flex-wrap gap-8">
            {event.attachments.map((attachment) => (
              <Thumbnail attachment={attachment} key={attachment.key} remoteSrc={(name) => get(name)} />
            ))}
          </div>
        )}
        {Boolean(event.email) && <RichTextEditor value={event.email} />}
      </div>
    </div>
  );
}

ResolutionEvent.propTypes = {
  event: PropTypes.objectOf(PropTypes.any).isRequired,
};
