"use strict";

module.exports = [{
  code: 'GHS01',
  title: 'Explosive'
}, {
  code: 'GHS02',
  title: 'Flammable'
}, {
  code: 'GHS03',
  title: 'Oxidizing'
}, {
  code: 'GHS05',
  title: 'Corrosive'
}, {
  code: 'GHS04',
  title: 'Compressed Gas'
}, {
  code: 'GHS06',
  title: 'Toxic'
}, {
  code: 'GHS07',
  title: 'Harmful'
}, {
  code: 'GHS08',
  title: 'Health Hazard'
}, {
  code: 'GHS09',
  title: 'Environmentally Damaging'
}];