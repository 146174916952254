import { Icon, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const IconWidget = ({ icon, link, textColor, title }) => {
  return (
    <Paper className="w-full rounded-8 shadow-1">
      <Link to={link} style={{ textDecoration: 'none' }}>
        <div className="pt-32 pb-28 text-center">
          <Icon className="text-72" color={textColor === 'textSecondary' ? 'disabled' : textColor}>
            {icon}
          </Icon>
          <Typography className="pt-20 text-16" color={textColor}>
            {title}
          </Typography>
        </div>
      </Link>
    </Paper>
  );
};

IconWidget.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  title: PropTypes.string.isRequired,
};

IconWidget.defaultProps = {
  textColor: 'primary',
};

export default IconWidget;
