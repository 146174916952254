"use strict";

module.exports = {
  CAMPUS: 'CAMPUS',
  COLLEGE: 'COLLEGE',
  DEPARTMENT: 'DEPARTMENT',
  DIVISION: 'DIVISION',
  PROGRAM: 'PROGRAM',
  TENANT: 'TENANT',
  UNIT: 'UNIT'
};