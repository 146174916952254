import { InspectionHelper } from '@risk-and-safety/inspect-v2-common';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

export const ALL_CATEGORIES = { key: 'CATEGORY_FILTER_ALL' };

export function useInspectionSearch() {
  const [searchResults, setSearchResults] = useState(null);
  const [searchParams, setSearchParams] = useState({ searchCategory: '', searchTerm: '' });

  const search = useMemo(
    () =>
      debounce((categories, term) => {
        if (term) {
          setSearchResults(InspectionHelper.fuzzySearch(categories, term));
        }

        if (categories.length === 1) {
          setSearchParams({ searchCategory: categories[0].key, searchTerm: term });
        } else {
          setSearchParams({ searchCategory: ALL_CATEGORIES.key, searchTerm: term });
        }
      }, 200),
    [],
  );

  function clearSearch() {
    setSearchResults(null);
  }

  return { searchParams, searchResults, search, clearSearch };
}
