/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import { Checkbox, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTable, useRowSelect } from 'react-table';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} color="primary" />
    </>
  );
});

function ReactTable({ columns, data, onSelectRows, onClickRow, passOriginalDataOnClick = false }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } = onSelectRows
    ? useTable(
        {
          columns: useMemo(() => columns, [columns]),
          data: useMemo(() => data, [data]),
        },
        useRowSelect,
        (hooks) => {
          hooks.visibleColumns.push((c) => [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...c,
          ]);
        },
      )
    : useTable({ columns, data });

  useEffect(() => {
    if (onSelectRows) {
      onSelectRows(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  return (
    <Table stickyHeader {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell {...column.getHeaderProps([{ className: column.className }])}>
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <TableRow
              className={`h-48 ${onClickRow ? 'cursor-pointer' : ''}`}
              hover={!!onClickRow}
              {...row.getRowProps()}
              onClick={() => (onClickRow ? onClickRow(passOriginalDataOnClick ? row.original : row.values) : {})}>
              {row.cells.map((cell) => {
                return (
                  <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>
                    {cell.render('Cell')}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default ReactTable;
