import { ADMIN_ACTION } from '@risk-and-safety/assessment-v2-common';
import { lazy } from 'react';

const basePaths = [
  '/biosafety',
  '/lab-hazard',
  '/medical/respirator',
  '/medical/unit-risk',
  '/medical/program-flex',
  '/pesticide-use',
];

const baseRoutes = basePaths.flatMap((path) => {
  return [
    {
      path: `${path}/admin`,
      component: lazy(() => import('./containers/AssessmentAdminPage')),
    },
    {
      path: `${path}/admin/view`,
      component: lazy(() => import('./containers/AssessmentAdminPage')),
      checkPermission: { actionType: ADMIN_ACTION.READ_ONLY },
    },
    {
      path: `${path}/agenda/:id`,
      exact: true,
      component: lazy(() => import('../procedure/containers/DocumentPage')),
      checkPermission: { actionType: ADMIN_ACTION.AGENDA },
    },
    {
      path: `${path}/agenda`,
      component: lazy(() => import('./containers/AgendaPage')),
      checkPermission: { actionType: ADMIN_ACTION.AGENDA },
    },
    {
      path: `${path}/search`,
      component: lazy(() => import('./containers/AssessmentListPage')),
      checkPermission: { actionType: [ADMIN_ACTION.MANAGE, ADMIN_ACTION.READ_ONLY] },
    },
    {
      path: `${path}/template/:templateId`,
      component: lazy(() => import('./containers/AssessmentCreatePage')),
    },
    {
      path: `${path}/series/:seriesId/version2`,
      component: lazy(() => import('./containers/AssessmentVersionPage')),
    },
    {
      path: `${path}/series/:seriesId/version/:id/review`,
      component: lazy(() => import('./containers/AssessmentVersionPage')),
    },
    {
      path: `${path}/series/:seriesId/version/:id`,
      component: lazy(() => import('./containers/AssessmentPage')),
    },
    {
      path: `${path}/series/:seriesId`,
      component: lazy(() => import('./containers/AssessmentPage')),
    },
    {
      path: `${path}/select`,
      component: lazy(() => import('./containers/AssessmentSelectPage')),
    },
    // This is specifically for migrating FLEX, PNW and WSW documents (one time)
    {
      path: `${path}/bulk-download`,
      component: lazy(() => import('./containers/AssessmentBulkDownloadPage')),
    },
  ];
});

const routes = {
  auth: ({ profile } = {}) => Boolean(profile),
  routes: basePaths.flatMap((path) => ({
    path,
    component: lazy(() => import('./containers/AssessmentTypeProviderPage')),
    routes: baseRoutes,
  })),
};

export default routes;
