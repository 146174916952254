/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Icon, Typography, TextField } from '@material-ui/core';
import { formatFundValueToString } from '../helper/account.helper';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const FundValueEdit = ({ errors, value: fieldValue, type, field }) => {
  const { onChange } = field;
  let value;
  if (type?.includes('PS_BUDGET_CODE')) {
    value = {
      accountCode: fieldValue?.accountCode || '',
      budgetCode: fieldValue?.budgetCode || '',
      fundCode: fieldValue?.fundCode || '',
    };
    return (
      <div className="w-full border-1 border-dashed p-16">
        <div className="flex w-full space-x-16">
          <TextField
            className="w-1/3"
            {...field}
            value={value.budgetCode}
            error={!!errors?.value?.budgetCode}
            helperText={errors?.value?.budgetCode?.message}
            label="Budget code"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, budgetCode: inputValue });
            }}
            required
          />
          <TextField
            className="w-1/3"
            {...field}
            value={value.accountCode}
            error={!!errors?.value?.accountCode}
            helperText={errors?.value?.accountCode?.message}
            label="Account code"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, accountCode: inputValue });
            }}
            required
          />
          <TextField
            className="w-1/3"
            {...field}
            value={value.fundCode}
            error={!!errors?.value?.fundCode}
            helperText={errors?.value?.fundCode?.message}
            label="Fund code"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, fundCode: inputValue });
            }}
            required
          />
        </div>
      </div>
    );
  }
  if (type?.includes('KFS')) {
    value = {
      kfsChartCode: fieldValue?.kfsChartCode || '',
      kfsAcctNumber: fieldValue?.kfsAcctNumber || '',
      effectiveDate: fieldValue.effectiveDate || new Date(),
    };
    return (
      <div className="w-full border-1 border-dashed p-16">
        <div className="flex w-full items-center justify-center space-x-16">
          <TextField
            className="w-1/2"
            {...field}
            value={value.kfsChartCode}
            error={!!errors?.value?.kfsChartCode}
            helperText={errors?.value?.kfsChartCode?.message}
            label="KFS Chart Code"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, kfsChartCode: inputValue });
            }}
            inputProps={{
              maxLength: 2,
            }}
            required
          />
          <TextField
            className="w-1/2"
            {...field}
            value={value.kfsAcctNumber}
            error={!!errors?.value?.kfsAcctNumber}
            helperText={errors?.value?.kfsAcctNumber?.message}
            label="KFS Account Number"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, kfsAcctNumber: inputValue })}
            inputProps={{
              maxLength: 7,
            }}
            required
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="w-1/2"
              sx={{
                '& .MuiInputLabel-asterisk': {
                  color: '#c62828',
                },
                '& .MuiInputLabel-root': {
                  fontSize: '1.4rem',
                  color: 'black',
                  lineHeight: 1,
                  fontWeight: 400,
                },
                '& .MuiInput-root': {
                  fontSize: '14px',
                },
              }}
              {...field}
              value={dayjs(value.effectiveDate)}
              onChange={(newDate) => onChange({ ...value, effectiveDate: dayjs(newDate) })}
              slotProps={{
                textField: {
                  label: 'Effective date',
                  variant: 'standard',
                  helperText: errors?.value?.effectiveDate?.message,
                  required: true,
                },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    );
  }
  if (type?.includes('CHARTSTRING')) {
    value = {
      businessUnit: 'SFCMP',
      account: fieldValue?.account || '',
      fund: fieldValue?.fund || '',
      departmentId: fieldValue?.departmentId || '',
      project: fieldValue?.project || '',
      activityPeriod: fieldValue?.activityPeriod || '',
      function: fieldValue?.function || '',
      flexField: fieldValue?.flexField || '',
    };
    return (
      <div className="w-full border-1 border-dashed p-16">
        <div className="mb-16 flex w-full justify-between">
          <TextField
            className="w-1/5"
            {...field}
            value={value.businessUnit}
            error={!!errors?.value?.businessUnit}
            helperText={errors?.value?.businessUnit?.message}
            label="Business Unit"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, businessUnit: inputValue });
            }}
            inputProps={{
              maxLength: 5,
            }}
            disabled
            required
          />
          <TextField
            className="w-1/5"
            {...field}
            value={value.account}
            error={!!errors?.value?.account}
            helperText={errors?.value?.account?.message}
            label="Account"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, account: inputValue })}
            inputProps={{
              maxLength: 5,
            }}
            required
          />
          <TextField
            className="w-1/5"
            {...field}
            value={value.fund}
            error={!!errors?.value?.fund}
            helperText={errors?.value?.fund?.message}
            label="Fund"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, fund: inputValue })}
            inputProps={{
              maxLength: 4,
            }}
            required
          />
          <TextField
            className="w-1/5"
            {...field}
            value={value.departmentId}
            error={!!errors?.value?.departmentId}
            helperText={errors?.value?.departmentId?.message}
            label="Dept Id"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, departmentId: inputValue })}
            inputProps={{
              maxLength: 6,
            }}
            required
          />
        </div>
        <div className="mb-16 flex w-full justify-between">
          <TextField
            className="w-1/5"
            {...field}
            value={value.project}
            error={!!errors?.value?.project}
            helperText={errors?.value?.project?.message}
            label="Project"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, project: inputValue })}
            inputProps={{
              maxLength: 7,
            }}
            required
          />
          <TextField
            className="w-1/5"
            {...field}
            value={value.activityPeriod}
            error={!!errors?.value?.activityPeriod}
            helperText={errors?.value?.activityPeriod?.message}
            label="Activity Period"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, activityPeriod: inputValue })}
            inputProps={{
              maxLength: 2,
            }}
          />
          <TextField
            className="w-1/5"
            {...field}
            value={value.function}
            error={!!errors?.value?.function}
            helperText={errors?.value?.function?.message}
            label="Function"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, function: inputValue })}
            inputProps={{
              maxLength: 2,
            }}
            required
          />
          <TextField
            className="w-1/5"
            {...field}
            value={value.flexField}
            error={!!errors?.value?.flexField}
            helperText={errors?.value?.flexField?.message}
            label="Flex Field"
            onChange={({ target: { value: inputValue } }) => onChange({ ...value, flexField: inputValue })}
            inputProps={{
              maxLength: 7,
            }}
          />
        </div>
        <div className="mt-32 flex w-full items-center">
          <FormControl>
            <Typography variant="subtitle1">Chart string: {formatFundValueToString(value)}</Typography>
          </FormControl>
          {errors?.value?.type === 'validate-fund' && (
            <Typography className="flex items-center text-error-500">
              <Icon className="ml-32 mr-8">cancel_circle</Icon> {errors?.value?.message || 'Not a valid chart string'}
            </Typography>
          )}
        </div>
      </div>
    );
  }
  if (type?.includes('SPEED_TYPE')) {
    value = { businessUnit: 'SFCMP', speedType: fieldValue.speedType || '' };
    return (
      <div className="w-full border-1 border-dashed p-16">
        <div className="flex w-full space-x-16">
          <TextField
            className="w-1/2"
            {...field}
            value={value.businessUnit}
            error={!!errors?.value?.businessUnit}
            helperText={errors?.value?.businessUnit?.message}
            label="Business Unit"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, businessUnit: inputValue });
            }}
            required
            disabled
          />
          <TextField
            className="w-1/2"
            {...field}
            value={value.speedType}
            error={!!errors?.value?.speedType}
            helperText={errors?.value?.speedType?.message}
            label="Speed Type"
            onChange={({ target: { value: inputValue } }) => {
              onChange({ ...value, speedType: inputValue });
            }}
            required
          />
        </div>
      </div>
    );
  }
  return <span />;
};

FundValueEdit.propTypes = {
  errors: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  field: PropTypes.objectOf(PropTypes.any),
};

FundValueEdit.defaultProps = {
  errors: {},
  value: {},
  type: '',
  field: {},
};

export default FundValueEdit;
