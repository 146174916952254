const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const {
  PHYSICAL_STATE
} = require('@risk-and-safety/chemical');
const {
  ObjectId
} = require('bson');
const {
  get
} = require('lodash');
const yup = require('yup');
const CATALOG_TYPE = require('../catalog-type.constant');
const LibraryItemReference = require('./library-item.reference');
const InventoryBioDetails = require('./details/bio-details');
const InventoryPPEDetails = require('./details/inventory.ppe-details');
class Product extends RssModel {
  constructor(opt = {}) {
    super(opt);
    const inventoryItem = get(opt, 'details.inventoryItem');
    const libraryItem = get(opt, 'details.libraryItem');
    switch (this.catalog) {
      case CATALOG_TYPE.PPE:
        this.details = {
          inventoryItem: new InventoryPPEDetails(inventoryItem),
          libraryItem: new LibraryItemReference(libraryItem)
        };
        break;
      case CATALOG_TYPE.BIOLOGICAL:
        this.details = {
          inventoryItem: new InventoryBioDetails(inventoryItem),
          libraryItem: new LibraryItemReference(libraryItem)
        };
        break;
      case CATALOG_TYPE.CHEMICAL:
      default:
        this.details = opt.details;
    }
    if (!opt._id) {
      this._id = new ObjectId();
    }
    if (!opt.sku) {
      this.sku = this.generateSku();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema(),
      storeId: new ObjectIdSchema().required(),
      inventoryId: new ObjectIdSchema().required(),
      name: yup.string().trim().required(),
      description: yup.string().trim().default(null).nullable().defined(),
      sku: yup.string().required(),
      price: yup.number().min(0).default(0),
      fee: yup.number().min(0).default(0),
      subsidy: yup.number().min(0).default(0),
      lowStockThreshold: yup.number().default(null).nullable().defined(),
      productNumber: yup.string().trim().required(),
      availableForSale: yup.bool().default(true),
      taxExempt: yup.bool().default(false),
      barcodeRequired: yup.bool().default(true),
      library: yup.object({
        _id: new ObjectIdSchema(),
        catalog: yup.string().required()
      }).required(),
      images: yup.array().of(yup.string()).default([]),
      catalog: yup.string().required(),
      details: yup.object().when('library.catalog', catalog => {
        switch (catalog) {
          case CATALOG_TYPE.CHEMICAL:
            return yup.object({
              size: yup.number().positive().required(),
              units: yup.string().required(),
              type: yup.string().required(),
              density: yup.object({
                value: yup.number().nullable(),
                units: yup.string().nullable()
              }).defined(),
              physicalState: yup.string().oneOf([...PHYSICAL_STATE.map(state => state.value), null]).default(null).nullable()
            });
          case CATALOG_TYPE.PPE:
            return yup.object({
              libraryItem: new LibraryItemReference().schema().required(),
              inventoryItem: new InventoryPPEDetails().schema().required()
            });
          case CATALOG_TYPE.BIOLOGICAL:
            return yup.object({
              libraryItem: new LibraryItemReference().schema().required(),
              inventoryItem: new InventoryBioDetails().schema().required()
            });
          default:
            return yup.object().nullable();
        }
      }),
      createdBy: yup.object({
        userId: yup.string().required()
      }),
      createdDate: yup.date().default(new Date()).required(),
      lastModifiedBy: yup.object({
        userId: yup.string().required()
      }),
      lastModifiedDate: yup.date().default(new Date()).required()
    });
  }
  generateSku() {
    const catalogREf = this.catalog.substring(0, 3);
    // get last 3-byte incrementing counter from ObjectId
    const counter = this._id.toString().slice(-6);
    return `${catalogREf}${counter}`;
  }
}
module.exports = Product;