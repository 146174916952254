import { Button, Paper, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonProgress } from '../components';
import RichTextEditor from './RichTextEditor';
import ProfileAvatar from './ProfileAvatar';

function CommentInput({
  actions,
  buttonText,
  buttonClass,
  user,
  value,
  supplementalContent,
  richText,
  InputProps,
  loading,
  onCancel,
  onChange,
  onSave,
  errors,
}) {
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSave();
      }}
    >
      <div className="flex w-full">
        <div className="mr-8 hidden sm:flex">
          <ProfileAvatar user={user} />
        </div>

        <div className="mx-4 mr-16 flex flex-1 flex-col">
          <Paper elevation={0} className="mb-16 w-full rounded-12">
            {richText ? (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <RichTextEditor value={value} onChange={onChange} {...InputProps} />
            ) : (
              <TextField
                id="multiline-flexible"
                multiline
                fullWidth
                value={value}
                onChange={onChange}
                variant="outlined"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...InputProps}
                inputProps={{ rowsMin: 4 }}
              />
            )}
            {Boolean(errors?.length) &&
              errors.map((message) => (
                <div key={message} className="pt-1 text-red-500">
                  {message}
                </div>
              ))}
          </Paper>
          {supplementalContent && <div className="py-8">{supplementalContent}</div>}

          <div className={buttonClass || 'space-between mb-16 mt-4 flex flex-row-reverse items-center'}>
            <ButtonProgress type="submit" variant="contained" color="primary" size="small" loading={loading}>
              {buttonText}
            </ButtonProgress>
            {onCancel && (
              <Button onClick={onCancel} variant="contained" size="small" className="mr-8">
                Cancel
              </Button>
            )}
            {actions && <div className="flex w-full">{actions}</div>}
          </div>
        </div>
      </div>
    </form>
  );
}

CommentInput.propTypes = {
  actions: PropTypes.node,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastname: PropTypes.string,
  }),
  value: PropTypes.string,
  supplementalContent: PropTypes.node,
  InputProps: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  richText: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
};

CommentInput.defaultProps = {
  actions: null,
  buttonText: 'Save',
  buttonClass: null,
  errors: [],
  InputProps: {},
  loading: false,
  onCancel: null,
  onChange: undefined,
  richText: false,
  supplementalContent: null,
  user: {},
  value: '',
};

export default CommentInput;
