"use strict";

module.exports = {
  CREATE_NODE: 'CREATE_NODE',
  UPDATE_NODE: 'UPDATE_NODE',
  REMOVE_NODE: 'REMOVE_NODE',
  REINDEX_NODE: 'REINDEX_NODE',
  CREATE_EDGE: 'CREATE_EDGE',
  UPDATE_EDGE: 'UPDATE_EDGE',
  DEACTIVATE_EDGE: 'DEACTIVATE_EDGE',
  DROP_EDGE: 'DROP_EDGE',
  DEACTIVATE_EDGES: 'DEACTIVATE_EDGES',
  DEACTIVATE_NODE_EDGES: 'DEACTIVATE_NODE_EDGES',
  TRANSFER_EDGE: 'TRANSFER_EDGE',
  UPDATE: 'UPDATE'
};