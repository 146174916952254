"use strict";

var _lodash = require("lodash");
var EDGE_ROLE = require('../constant/relationship/edge-role.constant');
var _require = require('../constant/relationship/label.constant'),
  EDGE = _require.EDGE,
  EDGE_TYPE = _require.EDGE_TYPE;
module.exports.isFeatureSettingsEnabled = function (user) {
  return (0, _lodash.get)(user, 'features.analytics.rollOut.value');
};

//  # TODO: modify after mongo => neptune migration
module.exports.isAnalyticsAdmin = function (_ref, isNeptune) {
  var relationships = _ref.relationships;
  return relationships && isNeptune && relationships.some(function (_ref2) {
    var _ref2$edge = _ref2.edge,
      label = _ref2$edge.label,
      type = _ref2$edge.type;
    return type === EDGE_TYPE.ROLE && label === EDGE.ANALYTICS_ADMIN;
  }) || relationships && relationships.some(function (_ref3) {
    var role = _ref3.role;
    return role === EDGE_ROLE.ANALYTICS_ADMIN;
  });
};