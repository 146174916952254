import { FormControl, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const ALL_CATEGORY = 'ALL';

const CategoryDropdown = ({ allCategories, selectedCategory, onCategoryChange }) => (
  <FormControl className="w-full sm:w-320">
    <Select
      value={selectedCategory}
      onChange={({ target }) => onCategoryChange([target.value])}
      input={
        <OutlinedInput
          id="category"
          color="secondary"
          classes={{ root: 'rounded-2xl sm:rounded-r-none h-40 sm:h-48' }}
        />
      }>
      <MenuItem value={ALL_CATEGORY}>{ALL_CATEGORY}</MenuItem>
      {(allCategories || []).map(({ sys_id: articleId, full_category: categoryName }) => (
        <MenuItem key={articleId} value={categoryName}>
          {categoryName}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

CategoryDropdown.propTypes = {
  allCategories: PropTypes.arrayOf(PropTypes.object),
  selectedCategory: PropTypes.string,
  onCategoryChange: PropTypes.func.isRequired,
};

CategoryDropdown.defaultProps = {
  allCategories: null,
  selectedCategory: ALL_CATEGORY,
};

export default CategoryDropdown;
