const { FqType, RELATIONSHIP_LABEL, Helper } = require('@rss/common');
const { parse } = require('advanced-date-parser');
const { endOfDay, endOfMonth, isAfter, isBefore, startOfDay } = require('date-fns');
const { cloneDeep } = require('lodash');

const { ADD_ANOTHER, ATTACHMENT, DATETIMEPICKER, DATEPICKER, DEPENDENT, GROUP, INPUT } = FqType;

const yearRegExp = new RegExp('^\\d{4}$');

const {
  NODE: {
    BUA,
    BUA_CONFIG,
    FLEX_CONFIG,
    LAB_HAZARD,
    LAB_HAZARD_CONFIG,
    RESPIRATOR,
    RESPIRATOR_CONFIG,
    PESTICIDE_USE,
    PROGRAM_FLEX,
    PESTICIDE_CONFIG,
  },
} = RELATIONSHIP_LABEL;

const parseApplicationDate = (dateString, referenceDate = new Date(), isEndDate = false) => {
  if (dateString instanceof Date || String(dateString).length >= 10) {
    return isEndDate ? endOfDay(new Date(dateString)) : startOfDay(new Date(dateString));
  }

  const inputReferenceDate = referenceDate || new Date();
  const [inputMonth, inputDay, inputYear] = dateString.split('/');
  const date = [inputMonth || 1, inputDay || 1, inputYear];

  if (inputYear) {
    if (!yearRegExp.test(inputYear)) {
      date[2] = new Date(inputReferenceDate).getFullYear() + Number.parseInt(inputYear, 10);
    }
  } else {
    date[2] = new Date(inputReferenceDate).getFullYear();
  }

  const result = new Date(date.join('/'));

  if (isEndDate) {
    if (!inputDay) {
      return endOfMonth(result);
    }

    return endOfDay(result);
  }

  return result;
};

const generateApplicationApplyWindow = (template) => {
  const { startDate, endDate } = template?.applicationWindow || {};

  if (!startDate || !endDate) {
    return null;
  }

  return { startDate, endDate };
};

const isInWindow = (referenceDate, startDate, endDate) =>
  isAfter(referenceDate, startDate) && isBefore(referenceDate, endDate);

const canApplyForAssessment = (
  { _id: templateId, applicationWindow = {} } = {},
  parentRelationships = [],
  referenceDate = new Date(),
) => {
  const { startDate, endDate, limit } = applicationWindow;
  if (!startDate || !endDate) {
    return true;
  }

  const currentYearStart = new Date(startDate).setFullYear(referenceDate.getFullYear());
  const currentYearEnd = new Date(endDate).setFullYear(referenceDate.getFullYear());

  if (!isInWindow(new Date(referenceDate), currentYearStart, currentYearEnd)) {
    throw Helper.error({ message: 'Unable to create. Application window has closed.' });
  }

  if (limit) {
    const existingAssessments = parentRelationships.filter(
      (r) =>
        r?.node?.templateId === templateId.toString() &&
        isInWindow(new Date(r?.edge?.startDate), currentYearStart, currentYearEnd),
    );
    if (existingAssessments.length >= limit) {
      throw Helper.error({ message: 'Application limit reached' });
    }
  }

  return true;
};

const parseDate = (date) => {
  const dateObj = parse(date);
  return dateObj instanceof Date ? dateObj : null;
};

const parseResponses = (responses, questions = []) => {
  if (!responses || !Object.keys(responses).length || !questions?.length) {
    return responses;
  }

  const transformed = cloneDeep(responses || {});
  questions.forEach((q) => {
    if (transformed[q.key] !== undefined) {
      switch (q.type) {
        case INPUT: {
          const inputPropsType = q?.inputProps?.type;
          if (inputPropsType === 'number' && transformed[q.key]) {
            transformed[q.key] = Number(transformed[q.key]);
          }
          break;
        }
        case DEPENDENT:
        case GROUP:
          transformed[q.key] = parseResponses(transformed[q.key], q.questions);
          break;
        case ADD_ANOTHER:
          transformed[q.key] = (transformed[q.key] || []).map((answer) => parseResponses(answer, q.questions));
          break;
        case DATETIMEPICKER:
        case DATEPICKER:
          if (transformed[q.key]) {
            transformed[q.key] = parseDate(transformed[q.key]);
          }
          break;
        case ATTACHMENT:
          transformed[q.key] = (transformed[q.key] || []).map((attachment) => ({
            ...attachment,
            uploadDate: parseDate(attachment?.uploadDate),
          }));
          break;
        default:
          break;
      }
    }
  });
  return transformed;
};

const getFeatureLabelByTemplateType = (templateType) => {
  switch (templateType) {
    case BUA:
      return BUA_CONFIG;
    case LAB_HAZARD:
      return LAB_HAZARD_CONFIG;
    case PROGRAM_FLEX:
      return FLEX_CONFIG;
    case PESTICIDE_USE:
      return PESTICIDE_CONFIG;
    case RESPIRATOR:
      return RESPIRATOR_CONFIG;
    default:
      return null;
  }
};

module.exports = {
  parseDate,
  parseResponses,
  parseApplicationDate,
  generateApplicationApplyWindow,
  canApplyForAssessment,
  getFeatureLabelByTemplateType,
};

const mapToDropdownOptions = (map) =>
  Object.entries(map).map(([value, label]) => ({
    value,
    label,
  }));

module.exports.applicationMethodMap = {
  A: 'Air',
  F: 'Fumigation',
  G: 'Ground',
  O: 'Other',
};

module.exports.areaUnitMap = {
  A: 'Acres',
  C: 'Cubic Feet',
  K: 'Thousands of Cubic Feet',
  P: 'Pounds',
  S: 'Square Feet',
  T: 'Tons',
  U: 'Miscellaneous Units',
};

module.exports.volumeUnitMap = {
  GA: 'Gallons',
  GR: 'Grams',
  KG: 'Kilograms',
  LB: 'Pounds',
  OZ: 'Ounces',
  PT: 'Pints',
  QT: 'Quarts',
};

module.exports.volumeUnitDropdownOptions = mapToDropdownOptions(module.exports.volumeUnitMap);
module.exports.areaUnitDropdownOptions = mapToDropdownOptions(module.exports.areaUnitMap);
