module.exports = {
  μg: {
    label: 'μg',
    type: 'mass',
    conversionFactor: 0.000001,
    cis: 'ug'
  },
  mg: {
    label: 'mg',
    type: 'mass',
    conversionFactor: 0.001,
    cis: 'mg'
  },
  g: {
    label: 'g',
    type: 'mass',
    conversionFactor: 1,
    cis: 'g'
  },
  kg: {
    label: 'kg',
    type: 'mass',
    conversionFactor: 1000,
    cis: 'kg'
  },
  μL: {
    label: 'μL',
    type: 'volume',
    conversionFactor: 0.000001,
    cis: 'ul'
  },
  mL: {
    label: 'mL',
    type: 'volume',
    conversionFactor: 0.001,
    cis: 'ml'
  },
  L: {
    label: 'L',
    type: 'volume',
    conversionFactor: 1,
    cis: 'l'
  },
  oz: {
    label: 'oz',
    type: 'mass',
    conversionFactor: 28.3495,
    cis: 'oz'
  },
  lb: {
    label: 'lb',
    type: 'mass',
    conversionFactor: 453.592,
    cis: 'lbs'
  },
  ton: {
    label: 'ton',
    type: 'mass',
    conversionFactor: 907185,
    cis: 'ton (English)'
  },
  tonne: {
    label: 'tonne (metric)',
    type: 'mass',
    conversionFactor: 1000000,
    cis: 'ton (Metric)'
  },
  cm3: {
    label: 'cm3',
    type: 'volume',
    conversionFactor: 0.001,
    cis: 'cm3'
  },
  m3: {
    label: 'm3',
    type: 'volume',
    conversionFactor: 1000,
    cis: 'm3'
  },
  'fluid oz': {
    label: 'fluid oz',
    type: 'volume',
    conversionFactor: 0.0295735,
    cis: 'fl oz'
  },
  pint: {
    label: 'pint',
    type: 'volume',
    conversionFactor: 0.473176,
    cis: 'pints'
  },
  qt: {
    label: 'qt',
    type: 'volume',
    conversionFactor: 0.946353,
    cis: 'qts'
  },
  gal: {
    label: 'gal',
    type: 'volume',
    conversionFactor: 3.78541,
    cis: 'gal'
  },
  ft3: {
    label: 'ft3',
    type: 'volume',
    conversionFactor: 28.3168,
    cis: 'ft3'
  },
  other: {
    label: 'other',
    type: 'other',
    conversionFactor: null
  }
};