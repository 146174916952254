/* eslint-disable import/no-cycle */
import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { capitalize, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FIND_OPERATOR_PERMIT } from '../../graphql/fq.query';
import FqForm from './FqForm';
import Search from '../Search';
import { QUESTION_VARIANT } from './Helper';

const PermitSearch = ({
  autoFocus,
  disabled,
  error,
  floatingText,
  hintText,
  helperText,
  id,
  label,
  labelVariant,
  onClear,
  onSelect,
  value,
  questions,
  parentResponses,
  required,
  variant,
}) => {
  const [search, { data, loading }] = useLazyQuery(FIND_OPERATOR_PERMIT);
  const [hasPermitNumber, setHasPermitNumber] = useState(false);
  const [permitDetails, setPermitDetails] = useState(parentResponses || null);
  const [err, setErr] = useState(error);
  useEffect(() => {
    onSelect({ ...permitDetails });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permitDetails]);

  useEffect(() => {
    setErr(error);
  }, [error]);

  const permits = (data?.findOperatorPermit || []).map((o) => ({ key: o.id, ...o }));

  const selectedValue = (permit) => {
    const { id: _id, sites, pests, ...details } = permit;
    setPermitDetails(details);
    search({ variables: { permitNumber: '' } });
  };
  const noMatchingFound = hasPermitNumber && isEmpty(permits) && !permitDetails?.permit;

  return (
    <>
      {!disabled && (
        <div className="mb-8 mt-20">
          <Search
            id={id}
            label={floatingText || label || ''}
            labelVariant={labelVariant}
            autoFocus={autoFocus}
            autocomplete
            isLoading={loading}
            placeholder={hintText}
            helperText={
              !loading && noMatchingFound
                ? `No matching permit number found against Cal Ag Permits data.
            Verify and re-enter correct permit number to proceed.`
                : helperText
            }
            debounceTime={1000}
            disabled={disabled}
            items={permits}
            itemToString={(permit) => (permit?.permit ? permit.permit : permit)}
            minSearchLength={1}
            onChange={({ inputValue }) => {
              setHasPermitNumber(true);
              search({ variables: { permitNumber: inputValue } });
            }}
            onSelect={(permit) => selectedValue(permit)}
            value={permitDetails?.permit || permitDetails?.operatorId || ''}
            onClear={(e) => {
              setHasPermitNumber(false);
              setPermitDetails(null);
              search({ variables: { permitNumber: '' } });
              onClear(e);
            }}
            renderItem={(permit) => (
              <Typography variant="body1">{`${permit.permit} - ${permit.operator} (${capitalize(
                permit.county,
              )} County)`}</Typography>
            )}
            error={err || noMatchingFound}
            required={required}
          />
        </div>
      )}

      {permitDetails?.permit && (
        <div className="-mx-24">
          <FqForm questions={questions} readOnly responses={permitDetails} onChange={(update) => {}} />
        </div>
      )}
    </>
  );
};

PermitSearch.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  floatingText: PropTypes.string,
  helperText: PropTypes.string,
  hintText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  questions: PropTypes.arrayOf(PropTypes.object),
  parentResponses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

PermitSearch.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  floatingText: null,
  helperText: '',
  hintText: 'Enter a valid Permit Number',
  id: 'permit-search',
  label: null,
  labelVariant: 'default',
  onClear: () => {},
  onSelect: () => {},
  questions: [],
  value: null,
  parentResponses: null,
  required: true,
  variant: QUESTION_VARIANT.STANDARD,
};

export default PermitSearch;
