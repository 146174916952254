const {
  ObjectIdSchema
} = require('@rss/common');
const yup = require('yup');
class RefundedItem {
  constructor(args) {
    Object.assign(this, RefundedItem.schema().cast(args, {
      stripUnknown: true
    }));
  }
  validate() {
    return RefundedItem.schema().validateSync(this, {
      abortEarly: false
    });
  }
  static schema() {
    return yup.object({
      productId: new ObjectIdSchema(),
      productName: yup.string().required(),
      description: yup.string().default('').nullable(),
      quantity: yup.number().default(0).required(),
      price: yup.number().default(0).required(),
      reasons: yup.array().of(yup.string()).required()
    });
  }
}
module.exports = RefundedItem;