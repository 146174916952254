import { Divider, List, ListItem, ListSubheader, ListItemText, Paper, Typography } from '@material-ui/core';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';

const getPrices = (items = [], products) => {
  return items.reduce(
    (acc, curr) => {
      const product = products ? products.find((p) => p._id === curr._id) : curr.product;
      if (!product) {
        return acc;
      }

      acc.subTotal += (curr.quantity || curr.product.quantity || 1) * product.price;
      acc.fees += (curr.quantity || curr.product.quantity || 1) * product.fee;

      if (!product.taxExempt) {
        acc.tax += (curr.quantity || curr.product.quantity || 1) * product.price * product.salesTax;
      }
      acc.total = acc.subTotal + acc.fees + acc.tax;
      return acc;
    },
    { subTotal: 0, fees: 0, tax: 0, total: 0 },
  );
};

function CartPrice({ storeId, items, productPrices }) {
  const { subTotal, fees, tax, total } = getPrices(items, productPrices);

  return (
    <Paper className="mb-16 rounded-8 pb-12 shadow-1">
      <List subheader={<ListSubheader component="div">Order Summary</ListSubheader>}>
        <ListItem>
          <ListItemText primary="Items" />
          {numeral(subTotal).format('$0,0.00')}
        </ListItem>
        <ListItem>
          <ListItemText primary="Fees" />
          {numeral(fees).format('$0,0.00')}
        </ListItem>

        <ListItem>
          <ListItemText primary="Tax" />
          {numeral(tax).format('$0,0.00')}
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Total" primaryTypographyProps={{ variant: 'h6' }} />
          <Typography variant="h6">{numeral(total).format('$0,0.00')}</Typography>
        </ListItem>
      </List>
    </Paper>
  );
}

CartPrice.propTypes = {
  storeId: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  productPrices: PropTypes.arrayOf(PropTypes.any),
};

CartPrice.defaultProps = {
  productPrices: null,
};

export default CartPrice;
