import { useState, useEffect } from 'react';
import useSecurity from './useSecurity';

export default function useIdClaim() {
  const [idClaim, setIdClaim] = useState(null);
  const { browserSecurity } = useSecurity();

  useEffect(() => {
    if (!idClaim) {
      if (browserSecurity.isAuthenticated) {
        setIdClaim(browserSecurity.user);
      }
    }
  }, [browserSecurity.isAuthenticated, browserSecurity.user, idClaim]);

  return idClaim;
}
