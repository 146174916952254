import gql from 'graphql-tag';

export const PROFILE_QUERY = gql`
  query LoadProfile($programFeatures: [String]) {
    profile {
      userId
      firstName
      lastName
      email
      phone
      idps
      tenantCode
      campusCode
      features
      relationships
      tags
    }
    rolePermissions
    findProgramsByUser(programFeatures: $programFeatures)
    findCentersOfExcellenceByUser {
      id
      name
      featureLabels
    }
  }
`;

export const PERSON_SUMMARY = gql`
  query PersonSummary($id: ID!) {
    personSummary(id: $id) {
      firstName
      lastName
      email
      userId
      campusCode
      tenantCode
    }
  }
`;

export const USER_DETAIL_FOR_ADMIN = gql`
  query LoadUser($id: ID!) {
    personByUserId(id: $id) {
      userId
      firstName
      lastName
      email
      phone
      identifiers {
        employeeId
        ucnetId
      }
      idps
      tenantCode
      campusCode
      groups {
        id
        name
        members {
          userId
          firstName
          lastName
          roles
        }
        tags
      }
      relationships
    }
    personUserGroups(id: $id) {
      id
      name
      routingList
      departments {
        name
        code
      }
    }
  }
`;
