"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var edgeRoles = {
  /*  ADMIN ROLES */
  ANALYTICS_ADMIN: 'ANALYTICS_ADMIN',
  /*  APP ROLES */
  AX_APP_ALL: 'AX-App: All',
  AX_APP_ASSESSMENT: 'AX-App: Assessment',
  AX_APP_ASSESSMENT_REPORTS: 'AX-App: Assessment Reports',
  AX_APP_AWAY: 'AX-App: Away',
  AX_APP_BIO: 'AX-App: BIO',
  AX_APP_BIO_SAFETY: 'AX-App: Biosafety',
  AX_APP_CHEMICALS: 'AX-App: Chemicals',
  AX_APP_CHEMICALS_CERS: 'AX-App: Chemicals CERS',
  AX_APP_CHEMICALS_CFATS: 'AX-App: Chemicals CFATS',
  AX_APP_CHEMICALS_DASHBOARD: 'AX-App: Chemicals Dashboard',
  AX_APP_CHEMICALS_ENVIRONMENTAL_REPORTS: 'AX-App: Chemicals Environmental Reports',
  AX_APP_CHEMICALS_GROUPS_WITH_NO_INVENTORY: 'AX-App: Chemicals Groups with No Inventory',
  AX_APP_CHEMICALS_MAQ: 'AX-App: Chemicals MAQ',
  AX_APP_DRONES: 'AX-App: Drones',
  AX_APP_EFR: 'AX-App: EFR',
  AX_APP_EXECUTIVE: 'AX-App: Executive',
  AX_APP_INSPECT: 'AX-App: Inspect',
  AX_APP_INSPECT_ADMIN: 'AX-App: Inspect Admin',
  AX_APP_INSPECT_ANALYTICS: 'AX-App: Inspect Analytics',
  AX_APP_LAB_HAZARD_ASSESSMENT: 'AX-App: Lab Hazard Assessment',
  AX_APP_LHAT: 'AX-App: LHAT',
  AX_APP_LHAT_NEW: 'AX-App: LHAT (New)',
  AX_APP_LHAT_NEW_PPE_DASHBOARD: 'AX-App: LHAT (New) PPE Dashboard',
  AX_APP_LHAT_OLD: 'AX-App: LHAT (Old)',
  AX_APP_LHAT_OLD_SIT_WASTE: 'AX-App: LHAT (Old), SIT & WASTe',
  AX_APP_LHAT_SIT_WASTE: 'AX-App: LHAT, SIT & WASTe',
  AX_APP_LOCATION_OVERVIEW: 'AX-App: Location Overview',
  AX_APP_PRE_INSPECTION_REPORT: 'AX-App: Preinspection Report',
  AX_APP_MONITOR: 'AX-App: Monitor',
  AX_APP_RAD_SAFTEY: 'AX-App: RadSafety',
  AX_APP_RP_OVERVIEW: 'AX-App: RP Overview',
  AX_APP_SAFE_PATIENT_HANDLING: 'AX-App: Safe Patient Handling',
  AX_APP_SAFETY_PLANS: 'AX-App: Safety Plans',
  AX_APP_SAFTEY_SUITE: 'AX-App: Safety Suite',
  AX_APP_SIT: 'AX-App: SIT',
  AX_APP_TRAINING: 'AX-App: Training',
  AX_APP_TRAINING_COE: 'AX-App: Training CoE',
  AX_APP_WASTE: 'AX-App: WASTe',
  AX_APP_WORKPLACE_VIOLENCE_PREVENTION: 'AX-App: Workplace Violence Prevention',
  AX_APP_WSSP: 'AX-App: WSSP',
  /*  SPECIAL ROLES */
  AX_SPECIAL_BI_TEAM: 'AX-Special: BI Team',
  AX_SPECIAL_DSC: 'AX-Special: DSC',
  AX_SPECIAL_EXECUTIVE: 'AX-Special: Executive',
  AX_SPECIAL_PREINSPECTION_DSC: 'AX-Special: Preinspection DSC',
  AX_SPECIAL_RSS_INTERNAL: 'AX-Special: RSS Internal',
  /*  ENTITY ROLES */
  AX_ENTITY: 'AX-Entity',
  /*  AUTO ROLES */
  AX_AUTO_DEPARTMENT_SAFTEY_COORDINATOR: 'AX-Auto: Department Safety Coordinator',
  AX_AUTO_INSPECT: 'AX-Auto: Inspect'
};
var createEdgeRoleForRelationship = function createEdgeRoleForRelationship() {
  return Object.keys(edgeRoles).reduce(function (acc, role) {
    return _objectSpread(_objectSpread({}, acc), {}, _defineProperty({}, role, role));
  }, {});
};
module.exports = {
  ANALYTICS_EDGE_ROLES: createEdgeRoleForRelationship(),
  EDGE_ROLES: edgeRoles
};