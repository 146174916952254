import { Button } from '@material-ui/core';
import { IDLE_KEY, IDLE_TIMEOUT } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DialogTemplate } from '../../components';
import config from '../../config';
import { useIdleEvent, useSecurity } from '../../hooks';
import { updateTimeoutTime } from '../auth.helper';

export default function CountDownDialog({ duration, onClose }) {
  const [timeRemaining, setTimeRemaining] = useState(duration);

  const { dispatchIdleEvent } = useIdleEvent();
  const history = useHistory();

  const { browserSecurity } = useSecurity();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((d) => (d > 0 ? d - 1 : d));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timeRemaining <= 0) {
      dispatchIdleEvent();
      localStorage.removeItem(IDLE_KEY.IDLE_MINUTES_KEY);
      localStorage.removeItem(IDLE_KEY.IDLE_TIMEOUT_KEY);
      localStorage.removeItem(config.SELECTED_IDP_KEY);
      browserSecurity.logout('/');
      onClose();
    }
  }, [timeRemaining, history, onClose, dispatchIdleEvent]);

  return (
    <DialogTemplate
      open
      content={IDLE_TIMEOUT.IDLE_WARNING_MESSAGE(timeRemaining)}
      title={IDLE_TIMEOUT.IDLE_WARNING_HEADING}
      primaryActions={
        <>
          <Button
            variant="contained"
            onClick={() => {
              dispatchIdleEvent();
              localStorage.removeItem(IDLE_KEY.IDLE_MINUTES_KEY);
              localStorage.removeItem(IDLE_KEY.IDLE_TIMEOUT_KEY);
              localStorage.removeItem(config.SELECTED_IDP_KEY);
              browserSecurity.logout('/');
              onClose();
            }}
          >
            {IDLE_TIMEOUT.IDLE_SIGNOUT_BUTTON}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              localStorage.removeItem(IDLE_KEY.IDLE_SIGNOUT_KEY);
              updateTimeoutTime(Date.now());
              onClose();
            }}
          >
            {IDLE_TIMEOUT.IDLE_STAY_BUTTON}
          </Button>
        </>
      }
    />
  );
}

CountDownDialog.propTypes = {
  duration: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};
