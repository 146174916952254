import { FqHelper, FqType } from '@rss/common';
import { compact, first, isEmpty, isString } from 'lodash';
import { string, number } from 'yup';

export const QUESTION_VARIANT = {
  STANDARD: 'standard',
  COMPACT: 'compact',
};

export const getFqClassName = (question, defaultClass = 'w-full') => question?.className || defaultClass || 'w-full';

export const shouldDisplay = (question, parentResponses) => {
  return (
    parentResponses &&
    Object.keys(parentResponses).some((key) => {
      const selectedItem = parentResponses[key];
      let childSelected;
      if (Array.isArray(question.parentValue)) {
        childSelected = question.parentValue.some((pValue) =>
          isSelected(pValue, selectedItem, key, question.parentKey),
        );
      } else {
        childSelected = isSelected(question.parentValue, selectedItem, key, question.parentKey);
      }
      return childSelected;
    })
  );
};

const isSelected = (qParentValue, selectedItem, key, qParentKey) => {
  if (Array.isArray(selectedItem)) {
    return selectedItem.some((val) => val?.value === qParentValue);
  }
  if (selectedItem?.constructor === Object) {
    return selectedItem.value && selectedItem.value === qParentValue;
  }
  return key === qParentKey && qParentValue === selectedItem;
};

export function hasErrors(value, question, validators = [], inputErrors = []) {
  const { inputProps, required, type, validationProps = {} } = question;
  const questionType = inputProps?.type || type || 'text';

  const errors = [];
  // TODO: move required validation to the validators array
  if (required && questionType === FqType.PERMIT_SITE) {
    errors.push(
      isEmpty(value) || value?.some((itemValue) => !FqHelper.hasValue(itemValue.item))
        ? validationProps.requiredMessage ?? 'This is a required question.'
        : null,
    );
  } else if (questionType === 'email' && FqHelper.hasValue(value)) {
    try {
      string().email().validateSync(value);
    } catch (yupError) {
      errors.push('This is invalid email address.');
    }
  } else if (questionType === 'number' && !isEmpty(inputProps) && FqHelper.hasValue(value)) {
    try {
      let numberSchema = number().label('This');
      if (FqHelper.hasValue(inputProps?.min)) {
        numberSchema = numberSchema.min(inputProps.min, validationProps.minNumberMessage);
      }

      if (FqHelper.hasValue(inputProps?.max)) {
        numberSchema = numberSchema.max(inputProps.max, validationProps.maxNumberMessage);
      }

      numberSchema.validateSync(value);
    } catch (yupError) {
      errors.push(yupError.message);
    }
  } else if (questionType === FqType.RECIPIENTS) {
    if (required && !FqHelper.hasValue(value)) {
      errors.push('This is a required question.');
    } else if (question.valueType === 'email' && FqHelper.hasValue(value)) {
      errors.push(
        value.some(({ email }) => {
          try {
            string().email().validateSync(email);
          } catch (yupError) {
            return true;
          }

          return false;
        })
          ? 'Invalid email addresses entered. Please validate all email addresses.'
          : null,
      );
    }
  } else if (required && questionType === 'richtext') {
    if (isString(value) && value?.trim() === '<p></p>') {
      errors.push(validationProps.requiredMessage ?? 'This is a required question');
    }
    (!!inputErrors?.length && !FqHelper.hasValue(value) && errors.push(inputErrors)) ||
      errors.push(!FqHelper.hasValue(value) ? validationProps.requiredMessage ?? 'This is a required question.' : null);
  } else if (required && questionType !== 'label') {
    (!!inputErrors?.length && !FqHelper.hasValue(value) && errors.push(inputErrors)) ||
      errors.push(!FqHelper.hasValue(value) ? validationProps.requiredMessage ?? 'This is a required question.' : null);
  }

  return compact(errors.concat(validators.map((func) => func(value))));
}

export const convertStringArrayToOptionsArray = (items = [], options = []) =>
  (items || []).map((item) => {
    if (typeof item !== 'object') {
      if (!isEmpty(options)) {
        return options.find(({ value }) => value === item);
      }
      return { value: item, label: item };
    }
    return item;
  });

// TODO: what is this for? Its only used for datepicker which shouldn't be an array for values?
// also defaultValue is never used as an input
export const getFirstValue = (values = [], defaultValue = '') => {
  const firstValue = Array.isArray(values) ? first(values) : values;

  return firstValue?.value || firstValue || defaultValue;
};

// the result string has to honor exact line spacing
// so that the compared strings are as in UI line/spacing

export const buildCheckboxString = (responses) => {
  let responseString = '';
  if (responses) {
    responses.forEach((response) => {
      responseString =
        (response.label &&
          responseString.concat(`${response.label}
`)) ||
        '';
    });
  }
  return responseString;
};

// the result string has to honor exact line spacing
// so that the compared strings are as in UI line/spacing

export const buildAttachmentString = (responses) => {
  let responseString = '';
  if (responses) {
    responses.forEach((response, idx) => {
      responseString = responseString.concat(`  ${idx + 1}.
`);
      responseString =
        (response.filename &&
          responseString.concat(`File Name:   ${response.filename}
`)) ||
        responseString;

      responseString =
        (response.title &&
          responseString.concat(`Title:        ${response.title}
`)) ||
        responseString;

      responseString =
        (response.description &&
          responseString.concat(`Description: ${response.description}
`)) ||
        responseString;
    });
  }

  return responseString;
};

export const displayLabel = (question) => {
  return (
    ![FqType.ATTACHMENT, FqType.DEPENDENT, FqType.GROUP, FqType.PERMIT_SITE, FqType.SWITCH].includes(question.type) &&
    question.label &&
    question.label.trim() &&
    !!question.label.length
  );
};
