import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { isEmpty, takeRight } from 'lodash';

import { responseType } from './types/prop-types';
import FqMultiSelect from './FqMultiSelect';
import { QUESTION_VARIANT } from './Helper';

const FqDropdown = ({
  autoFocus,
  disabled,
  error,
  label,
  labelVariant,
  options,
  onChange,
  required,
  questionKey,
  value,
  valueType,
  variant,
  placeholder,
}) => {
  const selectedValue = takeRight(Array.isArray(value) ? value : [value], 1);

  useEffect(() => {
    if ((!selectedValue || isEmpty(selectedValue)) && required && options.length === 1) {
      onChange(options[0], questionKey);
    }
  }, [options, onChange, questionKey, required, selectedValue]);

  return (
    <FqMultiSelect
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      label={label}
      labelVariant={labelVariant}
      limit={1}
      value={selectedValue}
      onChange={(selectedValues) => {
        const selected = selectedValues?.length ? selectedValues[0] : null;
        onChange(selected, questionKey);
      }}
      options={options}
      valueType={valueType}
      questionKey={questionKey}
      required={required}
      variant={variant}
      placeholder={placeholder}
    />
  );
};

FqDropdown.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(responseType)), PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.shape(responseType), PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  valueType: PropTypes.oneOf(['string', 'object']),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  placeholder: PropTypes.string,
};

FqDropdown.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  label: '',
  labelVariant: 'default',
  required: false,
  value: {},
  valueType: 'object',
  variant: QUESTION_VARIANT.STANDARD,
  placeholder: '',
};

export default FqDropdown;
