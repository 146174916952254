import { Accordion, AccordionDetails, AccordionSummary, Icon, Typography, makeStyles } from '@material-ui/core';
import Parser from 'html-react-parser';
import replace from 'lodash/replace';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import Feedback from './Feedback';

const useStyles = makeStyles((theme) => ({
  articleDetails: {
    '& a:not([role=button])': {
      color: `${theme.palette.primary.main} !important`,
      borderBottom: 'none !important',
      background: 'none !important',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const Article = ({ article: { short_description: question, kb_answer: answer, sys_id: articleId } }) => {
  const [expanded, setExpanded] = useState(false);
  const [showActions, setShowActions] = useState(true);
  const classes = useStyles();

  return (
    <Accordion
      square
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
        <div className="flex items-center">
          <Icon color="action">help_outline</Icon>
          <Typography className="px-8">{Parser(question)}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="ml-8 space-y-12">
          <Typography
            classes={{
              root: classes.articleDetails,
            }}
            component="span">
            {Parser(replace(answer, '<p>&nbsp;</p>', ''))}
          </Typography>
          <Feedback articleId={articleId} showActions={showActions} onSave={() => setShowActions(false)} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

Article.propTypes = {
  article: PropTypes.shape({
    short_description: PropTypes.string.isRequired,
    kb_answer: PropTypes.string.isRequired,
    sys_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default Article;
