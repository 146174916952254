import { lazy } from 'react';

const routes = {
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/developer/spoofing',
      component: lazy(() => import('./containers/SpoofPage')),
    },
  ],
};

export default routes;
