import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import config from '../../config';

const { HELP_SUBMIT_TICKET_URL, HELP_SUBMIT_TICKET_URL_EXTERNAL } = config;

const TEL_NUMBER = '5306185580';
const TEL_NUMBER_DISPLAY = '530-618-5580';

const LinkWithIcon = ({ href, icon, text }) => {
  const rel = href.startsWith('http') ? 'noopener noreferrer' : '';
  return (
    <a className="mx-16 inline-flex items-center text-lg font-medium" href={href} rel={rel}>
      <Icon>{icon}</Icon>
      <span className="ml-2">{text}</span>
    </a>
  );
};

const ContactUs = ({ isExternalUser }) => (
  <div className="text-center">
    <p className="text-base">Can&apos;t find the answer you&apos;re looking for?</p>
    <div className="flex flex-row items-center justify-center">
      <LinkWithIcon
        href={isExternalUser ? HELP_SUBMIT_TICKET_URL_EXTERNAL : HELP_SUBMIT_TICKET_URL}
        icon="mail"
        text="Submit a ticket"
      />
      <LinkWithIcon href={`tel:${TEL_NUMBER}`} icon="call" text={TEL_NUMBER_DISPLAY} />
    </div>
  </div>
);

ContactUs.propTypes = { isExternalUser: PropTypes.bool };

ContactUs.defaultProps = { isExternalUser: false };

export default ContactUs;
