import PropTypes from 'prop-types';
import React, { useState } from 'react';

export const AdvanceSearchDataContext = React.createContext();

const AdvanceSearchDataProvider = ({ children }) => {
  const data = { projectName: '', locationsLoading: false };
  const [criteria, setCriteria] = useState(data);
  const [filterCriteria, setFilterCriteria] = useState(data);

  return (
    <AdvanceSearchDataContext.Provider
      value={{
        criteria,
        setCriteria,
        filterCriteria,
        setFilterCriteria,
      }}>
      {children}
    </AdvanceSearchDataContext.Provider>
  );
};

AdvanceSearchDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AdvanceSearchDataProvider;
