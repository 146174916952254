"use strict";

module.exports = {
  ACKNOWLEDGE: 'ACKNOWLEDGE',
  CREATE: 'CREATE',
  REVIEW: 'REVIEW',
  REVISE: 'REVISE',
  READY_FOR_USE: 'READY_FOR_USE',
  DELETE: 'DELETE',
  EXPIRATION_REMINDER: 'EXPIRATION_REMINDER',
  CHANGE_OWNER: 'CHANGE_OWNER'
};