const {
  PersonReference
} = require('@rss/common');
const clone = require('clone');
const tv4 = require('tv4');
const InventoryReference = require('./inventory-reference');
class Checkout {
  constructor(opt) {
    this.to = null;
    this.inventory = null;
    this.status = null;
    this.date = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.to = new PersonReference(opt.to);
    this.inventory = new InventoryReference(opt.inventory);
    this.status = opt.status;
    this.date = opt.date ? new Date(opt.date) : new Date();
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'checkout.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        to: {
          type: ['object', 'null']
        },
        inventory: {
          type: ['object', 'null']
        },
        status: {
          type: ['string', 'null']
        },
        date: {
          type: ['object', 'null']
        }
      },
      required: ['to', 'inventory', 'status', 'date']
    };
  }
}
tv4.addSchema(InventoryReference.schema());
tv4.addSchema(PersonReference.schema());
module.exports = Checkout;