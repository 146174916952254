export const TEMPLATE_TYPE = {
  AGENDA: {
    type: 'AGENDA',
    url: '/biosafety/agenda',
    header: 'Agendas',
    tabHeader: 'Agenda',
  },
  CHEMICAL: {
    type: 'CHEMICAL',
    url: '',
    header: '',
  },
  HAZARD_SIGN: {
    type: 'HAZARD_SIGN',
    url: '/hazard-sign',
    header: 'Hazard Sign',
    tabHeader: 'Sign',
  },
  LOTO: {
    type: 'LOTO',
    url: '/loto',
    header: 'LOTO',
    templatePath: '/loto/template',
    cardPath: '/loto/template/card-option',
    menuTitle: 'LOTO',
    tabHeader: 'Procedure',
  },
  MEDICAL: {
    type: 'MEDICAL',
    url: '/medical/safety',
    header: 'Medical Safety Plan',
    templatePath: '/medical/safety/template',
    cardPath: '/medical/safety/template/card-option',
    menuTitle: 'Medical Safety Plan Menu',
    tabHeader: 'Safety Plan',
  },
  SPH: {
    type: 'SPH',
    url: '/medical/plan/sph',
    header: 'Safe Patient Handling',
    templatePath: '/medical/plan/sph/template',
    cardPath: '/medical/plan/sph/template/card-option',
    menuTitle: 'Medical Menu',
    tabHeader: 'Plan',
  },
  WPVP: {
    type: 'WPVP',
    url: '/medical/plan/wpvp',
    header: 'Workplace Violence Prevention',
    templatePath: '/medical/plan/wpvp/template',
    cardPath: '/medical/plan/wpvp/template/card-option',
    menuTitle: 'Medical Menu',
    tabHeader: 'Plan',
  },
  SOP: {
    type: 'SOP',
    url: '/sop',
    header: 'Inventory SOP',
    tabHeader: 'SOP',
    cardPath: '/sop/template/card-option',
    stockroomPath: '/inventory/chemical/stockroom',
    templatePath: '/sop/template',
  },
  FIELD: {
    type: 'FIELD',
    url: '/safety/plan',
    header: 'Safety Plan',
    templatePath: '/safety/plan/template',
    cardPath: '/safety/plan/template/card-option',
    menuTitle: 'Safety Plan Menu',
    tabHeader: 'Plan',
  },
};

export const TEMPLATE_TYPES = Object.keys(TEMPLATE_TYPE);
