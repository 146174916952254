import { ListItem, ListItemText } from '@material-ui/core';
import { CATALOG_TYPE, LibraryItemReference } from '@risk-and-safety/library';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import { useLazyQuery } from '@apollo/client';
import { debounce, get } from 'lodash';
import React from 'react';

import Search from './Search';

const FIND_LIBRARY_ITEMS = gql`
  query FindLibraryItems($terms: String, $catalog: CatalogType!) {
    findLibraryItems(terms: $terms, catalog: $catalog) {
      _id
      catalog
      name
      description
      tags
    }
  }
`;

const LibrarySearch = ({ autoFocus, catalog, clearOnSelect, id, label, onClear, onSelect, placeholder, value }) => {
  const [searchLibraryItems, { data = {}, loading }] = useLazyQuery(FIND_LIBRARY_ITEMS);
  const searchLibrary = debounce(searchLibraryItems, 200);
  const results = get(data, 'findLibraryItems', []).map((res) => ({ ...res, key: res._id }));

  return (
    <Search
      id={id}
      isLoading={loading}
      autocomplete
      autoFocus={autoFocus}
      clearOnSelect={clearOnSelect}
      disableUnderline={false}
      value={value}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      items={results}
      itemToString={(item) => get(item, 'name')}
      onChange={({ inputValue }) => {
        if (inputValue?.length > 1) {
          searchLibrary({ variables: { catalog, terms: inputValue } });
        }
      }}
      onSelect={onSelect}
      onClear={() => onClear()}
      renderItem={(item) => {
        return (
          <ListItem>
            <ListItemText primary={item.name} secondary={item.description} />
          </ListItem>
        );
      }}
    />
  );
};

LibrarySearch.propTypes = {
  autoFocus: PropTypes.bool,
  catalog: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  value: PropTypes.shape(LibraryItemReference),
};

LibrarySearch.defaultProps = {
  autoFocus: false,
  catalog: CATALOG_TYPE.PPE,
  clearOnSelect: false,
  id: null,
  onClear: () => null,
  label: 'Search Library',
  placeholder: 'Search Library',
  value: null,
};

export default LibrarySearch;
