import { get } from 'lodash';
import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import config from '../config';

const HomePage = lazy(() => import('./containers/HomePage'));
const AnalyticsPage = lazy(() => import('./containers/AnalyticsPage'));

const routes = {
  settings: {
    helpCategories: ['analytics'],
  },
  auth: ({ profile } = {}) => Boolean(profile) && get(profile, 'features.analytics.rollOut.value'),
  routes: [
    {
      path: `${config.ANALYTICS_V2_URL}/:category/:subCategory/:name`,
      component: () => <Redirect to={config.ANALYTICS_V2_URL} />,
    },
    {
      path: `${config.ANALYTICS_V2_URL}/:identifier`,
      component: AnalyticsPage,
    },
    {
      path: config.ANALYTICS_V2_URL,
      component: HomePage,
    },
  ],
};

export default routes;
