"use strict";

module.exports = {
  H200: ['GHS01'],
  H201: ['GHS01'],
  H202: ['GHS01'],
  H203: ['GHS01'],
  H204: ['GHS01'],
  H206: ['GHS02'],
  H207: ['GHS02'],
  H208: ['GHS02'],
  H220: ['GHS02'],
  H221: ['GHS02'],
  H222: ['GHS02'],
  H223: ['GHS02'],
  H224: ['GHS02'],
  H225: ['GHS02'],
  H226: ['GHS02'],
  H228: ['GHS02'],
  H229: ['GHS02'],
  H230: ['GHS02'],
  H231: ['GHS02'],
  H232: ['GHS02'],
  H240: ['GHS01'],
  H241: ['GHS01', 'GHS02'],
  H242: ['GHS02'],
  H250: ['GHS02'],
  H251: ['GHS02'],
  H252: ['GHS02'],
  H260: ['GHS02'],
  H261: ['GHS02'],
  H270: ['GHS03'],
  H271: ['GHS03'],
  H272: ['GHS03'],
  H280: ['GHS04'],
  H281: ['GHS04'],
  H282: ['GHS02', 'GHS04'],
  H283: ['GHS02', 'GHS04'],
  H284: ['GHS04'],
  H290: ['GHS05'],
  H304: ['GHS08'],
  H305: ['GHS08'],
  H314: ['GHS05'],
  H318: ['GHS05'],
  H300: ['GHS06'],
  H301: ['GHS06'],
  H302: ['GHS07'],
  H310: ['GHS06'],
  H311: ['GHS06'],
  H312: ['GHS07'],
  H315: ['GHS07'],
  H317: ['GHS07'],
  H319: ['GHS07'],
  H330: ['GHS06'],
  H331: ['GHS06'],
  H332: ['GHS07'],
  H334: ['GHS08'],
  H335: ['GHS07'],
  H336: ['GHS07'],
  H340: ['GHS08'],
  H341: ['GHS08'],
  H350: ['GHS08'],
  H350i: ['GHS08'],
  H351: ['GHS08'],
  H360: ['GHS08'],
  H360F: ['GHS08'],
  H360D: ['GHS08'],
  H360FD: ['GHS08'],
  H360Fd: ['GHS08'],
  H360Df: ['GHS08'],
  H361: ['GHS08'],
  H361f: ['GHS08'],
  H361d: ['GHS08'],
  H361fd: ['GHS08'],
  H370: ['GHS08'],
  H371: ['GHS08'],
  H372: ['GHS08'],
  H373: ['GHS08'],
  H400: ['GHS09'],
  H410: ['GHS09'],
  H411: ['GHS09'],
  H420: ['GHS07'],
  // all the combinations have hcodes in alphabetical order
  'H300 + H310 + H330': ['GHS06'],
  'H300 + H310': ['GHS06'],
  'H300 + H330': ['GHS06'],
  'H310 + H330': ['GHS06'],
  'H301 + H311 + H331': ['GHS06'],
  'H301 + H311': ['GHS06'],
  'H301 + H331': ['GHS06'],
  'H311 + H331': ['GHS06'],
  'H302 + H312': ['GHS07'],
  'H302 + H312 + H332': ['GHS07'],
  'H302 + H332': ['GHS07'],
  'H312 + H332': ['GHS07'],
  'H315 + H320': ['GHS07'],
  'H335 + H336': ['GHS07']
};