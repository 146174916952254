import React, { lazy } from 'react';

const Help = lazy(() => import('./containers/Help'));

const routes = {
  settings: {},
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/help/:category',
      component: () => <Help type="page" />,
    },
    {
      path: '/help',
      component: () => <Help type="page" />,
    },
  ],
};

export default routes;
