const { ObjectId } = require('bson');
const tv4 = require('tv4');

class Assignment {
  constructor(opt) {
    this._id = null;
    this.templateId = null;
    this.parents = [];
    this.lastUpdatedDate = null;
    if (opt) {
      this.fillObject(opt);
    }
  }

  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.templateId = new ObjectId(opt.templateId);
    this.parents = opt.parents || [];
    this.lastUpdatedDate = opt.lastUpdatedDate ? new Date(opt.lastUpdatedDate) : new Date();
  }

  validate() {
    return tv4.validateMultiple(this, Assignment.schema());
  }

  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'assignment.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        _id: { type: 'object' },
        templateId: { type: 'object' },
        parents: { type: 'array', item: { type: 'object' } },
        lastUpdatedDate: { type: 'object' },
      },
      required: Object.keys(new Assignment()),
    };
  }
}

tv4.addSchema(Assignment.schema());

module.exports = Assignment;
