import { FormControl, FormControlLabel, FormLabel, FormHelperText, Radio, RadioGroup } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { responseType } from './types/prop-types';
import { QUESTION_VARIANT } from './Helper';

const FqRadio = React.memo(
  ({
    required,
    row,
    value: val,
    variant,
    disabled,
    hasError,
    label,
    labelVariant,
    onChange,
    options,
    questionKey,
    hintText,
  }) => {
    const [value, setValue] = useState(val);

    useEffect(() => {
      setValue(val || '');
    }, [val]);

    return (
      <FormControl
        className={`flex flex-wrap ${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}
        component="fieldset"
        disabled={disabled}
        error={hasError}
        required={required}>
        {labelVariant !== 'long' && (
          <FormLabel
            className={clsx('pre-line my-9 inline-block pb-2 text-black', disabled && 'text-grey-400')}
            focused={false}>
            {label}
          </FormLabel>
        )}
        {hintText && <FormHelperText>{hintText}</FormHelperText>}
        <RadioGroup
          aria-label={label}
          className={clsx(labelVariant === 'long' && '-mt-8')}
          id={questionKey}
          name={questionKey}
          row={row}
          onChange={(e, v) => {
            setValue(v);
            onChange(v, questionKey);
          }}
          value={value}>
          {options.map((option) => (
            <FormControlLabel
              className="pr-12"
              control={<Radio color="primary" inputProps={{ 'aria-label': option.label }} />}
              disabled={disabled}
              id={`${questionKey}_${option.value}_radio`}
              key={`${option.value}${option.label}`}
              label={option.label}
              value={option.value}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  },
);

FqRadio.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  hintText: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  options: PropTypes.arrayOf(PropTypes.shape(responseType)).isRequired,
  onChange: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  row: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
};

FqRadio.defaultProps = {
  disabled: false,
  hasError: false,
  label: null,
  hintText: null,
  labelVariant: 'default',
  row: true,
  variant: QUESTION_VARIANT.STANDARD,
  required: false,
};

export default FqRadio;
