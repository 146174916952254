const {
  FileReference
} = require('@rss/common');
const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
const attachmentType = require('../constants/inventory-attachment-type.constant');
class InventoryAttachment {
  constructor(opt) {
    this._id = new ObjectId();
    this.inventoryId = null;
    this.familyId = null;
    this.attachment = null;
    this.attachmentType = null;
    this.shareWithInformatics = false;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.inventoryId = new ObjectId(opt.inventoryId);
    this.familyId = new ObjectId(opt.familyId);
    this.attachment = new FileReference(opt.attachment);
    this.attachmentType = opt.attachmentType;
    this.shareWithInformatics = !!opt.shareWithInformatics;
  }
  validate() {
    return tv4.validateMultiple(this, InventoryAttachment.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'inventory-attachment.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        inventoryId: {
          type: 'object'
        },
        familyId: {
          type: 'object'
        },
        attachment: {
          type: {
            $ref: 'file-reference.schema.json'
          }
        },
        attachmentType: {
          type: {
            enum: Object.values(attachmentType)
          }
        },
        shareWithInformatics: {
          type: 'boolean'
        }
      },
      required: Object.keys(new InventoryAttachment())
    };
  }
}
tv4.addSchema(FileReference.schema());
module.exports = InventoryAttachment;