import FuseChipSelect from '@fuse/core/FuseChipSelect';
import { isEmpty, takeRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { responseType } from './types/prop-types';
import { convertStringArrayToOptionsArray, QUESTION_VARIANT } from './Helper';

const FqMultiSelect = ({
  autoFocus,
  disabled,
  value,
  options,
  label,
  labelVariant,
  limit,
  onChange,
  valueType,
  questionKey,
  required,
  error,
  addNew,
  variant,
  placeholder,
}) => {
  const selectOptions = valueType === 'string' ? convertStringArrayToOptionsArray(options) : options;
  const selectedValue = (
    valueType === 'string' ? convertStringArrayToOptionsArray(value, selectOptions) : value
  )?.filter((v) => Boolean(v?.value));

  // if limit is not provided, means multi-select is true
  const isMulti = !limit || limit > 1;

  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      <FuseChipSelect
        autoFocus={autoFocus}
        isDisabled={disabled}
        required={required}
        error={error}
        aria-label={label}
        value={selectedValue}
        placeholder={placeholder}
        onChange={(val) => {
          let values = [];
          if (val) {
            const array = Array.isArray(val) ? val : [val];
            values = limit ? takeRight(array, limit) : array;
          }
          onChange(valueType === 'string' ? values?.map((v) => v.value) : values, questionKey);
        }}
        textFieldProps={{
          label: labelVariant === 'long' ? null : label,
          disabled,
          InputLabelProps: {
            shrink: true,
          },
          variant: 'outlined',
        }}
        options={selectOptions}
        isMulti={isMulti}
        variant={addNew ? 'create' : 'fixed'}
        isSearchable
        isClearable={!isEmpty(selectedValue)}
      />
    </div>
  );
};

FqMultiSelect.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  limit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(responseType)), PropTypes.arrayOf(PropTypes.string)]),
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(responseType)), PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  valueType: PropTypes.oneOf(['string', 'object']),
  addNew: PropTypes.bool,
  questionKey: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  placeholder: PropTypes.string,
};

FqMultiSelect.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  label: '',
  labelVariant: 'default',
  limit: null,
  value: {},
  valueType: 'object',
  questionKey: null,
  required: false,
  addNew: false,
  variant: QUESTION_VARIANT.STANDARD,
  placeholder: 'Select',
};

export default FqMultiSelect;
