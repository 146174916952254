import { Checkbox, FormControlLabel, FormGroup, Icon, List, ListItemText, ListItem } from '@material-ui/core';
import uniq from 'lodash/uniq';
import PropTypes from 'prop-types';
import React from 'react';

import IconWithTooltip from '../IconWithToolTip';
import { NodeShape } from './prop-types';
import { getDisplayName } from '../../shared/helper';
import { profileConfig, rolesDescription } from '../../shared/profile-config';

const UsageRoleSelect = ({
  disabled,
  isOwner,
  orgRoles,
  selectedRoles,
  usage,
  onDefaultRoleChange,
  onElevatedRoleChange,
}) => {
  const {
    node: { name, label },
    multipleGroups,
  } = usage;

  const usageDisplayLabel = getDisplayName(label);

  const { defaultRole, roles = [], shouldTakeOrgRole } = profileConfig[label] || {};

  const isDefaultRoleChecked = Boolean(selectedRoles.includes(defaultRole) || selectedRoles.length);

  // TODO: need to remove shouldTakeOrgRole related logic once decision on documents assuming org role is made.
  const defaultRoles = shouldTakeOrgRole ? uniq(orgRoles) : (defaultRole && [defaultRole]) || [];

  const showRoles = isDefaultRoleChecked && Boolean(roles.length || defaultRoles.length);

  return (
    <>
      <FormControlLabel
        className="text-wrap w-full"
        control={
          <Checkbox
            color="primary"
            name={defaultRole}
            checked={isDefaultRoleChecked}
            disabled={disabled}
            onChange={onDefaultRoleChange}
          />
        }
        label={
          <>
            {name} <span className="opacity-60">({usageDisplayLabel})</span>
            {multipleGroups && (
              <Icon className="text-16" color="action">
                group
              </Icon>
            )}
            <span className="ml-2">
              <IconWithTooltip
                icon="info_outlined"
                title={
                  <List dense dividers="true">
                    {defaultRoles.map((role) => (
                      <ListItem key={role}>
                        <ListItemText
                          primary={
                            <>
                              <strong>{getDisplayName(role)}</strong>
                              <span className="ml-2 italic">(Default Role)</span>
                            </>
                          }
                          secondary={profileConfig[label][role] || ''}
                          secondaryTypographyProps={{
                            color: 'inherit',
                          }}
                        />
                      </ListItem>
                    ))}
                    {roles.map((role) => (
                      <ListItem key={role}>
                        <ListItemText
                          primary={<strong>{getDisplayName(role)}</strong>}
                          secondary={rolesDescription[role] || ''}
                          secondaryTypographyProps={{
                            color: 'inherit',
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                }
              />
            </span>
          </>
        }
        labelPlacement="end"
      />

      {showRoles && (
        <FormGroup row className="ml-20">
          {isOwner ? (
            <FormControlLabel control={<Checkbox defaultChecked name="Owner" />} label="Owner" disabled />
          ) : (
            <>
              {roles.map((role) => (
                <FormControlLabel
                  key={`${label}_${role}`}
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedRoles.includes(role)}
                      name={role}
                      onChange={onElevatedRoleChange}
                    />
                  }
                  label={getDisplayName(role)}
                />
              ))}
            </>
          )}
        </FormGroup>
      )}
    </>
  );
};

UsageRoleSelect.propTypes = {
  disabled: PropTypes.bool,
  isOwner: PropTypes.bool,
  orgRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRoles: PropTypes.arrayOf(PropTypes.string),
  usage: PropTypes.shape({ node: NodeShape, multipleGroups: PropTypes.bool }).isRequired,
  onElevatedRoleChange: PropTypes.func.isRequired,
  onDefaultRoleChange: PropTypes.func.isRequired,
};

UsageRoleSelect.defaultProps = {
  disabled: false,
  isOwner: false,
  selectedRoles: [],
};

export default UsageRoleSelect;
