import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import { Button, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { RelativeTime } from 'app/inspect/components';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

function cleanBody(html) {
  if (!html) {
    return null;
  }

  const domParser = new DOMParser();

  const doc = domParser.parseFromString(html, 'text/html');
  doc.body.removeChild(doc.body.lastElementChild); // Remove Footer
  doc.body.removeChild(doc.body.firstElementChild); // Remove IMG
  doc.body.firstElementChild.style.paddingBottom = null;
  doc.body.firstElementChild.style.paddingTop = null;

  return doc;
}

function Event({ event }) {
  const [showNotifications, setShowNotifications] = useState(false);

  return (
    <div className="flex flex-col items-start">
      <ListItemText
        primary={
          <div>
            <Typography className="mr-2 inline">{event.description}</Typography>{' '}
            <RelativeTime dateString={event.date} />
          </div>
        }
        secondary={
          event.notifications?.length ? (
            <Button color="primary" onClick={() => setShowNotifications(!showNotifications)}>
              {`${showNotifications ? 'Hide' : 'Show'} Notifications`}
            </Button>
          ) : null
        }
      />
      {showNotifications && event.notifications?.length ? (
        <div className="-mt-16 w-full">
          <FuseAnimateGroup
            enter={{
              animation: 'transition.slideUpBigIn',
            }}
            leave={{
              animation: 'transition.slideUpBigOut',
            }}>
            {event.notifications.map(({ email, notificationId }) => {
              const doc = cleanBody(email?.html);

              return (
                <ListItem className="m-8 flex flex-col items-start" divider disableGutters key={notificationId}>
                  <ListItemText
                    primary={`Subject: ${email.subject}`}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondary={`To:${email.to.map((to) => ` ${to}`)}`}
                    className="mb-8"
                  />
                  {/* eslint-disable-next-line react/no-danger */}
                  {doc && <div className="mb-8" dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />}
                  <div className="italic text-grey-200">
                    Sent <RelativeTime dateString={event.date} />
                  </div>
                </ListItem>
              );
            })}
          </FuseAnimateGroup>
        </div>
      ) : null}
    </div>
  );
}

Event.propTypes = {
  event: PropTypes.objectOf(PropTypes.any).isRequired,
};

function History({ events }) {
  return !events?.length ? (
    <Typography align="center" color="textSecondary">
      No History
    </Typography>
  ) : (
    <List>
      {events?.map((event) => (
        <Event key={`${event.date}-${event.description}`} event={event} />
      ))}
    </List>
  );
}

History.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        action: PropTypes.string,
        date: PropTypes.string,
        description: PropTypes.string,
        /* Notifications are optional currently used in Inspect-V2.
           Any user entered email content needs to be sanitized */
        notifications: PropTypes.arrayOf(
          PropTypes.shape({
            deliveryStatus: PropTypes.string,
            email: PropTypes.objectOf(PropTypes.any),
            notificationId: PropTypes.string,
          }),
        ),
      }),
      PropTypes.shape({
        details: PropTypes.object,
      }),
    ]),
  ).isRequired,
};

export default History;
