import { capitalize, get, toLower } from 'lodash';
import pluralize from 'pluralize';

import { libraryItemConfig } from '../library/shared/libraryItem.config';
import {
  getDisplayName,
  hasAnalyticsAdminRole,
  hasConfigManagerRole,
  hasClientAdminRole,
  hasRssDataAdminRole,
  hasRssDataAdminL2Role,
  hasServiceDeskRole,
  hasRssToolAccessToLabel,
} from '../shared/helper';
import { profileConfig } from '../shared/profile-config';

const orgTypes = Object.entries(profileConfig)
  .filter(([_, value]) => value.includeUnderRSSTools)
  .map(([key]) => key);

const libraryCatalogTypes = Object.entries(libraryItemConfig)
  .filter(([_, value]) => value.includeUnderRSSTools)
  .map(([key]) => key);

function hasPermission({ relationships } = {}, rolePermissions, orgType) {
  const orgConfig = profileConfig[orgType] || { rssToolAccess: [] };

  return (
    relationships && hasRssToolAccessToLabel(rolePermissions, relationships, orgType, orgConfig?.rssToolAccess ?? [])
  );
}

const nav = [
  {
    id: 'rss-tools',
    title: 'RSS Tools',
    type: 'collapse',
    icon: 'construction',
    dense: true,
    auth: ({ profile, rolePermissions } = {}) => {
      if (!profile || !rolePermissions) {
        return false;
      }

      const hasRssToolOrgAccess = orgTypes.some((type) => hasPermission(profile, rolePermissions, type));
      const hasRssToolLibraryAccess = libraryCatalogTypes.some((catalog) =>
        hasPermission(profile, rolePermissions, catalog),
      );

      const isAnalyticsAdmin = hasAnalyticsAdminRole(profile);
      const isServiceDesk = hasServiceDeskRole(profile);
      const isConfigManager = hasConfigManagerRole(profile);
      const isRssDataAdmin = hasRssDataAdminRole(profile);
      const isRssDataAdminL2 = hasRssDataAdminL2Role(profile);

      return (
        isAnalyticsAdmin ||
        isConfigManager ||
        isServiceDesk ||
        hasRssToolOrgAccess ||
        hasRssToolLibraryAccess ||
        isRssDataAdmin ||
        isRssDataAdminL2
      );
    },
    children: [
      ...orgTypes.map((orgType) => ({
        id: `rss-tools-${capitalize(pluralize(orgType))}`,
        title: `${pluralize(getDisplayName(orgType))}`,
        type: 'item',
        url: `/rss-tools/${toLower(pluralize(orgType))}`,
        auth: ({ profile, rolePermissions } = {}) => hasPermission(profile, rolePermissions, orgType),
      })),
      ...libraryCatalogTypes.map((catalog) => {
        const config = libraryItemConfig[catalog];
        return {
          id: `rss-tools-${capitalize(toLower(catalog))}`,
          title: `${config.header} Library`,
          type: 'item',
          url: `/rss-tools/library/${toLower(catalog)}`,
          auth: ({ profile, rolePermissions } = {}) => hasPermission(profile, rolePermissions, catalog),
        };
      }),
      {
        id: 'rss-tools-data-cleanup',
        title: 'Data Cleanup',
        type: 'item',
        url: '/rss-tools/data-cleanup',
        auth: ({ profile } = {}) => hasRssDataAdminRole(profile),
      },
      {
        id: 'rss-tools-data-cleanup-admin-l2',
        title: 'Data Cleanup Admin L2',
        type: 'item',
        url: '/rss-tools/data-cleanup-admin-l2',
        auth: ({ profile } = {}) => hasRssDataAdminL2Role(profile),
      },
      {
        id: 'rss-tools-locations',
        title: 'Locations',
        type: 'item',
        url: '/rss-tools/locations',
        auth: ({ profile } = {}) => hasServiceDeskRole(profile),
      },
      {
        id: `rss-tools-questionnaires`,
        title: 'Questionnaires',
        type: 'item',
        url: `/rss-tools/questionnaires`,
        auth: ({ profile } = {}) => hasConfigManagerRole(profile),
      },
      {
        id: `rss-tools-templates`,
        title: 'Questionnaire Templates',
        type: 'item',
        url: `/rss-tools/template`,
        auth: ({ profile } = {}) => hasConfigManagerRole(profile),
      },
      {
        id: 'rss-tools-roles',
        title: 'Roles',
        type: 'item',
        url: `/rss-tools/roles`,
        auth: ({ profile } = {}) => hasConfigManagerRole(profile) || hasServiceDeskRole(profile),
      },
      {
        id: 'rss-tools-users',
        title: 'Users',
        type: 'item',
        url: '/rss-tools/users',
        auth: ({ profile } = {}) => hasServiceDeskRole(profile),
      },
      {
        id: 'rss-tools-notification-template',
        title: 'Notification Template',
        type: 'item',
        url: '/rss-tools/notification-template',
        auth: ({ profile }) =>
          get(profile, 'features.platform.notificationTemplateV2.value', false) && hasConfigManagerRole(profile),
      },
      {
        id: 'rss-tools-legacy-studio',
        title: 'Legacy Studio',
        type: 'item',
        url: '/legacy-studio',
        auth: ({ profile }) =>
          get(profile, 'features.studio.showFeature.value', false) && hasConfigManagerRole(profile),
      },
      {
        id: 'rss-tools-clients',
        title: 'Clients',
        type: 'item',
        url: '/rss-tools/clients',
        auth: ({ profile }) => hasClientAdminRole(profile),
      },
      {
        id: 'rss-tools-analytics-roles',
        title: 'Analytics - Manage Roles',
        type: 'item',
        url: '/internal/analytics/manage-roles',
        auth: ({ profile }) => hasAnalyticsAdminRole(profile),
      },
      {
        id: 'rss-tools-analytics-artifacts',
        title: 'Analytics - Manage Artifacts',
        type: 'item',
        url: '/internal/analytics/manage-artifacts',
        auth: ({ profile }) => hasAnalyticsAdminRole(profile),
      },
    ].sort((a, b) => a.title.localeCompare(b.title)),
  },
];

export default nav;
