/* eslint-disable max-len */
import gql from 'graphql-tag';

import { CONTAINER_FRAGMENT, familyFields, FAMILY_COMMON_FIELDS, FAMILY_SEARCH_RESULT_FIELDS } from './fragment';

export const INVENTORY_FAMILY_DETAIL = gql`
  query LoadInventoryDetail($id: ID!, $campusCode: String, $inventoryId: String, $page: Int, $displayCount: Int, $barcode: ID, $sublocationId: ID, $roomId: ID, $sortCriteria: String) {
    family(id: $id, inventoryId: $inventoryId) {
      ${FAMILY_COMMON_FIELDS}
    }
  }
  ${familyFields}
  ${CONTAINER_FRAGMENT}
`;

export const FAMILY_SUBSTANCES = gql`
  query LoadFamilySubstances($id: ID!, $inventoryId: String) {
    family(id: $id, inventoryId: $inventoryId) {
      _id
      name
      substances {
        _id
        name
        vendor
        products {
          productNumber
        }
      }
    }
  }
`;

export const SEARCH_FAMILIES = gql`
  query SearchFamily($campusCode: String!, $keyword: String) {
    families(campusCode: $campusCode, keyword: $keyword) {
      ...familySearchResultFields
    }
  }
  ${FAMILY_SEARCH_RESULT_FIELDS}
`;

export const GET_CAMPUS_BANDS = gql`
  query getCampusBands($campusCode: [String]) {
    band(campusCode: $campusCode) {
      _id
      name
      class
      requireSop
      hiddenCampus
      category
    }
  }
`;

export const GET_CAMPUS_BAND_BY_ID = gql`
  query getCampusBandById($id: ID) {
    band(id: $id) {
      _id
      name
      class
      campus
      tenant
      family {
        _id
        name
      }
      cas
      ghs
      excludes {
        _id
        name
      }
      requireSop
    }
  }
`;
