/* eslint-disable react-hooks/rules-of-hooks */
import { lazy } from 'react';

const SdsDetailPage = lazy(() => import('./containers/SdsDetailPage'));
const SdsLinksPage = lazy(() => import('./containers/SdsLinksPage'));
const SdsPage = lazy(() => import('./containers/SdsPage'));
const SdsSearchPage = lazy(() => import('./containers/SdsSearchPage'));
const SdsDetail = lazy(() => import('./components/SdsDetail'));
const SdsDetailEdit = lazy(() => import('./components/SdsDetailEdit'));

const routes = {
  settings: {
    helpCategories: ['sds'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/sds/detail/:id?',
      component: SdsDetailPage,
      routes: [
        {
          path: ['/sds/detail/:id/edit', '/sds/detail/new'],
          component: SdsDetailEdit,
          exact: true,
        },
        {
          path: '/sds/detail/:id',
          component: SdsDetail,
          exact: true,
        },
      ],
    },
    {
      path: '/sds',
      exact: true,
      component: SdsPage,
    },
    {
      path: '/sds/search',
      component: SdsSearchPage,
    },
    {
      path: '/sds/links',
      component: SdsLinksPage,
    },
  ],
};

export default routes;
