"use strict";

module.exports = {
  CREATE: 'CREATE',
  DELETE: 'DELETE',
  ADD_ROOM: 'ADD-ROOM',
  REMOVE_ROOM: 'REMOVE-ROOM',
  REMOVE_BUILDING: 'REMOVE-BUILDING',
  ADD_PERSON: 'ADD-PERSON',
  RENAME: 'RENAME',
  REASSIGN: 'REASSIGN',
  ADD_ROLE: 'ADD-ROLE',
  REMOVE_ROLE: 'REMOVE-ROLE',
  REMOVE_PERSON: 'REMOVE-PERSON',
  ADD_TAG: 'ADD-TAG',
  REMOVE_TAG: 'REMOVE-TAG'
};