import { useLazyQuery } from '@apollo/client';
import FuseChipSelect from '@fuse/core/FuseChipSelect';
import { Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { SEARCH_DEPARTMENTS } from '../../graphql/relationship.query';
import Search from '../Search';
import { responseType } from './types/prop-types';

const DepartmentSearch = ({
  InputLabelProps,
  autoFocus,
  disabled,
  error,
  hintText,
  label,
  labelVariant,
  onChange,
  onClear,
  options,
  placeholder,
  questionKey,
  required,
  value,
}) => {
  const [search, { data, loading }] = useLazyQuery(SEARCH_DEPARTMENTS);
  const departments = (data?.searchDepartments || []).map((d) => ({ key: d.id, ...d }));

  return options && options.length ? (
    <FuseChipSelect
      autoFocus={autoFocus}
      isDisabled={disabled}
      required={required}
      error={error}
      aria-label={label}
      value={value}
      placeholder={placeholder}
      onChange={(val) => onChange(isEmpty(val) ? null : val, questionKey)}
      textFieldProps={{
        label: labelVariant === 'long' ? null : label,
        disabled,
        InputLabelProps: { shrink: true },
        variant: 'outlined',
      }}
      options={options}
      isMulti={false}
      variant="fixed"
      isSearchable={false}
      isClearable={!isEmpty(value)}
    />
  ) : (
    <Search
      autocomplete
      disabled={disabled}
      autoFocus={autoFocus}
      InputLabelProps={InputLabelProps}
      id={questionKey}
      isLoading={loading}
      items={departments}
      itemToString={(department) => `${department.name || department.label}`}
      label={label || ''}
      labelVariant={labelVariant}
      minSearchLength={1}
      onChange={({ inputValue }) => search({ variables: { searchTerm: inputValue } })}
      onClear={onClear}
      onSelect={(department) =>
        onChange({ label: department.name, value: department.code, id: department.id }, questionKey)
      }
      placeholder={hintText}
      renderItem={(department) => (
        <Typography variant="body1">
          {department.name} ({department.code})
        </Typography>
      )}
      value={value || ''}
      openMenuOnFocus={false}
    />
  );
};

DepartmentSearch.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hintText: PropTypes.string,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape(responseType)),
  placeholder: PropTypes.string,
  questionKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.shape(responseType),
  InputLabelProps: PropTypes.objectOf(PropTypes.any),
};

DepartmentSearch.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  hintText: 'Search for departments',
  label: '',
  labelVariant: 'default',
  onClear: () => {},
  options: [],
  placeholder: 'Select a department',
  required: false,
};

export default DepartmentSearch;
