import { useQuery } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { PERSON_SUMMARY } from '../graphql/profile.query';
import ErrorMessage from './errors/ErrorMessage';

const PersonContainer = ({ children, userId }) => {
  const { data = {}, error, loading } = useQuery(PERSON_SUMMARY, { variables: { id: userId }, skip: !userId });

  if (loading) return <LinearProgress />;
  if (error) return <ErrorMessage />;

  const { personSummary } = data;
  return children(personSummary || null);
};

PersonContainer.propTypes = {
  children: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

PersonContainer.defaultProps = {
  userId: null,
};

export default PersonContainer;
