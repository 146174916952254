/* eslint-disable react/jsx-props-no-spreading */
import { CircularProgress, Icon, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { useCombobox } from 'downshift';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FixedSizeList as List } from 'react-window';

import { DEBOUNCE_TIME } from '../config';

// NOTE: this search allows performant scrolling with thousands of menu items
// TODO: might want to replace original Search component with this once it's proven to work with users

// TODO: material-ui has autocomplete in lab -- should be in core by v5
// TODO: we might be able to remove downshift
function SearchV2({
  autocomplete,
  autoFocus,
  clearOnSelect,
  debounceTime,
  disabled,
  disableUnderline,
  id,
  isLoading,
  items,
  label,
  labelVariant,
  minSearchLength,
  placeholder,
  resultProps,
  value,
  variant,
  itemToString,
  renderItem,
  onChange,
  onClear,
  onSelect,
  openMenuOnFocus,
  ...props
}) {
  const onInputValueChange = debounce((event) => {
    if (event.inputValue.length >= minSearchLength) {
      onChange(event);
    }
  }, debounceTime);

  const { highlightedIndex, isOpen, getComboboxProps, getInputProps, getItemProps, getMenuProps, openMenu } =
    useCombobox({
      labelId: id,
      initialInputValue: value && itemToString ? itemToString(value) : '',
      items,
      itemToString: clearOnSelect || !itemToString ? () => '' : itemToString,
      onInputValueChange,
      onIsOpenChange: onInputValueChange,
      onSelectedItemChange: ({ selectedItem }) => onSelect(selectedItem),
    });

  const inputProps = getInputProps({
    onChange: onClear,
  });

  return (
    <div className="relative w-full">
      {isLoading && <CircularProgress size="2rem" className="absolute right-40 top-16" />}
      <TextField
        className="z-0"
        id={id || inputProps.id}
        type="search"
        variant={variant}
        autoComplete={autocomplete ? 'on' : 'off'}
        autoFocus={autoFocus}
        disabled={disabled}
        label={(labelVariant !== 'long' && label) || ''}
        fullWidth
        {...getComboboxProps()}
        inputProps={{
          ...inputProps,
          id: id || inputProps.id,
          'aria-label': label,
          'aria-labelledby': id || inputProps.id,
          placeholder,
          onFocus: openMenuOnFocus ? openMenu : null,
          onClick: autocomplete && !disabled ? openMenu : null,
          disableunderline: disableUnderline.toString(),
        }}
        startadornment={<Icon>search</Icon>}
        FormHelperTextProps={{ classes: { root: 'ml-0 text-14' } }}
        placeholder={placeholder}
        {...props}
      />
      <Paper
        square
        className="absolute left-0 z-10 max-h-512 w-full min-w-256 max-w-full overflow-y-scroll sm:max-h-256"
        {...getMenuProps()}>
        {isOpen && (
          <>
            {resultProps.title && (
              <Typography variant="subtitle2" className="px-16 pt-8">
                {resultProps.title}
              </Typography>
            )}
            {!items.length ? null : (
              <List itemCount={items.length} itemSize={33} height={256} width={'100%'}>
                {({ index, style }) => {
                  const item = items[index];
                  return (
                    <MenuItem
                      key={item.key}
                      selected={index === highlightedIndex}
                      {...getItemProps({
                        component: 'div',
                        style,
                        index,
                        item,
                      })}>
                      {renderItem ? renderItem(item) : itemToString(item)}
                    </MenuItem>
                  );
                }}
              </List>
            )}
          </>
        )}
      </Paper>
    </div>
  );
}

SearchV2.propTypes = {
  autocomplete: PropTypes.bool,
  autoFocus: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  debounceTime: PropTypes.number,
  disabled: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    }),
  ),
  label: PropTypes.string.isRequired,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  minSearchLength: PropTypes.number,
  placeholder: PropTypes.string,
  resultProps: PropTypes.shape({
    title: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.string]),
  variant: PropTypes.string,
  itemToString: PropTypes.func.isRequired,
  renderItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

SearchV2.defaultProps = {
  autocomplete: false,
  autoFocus: false,
  clearOnSelect: false,
  debounceTime: DEBOUNCE_TIME,
  disabled: false,
  disableUnderline: false,
  id: null,
  isLoading: false,
  items: [],
  minSearchLength: 2,
  placeholder: null,
  resultProps: {},
  value: '',
  openMenuOnFocus: true,
  variant: 'outlined',
  labelVariant: 'default',
  renderItem: null,
  onClear: () => null,
};

export default SearchV2;
