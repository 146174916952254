const clone = require('clone');
const tv4 = require('tv4');
class SubstanceIdentifier {
  constructor(opt) {
    this.beilsteinReference = null;
    this.cas = null;
    this.ecNumber = null;
    this.merckIndexReference = null;
    this.rtecsNumber = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    Object.keys(this).forEach(prop => {
      this[prop] = opt[prop] !== undefined ? opt[prop] : null;
    });
  }
  validate() {
    return tv4.validateMultiple(this, SubstanceIdentifier.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'substance-identifier.schema.json',
      type: 'object',
      properties: {
        beilsteinReference: {
          type: ['string', 'null']
        },
        cas: {
          type: ['string', 'null']
        },
        ecNumber: {
          type: ['string', 'null']
        },
        merckIndexReference: {
          type: ['string', 'null']
        },
        rtecsNumber: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = SubstanceIdentifier;