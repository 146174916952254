import { useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TextField,
  TableRow,
  Typography,
  TableContainer,
} from '@material-ui/core';
import { ObjectIdSchema } from '@rss/common';
import { CATALOG_TYPE, FundReferenceSchema, Store } from '@risk-and-safety/library';
import { ObjectId } from 'bson';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { STORE_WITH_INVENTORY, UPDATE_STORE, VALIDATE_FUND } from '../graphql/query';
import { ButtonProgress, PhoneFormat } from '../../components';
import FundValueEdit from './FundValueEdit';

const CATALOG_TYPE_OPTIONS = Object.keys(CATALOG_TYPE).filter((c) =>
  [CATALOG_TYPE.CHEMICAL, CATALOG_TYPE.SERVICES, CATALOG_TYPE.SUPPLIES].includes(c),
);

const SettingDetailEdit = ({ store }) => {
  const fundTypes = (store.settings.fund?.types || []).filter((t) => !t.includes('SPEED_TYPE'));
  const history = useHistory();

  const navigateBack = () => history.push(`/store/${store._id}/settings/detail`);

  const [updateStore, { loading }] = useMutation(UPDATE_STORE, {
    onCompleted: navigateBack,
  });

  const { refetch } = useQuery(VALIDATE_FUND, {
    skip: true,
    fetchPolicy: 'network-only',
  });

  const validateFund = async (context, form) => {
    const { type, value, validator } = form[context.path];
    const isFormValid = await yup
      .object({ value: FundReferenceSchema.ValueSchema(type) })
      .isValid({ ...form[context.path] });
    if (!isFormValid) {
      return true;
    }
    if (type && value) {
      const res = await refetch({ type, value, validator });
      if (res?.data?.validateFund.valid) {
        return true;
      }
      return context.createError({
        message: res?.data?.validateFund.error,
        path: context.path,
      });
    }
    return true;
  };

  const { control, getValues, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      _id: store._id,
      hours: {
        from: store.hours?.from || '',
        to: store.hours?.to || '',
      },
      description: store.description || '',
      name: store.name || '',
      catalogs: store.catalogs || [],
      salesTax: (store.salesTax || 0.0) * 100,
      address: store.address || '',
      email: store.email || '',
      phone: store.phone || '',
      settings: {
        delivery: store.settings?.delivery || false,
        pickup: store.settings?.pickup || false,
        selfCheckout: store.settings?.selfCheckout || false,
        enableStoreFront: store.settings?.enableStoreFront || false,
      },
      receivingFund: store.receivingFund || { type: null, validator: store.settings.fund.validator },
      subsidyFund: store.subsidyFund || { type: null, validator: store.settings.fund.validator },
    },
    resolver: yupResolver(
      yup.object({
        _id: new ObjectIdSchema().default(() => new ObjectId()).required(),
        name: yup.string().trim().min(1, 'Name is Required').required(),
        description: yup.string().trim().default(null).nullable().defined(),
        hours: yup
          .object({
            from: yup.string().default(null).nullable().defined(),
            to: yup.string().default(null).nullable().defined(),
          })
          .required(),
        catalogs: yup
          .array()
          .of(
            yup.object({
              value: yup.string().default(CATALOG_TYPE.CHEMICAL).required(),
              purposeCode: yup.object({
                value: yup.string().default(null).nullable().defined(),
              }),
            }),
          )
          .min(1),
        salesTax: yup.number().min(0).default(0).defined(),
        phone: yup.string().default(null).nullable().defined(),
        email: yup.string().default(null).nullable().defined(),
        receivingFund: yup
          .object()
          .default(null)
          .nullable()
          .shape({
            type: yup.string().nullable(),
            value: yup.object().when('type', (type) => {
              return FundReferenceSchema.ValueSchema(type || store.receivingFund?.type);
            }),
          })
          .test('validate-fund', null, async (value, context) => validateFund(context, getValues())),
        subsidyFund: yup
          .object()
          .default(null)
          .nullable()
          .shape({
            type: yup.string().nullable(),
            value: yup.object().when('type', (type) => {
              return FundReferenceSchema.ValueSchema(type || store.subsidyFund?.type);
            }),
          })
          .test('validate-fund', null, async (value, context) => validateFund(context, getValues())),
      }),
    ),
  });
  const { dirtyFields, errors } = useFormState({ control });

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(async (value) => {
        const fields = Object.keys(dirtyFields);
        if (fields?.length) {
          const storeValue = fields?.reduce((acc, curr) => {
            acc[curr] = value[curr];
            if (curr === 'salesTax') {
              acc[curr] = value[curr] / 100;
            }

            return acc;
          }, {});
          updateStore({
            variables: { store: { _id: store._id, ...storeValue } },
            refetchQueries: [{ query: STORE_WITH_INVENTORY, variables: { id: store._id } }],
          });
        }
      })}>
      <div className="flex items-center pb-16">
        <Icon color="action">info</Icon>
        <Typography className="h2 mx-12 font-medium" color="textSecondary">
          General
        </Typography>
      </div>
      <div className="mb-48 ml-20">
        <div className="absolute left-48 top-136 flex h-64 items-center">
          <IconButton onClick={navigateBack}>
            <Icon>arrow_back</Icon>
          </IconButton>
        </div>
        <div className="absolute right-48 top-136 flex h-64 items-center">
          <ButtonProgress loading={loading} type="submit" variant="contained" color="primary">
            Save
          </ButtonProgress>
        </div>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              className="mb-16 mt-8"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16 mt-8"
              multiline
              rows={4}
              label="Description"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <div className="-mx-4 flex">
          <Controller
            name="hours.from"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Open"
                type="time"
                value={value ? format(new Date(value), 'HH:mm') : value}
                onChange={(e) => e.target.value && onChange(new Date(`2020-01-01T${e.target.value}`))}
                error={!!errors['hours.from']}
                helperText={errors['hours.from']?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-4 mb-16 mt-8"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Controller
            name="hours.to"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Close"
                type="time"
                value={value ? format(new Date(value), 'HH:mm') : value}
                onChange={(e) => e.target.value && onChange(new Date(`2020-01-01T${e.target.value}`))}
                error={!!errors['hours.to']}
                helperText={errors['hours.to']?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                className="mx-4 mb-16 mt-8"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </div>

        <Controller
          name="catalogs"
          control={control}
          render={({ field: { onChange, value: catalogs } }) => {
            return (
              <div className="mb-32 mt-16">
                <div className="flex items-center justify-between">
                  <Typography className="mx-12 text-20 font-light" color="textSecondary">
                    Catalogs
                  </Typography>

                  {catalogs.length < CATALOG_TYPE_OPTIONS.length && (
                    <IconButton
                      color="primary"
                      onClick={() =>
                        onChange(catalogs.concat({ value: '', purposeCode: { value: '' }, markupRate: 0 }))
                      }>
                      <Icon>add</Icon>
                    </IconButton>
                  )}
                </div>
                <Typography className="mx-12 mb-12" color="textSecondary">
                  When a store manager adds a Purpose Code to a catalog, this value is populated into the shopper’s fund
                  account used at purchase to help describe the nature of the transaction to your organization’s
                  financial system.
                </Typography>
                <TableContainer component={Paper}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>Catalog</TableCell>
                        <TableCell>Purpose code</TableCell>
                        <TableCell>Markup Percent</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {catalogs.map((catalog, index) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <TableRow key={`${catalog.value}-${index}`}>
                            <TableCell>
                              <FormControl className="w-full" error={!!(errors?.catalogs || [])[index]} required>
                                <Select
                                  fullWidth
                                  value={catalog.value}
                                  onChange={({ target: { value } }) =>
                                    onChange(
                                      catalogs.reduce(
                                        (acc, curr, currIndex) =>
                                          acc.concat({
                                            ...curr,
                                            value: index === currIndex ? value : curr.value,
                                          }),
                                        [],
                                      ),
                                    )
                                  }>
                                  {CATALOG_TYPE_OPTIONS.filter(
                                    (t) => t === catalog.value || !catalogs.map((c) => c.value).includes(t),
                                  ).map((t) => (
                                    <MenuItem value={t} key={t}>
                                      {t}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {(errors?.catalogs || [])[index] && (
                                  <FormHelperText>Catalog value is required</FormHelperText>
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <TextField
                                key={catalog.value}
                                value={catalog?.purposeCode?.value || ''}
                                onChange={({ target: { value } }) =>
                                  onChange(
                                    catalogs.reduce(
                                      (acc, curr, currIndex) =>
                                        acc.concat({
                                          ...curr,
                                          purposeCode: { value: index === currIndex ? value : curr.purposeCode.value },
                                        }),
                                      [],
                                    ),
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                key={catalog.value}
                                type="number"
                                InputProps={{
                                  inputProps: { min: 0 },
                                }}
                                value={(catalog?.markupRate || 0) * 100}
                                onChange={({ target: { value } }) =>
                                  onChange(
                                    catalogs.reduce(
                                      (acc, curr, currIndex) =>
                                        acc.concat({
                                          ...curr,
                                          markupRate: index === currIndex ? parseFloat(value / 100) : curr.markupRate,
                                        }),
                                      [],
                                    ),
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                fullWidth
                              />
                            </TableCell>
                            <TableCell align="right">
                              {catalogs.length > 1 && (
                                <IconButton
                                  onClick={() =>
                                    onChange(
                                      catalogs.reduce((acc, curr, currIndex) => {
                                        if (index !== currIndex) {
                                          return acc.concat(curr);
                                        }
                                        return acc;
                                      }, []),
                                    )
                                  }>
                                  <Icon>delete</Icon>
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            );
          }}
        />

        <div>
          <Controller
            name="salesTax"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                step=".01"
                className="mb-16 mt-8 w-1/3"
                variant="outlined"
                label="Sales Tax"
                error={!!errors?.salesTax}
                helperText={errors?.salesTax?.message}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            )}
          />
        </div>

        <Controller
          name="settings.enableStoreFront"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
              label="Enable Storefront"
            />
          )}
        />
        <Typography className="pt-4">
          When enabled, users associated to accounts will be able to place an order.
        </Typography>
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">monetization_on</Icon>
        <Typography className="h2 mx-12 font-light" color="textSecondary">
          Order Settings
        </Typography>
      </div>

      <div className="mb-48 ml-20">
        <Controller
          name="settings.delivery"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
              label="Allow Delivery"
            />
          )}
        />
        <Typography className="pb-24 pt-4">
          When enabled, users can select delivery when placing orders and will specify a dropoff location.
        </Typography>

        <Controller
          name="settings.pickup"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
              label="Allow Pickup"
            />
          )}
        />
        <Typography className="pb-24 pt-4">
          When enabled, users can select pick up when placing orders and will receive a notification once the order is
          ready for pickup at the store front.
        </Typography>

        <Controller
          name="settings.selfCheckout"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={(ev) => onChange(ev.target.checked)} color="primary" />}
              label="Allow Self Checkout"
            />
          )}
        />
        <Typography className="pb-24 pt-4">
          When enabled, users associated to accounts are able to make purchases at a dedicated self-checkout station.
          Use the generated URL for the self-checkout station.
        </Typography>
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">monetization_on</Icon>
        <Typography className="h2 mx-12 font-medium" color="textSecondary">
          Fund
        </Typography>
      </div>
      <div className="mb-48 ml-20">
        <div className="-mx-4 mb-16 flex flex-col">
          <Controller
            name="receivingFund.type"
            control={control}
            render={({ field }) => (
              <FormControl
                className="mx-4 mb-16 mt-8"
                variant="outlined"
                required
                error={!!errors?.receivingFund?.type}
                fullWidth>
                <InputLabel id="type-label">Fund Type</InputLabel>
                <Select {...field} labelId="type-label" label="Destination Fund Type">
                  {fundTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type.replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="receivingFund.value"
            control={control}
            render={({ field }) => {
              const receivingFund = getValues('receivingFund');
              return (
                <>
                  <FundValueEdit
                    value={receivingFund.value}
                    type={receivingFund.type}
                    field={field}
                    errors={errors.receivingFund}
                  />
                  {!!errors?.receivingFund?.type && (
                    <FormHelperText className="text-red-500">Fund is not valid</FormHelperText>
                  )}
                </>
              );
            }}
          />
        </div>

        <div className="-mx-4 flex flex-col">
          <Controller
            name="subsidyFund.type"
            control={control}
            render={({ field }) => (
              <FormControl
                className="mx-4 mb-16 mt-8"
                variant="outlined"
                required
                error={!!errors?.subsidyFund?.type}
                fullWidth>
                <InputLabel id="type-label">Subsidy Fund Type</InputLabel>
                <Select {...field} labelId="type-label" label="Destination Fund Type">
                  <MenuItem value={null} key={'None'}>
                    No Subsidy
                  </MenuItem>
                  {fundTypes.map((type) => (
                    <MenuItem value={type} key={type}>
                      {type.replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="subsidyFund.value"
            control={control}
            render={({ field }) => {
              const subsidyFund = getValues('subsidyFund');
              return (
                <>
                  <FundValueEdit
                    value={subsidyFund.value}
                    type={subsidyFund.type}
                    field={field}
                    errors={errors.subsidyFund}
                  />
                  {!!errors?.subsidyFund?.type && (
                    <FormHelperText className="text-red-500">Fund is not valid</FormHelperText>
                  )}
                </>
              );
            }}
          />
        </div>
      </div>

      <div className="flex items-center pb-16">
        <Icon color="action">account_circle</Icon>
        <Typography className="h2 mx-12 font-medium" color="textSecondary">
          Contact
        </Typography>
      </div>
      <div className="mb-48 ml-20">
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16 mt-8"
              label="Address"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
            />
          )}
        />

        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16 mt-8"
              label="Telephone"
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: PhoneFormat,
              }}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField {...field} className="mb-16 mt-8" label="Email" variant="outlined" fullWidth />
          )}
        />
      </div>
    </form>
  );
};

SettingDetailEdit.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default SettingDetailEdit;
