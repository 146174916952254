import {
  Box,
  Divider,
  Grid,
  Icon,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';
import { DateHelper, SHORT_DATE_FORMAT } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

const RETURN_URL_KEY = 'LEARNING_RETURN_URL';
const styles = (theme) => ({
  header: {
    marginTop: 25,
    marginBottom: 10,
  },
  displayPrint: {
    display: 'none',
  },
  launchIcon: {
    marginRight: -42,
  },
  list: {
    width: '100%',
  },
  tableHeader: {
    marginBottom: -15,
  },
  dateColumn: {
    marginLeft: '-4px',
  },
  '@media print': {
    hidePrint: {
      display: 'none !important',
    },
  },
});

// TODO: Need to work on fuse
const Training = ({ classes, title, trainings }) => {
  const navigate = (link) => {
    sessionStorage.setItem(RETURN_URL_KEY, window.location);
    window.location = link;
  };
  return (
    <div>
      <Typography variant="subtitle1" className={classes.header}>
        {title}
      </Typography>
      <Typography variant="body1" className="my-12">
        If the course listed below is incomplete, select the course to get started. Once completed, you can act on any
        additional next steps listed on this page.
      </Typography>
      {trainings.length ? (
        <Grid container lg={6} md={8} sm={12} item direction="column">
          <Box display={{ xs: 'none', md: 'inline' }} displayPrint="none" className={classes.tableHeader}>
            <Grid container item direction="row">
              <Grid item md={5}>
                <Typography variant="body2">Course Name</Typography>
              </Grid>
              <Grid item md={4}>
                <Typography variant="body2" className={classes.dateColumn}>
                  Completed Date
                </Typography>
              </Grid>
              <Grid item md={3}>
                <Typography variant="body2" className={classes.dateColumn}>
                  Expiration Date
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <List className={classes.list}>
            <Divider />
            <Grid container item direction="column" alignItems="center">
              {trainings.map((training) => (
                <ListItem key={training.id} onClick={() => navigate(training.link)} disableGutters divider button>
                  <Grid container item direction="row" alignItems="center">
                    <Grid item md={5} xs={12}>
                      <ListItemText
                        primary={
                          <Link underline="none" component="button" to={training.link} variant="body1">
                            {training.name}
                          </Link>
                        }
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      {training.completionDate && (
                        <Typography variant="body2" component={(props) => <div>{props.children}</div>}>
                          <Box display={{ xs: 'inline', md: 'none' }} displayPrint="inline">
                            Completed on:{' '}
                          </Box>
                          {training?.completionDate ? (
                            DateHelper.formatDate(training.completionDate, SHORT_DATE_FORMAT)
                          ) : (
                            <Icon color="action">remove</Icon>
                          )}
                        </Typography>
                      )}
                      {!training.completionDate && (
                        <Typography
                          variant="body2"
                          component={(props) => <div style={{ color: 'red' }}>{props.children}</div>}>
                          <Box display={{ xs: 'inline', md: 'none' }} displayPrint="inline">
                            Status:{' '}
                          </Box>
                          Incomplete
                        </Typography>
                      )}
                    </Grid>
                    <Grid item md={2} xs={12}>
                      {training.expiresDate && (
                        <Typography variant="body2" component={(props) => <div>{props.children}</div>} noWrap>
                          <Box display={{ xs: 'inline', md: 'none' }} displayPrint="inline">
                            Expires on:{' '}
                          </Box>
                          {training.expiresDate ? (
                            DateHelper.formatDate(training.expiresDate, SHORT_DATE_FORMAT)
                          ) : (
                            <Icon color="action">remove</Icon>
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <ListItemSecondaryAction>
                      <ListItemIcon className={`${classes.hidePrint} ${classes.launchIcon}`}>
                        <Icon color="primary">launch</Icon>
                      </ListItemIcon>
                    </ListItemSecondaryAction>
                  </Grid>
                </ListItem>
              ))}
            </Grid>
          </List>
        </Grid>
      ) : (
        <Typography variant="body1">You have no training.</Typography>
      )}
    </div>
  );
};

Training.propTypes = {
  children: PropTypes.node.isRequired,
  trainings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      completionDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]),
      expiredDate: PropTypes.string,
    }),
  ).isRequired,
  title: PropTypes.string,
};

Training.defaultProps = {
  title: 'Training',
};

export default withStyles(styles)(Training);
