"use strict";

module.exports = {
  dangerousGoodsDivision: ['Division 1.1', 'Division 1.2', 'Division 1.3', 'Division 1.4', 'Division 1.4G', 'Division 1.5', 'Division 1.6', 'Division 2.1', 'Division 2.2', 'Division 2.3', 'Division 3', 'Division 4.1', 'Division 4.2', 'Division 4.3', 'Division 5.1', 'Division 5.2', 'Division 6.1', 'Division 6.2', 'Division 7', 'Division 8', 'Division 9'],
  flammabilityLimit: [{
    label: '%',
    value: '%'
  }],
  heatOfMixing: [{
    label: 'kJ/mol',
    value: 'kJ/mol'
  }, {
    label: 'kcal/mol',
    value: 'kcal/mol'
  }, {
    label: 'kJ/g',
    value: 'kJ/g'
  }, {
    label: 'kcal/g',
    value: 'kcal/g'
  }],
  instantaneousPowerDensity: [{
    label: 'W/mL',
    value: 'W/mL'
  }],
  maximumMassLossRate: [{
    label: 'g/s',
    value: 'g/s'
  }],
  molecularWeight: [{
    label: 'g/mol',
    value: 'g/mol'
  }, {
    label: 'Da',
    value: 'Da'
  }, {
    label: 'kDa',
    value: 'kDa'
  }],
  particleDensity: [{
    label: 'g/cm3',
    value: 'g/cm3'
  }],
  peakConvectionHeatRelease: [{
    label: 'kW',
    value: 'kW'
  }],
  rateOfBurn: [{
    label: 'mm/sec',
    value: 'mm/sec'
  }, {
    label: 'in/sec',
    value: 'in/sec'
  }],
  rateOfEvolutionOfFlammableGas: [{
    label: 'L/kg',
    value: 'L/kg'
  }],
  skinCorrosion: ['irritation', 'damage']
};