"use strict";

module.exports = [{
  label: 'Inventory',
  value: 'myLab'
}, {
  label: 'Colleagues',
  value: 'colleagues'
}, {
  label: 'Campus',
  value: 'campus'
}];