import { Icon, Input, Paper, ThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { selectMainTheme } from '../../store/fuse/settingsSlice';

const SearchBar = ({ classes, dense, onSearchTermChange }) => {
  const mainTheme = useSelector(selectMainTheme);
  return (
    <ThemeProvider theme={mainTheme}>
      <Paper className={clsx('flex w-full max-w-lg flex-row', classes.paper)} elevation={1}>
        <Icon color="action" className={dense ? 'mx-8 my-12' : 'mx-12 my-8 sm:my-12'}>
          search
        </Icon>
        <Input
          placeholder="Search support"
          autoFocus
          disableUnderline
          fullWidth
          inputProps={{
            'aria-label': 'Search Support',
          }}
          onChange={({ target }) => onSearchTermChange(target.value)}
        />
      </Paper>
    </ThemeProvider>
  );
};

SearchBar.propTypes = {
  classes: PropTypes.shape({ paper: PropTypes.string }),
  dense: PropTypes.bool,
  onSearchTermChange: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  classes: {
    paper: '',
  },
  dense: false,
};

export default SearchBar;
