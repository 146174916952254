const {
  PersonReference
} = require('@rss/common');
const clone = require('clone');
const tv4 = require('tv4');
const InventoryReference = require('./inventory-reference');
class Transfer {
  constructor(opt) {
    this.name = null;
    this.targetInventory = null;
    this.initiatedBy = null;
    this.suggestedLocation = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.name = opt.name || null;
    this.targetInventory = opt.targetInventory ? new InventoryReference(opt.targetInventory) : null;
    this.initiatedBy = opt.initiatedBy ? new PersonReference(opt.initiatedBy) : null;
    this.suggestedLocation = opt.suggestedLocation || null;
  }
  validate() {
    return tv4.validateMultiple(this, Transfer.schema());
  }
  static schema() {
    const queue = new Transfer();
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'transfer.schema.json',
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        targetInventory: {
          anyOf: [{
            $ref: 'inventory-reference.schema.json'
          }, {
            type: ['null']
          }]
        },
        initiatedBy: {
          $ref: 'person-reference.schema.json'
        },
        suggestedLocation: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(queue)
    };
  }
}
tv4.addSchema(InventoryReference.schema());
tv4.addSchema(PersonReference.schema());
module.exports = Transfer;