const ObjectId = require('bson').ObjectID;
const tv4 = require('tv4');
class InventoryReference {
  constructor(opt) {
    this._id = null;
    this.tenantCode = '';
    this.campusCode = '';
    this.name = '';
    this.stockroom = false;
    if (opt) {
      this.fillObject(opt);
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.tenantCode = opt.tenantCode;
    this.campusCode = opt.campusCode;
    this.name = opt.name;
    this.stockroom = !!opt.stockroom;
  }
  validate() {
    return tv4.validateMultiple(this, InventoryReference.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'inventory-reference.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        tenantCode: {
          type: 'string'
        },
        campusCode: {
          type: 'string'
        },
        name: {
          type: 'string'
        },
        stockroom: {
          type: 'boolean'
        }
      },
      required: ['_id', 'name', 'tenantCode', 'campusCode']
    };
  }
}
module.exports = InventoryReference;