/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

function PhoneFormat(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="Phone #"
      guide={false}
    />
  );
}

PhoneFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default PhoneFormat;
