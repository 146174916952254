import { FqType } from '@rss/common';

export const BucketTemplate = [
  {
    type: FqType.INPUT,
    key: 'name',
    label: 'Name',
    required: true,
    disabled: true,
    className: 'w-full',
  },
  {
    type: FqType.INPUT,
    key: 'description',
    label: 'Description',
    required: false,
    disabled: true,
    className: 'w-full',
  },
  {
    type: FqType.INPUT,
    key: 'type',
    label: 'Type',
    required: false,
    disabled: true,
    className: 'w-full',
  },
];
