const clone = require('clone');
const tv4 = require('tv4');
class Ghs {
  constructor(opt) {
    this.hazardCode = null;
    this.hazardClass = null;
    this.category = null;
    this.hazardStatement = null;
    this.signalWord = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    Object.keys(this).forEach(prop => {
      this[prop] = opt[prop] || null;
    });
  }
  validate() {
    return tv4.validateMultiple(this, Ghs.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'ghs.schema.json',
      type: 'object',
      properties: {
        hazardCode: {
          type: 'string'
        },
        hazardClass: {
          type: ['string', 'null']
        },
        category: {
          type: ['string', 'null']
        },
        hazardStatement: {
          type: ['string', 'null']
        },
        signalWord: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = Ghs;