/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-boolean-value */
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Icon,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import { CONTAINER_TYPE } from '@risk-and-safety/chemical';
import { CATALOG_TYPE, STORE_PERMISSION } from '@risk-and-safety/library';
import { debounce, startCase } from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import ImageGallery from 'react-image-gallery';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ADD_PRODUCT_IMAGE, PRODUCT_IMAGE_PRESIGNED_URL, REMOVE_PRODUCT, REMOVE_PRODUCT_IMAGE } from '../graphql/query';
import { ButtonProgress } from '../../components';
import config from '../../config';
import { closeDialog, openDialog } from '../../store/fuse/dialogSlice';

function ProductDetail({ store, product, enableShoppingOption, enableEditOption, onAddToCart: _onAddToCart }) {
  const history = useHistory();
  const { productId, storeId } = useParams();
  const [openFlyout, setOpenFlyout] = useState(false);

  const dispatch = useDispatch();
  const cart = useSelector(({ app }) => app.shoppingCart && app.shoppingCart[storeId]);

  const [quantity, setQuantity] = useState('1');
  const galleryRef = useRef();

  const { refetch } = useQuery(PRODUCT_IMAGE_PRESIGNED_URL, { skip: true, fetchPolicy: 'network-only' });
  const [addProductImage] = useMutation(ADD_PRODUCT_IMAGE);
  const [removeProductImage, { loading: removeProductImageLoading }] = useMutation(REMOVE_PRODUCT_IMAGE, {
    onCompleted: () => dispatch(closeDialog()),
  });
  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    onCompleted: () => history.push(`/store/${storeId}/product`),
  });

  const hasEditAccess = () => enableEditOption && store?.permissions?.includes(STORE_PERMISSION.MANAGE);

  const onDrop = useCallback(async (files) => {
    files.reduce(async (acc, file) => {
      const { data } = await refetch({ storeId: store._id, fileName: file.name });
      const formData = new FormData();
      Object.keys(data.productImagePresignedUrl.fields).forEach((key) => {
        formData.append(key, data.productImagePresignedUrl.fields[key]);
      });

      formData.append('Content-Type', file.type);
      formData.append('file', file);

      await fetch(data.productImagePresignedUrl.url, {
        method: 'POST',
        body: formData,
      });

      await addProductImage({
        variables: {
          productId,
          url: `https://${config.RSS_APP_BUCKET}.s3.us-west-2.amazonaws.com/${data.productImagePresignedUrl.fields.key}`,
          fileName: file.name,
        },
      });
    }, 0);
  }, []);

  const onAddToCart = useCallback(debounce(_onAddToCart, 200), [_onAddToCart]);

  const { getRootProps, getInputProps, isDragReject } = useDropzone({ onDrop, accept: 'image/*' });

  const itemsInCart = cart?.items?.find((i) => i._id === productId)?.quantity || 0;
  const stockAvailable = product?.availableStock - itemsInCart;

  const images = [
    ...(product.images || []).map((image) => ({
      original: image,
      thumbnail: image,
      thumbnailClass: 'w-48',
    })),
  ];

  const customControl = () => {
    return hasEditAccess() && images.length > 0 ? (
      <div className="absolute inset-x-0 bottom-0 z-10 flex h-48 flex-row-reverse items-center bg-gray-400 bg-opacity-60">
        <IconButton
          onClick={async () => {
            dispatch(
              openDialog({
                children: (
                  <>
                    <DialogContent>
                      <DialogContentText component="div" className="mt-10">
                        Are you sure you want to remove this image?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        color="default"
                        autoFocus
                        onClick={() => dispatch(closeDialog())}
                        className="mb-10 mr-10">
                        Cancel
                      </Button>
                      <ButtonProgress
                        loading={removeProductImageLoading}
                        onClick={() => {
                          removeProductImage({
                            variables: {
                              productId,
                              url: images[galleryRef.current.getCurrentIndex()].original,
                            },
                          });
                        }}
                        variant="contained"
                        color="secondary"
                        className="mb-10 mr-16">
                        Remove
                      </ButtonProgress>
                    </DialogActions>
                  </>
                ),
              }),
            );
          }}
          className="text-white">
          <Icon>delete_outline</Icon>
        </IconButton>
      </div>
    ) : (
      <></>
    );
  };

  const MENU_ITEMS = [
    {
      label: 'Edit',
      icon: 'edit',
      onClick: () => history.push(`/store/${storeId}/product/${productId}/detail/edit`),
    },
  ];

  const removeProd = async () => {
    await removeProduct({
      variables: { productId },
    });
  };

  if (product.catalog !== CATALOG_TYPE.SERVICES && !product.stock?.length) {
    MENU_ITEMS.push({
      label: 'Delete Product',
      icon: 'delete',
      onClick: removeProd,
    });
  }

  return (
    <div className="flex">
      <div className="w-512 flex-none">
        <div className="">
          {images.length === 0 && (
            <img
              alt="Product"
              src={`http://${config.RSS_APP_BUCKET}.s3.us-west-2.amazonaws.com/library/public/no-product.png`}
            />
          )}

          <ImageGallery
            ref={galleryRef}
            showNav={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showThumbnails={images.length > 1}
            items={images}
            renderCustomControls={customControl}
            className="h-136"
          />
        </div>

        {hasEditAccess() && (
          <div
            {...getRootProps()}
            className="mt-40 flex flex-col items-center justify-center border-2 border-dashed bg-green-50 py-28">
            <Icon className="text-28 text-gray-200">backup_outlined</Icon>
            <input {...getInputProps()} />

            {!isDragReject ? (
              <Typography className="m-6 text-16 font-light" color="textSecondary">
                Drop file to attach or select to browse
              </Typography>
            ) : (
              <Typography className="m-6 text-16 font-light" color="error">
                Some files will be rejected
              </Typography>
            )}
          </div>
        )}
      </div>
      <div className="max-w-640 flex-1 py-16 pl-48">
        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Name</Typography>
          <Typography id="product-name">{product?.name}</Typography>
        </div>
        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Description</Typography>
          <Typography>{product?.description}</Typography>
        </div>

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Product Number</Typography>
          <Typography>{product?.productNumber}</Typography>
        </div>

        {enableShoppingOption && (
          <div className="mb-16">
            <Typography className="mb-4 text-15 font-semibold">Price</Typography>
            <Typography>
              {numeral(
                product?.price +
                  product?.price * (store.catalogs.find((c) => c.value === product.catalog)?.markupRate || 0),
              ).format('$0,0.00')}
              {` + ${numeral(product?.fee).format('$0,0.00')} fee`}
            </Typography>
          </div>
        )}

        {!enableShoppingOption && (
          <>
            <div className="mb-16">
              <Typography className="mb-4 text-15 font-semibold">SKU</Typography>
              <Typography>{product?.sku}</Typography>
            </div>
            <div className="mb-16">
              <Typography className="mb-4 text-15 font-semibold">Price</Typography>
              <Typography>
                {numeral(product?.price).format('$0,0.00')}
                {store.catalogs.find((c) => c.value === product.catalog)?.markupRate > 0 &&
                  ` + ${numeral(
                    product?.price * store.catalogs.find((c) => c.value === product.catalog)?.markupRate,
                  ).format('$0,0.00')} markup rate`}
              </Typography>
            </div>
            {store.subsidyFund?.type && (
              <div className="mb-16">
                <Typography className="mb-4 text-15 font-semibold">Subsidy</Typography>
                <Typography>{numeral(product?.subsidy).format('$0,0.00')}</Typography>
              </div>
            )}
            {product.catalog !== CATALOG_TYPE.SERVICES && (
              <div className="mb-16">
                <Typography className="mb-4 text-15 font-semibold">Low stock threshold</Typography>
                <Typography>{product?.lowStockThreshold || 'N/A'}</Typography>
              </div>
            )}
          </>
        )}

        {product?.taxExempt && (
          <div className="mb-16">
            <Typography className="mb-4 text-15 font-semibold">Tax Exempt</Typography>
            <Typography>Exempt</Typography>
          </div>
        )}

        <div className="mb-16">
          <Typography className="mb-4 text-15 font-semibold">Catalog</Typography>
          <Typography>{startCase(product?.catalog?.toLowerCase())}</Typography>
        </div>

        {product?.library?._id && (
          <div className="mb-16">
            <Typography className="mb-4 text-15 font-semibold">
              {startCase(product?.catalog?.toLowerCase())} Name
            </Typography>
            <Typography>{product?.library?.name || 'N/A'}</Typography>
          </div>
        )}

        {product?.catalog === CATALOG_TYPE.CHEMICAL && (
          <>
            <div className="mb-16">
              <Typography className="mb-4 text-15 font-semibold">Container Size</Typography>
              <Typography>{`${product?.details?.size || ''}${product?.details?.units || ''} ${
                CONTAINER_TYPE.find((item) => item.value === product?.details?.type)?.label || ''
              }`}</Typography>
            </div>

            <div className="mb-16">
              <Typography className="mb-4 text-15 font-semibold">Physical State</Typography>
              <Typography>{product?.library?.physicalState}</Typography>
            </div>

            <div className="mb-16">
              <Typography className="mb-4 text-15 font-semibold">CAS</Typography>
              <Typography>{product?.library?.cas}</Typography>
            </div>
          </>
        )}

        {!product?.availableForSale && (
          <>
            <Divider className="my-60" />
            <Typography className="text-18 text-danger-400">Not available for sale</Typography>
          </>
        )}

        {enableShoppingOption && (
          <>
            <Divider className="my-60" />

            {product.catalog === 'SERVICES' ? (
              <>
                {store.settings.pickup && (
                  <div className="flex items-center">
                    <Button
                      onClick={() => onAddToCart(quantity)}
                      variant="contained"
                      color="primary"
                      size="large"
                      className="w-200">
                      Add to Cart - {numeral(quantity * product.price).format('$0,0.00')}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {stockAvailable > 0 && (store.settings.delivery || store.settings.pickup) && (
                  <>
                    <Typography className="my-4 text-18">Quantity</Typography>
                    <div className="item-center flex space-x-48">
                      <div className="w-128">
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            label="Quantity"
                            disabled={stockAvailable < 1}
                            value={quantity}
                            onChange={(evt) => setQuantity(evt.target.value)}
                            className="h-44 w-136">
                            {[...Array(stockAvailable).keys()].slice(0, 10).map((option) => {
                              const qty = option + 1;
                              return (
                                <MenuItem key={qty} value={qty}>
                                  {qty}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="flex items-center">
                        <Button
                          onClick={() => onAddToCart(quantity)}
                          disabled={stockAvailable < 1}
                          variant="contained"
                          color="primary"
                          size="large"
                          className="w-200">
                          Add to Cart - {numeral(quantity * product.price).format('$0,0.00')}
                        </Button>
                      </div>
                    </div>
                  </>
                )}

                {stockAvailable <= 0 && (
                  <Typography className="text-18  text-danger-400">Temporarily out of stock</Typography>
                )}
              </>
            )}
          </>
        )}
      </div>

      {hasEditAccess() && (
        <Tooltip title="Edit" arrow>
          <ClickAwayListener onClickAway={() => setOpenFlyout(false)}>
            <SpeedDial
              open={openFlyout}
              ariaLabel="Product Options"
              className="absolute bottom-8 right-8"
              icon={<SpeedDialIcon icon={<Icon>more_vert</Icon>} openIcon={<Icon>close</Icon>} />}
              onClick={() => setOpenFlyout(!openFlyout)}>
              {MENU_ITEMS.map((action) => (
                <SpeedDialAction
                  key={action.label}
                  icon={<Icon>{action.icon}</Icon>}
                  className={action.isPrimaryAction && 'bg-primary-200 hover:bg-primary-300'}
                  tooltipTitle={action.label}
                  tooltipOpen
                  onClick={() => {
                    setOpenFlyout(false);
                    action.onClick();
                  }}
                />
              ))}
            </SpeedDial>
          </ClickAwayListener>
        </Tooltip>
      )}
    </div>
  );
}

ProductDetail.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  enableEditOption: PropTypes.bool,
  enableShoppingOption: PropTypes.bool,
  onAddToCart: PropTypes.func,
};

ProductDetail.defaultProps = {
  enableEditOption: false,
  enableShoppingOption: false,
  onAddToCart: () => {},
};

export default ProductDetail;
