"use strict";

var usStates = [{
  abbreviation: 'AL',
  name: 'Alabama'
}, {
  abbreviation: 'AK',
  name: 'Alaska'
}, {
  abbreviation: 'AZ',
  name: 'Arizona'
}, {
  abbreviation: 'AR',
  name: 'Arkansas'
}, {
  abbreviation: 'CA',
  name: 'California'
}, {
  abbreviation: 'CO',
  name: 'Colorado'
}, {
  abbreviation: 'CT',
  name: 'Connecticut'
}, {
  abbreviation: 'DE',
  name: 'Delaware'
}, {
  abbreviation: 'FL',
  name: 'Florida'
}, {
  abbreviation: 'GA',
  name: 'Georgia'
}, {
  abbreviation: 'HI',
  name: 'Hawaii'
}, {
  abbreviation: 'ID',
  name: 'Idaho'
}, {
  abbreviation: 'IL',
  name: 'Illinois'
}, {
  abbreviation: 'IN',
  name: 'Indiana'
}, {
  abbreviation: 'IA',
  name: 'Iowa'
}, {
  abbreviation: 'KS',
  name: 'Kansas'
}, {
  abbreviation: 'KY',
  name: 'Kentucky'
}, {
  abbreviation: 'LA',
  name: 'Louisiana'
}, {
  abbreviation: 'ME',
  name: 'Maine'
}, {
  abbreviation: 'MD',
  name: 'Maryland'
}, {
  abbreviation: 'MA',
  name: 'Massachusetts'
}, {
  abbreviation: 'MI',
  name: 'Michigan'
}, {
  abbreviation: 'MN',
  name: 'Minnesota'
}, {
  abbreviation: 'MS',
  name: 'Mississippi'
}, {
  abbreviation: 'MO',
  name: 'Missouri'
}, {
  abbreviation: 'MT',
  name: 'Montana'
}, {
  abbreviation: 'NE',
  name: 'Nebraska'
}, {
  abbreviation: 'NV',
  name: 'Nevada'
}, {
  abbreviation: 'NH',
  name: 'New Hampshire'
}, {
  abbreviation: 'NJ',
  name: 'New Jersey'
}, {
  abbreviation: 'NM',
  name: 'New Mexico'
}, {
  abbreviation: 'NY',
  name: 'New York'
}, {
  abbreviation: 'NC',
  name: 'North Carolina'
}, {
  abbreviation: 'ND',
  name: 'North Dakota'
}, {
  abbreviation: 'OH',
  name: 'Ohio'
}, {
  abbreviation: 'OK',
  name: 'Oklahoma'
}, {
  abbreviation: 'OR',
  name: 'Oregon'
}, {
  abbreviation: 'PA',
  name: 'Pennsylvania'
}, {
  abbreviation: 'RI',
  name: 'Rhode Island'
}, {
  abbreviation: 'SC',
  name: 'South Carolina'
}, {
  abbreviation: 'SD',
  name: 'South Dakota'
}, {
  abbreviation: 'TN',
  name: 'Tennessee'
}, {
  abbreviation: 'TX',
  name: 'Texas'
}, {
  abbreviation: 'UT',
  name: 'Utah'
}, {
  abbreviation: 'VT',
  name: 'Vermont'
}, {
  abbreviation: 'VA',
  name: 'Virginia'
}, {
  abbreviation: 'WA',
  name: 'Washington'
}, {
  abbreviation: 'WV',
  name: 'West Virginia'
}, {
  abbreviation: 'WI',
  name: 'Wisconsin'
}, {
  abbreviation: 'WY',
  name: 'Wyoming'
}];
module.exports = usStates;
var getStateByName = function getStateByName(stateName) {
  return usStates.find(function (_ref) {
    var name = _ref.name;
    return name.toUpperCase() === stateName.toUpperCase();
  });
};
var getStateByAbbreviation = function getStateByAbbreviation(stateAbbreviation) {
  return usStates.find(function (_ref2) {
    var abbreviation = _ref2.abbreviation;
    return abbreviation.toUpperCase() === stateAbbreviation.toUpperCase();
  });
};
module.exports.getStateNameByAbbreviation = function (stateAbbreviation) {
  var state = getStateByAbbreviation(stateAbbreviation);
  return state ? state.name : null;
};
module.exports.getStateAbbreviationByName = function (stateAbbreviation) {
  var state = getStateByName(stateAbbreviation);
  return state ? state.abbreviation : null;
};
module.exports.StateDropdown = usStates.map(function (_ref3) {
  var abbreviation = _ref3.abbreviation,
    name = _ref3.name;
  return {
    label: name,
    value: abbreviation
  };
});
module.exports.StateNameDropdown = usStates.map(function (_ref4) {
  var name = _ref4.name;
  return {
    label: name,
    value: name
  };
});
module.exports.StateAbbreviationDropdown = usStates.map(function (_ref5) {
  var abbreviation = _ref5.abbreviation;
  return {
    label: abbreviation,
    value: abbreviation
  };
});
module.exports.getStateByName = getStateByName;
module.exports.getStateByAbbreviation = getStateByAbbreviation;