const {
  ObjectIdSchema,
  PersonReference,
  RssModel
} = require('@rss/common');
const {
  ObjectId
} = require('bson');
const yup = require('yup');
const CATALOG_TYPE = require('../catalog-type.constant');
const {
  LIBRARY_ITEM_SOURCE,
  LIBRARY_ITEM_STATUS
} = require('../constants');
const BioDetails = require('./details/bio-details');
const PPEDetails = require('./details/library.ppe-details');
const PesticideDetails = require('./details/pesticide-details');
const CaPesticideDetails = require('./details/ca-pesticide-details');
class LibraryItem extends RssModel {
  constructor(opt = {}) {
    super(opt);
    if (opt) {
      this.fillObject(opt);
    }
  }
  fillObject(opt) {
    switch (opt.catalog) {
      case CATALOG_TYPE.BIOLOGICAL:
        this.details = new BioDetails(opt.details);
        break;
      case CATALOG_TYPE.EQUIPMENT:
        this.details = null;
        break;
      case CATALOG_TYPE.CA_PESTICIDE:
        this.details = new CaPesticideDetails(opt.details);
        break;
      case CATALOG_TYPE.PESTICIDE:
        this.details = new PesticideDetails(opt.details);
        break;
      case CATALOG_TYPE.PPE:
        this.details = new PPEDetails(opt.details);
        break;
      case CATALOG_TYPE.SERVICES:
        this.details = null;
        break;
      default:
        this.details = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema().default(() => new ObjectId()).required(),
      catalog: yup.string().oneOf(Object.keys(CATALOG_TYPE)).required(),
      name: yup.string().min(1).max(250).trim().required().label('Name').required(),
      alternativeNames: yup.array().of(yup.string().trim()).ensure().label('Alternative Names'),
      description: yup.string().max(1000).trim().default(null).nullable().label('Description'),
      tags: yup.array().of(yup.string().trim()).min(1).label('Type'),
      details: yup.object().when('libraryItem.catalog', catalog => {
        switch (catalog) {
          case CATALOG_TYPE.PPE:
            return new PPEDetails().schema().required();
          case CATALOG_TYPE.BIOLOGICAL:
            return new BioDetails().schema().required();
          case CATALOG_TYPE.CA_PESTICIDE:
            return new CaPesticideDetails().schema().required();
          case CATALOG_TYPE.PESTICIDE:
            return new PesticideDetails().schema().required();
          default:
            return yup.object().nullable();
        }
      }),
      status: yup.string().oneOf(Object.values(LIBRARY_ITEM_STATUS)).required(),
      source: yup.string().oneOf(Object.keys(LIBRARY_ITEM_SOURCE)).required(),
      createdBy: PersonReference.schema({
        yup: true
      }).required(),
      createdDate: yup.date().default(new Date()).required(),
      lastModifiedBy: PersonReference.schema({
        yup: true
      }).required(),
      lastModifiedDate: yup.date().default(new Date()).required()
    });
  }
}
module.exports = LibraryItem;