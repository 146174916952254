import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useDoogleMutation } from './useDoogle';
import { useReport } from './useReport';

export const INSERT_CATEGORY_MUTATION = gql`
  mutation InsertCategory($input: InsertCategoryInput!) {
    insertCategory(input: $input) {
      categoryId
    }
  }
`;

export const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      categoryId
    }
  }
`;

export function useCategory() {
  const [refetchingReport, setRefetchingReport] = useState(false);
  const { refetchReport } = useReport();
  const [insert, { loading: insertLoading }] = useDoogleMutation(INSERT_CATEGORY_MUTATION, {
    onCompleted: () => setRefetchingReport(true),
  });
  const [del, { loading: deleteLoading }] = useDoogleMutation(DELETE_CATEGORY_MUTATION, {});

  // HACK: we need to wait for the report to be refetched before loading is completed
  useEffect(() => {
    async function getUpdatedReport() {
      await refetchReport();
      setRefetchingReport(false);
    }

    if (refetchingReport) {
      getUpdatedReport();
    }
  }, [refetchingReport]);

  async function insertCategory({ reportId, categoryKey }) {
    return await insert({
      variables: {
        input: {
          reportId,
          categoryKey,
        },
      },
    });
  }

  async function deleteCategory({ reportId, categoryId }) {
    await del({
      variables: {
        input: {
          reportId,
          categoryId,
        },
      },
    });

    await refetchReport();
  }

  return {
    insertCategory,
    insertLoading: insertLoading || refetchingReport,
    deleteCategory,
    deleteLoading: deleteLoading,
  };
}
