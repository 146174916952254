import { IDLE_KEY, IDLE_TIMEOUT } from '@rss/common';

import config from '../config';

function getTimeout() {
  return JSON.parse(localStorage.getItem(IDLE_KEY.IDLE_TIMEOUT_KEY)) || {};
}

function getIdleMinutes() {
  return JSON.parse(localStorage.getItem(IDLE_KEY.IDLE_MINUTES_KEY));
}

export function isIdleSession(idleTimeInMinutes) {
  if (!idleTimeInMinutes) {
    return false;
  }

  const { time: timeoutTime } = getTimeout();
  if (!timeoutTime) {
    return false;
  }

  const isIdle = Date.now() > new Date(timeoutTime).getTime();
  if (isIdle) {
    localStorage.setItem(IDLE_KEY.IDLE_SIGNOUT_KEY, true);
  }

  return isIdle;
}

export function shouldShowWarning() {
  const { time: timeoutTime } = getTimeout();
  if (!timeoutTime) {
    return false;
  }
  const shouldShow = Date.now() > new Date(timeoutTime).getTime() - IDLE_TIMEOUT.IDLE_TIMOUT_DURATION_MINUTES * 60000;

  if (shouldShow) {
    localStorage.setItem(IDLE_KEY.IDLE_SIGNOUT_KEY, true);
  }

  return shouldShow;
}

export function updateTimeoutTime() {
  const idleMinutes = getIdleMinutes();
  if (!idleMinutes) {
    return;
  }
  const timestamp = Date.now() + idleMinutes * 60000;

  const activeTime = getTimeout();
  localStorage.setItem(
    IDLE_KEY.IDLE_TIMEOUT_KEY,
    JSON.stringify({ ...activeTime, id: activeTime.id || timestamp, time: new Date(timestamp) }),
  );
}

export function nonPlatformOauth2Url({ moodlesession, SNSession } = {}, provider) {
  if (!provider) {
    return null;
  }

  if (moodlesession) {
    const { host, protocol } = window.location;
    const redirect = encodeURIComponent(`${protocol}//${host}/learning/admin/oauth2callback.php`);
    //const redirect = encodeURIComponent(`${protocol}//${host}/auth/login?moodlesession=${moodlesession}`);
    const state = encodeURIComponent(`/auth/oauth2/login.php?wantsurl=/&sesskey=${moodlesession}&id=1`);
    //const state = encodeURIComponent(`${protocol}//${host}/auth/login?moodlesession=${moodlesession}&wantsurl=/&sesskey=${moodlesession}&id=1`);
    const {
      COGNITO_AUTH: {
        oauth: { domain },
      },
      MOODLE_CLIENT_ID,
    } = config;
    // main redirection for new auth
    const oauth2Url = `https://${domain}/oauth2/authorize?response_type=code&client_id=${MOODLE_CLIENT_ID}`;

    return `${oauth2Url}&identity_provider=${provider}&redirect_uri=${redirect}&state=${state}`;
  }

  if (SNSession) {
    const { SERVICE_NOW_AUTH_URL, SERVICE_NOW_AUTH_RSS_URL } = config;
    const { host } = window.location;
    // checking if hostname contains riskandsafety then use rss url to redirect.
    if (SNSession.includes(host?.split('.')[1])) {
      return `${SERVICE_NOW_AUTH_RSS_URL}&idp_identifier=${provider}&SNSession=${encodeURIComponent(SNSession)}`;
    }
    return `${SERVICE_NOW_AUTH_URL}&idp_identifier=${provider}&SNSession=${encodeURIComponent(SNSession)}`;
  }

  return null;
}
