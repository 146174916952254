import PropTypes from 'prop-types';
import React, { useState } from 'react';

import config from '../config';
import Upload from './Upload';

const AttachFile = ({ accept, buttonLabel, children, disabled, id, label, multiple, optional, onChange, variant }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleUpload = async (files) => {
    const uploadedFiles = (await Promise.all(files.map((file) => uploadFile(file)))).map((res) => res[0]);
    setIsLoading(false);
    onChange(uploadedFiles);
  };

  const uploadFile = async (file) => {
    setIsLoading(true);
    const data = new FormData();
    data.append('uploadFile', file, file.name);

    const options = {
      method: 'POST',
      body: data,
      accept: 'application/json',
    };
    return fetch(`${config.DRIVE_URL}/upload`, options).then((res) => res.json());
  };

  return (
    <Upload
      accept={accept}
      buttonLabel={buttonLabel}
      label={label}
      multiple={multiple}
      disabled={disabled || isLoading}
      id={id}
      optional={optional}
      onUpload={handleUpload}
      variant={variant}>
      {children}
    </Upload>
  );
};

AttachFile.propTypes = {
  accept: PropTypes.string,
  buttonLabel: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  variant: PropTypes.oneOf(['dropzone', 'icon']),
};

AttachFile.defaultProps = {
  accept: 'image/*,.csv,.xls,.xlsx,.doc,.docx,.pdf,.txt',
  buttonLabel: 'Upload File',
  children: null,
  disabled: false,
  id: '',
  label: 'Drag and drop some files here, or click to select files',
  multiple: true,
  optional: false,
  onChange: () => null,
  variant: 'dropzone',
};

export default AttachFile;
