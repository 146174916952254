import { useLazyQuery } from '@apollo/client';
import { FormHelperText, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FIND_ROOM_BY_ID } from '../../graphql/relationship.query';
import { inlineErrorMessages } from '../../shared/helper';
import Search from '../Search';

const RoomLookup = ({
  autoFocus,
  id,
  disabled,
  label,
  onMatch,
  onClear,
  roomDetails,
  placeholder,
  required,
  errors,
}) => {
  const [errorMessages, setErrorMessages] = useState(errors);
  const [roomValue, setRoomValue] = useState(roomDetails);

  const [lookupRoom, { loading }] = useLazyQuery(FIND_ROOM_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: ({ findRoomDetailById }) => {
      if (findRoomDetailById && findRoomDetailById?.id && findRoomDetailById?.active) {
        onMatch(findRoomDetailById);
      } else {
        setErrorMessages(['No rooms found with Id entered.']);
      }
    },
  });

  useEffect(() => {
    setRoomValue(roomDetails);
    if (roomDetails?.id && !roomDetails?.roomKey) {
      lookupRoom({
        variables: { roomId: roomDetails?.id },
      });
    }
  }, [roomDetails, lookupRoom]);

  useEffect(() => {
    setErrorMessages(errors);
  }, [errors]);

  return (
    <>
      <Search
        key={id}
        id={id}
        error={!isEmpty(errorMessages)}
        required={required}
        label={label}
        placeholder={placeholder || label}
        autocomplete
        autoFocus={autoFocus && !disabled}
        value={roomValue}
        disabled={disabled}
        isLoading={loading}
        itemToString={(room) => room?.id ?? ''}
        onChange={async ({ inputValue }) => {
          if (inputValue) {
            lookupRoom({
              variables: { roomId: inputValue },
            });
          }
        }}
        minSearchLength={1}
        onSelect={onMatch}
        onClear={onClear}
        renderItem={(room) => (
          <Typography variant="body1">
            {room.roomNumber} ({room.roomKey})
          </Typography>
        )}
      />
      {!isEmpty(errorMessages) && (
        <FormHelperText error={!isEmpty(errorMessages)}>{inlineErrorMessages(errorMessages)}</FormHelperText>
      )}
    </>
  );
};

RoomLookup.propTypes = {
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onClear: PropTypes.func,
  onMatch: PropTypes.func.isRequired,
  roomDetails: PropTypes.shape({
    id: PropTypes.string,
    roomKey: PropTypes.string,
    name: PropTypes.string,
    roomNumber: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    campusCode: PropTypes.string,
    floor: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      floorKey: PropTypes.string,
    }),
    building: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      buildingKey: PropTypes.string,
    }),
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
};

RoomLookup.defaultProps = {
  required: false,
  autoFocus: false,
  disabled: false,
  label: null,
  placeholder: 'Lookup room by id',
  onClear: () => {},
  roomDetails: '',
  errors: [],
};

export default RoomLookup;
