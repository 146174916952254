module.exports = [{
  label: 'Ambient',
  value: 'AMBIENT'
}, {
  label: 'Below Ambient',
  value: 'BELOW_AMBIENT'
}, {
  label: 'Above Ambient',
  value: 'ABOVE_AMBIENT'
}, {
  label: 'Cryogenic',
  value: 'CRYOGENIC'
}];