import PropTypes from 'prop-types';
import React from 'react';

const HeaderWithRightSidebar = ({ children }) => (
  <div className="flex h-full flex-col justify-between p-4 sm:p-12">
    <div className="toolbar align-center flex justify-end pr-12">{children}</div>
  </div>
);

HeaderWithRightSidebar.propTypes = {
  children: PropTypes.node,
};

HeaderWithRightSidebar.defaultProps = {
  children: [],
};

export default HeaderWithRightSidebar;
