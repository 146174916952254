// Value should be string because of strict typing on mobile-realm for inspect-v2
import { FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { QUESTION_VARIANT } from './Helper';

const FqSwitch = React.memo(({ disabled, label, onChange, questionKey, value, variant }) => {
  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'mx-8' : 'mx-24'}`}>
      <FormControlLabel
        label={label}
        control={
          <Switch
            disabled={disabled}
            name={label}
            color="primary"
            checked={String(value) === 'true'}
            onChange={(event) => onChange(String(event.target.checked), questionKey)}
          />
        }
      />
    </div>
  );
});

FqSwitch.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  questionKey: PropTypes.string.isRequired,
  value: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FqSwitch.defaultProps = {
  disabled: false,
  label: '',
  value: '',
  variant: QUESTION_VARIANT.STANDARD,
};

export default FqSwitch;
