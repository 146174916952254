/* eslint-disable max-len */
import { CATALOG_TYPE } from '@risk-and-safety/library';
import { RELATIONSHIP_LABEL } from '@rss/common';

import { BiologicalLibraryItemTemplate } from './biological.template';
import { PesticideLibraryItemTemplate } from './pesticide.template';
import { PPELibraryItemTemplate } from './ppe.template';
import { CaPesticideLibraryItemTemplate } from './ca.pesticide.template';

const {
  EDGE: { LIBRARY_MANAGER, SERVICE_DESK },
} = RELATIONSHIP_LABEL;

export const libraryItemConfig = {
  [CATALOG_TYPE.PESTICIDE]: {
    header: 'Pesticide',
    includeUnderRSSTools: true,
    rssToolAccess: [LIBRARY_MANAGER, SERVICE_DESK],
    form: [...PesticideLibraryItemTemplate],
  },
  [CATALOG_TYPE.CA_PESTICIDE]: {
    header: 'CA Pesticide',
    includeUnderRSSTools: true,
    rssToolAccess: [LIBRARY_MANAGER, SERVICE_DESK],
    form: [...CaPesticideLibraryItemTemplate],
  },
  [CATALOG_TYPE.BIOLOGICAL]: {
    header: 'Biological Material',
    includeUnderRSSTools: true,
    rssToolAccess: [LIBRARY_MANAGER, SERVICE_DESK],
    form: [...BiologicalLibraryItemTemplate],
  },
  [CATALOG_TYPE.PPE]: {
    header: 'PPE',
    includeUnderRSSTools: true,
    rssToolAccess: [LIBRARY_MANAGER, SERVICE_DESK],
    form: [...PPELibraryItemTemplate],
  },
};
