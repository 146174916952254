import { DriveProvider } from '@risk-and-safety/drive-client';
import { ProximityProvider } from '@risk-and-safety/proximity-hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import config from '../config';
import { useProfile } from '../hooks';
import { getImpersonateId } from '../shared/helper';

export default function Session({ children }) {
  const { profile } = useProfile();
  const [impersonateId, setImpersonateId] = useState('');

  useEffect(() => {
    async function load() {
      setImpersonateId(getImpersonateId());
    }

    load();
  }, []);

  return (
    <ProximityProvider url={config.SOCKET_URL} impersonateId={impersonateId}>
      <DriveProvider userId={profile?.userId || ''} impersonateId={impersonateId}>
        {children}
      </DriveProvider>
    </ProximityProvider>
  );
}

Session.propTypes = {
  children: PropTypes.node.isRequired,
};
