import { useMutation, useLazyQuery } from '@apollo/client';
import { Button, Step, StepButton, Stepper, Typography } from '@material-ui/core';
import { CATALOG_TYPE, Store } from '@risk-and-safety/library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CartCheckout, CartItems, CartPrice, OrderConfirmation } from './index';
import { canCheckout, canPlaceOrder } from '../helper/cart.helper';
import { generateOrderId } from '../helper/order.helper';
import { SUBMIT_ORDER, VALIDATE_SHOPPING_CART, GET_ORDERS } from '../graphql/query';
import { clearCart, updateCart } from '../../store/app/shoppingCartSlice';
import { ButtonProgress } from '../../components';

const STEPS = ['Review Cart', 'Checkout', 'Order Complete'];

function ShoppingCart({ accounts, store }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const cart = useSelector(({ app }) => app.shoppingCart && app.shoppingCart[store._id]) || {};
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [orderId, setOrderId] = useState();
  useEffect(() => setOrderId(generateOrderId()), []);

  const [validateShoppingCartQuery, { data = {} }] = useLazyQuery(VALIDATE_SHOPPING_CART, {
    // variables: { storeId: store._id, items: cart.items, accountId: cart.account?.id, fundId: cart.fund?.id },
    // TODO temporary to account for breaking changes on account schema in cart redux
    variables: {
      storeId: store._id,
      items: cart.items,
      accountId: cart.account?.id || cart.account?._id,
      fundId: cart.fund?.id,
    },
    skip: !cart.items?.length && !(cart.account?.id || cart.account?._id),
  });
  const { validateShoppingCart = {} } = data;
  const validatedProducts = validateShoppingCart.items || [];
  const [submitOrder, { data: orderData = {}, loading }] = useMutation(SUBMIT_ORDER, {
    onCompleted: () => {
      setActiveStepIndex(2);
      dispatch(clearCart({ storeId: store._id }));
    },
    refetchQueries: [
      {
        query: GET_ORDERS,
        variables: {
          storeId: store._id,
          orderId: '',
          status: [],
          fromDate: '',
          toDate: '',
          createdByUserId: '',
          myOrders: true,
        },
      },
    ],
  });

  useEffect(() => {
    if (!cart.account?._id) {
      dispatch(
        updateCart({
          storeId: store._id,
          account: accounts[0],
          deliveryLocation: (accounts[0]?.deliveryLocations || []).find((loc) => loc.default),
        }),
      );
    }
    if (cart.account && cart.items?.length) {
      validateShoppingCartQuery({
        variables: {
          storeId: store._id,
          items: cart.items,
          accountId: cart.account?.id || cart.account?._id,
          fundId: cart.fund?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <div className="w-full p-16">
      <Stepper nonLinear activeStep={activeStepIndex} className="mx-auto my-20 max-w-640">
        {STEPS.map((label, index) => (
          <Step key={label}>
            <StepButton
              disabled={index > activeStepIndex || activeStepIndex === 2}
              onClick={() => setActiveStepIndex(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {activeStepIndex === 0 && (
        <div className="flex flex-wrap">
          {cart?.items?.length > 0 ? (
            <>
              <div className="m-8 flex-grow">
                <CartItems storeId={store._id} validatedProducts={validatedProducts} />
              </div>
              <div className="m-8 min-w-256 flex-none">
                <CartPrice storeId={store._id} items={cart.items} productPrices={validatedProducts} />
                <Button
                  variant="contained"
                  color="primary"
                  className="w-full"
                  disabled={!canCheckout(cart, validateShoppingCart)}
                  onClick={() => setActiveStepIndex(1)}>
                  Checkout
                </Button>
              </div>
            </>
          ) : (
            <div className="m-auto items-center">
              <Typography variant="h6" className="my-16">
                Your shopping cart is empty.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/shop/${store._id}/search`)}
                className="w-full">
                Shop for products
              </Button>
            </div>
          )}
        </div>
      )}

      {activeStepIndex === 1 && (
        <div className="flex flex-wrap gap-4">
          <div className="m-8 flex-grow">
            <CartCheckout
              storeId={store._id}
              allowDelivery={store.settings.delivery && cart?.items?.find((i) => i.catalog !== CATALOG_TYPE.SERVICES)}
              allowPickup={store.settings.pickup}
              accounts={accounts}
              cart={cart}
              onUpdate={(val) => dispatch(updateCart(val))}
            />
          </div>
          <div className="m-8 min-w-256 flex-none">
            <CartPrice storeId={store._id} items={cart.items} productPrices={validatedProducts} />
            <ButtonProgress
              loading={loading}
              variant="contained"
              color="primary"
              className="w-full"
              disabled={!canPlaceOrder(cart, validateShoppingCart)}
              onClick={() =>
                submitOrder({
                  variables: {
                    storeId: store._id,
                    order: {
                      _id: orderId,
                      accountId: cart.account._id,
                      fundId: cart.fund.id,
                      receivingType: cart.receivingType,
                      deliveryLocationId: cart.deliveryLocation?.id,
                      items: cart.items,
                    },
                  },
                })
              }>
              Place Order
            </ButtonProgress>
          </div>
        </div>
      )}

      {activeStepIndex === 2 && <OrderConfirmation order={orderData?.submitOrder} store={store} />}
    </div>
  );
}

ShoppingCart.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  store: PropTypes.shape(Store).isRequired,
};

export default ShoppingCart;
