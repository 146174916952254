import gql from 'graphql-tag';

export const GET_ORGANIZATION_DETAILS = gql`
  query getOrganizationDetails($id: String!) {
    getOrganizationDetails(id: $id) {
      active
      owner
      orgNode
      members
      locations
      trainings
      usages
      adjacentToOrganizations
      adjacentFromOrganizations
      parentOrg
    }
  }
`;

export const FIND_GROUPS_BY_USER = gql`
  query findOrgRelationshipsByUserId($orgId: String!, $userId: String!, $toLabel: [String], $edgeLabel: [String]) {
    findOrgRelationshipsByUserId(orgId: $orgId, userId: $userId, toLabel: $toLabel, edgeLabel: $edgeLabel) {
      node
    }
  }
`;

export const GET_NODE_ANCESTORS = gql`
  query getNodeAncestors($id: ID!, $label: NodeLabel!) {
    getNodeAncestors(id: $id, label: $label) {
      id
      label
      name
    }
  }
`;

export const FIND_COURSES_BY_CAMPUS = gql`
  query findCoursesByCampus($campusCode: String!) {
    findCoursesByCampus(campusCode: $campusCode) {
      activityCode
      activityName
    }
  }
`;

export const FIND_ORG_NODE = gql`
  query findOrgNode($id: String!) {
    findOrgNode(id: $id) {
      node
    }
  }
`;

export const LIST_ASSESSMENT_TYPE_CONFIG = gql`
  query listAssessmentTypeConfig {
    listAssessmentTypeConfig {
      _id
      type
      url
    }
  }
`;

export const LIST_PESTICIDE_MATERIALS_FOR_ASSESSMENTS = gql`
  query listPesticideMaterials($seriesIds: [ID!]) {
    listPesticideMaterials(seriesIds: $seriesIds)
  }
`;
