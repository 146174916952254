import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { ErrorMessage } from './errors';

const STATUS = {
  IDLE: 'IDLE',
  DONE: 'DONE',
  FAILED: 'FAILED',
  LOADING: 'LOADING',
};

const WebComponentEventful = React.forwardRef(({ children, name, sourceUrl, props }, ref) => {
  const [status, setStatus] = useState(STATUS.IDLE);

  const setWebComponentProps = useCallback(() => {
    if (status === STATUS.DONE && ref.current !== null) {
      /* eslint-disable-next-line */
      ref.current.props = props;
    }
  }, [props, ref, status]);

  useEffect(() => {
    async function load() {
      setStatus(STATUS.LOADING);

      const webComponentURL = `${sourceUrl}/${name}.mjs`

      try {
        await import(/* webpackIgnore: true */ webComponentURL);
        setStatus(STATUS.DONE);
      } catch (err) {
        setStatus(STATUS.FAILED);
      }
    }

    if (status === STATUS.IDLE) {
      load();
    }

    setWebComponentProps();
  }, [name, sourceUrl, status, setWebComponentProps]);

  useEffect(() => {
    setWebComponentProps();
  });

  switch (status) {
    case STATUS.LOADING:
      return (
        <div className="m-16 flex items-center justify-center">
          <CircularProgress role="status" aria-label="loading" />
        </div>
      );
    case STATUS.FAILED:
      return <ErrorMessage message="We were unable to process this request." />;
    case STATUS.DONE:
      return children;
    default:
      return null;
  }
});

WebComponentEventful.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  props: PropTypes.shape({}).isRequired,
  sourceUrl: PropTypes.string.isRequired,
};

export default WebComponentEventful;
