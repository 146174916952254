export const blue = {
  50: '#e3effb',
  100: '#bad6f5',
  200: '#8cbbee',
  300: '#5e9fe7',
  400: '#3c8be1',
  500: '#1976dc',
  600: '#166ed8',
  700: '#1263d3',
  800: '#0e59ce',
  900: '#0846c5',
  A100: '#eff3ff',
  A200: '#bccfff',
  A400: '#89aaff',
  A700: '#6f97ff',
};

export const grey = {
  25: '#eaeaea',
  50: '#e8e8e8',
  100: '#c6c6c6',
  200: '#949494', // Min ADA contrast for large font
  300: '#757575', // Min ADA contrast for normal font
  400: '#5d5d5d',
  500: '#404040',
  600: '#3a3a3a',
  700: '#323232',
  800: '#2a2a2a',
  900: '#1c1c1c',
  A100: '#f07777',
  A200: '#eb4949',
  A400: '#ff0202',
  A700: '#e70000',
};

export const purple = {
  50: '#f2e5f1',
  100: '#ddbfdd',
  200: '#c794c6',
  300: '#b169af',
  400: '#a0489d',
  500: '#8f288c',
  600: '#872484',
  700: '#7c1e79',
  800: '#72186f',
  900: '#600f5c',
  A100: '#ff96f9',
  A200: '#ff63f7',
  A400: '#ff30f4',
  A700: '#ff16f2',
};

export const background = '#f6f7f9';
