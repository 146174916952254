import { Button, FormControlLabel, Icon, IconButton, Switch, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { ContactPerson } from '@rss/common';
import { ObjectId } from 'bson';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useProfile } from '../hooks';
import ButtonProgress from './ButtonProgress';
import CommentInput from './CommentInput';
import CommentItem from './CommentItem';
import DialogTemplate from './DialogTemplate';
import FormattedDate from './FormattedDate';

const Comment = ({
  onClose,
  onCommentAction,
  comments,
  privateComments,
  canComment,
  canCommentPrivate,
  onSave,
  onDelete,
}) => {
  const sortedComments = sortBy([...comments, ...privateComments], ['createdDate']);

  const { profile } = useProfile();
  const [value, setValue] = useState('');
  const [selectedComment, setSelectedComment] = useState('');
  const [currentComments, setCurrentComments] = useState(sortedComments);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedDeleteComment, setSelectedDeleteComment] = useState(null);
  const [newCommentPrivate, setNewCommentPrivate] = useState(!canComment);

  const handleSave = (comment, update) => {
    if (update) {
      const commentsState = currentComments.map((currentComment) =>
        new ObjectId(currentComment.id).equals(new ObjectId(comment.id)) ? comment : currentComment,
      );
      setCurrentComments(commentsState);
    } else {
      setCurrentComments([...currentComments, comment]);
    }
    onSave(comment, comment.private);
    setValue('');
    setSelectedComment('');
  };

  const handleDelete = (comment) => {
    const filteredDeleted = currentComments.filter((val) => !new ObjectId(comment.id).equals(new ObjectId(val.id)));
    setCurrentComments(filteredDeleted);
    onDelete(comment.id, comment.private);
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="mt-0 ml-4 rounded-8 bg-gray-50 pb-8 sm:mr-8 sm:mb-8 sm:ml-56 sm:pr-8">
        <Toolbar className="inline-flex w-full p-16 pl-0 pb-8">
          <div className="inline-flex flex-1 pl-16 text-20">
            <Icon color="primary" className="mt-4 w-32">
              comment
            </Icon>
            <div>Comments</div>
          </div>
          <Tooltip id="close-icon" title="Close" placement="left" arrow>
            <Icon className="cursor-pointer" onClick={onClose} color="primary">
              close
            </Icon>
          </Tooltip>
        </Toolbar>
        <div className="pl-16">
          {currentComments.length > 0 &&
            currentComments.map((comment) => (
              <div key={comment.id} className="whitespace-pre">
                {selectedComment?.id === comment.id ? (
                  <CommentInput
                    buttonText="Update Comment"
                    value={selectedComment.text}
                    InputProps={{
                      placeholder: 'Enter comment here',
                      label: 'Update comment',
                      required: true,
                    }}
                    onChange={({ target: { value: newVal } }) =>
                      setSelectedComment({ ...selectedComment, text: newVal })
                    }
                    onSave={() => handleSave({ ...selectedComment, lastModifiedDate: new Date() }, true)}
                    actions={
                      canCommentPrivate && (
                        <FormControlLabel
                          label="Private"
                          control={
                            <Switch
                              name="private"
                              color="primary"
                              checked={selectedComment.private}
                              disabled={!canComment}
                              onChange={() =>
                                setSelectedComment(
                                  canComment
                                    ? { ...selectedComment, private: !selectedComment.private }
                                    : selectedComment,
                                )
                              }
                            />
                          }
                        />
                      )
                    }
                  />
                ) : (
                  <CommentItem
                    key={comment.id}
                    isPrivate={comment.private}
                    comment={comment}
                    onComment={onCommentAction}
                    canComment={canComment}
                    subtitle={<FormattedDate date={comment.lastModifiedDate || comment.createdDate} showTime />}
                    user={comment?.author}
                    actions={
                      <div>
                        {comment?.author?.userId === profile.userId && (
                          <Tooltip title="Edit" arrow>
                            <IconButton onClick={() => setSelectedComment(comment)}>
                              <Icon color="primary">edit</Icon>
                            </IconButton>
                          </Tooltip>
                        )}
                        {comment?.author?.userId === profile.userId && (
                          <Tooltip title="Delete" arrow>
                            <IconButton
                              onClick={() => {
                                setDeleteConfirmation(true);
                                setSelectedDeleteComment(comment);
                              }}>
                              <Icon color="error">delete</Icon>
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    }>
                    <Typography className="w-full whitespace-pre-line break-words break-all pr-16">
                      {comment.text}
                    </Typography>
                  </CommentItem>
                )}
              </div>
            ))}
        </div>
        {deleteConfirmation && (
          <DialogTemplate
            open={deleteConfirmation}
            color="secondary"
            content={<p className="pt-10 pb-2 text-base">Delete this comment?</p>}
            primaryActions={
              <>
                <Button variant="contained" onClick={() => setDeleteConfirmation(false)}>
                  Cancel
                </Button>
                <ButtonProgress
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    handleDelete(selectedDeleteComment);
                    setDeleteConfirmation(false);
                  }}>
                  Delete
                </ButtonProgress>
              </>
            }
          />
        )}

        {(canComment || canCommentPrivate) && (
          <div className="ml-16 whitespace-pre">
            <CommentInput
              buttonText="Save Comment"
              value={value}
              InputProps={{
                placeholder: 'Enter comment here',
                label: 'Comment',
                required: true,
              }}
              onChange={({ target: { value: newVal } }) => setValue(newVal)}
              onSave={() => {
                handleSave({
                  author: new ContactPerson(profile),
                  createdDate: new Date(),
                  id: new ObjectId(),
                  text: value,
                  lastModifiedDate: new Date(),
                  private: newCommentPrivate,
                });
              }}
              actions={
                canCommentPrivate && (
                  <FormControlLabel
                    label="Private"
                    control={
                      <Switch
                        name="private"
                        color="primary"
                        checked={newCommentPrivate}
                        disabled={!canComment}
                        onChange={() =>
                          canComment
                            ? setNewCommentPrivate(!newCommentPrivate)
                            : setNewCommentPrivate(newCommentPrivate)
                        }
                      />
                    }
                  />
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

Comment.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.any),
  privateComments: PropTypes.arrayOf(PropTypes.any),
  onClose: PropTypes.func.isRequired,
  onCommentAction: PropTypes.func,
  canComment: PropTypes.bool.isRequired,
  canCommentPrivate: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

Comment.defaultProps = {
  comments: [],
  privateComments: [],
  onCommentAction: null,
  canCommentPrivate: false,
};

export default Comment;
