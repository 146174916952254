module.exports = {
  AMEND: 'AMEND',
  AMENDMENT_DRAFT: 'AMENDMENT_DRAFT',
  APPROVED: 'APPROVED',
  ARCHIVED: 'ARCHIVED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  AWAITING_REVIEW: 'AWAITING_REVIEW',
  AWAITING_MONTHLY_SUBMISSION: 'AWAITING_MONTHLY_SUBMISSION',
  BIOSAFETY_REVIEW: 'BIOSAFETY_REVIEW',
  CANCELLED: 'CANCELLED',
  CERTIFICATION_IN_PROGRESS: 'CERTIFICATION_IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  DEACTIVATED: 'DEACTIVATED',
  DRAFT: 'DRAFT',
  EVALUATOR_REVIEW: 'EVALUATOR_REVIEW',
  EXPIRED: 'EXPIRED',
  IBC_REVIEW: 'IBC_REVIEW',
  INELIGIBLE: 'INELIGIBLE',
  MANAGER_REVIEW: 'MANAGER_REVIEW',
  OWNER_REVIEW: 'OWNER_REVIEW',
  PI_REVISE: 'PI_REVISE',
  REVISE: 'REVISE',
  REVISION_REQUESTED: 'REVISION_REQUESTED',
  RENEWAL_DRAFT: 'RENEWAL_DRAFT',
  DENIED: 'DENIED',
  REVOKED: 'REVOKED',
  REPORTING_REQUIRED: 'REPORTING_REQUIRED',
  SUBMITTED: 'SUBMITTED',
  WITHDRAWN: 'WITHDRAWN',
  REACTIVATE: 'REACTIVATE',
};
