import {
  IconButton,
  Icon,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { last, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import RegulationSearch from '../regulation-search/RegulationSearch';
import { FACILITY_TYPES } from '../../shared/facility-type.constant';

const FacilitySearch = ({ onSelect, selected, disabled }) => (
  <TextField
    select
    variant="outlined"
    id="facility_search-label"
    label="Select Facility Type"
    fullWidth
    disabled={disabled}
    value={selected || FACILITY_TYPES[0]?.type}
    inputProps={{
      'aria-labelledby': 'facility_search-label',
    }}
    onChange={(event) => onSelect(event.target.value)}>
    {orderBy(FACILITY_TYPES, 'description').map((facility) => (
      <MenuItem key={facility?.type} value={facility?.type}>
        {facility?.description}
      </MenuItem>
    ))}
  </TextField>
);

FacilitySearch.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
};

FacilitySearch.defaultProps = {
  disabled: false,
  selected: null,
};

const FqRegulationType = ({ disabled, fqVariant, label, labelVariant, onChange, question, values }) => {
  const [currRegulations, setCurrRegulations] = useState(values || []);
  const [selectedFacility, setSelectedFacility] = useState(FACILITY_TYPES[0]?.type);

  useEffect(() => {
    setCurrRegulations(values);
    if (values?.length) setSelectedFacility(last(values)?.selectedFacility);
  }, [values, selectedFacility]);

  const handleAnother = (newRegulation) => {
    const newRegulationList = [...currRegulations, newRegulation];
    setCurrRegulations(newRegulationList);
    onChange({ [question.key]: newRegulationList });
  };

  const handleDelete = (regulation) => {
    const newRegulationList = currRegulations.filter((r) => r.id !== regulation.id);
    setCurrRegulations(newRegulationList);
    onChange({ [question.key]: newRegulationList });
  };

  return (
    <div className="my-8">
      <div className="flex w-full">
        {question?.showFacility ? (
          <div className="w-full p-8 sm:w-1/3">
            <FacilitySearch
              onSelect={(facility) => setSelectedFacility(facility)}
              selected={selectedFacility}
              disabled={disabled || !!currRegulations?.length}
            />
          </div>
        ) : null}
        <div className="w-full">
          <RegulationSearch
            filter={
              selectedFacility
                ? currRegulations.map((r) => r.id)
                : currRegulations.map((r) => r?.id && r?.facilityTypes?.includes(selectedFacility))
            }
            onSelect={(val) => handleAnother(val)}
            clearOnSelect
            question={question}
            label={label}
            labelVariant={labelVariant}
            variant={fqVariant}
            disabled={disabled}
            selectedFacility={selectedFacility}
          />
        </div>
      </div>
      {!!currRegulations.length && (
        <>
          <Typography className="pl-16 pt-8 font-medium">Selected Regulations</Typography>
          <List className="pt-4" dense>
            {currRegulations.map((regulation) => (
              <ListItem key={regulation.id}>
                <div className="flex">
                  <ListItemText primary={regulation?.value} secondary={regulation?.regText} />
                </div>
                {!disabled && (
                  <ListItemSecondaryAction>
                    <Tooltip arrow title="Delete Regulation" placement="top">
                      <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(regulation)}>
                        <Icon className="text-error-500">delete</Icon>
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}
    </div>
  );
};

FqRegulationType.propTypes = {
  disabled: PropTypes.bool,
  fqVariant: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelVariant: PropTypes.string,
  onChange: PropTypes.func,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.arrayOf(PropTypes.object),
};

FqRegulationType.defaultProps = {
  disabled: false,
  fqVariant: 'default',
  labelVariant: 'default',
  onChange: null,
  values: [],
};

export default FqRegulationType;
