import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { take } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { SEARCH_ROOMS_NEPTUNE } from '../../graphql/relationship.query';
import Search from '../Search';

const RoomSearch = ({
  autoFocus,
  buildingId,
  floatingText,
  filter,
  hintText,
  id,
  isDisabled,
  label,
  onSelect,
  onClear,
  suggestions,
  showSuggestions,
  suggestionsLimit,
  value,
}) => {
  const [searchRooms, { called, data, loading }] = useLazyQuery(SEARCH_ROOMS_NEPTUNE);

  const { searchRoomsNeptune } = data || {};

  let suggestionsList = showSuggestions && suggestions && suggestions.length ? suggestions : [];
  suggestionsList = suggestionsLimit ? take(suggestionsList, suggestionsLimit) : suggestionsList;

  const results = (called && searchRoomsNeptune) || [];

  const filteredResults = (results.length ? results : suggestionsList)
    .filter((room) => room.roomNumber)
    .filter((room) => filter.every((roomId) => roomId !== room.roomId))
    .map((room) => ({ key: room.roomId, ...room }));

  useEffect(() => {
    if (buildingId && !suggestions) {
      searchRooms({
        variables: { searchTerm: '', buildingId, maxResults: suggestionsLimit },
      });
    }
  }, [buildingId, searchRooms, suggestions, suggestionsLimit]);

  return (
    <Search
      key={buildingId}
      id={id}
      label={label || floatingText || ''}
      placeholder={hintText}
      autocomplete
      autoFocus={autoFocus && !isDisabled}
      value={value || ''}
      disabled={isDisabled}
      isLoading={loading}
      items={filteredResults}
      itemToString={(room) => (room ? room.roomNumber : '')}
      onChange={({ inputValue }) => {
        searchRooms({
          variables: { searchTerm: inputValue, buildingId, maxResults: suggestionsLimit },
        });
      }}
      minSearchLength={1}
      onSelect={onSelect}
      onClear={onClear}
      resultProps={{ title: showSuggestions && filteredResults.length && !results.length ? 'Suggestions' : null }}
      renderItem={(room) => <Typography variant="body1">{room.roomNumber}</Typography>}
    />
  );
};

RoomSearch.propTypes = {
  autoFocus: PropTypes.bool,
  buildingId: PropTypes.string,
  floatingText: PropTypes.string,
  filter: PropTypes.arrayOf(PropTypes.string),
  hintText: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onClear: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  showSuggestions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      roomId: PropTypes.string,
      roomNumber: PropTypes.string,
    }),
  ),
  suggestionsLimit: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

RoomSearch.defaultProps = {
  autoFocus: false,
  buildingId: '',
  hintText: '',
  filter: [],
  floatingText: null,
  id: 'room-search',
  label: null,
  onClear: () => {},
  showSuggestions: false,
  suggestions: null,
  suggestionsLimit: 20,
  value: '',
};

export default RoomSearch;
