const {
  GroupReference,
  PersonReference
} = require('@rss/common');
const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
const InventoryReference = require('./inventory-reference');
class Inventory {
  constructor(opt) {
    this._id = new ObjectId();
    this.tenantCode = null;
    this.campusCode = null;
    this.colleagues = [];
    this.groups = [];
    this.name = null;
    this.owner = null;
    this.tags = [];
    this.permissions = [];
    this.lastCertifiedBy = null;
    this.lastCertifiedDate = null;
    this.stockroom = false;
    this.orgCodes = [];
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    // eslint-disable-next-line no-underscore-dangle
    this._createdDate = this._id.getTimestamp();
    this.tenantCode = opt.tenantCode || opt.owner.tenantCode;
    this.campusCode = opt.campusCode || opt.owner.campusCode;
    this.name = opt.name;
    this.colleagues = Array.isArray(opt.colleagues) ? opt.colleagues.map(colleague => new InventoryReference(colleague)) : [];
    this.owner = new PersonReference(opt.owner);
    this.groups = Array.isArray(opt.groups) ? opt.groups.map(group => new GroupReference(group)) : [];
    this.tags = Array.isArray(opt.tags) ? [...opt.tags] : [];
    this.permissions = Array.isArray(opt.permissions) ? [...opt.permissions] : [];
    this.lastCertifiedBy = opt.lastCertifiedBy ? new PersonReference(opt.lastCertifiedBy) : null;
    this.lastCertifiedDate = opt.lastCertifiedDate ? new Date(opt.lastCertifiedDate) : null;
    this.stockroom = !!opt.stockroom;
    this.orgCodes = opt.orgCodes || [];
  }
  validate() {
    return tv4.validateMultiple(this, Inventory.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'inventory.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        tenantCode: {
          type: 'string'
        },
        campusCode: {
          type: 'string'
        },
        colleagues: {
          type: 'array',
          item: {
            $ref: 'inventory-reference.schema.json'
          }
        },
        groups: {
          type: 'array',
          item: {
            $ref: 'group-reference.schema.json'
          }
        },
        name: {
          type: 'string'
        },
        owner: {
          $ref: 'person-reference.schema.json'
        },
        tags: {
          type: 'array',
          item: {
            type: 'string'
          }
        },
        permissions: {
          type: 'array',
          item: {
            type: 'object'
          }
        },
        lastCertifiedBy: {
          anyOf: [{
            $ref: 'person-reference.schema.json'
          }, {
            type: ['null']
          }]
        },
        lastCertifiedDate: {
          type: ['object', 'null']
        },
        stockroom: {
          type: 'boolean'
        },
        orgCodes: {
          type: 'array',
          item: {
            type: 'string'
          }
        }
      },
      required: Object.keys(new Inventory())
    };
  }
}
tv4.addSchema(GroupReference.schema());
tv4.addSchema(InventoryReference.schema());
tv4.addSchema(PersonReference.schema());
module.exports = Inventory;