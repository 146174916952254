"use strict";

/* eslint-disable no-useless-escape */
// prettier-ignore
var basePattern = '^UC\\d{10}$|^CA[0-9A-F]{22}$';
module.exports = {
  UC: {
    '03': new RegExp("".concat(basePattern, "|^3415925DA\\w{6,31}$|^(201[8-9]|20[2-9]\\d{1}|2[1-9]\\d{2})(0600[0-9A-F]{16}|\\d{20})$"))
  },
  MARS: {
    MMM: new RegExp("".concat(basePattern, "|^3415925DA\\w{6,31}$|^(201[8-9]|20[2-9]\\d{1}|2[1-9]\\d{2})(0600[0-9A-F]{16}|\\d{20})$"))
  },
  DEFAULT: new RegExp(basePattern)
};
var baseFormat = 'UCXXXXXXXXXX or \nCAXXXXXXXXXXXXXXXXXXXXXX';
module.exports.formats = {
  UC: {
    '03': "".concat(baseFormat, " or \n2018XXXXXXXXXXXXXXXXXXXX")
  },
  DEFAULT: baseFormat
};