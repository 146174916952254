"use strict";

module.exports = {
  DROP_DOWN: [{
    label: 'User ID',
    value: 'USER',
    mapping: 'userId'
  }, {
    label: 'Tenant Code',
    value: 'TENANT',
    mapping: 'tenantCode'
  }, {
    label: 'Campus Code',
    value: 'CAMPUS',
    mapping: 'campusCode'
  }, {
    label: 'None',
    value: 'NONE'
  }],
  GRAPHQL_ENUM: {
    USER: {
      value: 'USER'
    },
    TENANT: {
      value: 'TENANT'
    },
    CAMPUS: {
      value: 'CAMPUS'
    },
    NONE: {
      value: 'NONE'
    }
  }
};