import FuseAnimate from '@fuse/core/FuseAnimate';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

import accessDeniedImage from './access-denied.svg';

const AccessDenied = ({ children, title, message }) => (
  <div className="flex flex-1 flex-col items-center justify-center p-16">
    <div className="max-w-512 space-y-16 text-center">
      <FuseAnimate delay={500}>
        <Typography variant="h1" className="text-48 font-medium text-gray-500 sm:text-52">
          <img src={accessDeniedImage} alt="Access Denied" className="mr-8 h-96 w-full" />
          <div className="pt-12">{title}</div>
        </Typography>
      </FuseAnimate>

      <FuseAnimate delay={500}>
        <Typography variant="h5" color="textSecondary">
          {message}
        </Typography>
      </FuseAnimate>

      {children && <FuseAnimate delay={500}>{children}</FuseAnimate>}

      <FuseAnimate delay={500}>
        <Typography color="textSecondary">
          If you think you should have access and this page is being displayed in error please contact{' '}
          <a href="mailto:service@riskandsafety.com"> service@RiskandSafety.com </a>
        </Typography>
      </FuseAnimate>
    </div>
  </div>
);

AccessDenied.defaultProps = {
  children: null,
  message: 'You do not have permission to view this page.',
  title: 'Access Denied',
};

AccessDenied.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  title: PropTypes.string,
};

export default AccessDenied;
