import FuseAnimate from '@fuse/core/FuseAnimate';
import { Backdrop, Button, Icon, IconButton, Input, InputAdornment, Paper, ThemeProvider } from '@material-ui/core';
import clsx from 'clsx';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { selectMainTheme } from '../../store/fuse/settingsSlice';

function HeaderSearch({ control, name, children, placeholder, onChange, onSubmit, onReset }) {
  const mainTheme = useSelector(selectMainTheme);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ThemeProvider theme={mainTheme}>
      <form
        noValidate
        autoComplete="off"
        className="max-w-512 flex-grow items-center"
        onChange={(evt) => {
          if (!isOpen) {
            debounce(onChange, 400)();
          }
        }}
        onSubmit={(evt) => {
          setIsOpen(false);
          onSubmit();
          evt.preventDefault();
        }}>
        <Paper className={clsx('flex w-full rounded-12 p-10', isOpen && 'rounded-b-none')} elevation={1}>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="search"
                placeholder={placeholder}
                disableUnderline
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <Icon color="action">search</Icon>
                  </InputAdornment>
                }
                endAdornment={
                  !!children && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsOpen(true)}>
                        <Icon>{isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                      </IconButton>
                    </InputAdornment>
                  )
                }
                inputProps={{ 'aria-label': 'search' }}
              />
            )}
          />
        </Paper>

        {isOpen && (
          <div className="absolute z-10 w-full max-w-512">
            <Backdrop open invisible onClick={() => setIsOpen(false)} />
            <FuseAnimate animation="transition.slideDownIn" duration={200}>
              <Paper className="flex flex-col rounded-t-none p-12">
                {children}
                <div className="flex justify-end gap-16 pb-12 pt-24">
                  <Button
                    onClick={(evt) => {
                      setIsOpen(false);
                      onReset();
                    }}
                    className="w-96"
                    variant="contained">
                    Reset
                  </Button>

                  <Button type="submit" color="primary" variant="contained" className="w-96">
                    Filter
                  </Button>
                </div>
              </Paper>
            </FuseAnimate>
          </div>
        )}
      </form>
    </ThemeProvider>
  );
}

HeaderSearch.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
};

HeaderSearch.defaultProps = {
  name: 'keyword',
  children: null,
  onChange: () => {},
  onSubmit: () => {},
  onReset: () => {},
};
export default HeaderSearch;
