/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FuseUtils from '@fuse/utils';
import { get } from 'lodash';
import { lazy } from 'react';
import { renderRoutes } from 'react-router-config';

import authRoutes from '../auth/routes';
import accountManagementRoutes from '../account-management/routes';
import analyticsRoutes from '../analytics/routes';
import assessmentRoutes from '../assessment/routes';
import { Auth, IdleTimeout, Session } from '../auth';
import awayRoutes from '../away/routes';
import bsasRoutes from '../bsas/routes';
import cliRoutes from '../cli/routes';
import chemicalRoutes from '../chemical/routes';
import developerRoutes from '../developer-tools/routes';
import hazardSignRoutes from '../hazard-sign/routes';
import helpRoutes from '../help/routes';
import inspectRoutes from '../inspect/routes';
import libraryRoutes from '../library/routes';
import locationAreaRoutes from '../location-area/routes';
import nfpaRoutes from '../nfpa/routes';
import notificationTemplateRoutes from '../notification-template/routes';
import clientConfigRoutes from '../client-config/routes';
import procedureRoutes from '../procedure/routes';
import profileRoutes from '../profile/routes';
import rssToolsRoutes from '../rss-tools/routes';
import readOnlyRoutes from './readOnlyRoutes';
import sdsRoutes from '../sds/routes';

const routeConfigs = [
  readOnlyRoutes,
  authRoutes,
  accountManagementRoutes,
  analyticsRoutes,
  awayRoutes,
  bsasRoutes,
  cliRoutes,
  chemicalRoutes,
  clientConfigRoutes,
  developerRoutes,
  hazardSignRoutes,
  helpRoutes,
  inspectRoutes,
  libraryRoutes,
  locationAreaRoutes,
  assessmentRoutes,
  nfpaRoutes,
  notificationTemplateRoutes,
  procedureRoutes,
  profileRoutes,
  rssToolsRoutes,
  sdsRoutes,
  {
    settings: {
      layout: {
        config: {
          footer: {
            display: false,
          },
        },
      },
    },
    auth: ({ profile } = {}) => Boolean(profile),
    routes: [
      {
        path: ['/incident'],
        component: lazy(() => import('../incident-mgmt/LoadRoutes')),
        settings: { helpCategories: ['marketplace'] },
        auth: ({ profile }) => get(profile, 'features.incidentMgmt.showFeature.value'),
      },
      {
        path: ['/store/:storeId', '/shop/:storeId'],
        component: lazy(() => import('../marketplace/StoreFeature')),
        settings: { helpCategories: ['marketplace'] },
      },
      {
        path: '/action-items',
        component: lazy(() => import('../home/containers/ActionItemPage')),
        settings: { helpCategories: ['profile'] },
      },
      {
        path: '/bands/custom-bands',
        component: lazy(() => import('../chemical/containers/CampusBandsPage')),
      },
      {
        path: '/bands',
        component: lazy(() => import('../chemical/containers/BandListPage')),
      },
      {
        path: '/error',
        component: lazy(() => import('../components/errors/AccessDenied')),
      },
      {
        path: '/redirect/assessment/:id',
        exact: true,
        component: lazy(() => import('../assessment/containers/AssessmentRedirect')),
      },
      {
        path: '/workspace',
        component: lazy(() => import('../home/containers/WorkspacePage')),
        settings: { helpCategories: ['profile'] },
      },
      {
        path: '/login',
        auth: [],
        component: () => {
          window.location.replace(`/auth/login${window.location.search}`);
          return null;
        },
      },
      {
        path: '/',
        exact: true,
        component: lazy(() => import('../home/containers/HomePage')),
        settings: {
          layout: {
            config: {
              footer: {
                display: true,
              },
            },
          },
          helpCategories: ['profile'],
        },
      },
    ],
  },
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    component: lazy(() => import('../components/errors/NotFound')),
  },
];

export default routes;
