import { useQuery } from '@apollo/client';
import { FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, Select } from '@material-ui/core';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { LIST_FACILITY_TYPES } from '../graphql/relationship.query';
import { inlineErrorMessages } from '../shared/helper';
import ErrorMessage from './errors/ErrorMessage';

const FacilityTypeSelect = ({ id, value, campusCode, required, errors, onSelect }) => {
  const [selectedFacilityType, setSelectedFacilityType] = useState(value || '');

  const { data, loading, error } = useQuery(LIST_FACILITY_TYPES, { variables: { campusCode } });
  const facilityTypes = data?.listFacilityTypes || [];

  const handleSelect = (type) => {
    onSelect(type);
    setSelectedFacilityType(type);
  };

  const selectId = `${id}_facilityType`;

  if (error) return <ErrorMessage />;

  return (
    <FormControl variant="outlined" fullWidth required={required} error={Boolean(errors?.length)}>
      <InputLabel htmlFor={selectId}>Type</InputLabel>
      <Select
        data-cy="e2e-selectFacilityType"
        variant="outlined"
        label="Type"
        value={selectedFacilityType}
        onChange={(event) => {
          handleSelect(event.target.value);
        }}
        inputProps={{ name: selectId, id: selectId }}>
        {facilityTypes &&
          facilityTypes.map((type) => (
            <MenuItem data-cy={`e2e-${type}`} key={type} value={type}>
              {type}
            </MenuItem>
          ))}
      </Select>
      {loading && <LinearProgress />}
      {!isEmpty(errors) && <FormHelperText>{inlineErrorMessages(errors)}</FormHelperText>}
    </FormControl>
  );
};

FacilityTypeSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  campusCode: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
};

FacilityTypeSelect.defaultProps = {
  id: '',
  value: {},
  campusCode: '',
  required: false,
  errors: [],
};

export default FacilityTypeSelect;
