const FUND_TYPE = require('./fund-type.constant');
const INVENTORY_TYPE = require('./inventory-type.constant');
const LIBRARY_ITEM_SOURCE = require('./library-item.source.constant');
const LIBRARY_ITEM_STATUS = require('./library-item.status.constant');
const ORDER_RECEIVING = require('./order-receiving.constant');
const ORDER_STATUS = require('./order-status.constant');
const {
  PERMISSION_ACTION
} = require('./permission.action.constant');
const STORE_PERMISSION = require('./store-permission.constant');
const WORKFLOW_ACTION = require('./workflow.action.constant');
module.exports = {
  FUND_TYPE,
  INVENTORY_TYPE,
  LIBRARY_ITEM_SOURCE,
  LIBRARY_ITEM_STATUS,
  ORDER_RECEIVING,
  ORDER_STATUS,
  PERMISSION_ACTION,
  STORE_PERMISSION,
  WORKFLOW_ACTION
};