"use strict";

var types = {
  SAFETY_DATA_SHEET: 'Safety Data Sheet',
  TECHNICAL_REFERENCE: 'Technical Reference',
  SPECTRA: 'Spectra',
  ORDERING_INFO: 'Ordering Information',
  OTHER: 'Other'
};
module.exports = types;
module.exports.keys = Object.keys(types);