module.exports = [{
  hazardCode: 'H200',
  category: 'Unstable Explosive',
  hazardClass: 'Explosives',
  hazardStatement: 'Unstable Explosive',
  signalWord: 'Danger'
}, {
  hazardCode: 'H201',
  category: 'Div 1.1',
  hazardClass: 'Explosives',
  hazardStatement: 'Explosive; mass explosion hazard',
  signalWord: 'Danger'
}, {
  hazardCode: 'H202',
  category: 'Div 1.2',
  hazardClass: 'Explosives',
  hazardStatement: 'Explosive; severe projection hazard',
  signalWord: 'Danger'
}, {
  hazardCode: 'H203',
  category: 'Div 1.3',
  hazardClass: 'Explosives',
  hazardStatement: 'Explosive; fire, blast or projection hazard',
  signalWord: 'Danger'
}, {
  hazardCode: 'H204',
  category: 'Div 1.4',
  hazardClass: 'Explosives',
  hazardStatement: 'Fire or projection hazard',
  signalWord: 'Warning'
}, {
  hazardCode: 'H205',
  category: 'Div 1.5',
  hazardClass: 'Explosives',
  hazardStatement: 'May mass explode in fire',
  signalWord: 'Danger'
}, {
  hazardCode: 'H205',
  category: 'Div 1.6*',
  hazardClass: 'Explosives',
  hazardStatement: 'May mass explode in fire',
  signalWord: 'Danger'
}, {
  hazardCode: 'H206',
  category: 'Category 1',
  hazardClass: 'Desensitized explosives',
  hazardStatement: 'Fire, blast or projection hazard; increased risk of explosion if desensitizing agent is reduced',
  signalWord: 'Danger'
}, {
  hazardCode: 'H207',
  category: 'Category 2',
  hazardClass: 'Desensitized explosives',
  hazardStatement: 'Fire or projection hazard; increased risk of explosion if desensitizing agent is reduced',
  signalWord: 'Danger'
}, {
  hazardCode: 'H207',
  category: 'Category 3',
  hazardClass: 'Desensitized explosives',
  hazardStatement: 'Fire or projection hazard; increased risk of explosion if desensitizing agent is reduced',
  signalWord: 'Warning'
}, {
  hazardCode: 'H208',
  category: 'Category 4',
  hazardClass: 'Desensitized explosives',
  hazardStatement: 'Fire hazard; increased risk of explosion if desensitizing agent is reduced',
  signalWord: 'Warning'
}, {
  hazardCode: 'H220',
  category: 'Category 1',
  hazardClass: 'Flammable gases',
  hazardStatement: 'Extremely flammable gas',
  signalWord: 'Danger'
}, {
  hazardCode: 'H220',
  category: 'Category 1A',
  hazardClass: 'Flammable gases',
  hazardStatement: 'Extremely flammable gas',
  signalWord: 'Danger'
}, {
  hazardCode: 'H220',
  category: 'Category A',
  hazardClass: 'Flammable gases',
  hazardStatement: 'Extremely flammable gas',
  signalWord: 'Danger'
}, {
  hazardCode: 'H220',
  category: 'Category B',
  hazardClass: 'Flammable gases',
  hazardStatement: 'Extremely flammable gas',
  signalWord: 'Danger'
}, {
  hazardCode: 'H221',
  category: 'Category 2',
  hazardClass: 'Flammable gases',
  hazardStatement: 'Flammable gas',
  signalWord: 'Warning'
}, {
  hazardCode: 'H221',
  category: 'Category 1B',
  hazardClass: 'Flammable gases',
  hazardStatement: 'Flammable gas',
  signalWord: 'Danger'
}, {
  hazardCode: 'H222',
  category: 'Category 1',
  hazardClass: 'Flammable aerosols',
  hazardStatement: 'Extremely flammable aerosol',
  signalWord: 'Danger'
}, {
  hazardCode: 'H223',
  category: 'Category 2',
  hazardClass: 'Flammable aerosols',
  hazardStatement: 'Flammable aerosol',
  signalWord: 'Warning'
}, {
  hazardCode: 'H224',
  category: 'Category 1',
  hazardClass: 'Flammable liquids',
  hazardStatement: 'Extremely flammable liquid and vapour',
  signalWord: 'Danger'
}, {
  hazardCode: 'H225',
  category: 'Category 2',
  hazardClass: 'Flammable liquids',
  hazardStatement: 'Highly Flammable liquid and vapour',
  signalWord: 'Danger'
}, {
  hazardCode: 'H226',
  category: 'Category 3',
  hazardClass: 'Flammable liquids',
  hazardStatement: 'Flammable liquid and vapour',
  signalWord: 'Warning'
}, {
  hazardCode: 'H227',
  category: 'Category 4',
  hazardClass: 'Flammable liquids',
  hazardStatement: 'Combustible liquid',
  signalWord: 'Warning'
}, {
  hazardCode: 'H228',
  category: 'Category 1',
  hazardClass: 'Flammable solids',
  hazardStatement: 'Flammable solid',
  signalWord: 'Danger'
}, {
  hazardCode: 'H228',
  category: 'Category 2',
  hazardClass: 'Flammable solids',
  hazardStatement: 'Flammable solid',
  signalWord: 'Warning'
}, {
  hazardCode: 'H229',
  category: 'Category 1',
  hazardClass: 'Aerosols',
  hazardStatement: 'Pressurized container: may burst if heated',
  signalWord: 'Danger'
}, {
  hazardCode: 'H229',
  category: 'Category 2',
  hazardClass: 'Aerosols',
  hazardStatement: 'Pressurized container: may burst if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H229',
  category: 'Category 3',
  hazardClass: 'Aerosols',
  hazardStatement: 'Pressurized container: may burst if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H230',
  category: 'Category 1A',
  hazardClass: 'Flammable gases',
  hazardStatement: 'May react explosively even in the absence of air',
  signalWord: 'Danger'
}, {
  hazardCode: 'H230',
  category: 'Category A',
  hazardClass: 'Flammable gases',
  hazardStatement: 'May react explosively even in the absence of air',
  signalWord: 'Danger'
}, {
  hazardCode: 'H231',
  category: 'Category 1A',
  hazardClass: 'Flammable gases',
  hazardStatement: 'May react explosively even in the absence of air at elevated pressure and/or temperature',
  signalWord: 'Danger'
}, {
  hazardCode: 'H231',
  category: 'Category B',
  hazardClass: 'Flammable gases',
  hazardStatement: 'May react explosively even in the absence of air at elevated pressure and/or temperature',
  signalWord: 'Danger'
}, {
  hazardCode: 'H232',
  category: 'Category 1A, Pyrophoric gas',
  hazardClass: 'Flammable gases',
  hazardStatement: 'May ignite spontaneously if exposed to air',
  signalWord: 'Danger'
}, {
  hazardCode: 'H282',
  category: 'Category 1',
  hazardClass: 'Chemicals under pressure',
  hazardStatement: 'Extremely flammable chemical under pressure: may explode if heated',
  signalWord: 'Danger'
}, {
  hazardCode: 'H282',
  category: 'Category 2',
  hazardClass: 'Chemicals under pressure',
  hazardStatement: 'Flammable chemical under pressure: may explode if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H282',
  category: 'Category 3',
  hazardClass: 'Chemicals under pressure',
  hazardStatement: 'Chemical under pressure: may explode if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H240',
  category: 'Type A',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause an explosion',
  signalWord: 'Danger'
}, {
  hazardCode: 'H241',
  category: 'Type B',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause a fire or explosion',
  signalWord: 'Danger'
}, {
  hazardCode: 'H242',
  category: 'Type C',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause a fire ',
  signalWord: 'Danger'
}, {
  hazardCode: 'H242',
  category: 'Type D',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause a fire ',
  signalWord: 'Danger'
}, {
  hazardCode: 'H242',
  category: 'Type E',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause fire',
  signalWord: 'Warning'
}, {
  hazardCode: 'H242',
  category: 'Type F',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause fire',
  signalWord: 'Warning'
}, {
  hazardCode: 'H242',
  category: 'Type G',
  hazardClass: 'Self-reactive substances and mixtures; and Organic peroxides',
  hazardStatement: 'Heating may cause fire',
  signalWord: 'Warning'
}, {
  hazardCode: 'H250',
  category: 'Category 1',
  hazardClass: 'Pyrophoric liquids; Pyrophoric solids',
  hazardStatement: 'Catches fire spontaneously if exposed to air',
  signalWord: 'Danger'
}, {
  hazardCode: 'H251',
  category: 'Category 1',
  hazardClass: 'Self-heating substances and mixtures',
  hazardStatement: 'Self-heating; may catch fire',
  signalWord: 'Danger'
}, {
  hazardCode: 'H252',
  category: 'Category 2',
  hazardClass: 'Self-heating substances and mixtures',
  hazardStatement: 'Self-heating in large quantities; may catch fire',
  signalWord: 'Warning'
}, {
  hazardCode: 'H260',
  category: 'Category 1',
  hazardClass: 'Substances and mixtures which, in contact with water, emit flammable gases',
  hazardStatement: 'In contact with water releases flammable gases which may  ignite spontaneously',
  signalWord: 'Danger'
}, {
  hazardCode: 'H261',
  category: 'Category 2',
  hazardClass: 'Substances and mixtures which, in contact with water, emit flammable gases',
  hazardStatement: 'In contact with water releases flammable gas',
  signalWord: 'Danger'
}, {
  hazardCode: 'H261',
  category: 'Category 3',
  hazardClass: 'Substances and mixtures which, in contact with water, emit flammable gases',
  hazardStatement: 'In contact with water releases flammable gas',
  signalWord: 'Warning'
}, {
  hazardCode: 'H270',
  category: 'Category 1',
  hazardClass: 'Oxidizing gases',
  hazardStatement: 'May cause or intensify  fire; oxidizer',
  signalWord: 'Danger'
}, {
  hazardCode: 'H271',
  category: 'Category 1',
  hazardClass: 'Oxidizing liquids; Oxidizing solids',
  hazardStatement: 'May cause fire or explosion; strong oxidizer',
  signalWord: 'Danger'
}, {
  hazardCode: 'H272',
  category: 'Category 2',
  hazardClass: 'Oxidising liquids; Oxidising solids',
  hazardStatement: 'May intensify fire; oxidizer',
  signalWord: 'Danger'
}, {
  hazardCode: 'H272',
  category: 'Category 3',
  hazardClass: 'Oxidising liquids; Oxidising solids',
  hazardStatement: 'May intensify fire; oxidizer',
  signalWord: 'Warning'
}, {
  hazardCode: 'H280',
  category: 'Compressed Gas',
  hazardClass: 'Gases under pressure',
  hazardStatement: 'Contains gas under pressure; may explode if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H280',
  category: 'Liquefied Gas',
  hazardClass: 'Gases under pressure',
  hazardStatement: 'Contains gas under pressure; may explode if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H280',
  category: 'Dissolved Gas',
  hazardClass: 'Gases under pressure',
  hazardStatement: 'Contains gas under pressure; may explode if heated',
  signalWord: 'Warning'
}, {
  hazardCode: 'H281',
  category: 'Refrigerated liquefied  Gas',
  hazardClass: 'Gases under pressure',
  hazardStatement: 'Contains refrigerated gas; may cause cryogenic burns or injury',
  signalWord: 'Warning'
}, {
  hazardCode: 'H290',
  category: 'Category 1',
  hazardClass: 'Corrosive to metals',
  hazardStatement: 'May be corrosive to metals',
  signalWord: 'Warning'
}, {
  hazardCode: 'H300',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, oral',
  hazardStatement: 'Fatal if swallowed',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, oral',
  hazardStatement: 'Fatal if swallowed',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300 + H310',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, oral & dermal',
  hazardStatement: 'Fatal if swallowed or in contact with skin',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300 + H310',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, oral & dermal',
  hazardStatement: 'Fatal if swallowed or in contact with skin',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300 + H310 + H330',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, oral, dermal & inhalation',
  hazardStatement: 'Fatal if swallowed, in contact with skin or inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300 + H310 + H330',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, oral, dermal & inhalation',
  hazardStatement: 'Fatal if swallowed, in contact with skin or inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300 + H330',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, oral & inhalation',
  hazardStatement: 'Fatal if swallowed or inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H300 + H330',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, oral & inhalation',
  hazardStatement: 'Fatal if swallowed or inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H301',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, oral',
  hazardStatement: 'Toxic if swallowed',
  signalWord: 'Danger'
}, {
  hazardCode: 'H301 + H311 + H331',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, oral, dermal & inhalation',
  hazardStatement: 'Toxic if swallowed, in contact with skin or if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H301 + H311',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, oral & dermal',
  hazardStatement: 'Toxic if swallowed or in contact with skin',
  signalWord: 'Danger'
}, {
  hazardCode: 'H301 + H331',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, oral & inhalation',
  hazardStatement: 'Toxic if swallowed or if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H302',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, oral',
  hazardStatement: 'Harmful if swallowed',
  signalWord: 'Warning'
}, {
  hazardCode: 'H302 + H312',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, oral & dermal',
  hazardStatement: 'Harmful if swallowed or if in contact with skin',
  signalWord: 'Warning'
}, {
  hazardCode: 'H302 + H312 + H332',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, oral, dermal & inhalation',
  hazardStatement: 'Harmful if swallowed, in contact with skin or if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H302 + H332',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, oral & inhalation',
  hazardStatement: 'Harmful if swallowed or if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H303',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, oral',
  hazardStatement: 'May be harmful if swallowed',
  signalWord: 'Warning'
}, {
  hazardCode: 'H303 + H313',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, oral & dermal',
  hazardStatement: 'May be harmful if swallowed or if in contact with skin',
  signalWord: 'Warning'
}, {
  hazardCode: 'H303 + H313 + H333',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, oral, dermal & inhalation',
  hazardStatement: 'May be harmful if swallowed, in contact with skin or if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H303 + H333',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, oral & inhalation',
  hazardStatement: 'May be harmful if swallowed or if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H304',
  category: 'Category 1',
  hazardClass: 'Aspiration hazard',
  hazardStatement: 'May be fatal if swallowed and enters airways',
  signalWord: 'Danger'
}, {
  hazardCode: 'H305',
  category: 'Category 2',
  hazardClass: 'Aspiration hazard',
  hazardStatement: 'May be harmful if swallowed and enters airways',
  signalWord: 'Warning'
}, {
  hazardCode: 'H310',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, dermal',
  hazardStatement: 'Fatal in contact with skin',
  signalWord: 'Danger'
}, {
  hazardCode: 'H310',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, dermal',
  hazardStatement: 'Fatal in contact with skin',
  signalWord: 'Danger'
}, {
  hazardCode: 'H310 + H330',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, dermal & inhalation',
  hazardStatement: 'Fatal if in contact with skin or if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H310 + H330',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, dermal & inhalation',
  hazardStatement: 'Fatal if in contact with skin or if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H311',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, dermal',
  hazardStatement: 'Toxic in contact with skin',
  signalWord: 'Danger'
}, {
  hazardCode: 'H311 + H331',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, dermal & inhalation',
  hazardStatement: 'Toxic in contact with skin or if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H312',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, dermal',
  hazardStatement: 'Harmful in contact with skin',
  signalWord: 'Warning'
}, {
  hazardCode: 'H312 + H332',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, dermal & inhalation',
  hazardStatement: 'Harmful if in contact with skin or if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H313',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, dermal',
  hazardStatement: 'May be harmful in contact with skin',
  signalWord: 'Warning'
}, {
  hazardCode: 'H313 + H333',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, dermal & inhalation',
  hazardStatement: 'May be harmful if in contact with skin or if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H314',
  category: 'Category 1A',
  hazardClass: 'Skin corrosion/irritation',
  hazardStatement: 'Causes severe skin burns and eye damage',
  signalWord: 'Danger'
}, {
  hazardCode: 'H314',
  category: 'Category 1B',
  hazardClass: 'Skin corrosion/irritation',
  hazardStatement: 'Causes severe skin burns and eye damage',
  signalWord: 'Danger'
}, {
  hazardCode: 'H314',
  category: 'Category 1C',
  hazardClass: 'Skin corrosion/irritation',
  hazardStatement: 'Causes severe skin burns and eye damage',
  signalWord: 'Danger'
}, {
  hazardCode: 'H315',
  category: 'Category 2',
  hazardClass: 'Skin corrosion/irritation',
  hazardStatement: 'Causes skin irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H315 + H320',
  category: 'Category 2',
  hazardClass: 'Skin corrosion/irritation and serious eye damage/eye irritation',
  hazardStatement: 'Cause skin and eye irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H315 + H320',
  category: 'Category 2B',
  hazardClass: 'Skin corrosion/irritation and serious eye damage/eye irritation',
  hazardStatement: 'Cause skin and eye irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H315 + H320',
  category: '2(skin)/2A(eye)',
  hazardClass: 'Skin corrosion/irritation & serious eye damage/irritation',
  hazardStatement: 'Causes skin and eye irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H316',
  category: 'Category 3',
  hazardClass: 'Skin corrosion/irritation',
  hazardStatement: 'Causes mild skin irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H317',
  category: 'Category 1',
  hazardClass: 'Sensitization, skin',
  hazardStatement: 'May cause an allergic skin reaction',
  signalWord: 'Warning'
}, {
  hazardCode: 'H317',
  category: 'Category 1A',
  hazardClass: 'Sensitization, skin',
  hazardStatement: 'May cause an allergic skin reaction',
  signalWord: 'Warning'
}, {
  hazardCode: 'H317',
  category: 'Category 1B',
  hazardClass: 'Sensitization, skin',
  hazardStatement: 'May cause an allergic skin reaction',
  signalWord: 'Warning'
}, {
  hazardCode: 'H318',
  category: 'Category 1',
  hazardClass: 'Serious eye damage/eye irritation',
  hazardStatement: 'Causes serious eye damage',
  signalWord: 'Danger'
}, {
  hazardCode: 'H319',
  category: 'Category 2A',
  hazardClass: 'Serious eye damage/eye irritation',
  hazardStatement: 'Causes serious eye irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H320',
  category: 'Category 2B',
  hazardClass: 'Serious eye damage/eye irritation',
  hazardStatement: 'Causes eye irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H330',
  category: 'Category 1',
  hazardClass: 'Acute toxicity, inhalation',
  hazardStatement: 'Fatal if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H330',
  category: 'Category 2',
  hazardClass: 'Acute toxicity, inhalation',
  hazardStatement: 'Fatal if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H331',
  category: 'Category 3',
  hazardClass: 'Acute toxicity, inhalation',
  hazardStatement: 'Toxic if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H332',
  category: 'Category 4',
  hazardClass: 'Acute toxicity, inhalation',
  hazardStatement: 'Harmful if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H333',
  category: 'Category 5',
  hazardClass: 'Acute toxicity, inhalation',
  hazardStatement: 'May be harmful if inhaled',
  signalWord: 'Warning'
}, {
  hazardCode: 'H334',
  category: 'Category 1',
  hazardClass: 'Sensitization, respiratory',
  hazardStatement: 'May cause allergy or asthma symptoms or breathing difficulties if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H334',
  category: 'Category 1A',
  hazardClass: 'Sensitization, respiratory',
  hazardStatement: 'May cause allergy or asthma symptoms or breathing difficulties if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H334',
  category: 'Category 1B',
  hazardClass: 'Sensitization, respiratory',
  hazardStatement: 'May cause allergy or asthma symptoms or breathing difficulties if inhaled',
  signalWord: 'Danger'
}, {
  hazardCode: 'H335',
  category: 'Category 3',
  hazardClass: 'Specific target organ toxicity, single exposure',
  hazardStatement: 'May cause respiratory irritation',
  signalWord: 'Warning'
}, {
  hazardCode: 'H335 + H336',
  category: 'Category 3',
  hazardClass: 'Specific target organ toxicity, single exposure; Respiratory tract irritation & narcotic effects',
  hazardStatement: 'May cause respiratory irritation, and drowsiness or dizziness.',
  signalWord: 'Danger'
}, {
  hazardCode: 'H336',
  category: 'Category 3',
  hazardClass: 'Specific target organ toxicity, single exposure',
  hazardStatement: 'May cause drowsiness or dizziness',
  signalWord: 'Warning'
}, {
  hazardCode: 'H340',
  category: 'Category 1A',
  hazardClass: 'Germ cell mutagenicity',
  hazardStatement: 'May cause genetic defects',
  signalWord: 'Danger'
}, {
  hazardCode: 'H340',
  category: 'Category 1B',
  hazardClass: 'Germ cell mutagenicity',
  hazardStatement: 'May cause genetic defects',
  signalWord: 'Danger'
}, {
  hazardCode: 'H341',
  category: 'Category 2',
  hazardClass: 'Germ cell mutagenicity',
  hazardStatement: 'Suspected of causing genetic defects',
  signalWord: 'Warning'
}, {
  hazardCode: 'H350',
  category: 'Category 1A',
  hazardClass: 'Carcinogenicity',
  hazardStatement: 'May cause cancer',
  signalWord: 'Danger'
}, {
  hazardCode: 'H350',
  category: 'Category 1B',
  hazardClass: 'Carcinogenicity',
  hazardStatement: 'May cause cancer',
  signalWord: 'Danger'
}, {
  hazardCode: 'H350i',
  category: 'Category 1A',
  hazardClass: 'Carcinogenicity',
  hazardStatement: 'May cause cancer by inhalation',
  signalWord: 'Danger'
}, {
  hazardCode: 'H350i',
  category: 'Category 1B',
  hazardClass: 'Carcinogenicity',
  hazardStatement: 'May cause cancer by inhalation',
  signalWord: 'Danger'
}, {
  hazardCode: 'H351',
  category: 'Category 2',
  hazardClass: 'Carcinogenicity',
  hazardStatement: 'Suspected of causing cancer',
  signalWord: 'Warning'
}, {
  hazardCode: 'H360',
  category: 'Category 1A',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility or the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360',
  category: 'Category 1B',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility or the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360F',
  category: 'Category 1A',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360F',
  category: 'Category 1B',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360D',
  category: 'Category 1A',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360D',
  category: 'Category 1B',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360FD',
  category: 'Category 1A',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility; May damage the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360FD',
  category: 'Category 1B',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility; May damage the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360Fd',
  category: 'Category 1A',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility; Suspected of damaging the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360Fd',
  category: 'Category 1B',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage fertility; Suspected of damaging the unborn child',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360fD',
  category: 'Category 1A',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage the unborn child; Suspected of damaging fertility',
  signalWord: 'Danger'
}, {
  hazardCode: 'H360fD',
  category: 'Category 1B',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'May damage the unborn child; Suspected of damaging fertility',
  signalWord: 'Danger'
}, {
  hazardCode: 'H361',
  category: 'Category 2',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'Suspected of damaging fertility or the unborn child',
  signalWord: 'Warning'
}, {
  hazardCode: 'H361f',
  category: 'Category 2',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'Suspected of damaging fertility',
  signalWord: 'Warning'
}, {
  hazardCode: 'H361d',
  category: 'Category 2',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'Suspected of damaging the unborn child',
  signalWord: 'Warning'
}, {
  hazardCode: 'H361fd',
  category: 'Category 2',
  hazardClass: 'Reproductive toxicity',
  hazardStatement: 'Suspected of damaging fertility; Suspected of damaging the unborn child',
  signalWord: 'Warning'
}, {
  hazardCode: 'H362',
  category: 'Additional',
  hazardClass: 'Reproductive toxicity, effects on or via lactation',
  hazardStatement: 'May cause harm to breast-fed children',
  signalWord: null
}, {
  hazardCode: 'H370',
  category: 'Category 1',
  hazardClass: 'Specific target organ toxicity, single exposure',
  hazardStatement: 'Causes damage to organs',
  signalWord: 'Danger'
}, {
  hazardCode: 'H371',
  category: 'Category 2',
  hazardClass: 'Specific target organ toxicity, single exposure',
  hazardStatement: 'May cause damage to organs',
  signalWord: 'Warning'
}, {
  hazardCode: 'H372',
  category: 'Category 1',
  hazardClass: 'Specific target organ toxicity, repeated exposure',
  hazardStatement: 'Causes damage to organs through prolonged or repeated exposure',
  signalWord: 'Danger'
}, {
  hazardCode: 'H373',
  category: 'Category 2',
  hazardClass: 'Specific target organ toxicity, repeated exposure',
  hazardStatement: 'May cause damage to organs through prolonged or  repeated exposure',
  signalWord: 'Warning'
}, {
  hazardCode: 'H400',
  category: 'Category 1',
  hazardClass: 'Hazardous to the aquatic environment, acute hazard',
  hazardStatement: 'Very toxic to aquatic life',
  signalWord: 'Warning'
}, {
  hazardCode: 'H401',
  category: 'Category 2',
  hazardClass: 'Hazardous to the aquatic environment, acute hazard',
  hazardStatement: 'Toxic to aquatic life',
  signalWord: 'Warning'
}, {
  hazardCode: 'H402',
  category: 'Category 3',
  hazardClass: 'Hazardous to the aquatic environment, acute hazard',
  hazardStatement: 'Harmful to aquatic life',
  signalWord: 'Warning'
}, {
  hazardCode: 'H410',
  category: 'Category 1',
  hazardClass: 'Hazardous to the aquatic environment, long-term hazard',
  hazardStatement: 'Very toxic to aquatic life with long lasting effects',
  signalWord: 'Warning'
}, {
  hazardCode: 'H411',
  category: 'Category 2',
  hazardClass: 'Hazardous to the aquatic environment, long-term hazard',
  hazardStatement: 'Toxic to aquatic life with long lasting effects',
  signalWord: 'Warning'
}, {
  hazardCode: 'H412',
  category: 'Category 3',
  hazardClass: 'Hazardous to the aquatic environment, long-term hazard',
  hazardStatement: 'Harmful to aquatic life with long lasting effects',
  signalWord: 'Warning'
}, {
  hazardCode: 'H413',
  category: 'Category 4',
  hazardClass: 'Hazardous to the aquatic environment, long-term hazard',
  hazardStatement: 'May cause long lasting harmful effects to aquatic life',
  signalWord: 'Warning'
}, {
  hazardCode: 'H420',
  category: 'Category 1',
  hazardClass: 'Hazardous to the ozone layer',
  hazardStatement: 'Harms public health and the environment by destroying ozone in the upper atmosphere',
  signalWord: 'Warning'
}];