import { useMutation } from '@apollo/client';
import { Button, DialogContentText, Grid, Icon, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { object, string } from 'yup';

import { UPSERT_ROUTING_LIST } from '../graphql/relationship.query';
import { PROFILE_QUERY } from '../graphql/profile.query';
import { inlineErrorMessages, mapYupValidationErrorToErrorMap } from '../shared/helper';
import DomainSelect from './DomainSelect';
import DialogTemplate from './DialogTemplate';

const ROUTING_LIST_SCHEMA = object().shape({
  id: string(),
  name: string().required().label('Routing List Name'),
  domain: string().required().label('Domain'),
});

const ManageRoutingListDialog = ({
  closeDialog,
  description,
  isOpen,
  onSaveComplete,
  title,
  tenantCode,
  campusCode,
  domainId,
  isCreate,
  rlData,
}) => {
  const [errors, setErrors] = useState(null);
  const [name, setName] = useState(rlData?.name || '');
  const [domain, setDomain] = useState(rlData?.domain || domainId || '');

  useEffect(() => {
    if (rlData && !isOpen) {
      setName(rlData.name);
      setDomain(rlData.domain);
    }
  }, [isOpen, rlData]);

  useEffect(() => {
    if (domainId) {
      setDomain(domainId);
    }
  }, [domainId]);

  const domainVariant = campusCode ? 'domain' : 'campus';

  const getRefetchQueries = () => {
    return [{ query: PROFILE_QUERY }];
  };

  const clear = () => {
    setName('');
    setDomain('');
    setErrors(null);
    closeDialog();
  };

  const [upsertRoutingList, { loading }] = useMutation(UPSERT_ROUTING_LIST, {
    refetchQueries: getRefetchQueries,
    onError: (error) => {
      if (/ROUTING_LIST_EXISTS/.test(error.message)) {
        return setErrors({
          name: ['Routing list name is already in use. Please provide a new name.'],
        });
      }

      return setErrors({
        server: (
          <>
            <Icon className="mr-5">warning</Icon>An error has occured while saving the routing list.
          </>
        ),
      });
    },
    onCompleted: (data) => {
      clear();
      onSaveComplete(data.upsertRoutingList);
    },
  });

  const isFormValid = async () => {
    setErrors(null);
    return ROUTING_LIST_SCHEMA.validate({ domain, name }, { abortEarly: false })
      .then(() => true)
      .catch((err) => {
        setErrors(mapYupValidationErrorToErrorMap(err));
        return false;
      });
  };

  return (
    <DialogTemplate
      open={isOpen}
      title={title}
      loading={loading}
      contentScrollable
      content={
        <div className="space-y-16">
          {description && <DialogContentText className="whitespace-pre-wrap">{description}</DialogContentText>}

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <DomainSelect
                tenantCode={tenantCode}
                campusCode={campusCode}
                value={domain}
                variant={domainVariant}
                required
                disabled={!isCreate || !!domainId}
                onSelect={(e) => setDomain(e.domainId)}
                errors={errors?.domain || []}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="routing-list-name"
                placeholder="Enter Routing List Name"
                label="Routing List Name"
                type="input"
                variant="outlined"
                autoFocus
                autoComplete="off"
                fullWidth
                required
                value={name || ''}
                onChange={(event) => setName(event.target.value)}
                inputProps={{ maxLength: 250, 'aria-label': `Enter Routing List Name` }}
                data-cy="e2e-org-name-input"
                error={!!errors?.name?.length}
                helperText={inlineErrorMessages(errors?.name)}
              />
            </Grid>
            {errors && errors?.server && (
              <Grid item xs={12}>
                <div className="flex items-center text-danger-800">{errors?.server}</div>
              </Grid>
            )}
          </Grid>
        </div>
      }
      primaryActions={
        <>
          <Button variant="contained" onClick={clear}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async (event) => {
              event.preventDefault();
              if (await isFormValid()) {
                upsertRoutingList({
                  variables: {
                    id: rlData?.id,
                    domainId: domain,
                    name,
                    campusCode,
                  },
                });
              }
            }}>
            {!isCreate ? 'Save' : 'Create'}
          </Button>
        </>
      }
    />
  );
};

ManageRoutingListDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSaveComplete: PropTypes.func,
  title: PropTypes.string.isRequired,
  tenantCode: PropTypes.string.isRequired,
  campusCode: PropTypes.string,
  domainId: PropTypes.string,
  isCreate: PropTypes.bool,
  rlData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    domain: PropTypes.string,
  }),
};

ManageRoutingListDialog.defaultProps = {
  description: null,
  campusCode: null,
  domainId: null,
  isCreate: true,
  rlData: {},
  onSaveComplete: () => {},
};

export default ManageRoutingListDialog;
