import { useEffect, useState } from 'react';

import config from '../config';

const useCampuses = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [campuses, setCampuses] = useState([]);

  useEffect(() => {
    async function fetchCampuses() {
      const results = await fetch(`${config.GRAPH_URL}/campus`);
      const data = await results.json();

      setCampuses(data.campuses);
      setIsLoading(false);
    }

    fetchCampuses();
  }, []);

  return { isLoading, campuses };
};

export default useCampuses;
