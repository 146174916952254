import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import { Store } from '@risk-and-safety/library';
import { format } from 'date-fns';
import { groupBy, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import TableWithHeader from './TableWithHeader';
import StoreLocations from './StoreLocations';
import { DELETE_PRODUCT_ITEMS, UPDATE_PRODUCT_ITEMS } from '../graphql/query';
import BarcodeInput from './BarcodeInput';
import { ButtonProgress } from 'app/components';

const BarcodeEdit = ({ selected, barcodes, setBarcodes, campusCode }) => {
  const [invalidPattern, setInvalidPattern] = useState({
    barcode: null,
    isInvalid: false,
    message: 'is invalid. Please enter a valid barcode.',
  });
  const [invalidInUse, setInvalidInUse] = useState({ barcode: null, isInvalid: false, message: 'is already in use' });

  return (
    <>
      <BarcodeInput
        campusCode={campusCode}
        placeholder="Scan barcodes continously"
        onValidBarcode={(validBarcode) => {
          if (!barcodes.has(validBarcode) && barcodes.size === selected.length) {
            setBarcodes((previousState) => {
              const copyArr = [...previousState];
              copyArr.splice(-1);
              const result = new Set(copyArr);
              result.add(validBarcode);
              return result;
            });
          } else {
            setBarcodes((previousState) => {
              const result = new Set(previousState);
              result.add(validBarcode);
              return result;
            });
          }

          setInvalidPattern((prevState) => ({ ...prevState, barcode: null, isInvalid: false }));
          setInvalidInUse((prevState) => ({ ...prevState, barcode: null, isInvalid: false }));
        }}
        onInvalidDuplicateBarcode={(invalidBarcode) => {
          setInvalidInUse((prevState) => ({ ...prevState, barcode: invalidBarcode, isInvalid: true }));
          setInvalidPattern((prevState) => ({ ...prevState, barcode: null, isInvalid: false }));
        }}
        onInvalidPatternBarcode={(invalidBarcode) => {
          setInvalidPattern((prevState) => ({ ...prevState, barcode: invalidBarcode, isInvalid: true }));
          setInvalidInUse((prevState) => ({ ...prevState, barcode: null, isInvalid: false }));
        }}
      />
      <div className="grid grid-cols-4 gap-4">
        {Array.from(barcodes).map((b) => (
          <Tooltip title={b} aria-label={b}>
            <Chip
              key={b}
              label={b}
              onDelete={() => {
                setBarcodes((previousState) => {
                  const result = new Set(previousState);
                  result.delete(b);
                  return result;
                });
              }}
            />
          </Tooltip>
        ))}
      </div>

      {(invalidPattern.isInvalid || invalidInUse.isInvalid) && (
        <FormHelperText error={invalidPattern.isInvalid || invalidInUse.isInvalid}>
          Barcode: {invalidInUse?.barcode || invalidPattern?.barcode}{' '}
          {invalidPattern.isInvalid ? invalidPattern.message : invalidInUse.message}
        </FormHelperText>
      )}
    </>
  );
};
function ProductStockTable({ stock, store, barcodeRequired }) {
  const { productId, storeId } = useParams();
  const [selected, setSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [location, setLocation] = useState(null);
  const [deleteProductStock] = useMutation(DELETE_PRODUCT_ITEMS);
  const [updateProductStock, { loading }] = useMutation(UPDATE_PRODUCT_ITEMS);
  const [barcodes, setBarcodes] = useState(new Set([]));
  const buildings = useMemo(
    () =>
      groupBy(
        sortBy(
          stock,
          (item) => `${item.location.buildingName}${item.location.roomNumber}${item.location.subloctionName}`,
        ),
        (item) => item.location.buildingId,
      ),
    [stock],
  );

  return (
    <>
      <Dialog open={openDialog} fullWidth>
        <DialogTitle>Update selected inventory items location</DialogTitle>
        <DialogContent id="location" className="flex flex-col space-y-12 text-base">
          <StoreLocations sublocations={store?.inventory?.sublocations} setLocation={(loc) => setLocation(loc)} />
          {barcodeRequired && (
            <BarcodeEdit
              campusCode={store.campusCode}
              selected={selected}
              barcodes={barcodes}
              setBarcodes={setBarcodes}
            />
          )}
        </DialogContent>
        <DialogActions className="m-16">
          <Button variant="contained" onClick={() => setOpenDialog(false)} className="mr-16">
            Cancel
          </Button>
          <ButtonProgress
            variant="contained"
            color="primary"
            loading={loading}
            onClick={async () => {
              const barcodeItr = barcodes.values();

              await updateProductStock({
                variables: {
                  storeId,
                  productId,
                  items: selected.map((item) => {
                    const { __typename, ...s } = item;
                    return {
                      ...s,
                      barcode: barcodeItr.next().value ?? s?.barcode,
                      location: {
                        buildingName: location.buildingName,
                        buildingId: location.buildingId,
                        floorId: location.floorId,
                        floorName: location.floorName,
                        roomId: location.roomId,
                        roomNumber: location.roomNumber,
                        sublocationId: location.sublocationId,
                        sublocationName: location.sublocationName,
                      },
                    };
                  }),
                },
              });
              setOpenDialog(false);
            }}
            autoFocus>
            Save
          </ButtonProgress>
        </DialogActions>
      </Dialog>

      {buildings &&
        Object.keys(buildings).map((key) => (
          <div className="-mt-14 pb-52" key={key}>
            <TableWithHeader
              enableCheckbox
              allowMultipleEdit
              icon="apartment"
              title={buildings[key][0].location.buildingName}
              columns={[
                { Header: 'Barcode', accessor: 'barcode', className: 'w-160' },
                { Header: 'Room', accessor: 'location.roomNumber', className: 'w-128' },
                { Header: 'Sublocation', accessor: 'location.sublocationName' },
                {
                  Header: 'Created Date',
                  accessor: 'createdDate',
                  className: 'w-128',
                  Cell: ({ value }) => format(new Date(value), 'P'),
                },
              ]}
              data={buildings[key]}
              onEdit={(values) => {
                setSelected(values);

                if (barcodeRequired) {
                  setBarcodes(new Set());
                }

                setOpenDialog(true);
              }}
              onRemove={(values) =>
                deleteProductStock({
                  variables: { productId, ids: values.map((item) => item._id), storeId },
                })
              }
            />
          </div>
        ))}
    </>
  );
}

ProductStockTable.propTypes = {
  stock: PropTypes.arrayOf(PropTypes.any).isRequired,
  store: PropTypes.shape(Store).isRequired,
};

export default ProductStockTable;
