const {
  ObjectIdSchema,
  RssModel,
  RELATIONSHIP_LABEL: {
    NODE
  }
} = require('@rss/common');
const {
  ObjectId
} = require('bson');
const yup = require('yup');
const FundReferenceSchema = require('./fund-reference.schema');
class StoreAccount extends RssModel {
  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema().default(() => new ObjectId()).required(),
      storeId: new ObjectIdSchema(),
      programName: yup.string().required(),
      administeredBy: yup.object({
        id: yup.string().required(),
        label: yup.string().oneOf([NODE.INVENTORY]).required(),
        name: yup.string().required(),
        ownerLastName: yup.string().required()
      }),
      funds: yup.array().of(FundReferenceSchema.Fund.concat(yup.object({
        id: new ObjectIdSchema().default(() => new ObjectId()).required(),
        name: yup.string().required(),
        default: yup.boolean().default(false)
      }))).default([]),
      deliveryLocations: yup.array().of(yup.object({
        id: new ObjectIdSchema().default(() => new ObjectId()).required(),
        buildingId: yup.string().required(),
        buildingName: yup.string().required(),
        floorId: yup.string(),
        floorName: yup.string(),
        roomId: yup.string().required(),
        roomNumber: yup.string().required(),
        sublocationId: yup.string(),
        sublocationName: yup.string(),
        default: yup.boolean().default(false)
      })).default([]),
      createdBy: yup.object({
        userId: yup.string().required()
      }),
      createdDate: yup.date().default(new Date()).required(),
      lastModifiedBy: yup.object({
        userId: yup.string().required()
      }),
      lastModifiedDate: yup.date().default(new Date()).required()
    });
  }
}
module.exports = StoreAccount;