import { IDLE_KEY, IDLE_TIMEOUT } from '@rss/common';
import React, { useEffect, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';

import IdleTimeoutDialog from './IdleTimeoutDialog';
import { logoutUser } from '../store/userSlice';
import { isIdleSession, shouldShowWarning, updateTimeoutTime } from '../auth.helper';
import { useSecurity } from 'app/hooks';

let idleTimer = null;

export default function IdleTimeout() {
  const dispatch = useDispatch();
  const [idleTimeout, setIdleTimeout] = useState(localStorage.getItem(IDLE_KEY.IDLE_MINUTES_KEY));
  const [showIdleWarning, setShowIdleWarning] = useState(false);
  const campus = useSelector(({ auth }) => auth.user.data.campus);

  const browserSecurity = useSecurity();

  useEffect(() => {
    const campusIdleTimeout = campus?.idleTimeoutInMinutes;
    if (campusIdleTimeout) {
      updateTimeoutTime(Date.now());
      localStorage.setItem(IDLE_KEY.IDLE_MINUTES_KEY, campusIdleTimeout);
      setIdleTimeout(campusIdleTimeout);
    }
  }, [campus]);

  if (!idleTimeout) {
    return null;
  }

  if (!showIdleWarning && isIdleSession(idleTimeout)) {
    dispatch(logoutUser(browserSecurity));
  }

  return (
    <>
      <IdleTimer
        ref={(r) => {
          idleTimer = r;
        }}
        timeout={(idleTimeout - IDLE_TIMEOUT.IDLE_TIMOUT_DURATION_MINUTES) * 60000}
        onAction={() => {
          updateTimeoutTime(idleTimer.getLastActiveTime());
        }}
        onIdle={() => {
          if (shouldShowWarning()) {
            setShowIdleWarning(true);
          }
        }}
      />
      {showIdleWarning && (
        <IdleTimeoutDialog
          duration={IDLE_TIMEOUT.IDLE_TIMOUT_DURATION_MINUTES * 60}
          onClose={() => setShowIdleWarning(false)}
        />
      )}
    </>
  );
}
