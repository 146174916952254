const {
  CONTAINER_TYPE,
  PHYSICAL_STATE,
  UNITS
} = require('@risk-and-safety/chemical');
const {
  RssModel
} = require('@rss/common');
const yup = require('yup');
class ChemicalDetails extends RssModel {
  constructor(payload = {}) {
    super(payload);
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      physicalState: yup.string().oneOf([...PHYSICAL_STATE.map(state => state.value), null]).default(null).nullable(),
      size: yup.number().positive().required(),
      type: yup.string().oneOf(CONTAINER_TYPE.map(type => type.value)).required(),
      units: yup.string().oneOf(UNITS.map(unit => unit.value)).required(),
      density: yup.object({
        value: yup.number().nullable(),
        units: yup.string().nullable()
      }).defined()
    });
  }
}
module.exports = ChemicalDetails;