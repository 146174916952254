import { TextField, Typography } from '@material-ui/core';
import { FqType } from '@rss/common';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { hasErrors, QUESTION_VARIANT } from './Helper';

const FqInputBox = React.memo(
  ({
    autoFocus,
    disabled,
    error,
    fqType,
    hintText,
    inputProps,
    isPrintView,
    label,
    labelVariant,
    variant,
    onChange,
    onError,
    placeholder,
    questionKey,
    required,
    rows,
    maxRows,
    value,
    delay,
    helperText,
    showCharCounter,
  }) => {
    const [inputValue, setInputValue] = useState(value);
    const [err, setErr] = useState(error);
    const textRef = useRef(null);
    const isMultiline = fqType === FqType.TEXTAREA;
    const rowsMin = isMultiline ? { rowsMin: rows } : {};
    const rowsMax = isMultiline && maxRows && !isPrintView ? { rowsMax: maxRows } : {};
    const maxChars = inputProps?.maxLength || 0;
    const [charsLeft, setCharsLeft] = useState(maxChars - (inputValue || '').length);

    const [errors, setErrors] = useState([]);
    useEffect(() => {
      setInputValue(value);
    }, [value]);

    useEffect(() => {
      setErr(error);
    }, [error]);

    const type = inputProps?.type || 'text';

    if (type === 'number' && !inputProps?.step) {
      inputProps = {
        ...inputProps,
        step: 'any',
      };
    }

    return (
      <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
        <TextField
          type={type}
          disabled={disabled}
          autoFocus={autoFocus}
          error={err || !isEmpty(errors)}
          fullWidth
          inputProps={{
            min: 0,
            ...inputProps,
            className: isPrintView ? 'text-gray-900' : null,
            ref: textRef,
            'aria-label': hintText || label || placeholder,
            ...rowsMin,
            ...rowsMax,
          }}
          multiline={isMultiline}
          onChange={(e) => {
            const validationErrors = hasErrors(e.target.value, { type: fqType, required, inputProps });
            onError(e.target.value, questionKey);
            setErrors(validationErrors);
            setInputValue(e.target.value);
            if (maxChars) {
              setCharsLeft(maxChars - e.target.value.length);
            }
          }}
          onBlur={(e) => {
            onChange(e.target.value, questionKey);
          }}
          placeholder={placeholder}
          required={required}
          value={inputValue ?? ''}
          variant="outlined"
          autoComplete="off"
          label={hintText || (labelVariant !== 'long' && label) || ''}
          helperText={!err && (errors.join(' ') || helperText)}
          FormHelperTextProps={{ classes: { root: 'ml-0 text-14' } }}
          onKeyDown={(evt) => type === 'number' && evt.key === 'e' && evt.preventDefault()}
          onWheel={(event) => event.target.blur()}
        />

        {showCharCounter && maxChars && (
          <Typography variant="body1" className="-mt-8 py-8 text-gray-400">
            {`${charsLeft} of ${maxChars} characters left.`}
          </Typography>
        )}
      </div>
    );
  },
);

FqInputBox.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fqType: PropTypes.string,
  hintText: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any),
  isPrintView: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  maxRows: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  questionKey: PropTypes.string.isRequired,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  delay: PropTypes.bool,
  helperText: PropTypes.string,
  showCharCounter: PropTypes.bool,
};

FqInputBox.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  fqType: FqType.INPUT,
  hintText: null,
  inputProps: {},
  onError: () => {},
  label: '',
  labelVariant: 'default',
  variant: QUESTION_VARIANT.STANDARD,
  maxRows: null,
  required: false,
  isPrintView: false,
  placeholder: null,
  rows: 4,
  delay: true,
  helperText: '',
  showCharCounter: false,
};

export default FqInputBox;
