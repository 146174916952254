"use strict";

module.exports = [{
  label: 'deg. C',
  value: 'deg. C'
}, {
  label: 'deg. F',
  value: 'deg. F'
}, {
  label: 'K',
  value: 'K'
}];