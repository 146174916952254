/* eslint-disable react/no-array-index-key */
import { Button, Icon, List, ListSubheader, Paper, Typography } from '@material-ui/core';
import { Order, Store } from '@risk-and-safety/library';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

function OrderConfirmation({ order = {}, store }) {
  const history = useHistory();

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center py-12">
        <Icon className="text-40 text-success-500">check_circle</Icon>
        <Typography variant="h6" className="mx-12">
          Your order was placed.
        </Typography>
      </div>

      <Paper className="s:min-w-0 my-12 min-w-512 rounded-8 shadow-1">
        <List subheader={<ListSubheader component="div">Order Summary</ListSubheader>}>
          <div className="p-16">
            <Typography>
              Order Number: <span className="font-semibold">{order._id}</span>
            </Typography>
            <Typography>Date: {format(new Date(order.createdDate), 'PPP p')}</Typography>
          </div>
        </List>
      </Paper>

      <Paper className="s:min-w-0 mb-12 min-w-512 rounded-8 shadow-1">
        <List subheader={<ListSubheader component="div">Store Information</ListSubheader>}>
          <div className="p-16">
            <Typography variant="subtitle1" className="font-semibold">
              {store.name}
            </Typography>
            <Typography>{store.description}</Typography>

            <div className="my-20 mr-20 flex justify-between">
              <Typography>
                {store.address?.split('\n').map((item, index) => (
                  <span key={`address_${index}`} className="block">
                    {item}
                  </span>
                ))}
              </Typography>

              <div>
                {store.hours.from && (
                  <Typography>
                    Open from {format(new Date(store.hours.from), 'h:mm a')}
                    {store.hours.to ? ` to ${format(new Date(store.hours.to), 'h:mm a')}` : ''}
                  </Typography>
                )}
                {store.phone && <Typography>{store.phone}</Typography>}
                {store.email && <Typography>{store.email}</Typography>}
              </div>
            </div>
          </div>
        </List>
      </Paper>
      <Button size="large" variant="contained" color="primary" onClick={() => history.push(`/shop/${store._id}/order`)}>
        Recent Orders
      </Button>
    </div>
  );
}

OrderConfirmation.propTypes = {
  order: PropTypes.shape(Order).isRequired,
  store: PropTypes.shape(Store).isRequired,
};

export default OrderConfirmation;
