"use strict";

module.exports = [{
  label: 'Solid',
  value: 'SOLID'
}, {
  label: 'Liquid',
  value: 'LIQUID'
}, {
  label: 'Gas',
  value: 'GAS'
}];