import { Typography } from '@material-ui/core';
import { isEmpty, words } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { QUESTION_VARIANT } from '../flexible-questionnaire/Helper';
import Search from '../Search';
import CommodityHelper from '../../shared/pesticide-commodities.constant';

const PermitSiteSearch = ({
  clearOnSelect,
  disabled,
  filter,
  autoFocus,
  floatingText,
  hintText,
  helperText,
  id,
  label,
  onClear,
  onSelect,
  question,
  value,
  variant,
  labelVariant,
  permit,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [permitData, setPermitData] = useState(permit);

  useEffect(() => {
    setPermitData(permit);
  }, [permit]);

  const results =
    permitData?.sites
      .map((site) => ({ ...site, key: `${site.loc}-${site.commCode}` }))
      .filter((v) => !filter.includes(`${v.loc}-${v.commCode}`)) || [];

  const itemToString = (site) => {
    let item;
    const commodity = CommodityHelper.findCommodityByCodeAndQualifier(site?.commCode, site?.siteCode) || '';
    if (site?.siteNarr && site?.locNarr) {
      item = `${site?.loc}: ${site?.siteNarr} (${site?.locNarr}) - Commodity: ${commodity?.name}`;
    } else if (site?.siteNarr) {
      item = `${site?.loc}: ${site?.siteNarr} - Commodity: ${commodity?.name}`;
    } else if (site?.locNarr) {
      item = `${site?.loc} (${site?.locNarr}) - Commodity: ${commodity?.name}`;
    } else {
      item = `${site?.loc} - Commodity: ${commodity?.name}`;
    }
    return item;
  };

  const filteredData =
    !searchTerm && isEmpty(searchTerm)
      ? results
      : results?.filter((item) => {
          const fields = [
            item?.loc,
            item?.locNarr ? item?.locNarr : '',
            item?.siteNarr ? item?.siteNarr : '',
            item?.commCode ? item?.commCode : '',
            item?.commName ? item?.commName : '',
          ].map((f) => f.toLowerCase());

          return words(searchTerm)
            .map((w) => w.toLowerCase())
            .every((term) => fields.some((keyword) => keyword !== undefined && keyword.includes(term)));
        });

  const handleSelected = (val) => {
    if (val) {
      onSelect({ ...val, value: itemToString(val) }, question.key);
    }
  };

  return (
    <div className="m-8 w-full">
      <Search
        disabled={disabled}
        clearOnSelect={clearOnSelect}
        id={id}
        label={labelVariant !== 'long' ? floatingText || label || '' : ''}
        autoFocus={autoFocus}
        autocomplete
        isLoading={!permitData}
        placeholder={hintText}
        helperText={helperText}
        items={filteredData}
        minSearchLength={1}
        onChange={({ inputValue }) => {
          setSearchTerm(inputValue);
        }}
        onSelect={handleSelected}
        value={(value && Object.keys(value).length) || ''}
        onClear={({ target: { value: val } }) => {
          if (isEmpty(val)) {
            setSearchTerm('');
          }
        }}
        itemToString={itemToString}
        renderItem={(r) => <Typography variant="body1">{itemToString(r)}</Typography>}
      />
    </div>
  );
};

PermitSiteSearch.propTypes = {
  clearOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  filter: PropTypes.arrayOf(PropTypes.string),
  autoFocus: PropTypes.bool,
  floatingText: PropTypes.string,
  helperText: PropTypes.string,
  hintText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  labelVariant: PropTypes.oneOf(['default', 'long']),
  permit: PropTypes.objectOf(PropTypes.any).isRequired,
};

PermitSiteSearch.defaultProps = {
  clearOnSelect: false,
  disabled: false,
  filter: [],
  autoFocus: false,
  floatingText: 'Search Sites',
  helperText: '',
  hintText: 'Search by Site Name or Site Code',
  id: 'regulation-search',
  label: null,
  onClear: () => {},
  onSelect: () => {},
  question: null,
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
  labelVariant: 'default',
};

export default PermitSiteSearch;
