/* eslint-disable import/no-cycle */
import { Button } from '@material-ui/core';
import { ObjectId } from 'bson';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FqQuestion from './FqQuestion';
import { QUESTION_VARIANT, getFqClassName } from './Helper';

const FqAddAnother = ({
  disabled,
  minimumQuestionLength,
  onChange,
  question,
  questionKey,
  shouldValidate,
  values,
  variant,
  labelVariant,
}) => {
  const [items, setItems] = useState(
    !disabled && isEmpty(values) ? [{ id: new ObjectId().toString(), item: {} }] : values,
  );

  useEffect(() => {
    if (values) {
      setItems(!disabled && isEmpty(values) ? [{ id: new ObjectId().toString(), item: {} }] : values);
    }
  }, [values, disabled]);

  const handleAddAnother = () => {
    const updated = [
      ...items,
      {
        id: new ObjectId().toString(),
        item: {},
      },
    ];
    setItems(updated);
    onChange(updated, questionKey);
  };

  const handleRemove = (id) => {
    if (items.length > minimumQuestionLength) {
      const updated = items?.filter((item) => item.id !== id);
      setItems(updated);
      onChange(updated, questionKey);
    }
  };

  const handleChange = (value, id) => {
    const updated = items?.map((add) => (add.id === id ? { ...add, item: { ...add.item, ...value } } : add));
    setItems(updated);
    onChange(updated, questionKey);
  };

  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'pb-10'}`}>
      {items?.map((addable) => (
        <div
          key={addable.id}
          className={`${
            variant === QUESTION_VARIANT.COMPACT ? 'mb-16 p-8' : 'mx-24 mb-24'
          } 1px solid rounded border border-gray-100`}>
          <div className={`flex flex-wrap ${variant === QUESTION_VARIANT.COMPACT ? '' : 'py-12'}`}>
            {question?.questions.map((q) => (
              <div key={`${addable.id}_${q.key}`} className={getFqClassName(q, 'w-full')}>
                <FqQuestion
                  disabled={disabled}
                  onChange={(v) => handleChange(v, addable.id)}
                  question={q}
                  values={addable.item}
                  key={q.key}
                  shouldValidate={shouldValidate}
                  variant={variant}
                  labelVariant={labelVariant}
                />
              </div>
            ))}
          </div>
          {items.length > minimumQuestionLength && !disabled && (
            <Button
              variant="contained"
              className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'ml-24 mt-0 mb-16'} print:hidden`}
              size="small"
              onClick={() => handleRemove(addable.id)}
              disabled={disabled}>
              Remove
            </Button>
          )}
        </div>
      ))}
      {!disabled && (
        <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'mt-12' : 'm-12 ml-24'} print:hidden`}>
          <Button color="primary" variant="contained" onClick={handleAddAnother} disabled={disabled}>
            {question?.btnDisplayText || 'Add Another'}
          </Button>
        </div>
      )}
    </div>
  );
};

FqAddAnother.propTypes = {
  disabled: PropTypes.bool,
  minimumQuestionLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  questionKey: PropTypes.string.isRequired,
  shouldValidate: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.any),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  labelVariant: PropTypes.oneOf(['default', 'long']),
};

FqAddAnother.defaultProps = {
  disabled: false,
  minimumQuestionLength: 1,
  shouldValidate: false,
  values: null,
  variant: QUESTION_VARIANT.STANDARD,
  labelVariant: 'default',
};

export default FqAddAnother;
