import { useMutation } from '@apollo/client';
import { Chip, List, ListItem, ListItemText, MenuItem, TextField, Typography } from '@material-ui/core';
import { RESOLUTION_STATUS } from '@risk-and-safety/inspect-v2-common';
import { groupBy, startCase, truncate, upperCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CommentItem, FormattedDate, RichTextEditor } from '../../../components';
import { useToast } from '../../../hooks';
import { OPEN_TICKET } from '../../graphql/query';
import { resolveDate } from '../../helper/incident.helper';
import { useReportNavigation, REPORT_NAV_SUBPAGE, REPORT_NAV_TABS, useReport } from '../../hooks';

const SHOW_ALL = 'SHOW_ALL';
const filterResolutions = (resolutions) => {
  const {
    NOT_RESOLVED = [],
    READY_FOR_VERIFICATION = [],
    NO_FURTHER_ACTION = [],
    RESOLVED = [],
  } = groupBy(resolutions, 'status');

  return {
    SHOW_ALL: [...NOT_RESOLVED, ...READY_FOR_VERIFICATION, ...NO_FURTHER_ACTION, ...RESOLVED],
    NOT_RESOLVED,
    READY_FOR_VERIFICATION,
    NO_FURTHER_ACTION,
    RESOLVED,
  };
};

function ResolutionList({ resolutions, resolutionStatus, hasTicketingSystem }) {
  const { report } = useReport();
  const { showError } = useToast();
  const [selectedStatus, setSelectedStatus] = useState(resolutionStatus || SHOW_ALL);
  const navigation = useReportNavigation({ returnTab: REPORT_NAV_TABS.INCIDENTS });
  const filteredResolutions = filterResolutions(resolutions);

  if (!resolutions?.length) {
    return <Typography color="textSecondary">Nothing to resolve.</Typography>;
  }

  const [openTicket, { loading: openTicketLoading }] = useMutation(OPEN_TICKET, {
    onCompleted: (data) => {
      navigation.link(data.openTicket.ticketUrl);
    },
    onError: () => {
      showError('Unable to open ticket. Please try again later.');
    },
  });

  return (
    <div className="w-full">
      <div className="grid w-full grid-cols-12 py-8">
        <TextField
          id="status-filter"
          select
          variant="outlined"
          className="col-span-4 col-start-9"
          value={selectedStatus}
          onChange={(event) => setSelectedStatus(event.target.value)}
        >
          {Object.keys(filteredResolutions).map((val) => (
            <MenuItem key={val} value={val}>
              {upperCase(val)}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <List>
        {filteredResolutions[selectedStatus].map((resolution) => {
          const mostRecentEvent = resolution.log[resolution.log.length - 1] || null;
          const truncateComment = truncate(mostRecentEvent?.comment || '', { length: 180, separator: /<\/.>/ });
          const numComments = resolution.log.filter(({ status }) => status === RESOLUTION_STATUS.COMMENT).length;
          return (
            <ListItem
              key={resolution._id}
              className="flex flex-col items-start justify-between lg:flex-row"
              button
              divider
              disabled={openTicketLoading}
              onClick={() => {
                if (!hasTicketingSystem) {
                  navigation.to(REPORT_NAV_SUBPAGE.RESOLUTION, { id: resolution._id, resolutionStatus });
                } else {
                  if (resolution.ticketUrl) {
                    navigation.link(resolution.ticketUrl);
                  } else {
                    openTicket({
                      variables: {
                        resolutionId: resolution._id,
                        reportId: report.id,
                      },
                    });
                  }
                }
              }}
            >
              <div className="flex w-full flex-col lg:w-4/6">
                <ListItemText
                  disableTypography
                  primary={
                    <div>
                      <Typography>{truncate(resolution.question.label, { length: 180 })}</Typography>
                    </div>
                  }
                  secondary={
                    <>
                      {[RESOLUTION_STATUS.NOT_RESOLVED, RESOLUTION_STATUS.READY_FOR_VERIFICATION].includes(
                        resolution.status,
                      ) && (
                          <Typography variant="subtitle2" component="span" color="textPrimary">
                            {resolveDate(resolution.createdDate, resolution.incident.daysToResolve)}
                          </Typography>
                        )}
                      {resolution.incident.requiresVerification && (
                        <Chip size="small" className="ml-4" label="Requires Verification" />
                      )}
                    </>
                  }
                />
                <ListItemText
                  disableTypography
                  className="flex w-full flex-col-reverse"
                  primary={
                    mostRecentEvent && (
                      <CommentItem
                        key={mostRecentEvent.createdDate}
                        user={mostRecentEvent.createdBy}
                        condensed
                        title={
                          mostRecentEvent.status !== RESOLUTION_STATUS.COMMENT ? (
                            <>
                              {`${mostRecentEvent.createdBy.firstName} ${mostRecentEvent.createdBy.lastName} `}
                              <FormattedDate date={mostRecentEvent.createdDate} />
                              <Chip
                                size="small"
                                variant="outlined"
                                label={<Typography>{startCase(mostRecentEvent.status)}</Typography>}
                                className="ml-5"
                              />
                            </>
                          ) : (
                            ''
                          )
                        }
                      >
                        {mostRecentEvent.status !== RESOLUTION_STATUS.COMMENT ? (
                          <RichTextEditor value={truncateComment} />
                        ) : (
                          <>
                            <Typography variant="subtitle2" className="mt-16" component="div">
                              {`${mostRecentEvent.createdBy.firstName} ${mostRecentEvent.createdBy.lastName} `}
                              <FormattedDate date={mostRecentEvent.createdDate} />
                              {'- '}
                            </Typography>
                            <RichTextEditor value={truncateComment} />
                          </>
                        )}
                      </CommentItem>
                    )
                  }
                  secondary={numComments ? `${numComments} Comment${numComments === 1 ? ' ' : 's'}` : null}
                />
              </div>
              <div className="flex w-full justify-end lg:w-2/6 ">
                <Chip color="primary" size="small" label={`${startCase(resolution.status)}`} />
              </div>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

ResolutionList.propTypes = {
  resolutions: PropTypes.arrayOf(PropTypes.any).isRequired,
  resolutionStatus: PropTypes.string.isRequired,
};

export default ResolutionList;
