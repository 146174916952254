import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Fab, Icon, Tooltip } from '@material-ui/core';
import { RELATIONSHIP_LABEL} from '@rss/common';
import { get, snakeCase, toLower, toUpper } from 'lodash';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Header,
  HeaderWithRightSidebar,
  IconWithTooltip,
  ManageRoutingGroupDialog,
  ManageRoutingListDialog,
  RoleCard,
  SortableTable,
} from '../../components';
import { AccessDenied, ErrorMessage, NotFound } from '../../components/errors';
import RightSidebarMenu from '../../components/RightSidebarMenu';
import { GET_ROUTING_DETAILS } from '../../graphql/relationship.query';
import { useProfile, useTenants } from '../../hooks';
import {
  convertToSingular,
  getDisplayName,
  getRoleLabel,
  getUserDisplayName,
  hasAdminAccessToLabel,
  hasProgramRoleToDomain,
  hasReadAccessToTarget,
  parseSearchParams,
} from '../../shared/helper';
import { profileConfig } from '../../shared/profile-config';
import { setRssTools } from '../../store/app/rssToolsSlice';


const {
  NODE: { ROUTING_LIST, ROUTING_GROUP },
  EDGE: { MEMBER },
} = RELATIONSHIP_LABEL;

const formatData = (list) => {
  return list.map((node) => ({
    id: node.id,
    name: node.name,
    label: node.label,
    lastUpdatedBy: node?.lastUpdatedBy && getUserDisplayName(node.lastUpdatedBy),
  }));
};

const columnsConfig = [
  { prop: 'name', label: 'Name', className: 'w-4/12 px-4', defaultSort: 'asc' },
  {
    prop: 'lastUpdatedBy',
    label: 'Last modified by',
    className: 'w-4/12 px-4',
  },
];

const RoutingDetailsPage = ({ isRssTools }) => {
  const {
    params: { id, type: routingType, routingGroupId, menu, domainId },
    url,
  } = useRouteMatch();

  const { rssTools } = useSelector(({ app }) => app);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRssTools({ disableCampusSwitch: true }));
    return () => dispatch(setRssTools({ disableCampusSwitch: false }));
  }, [dispatch]);

  const [isRLDialogOpen, setIsRLDialogOpen] = useState(false);
  const [isRGDialogOpen, setIsRGDialogOpen] = useState(false);
  const [type, setType] = useState(toUpper(routingType) || ROUTING_LIST);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [redirectText, setRedirectText] = useState(null);
  const pageLayout = useRef(null);

  const { profile, rolePermissions } = useProfile();
  const TENANTS = useTenants();
  const { campusCode, tenantCode } = isRssTools ? rssTools : profile;

  const { data, loading, error, refetch } = useQuery(GET_ROUTING_DETAILS, {
    skip: !campusCode,
    variables: {
      id: routingGroupId || id,
      type: type && toUpper(type),
      domainId,
    },
    fetchPolicy: 'network-only',
  });

  const routingDetails = data?.getByIdAndType ?? null;
  const header = routingDetails?.name ?? '';
  const { members, routingList, routingGroups } = routingDetails || {};

  useEffect(() => {
    if (type) {
      if (type === ROUTING_GROUP && routingList) {
        setRedirectText(`${routingList?.name}`);

        if (url.includes('rss-tools')) {
          setRedirectUrl(`/rss-tools/routing_lists/${routingList?.id}`);
        } else if (url.includes('domain')) {
          setRedirectUrl(`/domain/${domainId}/routing_lists/${routingList?.id}`);
        }
      } else if (type === ROUTING_LIST) {
        setRedirectText('Routing Lists');

        if (url.includes('rss-tools')) {
          setRedirectUrl('/rss-tools/routing_lists');
        } else if (url.includes('domain')) {
          setRedirectUrl(`/domain/${domainId}/routing_lists`);
        }
      }
    }
  }, [url, type, domainId, routingList, setType, setRedirectUrl, setRedirectText]);

  const showFilterFooter = Boolean(false);
  const history = useHistory();
  const search = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { tab } = search;

  const { icon = 'groups', memberAlias = 'Member' } = profileConfig[convertToSingular(type)] || {};

  const sideMenuList = {
    MEMBERS: toUpper(pluralize(memberAlias)),
    ROUTING_GROUPS: 'ROUTING_GROUPS',
  };
  const { MEMBERS, ROUTING_GROUPS } = sideMenuList;
  const menuItems = [
    members && { label: pluralize(memberAlias), value: MEMBERS, icon: 'person' },
    routingGroups && { label: 'Routing Groups', value: ROUTING_GROUPS, icon: 'groups' },
  ].filter(Boolean);

  // eslint-disable-next-line no-nested-ternary
  const [menuItem, setMenuItem] = useState(
    // eslint-disable-next-line no-nested-ternary
    menu ? toUpper(menu) : type === ROUTING_GROUP ? 'ROUTING_GROUPS' : 'MEMBERS',
  );
  const [menuLabel, setMenuLabel] = useState(tab);

  if (url.includes('domain')) {
    const domain = TENANTS.getDomain(domainId);
    if (!domain) {
      return <NotFound />;
    }
  }

  const queryParam = parseSearchParams({ ...search, tab: toUpper(snakeCase(menuLabel)) });

  const selectedMenu =
    menuItems.find((m) => toUpper(m.label) === menuLabel)?.value ||
    menuItems.find((m) => m.value === menuItem)?.value ||
    menuItems[0]?.value ||
    '';

  const displayName = getDisplayName(selectedMenu);

  const list = formatData(routingGroups ?? members ?? []);

  const isProgramRoleForDomain = hasProgramRoleToDomain(profile, domainId);
  const canRead =
    hasReadAccessToTarget(rolePermissions, profile?.relationships, type, id, [], domainId) || isProgramRoleForDomain;
  const canUpdate = hasAdminAccessToLabel(rolePermissions, profile?.relationships, type, id, [], domainId);

  if (!canRead) {
    return <AccessDenied />;
  }

  if (error) {
    if (/403/.test(error)) {
      return <AccessDenied />;
    }
    if (/404/.test(error)) {
      return <NotFound />;
    }
    return <ErrorMessage />;
  }

  if (loading) return <FuseLoading />;

  return (
    <>
      <FusePageCarded
        classes={{ content: 'flex flex-col', contentCard: 'overflow-hidden relative' }}
        header={
          <>
            <Header
              icon={<Icon className="text-24 sm:text-32">{icon}</Icon>}
              title={header}
              subtitle={routingDetails?.domain?.name || ''}
              backArrow={{
                link: `${redirectUrl}`,
                name: `${redirectText}`,
              }}
              search={<div className="z-10 w-full max-w-512" />}
              onMenuAction={(ev) => pageLayout.current.toggleRightSidebar()}
            />
          </>
        }
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <>
              {type === ROUTING_GROUP && selectedMenu === MEMBERS && members && (
                <RoleCard
                  isEditable={canUpdate}
                  role={getRoleLabel(MEMBER)}
                  parent={routingDetails}
                  campusCode={campusCode}
                  tenantCode={tenantCode}
                  domainId={routingDetails?.domain?.domainId}
                  members={members}
                  addTitle="Add Member"
                />
              )}
              {type === ROUTING_LIST && (
                <>
                  <SortableTable
                    columns={columnsConfig}
                    emptyState={{ message: `No ${pluralize(displayName)} currently found for you.` }}
                    rows={list}
                    loading={loading}
                    link={(row) => `${url}/routing_group/manage/${row.id}`}
                    hasFilterFooter={showFilterFooter}
                  />

                  {canUpdate && type === ROUTING_LIST && (
                    <>
                      <div className="ml-auto mr-56 pb-8 pr-8">
                        <Tooltip title={`Add ${displayName}`} arrow>
                          <Fab
                            color="primary"
                            onClick={() => setIsRGDialogOpen(true)}
                            aria-label="add"
                            className={`absolute ${showFilterFooter ? 'bottom-56' : 'bottom-8'}`}>
                            <Icon>add</Icon>
                          </Fab>
                        </Tooltip>
                      </div>
                      <ManageRoutingGroupDialog
                        isOpen={isRGDialogOpen}
                        title={`Add ${getDisplayName(selectedMenu)}`}
                        description={get(profileConfig, `${toUpper(selectedMenu)}.DESCRIPTION`)}
                        onSaveComplete={() => refetch()}
                        closeDialog={() => setIsRGDialogOpen(false)}
                        isCreate
                        campusCode={campusCode}
                        routingList={{
                          id: routingDetails?.id,
                          name: routingDetails?.name,
                          domain: routingDetails?.domain,
                        }}
                        data={{}}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )
        }
        rightSidebarHeader={
          (canUpdate && (
            <HeaderWithRightSidebar>
              <>
                <div className="flex h-full flex-col justify-between p-4 sm:p-12">
                  <div className="toolbar align-center flex justify-end">
                    <IconWithTooltip
                      icon="edit"
                      title={`Edit ${getDisplayName(type)}`}
                      placement="bottom"
                      onClick={() => {
                        if (type === ROUTING_LIST) {
                          setIsRLDialogOpen(true);
                        } else if (type === ROUTING_GROUP) {
                          setIsRGDialogOpen(true);
                        }
                      }}
                    />

                    {type === ROUTING_LIST && (
                      <ManageRoutingListDialog
                        isOpen={isRLDialogOpen}
                        title={`Edit ${getDisplayName(ROUTING_LIST)}`}
                        description={get(profileConfig, `${toUpper(ROUTING_LIST)}.DESCRIPTION`)}
                        onSaveComplete={() => refetch()}
                        closeDialog={() => setIsRLDialogOpen(false)}
                        tenantCode={tenantCode}
                        campusCode={campusCode}
                        isCreate={false}
                        rlData={
                          {
                            id: routingDetails?.id,
                            name: routingDetails?.name,
                            domain: routingDetails?.domain?.domainId,
                          } || {}
                        }
                      />
                    )}

                    {type === ROUTING_GROUP && (
                      <ManageRoutingGroupDialog
                        isOpen={isRGDialogOpen}
                        title={`Edit ${getDisplayName(ROUTING_GROUP)}`}
                        description={get(profileConfig, `${toUpper(ROUTING_GROUP)}.DESCRIPTION`)}
                        onSaveComplete={() => refetch()}
                        onDeleteComplete={() => history.replace(redirectUrl)}
                        closeDialog={() => setIsRGDialogOpen(false)}
                        campusCode={campusCode}
                        routingList={{
                          id: routingList?.id,
                          name: routingList?.name,
                          domain: routingDetails?.domain,
                        }}
                        data={{
                          id: routingDetails?.id,
                          name: routingDetails?.name,
                        }}
                      />
                    )}
                  </div>
                </div>
              </>
            </HeaderWithRightSidebar>
          )) || <></>
        }
        rightSidebarContent={
          <RightSidebarMenu
            selectedMenuItem={selectedMenu}
            menuItems={menuItems}
            onMenuSelect={({ label, value }) => {
              queryParam.tab = toUpper(snakeCase(label));
              const selectedTabUrl = {
                pathname: `${redirectUrl}${toLower(type)}/${id}`,
                search: qs.stringify(queryParam),
              };
              setMenuItem(value);
              setMenuLabel(label);
              menu ? history.push(selectedTabUrl) : history.replace(selectedTabUrl);
            }}
          />
        }
        innerScroll
        ref={pageLayout}
      />
    </>
  );
};

RoutingDetailsPage.propTypes = {
  isRssTools: PropTypes.bool,
};

RoutingDetailsPage.defaultProps = {
  isRssTools: false,
};

export default RoutingDetailsPage;
