"use strict";

module.exports = {
  ACKNOWLEDGEMENT: {
    type: 'ACKNOWLEDGEMENT',
    menu: 'Add Acknowledgement Card'
  },
  ATTENDANCE: {
    type: 'ATTENDANCE',
    menu: 'Add Attendance Card'
  },
  BIOSAFETY: {
    type: 'BIOSAFETY',
    menu: 'Add BUA Card'
  },
  BUA: {
    type: 'BUA',
    menu: 'Add BUA Card'
  },
  BUA_ATTENDANCE: {
    type: 'BUA_ATTENDANCE',
    menu: 'Add Attendance Card'
  },
  CHECKBOX: {
    type: 'CHECKBOX',
    menu: 'Add Checkbox Card'
  },
  CHEMICAL: {
    type: 'CHEMICAL',
    menu: 'Add Chemical Card'
  },
  CHEMICAL_BAND: {
    type: 'CHEMICAL_BAND',
    menu: 'Add Chemical Band Card'
  },
  EQUIPMENT: {
    type: 'EQUIPMENT',
    menu: 'Add Equipment Card'
  },
  INVENTORY: {
    type: 'INVENTORY',
    menu: 'Add Inventory Card'
  },
  LOCATION: {
    type: 'LOCATION',
    menu: 'Add Location Card'
  },
  QUESTION: {
    type: 'QUESTION',
    menu: 'Add Question Card'
  },
  REFERENCE: {
    type: 'REFERENCE',
    menu: 'Add Reference Card'
  },
  TABLE: {
    type: 'TABLE',
    menu: 'Add Table Card'
  },
  TEXT: {
    type: 'TEXT',
    menu: 'Add Text Card'
  },
  VALUE_UNIT: {
    type: 'VALUE_UNIT',
    menu: 'Add Value Unit Card'
  }
};