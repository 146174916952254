import { Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const KPIWidget = ({ count, countDescriptor, link, color }) => {
  return (
    <Paper className="w-full rounded-8 shadow-1">
      <Link to={link} style={{ textDecoration: 'none' }}>
        <div className="pt-12 pb-28 text-center">
          <Typography className="text-72" color={color}>
            {count}
          </Typography>
          <Typography className="text-16" color={color}>
            {countDescriptor}
          </Typography>
        </div>
      </Link>
    </Paper>
  );
};

KPIWidget.propTypes = {
  count: PropTypes.number.isRequired,
  countDescriptor: PropTypes.string.isRequired,
  link: PropTypes.objectOf(PropTypes.any).isRequired,
  color: PropTypes.string,
};

KPIWidget.defaultProps = {
  color: 'primary',
};

export default KPIWidget;
