const clone = require('clone');
const tv4 = require('tv4');
class AcuteToxicity {
  constructor(opt) {
    this.type = null;
    this.value = null;
    this.unit = null;
    this.animal = null;
    this.route = null;
    this.duration = {
      value: null,
      unit: null
    };
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    Object.keys(this).forEach(prop => {
      this[prop] = opt[prop] !== undefined ? opt[prop] : null;
    });
  }
  validate() {
    return tv4.validateMultiple(this, AcuteToxicity.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'acute-toxicity.schema.json',
      type: 'object',
      properties: {
        type: {
          type: ['string', 'null']
        },
        value: {
          type: ['string', 'null']
        },
        unit: {
          type: ['string', 'null']
        },
        animal: {
          type: ['string', 'null']
        },
        duration: {
          type: ['object', 'null']
        },
        route: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = AcuteToxicity;