/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Icon, Paper, Input, TablePagination, InputAdornment, ThemeProvider } from '@material-ui/core';
import { Store } from '@risk-and-safety/library';
import React, { useCallback, useEffect, useState, memo, useRef } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Header, StatusChip } from '../../components';
import { ErrorMessage } from '../../components/errors';
import { FIND_ACCOUNTS_FOR_USER, LIST_STORE_ACCOUNTS } from '../graphql/query';
import { STORE_PERMISSION } from '@risk-and-safety/library';
import { ReactTable } from '../components';
import { useSelector } from 'react-redux';
import { selectMainTheme } from '../../store/fuse/settingsSlice';

const ownerNameDisplay = (props) => {
  const { value } = props;
  return (
    <p>
      <span>
        {value.firstName} {value.lastName}
      </span>
    </p>
  );
};

const statusDisplay = (props) => {
  const { value } = props;

  const isValidAccount = value?.funds?.length && value?.deliveryLocations?.length;

  return (
    <StatusChip
      label={isValidAccount ? 'Ready for Purchases' : 'Configuration Required'}
      color={isValidAccount ? 'success' : 'error'}
    />
  );
};

const Search = ({ setUserInput }) => {
  const mainTheme = useSelector(selectMainTheme);

  return (
    <ThemeProvider theme={mainTheme}>
      <Paper className={clsx('flex w-full max-w-512 rounded-12 p-10')} elevation={1}>
        <Input
          type="search"
          placeholder={'Search By Inventory Name, Owner, Email'}
          disableUnderline
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Icon color="action">search</Icon>
            </InputAdornment>
          }
          onChange={(event) => {
            setUserInput(event.target.value);
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </Paper>
    </ThemeProvider>
  );
};

const AccountList = memo(function AccountList({ accountList, loading, error }) {
  const { url } = useRouteMatch();
  const history = useHistory();

  const onRowClick = useCallback((value) => {
    history.push({
      pathname: `${url}/${value.account?._id || 'create'}`,
      state: { administeredBy: value },
    });
  }, []);

  const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Owner', accessor: 'owner', Cell: ownerNameDisplay },
    { Header: 'Email', accessor: 'owner.email' },
    { Header: 'Status', accessor: 'account', Cell: statusDisplay },
  ];

  if (loading) {
    return <FuseLoading />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <div className="min-h-full min-w-full">
      <ReactTable columns={columns} data={accountList} onClickRow={onRowClick} passOriginalDataOnClick />
    </div>
  );
});

const PaginationFooter = memo(({ setSearchOptions, searchOptions, totalSearchHits }) => {
  const [count, setCount] = useState(-1);

  // HACKME: Just to display total static 2/14/2024
  useEffect(() => {
    if (totalSearchHits !== null && totalSearchHits !== undefined) {
      setCount(totalSearchHits);
    }
  }, [totalSearchHits]);

  const handleChangeRowsPerPage = (event) => {
    setSearchOptions((prevState) => ({ ...prevState, displayCount: event.target.value, offset: 0, page: 0 }));
  };

  const handleChangePage = (_, newPage) => {
    const offset = newPage * searchOptions.displayCount;
    setSearchOptions((prevState) => ({ ...prevState, page: newPage, offset }));
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={searchOptions.page}
      rowsPerPage={searchOptions.displayCount}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
});

function AccountListPage({ store }) {
  const [userInput, setUserInput] = useState('');
  const [searchOptions, setSearchOptions] = useState({ searchTerm: '', page: 0, displayCount: 50, offset: 0 });

  const hasPermission = store?.permissions?.includes(STORE_PERMISSION.MANAGE) && useRouteMatch('/store/:storeId');

  const QUERY = !hasPermission ? FIND_ACCOUNTS_FOR_USER : LIST_STORE_ACCOUNTS;
  const variables = !hasPermission
    ? { storeId: store._id, administeredByLabel: 'INVENTORY' }
    : {
        storeId: store._id,
        campusCode: store.campusCode,
        keyword: searchOptions.searchTerm,
        offset: searchOptions.offset,
        displayCount: searchOptions.displayCount,
      };

  const { loading, data, error } = useQuery(QUERY, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const accountList = !hasPermission ? data?.findAccountsForUser : data?.listStoreAccounts?.userAccountSearchList;
  const totalSearchHits = data?.listStoreAccounts?.totalHits;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchOptions((prevState) => ({ ...prevState, searchTerm: userInput, page: 0, offset: 0 }));
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [userInput]);

  let backArrow = !hasPermission
    ? { link: `/shop/${store._id}`, name: `${store.name}` }
    : { link: `/store/${store._id}`, name: `${store.name}` };

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        contentCard: 'overflow-hidden',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">settings</Icon>}
          title="Inventory Accounts"
          backArrow={backArrow}
          search={hasPermission && <Search setUserInput={setUserInput} />}
        />
      }
      filterFooter={
        hasPermission && (
          <PaginationFooter
            setSearchOptions={setSearchOptions}
            searchOptions={searchOptions}
            totalSearchHits={totalSearchHits}
            loading={loading}
          />
        )
      }
      content={<AccountList loading={loading} error={error} accountList={accountList} />}
    />
  );
}

AccountListPage.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default AccountListPage;
