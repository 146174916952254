import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import Mailto from './Mailto';

const useStyles = makeStyles((theme) => ({
  primary: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    marginLeft: theme.spacing(0.5),
    fontSize: '0.80em',
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.primary.dark,
  },
  text: {
    marginRight: theme.spacing(0.5),
  },
  groupText: {
    display: 'inline-block',
  },
}));

const PersonDisplay = ({ email, endDate, firstName, inline, lastName, primary, startDate, mailto }) => {
  const classes = useStyles();
  let emailEl = null;

  if (email) {
    emailEl = mailto ? (
      <Mailto email={email} />
    ) : (
      <Typography variant="caption" color="textSecondary" className={classes.groupText}>
        {`(${email})`}
      </Typography>
    );
  }

  return (
    <Typography variant={inline ? 'body2' : 'body1'} component="span" align="left">
      <span className={classes.text}>{`${lastName}, ${firstName}`}</span>
      {emailEl}
      {endDate && (
        <Typography variant="caption" color="textPrimary" display="inline-block">
          {` [${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}]`}
        </Typography>
      )}
      {primary && <span className={classes.primary}>PRIMARY</span>}
    </Typography>
  );
};

PersonDisplay.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  primary: PropTypes.bool,
  inline: PropTypes.bool,
  mailto: PropTypes.bool,
};

PersonDisplay.defaultProps = {
  email: null,
  primary: false,
  inline: false,
  startDate: null,
  endDate: null,
  mailto: false,
};

export default memo(PersonDisplay);
