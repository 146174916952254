import {
  Button,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { getBase64 } from '../shared/file.helper';

import brokenAttachmentImage from './assets/BrokenAttachment.svg';

const NAME_DISPLAY_LENGTH = 5;

function getThumbnailDisplayName(name) {
  if (!name) {
    return 'Untitled';
  }
  const nameLength = name.lastIndexOf('.');
  return nameLength > NAME_DISPLAY_LENGTH
    ? `${name.substring(0, NAME_DISPLAY_LENGTH)}...${name.substring(nameLength)}`
    : name;
}

function Thumbnail({ attachment, remoteSrc, onDelete }) {
  const [attachmentError, setAttachmentError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const [srcString, setSrcString] = useState(null);
  const [srcThumbnail, setSrcThumbnail] = useState(null);

  useEffect(() => {
    async function loadFile() {
      setSrcString(`data:image/jpeg;base64,${await getBase64(attachment.file)}`);
      setLoading(false);
    }

    async function loadAttachment() {
      const { url, thumbnailUrl } = await remoteSrc(attachment.name);

      if (url) {
        setSrcString(url);
        if (thumbnailUrl) {
          setSrcThumbnail(thumbnailUrl);
        }
        setLoading(false);
      } else {
        setAttachmentError(true);
        setSrcString(brokenAttachmentImage);
        setSrcThumbnail(null);
        setLoading(false);
      }
    }

    if (!srcString && !loading) {
      if (attachment.file?.type.includes('image')) {
        setLoading(true);
        loadFile();
      }

      if (attachment.name) {
        setLoading(true);
        loadAttachment();
      }
    }
  }, [srcString, attachment.file, attachment.name, attachment.file?.type, remoteSrc, loading]);

  const isImage = attachment.type === 'IMAGE' || attachment.file?.type.includes('image');

  return (
    <div className="relative h-[96px] w-[96px] cursor-pointer rounded border border-gray-300">
      {loading && (
        <div className="flex h-full flex-col items-center justify-center">
          <CircularProgress aria-label="Attachment loading" />
        </div>
      )}

      {!loading && (
        <>
          <Tooltip title={attachment.originalName || attachment.file?.name}>
            <Button
              aria-label={attachment.file?.name || attachment.originalName}
              classes={{ root: 'p-0 h-full w-full', label: 'flex flex-col justify-between' }}
              onClick={() => setShowDetailsDialog(true)}
            >
              {srcString && (isImage || attachmentError) ? (
                <img
                  alt={attachment.originalName || attachment.file?.name}
                  src={srcThumbnail || srcString}
                  className="h-[96px] w-[96px] object-cover"
                />
              ) : (
                <>
                  <div className="flex h-full flex-1 flex-col items-center justify-center">
                    <Icon color="action" fontSize="large">
                      description
                    </Icon>
                  </div>
                  <Typography align="center" className="px-2">
                    {getThumbnailDisplayName(attachment.originalName || attachment.file?.name)}
                  </Typography>
                </>
              )}
            </Button>
          </Tooltip>

          {onDelete && (
            <IconButton
              aria-label="Cancel"
              onClick={() => onDelete(attachment.name || attachment.key)}
              className="absolute -left-24 -top-24 z-10"
            >
              <Icon color="error">cancel</Icon>
            </IconButton>
          )}
        </>
      )}

      {showDetailsDialog && (
        <Dialog open onClose={() => setShowDetailsDialog(false)}>
          <CardContent>
            {attachmentError && (
              <div>
                <img src={srcString} className="w-full" alt="Broken Attachment" />
                <div className="w-full">The attachment process was interrupted, and this file is unavailable.</div>
              </div>
            )}

            {!attachmentError && isImage && (
              <img className="w-full" src={srcString} alt={attachment.name || attachment.file?.name} />
            )}

            {!attachmentError && !isImage && (
              <Typography>{attachment.file?.name || attachment.originalName}</Typography>
            )}
          </CardContent>
          <DialogActions>
            {onDelete && (
              <IconButton
                onClick={() => {
                  setShowDetailsDialog(false);
                  onDelete(attachment.name || attachment.file);
                }}
                aria-label="Remove attachment"
                className="mr-160"
              >
                <Icon>delete</Icon>
              </IconButton>
            )}
            <div className="flex justify-end">
              {!attachmentError && (
                <IconButton href={srcString} label={attachment.file?.name || attachment.originalName}>
                  <Icon>cloud_download</Icon>
                </IconButton>
              )}
              <IconButton
                variant="outlined"
                color="default"
                aria-label="Clear"
                onClick={() => {
                  setShowDetailsDialog(false);
                }}
              >
                <Icon>clear</Icon>
              </IconButton>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

Thumbnail.propTypes = {
  attachment: PropTypes.objectOf(PropTypes.any).isRequired,
  remoteSrc: PropTypes.func,
  onDelete: PropTypes.func,
};

Thumbnail.defaultProps = {
  remoteSrc: null,
  onDelete: null,
};

export default Thumbnail;
