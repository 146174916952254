module.exports = [{
  label: 'Glass Bottle',
  value: 'GLASS_BOTTLE'
}, {
  label: 'Plastic Bottle',
  value: 'PLASTIC_BOTTLE'
}, {
  label: 'Ampule',
  value: 'AMPULE'
}, {
  label: 'Bag',
  value: 'BAG'
}, {
  label: 'Box',
  value: 'BOX'
}, {
  label: 'Can',
  value: 'CAN'
}, {
  label: 'Cylinder',
  value: 'CYLINDER'
}, {
  label: 'Fiber Drum',
  value: 'FIBER_DRUM'
}, {
  label: 'Steel Drum',
  value: 'STEEL_DRUM'
}, {
  label: 'Plastic/Non-Metallic Drum',
  value: 'NON_METALLIC_DRUM'
}, {
  label: 'Above Ground Tank',
  value: 'ABOVE_GROUND_TANK'
}, {
  label: 'Tank Inside Building',
  value: 'TANK_INSIDE_BUILDING'
}, {
  label: 'Underground Tank',
  value: 'UNDERGROUND_TANK'
}, {
  label: 'Cryogenic Dewar',
  value: 'CRYOGENIC_DEWAR'
}, {
  label: 'Carboy',
  value: 'CARBOY'
}, {
  label: 'Other',
  value: 'OTHER'
}];