import rssToolsNav from '../rss-tools/nav';

const navigationConfig = [
  {
    id: 'home',
    title: 'Home',
    type: 'item',
    icon: 'home',
    exact: true,
    url: '/',
  },
  {
    id: 'action-items',
    title: 'Action Items',
    type: 'item',
    icon: 'check_box',
    url: '/action-items',
  },
  {
    id: 'workspace',
    title: 'Workspace',
    type: 'item',
    icon: 'work_outline',
    url: '/workspace',
  },
  {
    id: 'campus-management',
    children: [],
  },
  /* placeholders to inject domain nav in LeftSideLayout1.js */
  ...Array(5)
    .fill()
    .map((item, index) => ({ id: `domain-${index}`, children: [] })),
  ...rssToolsNav,
  {
    type: 'divider',
    id: 'divider-1',
  },
  {
    id: 'more-apps',
    title: 'More Apps',
    type: 'collapse',
    icon: 'apps',
    dense: true,
    children: [],
  },
  /* Additional nav's dependent on profile and application data are in Auth.js */
];

export default navigationConfig;
