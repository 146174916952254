/* eslint-disable import/no-cycle */
import { Button } from '@material-ui/core';
import { get, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';

import DialogTemplate from '../DialogTemplate';
import FollowupQuestionPanel from '../FollowupQuestionPanel';
import { QUESTION_VARIANT } from './Helper';

const FqFollowup = ({
  disabled,
  followupKey,
  followupKeyLabel,
  onChange,
  question,
  selectedItem,
  shouldValidate,
  showTagFilter,
  value,
  variant,
}) => {
  const [currentItems, setCurrentItems] = useState(value);
  const [panelExpanded, setPanelExpanded] = useState([]);
  const [openDialogue, setOpenDialogue] = useState(false);
  const [removedKey, setRemovedKey] = useState(null);

  useEffect(() => {
    setCurrentItems(value);
  }, [value]);

  const itemKeys = currentItems?.map((ci) => ci.key) || [];
  if (
    selectedItem &&
    itemKeys?.length &&
    itemKeys.includes(selectedItem.key) &&
    !panelExpanded.includes(selectedItem.key)
  ) {
    setPanelExpanded([...panelExpanded, selectedItem.key]);
  }

  const getQuestionByType = (followupQuestion = { questions: [] }, type) =>
    followupQuestion.questions.filter((q) => q.parentType.some((t) => type?.includes(t))) || [];

  const handleDone = (currentItem) => {
    setPanelExpanded(panelExpanded.filter((p) => p !== currentItem.key));
    handleChange(currentItem);
  };

  const handleChange = useCallback(
    (savedItem) => {
      const existingItem = value?.find((item) => item.key === savedItem.key);
      if (existingItem) {
        setCurrentItems(currentItems.map((item) => (item.key === savedItem.key ? savedItem : item)));
        onChange(currentItems.map((item) => (item.key === savedItem.key ? savedItem : item)));
      } else {
        setCurrentItems([...currentItems, savedItem]);
        onChange([...currentItems, savedItem]);
      }
    },
    [onChange, currentItems, value],
  );

  const handlePanelChange = (itemKey, expanded) => {
    if (expanded) {
      setPanelExpanded(panelExpanded.filter((p) => p !== itemKey));
    } else {
      setPanelExpanded([...panelExpanded, itemKey]);
    }
    handleChange(currentItems.find((item) => item.key === itemKey));
  };

  const handleDialogClose = (confirmed) => {
    if (confirmed) {
      setCurrentItems(currentItems.filter((item) => item.key !== removedKey));
      onChange(currentItems.filter((item) => item.key !== removedKey));
    }
    setOpenDialogue(false);
    setRemovedKey(null);
  };

  const handleRemove = (itemKey) => {
    setOpenDialogue(true);
    setRemovedKey(itemKey);
  };

  return (
    <div className="m-8">
      {currentItems.length > 0 && (
        <>
          {sortBy(currentItems, ['type', 'name']).map((item) => (
            <div key={item.key}>
              <FollowupQuestionPanel
                selectedItem={item}
                followupQuestions={
                  showTagFilter ? getQuestionByType(question, get(item, followupKey)) : question.questions
                }
                id={item.key}
                panelExpanded={panelExpanded.includes(item.key)}
                onPanelChange={handlePanelChange}
                onDone={handleDone}
                onChange={handleChange}
                onRemove={handleRemove}
                disabled={disabled}
                shouldValidate={shouldValidate}
                variant={variant}
                panelSummary={{
                  primaryText: `${item.name} - ${followupKeyLabel}: ${get(item, followupKey)}`,
                  detailsLink: item?.link || '',
                }}
              />
            </div>
          ))}
          {openDialogue && (
            <DialogTemplate
              open={openDialogue}
              color="secondary"
              content={<p className="pt-10 pb-2 text-base">Are you sure you want to delete ?</p>}
              primaryActions={
                <>
                  <Button
                    variant="contained"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenDialogue(false);
                    }}>
                    Cancel
                  </Button>
                  <Button color="secondary" variant="contained" onClick={handleDialogClose}>
                    Remove
                  </Button>
                </>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

FqFollowup.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
  selectedItem: PropTypes.objectOf(PropTypes.any),
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  followupKey: PropTypes.string.isRequired,
  followupKeyLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  showTagFilter: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FqFollowup.defaultProps = {
  value: [],
  disabled: false,
  selectedItem: null,
  shouldValidate: false,
  showTagFilter: true,
  variant: QUESTION_VARIANT.STANDARD,
  followupKeyLabel: 'Type',
};

export default React.memo(FqFollowup);
