import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { SEARCH_OCCUPATIONS } from '../../graphql/incident-mgmt.query';
import SearchV2 from '../SearchV2';
import { QUESTION_VARIANT } from './Helper';
import useProfile from '../../hooks/useProfile';
import { getGraphClient } from '../../incident-mgmt/graphql/helper';

const OccupationSearch = ({
  autoFocus,
  floatingText,
  hintText,
  helperText,
  id,
  label,
  labelVariant,
  onClear,
  onSelect,
  value,
  variant,
}) => {
  // HACK: remove use profile hook once we retire v1
  const { profile } = useProfile();
  const [search, { data, loading }] = useLazyQuery(SEARCH_OCCUPATIONS, { client: getGraphClient(profile) });
  const occupations = (data?.searchOccupations || []).map((o) => ({ key: o.value, ...o }));

  return (
    <SearchV2
      id={id}
      label={floatingText || label || ''}
      labelVariant={labelVariant}
      autoFocus={autoFocus}
      autocomplete
      isLoading={loading}
      placeholder={hintText}
      helperText={helperText}
      items={occupations}
      itemToString={(occupation) => occupation.label}
      minSearchLength={0}
      onChange={({ inputValue }) => search({ variables: { searchTerm: inputValue } })}
      onSelect={(occupation) => onSelect({ value: occupation.value, label: occupation.label })}
      value={value || ''}
      onClear={onClear}
      renderItem={(occupation) => <Typography variant="body1">{occupation.label}</Typography>}
      openMenuOnFocus={false}
    />
  );
};

OccupationSearch.propTypes = {
  autoFocus: PropTypes.bool,
  floatingText: PropTypes.string,
  helperText: PropTypes.string,
  hintText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

OccupationSearch.defaultProps = {
  autoFocus: false,
  floatingText: null,
  helperText: '',
  hintText: 'Search for occupation codes',
  id: 'occupation-search',
  label: null,
  labelVariant: 'default',
  onClear: () => {},
  onSelect: () => {},
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
};

export default OccupationSearch;
