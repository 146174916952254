import { Icon, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const IconEmpty = ({ classes, title }) => (
  <Tooltip title={title} arrow>
    <span aria-label={title}>
      <Icon className={classes.icon} color="disabled">
        remove
      </Icon>
    </span>
  </Tooltip>
);

IconEmpty.propTypes = {
  classes: PropTypes.shape({ icon: PropTypes.string }),
  title: PropTypes.string,
};

IconEmpty.defaultProps = {
  classes: { icon: '' },
  title: 'No Value',
};

export default IconEmpty;
