import gql from 'graphql-tag';

const articleFragment = gql`
  fragment Article on Article {
    sys_id
    number
    kb_answer
    short_description
    sys_view_count
    full_category
  }
`;

export default articleFragment;
