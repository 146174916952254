/* eslint-disable react-hooks/rules-of-hooks */
import { AppBar, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DialogHeader = ({ children }) => {
  return (
    <AppBar position="static">
      <Toolbar className="flex w-full">{children}</Toolbar>
    </AppBar>
  );
};

DialogHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DialogHeader;
