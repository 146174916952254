import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withReducer from '../../../store/withReducer';
import Help from '../../../help/containers/Help';

import reducer from './store';
import { toggleQuickPanel } from './store/stateSlice';

function QuickPanel(props) {
  const dispatch = useDispatch();
  const state = useSelector(({ quickPanel }) => quickPanel.state);

  return (
    <SwipeableDrawer
      classes={{ paper: 'w-full sm:w-400' }}
      open={state}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleQuickPanel())}
      disableSwipeToOpen>
      <Help />
    </SwipeableDrawer>
  );
}

export default withReducer('quickPanel', reducer)(memo(QuickPanel));
