import { useQuery } from '@apollo/client';
import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Icon, Typography } from '@material-ui/core';
import { ORDER_STATUS, STORE_PERMISSION } from '@risk-and-safety/library';
import PropTypes from 'prop-types';
import React from 'react';

import { ErrorMessage } from '../../components/errors';
import { ActionCard, Header } from '../../components';
import { useProfile, useTenants } from '../../hooks';
import { KPIWidget } from '../components';
import { STORE_SUMMARY_PAGE } from '../graphql/query';

function StorePage({ store }) {
  const TENANTS = useTenants();
  const { loading, profile, programs, error } = useProfile();

  const {
    loading: storePageLoading,
    data,
    error: storePageError,
  } = useQuery(STORE_SUMMARY_PAGE, {
    variables: { storeId: store._id },
    fetchPolicy: 'network-only',
  });

  const program = programs.find((p) => p.features.some(({ id }) => id === store._id));

  if (loading || storePageLoading) {
    return <FuseLoading />;
  }

  if (error || storePageError) {
    return <ErrorMessage />;
  }

  return (
    <FusePageSimple
      classes={{ header: 'min-h-128 h-128' }}
      header={
        <div className="flex pl-32">
          <Header icon={<Icon className="text-32">store</Icon>} title={store.name} />
        </div>
      }
      content={
        <div className="px-16 py-8 sm:p-24">
          <FuseAnimateGroup
            className="flex flex-wrap"
            enter={{
              animation: 'transition.slideUpBigIn',
            }}>
            <div className="flex px-24 py-8 sm:w-1/2 md:w-1/4">
              <KPIWidget
                link={{ pathname: `/store/${store._id}/order`, state: { orderStatus: ORDER_STATUS.ORDER_SUBMITTED } }}
                count={data.ordersSummaryByStore?.ORDER_SUBMITTED || 0}
                countDescriptor="Pending Orders"
                color="error"
              />
            </div>
            <div className="flex px-24 py-8 sm:w-1/2 md:w-1/4">
              <KPIWidget
                link={{ pathname: `/store/${store._id}/order`, state: { orderStatus: ORDER_STATUS.READY_FOR_PICK_UP } }}
                count={data.ordersSummaryByStore?.READY_FOR_PICK_UP || 0}
                countDescriptor="Ready for Pickup"
                color="primary"
              />
            </div>
            <div className="flex px-24 py-8 sm:w-1/2 md:w-1/4">
              <KPIWidget
                link={{
                  pathname: `/store/${store._id}/order`,
                  state: { orderStatus: ORDER_STATUS.READY_FOR_DELIVERY },
                }}
                count={data.ordersSummaryByStore?.READY_FOR_DELIVERY || 0}
                countDescriptor="Ready for Delivery"
                color="primary"
              />
            </div>
            <div className="flex px-24 py-8 sm:w-1/2 md:w-1/4">
              <KPIWidget
                link={{ pathname: `/store/${store._id}/low-stock` }}
                count={data?.lowStockProductsStockCount?.lowProductCount || 0}
                countDescriptor="Products low on stock"
                color="error"
              />
            </div>
          </FuseAnimateGroup>

          <FuseAnimateGroup
            className="pt-60"
            enter={{
              animation: 'transition.slideUpBigIn',
            }}>
            <div className="grid grid-cols-9 items-start">
              <div className="col-span-3 mx-16 items-center justify-center">
                <ActionCard
                  id="products"
                  link={`/store/${store._id}/product`}
                  icon="add_circle"
                  title={
                    store?.permissions?.includes(STORE_PERMISSION.MANAGE)
                      ? 'Add Product and Stock'
                      : 'Add Product Stock'
                  }
                  description="Manage products and stocks"
                />
                {store?.permissions?.includes(STORE_PERMISSION.MANAGE) && (
                  <ActionCard
                    link={`/store/${store._id}/checkout`}
                    icon="shopping_cart"
                    title="Checkout"
                    description="store can check out for customers"
                  />
                )}
                {store?.permissions?.includes(STORE_PERMISSION.MANAGE) && (
                  <ActionCard
                    link={`/store/${store._id}/account`}
                    icon="people"
                    title="Inventory Accounts"
                    description="Search and Add"
                  />
                )}
                <ActionCard
                  link={`/store/${store._id}/settings/detail`}
                  icon="settings"
                  title={`${store?.permissions?.includes(STORE_PERMISSION.MANAGE) ? 'Manage' : 'View'} Store Settings`}
                  description="locations, hours, contact information"
                />
                <ActionCard
                  link={`/store/${store._id}/order`}
                  icon="shopping_bag"
                  title="Order History"
                  description="search and view all orders"
                />
                {store?.permissions?.includes(STORE_PERMISSION.MANAGE) && (
                  <ActionCard
                    link={`/store/${store._id}/transaction`}
                    icon="credit_card"
                    title="View Transactions"
                    description="search and download transactions"
                  />
                )}
              </div>
              <div className="col-span-6 mx-36">
                <Typography variant="h5" className="pb-24 text-center font-light">
                  Frequently Asked Questions
                </Typography>

                <div className="py-12">
                  <Typography className="mb-8 text-18">Who can purchase from {store.name}?</Typography>
                  <Typography className="text-16" color="textSecondary">
                    Anyone associated with a chemical inventory at {TENANTS.getCampus(profile.campusCode).shortName} can
                    shop from this store. Items will be transferred from the store&apos;s inventory to the
                    purchaser&apos;s inventory.
                  </Typography>
                </div>

                <div className="py-12">
                  <Typography className="mb-8 text-18">What about hazardous materials?</Typography>
                  <Typography className="text-16" color="textSecondary">
                    As you add hazardous chemicals to the store inventory, they will be included in regulatory
                    compliance reporting supported by chemical inventory.
                  </Typography>
                </div>

                <div className="py-12">
                  <Typography className="mb-8 text-18">How do I manage permissions for the store?</Typography>
                  <Typography className="text-16" color="textSecondary">
                    The program admin for {program.name} can add and remove staff and other managers by accessing the
                    program from their profile page.
                  </Typography>
                </div>
              </div>
            </div>
          </FuseAnimateGroup>
        </div>
      }
    />
  );
}

StorePage.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default StorePage;
