/* eslint-disable arrow-body-style */
import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import React from 'react';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';

import AppContext from './AppContext';
import { Auth, IdleTimeout, Session } from './auth';
import routes from './fuse-configs/routesConfig';
import store from './store';
import { ReadWriteClient } from './services/apollo';

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const generateClassName = createGenerateClassName({ disableGlobal: true });

const persistor = persistStore(store);

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Provider loading={<FuseLoading />} store={store}>
          <PersistGate persistor={persistor}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ApolloProvider client={ReadWriteClient}>
                <Auth>
                  <Router history={history}>
                    <IdleTimeout />
                    <FuseAuthorization>
                      <FuseTheme>
                        <Session>
                          <FuseLayout />
                        </Session>
                      </FuseTheme>
                    </FuseAuthorization>
                  </Router>
                </Auth>
              </ApolloProvider>
            </MuiPickersUtilsProvider>
          </PersistGate>
        </Provider>
      </StylesProvider>
    </AppContext.Provider>
  );
}

export default App;
