import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';

import {
  Control,
  MultiValue,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
} from './MuiReactSelectComponents';

const OptionsShape = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

const styles = (theme) => ({
  chipFocused: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey.light : theme.palette.grey.dark,
  },
  input: {
    display: 'inherit',
  },
  multiinput: {
    display: 'flex',
    padding: 8,
    height: 'auto',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute',
  },
  paper: {
    left: 0,
    marginTop: theme.spacing(1),
    position: 'absolute',
    right: 0,
    zIndex: 10,
  },
  root: {
    flexGrow: 1,
    height: 'auto',
  },
  singleValue: {
    fontSize: 16,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
  },
  chip: {
    margin: '4px 4px 4px 0',
  },
  valueContainer: {
    '& div + div': {
      marginLeft: theme.spacing(0.5),
    },
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
  },
});

const components = {
  Control,
  MultiValue,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class MuiReactSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentResponses: props.inputValue,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.clearSelection) {
      return { parentResponses: null };
    }
    if (nextProps.inputValue !== prevState.parentResponses) {
      return { parentResponses: nextProps.inputValue };
    }
    return null;
  }

  handleSelect(selectedSuggestion) {
    const { onInputSelect } = this.props;
    this.setState({
      parentResponses: selectedSuggestion,
    });
    onInputSelect(selectedSuggestion);
  }

  render() {
    const { parentResponses } = this.state;
    const {
      classes,
      error,
      errorMessage,
      id,
      isClearable,
      isDisabled,
      isMulti,
      label,
      maxMenuHeight,
      onInputChange,
      options,
      placeholder,
      variant,
      required,
    } = this.props;
    return (
      <div className={classes.root}>
        <Select
          classes={classes}
          textFieldProps={{
            label,
            id,
            InputLabelProps: {
              shrink: true,
              required,
            },
            error,
            helperText: errorMessage,
            variant,
          }}
          inputId={id}
          options={options}
          components={components}
          value={parentResponses}
          onChange={this.handleSelect}
          onInputChange={onInputChange}
          placeholder={placeholder}
          isMulti={isMulti}
          isClearable={isClearable}
          isDisabled={isDisabled}
          maxMenuHeight={maxMenuHeight}
        />
      </div>
    );
  }
}

MuiReactSelect.defaultProps = {
  clearSelection: false,
  error: false,
  errorMessage: '',
  id: 'react-select',
  inputValue: { label: '', value: '' },
  isMulti: false,
  isClearable: false,
  isDisabled: false,
  label: '',
  maxMenuHeight: 300,
  onInputChange: () => {},
  placeholder: '',
  variant: '',
  required: false,
};

MuiReactSelect.propTypes = {
  clearSelection: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  inputValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(OptionsShape)), PropTypes.shape(OptionsShape)]),
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  onInputSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape(OptionsShape)).isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
};

export default withStyles(styles)(MuiReactSelect);
