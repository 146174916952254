import { Typography, useTheme } from '@material-ui/core';
import { DateHelper, RELATIONSHIP_LABEL, SHORT_DATE_FORMAT } from '@rss/common';
import clsx from 'clsx';
import toUpper from 'lodash/toUpper';
import React from 'react';

import { ChipLabel, StatusChip } from '../components';
import { getDisplayName } from '../shared/helper';

const {
  EDGE: { MEMBER },
  NODE: { DISTRICT, FACILITY, GROUP, APPLICATOR_GROUP, PROGRAM },
} = RELATIONSHIP_LABEL;

const getStatusMap = (active) => {
  if (active === true) {
    return { color: 'success', label: 'Active' };
  }
  return { color: 'error', label: 'Inactive' };
};

const getRoles = (rowData, colors) => {
  return (
    <div className={clsx('flex w-216 flex-wrap')}>
      {rowData.roles.length ? (
        rowData.roles.map((label) => {
          return (
            <ChipLabel
              classes={{ root: 'mx-2 my-2' }}
              key={`${rowData.id}-${label}`}
              title={`${getDisplayName(label)}`}
              color={label !== MEMBER ? colors.purple[500] : colors.grey[500]}
            />
          );
        })
      ) : (
        <ChipLabel
          title={`${getDisplayName(rowData.label)}`}
          color={rowData.label !== MEMBER ? colors.purple[500] : colors.grey[500]}
        />
      )}
    </div>
  );
};

export default function useOrganizationColumns(type) {
  const theme = useTheme();
  const columns = [];
  const adminColumns = [];

  switch (toUpper(type)) {
    case APPLICATOR_GROUP:
    case GROUP:
      columns.push(
        ...[
          { prop: 'name', label: 'Name', className: 'w-4/12 pr-4' },
          {
            prop: 'owner',
            label: 'Owner',
            className: 'w-3/12 px-4',
          },
          {
            prop: 'label',
            label: 'Role',
            className: 'w-3/12 px-4',
            renderCell: (rowData) => getRoles(rowData, theme.colors),
          },
          {
            prop: 'createdDate',
            label: 'Created',
            className: 'w-2/12',
            defaultSort: 'desc',
            type: 'date',
            renderCell: (rowData) => DateHelper.formatDate(rowData?.createdDate, SHORT_DATE_FORMAT),
          },
        ],
      );
      adminColumns.push(
        ...[
          { prop: 'name', label: 'Name', className: 'w-4/12 pr-4' },
          {
            prop: 'owner',
            label: 'Owner',
            className: 'w-3/12 px-4',
          },
          {
            prop: 'createdDate',
            label: 'Created',
            className: 'w-2/12',
            defaultSort: 'desc',
            type: 'date',
            renderCell: (rowData) => DateHelper.formatDate(rowData?.createdDate, SHORT_DATE_FORMAT),
          },
        ],
      );
      break;
    case PROGRAM:
      columns.push(
        ...[
          { prop: 'name', label: 'Name', className: 'w-4/12 pr-4' },
          {
            prop: 'label',
            label: 'Role',
            className: 'w-6/12 px-4',
            renderCell: (rowData) => getRoles(rowData, theme.colors),
          },
          {
            prop: 'createdDate',
            label: 'Created',
            className: 'w-2/12',
            defaultSort: 'desc',
            type: 'date',
            renderCell: (rowData) => DateHelper.formatDate(rowData?.createdDate, SHORT_DATE_FORMAT),
          },
        ],
      );
      adminColumns.push(
        ...[
          { prop: 'name', label: 'Name', className: 'w-4/12 pr-4' },
          {
            prop: 'createdDate',
            label: 'Created',
            className: 'w-2/12',
            defaultSort: 'desc',
            type: 'date',
            renderCell: (rowData) => DateHelper.formatDate(rowData?.createdDate, SHORT_DATE_FORMAT),
          },
        ],
      );
      break;
    case FACILITY:
      columns.push(
        ...[
          {
            prop: 'name',
            label: 'Name',
            className: 'w-4/12 pr-4',
            defaultSort: 'asc',
            renderCell: (rowData) =>
              rowData?.name && (
                <span>
                  {rowData?.name}
                  <Typography className="pr-4" color="textSecondary">
                    {rowData?.address}
                  </Typography>
                </span>
              ),
          },
          {
            prop: 'label',
            label: 'Role',
            className: 'w-2/12 px-4',
            renderCell: (rowData) => getRoles(rowData, theme.colors),
          },
          { prop: 'district', label: 'District', className: 'w-3/12 pr-4' },
          { prop: 'type', label: 'Type', className: 'w-1/12 pr-4' },
          { prop: 'license', label: 'License Number', className: 'w-3/12 pr-4' },
        ],
      );
      adminColumns.push(
        ...[
          {
            prop: 'name',
            label: 'Name',
            className: 'w-4/12 pr-4',
            defaultSort: 'asc',
            renderCell: (rowData) =>
              rowData?.name && (
                <span>
                  {rowData?.name}
                  <Typography className="pr-4" color="textSecondary">
                    {rowData?.address}
                  </Typography>
                </span>
              ),
          },
          { prop: 'district', label: 'District', className: 'w-3/12 pr-4' },
          { prop: 'type', label: 'Type', className: 'w-1/12 pr-4' },
          { prop: 'license', label: 'License Number', className: 'w-2/12 pr-4' },
          {
            prop: 'active',
            label: 'Active Status',
            className: 'w-2/12 px-4',
            renderCell: (rowData) => (
              <StatusChip
                color={getStatusMap(rowData.active)?.color}
                label={getStatusMap(rowData.active)?.label}
                variant="compact"
              />
            ),
          },
        ],
      );
      break;
    case DISTRICT:
      adminColumns.push(
        ...[
          { prop: 'name', label: 'Name', className: 'w-4/12 pr-4', defaultSort: 'asc' },
          { prop: 'code', label: 'Code', className: 'w-3/12 px-4' },
        ],
      );
      break;
    default:
      columns.push({ prop: 'name', label: 'Name', className: 'w-4/12 pr-4', defaultSort: 'asc' });
      adminColumns.push({ prop: 'name', label: 'Name', className: 'w-4/12 pr-4', defaultSort: 'asc' });
      break;
  }

  return { columns, adminColumns };
}
