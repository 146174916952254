/* eslint-disable react/no-array-index-key */
import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import { makeStyles, Chip, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { ActionCard } from '../../components';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
  },
}));

function ShopHome({ store }) {
  const classes = useStyles();

  return (
    <FuseAnimateGroup
      className={clsx('h-full p-28 pt-36', classes.content)}
      enter={{
        animation: 'transition.slideUpBigIn',
      }}>
      <div className="grid grid-cols-9 items-start">
        <div className="col-span-3 mx-16 items-center justify-center">
          <ActionCard
            link={`/shop/${store._id}/order`}
            icon="receipt"
            title="Order History"
            description="view pending and complete orders"
          />

          <ActionCard
            link={`/shop/${store._id}/cart`}
            icon="shopping_cart"
            title="My Cart"
            description="edit order and checkout"
          />

          <ActionCard
            link={`/shop/${store._id}/account`}
            icon="credit_card"
            title="Accounts"
            description="funds and locations"
          />

          <ActionCard
            link={`/shop/${store._id}/purchasing-code`}
            icon="qr_code"
            title="My Purchasing Code"
            description={`for${store.settings?.selfCheckout ? ' self-checkout and' : ''} in store purchases`}
          />
        </div>

        <div className="col-span-6 mx-36">
          <Paper className="s:min-w-0 mb-12 min-w-512 rounded-8 shadow-1">
            <div className="p-16">
              <Typography variant="subtitle1" className="font-semibold">
                Store Details
              </Typography>
              <Typography>{store.description}</Typography>

              <div className="my-10 flex">
                <Typography className="mr-8">Available order options: </Typography>
                {store.settings.delivery && <Chip size="small" label="Delivery" className="mr-12" />}
                {store.settings.pickup && <Chip size="small" label="Pickup" className="mr-12" />}
                {store.settings.selfCheckout && <Chip size="small" label="Self Checkout" className="mr-12" />}
              </div>

              <div className="my-20 mr-20 flex justify-between">
                <Typography>
                  {store.address?.split('\n').map((item, index) => (
                    <span key={`address_${index}`} className="block">
                      {item}
                    </span>
                  ))}
                </Typography>

                <div>
                  {store.hours.from && (
                    <Typography>
                      Open from {format(new Date(store.hours.from), 'h:mm a')}
                      {store.hours.to ? ` to ${format(new Date(store.hours.to), 'h:mm a')}` : ''}
                    </Typography>
                  )}
                  {store.phone && <Typography>{store.phone}</Typography>}
                  {store.email && <Typography>{store.email}</Typography>}
                </div>
              </div>
            </div>
          </Paper>

          <Typography variant="h5" className="py-24 text-center font-light">
            Frequently Asked Questions
          </Typography>

          <div className="py-12">
            <Typography className="mb-8 text-18">Why can&apos;t I shop at this store?</Typography>
            <Typography className="text-16" color="textSecondary">
              To search items and add them to a cart, your account must be connected to a valid fund and at least one
              location.
            </Typography>
          </div>

          <div className="py-12">
            <Typography className="mb-8 text-18">What about hazardous materials?</Typography>
            <Typography className="text-16" color="textSecondary">
              When your order is complete, any hazardous chemicals will be transferred to the chemical inventory
              designated on your order.
            </Typography>
          </div>
        </div>
      </div>
    </FuseAnimateGroup>
  );
}

ShopHome.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ShopHome;
