import { useMutation } from '@apollo/client';
import { Button, FormControl, FormLabel, IconButton, Icon, TextField, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DialogTemplate from '../../components/DialogTemplate';
import { SAVE_ARTICLE_FEEDBACK } from '../graphql/mutation';

const Feedback = ({ articleId, showActions, onSave }) => {
  const [comment, setComment] = useState();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

  const [saveArticleFeedback] = useMutation(SAVE_ARTICLE_FEEDBACK);

  const handleActionClick = (isHelpful, comments) => {
    let feedbackInput = { articleId };
    if (comments) feedbackInput = { ...feedbackInput, comments };
    if (isHelpful) feedbackInput = { ...feedbackInput, useful: isHelpful };
    saveArticleFeedback({
      variables: { feedbackInput },
    });
    onSave();
  };

  return (
    <>
      {showActions && (
        <div className="flex flex-row space-x-8">
          <span className="font-medium">Was this helpful?</span>
          <div className="-mt-14 flex-1 space-x-0">
            <Tooltip title="Yes" arrow>
              <IconButton className="text-success" onClick={() => handleActionClick('yes')}>
                <Icon fontSize="small">done</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="No" arrow>
              <IconButton
                onClick={() => {
                  handleActionClick('no');
                  setOpenFeedbackDialog(true);
                }}>
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
      <DialogTemplate
        open={openFeedbackDialog}
        title="Please provide feedback"
        content={
          <FormControl fullWidth>
            <FormLabel htmlFor="feedback" className="mb-8 text-sm">
              Let us know how we might improve this answer (optional):
            </FormLabel>
            <TextField
              id="feedback"
              variant="outlined"
              multiline
              rows="4"
              autoFocus
              onChange={({ target }) => setComment(target.value)}
            />
          </FormControl>
        }
        primaryActions={
          <>
            <Button variant="contained" onClick={() => setOpenFeedbackDialog(false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (comment) handleActionClick(null, comment);
                setOpenFeedbackDialog(false);
              }}>
              Submit
            </Button>
          </>
        }
      />
    </>
  );
};

Feedback.propTypes = {
  articleId: PropTypes.string.isRequired,
  showActions: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default Feedback;
