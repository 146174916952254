import { Checkbox, FormControlLabel, FormGroup, List, ListItem, ListItemText } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

import IconWithTooltip from '../IconWithToolTip';
import { getDisplayName } from '../../shared/helper';
import { profileConfig } from '../../shared/profile-config';

const {
  NODE: { APPLICATOR_GROUP },
} = RELATIONSHIP_LABEL;

const OrganizationRoleSelect = ({ isOwner, label, selectedRoles, onElevatedRoleChange, adminAccess, writeAccess }) => {
  const { roles: baseRoles, defaultRole, OWNER: ownerRoleDescription } = profileConfig[label] || {};
  // APPLICATOR_GROUP only allow admins to assign roles
  const roles = label === APPLICATOR_GROUP && !adminAccess && writeAccess ? [] : baseRoles ?? [];

  if (!roles.length) return null;

  return (
    <>
      <p className="text-lg font-medium">
        Roles <span className="text-sm opacity-60">(Optional)</span>
        <span className="ml-2">
          <IconWithTooltip
            icon="info_outlined"
            title={
              <List dense dividers="true">
                {defaultRole && (
                  <ListItem key={defaultRole}>
                    <ListItemText
                      primary={
                        <>
                          <strong>{getDisplayName(defaultRole)}</strong>
                          <span className="ml-2 italic">(Default Role)</span>
                        </>
                      }
                      secondary={profileConfig[label][defaultRole] || ''}
                      secondaryTypographyProps={{
                        color: 'inherit',
                      }}
                    />
                  </ListItem>
                )}
                {ownerRoleDescription && (
                  <ListItem>
                    <ListItemText
                      primary={<strong>Owner</strong>}
                      secondary={ownerRoleDescription}
                      secondaryTypographyProps={{
                        color: 'inherit',
                      }}
                    />
                  </ListItem>
                )}
                {roles.map((role) => (
                  <ListItem key={role}>
                    <ListItemText
                      primary={<strong>{getDisplayName(role)}</strong>}
                      secondary={profileConfig[label][role] || ''}
                      secondaryTypographyProps={{
                        color: 'inherit',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            }
          />
        </span>
      </p>
      {isOwner ? (
        <FormControlLabel control={<Checkbox defaultChecked name="Owner" />} label="Owner" disabled />
      ) : (
        <FormGroup row>
          {roles.map((role) => (
            <FormControlLabel
              key={`${label}_${role}`}
              control={
                <Checkbox
                  color="primary"
                  checked={selectedRoles.includes(role)}
                  name={role}
                  onChange={onElevatedRoleChange}
                />
              }
              label={getDisplayName(role)}
            />
          ))}
        </FormGroup>
      )}
    </>
  );
};

OrganizationRoleSelect.propTypes = {
  isOwner: PropTypes.bool,
  label: PropTypes.string.isRequired,
  selectedRoles: PropTypes.arrayOf(PropTypes.string),
  onElevatedRoleChange: PropTypes.func.isRequired,
  adminAccess: PropTypes.bool.isRequired,
  writeAccess: PropTypes.bool.isRequired,
};

OrganizationRoleSelect.defaultProps = {
  isOwner: false,
  selectedRoles: null,
};

export default OrganizationRoleSelect;
