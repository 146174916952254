const {
  PersonReference
} = require('@rss/common');
const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
class CustomName {
  constructor(opt) {
    this.name = null;
    this.createdBy = null;
    this.date = null;
    this.familyId = null;
    this.inventoryId = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.name = opt.name;
    this.createdBy = opt.createdBy ? new PersonReference(opt.createdBy) : null;
    this.date = new Date();
    this.familyId = new ObjectId(opt.familyId);
    this.inventoryId = new ObjectId(opt.inventoryId) || '';
  }
  validate() {
    return tv4.validateMultiple(this, CustomName.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'custom-name.schema.json',
      type: 'object',
      properties: {
        name: {
          type: 'string'
        },
        createdBy: {
          $ref: 'person-reference.schema.json'
        },
        date: {
          type: ['object']
        },
        familyId: {
          type: 'object'
        },
        inventoryId: {
          type: 'object'
        }
      },
      required: Object.keys(this)
    };
  }
}
tv4.addSchema(PersonReference.schema());
module.exports = CustomName;