import FuseLoading from '@fuse/core/FuseLoading';
import {
  Button,
  Divider,
  FormControl,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { removeFromCart, updateCartQuantity } from '../../store/app/shoppingCartSlice';
import { getCartCount } from '../helper/cart.helper';

function CartItems({ storeId, validatedProducts }) {
  const cart = useSelector(({ app }) => app.shoppingCart && app.shoppingCart[storeId]) || {};
  const dispatch = useDispatch();

  return (
    <Paper className="rounded-8 pb-16 shadow-1">
      <List
        subheader={
          <ListSubheader component="div">
            Cart Items {cart.items?.length > 0 && `(${getCartCount(cart)} items)`}
          </ListSubheader>
        }>
        {cart?.items?.map((item, index) => {
          const product = validatedProducts.find((p) => p._id === item._id);
          return product ? (
            <div key={item._id}>
              <ListItem className="flex items-start justify-between pt-20">
                <div>
                  <Link to={`/shop/${storeId}/product/${item._id}`} style={{ textDecoration: 'none' }}>
                    <Typography className="text-16">{product.name}</Typography>
                  </Link>
                  <Typography color="textSecondary">{product.description}</Typography>

                  <div className="pt-16">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => dispatch(removeFromCart({ storeId, productId: item._id }))}
                      className="align-middle">
                      Remove
                    </Button>
                    {!product.availableForSale && (
                      <Typography className="align-middle inline-block px-8" color="error">
                        No longer available for purchase.
                      </Typography>
                    )}
                    {product.catalog !== 'SERVICES' &&
                      product.availableForSale &&
                      product.quantityAvailable < item.quantity && (
                        <Typography className="align-middle inline-block px-8" color="error">
                          {product.quantityAvailable} in stock.
                        </Typography>
                      )}
                  </div>
                </div>
                <div className="flex">
                  <div>
                    <Typography color="textSecondary">Quantity</Typography>
                    <FormControl variant="outlined">
                      <Select
                        disabled={!product.availableForSale || item.quantity > item.quantityAvailable}
                        value={item.quantity}
                        onChange={({ target: { value } }) =>
                          dispatch(updateCartQuantity({ storeId, productId: item._id, quantity: value }))
                        }
                        className="h-44">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                          .slice(0, product.quantityAvailable || item.quantity)
                          .map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="pl-32">
                    <Typography color="textSecondary">Item Price</Typography>
                    {numeral(product.price).format('$0,0.00')}
                  </div>
                  <div className="px-24">
                    <Typography color="textSecondary">Total Price</Typography>
                    {numeral(item.quantity * product.price).format('$0,0.00')}
                  </div>
                </div>
              </ListItem>
              {index < cart.items?.length - 1 && <Divider className="mt-20" />}
            </div>
          ) : (
            <FuseLoading key={item._id} />
          );
        })}
      </List>
    </Paper>
  );
}

CartItems.propTypes = {
  storeId: PropTypes.string.isRequired,
  validatedProducts: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CartItems;
