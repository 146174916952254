import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Store } from '@risk-and-safety/library';
import { FormHelperText, Icon, IconButton, TextField, Typography } from '@material-ui/core';
import { flatten, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { STORE_WITH_INVENTORY, UPSERT_SUBLOCATION } from '../graphql/query';
import { BuildingSearch, ButtonProgress, RoomSearch } from '../../components';

const SCHEMA = yup.object({
  roomId: yup.string().required(),
  name: yup.string().required(),
});

const SettingLocationAdd = ({ store }) => {
  const { locationId } = useParams();
  const history = useHistory();

  const navigateBack = () => history.push(`/store/${store._id}/settings/location`);

  const [upsertSublocation, { loading }] = useMutation(UPSERT_SUBLOCATION, {
    refetchQueries: [
      {
        query: STORE_WITH_INVENTORY,
        variables: { id: store._id },
      },
    ],
    onCompleted: navigateBack,
  });

  const location = locationId
    ? flatten(values(store.inventory.sublocations)).find((item) => item._id === locationId)
    : null;

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      inventoryId: store.inventory._id || '',
      buildingId: location?.buildingId || '',
      buildingName: location?.buildingName || '',
      floorId: location?.floorId || '',
      floorName: location?.floorName || '',
      roomId: location?.roomId || '',
      roomNumber: location?.roomNumber || '',
      name: location?.name || '',
      barcode: location?.barcode || '',
      temperature: location?.temperature || 'AMBIENT',
      pressure: location?.pressure || 'AMBIENT',
      isPrivate: location?.isPrivate || false,
    },
    resolver: yupResolver(SCHEMA),
  });

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit((value) => {
        const variables = { sublocation: value };
        if (location) {
          variables.sublocation._id = location._id;
        }
        upsertSublocation({ variables });
      })}>
      <div className="absolute left-48 top-136 flex h-64 items-center">
        <IconButton onClick={navigateBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </div>
      <div className="absolute right-48 top-136 flex h-64 items-center">
        <ButtonProgress loading={loading} type="submit" variant="contained" color="primary">
          Save
        </ButtonProgress>
      </div>
      <div className="flex items-center">
        <Typography className="mb-24 text-20 font-medium" color="textSecondary">
          Add a new location to the store
        </Typography>
      </div>

      <div className="mb-20">
        <Controller
          name="buildingName"
          control={control}
          render={({ field: { value } }) => (
            <>
              <BuildingSearch
                aria-label="search-building"
                floatingText="Search Building"
                hintText="Search Building"
                isDisabled={!!location}
                onSelect={(selected) => {
                  setValue('buildingId', selected.buildingId);
                  setValue('buildingName', selected.name);
                }}
                onClear={() => {
                  setValue('buildingId', '');
                  setValue('buildingName', '');
                  setValue('floorId', '');
                  setValue('floorName', '');
                  setValue('roomId', '');
                  setValue('roomNumber', '');
                }}
                value={{
                  buildingId: getValues('buildingId'),
                  name: getValues('buildingName'),
                }}
              />
              {!!errors.buildingId && (
                <FormHelperText id="search-building-error-text" className="text-red-500">
                  Building is required
                </FormHelperText>
              )}
            </>
          )}
        />
      </div>

      <div className="mb-12">
        <Controller
          name="roomNumber"
          control={control}
          render={({ field: { value } }) => (
            <>
              <RoomSearch
                aria-label="search-room"
                floatingText="Search Room"
                hintText="Search Room"
                buildingId={watch().buildingId}
                isDisabled={!watch().buildingId || !!location}
                onSelect={(selected) => {
                  setValue('floorId', selected.floor.id);
                  setValue('floorName', selected.floor.name);
                  setValue('roomId', selected.roomId);
                  setValue('roomNumber', selected.roomNumber);
                }}
                onClear={() => {
                  setValue('floorId', '');
                  setValue('floorName', '');
                  setValue('roomId', '');
                  setValue('roomNumber', '');
                }}
                value={{
                  roomId: getValues('roomId'),
                  roomNumber: getValues('roomNumber'),
                }}
              />
              {!!errors.roomId && (
                <FormHelperText id="search-building-error-text" className="text-red-500">
                  Room is required
                </FormHelperText>
              )}
            </>
          )}
        />
      </div>

      <div className="flex flex-col">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              className="mt-8 mb-8"
              error={!!errors.name}
              helperText={errors?.name?.message}
              label="Name"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <Controller
          name="barcode"
          control={control}
          render={({ field }) => (
            <TextField {...field} className="mt-8 mb-8" label="Barcode" variant="outlined" fullWidth />
          )}
        />
      </div>
    </form>
  );
};

SettingLocationAdd.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default SettingLocationAdd;
