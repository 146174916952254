/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Icon } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { Header } from '../../components';
import { ErrorMessage } from '../../components/errors';
import { ORDER, FIND_VALID_REFUND_ITEMS } from '../graphql/query';

function OrderPage({ route, store }) {
  const history = useHistory();
  const { orderId } = useParams();
  const { params } = useRouteMatch('/:type/:storeId/order/:orderId');

  const { loading, data, error } = useQuery(ORDER, {
    variables: { id: orderId },
    fetchPolicy: 'cache-and-network',
  });

  const {
    loading: refundLoading,
    data: refundData,
    error: refundError,
  } = useQuery(FIND_VALID_REFUND_ITEMS, {
    variables: { id: orderId, campusCode: store.campusCode },
    fetchPolicy: 'cache-and-network',
  });

  if (loading || refundLoading) {
    return <FuseLoading />;
  }

  if (error || refundError) {
    return <ErrorMessage />;
  }

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136 print:hidden',
        contentCard: 'overflow-hidden print:shadow-none print:rounded-none',
        contentWrapper: 'print:p-0',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">shopping_bag</Icon>}
          title={`Order # ${data.order._id}`}
          backArrow={{
            link: `/${params.type}/${store._id}/order${history.location.search}`,
            name: 'Orders',
          }}
        />
      }
      content={<>{renderRoutes(route.routes, { store, order: data?.order, refundData: refundData || {} })}</>}
    />
  );
}

OrderPage.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrderPage;
