import { AppBar, Chip, Icon, Toolbar, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { orderBy, truncate as truncateString } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const FilterFooter = ({ classes, variant, filters, truncate, total, length }) => {
  if (!filters.length && total <= 0) return null;

  const sortedFilters = orderBy(filters, 'asc');
  const displayFilters = sortedFilters.slice(0, 5);
  const remainingFilters = sortedFilters.slice(5);

  return (
    <div className={clsx('flex w-full shadow-2xl', classes.root)}>
      <AppBar id="filter-footer" position="sticky" color="inherit" className="z-10">
        <Toolbar variant={variant} disableGutters>
          <div className="align-middle m-4 ml-8 flex space-x-8 overflow-x-scroll text-center">
            <span className="mt-2 self-center whitespace-nowrap opacity-70">Total: {total}</span>
            {Boolean(filters.length) && (
              <span className="mt-2 self-center whitespace-nowrap opacity-70">Filtered by:</span>
            )}
            {displayFilters.map((filter) => (
              <Chip
                key={filter}
                label={truncate ? truncateString(filter, { length }) : filter}
                size="small"
                color="primary"
              />
            ))}
            {Boolean(remainingFilters.length) && (
              <Tooltip title={remainingFilters.join(', ')} placement="top" arrow>
                <Icon>more_horiz</Icon>
              </Tooltip>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

FilterFooter.propTypes = {
  classes: PropTypes.shape({ root: PropTypes.string }),
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  length: PropTypes.number,
  total: PropTypes.number,
  truncate: PropTypes.bool,
  variant: PropTypes.oneOf(['regular', 'dense']),
};

FilterFooter.defaultProps = {
  classes: { root: 'absolute bottom-2' },
  length: 15,
  total: 0,
  truncate: false,
  variant: 'dense',
};

export default FilterFooter;
