import { useQuery } from '@apollo/client';
import { Helper } from '@risk-and-safety/assessment-v2-common';
import { Button, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { ObjectId } from 'bson';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IconWithTooltip from '../IconWithToolTip';
import FqLabel from './FqLabel';
// eslint-disable-next-line import/no-cycle
import FqQuestionFactory from './FqQuestionFactory';
import FqQuestionPrint from './FqQuestionPrint';
// eslint-disable-next-line import/no-cycle
import { QUESTION_VARIANT } from './Helper';
import PermitSiteSearch from '../permit-site-search/PermitSiteSearch';

import { FIND_OPERATOR_PERMIT } from '../../graphql/fq.query';

const getSitePests = (site, permit) => {
  return (
    site?.sitePests
      ?.filter(Boolean)
      .map((pest) => permit?.pests?.find((p) => p.pestCode === pest))
      .filter(Boolean) || []
  );
};

const mapDynamicOptions = (question, site, permit) => {
  switch (question?.key) {
    case 'applicationMethod': {
      const sitePests = getSitePests(site, permit);
      const applicationMethods =
        sitePests
          .map((pest) => pest.method && !!Helper.applicationMethodMap[pest.method] && pest.method)
          .filter(Boolean)
          .join('') || 'O';
      const options = Array.from(new Set(applicationMethods.split('')))
        .sort()
        .map((method) => ({ value: method, label: Helper.applicationMethodMap[method] }));

      return { ...question, options };
    }
    case 'pesticideMaterialsKey': {
      const sitePests = getSitePests(site, permit);
      const questions = question?.questions?.map((ques) => {
        return {
          ...ques,
          questions: ques.questions?.map((childQues) => {
            switch (childQues?.key) {
              case 'pests':
                return {
                  ...childQues,
                  options: [
                    ...Array.from(new Set(sitePests.map((pest) => pest.pest))).map((pest) => ({
                      label: pest,
                      value: pest,
                    })),
                    {
                      label: 'OTHER',
                      value: 'OTHER',
                    },
                  ],
                };
              default:
                return { ...childQues };
            }
          }),
        };
      });
      return { ...question, questions };
    }
    default:
      return question;
  }
};

const FqPermitSite = ({
  disabled,
  onChange,
  question,
  values,
  minimumQuestionLength,
  permit,
  questionKey,
  shouldValidate,
  variant,
}) => {
  const [currSites, setCurrSites] = useState(values || []);
  const [items, setItems] = useState([{ id: new ObjectId().toString(), item: {} }]);
  const [operatorPermit, setOperatorPermit] = useState({});

  useQuery(FIND_OPERATOR_PERMIT, {
    variables: { permitNumber: permit },
    skip: !permit,
    onCompleted: (data) => {
      if (data?.findOperatorPermit) {
        setOperatorPermit(data?.findOperatorPermit[0]);
      }
    },
  });

  useEffect(() => {
    if (values.length) {
      setItems(isEmpty(values) ? [{ id: new ObjectId().toString(), item: {} }] : values);
      setCurrSites(values);
    }
  }, [values, items, currSites]);

  const handleChange = useCallback(
    (newSite) => {
      const updatedSites = { ...newSite };
      if ('acresOrUnitsTreated' in updatedSites?.item) {
        updatedSites.item.areaUnit = {
          label: Helper.areaUnitMap[updatedSites.item.plantU] ?? updatedSites.item.plantU,
          value: updatedSites.item.plantU,
        };
      } else if (
        updatedSites?.item?.plantAmt &&
        !isEmpty(updatedSites.item.pesticideMaterialsKey) &&
        updatedSites.item.pesticideMaterialsKey.some(
          (material) => material.pesticideMaterialGroup && 'acresOrUnitsTreated' in material.pesticideMaterialGroup,
        )
      ) {
        updatedSites.item.pesticideMaterialsKey = updatedSites.item.pesticideMaterialsKey.map((material) => ({
          ...material,
          pesticideMaterialGroup: {
            ...material.pesticideMaterialGroup,
            plantAmt: updatedSites.item.plantAmt,
            plantU: updatedSites.item.plantU,
            areaUnit: {
              label: Helper.areaUnitMap[updatedSites.item.plantU] ?? updatedSites.item.plantU,
              value: updatedSites.item.plantU,
            },
          },
        }));
      }
      const updated = items.map((item) => (item.id === updatedSites.id ? { ...updatedSites } : item));
      setItems(updated);

      onChange(updated, questionKey);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, questionKey],
  );

  const handleAddAnother = () => {
    const updated = [
      ...items,
      {
        id: new ObjectId().toString(),
        item: {},
      },
    ];
    setItems(updated);
    onChange(updated, questionKey);
  };

  const handleRemove = ({ id, ...site }) => {
    if (items.length > minimumQuestionLength) {
      const updated = items?.filter((item) => item.id !== id);
      setItems(updated);
      onChange(updated, questionKey);
    }
    const newSitesList = currSites.filter((r) => {
      return `${r.item.loc}-${r.item.commCode}` !== `${site.item.loc}-${site.item.commCode}`;
    });
    setCurrSites(newSitesList);
  };

  const handleClear = ({ id, ...site }) => {
    const updated = [{ id, item: {} }];
    setItems(updated);
    onChange(updated, questionKey);
    const newSitesList = currSites.filter((r) => {
      return `${r.item.loc}-${r.item.commCode}` !== `${site.item.loc}-${site.item.commCode}`;
    });
    setCurrSites(newSitesList);
  };

  const handleSelected = (newSite) => {
    const updated = items.map((item) => (item.id === newSite.id ? { ...newSite } : item));
    setItems(updated);
    const newSitesList = [...currSites, newSite];
    setCurrSites(newSitesList);
    onChange(updated, questionKey);
  };

  const generateSiteTooltip = (addable) => {
    const tooltip = [
      {
        label: 'Base & Meridian',
        value: addable.item.baseMer,
      },
      {
        label: 'Township',
        value: addable.item.town,
      },
      {
        label: 'Range',
        value: addable.item.range,
      },
      {
        label: 'Section',
        value: addable.item.section,
      },
      {
        label: 'Field location',
        value: addable.item.loc,
      },
      {
        label: 'Total planted units',
        value: `${addable.item.plantAmt} ${Helper.areaUnitMap[addable.item.plantU] ?? addable.item.plantU}`,
      },
    ];
    return (
      <IconWithTooltip
        icon="info_outlined"
        placement="bottom"
        title={
          <List key={`${addable.id}_list`} dense className="p-0">
            {tooltip.map((item) => (
              <ListItem key={`${addable.id}_${item.label}`} className="items-start p-0">
                <ListItemText
                  primary={
                    <>
                      <strong>{item.label}: </strong>
                      {item.value}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        }
      />
    );
  };

  return (
    <>
      <div className="m-8">
        <div className="-ml-4 mb-8 flex-grow self-center">
          <FqLabel
            question={{
              label: question.label,
              required: question.required,
            }}
            variant={variant}
          />
        </div>

        {items?.map((addable) => (
          <div key={addable.id} className="1px solid mb-16 rounded border border-gray-100 p-8">
            <div key={`${addable.id}_dev`} className="w-full">
              <div key={`${addable.id}_div2`} className="my-8">
                {!addable?.item?.loc && (
                  <>
                    <div key={`${addable.id}_div3`} className="flex w-full flex-wrap py-10">
                      <div key={`${addable.id}_div4`} className="w-full py-10 text-sm font-normal">
                        <FqLabel
                          question={{
                            label: question.searchLabel,
                          }}
                          variant="compact"
                        />
                      </div>
                      {!isEmpty(operatorPermit) && (
                        <PermitSiteSearch
                          onSelect={(val) => handleSelected({ ...addable, item: { ...val } })}
                          filter={currSites.map((r) => `${r.item.loc}-${r.item.commCode}`)}
                          clearOnSelect
                          question={question}
                          variant={variant}
                          disabled={disabled}
                          permit={operatorPermit}
                        />
                      )}
                    </div>
                  </>
                )}

                {addable?.item?.loc && (
                  <>
                    <div key={`${addable.item.loc}-${addable.item.commCode}`} className="w-full">
                      <Typography component="div" variant="h6" className="pl-10">
                        {addable?.item?.value}
                        <span className="ml-2">{generateSiteTooltip(addable)}</span>
                      </Typography>

                      <div className="flex w-full flex-wrap">
                        {question?.questions.map((dependantQuestion) => (
                          <div key={dependantQuestion.key} className={dependantQuestion?.className || 'w-full'}>
                            {!dependantQuestion.readOnly ? (
                              <FqQuestionFactory
                                onChange={(change) => {
                                  handleChange({ ...addable, item: { ...addable.item, ...change } });
                                }}
                                disabled={disabled || dependantQuestion.disabled}
                                question={mapDynamicOptions(dependantQuestion, addable.item, operatorPermit)}
                                questionKey={dependantQuestion.key}
                                value={addable ? addable.item[dependantQuestion.key] : null}
                                label={dependantQuestion.label}
                                key={dependantQuestion.key}
                                parentResponses={addable.item}
                                shouldValidate={shouldValidate}
                                labelVariant="long"
                                variant={variant}
                              />
                            ) : (
                              <FqQuestionPrint
                                question={dependantQuestion}
                                response={addable.item[dependantQuestion.key] || null}
                                parentResponses={addable.item}
                                headerLabel
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!disabled && items.length > minimumQuestionLength && (
              <Button
                variant="contained"
                className="m-8 print:hidden"
                size="small"
                onClick={() => handleRemove(addable)}
                disabled={disabled}>
                Remove
              </Button>
            )}
            {!disabled && items.length === 1 && items[0]?.item?.loc && (
              <Button
                variant="contained"
                className="m-8 print:hidden"
                size="small"
                onClick={() => handleClear(addable)}
                disabled={disabled}>
                Clear
              </Button>
            )}
          </div>
        ))}
        {!disabled && (
          <div className="mt-12 print:hidden">
            <Button color="primary" variant="contained" onClick={handleAddAnother} disabled={disabled}>
              {question?.btnDisplayText || 'Add Another Site'}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

FqPermitSite.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.arrayOf(PropTypes.object),
  permit: PropTypes.string.isRequired,
  minimumQuestionLength: PropTypes.number,
  shouldValidate: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  questionKey: PropTypes.string.isRequired,
};

FqPermitSite.defaultProps = {
  disabled: false,
  values: [{ id: new ObjectId().toString(), item: {} }],
  onChange: null,
  shouldValidate: false,
  minimumQuestionLength: 1,
  variant: QUESTION_VARIANT.STANDARD,
};

export default FqPermitSite;
