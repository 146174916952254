import gql from 'graphql-tag';

import articleFragment from './fragment';

const HELP_BY_CATEGORY = gql`
  query helpByCategory($helpInput: HelpQueryInput) {
    helpByCategory(helpInput: $helpInput) {
      result {
        ...Article
      }
    }
  }
  ${articleFragment}
`;

const HELP_CATEGORIES = gql`
  query helpCategoriesByName($categories: [String]) {
    helpCategoriesByName(categories: $categories) {
      result {
        sys_id
      }
    }
    helpCategories {
      result {
        ...Article
      }
    }
  }
  ${articleFragment}
`;

export { HELP_CATEGORIES, HELP_BY_CATEGORY };
