const {
  PersonReference
} = require('@rss/common');
const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
const InventoryReference = require('./inventory-reference');
class Request {
  constructor(opt) {
    this.status = null;
    this.name = null;
    this.familyId = null;
    this.inventory = null;
    this.type = null;
    this.requestedBy = null;
    this.requestedDate = new Date();
    this.message = null;
    this.sendTo = [];
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.status = opt.status ? opt.status : 'OPEN';
    this.name = opt.name || null;
    this.familyId = opt.familyId ? new ObjectId(opt.familyId) : null;
    this.inventory = new InventoryReference(opt.inventory);
    this.type = opt.type || null;
    this.requestedBy = opt.requestedBy ? new PersonReference(opt.requestedBy) : null;
    this.message = opt.message || null;
    this.sendTo = Array.isArray(opt.sendTo) ? opt.sendTo.map(inventory => new InventoryReference(inventory)) : [];
  }
  validate() {
    return tv4.validateMultiple(this, Request.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'request.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        inventory: {
          $ref: 'inventory-reference.schema.json'
        },
        message: {
          type: 'string'
        },
        name: {
          type: 'string'
        },
        familyId: {
          type: 'object'
        },
        requestedBy: {
          $ref: 'person-reference.schema.json'
        },
        requestedDate: {
          type: 'object'
        },
        sendTo: {
          type: 'array',
          item: {
            $ref: 'inventory-reference.schema.json'
          }
        },
        status: {
          type: 'string'
        },
        type: {
          type: 'string'
        }
      },
      required: ['status', 'name', 'familyId', 'inventory', 'type', 'requestedBy', 'requestedDate', 'message', 'sendTo']
    };
  }
}
tv4.addSchema(InventoryReference.schema());
tv4.addSchema(PersonReference.schema());
module.exports = Request;