import { RelationshipHelper, RELATIONSHIP_LABEL } from '@rss/common';
import { lazy } from 'react';

import { hasServiceDeskRole } from '../shared/helper';

const AdminPage = lazy(() => import('./containers/AdminPage'));
const DashboardPage = lazy(() => import('./containers/DashboardPage'));
const SurveyPage = lazy(() => import('./containers/SurveyPage'));

const routes = {
  settings: {
    helpCategories: ['nfpa'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/nfpa/admin',
      auth: ({ profile }) =>
        hasServiceDeskRole(profile) ||
        RelationshipHelper.hasRelationshipWithRole(profile.campusCode, profile.relationships, [
          RELATIONSHIP_LABEL.EDGE.NFPA_ADMIN,
        ]),
      component: AdminPage,
    },
    {
      path: ['/nfpa/survey/:id', '/nfpa/survey'],
      component: SurveyPage,
    },
    {
      path: ['/nfpa/surveys', '/nfpa'],
      component: DashboardPage,
    },
  ],
};

export default routes;
