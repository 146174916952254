"use strict";

module.exports = {
  DROP_DOWN: [{
    label: 'Report',
    value: 'REPORT'
  }, {
    label: 'Dashboard',
    value: 'DASHBOARD'
  }, {
    label: 'Paginated Report',
    value: 'PAGINATED_REPORT'
  }, {
    label: 'QnA',
    value: 'QNA'
  }],
  GRAPHQL_ENUM: {
    REPORT: {
      value: 'REPORT'
    },
    DASHBOARD: {
      value: 'DASHBOARD'
    },
    PAGINATED_REPORT: {
      value: 'PAGINATED_REPORT'
    },
    QNA: {
      value: 'QNA'
    }
  }
};