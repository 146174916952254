import gql from 'graphql-tag';

import { ATTACHMENT_FRAGMENT } from '../../graphql/attachment.fragments';
import {
  QUESTIONNAIRE_FRAGMENT,
  QUESTIONNAIRE_TEMPLATE_FRAGMENT,
  QT_CONTENT_FRAGMENT,
  REPORT_FRAGMENT,
  RESOLUTION_FRAGMENT,
  RESPONSE_COMMENT_FRAGMENT,
  SUBJECT_FRAGMENT,
} from './fragments';

// REPORT

export const GET_INSPECTION_REPORT = gql`
  query getInspectionReportById($id: ID!) {
    getInspectionReportById(id: $id) {
      report {
        ...Report
      }
      questionnaire {
        ...Questionnaire
      }
      subject
      admins {
        userId
        firstName
        lastName
        email
      }
      inspectors {
        userId
        firstName
        lastName
        email
      }
      responsiblePeople {
        userId
        firstName
        lastName
        email
        type
      }
      domainId
      permissions
      meta
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
  ${REPORT_FRAGMENT}
`;

export const GET_INSPECTION_REPORTS = gql`
  query getInspectionReports(
    $questionnaireSeriesId: ID!
    $status: [String]
    $resolutionStatus: [String]
    $omnisearchQuery: String
    $inspectorQuery: String
    $responsiblePeopleQuery: String
    $dateFrom: String
    $dateTo: String
    $size: Int
  ) {
    getInspectionReports(
      questionnaireSeriesId: $questionnaireSeriesId
      status: $status
      resolutionStatus: $resolutionStatus
      omnisearchQuery: $omnisearchQuery
      inspectorQuery: $inspectorQuery
      responsiblePeopleQuery: $responsiblePeopleQuery
      dateFrom: $dateFrom
      dateTo: $dateTo
      size: $size
    ) {
      total
      reports {
        report {
          _id
          inspectionDate
          status
          createdDate
        }
        statusChange {
          date
        }
        subject {
          name
        }
        highlight
        inspectors {
          userId
          firstName
          lastName
          email
        }
        responsiblePeople {
          userId
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const DELETE_INSPECT_REPORT = gql`
  mutation deleteInspectReport($reportId: ID!) {
    deleteInspectReport(reportId: $reportId)
  }
`;

export const NEXT_WORKFLOW_ACTION = gql`
  mutation inspectNextAction($reportId: ID!, $action: String!, $comment: String) {
    inspectNextAction(reportId: $reportId, action: $action, comment: $comment) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const AUDIT_TRAIL = gql`
  query getAuditTrail($attributeId: ID, $collection: String!, $documentId: ID!, $type: String) {
    getAuditTrail(attributeId: $attributeId, collection: $collection, documentId: $documentId, type: $type)
  }
`;

// REPORT PEOPLE

export const FIND_QUESTIONNAIRE_PEOPLE = gql`
  query findQuestionnairePeople($questionnaireSeriesId: String!) {
    findQuestionnairePeople(questionnaireSeriesId: $questionnaireSeriesId) {
      userId
      email
      firstName
      lastName
      role
    }
  }
`;

export const ADD_ASSIGNED_REVIEWER_TO_REPORT = gql`
  mutation addAssignedReviewerToReport($reportId: ID!, $assignedReviewerId: ID!) {
    addAssignedReviewerToReport(reportId: $reportId, assignedReviewerId: $assignedReviewerId) {
      assignedReviewers {
        userId
        firstName
        lastName
        email
      }
    }
  }
`;

export const REMOVE_ASSIGNED_REVIEWER_FROM_REPORT = gql`
  mutation removeAssignedReviewerFromReport($reportId: ID!, $assignedReviewerId: ID!) {
    removeAssignedReviewerFromReport(reportId: $reportId, assignedReviewerId: $assignedReviewerId) {
      assignedReviewers {
        userId
        firstName
        lastName
        email
      }
    }
  }
`;

export const ADD_USER_TO_REPORT = gql`
  mutation addUserToReport($reportId: ID!, $userId: ID!, $role: InspectRoleType!) {
    addUserToReport(reportId: $reportId, userId: $userId, role: $role) {
      report {
        ...Report
      }
      inspectors {
        userId
        firstName
        lastName
        email
      }
      responsiblePeople {
        userId
        firstName
        lastName
        email
        type
      }
    }
  }
  ${REPORT_FRAGMENT}
`;

export const INVITE_USER_TO_REPORT = gql`
  mutation sendReportInvite($reportId: ID!, $invite: InvitationInput) {
    sendReportInvite(reportId: $reportId, invite: $invite)
  }
`;

export const REMOVE_USER_FROM_REPORT = gql`
  mutation removeUserFromReport($reportId: ID!, $userId: ID!, $role: InspectRoleType!) {
    removeUserFromReport(reportId: $reportId, userId: $userId, role: $role) {
      report {
        ...Report
      }
      inspectors {
        userId
        firstName
        lastName
        email
      }
      responsiblePeople {
        userId
        firstName
        lastName
        email
        type
      }
    }
  }
  ${REPORT_FRAGMENT}
`;

export const UPDATE_CATEGORY_RESPONSE = gql`
  mutation updateCategoryResponse($reportId: ID!, $response: JSON!, $categoryId: ID!) {
    updateCategoryResponse(reportId: $reportId, response: $response, categoryId: $categoryId) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const SET_REMAINING_RESPONSES = gql`
  mutation setRemainingResponses($reportId: ID!, $answer: String!, $categoryKey: String) {
    setRemainingResponses(reportId: $reportId, answer: $answer, categoryKey: $categoryKey) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const UPDATE_SUBJECT_RESPONSE = gql`
  mutation updateSubjectResponse($reportId: ID!, $response: JSON!) {
    updateSubjectResponse(reportId: $reportId, response: $response) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const UPDATE_INCIDENT = gql`
  mutation updateIncident(
    $reportId: ID!
    $categoryId: String!
    $questionKey: String
    $responseId: String
    $incident: IncidentInput!
  ) {
    updateIncident(
      reportId: $reportId
      categoryId: $categoryId
      questionKey: $questionKey
      responseId: $responseId
      incident: $incident
    ) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const DELETE_INCIDENT = gql`
  mutation deleteIncident($reportId: ID!, $categoryId: ID!, $responseId: ID!, $incidentId: ID!) {
    deleteIncident(reportId: $reportId, categoryId: $categoryId, responseId: $responseId, incidentId: $incidentId) {
      ...Report
    }
  }
  ${REPORT_FRAGMENT}
`;

export const GET_REPORT_HISTORY = gql`
  query GetReportHistory($reportId: ID!) {
    getReportHistory(id: $reportId) {
      action
      date
      description
      notifications
    }
  }
`;

export const UPSERT_INSPECT_NOTE = gql`
  mutation upsertInspectNote($reportId: ID!, $note: NoteInput!) {
    upsertInspectNote(reportId: $reportId, note: $note) {
      _id
      subject {
        ...Subject
      }
    }
  }
  ${SUBJECT_FRAGMENT}
`;

export const DELETE_INSPECT_NOTE = gql`
  mutation deleteInspectNote($reportId: ID!, $noteId: ID!) {
    deleteInspectNote(reportId: $reportId, noteId: $noteId) {
      _id
      subject {
        ...Subject
      }
    }
  }
  ${SUBJECT_FRAGMENT}
`;

export const UPSERT_INSPECT_RESPONSE_COMMENT = gql`
  mutation upsertInspectResponseComment(
    $reportId: ID!
    $categoryId: String!
    $responseId: String!
    $responseComment: ResponseCommentInput!
  ) {
    upsertInspectResponseComment(
      reportId: $reportId
      categoryId: $categoryId
      responseId: $responseId
      responseComment: $responseComment
    ) {
      _id
      categories {
        responses {
          comment {
            ...ResponseComment
          }
        }
      }
    }
  }
  ${RESPONSE_COMMENT_FRAGMENT}
`;

export const DELETE_INSPECT_RESPONSE_COMMENT = gql`
  mutation deleteInspectResponseComment($reportId: ID!, $categoryId: String!, $responseId: String!) {
    deleteInspectResponseComment(reportId: $reportId, categoryId: $categoryId, responseId: $responseId) {
      _id
      categories {
        responses {
          comment {
            ...ResponseComment
          }
        }
      }
    }
  }
  ${RESPONSE_COMMENT_FRAGMENT}
`;

export const UPDATE_INSPECTION_DATE = gql`
  mutation updateInspectionDate($reportId: ID!, $inspectionDate: String!) {
    updateInspectionDate(reportId: $reportId, inspectionDate: $inspectionDate) {
      _id
      inspectionDate
    }
  }
`;

// RESOLUTION

export const GET_RESOLUTIONS = gql`
  query getReportResolutionsById($reportId: String!) {
    getResolutionsByReportId(id: $reportId) {
      currentUserRole
      resolutions {
        ...Resolution
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
  ${RESOLUTION_FRAGMENT}
`;

export const ADD_RESOLUTION_EVENT = gql`
  mutation AddResolutionEvent($resolutionId: ID!, $reportId: ID!, $event: JSON!) {
    addResolutionEvent(resolutionId: $resolutionId, reportId: $reportId, event: $event) {
      ...Resolution
    }
  }
  ${RESOLUTION_FRAGMENT}
`;

export const UPDATE_TRACKING_NUMBER = gql`
  mutation updateTrackingNumber($resolutionId: ID!, $reportId: ID!, $trackingNumber: String!) {
    updateTrackingNumber(resolutionId: $resolutionId, reportId: $reportId, trackingNumber: $trackingNumber) {
      ...Resolution
    }
  }
  ${RESOLUTION_FRAGMENT}
`;

export const OPEN_TICKET = gql`
  mutation openTicket($resolutionId: ID!, $reportId: ID!) {
    openTicket(resolutionId: $resolutionId, reportId: $reportId) {
      ticketUrl
    }
  }
`;

// QUESTIONNAIRE

export const GET_SIBLING_PROGRAMS = gql`
  query findSiblingPrograms($programId: String!) {
    findSiblingPrograms(programId: $programId) {
      id
      name
    }
  }
`;

export const GET_CURRENT_QUESTIONNAIRE = gql`
  query getCurrentQuestionnaire($seriesId: ID!) {
    getCurrentQuestionnaire(seriesId: $seriesId) {
      domainId
      questionnaire {
        ...Questionnaire
      }
      draft {
        ...QTContents
      }
      settings {
        _id
        name
        program {
          id
          name
        }
        print {
          header
          includeThumbnails
          includePeopleNames
          includePrivateNotesAndComments
          showNoncompliantOnly
          includeResolutions
        }
        isShareEnabled
        updateResponsiblePeople
        updateNotesAndComments
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
  ${QT_CONTENT_FRAGMENT}
`;

export const QUESTIONNAIRES = gql`
  query questionnaires($campusCode: String!, $domainId: String, $programId: ID, $status: String, $term: String) {
    questionnaires(campusCode: $campusCode, domainId: $domainId, programId: $programId, status: $status, term: $term) {
      id
      name
      status
      domainName
      programName
    }
  }
`;

export const CHANGE_PROGRAM = gql`
  mutation changeQuestionnaireProgram($seriesId: String!, $programId: String!) {
    changeQuestionnaireProgram(seriesId: $seriesId, programId: $programId) {
      _id
      program {
        id
        name
      }
    }
  }
`;

export const RENAME_QUESTIONNAIRE = gql`
  mutation renameQuestionnaire($seriesId: String!, $name: String!) {
    renameQuestionnaire(seriesId: $seriesId, name: $name) {
      _id
      name
    }
  }
`;

export const UPDATE_QUESTIONNAIRE_SETTINGS = gql`
  mutation updateQuestionnaireSettings($id: ID!, $settings: JSON!) {
    updateQuestionnaireSettings(id: $id, settings: $settings) {
      print {
        header
        includeThumbnails
        includePeopleNames
        includePrivateNotesAndComments
        showNoncompliantOnly
        includeResolutions
      }
      isShareEnabled
      updateNotesAndComments
    }
  }
`;

export const DEACTIVATE_QUESTIONNAIRE_SERIES = gql`
  mutation deactivateQuestionnaireSeries($seriesId: ID!) {
    deactivateQuestionnaireSeries(seriesId: $seriesId)
  }
`;

export const REACTIVATE_QUESTIONNAIRE_SERIES = gql`
  mutation reactivateQuestionnaireSeries($seriesId: ID!) {
    reactivateQuestionnaireSeries(seriesId: $seriesId)
  }
`;

// TEMPLATE

export const CREATE_QUESTIONNAIRE_TEMPLATE = gql`
  mutation createQuestionnaireTemplate($questionnaireTemplate: questionnaireTemplateInputType!) {
    createQuestionnaireTemplate(questionnaireTemplate: $questionnaireTemplate) {
      templateId
      draft {
        ...QTContents
      }
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const RENAME_TEMPLATE = gql`
  mutation renameTemplate($templateId: ID!, $name: String!) {
    renameTemplate(templateId: $templateId, name: $name) {
      _id
      name
    }
  }
`;

export const GET_QUESTIONNAIRE_TEMPLATE = gql`
  query getQuestionnaireTemplate($templateId: ID!) {
    getQuestionnaireTemplate(templateId: $templateId) {
      template {
        ...QuestionnaireTemplate
      }
      draft {
        ...QTContents
      }
    }
  }
  ${QUESTIONNAIRE_TEMPLATE_FRAGMENT}
  ${QT_CONTENT_FRAGMENT}
`;

export const GET_TEMPLATE_NODE = gql`
  query getQuestionnaireTemplateNode($id: ID!) {
    getQuestionnaireTemplateNode(id: $id) {
      id
      name
    }
  }
`;

export const GET_QUESTIONNAIRE_TEMPLATES = gql`
  query findQuestionnaireTemplateNodes($tenantCode: String!, $feature: String, $status: String, $term: String) {
    findQuestionnaireTemplateNodes(tenantCode: $tenantCode, feature: $feature, status: $status, term: $term) {
      id
      name
      status
      lastPublished
      feature
    }
  }
`;

// DRAFT

export const CREATE_QUESTIONNAIRE_DRAFT = gql`
  mutation createQuestionnaireDraft($questionnaire: QuestionnaireInputType!) {
    createQuestionnaireDraft(questionnaire: $questionnaire) {
      seriesId
    }
  }
`;

export const GET_DRAFT = gql`
  query findInspectDraft($parentId: String!) {
    findInspectDraft(parentId: $parentId) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPDATE_DRAFT_REMINDERS = gql`
  mutation updateInspectDraftReminders($parentId: String!, $reminders: QuestionnaireRemindersInput!) {
    updateInspectDraftReminders(parentId: $parentId, reminders: $reminders) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPDATE_DRAFT_SETTINGS = gql`
  mutation updateInspectDraftSettings($parentId: String!, $settings: QTSettingsInput!) {
    updateInspectDraftSettings(parentId: $parentId, settings: $settings) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const REORDER_DRAFT_CATEGORIES = gql`
  mutation reorderInspectDraftCategories($parentId: String!, $source: Int!, $dest: Int!) {
    reorderInspectDraftCategories(parentId: $parentId, source: $source, dest: $dest) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPSERT_DRAFT_CATEGORY = gql`
  mutation upsertInspectDraftCategory($parentId: String!, $category: QuestionnaireCategoryInputType!) {
    upsertInspectDraftCategory(parentId: $parentId, category: $category) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPLOAD_DRAFT_QUESTIONS = gql`
  mutation uploadInspectDraftQuestions($parentId: String!, $data: String!) {
    uploadInspectDraftQuestions(parentId: $parentId, data: $data) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPSERT_DRAFT_CATEGORY_QUESTION = gql`
  mutation upsertInspectDraftCategoryQuestion(
    $parentId: String!
    $categoryKey: String!
    $question: QuestionnaireCategoryQuestionInputType!
  ) {
    upsertInspectDraftCategoryQuestion(parentId: $parentId, categoryKey: $categoryKey, question: $question) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const DELETE_DRAFT_CATEGORY_QUESTION = gql`
  mutation deleteInspectCraftCategoryQuestion($parentId: String!, $categoryKey: String!, $questionKey: String!) {
    deleteInspectCraftCategoryQuestion(parentId: $parentId, categoryKey: $categoryKey, questionKey: $questionKey) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const DELETE_DRAFT_CATEGORY = gql`
  mutation deleteInspectDraftCategory($parentId: String!, $categoryKey: String!) {
    deleteInspectDraftCategory(parentId: $parentId, categoryKey: $categoryKey) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPSERT_DRAFT_INCIDENT_QUESTION = gql`
  mutation upsertInspectDraftIncidentQuestion($parentId: String!, $question: QuestionnaireCategoryQuestionInputType!) {
    upsertInspectDraftIncidentQuestion(parentId: $parentId, question: $question) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const DELETE_DRAFT_INCIDENT_QUESTION = gql`
  mutation deleteInspectDraftIncidentQuestion($parentId: String!, $questionKey: String!) {
    deleteInspectDraftIncidentQuestion(parentId: $parentId, questionKey: $questionKey) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const UPSERT_DRAFT_SUBJECT_QUESTION = gql`
  mutation upsertInspectDraftSubjectQuestion($parentId: String!, $question: QuestionnaireCategoryQuestionInputType!) {
    upsertInspectDraftSubjectQuestion(parentId: $parentId, question: $question) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const DELETE_DRAFT_SUBJECT_QUESTION = gql`
  mutation deleteInspectDraftSubjectQuestion($parentId: String!, $questionKey: String!) {
    deleteInspectDraftSubjectQuestion(parentId: $parentId, questionKey: $questionKey) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const BULK_EDIT_REQUIRES_VERIFICATION = gql`
  mutation bulkEditInspectDraftRequiresVerification($parentId: String!, $requiresVerification: Boolean!) {
    bulkEditInspectDraftRequiresVerification(parentId: $parentId, requiresVerification: $requiresVerification) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const PUBLISH_DRY_RUN = gql`
  query inspectDraftPublishDryRun($parentId: String!) {
    inspectDraftPublishDryRun(parentId: $parentId)
  }
`;

export const PUBLISH_DRAFT = gql`
  mutation publishInspectDraft($parentId: String!) {
    publishInspectDraft(parentId: $parentId) {
      template {
        ...QuestionnaireTemplate
      }
      questionnaire {
        ...Questionnaire
      }
    }
  }
  ${QUESTIONNAIRE_TEMPLATE_FRAGMENT}
  ${QUESTIONNAIRE_FRAGMENT}
`;

export const PUBLISH_QUESTIONNAIRE_DRAFT = gql`
  mutation publishInspectQuestionnaireDraft($parentId: String!) {
    publishInspectQuestionnaireDraft(parentId: $parentId) {
      questionnaire {
        ...Questionnaire
      }
    }
  }
  ${QUESTIONNAIRE_FRAGMENT}
`;

export const CREATE_DRAFT_FROM_PUBLISHED = gql`
  mutation createInspectDraftFromPublished($parentId: String!, $type: String!) {
    createInspectDraftFromPublished(parentId: $parentId, type: $type) {
      ...QTContents
    }
  }
  ${QT_CONTENT_FRAGMENT}
`;

export const DEPENDENT_QUESTIONNAIRES = gql`
  query findDependentInspectQuestionnairesForTemplate($templateId: String!) {
    findDependentInspectQuestionnairesForTemplate(templateId: $templateId) {
      series {
        id
        name
        status
      }
      program {
        id
        name
      }
      domain {
        domainId
        name
      }
    }
  }
`;
