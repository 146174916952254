import React, { useState, useMemo } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { AccessDenied, ErrorMessage } from '../../components/errors';
import { useFieldArray, useForm, Controller, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIND_VALID_REFUND_ITEMS, STORE_WITH_INVENTORY, SUBMIT_REFUND, ORDER } from '../graphql/query';
import { useQuery, useMutation } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import { CATALOG_TYPE, STORE_PERMISSION } from '@risk-and-safety/library';
import { ButtonProgress } from '../../components';
import { useToast } from '../../hooks';

const RETURN_REASONS = [
  { value: 'Specification Mismatch', label: 'Specification Mismatch' },
  { value: 'Quality Mismatch', label: 'Quality Mismatch' },
  { value: 'Incorrect Mismatch', label: 'Incorrect Mismatch' },
  { value: 'Packaging Mismatch', label: 'Packaging Mismatch' },
  { value: 'Shelf Mismatch', label: 'Shelf Mismatch' },
  { value: 'Storage Mismatch', label: 'Storage Mismatch' },
  { value: 'Storage and Handling Damage', label: 'Storage and Handling Damage' },
  { value: 'Regulatory Compliance', label: 'Regulatory Compliance' },
  { value: 'Quantity Discrepancy', label: 'Quantity Discrepancy' },
  { value: 'Performance Issues', label: 'Performance Issues' },
  { value: 'Storage Unavailable', label: 'Storage Unavailable' },
  { value: 'No Longer Needed', label: 'No Longer Needed' },
];

const FormControlSelect = ({ name, control, label, options, isSubmitting }) => {
  return (
    <FormControl variant="outlined" disabled={isSubmitting} required>
      <InputLabel id={label}>{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
            label={`${label} *`}
            labelId={label}
            value={value ?? ''}
            className="w-256 overflow-hidden text-ellipsis"
            onChange={onChange}>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

const RefundableItem = ({
  label,
  itemId,
  control,
  remove,
  append,
  fields,
  quantity,
  missing,
  sublocations,
  isChemical,
  refundableItemsWithNoBarcode,
  isSubmitting,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const isSelect = fields.findIndex((item) => item.itemId === itemId);

  const sublocationOptions = Object.values(sublocations).map((sub) => ({
    value: sub._id,
    label: `${sub.sublocationName} - ${sub.roomNumber} - ${sub.buildingName}`,
  }));

  const handleChange = (event) => {
    setIsSelected(event.target.checked);
    if (event.target.checked) {
      append({
        itemId,
        quantity: quantity === 1 ? 1 : null,
        reason: '',
        refundLocation: null,
        isChemical,
        nonbarcodeItems: refundableItemsWithNoBarcode,
      });
    } else {
      isSelect !== -1 && remove(isSelect);
    }
  };

  return (
    <div className="flex flex-col space-y-16">
      <FormControlLabel
        disabled={missing || isSubmitting}
        control={<Checkbox checked={isSelected} onChange={handleChange} color="primary" />}
        label={label}
      />

      {isSelect !== -1 && (
        <>
          {quantity !== 1 && (
            <FormControlSelect
              name={`refundItems[${isSelect}].quantity`}
              control={control}
              label="Choose quantity"
              options={[...Array(quantity).keys()].map((q) => ({ value: q + 1, label: q + 1 }))}
              isSubmitting={isSubmitting}
            />
          )}

          <p className="text-pretty text-balance text-wrap max-w-256">Items are moved back to stock on refund.</p>
          <FormControlSelect
            name={`refundItems[${isSelect}].refundLocation`}
            control={control}
            label="Choose a sublocation"
            options={sublocationOptions}
            isSubmitting={isSubmitting}
          />

          <p className="ext-pretty text-balance text-wrap max-w-256">Why are you returning this?</p>
          <FormControlSelect
            name={`refundItems[${isSelect}].reason`}
            control={control}
            label="Choose a response"
            options={RETURN_REASONS}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </div>
  );
};

const SubmitButton = ({ control, refundLoading }) => {
  const { isValid } = useFormState({
    control,
  });

  return (
    <div className="ml-32 flex flex-col items-end justify-start space-y-20 border-l-1 border-dashed pl-32 print:hidden">
      <ButtonProgress disabled={!isValid} type="submit" loading={refundLoading} className="w-200">
        Submit Refund
      </ButtonProgress>
    </div>
  );
};

const OrderRefundDetails = ({ store, order, refundData }) => {
  const history = useHistory();
  const { showError } = useToast();
  const { state } = useLocation();

  const navigateBack = () => {
    if (state?.fromOrderDetail) {
      history.goBack();
      return;
    }
    history.replace(`/store/${store._id}/order/${order._id}`);
  };

  const [submitRefund, { loading: refundLoading, client }] = useMutation(SUBMIT_REFUND, {
    onCompleted: navigateBack,
    onError: async (error) => {
      showError(error?.message?.split(':')?.pop() ?? 'An unexpected error occurred. Please try again later.');
      await client.refetchQueries({
        include: [ORDER, FIND_VALID_REFUND_ITEMS],
      });
      navigateBack();
    },
    fetchPolicy: 'no-cache',
    refetchQueries: [ORDER, FIND_VALID_REFUND_ITEMS],
  });

  const {
    loading: storeInventoryLoading,
    data: storeData,
    error: storeInventoryError,
  } = useQuery(STORE_WITH_INVENTORY, {
    variables: { id: store._id },
    fetchPolicy: 'cache-and-network',
  });

  const graphQlSublocationData = storeData?.store?.inventory?.sublocations;

  const sublocations = useMemo(
    () =>
      Object.keys(graphQlSublocationData ?? {}).reduce((acc, cur) => {
        graphQlSublocationData[cur].forEach((sub) => {
          if (sub?._id) {
            acc[sub._id] = sub;
          }
        });

        return acc;
      }, {}),
    [graphQlSublocationData],
  );

  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: { refundItems: [] },
    resolver: yupResolver(
      yup.object().shape({
        refundItems: yup
          .array(
            yup.object().shape({
              quantity: yup.number().required(),
              reason: yup.string().required(),
              refundLocation: yup.string().required(),
            }),
          )
          .required()
          .min(1, 'Error'),
      }),
    ),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'refundItems',
  });

  if (!store?.permissions?.includes(STORE_PERMISSION.WRITE)) {
    return <AccessDenied />;
  }

  if (storeInventoryLoading) {
    return <FuseLoading />;
  }

  if (storeInventoryError) {
    return <ErrorMessage />;
  }

  const refundableItemsWithNoBarcode = {};
  let refundableItems = [];

  refundData?.findValidRefundItems?.forEach((item) => {
    if (item?.inventoryItems?.barcode) {
      refundableItems.push(item);
    } else {
      if (!refundableItemsWithNoBarcode[item.product._id]) {
        const obj = {
          ...item,
          quantity: 0,
          inventoryItems: [],
        };
        refundableItemsWithNoBarcode[item.product._id] = obj;
      }
      refundableItemsWithNoBarcode[item.product._id].quantity++;
      refundableItemsWithNoBarcode[item.product._id].inventoryItems.push(item.inventoryItems);
    }
  });

  refundableItems = refundableItems.concat(Object.values(refundableItemsWithNoBarcode));

  if (!refundableItems.length) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Typography className="font-light" variant="h3" color="textSecondary">
          No Refund Available
        </Typography>
      </div>
    );
  }

  return (
    <form
      onSubmit={handleSubmit((data) => {
        let refundItems = [];
        data.refundItems.forEach((item) => {
          if (!item?.nonbarcodeItems) {
            const sublocation = sublocations[item.refundLocation];
            const returnLocation = {
              id: sublocation._id,
              buildingId: sublocation.buildingId,
              buildingName: sublocation.buildingName,
              floorId: sublocation.floorId,
              floorName: sublocation.floorName,
              roomId: sublocation.roomId,
              roomNumber: sublocation.roomNumber,
              sublocationId: sublocation.sublocationId,
              sublocationName: sublocation.sublocationName,
            };

            refundItems.push({
              inventoryItemId: item.itemId,
              quantity: item.quantity,
              isChemical: item.isChemical,
              returnLocation,
              reason: item.reason,
            });
          } else {
            const nonBarCodeItems = item.nonbarcodeItems.slice(0, item.quantity);

            nonBarCodeItems.forEach((nonBarCodeItem) => {
              const sublocation = sublocations[item.refundLocation];
              const returnLocation = {
                id: sublocation._id,
                buildingId: sublocation.buildingId,
                buildingName: sublocation.buildingName,
                floorId: sublocation.floorId,
                floorName: sublocation.floorName,
                roomId: sublocation.roomId,
                roomNumber: sublocation.roomNumber,
                sublocationId: sublocation.sublocationId,
                sublocationName: sublocation.sublocationName,
              };

              refundItems.push({
                inventoryItemId: nonBarCodeItem._id,
                quantity: 1,
                isChemical: item.isChemical,
                returnLocation,
                reason: item.reason,
              });
            });
          }
        });

        submitRefund({
          variables: {
            orderId: order._id,
            storeId: store._id,
            refundItems,
          },
        });
      })}
      className="mt-64 flex flex-grow px-28 print:mt-28">
      <div className="absolute left-48 top-136 flex h-64 items-center">
        <IconButton aria-label="back" onClick={navigateBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </div>

      <div className="flex-grow">
        <div className="mx-12 mb-56 flex flex-row">
          <div className="flex flex-col">
            <Typography className="font-light" variant="h5" color="textSecondary">
              Choose items to return
            </Typography>
          </div>
        </div>

        {refundableItems.map((item, index) => {
          return (
            <div
              key={`${item.product._id} - ${Array.isArray(item?.inventoryItems) ? item?.inventoryItems?._id : index}`}
              className="m-20">
              <RefundableItem
                label={`${item.product.name}${item?.inventoryItems?.barcode ? ' - ' : ''}${
                  item?.inventoryItems?.barcode ?? ''
                }`}
                control={control}
                itemId={Array.isArray(item?.inventoryItems) ? item.product.id : item?.inventoryItems?._id}
                append={append}
                remove={remove}
                fields={fields}
                isChemical={item.product.library.catalog === CATALOG_TYPE.CHEMICAL ? true : false}
                sublocations={sublocations}
                missing={!item.isRefundable}
                quantity={item.quantity}
                refundableItemsWithNoBarcode={refundableItemsWithNoBarcode[item.product._id]?.inventoryItems}
                isSubmitting={refundLoading}
              />

              {!item.isRefundable && (
                <p className="text-pretty text-balance text-wrap max-w-256 text-red-500">
                  Non-refundable item. Original product not found in the system. If the item is a chemical, the customer
                  may have removed it from their own inventory. Please reach out to Support for assistance.
                </p>
              )}
            </div>
          );
        })}
      </div>

      <SubmitButton control={control} refundLoading={refundLoading} />
    </form>
  );
};

export default OrderRefundDetails;
