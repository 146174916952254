import FusePageSimple from '@fuse/core/FusePageSimple';
import { Divider, Typography } from '@material-ui/core';
import { useDrive } from '@risk-and-safety/drive-client';
import { COMPLIANCE_QUESTION_TYPE } from '@risk-and-safety/inspect-v2-common';
import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CommentItem, Thumbnail, FormattedDate, QuestionFactory, RichTextEditor } from '../../../components';
import { IncidentSummary } from '../incident/IncidentSummary';
import { ResolutionEvent } from '../resolution';
import ShowMore from '../ShowMore';
import ReportHeader from './ReportHeader';
import { ComplianceButton } from './compliance/ComplianceButtons';
import { SubjectDetails } from './SubjectDetails';

import config from '../../../config';

function getResponse(responses, questionKey) {
  return responses.find(({ key }) => key === questionKey);
}

const PreviewReport = React.forwardRef(
  ({ expandQuestions, inspectors, questionnaire, report, resolutions, responsiblePeople, subject, title }, ref) => {
    const resolutionIncidentMap = resolutions ? groupBy(resolutions, ({ incident }) => incident._id) : {};

    const { get: getNoteAttachment } = useDrive('inspect-v2', {
      graphUrl: `${config.GRAPH_URL}/graphql`,
      operations: {
        get: 'getInspectNoteAttachment',
      },
    });

    const { get: getIncidentAttachment } = useDrive('inspect-v2', {
      graphUrl: `${config.GRAPH_URL}/graphql`,
      operations: {
        get: 'getInspectIncidentAttachment',
      },
    });

    return (
      <div ref={ref}>
        <FusePageSimple
          header={
            <div className="flex flex-1 flex-col">
              <div className="flex flex-col items-end px-24">
                <ReportHeader
                  animate={false}
                  subject={subject}
                  questionnaire={questionnaire}
                  report={report}
                  overline={title && <Typography className="flex w-full justify-center text-24">{title}</Typography>}
                />
              </div>
            </div>
          }
          content={
            <div className="space-y-20 p-24 pb-64">
              <section>
                <Typography variant="h2" className="text-20 font-medium" color="textSecondary">
                  Details
                </Typography>
                {subject.details && (
                  <SubjectDetails
                    details={subject.details}
                    reportId={report._id}
                    subjectType={questionnaire.subject.type}
                  />
                )}
                <div className="pl-16">
                  {questionnaire.subject.questions.map((question) => {
                    return (
                      // HACK: Prevents page breaks from cutting content in half when printing
                      <div key={question.key} className="py-8" style={{ breakInside: 'avoid' }}>
                        <QuestionFactory
                          disabled
                          isPrintView
                          question={question}
                          values={getResponse(report.subject.responses, question.key)?.values}
                        />
                      </div>
                    );
                  })}
                </div>
              </section>

              <section>
                <Typography variant="h2" className="text-20 font-medium" color="textSecondary">
                  Notes
                </Typography>
                <div className="pl-16">
                  {report.subject.notes.map((note) => (
                    <CommentItem
                      key={note._id}
                      condensed
                      user={note.createdBy}
                      isPrivate={note.isPrivate}
                      subtitle={<FormattedDate date={note.createdDate} showTime />}
                    >
                      <div className="flex w-full flex-col">
                        {note.comment && <RichTextEditor value={note.comment} />}
                        {Boolean(note.attachments?.length) && (
                          <div
                            className="flex w-full flex-wrap gap-8"
                            // HACK: Prevents page breaks from cutting content in half when printing
                            style={{ breakInside: 'avoid' }}
                          >
                            {note.attachments.map((attachment) => (
                              <Thumbnail attachment={attachment} remoteSrc={(name) => getNoteAttachment(name)} />
                            ))}
                          </div>
                        )}
                      </div>
                    </CommentItem>
                  ))}
                </div>
              </section>

              {Boolean(inspectors.length) && (
                <section>
                  <PersonList title="Contributors" people={inspectors} />
                </section>
              )}

              {Boolean(responsiblePeople.length) && (
                <section>
                  <PersonList title="Responsible People" people={responsiblePeople} />
                </section>
              )}

              {/* TODO: this wont handle series ... we need to map over report */}
              {!questionnaire.categories?.length && (
                <section className="flex pt-20">
                  <Typography>No incidents to show.</Typography>
                </section>
              )}

              {questionnaire.categories.map((category) => {
                const reportCategory = report.categories.find((c) => c.key === category.key);
                return (
                  <section key={category.key} className="pt-20">
                    <Typography variant="h2" className="mb-16 text-20 font-medium" color="textSecondary">
                      {category.label}
                    </Typography>

                    {category.questions.map((question) => {
                      const response = getResponse(reportCategory.responses, question.key);
                      return (
                        <div className="mb-16" key={question.key}>
                          <span className="w-full pt-8">
                            <div className="flex flex-row">
                              <span className="w-40">
                                {question.type === COMPLIANCE_QUESTION_TYPE && response?.values?.length && (
                                  <ComplianceButton type={response.values?.[0].value} disabled />
                                )}
                              </span>
                              <span className="pr-40 pt-8">
                                {expandQuestions ? (
                                  <Typography>{question.label}</Typography>
                                ) : (
                                  <ShowMore text={question.label} />
                                )}
                              </span>
                            </div>

                            <div className="ml-40">
                              {question.type !== COMPLIANCE_QUESTION_TYPE && (
                                <QuestionFactory
                                  disabled
                                  question={{ ...question, label: '' }}
                                  values={response?.values || []}
                                />
                              )}

                              {response?.incidents?.map((incident) => (
                                <div key={incident._id} className="py-8">
                                  <IncidentSummary incident={incident} />
                                  {incident.comments.map((comment) => (
                                    <CommentItem
                                      key={comment._id}
                                      condensed
                                      user={comment.createdBy}
                                      subtitle={<FormattedDate date={comment.createdDate} showTime />}
                                    >
                                      {comment.isPrivate && <Typography variant="subtitle2">Private</Typography>}
                                      <RichTextEditor value={comment.text} />
                                    </CommentItem>
                                  ))}
                                  {Boolean(incident.attachments?.length) && (
                                    <div
                                      className="-ml-8 flex w-full flex-wrap gap-8"
                                      // HACK: Prevents page breaks from cutting content in half when printing
                                      style={{ breakInside: 'avoid' }}
                                    >
                                      {incident.attachments.map((attachment) => (
                                        <Thumbnail
                                          attachment={attachment}
                                          remoteSrc={(name) => getIncidentAttachment(name)}
                                        />
                                      ))}
                                    </div>
                                  )}
                                  <Divider className="my-24" />
                                  {resolutionIncidentMap[incident._id]?.map((resolution) =>
                                    resolution.log.map((event) => (
                                      <div key={event.createdDate} className="flex w-full flex-row">
                                        <ResolutionEvent event={event} reportId={report._id} />
                                      </div>
                                    )),
                                  )}
                                </div>
                              ))}
                            </div>
                          </span>
                        </div>
                      );
                    })}
                  </section>
                );
              })}
            </div>
          }
        />
      </div>
    );
  },
);

PreviewReport.propTypes = {
  expandQuestions: PropTypes.bool,
  inspectors: PropTypes.arrayOf(PropTypes.any).isRequired,
  questionnaire: PropTypes.objectOf(PropTypes.any).isRequired,
  report: PropTypes.objectOf(PropTypes.any).isRequired,
  resolutions: PropTypes.arrayOf(PropTypes.any),
  responsiblePeople: PropTypes.arrayOf(PropTypes.any).isRequired,
  subject: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string,
};

PreviewReport.defaultProps = {
  expandQuestions: false,
  resolutions: [],
  title: null,
};

function PersonList({ title, people }) {
  return (
    <>
      <div className="pt-20">
        <Typography variant="h2" className="text-20 font-medium" color="textSecondary">
          {title}
        </Typography>
      </div>
      <ul className="pl-16">
        {people.map((person) => (
          <li key={person.userId}>
            <Typography component="span" className="mr-5">
              {person.firstName} {person.lastName}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="span">
              {person.email}
            </Typography>
          </li>
        ))}
      </ul>
    </>
  );
}

PersonList.propTypes = {
  title: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PreviewReport;
