import { Icon, IconButton, Input, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const ShareableLink = ({ link }) => {
  const inputRef = useRef(null);

  const copyLink = () => {
    inputRef.current.select();
    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('not copied'); // eslint-disable-line no-console
    }
  };

  return (
    <div className="min-w-520 border-grey my-10 border-1 border-solid">
      <Input
        id="shareable-link"
        inputProps={{
          'aria-label': 'Shareable Link',
        }}
        className="w-11/12 pl-10 text-sm"
        disableUnderline
        readOnly
        value={link}
        inputRef={inputRef}
      />
      <Tooltip title="Copy to clipboard">
        <IconButton className="px-8" component="span" onClick={copyLink}>
          <Icon>content_copy</Icon>
        </IconButton>
      </Tooltip>
    </div>
  );
};

ShareableLink.propTypes = {
  link: PropTypes.string.isRequired,
};

export default ShareableLink;
