"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
module.exports.isFormValid = function (questions, answers) {
  var errors = [];
  module.exports.parseTemplate(questions, answers, errors);
  return !(errors.length > 0);
};
module.exports.parseTemplate = function (questions, answers, errors) {
  questions.map(function (question) {
    if (question && _typeof(question) === 'object' && question.constructor === Object) {
      if (question.questions) {
        var nestedQuestions = question.questions;
        var nestedAnswers = answers && answers[question.key];
        module.exports.parseTemplate(nestedQuestions, nestedAnswers, errors);
      } else if (question.required && module.exports.hasRequiredError(answers, question.key)) {
        errors.push(true);
      }
    } else if (Array.isArray(question)) {
      module.exports.parseTemplate(question, answers, errors);
    }
    return errors;
  });
};
module.exports.hasRequiredError = function (answers, questionKey) {
  return !(answers && answers[questionKey]);
};