const { ObjectId } = require('bson');
const tv4 = require('tv4');
const TemplateApplicationWindow = require('./template.application-window');
const PARENT_TYPES = require('../constants/assessment-parent-type.constant');

class AssessmentTemplate {
  constructor(opt = {}) {
    this._id = null;
    this.type = null;
    this.campusCode = null;
    this.tenantCode = null;
    this.label = null;
    this.description = null;
    this.ruleSet = null;
    this.permissionId = null;
    this.notificationConfigId = null;
    this.assessmentTypeConfigId = null;
    this.sequenceId = null;
    this.reportRulesId = null;
    this.ownerLimit = null;
    this.questions = [];
    this.internalSections = [];
    this.recertificationDurationInMonths = null;
    this.trainings = null;
    this.active = null;
    this.nextSteps = {};
    this.restrictCreateAccess = false;
    this.parentType = null;
    // lab-hazard does not need a parent to be sent, backend automatically takes care
    // vs unit risk requires a parent department to exist
    this.requireExistingParent = false;
    this.limitSeriesParent = true;
    this.sections = [];
    this.reportHeaders = null;
    this.namePrefix = null;
    this.adminUpdateGeneralDetails = false;
    this.rosterQuestions = null;
    this.locationQuestions = null;
    this.metaQuestions = null;
    this.applicationWindow = {
      limit: null,
      startDate: null,
      endDate: null,
    };
    this.printTemplate = null;
    this.formActionQuestions = null;
    this.emptyParentLabel = null;
    this.parentSelectedLabel = null;
    this.hideDescription = false;
    this.fillObject(opt);
  }

  fillObject(opt) {
    this._id = opt._id ? new ObjectId(opt._id) : null;
    this.type = opt.type;
    this.campusCode = opt.campusCode;
    this.tenantCode = opt.tenantCode;
    this.label = opt.label;
    this.description = opt.description || '';
    this.ruleSet = opt.ruleSet || null;
    this.permissionId = opt.permissionId ? new ObjectId(opt.permissionId) : null;
    this.notificationConfigId = opt.notificationConfigId ? new ObjectId(opt.notificationConfigId) : null;
    this.assessmentTypeConfigId = opt.assessmentTypeConfigId ? new ObjectId(opt.assessmentTypeConfigId) : null;
    this.reportRulesId = opt.reportRulesId ? new ObjectId(opt.reportRulesId) : null;
    this.sequenceId = opt.sequenceId ? new ObjectId(opt.sequenceId) : null;
    this.ownerLimit = opt.ownerLimit || 1;
    this.questions = opt.questions || [];
    this.internalSections = opt.internalSections || [];
    this.recertificationDurationInMonths = opt.recertificationDurationInMonths || 12;
    this.trainings = opt.trainings || [];
    this.active = !!opt.active;
    this.nextSteps = opt.nextSteps || {};
    this.restrictCreateAccess = opt.restrictCreateAccess || false;
    this.parentType = opt.parentType || 'GROUP';
    this.requireExistingParent = opt.requireExistingParent || false;
    this.limitSeriesParent = opt.limitSeriesParent || false;
    this.sections = opt.sections || [];
    this.reportHeaders = opt.reportHeaders || null;
    this.namePrefix = opt.namePrefix || null;
    this.adminUpdateGeneralDetails = opt.adminUpdateGeneralDetails || false;
    this.metaQuestions = opt.metaQuestions || null;
    this.rosterQuestions = opt.rosterQuestions || null;
    this.locationQuestions = opt.locationQuestions || null;
    this.applicationWindow = new TemplateApplicationWindow(opt.applicationWindow);
    this.printTemplate = opt.printTemplate || null;
    this.formActionQuestions = opt.formActionQuestions || [];
    this.emptyParentLabel = opt.emptyParentLabel || null;
    this.parentSelectedLabel = opt.parentSelectedLabel || null;
    this.hideDescription = opt.hideDescription || false;
  }

  validate() {
    return tv4.validateMultiple(this, AssessmentTemplate.schema());
  }

  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'assessment-template.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        _id: { type: 'object' },
        type: { type: 'string' },
        campusCode: { type: 'string' },
        tenantCode: { type: 'string' },
        label: { type: 'string' },
        description: { type: 'string' },
        ruleSet: { type: 'string' },
        permissionId: { type: 'object' },
        reportRulesId: { type: ['object', 'null'] },
        sequenceId: { type: ['object', 'null'] },
        ownerLimit: { type: 'number' },
        questions: { type: 'array' },
        assessmentTypeConfigId: { type: 'object' },
        recertificationDurationInMonths: { type: 'number' },
        trainings: { type: 'array', item: { type: 'object' } },
        active: { type: 'boolean' },
        nextSteps: { type: 'object' },
        restrictCreateAccess: { type: 'boolean' },
        parentType: { anyOf: [{ enum: Object.values(PARENT_TYPES) }] },
        requireExistingParent: { type: 'boolean' },
        limitSeriesParent: { type: 'boolean' },
        sections: { type: 'array', item: { type: 'object' } },
        reportHeaders: { type: 'object' },
        namePrefix: { type: ['string', 'null'] },
        adminUpdateGeneralDetails: { type: 'boolean' },
        rosterQuestions: { type: 'object' },
        locationQuestions: { type: 'object' },
        internalSections: { type: 'array' },
        applicationWindow: { type: ['null', 'object'] },
        printTemplate: { type: 'string' },
        formActionQuestions: { type: 'array' },
        emptyParentLabel: { type: 'string' },
        parentSelectedLabel: { type: 'string' },
        hideDescription: { type: 'boolean' },
      },
      required: Object.keys(new AssessmentTemplate()),
    };
  }
}

tv4.addSchema(AssessmentTemplate.schema());

module.exports = AssessmentTemplate;
