/* eslint-disable import/no-cycle */
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FqQuestion from './FqQuestion';
import { getFqClassName, QUESTION_VARIANT, shouldDisplay } from './Helper';

const FqDependent = ({
  comments,
  disabled,
  label,
  labelVariant,
  onComment,
  onChange,
  parentResponses,
  question,
  shouldValidate,
  value,
  variant,
  nestedErrors,
}) => {
  const canDisplay = shouldDisplay(question, parentResponses);
  const [display, setDisplay] = useState(canDisplay);

  useEffect(() => {
    setDisplay(canDisplay);
  }, [parentResponses, question, canDisplay]);

  const subNestedErrors = nestedErrors[question.key] || {};

  return (
    <>
      {display ? (
        <div className={`${variant === QUESTION_VARIANT.COMPACT ? '-mt-4' : 'mb-16'}`}>
          {label ? <Typography className="pt-10 pb-4 text-sm">{label}</Typography> : null}
          <div className="flex w-full flex-wrap">
            {question.questions.map((quest) => (
              <div key={`fq_dep_${quest.key}`} className={getFqClassName(quest, 'w-full pl-24 pb-8')}>
                <FqQuestion
                  onChange={(val) => onChange({ ...value, ...val }, question.key)}
                  question={quest}
                  values={value}
                  disabled={disabled}
                  onComment={onComment}
                  comments={comments}
                  shouldValidate={shouldValidate}
                  labelVariant={labelVariant}
                  variant={variant}
                  nestedErrors={subNestedErrors}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

FqDependent.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  onChange: PropTypes.func.isRequired,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  parentResponses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  comments: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  onComment: PropTypes.func,
  shouldValidate: PropTypes.bool,
  nestedErrors: PropTypes.objectOf(PropTypes.any),
};

FqDependent.defaultProps = {
  disabled: false,
  label: '',
  labelVariant: 'default',
  variant: QUESTION_VARIANT.STANDARD,
  value: {},
  parentResponses: null,
  comments: null,
  onComment: () => null,
  shouldValidate: false,
  nestedErrors: {},
};

export default FqDependent;
