import PropTypes from 'prop-types';
import React from 'react';

import { getImpersonateId } from 'app/shared/helper';
import config from '../config';
import WebComponent from './WebComponent';

export default function InspectionList({ programId, questionnaireSeriesId, subjectId }) {
  const impersonateId = getImpersonateId();

  return (
    <WebComponent name="inspection-summary" sourceUrl={config.INSPECTION_TOOL_WC_URL}>
      <inspection-summary
        impersonateId={impersonateId}
        graphUrl={`${config.INSPECTION_TOOL_URL}/graphql`}
        programId={programId}
        questionnaireSeriesId={questionnaireSeriesId}
        subjectId={subjectId}
      />
    </WebComponent>
  );
}

InspectionList.propTypes = {
  programId: PropTypes.string.isRequired,
  questionnaireSeriesId: PropTypes.string.isRequired,
  subjectId: PropTypes.string.isRequired,
};
