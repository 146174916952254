"use strict";

module.exports = [{
  label: 'Acros',
  value: 'ACROS'
}, {
  label: 'Airgas',
  value: 'AIRGAS'
}, {
  label: 'AK Scientific',
  value: 'AK_SCIENTIFIC'
}, {
  label: 'Alfa-Aesar',
  value: 'ALFA_AESAR'
}, {
  label: 'American Custom Chemical Corporation',
  value: 'AMERICAN_CUSTOM_CHEMICAL_CORPORATION'
}, {
  label: 'American Elements',
  value: 'AMERICAN_ELEMENTS'
}, {
  label: 'American Master Tech',
  value: 'AMERICAN_MASTER_TECH'
}, {
  label: 'Apollo Scientific',
  value: 'APOLLO_SCIENTIFIC'
}, {
  label: 'Astatech',
  value: 'ASTATECH'
}, {
  label: 'Avanti',
  value: 'AVANTI'
}, {
  label: 'Avantor',
  value: 'AVANTOR'
}, {
  label: 'Bachem',
  value: 'BACHEM'
}, {
  label: 'BEHR',
  value: 'BEHR'
}, {
  label: 'Benjamin Moore',
  value: 'BENJAMINMOORE'
}, {
  label: 'Biospec',
  value: 'BIOSPEC'
}, {
  label: 'Chembridge',
  value: 'CHEMBRIDGE'
}, {
  label: 'Chemimpex',
  value: 'CHEMIMPEX'
}, {
  label: 'Combiblocks',
  value: 'COMBIBLOCKS'
}, {
  label: 'Dunn Edwards',
  value: 'DUNN_EDWARDS'
}, {
  label: 'EM Science',
  value: 'EM_SCIENCE'
}, {
  label: 'Enamine',
  value: 'ENAMINE'
}, {
  label: 'Fine Paints of Europe',
  value: 'FINEPAINTSOFEUROPE'
}, {
  label: 'Fisher Scientific',
  value: 'FISHER'
}, {
  label: 'Jon-Don',
  value: 'JON_DON'
}, {
  label: 'JT Baker',
  value: 'JT_BAKER'
}, {
  label: 'Kelly-Moore',
  value: 'KELLYMOORE'
}, {
  label: 'Krylon',
  value: 'KRYLON'
}, {
  label: 'Matreya LLC',
  value: 'MATREYA_LLC'
}, {
  label: 'MG Chemicals',
  value: 'MG_CHEMICALS'
}, {
  label: 'Molekula',
  value: 'MOLEKULA'
}, {
  label: 'National diagnostics',
  value: 'NATIONAL_DIAGNOSTICS'
}, {
  label: 'Neogen',
  value: 'NEOGEN'
}, {
  label: 'Other',
  value: 'OTHER'
}, {
  label: 'Pierce',
  value: 'PIERCE'
}, {
  label: 'Polysciences Inc.',
  value: 'POLYSCIENCES_INC.'
}, {
  label: 'Praxair',
  value: 'PRAXAIR'
}, {
  label: 'R & D Systems',
  value: 'R_&_D_SYSTEMS'
}, {
  label: 'Rust-Oleum',
  value: 'RUST-OLEUM'
}, {
  label: 'SAF-T-LOK',
  value: 'SAF-T-LOK'
}, {
  label: 'SCBT',
  value: 'SCBT'
}, {
  label: 'Sigma-Aldrich',
  value: 'SIGMA'
}, {
  label: 'Sika',
  value: 'SIKA'
}, {
  label: 'Strem Chemicals',
  value: 'STREM_CHEMICALS'
}, {
  label: 'TCI',
  value: 'TCI'
}, {
  label: 'TRC',
  value: 'TRC'
}, {
  label: 'Unknown',
  value: 'UNKNOWN'
}, {
  label: 'VWR',
  value: 'VWR'
}];