import { lazy } from 'react';

const OrganizationDetailPage = lazy(() => import('./containers/OrganizationDetailPage'));
const MyOrganizationListPage = lazy(() => import('./containers/MyOrganizationListPage'));
const MyRolesListPage = lazy(() => import('./components/MyRolesListPage'));
const ProfilePage = lazy(() => import('./containers/ProfilePage'));

const routes = {
  settings: {
    helpCategories: ['profile'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/profile/user/:userId',
      component: ProfilePage,
    },
    {
      path: '/profile/roles/user/:userId',
      component: MyRolesListPage,
    },
    {
      path: '/profile/:type/user/:userId',
      component: MyOrganizationListPage,
    },
    {
      path: [
        '/profile/:type/:id/:menu/manage/:nodeId?/room/:room?',
        '/profile/:type/:id/:menu/manage/:userId?',
        '/profile/:type/:id',
      ],
      component: OrganizationDetailPage,
    },
    {
      path: '/profile/:type',
      component: MyOrganizationListPage,
    },
    {
      path: '/profile',
      component: ProfilePage,
    },
  ],
};

export default routes;
