"use strict";

module.exports = {
  ASSESSMENT: 'ASSESSMENT',
  CAMPUS: 'CAMPUS',
  COLLEGE: 'COLLEGE',
  DEPARTMENT: 'DEPARTMENT',
  DIVISION: 'DIVISION',
  GROUP: 'GROUP',
  INVENTORY: 'INVENTORY',
  PERSON: 'PERSON',
  PROGRAM: 'PROGRAM',
  ROOM: 'ROOM',
  SERVICE: 'SERVICE',
  TENANT: 'TENANT',
  UNIT: 'UNIT'
};