module.exports = [{
  label: 'nm',
  value: 'nm'
}, {
  label: 'μm',
  value: 'μm'
}, {
  label: 'mm',
  value: 'mm'
}, {
  label: 'cm',
  value: 'cm'
}];