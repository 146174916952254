const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const {
  ObjectId
} = require('bson');
const yup = require('yup');
const NULLABLE_STRING = yup.string().default(null).nullable();
const YES_NO_STRING = NULLABLE_STRING.oneOf(['Yes', 'No', null]);
const COMPANY_SCHEMA = yup.object().shape({
  number: NULLABLE_STRING,
  name: NULLABLE_STRING,
  street: NULLABLE_STRING,
  poBoxNumber: NULLABLE_STRING,
  city: NULLABLE_STRING,
  state: NULLABLE_STRING,
  zipCode: NULLABLE_STRING
}).default(null).nullable();
const HAZARD_INFO_SCHEMA = yup.object().shape({
  stateRestricted: YES_NO_STRING,
  waterSensitiveRestriction: YES_NO_STRING,
  storageTemperatureRange: NULLABLE_STRING.oneOf(['Room Temperature', 'Fridge', 'Freezer', 'Other', null]),
  storageTemperatureRangeOther: yup.object().shape({
    details: NULLABLE_STRING.max(500)
  }),
  hazardsStatement: NULLABLE_STRING.max(1000),
  generalHazardTierClassification: NULLABLE_STRING.oneOf(['Red', 'Yellow', 'Green', null]),
  signalWord: NULLABLE_STRING.oneOf(['POISON/DANGER', 'DANGER (ONLY)', 'WARNING', 'CAUTION', 'NONE', null]),
  restrictedUse: YES_NO_STRING,
  cancer: NULLABLE_STRING.oneOf(['Known / Probable', 'Possible', 'Unclassifiable / Not Likely', null]),
  reproductiveOrDevelopmentalToxicity: NULLABLE_STRING.oneOf(['Listed', 'Not Listed', null]),
  endocrineDisruption: NULLABLE_STRING.oneOf(['Yes (listed)', 'Maybe (weak)', 'No (not listed)', null]),
  waterPollutionPotential: NULLABLE_STRING.oneOf(['303d listed', 'Not listed', null]),
  specialStatus: yup.array().of(yup.string().trim()).ensure(),
  cdprEnvHazards: yup.array().of(yup.string().trim()).ensure(),
  soilMobility: NULLABLE_STRING.oneOf(['GUS ≥2 or DPR classifies AI as exceeding SNVs', 'GUS <2 and Not listed by DPR as exceeding SNVs.', null]),
  persistentBioaccumulativeToxicSubstances: NULLABLE_STRING.oneOf(['Listed', 'Not Listed', null]),
  hazardsToBirds: NULLABLE_STRING.oneOf(['Extremely toxic', 'Toxic / Moderate (LC50 or LD50)', 'No Warning / Low (LC50 or LD50)', null]),
  hazardsToAquaticLife: NULLABLE_STRING.oneOf(['Extremely toxic', 'Toxic / Moderate (LC50)', 'No Warning / Low (LC50)', null]),
  hazardsToBees: NULLABLE_STRING.oneOf(['Extremely toxic', 'Toxic / Moderate (LC50)', 'No Warning / Low (LC50)', null]),
  hazardsToWildlife: NULLABLE_STRING.oneOf(['Extremely toxic', 'Toxic / Moderate (LC50 or LD50)', 'No Warning / Low (LC50 or LD50)', null])
});
class CaPesticideDetails extends RssModel {
  constructor(payload = {}) {
    super(payload);
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      epaCaRegNumber: yup.string().required(),
      company: COMPANY_SCHEMA,
      statusDate: yup.date(),
      formulations: yup.array().of(yup.string().trim()).ensure(),
      specificGravitiy: yup.number().default(0).nullable(),
      specificGravitiyIndicator: yup.string().default(null).nullable(),
      activeIngredients: yup.array().of(yup.object().shape({
        id: new ObjectIdSchema().default(() => new ObjectId()).required(),
        item: yup.object().shape({
          activeIngredient: yup.string().required(),
          activeIngredientPercent: yup.number().default(null).nullable(),
          activeIngredientStatus: yup.string().default('false').nullable()
        })
      })).ensure(),
      pests: yup.array().of(yup.string().trim()).ensure(),
      lastSyncDate: yup.date().default(null).nullable(),
      hazardInfo: HAZARD_INFO_SCHEMA,
      curatedDate: yup.date().default(null).nullable(),
      curated: yup.boolean().default(null).nullable()
    });
  }
}
module.exports = CaPesticideDetails;