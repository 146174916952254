import { FormControl, InputLabel, MenuItem, makeStyles, Select } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TenantSelect from './TenantSelect';
import { useTenants } from '../hooks';
import { GLOBAL_TENANT } from '../shared/helper';

const {
  NODE: { GLOBAL },
} = RELATIONSHIP_LABEL;

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: 300,
  },
  select: {
    display: 'flex',
    flexGrow: 1,
    '& > div:first-of-type': {
      flexBasis: '25%',
      minWidth: 85,
      paddingRight: theme.spacing(1),
    },
  },
}));

const CampusSelect = ({ id, includeAll, includeGlobal, tenantCode, value, variant, onSelect, disabled, size }) => {
  const TENANTS = useTenants();
  const [selectedTenant, setSelectedTenant] = useState(tenantCode);
  const [selectedCampus, setSelectedCampus] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    setSelectedCampus(value);
  }, [value]);

  useEffect(() => {
    setSelectedTenant(tenantCode);
  }, [tenantCode]);

  let campuses =
    includeGlobal && selectedTenant === GLOBAL
      ? GLOBAL_TENANT.campusList
      : TENANTS.getCampusesByTenantCode(selectedTenant);

  const handleSelect = (campusCode, tenant) => {
    const campus = campuses.find((c) => c.campusCode === campusCode || c.nodeId === campusCode);
    onSelect({ tenantCode: tenant, campusCode, campus });
    setSelectedCampus(campusCode);
    setSelectedTenant(tenant);
  };

  const selectId = `${id}_campus`;

  return (
    <div className={classNames(classes.content, variant === 'tenant' && classes.select)}>
      {variant === 'tenant' && (
        <TenantSelect
          id={selectId}
          includeGlobal={includeGlobal}
          tenantCode={selectedTenant}
          onSelect={(tenant) => handleSelect('', tenant)}
          disabled={disabled}
          size={size}
        />
      )}
      <FormControl variant="outlined" fullWidth disabled={!selectedTenant} size={size}>
        <InputLabel htmlFor={selectId}>Campus</InputLabel>
        <Select
          data-cy="e2e-campus"
          value={selectedCampus}
          label="Campus"
          variant="outlined"
          disabled={disabled}
          inputProps={{
            name: selectId,
            id: selectId,
            'aria-label': 'select campus',
          }}
          onChange={(event) => {
            handleSelect(event.target.value, selectedTenant);
          }}>
          {includeAll && (
            <MenuItem data-cy="e2e-all" key="all" value="all">
              All Campuses
            </MenuItem>
          )}
          {campuses &&
            campuses.map((campus) => (
              <MenuItem
                data-cy={`e2e-${campus.shortName || campus.campusCode || campus.node.id}`}
                key={campus.campusCode || campus.node.id}
                value={campus.campusCode || campus.node.id}>
                {campus.name || campus.node.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

CampusSelect.defaultProps = {
  id: '',
  value: '',
  tenantCode: '',
  includeGlobal: false,
  includeAll: false,
  variant: 'campus',
  onSelect: () => {},
  disabled: false,
  size: 'medium',
};

CampusSelect.propTypes = {
  id: PropTypes.string,
  includeGlobal: PropTypes.bool,
  includeAll: PropTypes.bool,
  tenantCode: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['campus', 'tenant']),
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
};

export default CampusSelect;
