const tv4 = require('tv4');

class AssessmentApplicationWindow {
  constructor(opt) {
    this.startDate = null;
    this.endDate = null;

    if (opt) {
      this.fillObject(opt);
    }
  }

  fillObject({ startDate = null, endDate = null }) {
    this.startDate = startDate ? new Date(startDate) : startDate;
    this.endDate = endDate ? new Date(endDate) : endDate;
  }

  validate() {
    return tv4.validateMultiple(this, AssessmentApplicationWindow.schema());
  }

  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'assessment.application-window.schema.json',
      additionalProperties: false,
      properties: {
        startDate: { type: ['null', 'object'] },
        endDate: { type: ['null', 'object'] },
      },
      required: Object.keys(new AssessmentApplicationWindow()),
    };
  }
}

tv4.addSchema(AssessmentApplicationWindow.schema());

module.exports = AssessmentApplicationWindow;
