import config from '../../config';
import { getHeaders } from '../../shared/helper';

const Helper = {
  handleDownload: async (filename, token, id, attachmentUrl) => {
    let url = `${config.DRIVE_URL}/${token}`;

    if (id && attachmentUrl) {
      url = `${attachmentUrl}/attachment/id/${id}`;
    }

    return fetch(url, { method: 'GET', headers: await getHeaders() })
      .then((res) => res.blob())
      .then((blob) => {
        Helper.download(blob, filename);
      });
  },

  download: (blob, filename) => {
    // Special case for IE as IE does not work for window.URL.createObjectURL
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  },
};
export default Helper;
