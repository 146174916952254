module.exports = [{
  type: 'ALL',
  name: 'All flagged'
}, {
  type: 'USER',
  name: 'Reported by User'
}, {
  type: 'CUSTOM',
  name: 'Custom Chemical Created'
}, {
  type: 'CUSTOM_NAME',
  name: 'Custom Name'
}];