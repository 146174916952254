const commoditiesList = [
  {
    name: 'STRUCTURAL PEST CONTROL',
    code: '10',
    qualifier: '0',
  },
  {
    name: 'STRUCTURAL PEST CONTROL/PARK',
    code: '10',
    qualifier: '20',
  },
  {
    name: 'PRECONSTRUCTION',
    code: '11',
    qualifier: '0',
  },
  {
    name: 'LANDSCAPE MAINTENANCE',
    code: '30',
    qualifier: '0',
  },
  {
    name: 'LANDSCAPE MAINTENANCE/PARK',
    code: '30',
    qualifier: '20',
  },
  {
    name: 'RIGHTS OF WAY',
    code: '40',
    qualifier: '0',
  },
  {
    name: 'RIGHTS OF WAY/PARK',
    code: '40',
    qualifier: '20',
  },
  {
    name: 'PUBLIC HEALTH',
    code: '50',
    qualifier: '0',
  },
  {
    name: 'PUBLIC HEALTH/PARK',
    code: '50',
    qualifier: '20',
  },
  {
    name: 'COUNTY AG. COMM. SALES',
    code: '60',
    qualifier: '0',
  },
  {
    name: 'VERTEBRATE PEST CONTROL',
    code: '80',
    qualifier: '0',
  },
  {
    name: 'COMMODITY FUMIGATION',
    code: '90',
    qualifier: '0',
  },
  {
    name: 'COMMODITY FUMIGATION/PARK',
    code: '90',
    qualifier: '20',
  },
  {
    name: 'FUMIGATION, OTHER',
    code: '91',
    qualifier: '0',
  },
  {
    name: 'FUMIGATION, OTHER/PARK',
    code: '91',
    qualifier: '20',
  },
  {
    name: 'RESEARCH COMMODITY',
    code: '99',
    qualifier: '0',
  },
  {
    name: 'RESEARCH COMMODITY/PARK',
    code: '99',
    qualifier: '20',
  },
  {
    name: 'REGULATORY PEST CONTROL',
    code: '100',
    qualifier: '0',
  },
  {
    name: 'REGULATORY PEST CONTROL/PARK',
    code: '100',
    qualifier: '20',
  },
  {
    name: 'GF-AZALEA',
    code: '151',
    qualifier: '30',
  },
  {
    name: 'GF-BULB',
    code: '151',
    qualifier: '31',
  },
  {
    name: 'GF-CACTUS',
    code: '151',
    qualifier: '32',
  },
  {
    name: 'GF-CARNATION',
    code: '151',
    qualifier: '33',
  },
  {
    name: 'GF-CHRISTMAS TREE',
    code: '151',
    qualifier: '34',
  },
  {
    name: 'GF-CHRYSANTHEMUM',
    code: '151',
    qualifier: '35',
  },
  {
    name: 'GF-CONIFER',
    code: '151',
    qualifier: '36',
  },
  {
    name: 'GF-DEC. SHRUB',
    code: '151',
    qualifier: '37',
  },
  {
    name: 'GF-DEC. TREE',
    code: '151',
    qualifier: '38',
  },
  {
    name: 'GF-EVG. SHRUB',
    code: '151',
    qualifier: '39',
  },
  {
    name: 'GF-EVG. TREE',
    code: '151',
    qualifier: '40',
  },
  {
    name: 'GF-FERN',
    code: '151',
    qualifier: '41',
  },
  {
    name: 'GF-FLOWER SEED',
    code: '151',
    qualifier: '44',
  },
  {
    name: 'GF-FLWRNG PLANT',
    code: '151',
    qualifier: '42',
  },
  {
    name: 'GF-FOLIAGE PLANT',
    code: '151',
    qualifier: '45',
  },
  {
    name: 'GF-FLWRNG TREE',
    code: '151',
    qualifier: '43',
  },
  {
    name: 'GF-GROUND COVER',
    code: '151',
    qualifier: '46',
  },
  {
    name: 'GF-HOUSE PLANT',
    code: '151',
    qualifier: '47',
  },
  {
    name: 'GF-ICEPLANT',
    code: '151',
    qualifier: '48',
  },
  {
    name: 'GF-IVY',
    code: '151',
    qualifier: '49',
  },
  {
    name: 'GF-JUNIPER',
    code: '151',
    qualifier: '50',
  },
  {
    name: 'GF-PALM',
    code: '151',
    qualifier: '51',
  },
  {
    name: 'GF-PINE TREE',
    code: '151',
    qualifier: '52',
  },
  {
    name: 'GF-ROSE',
    code: '151',
    qualifier: '53',
  },
  {
    name: 'GF-SUCCULENT',
    code: '151',
    qualifier: '54',
  },
  {
    name: 'GF-TURF',
    code: '151',
    qualifier: '55',
  },
  {
    name: 'GF-VINE',
    code: '151',
    qualifier: '56',
  },
  {
    name: 'N-GRNHS GRWN CUT FLWRS OR GREENS',
    code: '151',
    qualifier: '0',
  },
  {
    name: 'N-OUTDR GRWN CUT FLWRS OR GREENS',
    code: '152',
    qualifier: '0',
  },
  {
    name: 'OF-AZALEA',
    code: '152',
    qualifier: '30',
  },
  {
    name: 'OF-BULB',
    code: '152',
    qualifier: '31',
  },
  {
    name: 'OF-CACTUS',
    code: '152',
    qualifier: '32',
  },
  {
    name: 'OF-CARNATION',
    code: '152',
    qualifier: '33',
  },
  {
    name: 'OF-CHRISTMAS TREE',
    code: '152',
    qualifier: '34',
  },
  {
    name: 'OF-CHRYSANTHEMUM',
    code: '152',
    qualifier: '35',
  },
  {
    name: 'OF-CONIFER',
    code: '152',
    qualifier: '36',
  },
  {
    name: 'OF-DEC. SHRUB',
    code: '152',
    qualifier: '37',
  },
  {
    name: 'OF-DEC. TREE',
    code: '152',
    qualifier: '38',
  },
  {
    name: 'OF-EVG. SHRUB',
    code: '152',
    qualifier: '39',
  },
  {
    name: 'OF-EVG. TREE',
    code: '152',
    qualifier: '40',
  },
  {
    name: 'OF-FERN',
    code: '152',
    qualifier: '41',
  },
  {
    name: 'OF-FLOWER SEED',
    code: '152',
    qualifier: '44',
  },
  {
    name: 'OF-FLWRNG PLANT',
    code: '152',
    qualifier: '42',
  },
  {
    name: 'OF-FOLIAGE PLANT',
    code: '152',
    qualifier: '45',
  },
  {
    name: 'OF-FLWRNG TREE',
    code: '152',
    qualifier: '43',
  },
  {
    name: 'OF-GROUND COVER',
    code: '152',
    qualifier: '46',
  },
  {
    name: 'OF-HOUSE PLANT',
    code: '152',
    qualifier: '47',
  },
  {
    name: 'OF-ICEPLANT',
    code: '152',
    qualifier: '48',
  },
  {
    name: 'OF-IVY',
    code: '152',
    qualifier: '49',
  },
  {
    name: 'OF-JUNIPER',
    code: '152',
    qualifier: '50',
  },
  {
    name: 'OF-PALM',
    code: '152',
    qualifier: '51',
  },
  {
    name: 'OF-PINE TREE',
    code: '152',
    qualifier: '52',
  },
  {
    name: 'OF-ROSE',
    code: '152',
    qualifier: '53',
  },
  {
    name: 'OF-SUCCULENT',
    code: '152',
    qualifier: '54',
  },
  {
    name: 'OF-TURF',
    code: '152',
    qualifier: '55',
  },
  {
    name: 'OF-VINE',
    code: '152',
    qualifier: '56',
  },
  {
    name: 'GP-AZALEA',
    code: '153',
    qualifier: '30',
  },
  {
    name: 'GP-BULB',
    code: '153',
    qualifier: '31',
  },
  {
    name: 'GP-CACTUS',
    code: '153',
    qualifier: '32',
  },
  {
    name: 'GP-CARNATION',
    code: '153',
    qualifier: '33',
  },
  {
    name: 'GP-CHRISTMAS TREE',
    code: '153',
    qualifier: '34',
  },
  {
    name: 'GP-CHRYSANTHEMUM',
    code: '153',
    qualifier: '35',
  },
  {
    name: 'GP-CONIFER',
    code: '153',
    qualifier: '36',
  },
  {
    name: 'GP-DEC. SHRUB',
    code: '153',
    qualifier: '37',
  },
  {
    name: 'GP-DEC. TREE',
    code: '153',
    qualifier: '38',
  },
  {
    name: 'GP-EVG. SHRUB',
    code: '153',
    qualifier: '39',
  },
  {
    name: 'GP-EVG. TREE',
    code: '153',
    qualifier: '40',
  },
  {
    name: 'GP-FERN',
    code: '153',
    qualifier: '41',
  },
  {
    name: 'GP-FLOWER SEED',
    code: '153',
    qualifier: '44',
  },
  {
    name: 'GP-FLWRNG PLANT',
    code: '153',
    qualifier: '42',
  },
  {
    name: 'GP-FOLIAGE PLANT',
    code: '153',
    qualifier: '45',
  },
  {
    name: 'GP-FLWRNG TREE',
    code: '153',
    qualifier: '43',
  },
  {
    name: 'GP-GROUND COVER',
    code: '153',
    qualifier: '46',
  },
  {
    name: 'GP-HOUSE PLANT',
    code: '153',
    qualifier: '47',
  },
  {
    name: 'GP-ICEPLANT',
    code: '153',
    qualifier: '48',
  },
  {
    name: 'GP-IVY',
    code: '153',
    qualifier: '49',
  },
  {
    name: 'GP-JUNIPER',
    code: '153',
    qualifier: '50',
  },
  {
    name: 'GP-PALM',
    code: '153',
    qualifier: '51',
  },
  {
    name: 'GP-PINE TREE',
    code: '153',
    qualifier: '52',
  },
  {
    name: 'GP-ROSE',
    code: '153',
    qualifier: '53',
  },
  {
    name: 'GP-SUCCULENT',
    code: '153',
    qualifier: '54',
  },
  {
    name: 'GP-TURF',
    code: '153',
    qualifier: '55',
  },
  {
    name: 'GP-VINE',
    code: '153',
    qualifier: '56',
  },
  {
    name: 'N-GRNHS GRWN PLANTS IN CONTAINERS',
    code: '153',
    qualifier: '0',
  },
  {
    name: 'N-OUTDR CONTAINER/FLD GRWN PLANTS',
    code: '154',
    qualifier: '0',
  },
  {
    name: 'OP-AZALEA',
    code: '154',
    qualifier: '30',
  },
  {
    name: 'OP-BULB',
    code: '154',
    qualifier: '31',
  },
  {
    name: 'OP-CACTUS',
    code: '154',
    qualifier: '32',
  },
  {
    name: 'OP-CARNATION',
    code: '154',
    qualifier: '33',
  },
  {
    name: 'OP-CHRISTMAS TREE',
    code: '154',
    qualifier: '34',
  },
  {
    name: 'OP-CHRYSANTHEMUM',
    code: '154',
    qualifier: '35',
  },
  {
    name: 'OP-CONIFER',
    code: '154',
    qualifier: '36',
  },
  {
    name: 'OP-DEC. SHRUB',
    code: '154',
    qualifier: '37',
  },
  {
    name: 'OP-DEC. TREE',
    code: '154',
    qualifier: '38',
  },
  {
    name: 'OP-EVG. SHRUB',
    code: '154',
    qualifier: '39',
  },
  {
    name: 'OP-EVG. TREE',
    code: '154',
    qualifier: '40',
  },
  {
    name: 'OP-FERN',
    code: '154',
    qualifier: '41',
  },
  {
    name: 'OP-FLOWER SEED',
    code: '154',
    qualifier: '44',
  },
  {
    name: 'OP-FLWRNG PLANT',
    code: '154',
    qualifier: '42',
  },
  {
    name: 'OP-FOLIAGE PLANT',
    code: '154',
    qualifier: '45',
  },
  {
    name: 'OP-FLWRNG TREE',
    code: '154',
    qualifier: '43',
  },
  {
    name: 'OP-GROUND COVER',
    code: '154',
    qualifier: '46',
  },
  {
    name: 'OP-HOUSE PLANT',
    code: '154',
    qualifier: '47',
  },
  {
    name: 'OP-ICEPLANT',
    code: '154',
    qualifier: '48',
  },
  {
    name: 'OP-IVY',
    code: '154',
    qualifier: '49',
  },
  {
    name: 'OP-JUNIPER',
    code: '154',
    qualifier: '50',
  },
  {
    name: 'OP-PALM',
    code: '154',
    qualifier: '51',
  },
  {
    name: 'OP-PINE TREE',
    code: '154',
    qualifier: '52',
  },
  {
    name: 'OP-ROSE',
    code: '154',
    qualifier: '53',
  },
  {
    name: 'OP-SUCCULENT',
    code: '154',
    qualifier: '54',
  },
  {
    name: 'OP-TURF',
    code: '154',
    qualifier: '55',
  },
  {
    name: 'OP-VINE',
    code: '154',
    qualifier: '56',
  },
  {
    name: 'GT-AZALEA',
    code: '155',
    qualifier: '30',
  },
  {
    name: 'GT-BULB',
    code: '155',
    qualifier: '31',
  },
  {
    name: 'GT-CACTUS',
    code: '155',
    qualifier: '32',
  },
  {
    name: 'GT-CARNATION',
    code: '155',
    qualifier: '33',
  },
  {
    name: 'GT-CHRISTMAS TREE',
    code: '155',
    qualifier: '34',
  },
  {
    name: 'GT-CHRYSANTHEMUM',
    code: '155',
    qualifier: '35',
  },
  {
    name: 'GT-CONIFER',
    code: '155',
    qualifier: '36',
  },
  {
    name: 'GT-DEC. SHRUB',
    code: '155',
    qualifier: '37',
  },
  {
    name: 'GT-DEC. TREE',
    code: '155',
    qualifier: '38',
  },
  {
    name: 'GT-EVG. SHRUB',
    code: '155',
    qualifier: '39',
  },
  {
    name: 'GT-EVG. TREE',
    code: '155',
    qualifier: '40',
  },
  {
    name: 'GT-FERN',
    code: '155',
    qualifier: '41',
  },
  {
    name: 'GT-FLOWER SEED',
    code: '155',
    qualifier: '44',
  },
  {
    name: 'GT-FLWRNG PLANT',
    code: '155',
    qualifier: '42',
  },
  {
    name: 'GT-FOLIAGE PLANT',
    code: '155',
    qualifier: '45',
  },
  {
    name: 'GT-FLWRNG TREE',
    code: '155',
    qualifier: '43',
  },
  {
    name: 'GT-GROUND COVER',
    code: '155',
    qualifier: '46',
  },
  {
    name: 'GT-HOUSE PLANT',
    code: '155',
    qualifier: '47',
  },
  {
    name: 'GT-ICEPLANT',
    code: '155',
    qualifier: '48',
  },
  {
    name: 'GT-IVY',
    code: '155',
    qualifier: '49',
  },
  {
    name: 'GT-JUNIPER',
    code: '155',
    qualifier: '50',
  },
  {
    name: 'GT-PALM',
    code: '155',
    qualifier: '51',
  },
  {
    name: 'GT-PINE TREE',
    code: '155',
    qualifier: '52',
  },
  {
    name: 'GT-ROSE',
    code: '155',
    qualifier: '53',
  },
  {
    name: 'GT-SUCCULENT',
    code: '155',
    qualifier: '54',
  },
  {
    name: 'GT-TURF',
    code: '155',
    qualifier: '55',
  },
  {
    name: 'GT-VINE',
    code: '155',
    qualifier: '56',
  },
  {
    name: 'N-GRNHS GRWN TRNSPLNT/PRPGTV MTRL',
    code: '155',
    qualifier: '0',
  },
  {
    name: 'GT-AZALEA',
    code: '156',
    qualifier: '30',
  },
  {
    name: 'GT-BULB',
    code: '156',
    qualifier: '31',
  },
  {
    name: 'GT-CACTUS',
    code: '156',
    qualifier: '32',
  },
  {
    name: 'N-OUTDR GRWN TRNSPLNT/PRPGTV MTRL',
    code: '156',
    qualifier: '0',
  },
  {
    name: 'OT-CARNATION',
    code: '156',
    qualifier: '33',
  },
  {
    name: 'OT-CHRISTMAS TREE',
    code: '156',
    qualifier: '34',
  },
  {
    name: 'OT-CHRYSANTHEMUM',
    code: '156',
    qualifier: '35',
  },
  {
    name: 'OT-CONIFER',
    code: '156',
    qualifier: '36',
  },
  {
    name: 'OT-DEC. SHRUB',
    code: '156',
    qualifier: '37',
  },
  {
    name: 'OT-DEC. TREE',
    code: '156',
    qualifier: '38',
  },
  {
    name: 'OT-EVG. SHRUB',
    code: '156',
    qualifier: '39',
  },
  {
    name: 'OT-EVG. TREE',
    code: '156',
    qualifier: '40',
  },
  {
    name: 'OT-FERN',
    code: '156',
    qualifier: '41',
  },
  {
    name: 'OT-FLOWER SEED',
    code: '156',
    qualifier: '44',
  },
  {
    name: 'OT-FLWRNG PLANT',
    code: '156',
    qualifier: '42',
  },
  {
    name: 'OT-FOLIAGE PLANT',
    code: '156',
    qualifier: '45',
  },
  {
    name: 'OT-FLWRNG TREE',
    code: '156',
    qualifier: '43',
  },
  {
    name: 'OT-GROUND COVER',
    code: '156',
    qualifier: '46',
  },
  {
    name: 'OT-HOUSE PLANT',
    code: '156',
    qualifier: '47',
  },
  {
    name: 'OT-ICEPLANT',
    code: '156',
    qualifier: '48',
  },
  {
    name: 'OT-IVY',
    code: '156',
    qualifier: '49',
  },
  {
    name: 'OT-JUNIPER',
    code: '156',
    qualifier: '50',
  },
  {
    name: 'OT-PALM',
    code: '156',
    qualifier: '51',
  },
  {
    name: 'OT-PINE TREE',
    code: '156',
    qualifier: '52',
  },
  {
    name: 'OT-ROSE',
    code: '156',
    qualifier: '53',
  },
  {
    name: 'OT-SUCCULENT',
    code: '156',
    qualifier: '54',
  },
  {
    name: 'OT-TURF',
    code: '156',
    qualifier: '55',
  },
  {
    name: 'OT-VINE',
    code: '156',
    qualifier: '56',
  },
  {
    name: 'SMALL FRUITS/BERRY',
    code: '1000',
    qualifier: '0',
  },
  {
    name: 'BLACKBERRY',
    code: '1002',
    qualifier: '0',
  },
  {
    name: 'BOYSENBERRY',
    code: '1003',
    qualifier: '0',
  },
  {
    name: 'DEWBERRY',
    code: '1004',
    qualifier: '0',
  },
  {
    name: 'LOGANBERRY',
    code: '1005',
    qualifier: '0',
  },
  {
    name: 'RASPBERRY',
    code: '1006',
    qualifier: '0',
  },
  {
    name: 'BLUEBERRY',
    code: '1009',
    qualifier: '0',
  },
  {
    name: 'CRANBERRY',
    code: '1010',
    qualifier: '0',
  },
  {
    name: 'CURRANT',
    code: '1011',
    qualifier: '0',
  },
  {
    name: 'GOOSEBERRY',
    code: '1013',
    qualifier: '0',
  },
  {
    name: 'STRAWBERRY',
    code: '1016',
    qualifier: '0',
  },
  {
    name: 'STRAWBERRY PROC',
    code: '1016',
    qualifier: '89',
  },
  {
    name: 'OLALLIEBERRY',
    code: '1017',
    qualifier: '0',
  },
  {
    name: 'BUSHBERRY',
    code: '1023',
    qualifier: '0',
  },
  {
    name: 'MULBERRY',
    code: '1025',
    qualifier: '0',
  },
  {
    name: 'CITRUS',
    code: '2000',
    qualifier: '0',
  },
  {
    name: 'GRAPEFRUIT',
    code: '2002',
    qualifier: '0',
  },
  {
    name: 'GRAPEFRUIT PROC',
    code: '2002',
    qualifier: '89',
  },
  {
    name: 'KUMQUAT',
    code: '2003',
    qualifier: '0',
  },
  {
    name: 'LEMON',
    code: '2004',
    qualifier: '0',
  },
  {
    name: 'LIME',
    code: '2005',
    qualifier: '0',
  },
  {
    name: 'ORANGE',
    code: '2006',
    qualifier: '0',
  },
  {
    name: 'ORANGE PROC',
    code: '2006',
    qualifier: '89',
  },
  {
    name: 'TANGELO',
    code: '2007',
    qualifier: '0',
  },
  {
    name: 'TANGERINE',
    code: '2008',
    qualifier: '0',
  },
  {
    name: 'TANGERINE (SEEDLESS)',
    code: '2008',
    qualifier: '7',
  },
  {
    name: 'CITRUS HYBRIDS',
    code: '2009',
    qualifier: '0',
  },
  {
    name: 'POMELO',
    code: '2012',
    qualifier: '0',
  },
  {
    name: 'NUTS',
    code: '3000',
    qualifier: '0',
  },
  {
    name: 'ALMOND',
    code: '3001',
    qualifier: '0',
  },
  {
    name: 'CASHEW',
    code: '3003',
    qualifier: '0',
  },
  {
    name: 'CHESTNUT',
    code: '3004',
    qualifier: '0',
  },
  {
    name: 'FILBERT OR HAZLENUT',
    code: '3005',
    qualifier: '0',
  },
  {
    name: 'MACADAMIA NUT',
    code: '3007',
    qualifier: '0',
  },
  {
    name: 'PECAN',
    code: '3008',
    qualifier: '0',
  },
  {
    name: 'WALNUT',
    code: '3009',
    qualifier: '0',
  },
  {
    name: 'PISTACHIO',
    code: '3011',
    qualifier: '0',
  },
  {
    name: 'POME FRUIT',
    code: '4000',
    qualifier: '0',
  },
  {
    name: 'APPLE',
    code: '4001',
    qualifier: '0',
  },
  {
    name: 'PEAR',
    code: '4003',
    qualifier: '0',
  },
  {
    name: 'PEAR PROCESSING',
    code: '4003',
    qualifier: '89',
  },
  {
    name: 'QUINCE',
    code: '4004',
    qualifier: '0',
  },
  {
    name: 'ASIAN PEAR',
    code: '4501',
    qualifier: '0',
  },
  {
    name: 'STONE FRUIT',
    code: '5000',
    qualifier: '0',
  },
  {
    name: 'APRICOT',
    code: '5001',
    qualifier: '0',
  },
  {
    name: 'CHERRY',
    code: '5002',
    qualifier: '0',
  },
  {
    name: 'NECTARINE',
    code: '5003',
    qualifier: '0',
  },
  {
    name: 'PEACH',
    code: '5004',
    qualifier: '0',
  },
  {
    name: 'PEACH PROCESSING',
    code: '5004',
    qualifier: '89',
  },
  {
    name: 'PLUM',
    code: '5005',
    qualifier: '0',
  },
  {
    name: 'PRUNE',
    code: '5006',
    qualifier: '0',
  },
  {
    name: 'PLUOT',
    code: '5505',
    qualifier: '0',
  },
  {
    name: 'STONE FRUIT HYBRIDS',
    code: '5506',
    qualifier: '0',
  },
  {
    name: 'TROPICAL/SUBTROPICAL FRUIT',
    code: '6000',
    qualifier: '0',
  },
  {
    name: 'BANANA',
    code: '6002',
    qualifier: '0',
  },
  {
    name: 'DATE',
    code: '6004',
    qualifier: '0',
  },
  {
    name: 'FIG',
    code: '6005',
    qualifier: '0',
  },
  {
    name: 'GUAVA',
    code: '6006',
    qualifier: '0',
  },
  {
    name: 'MANGO',
    code: '6007',
    qualifier: '0',
  },
  {
    name: 'LOQUAT',
    code: '6008',
    qualifier: '0',
  },
  {
    name: 'PAPAYA',
    code: '6010',
    qualifier: '0',
  },
  {
    name: 'PERSIMMON',
    code: '6012',
    qualifier: '0',
  },
  {
    name: 'PINEAPPLE',
    code: '6013',
    qualifier: '0',
  },
  {
    name: 'POMEGRANATE',
    code: '6015',
    qualifier: '0',
  },
  {
    name: 'LITCHI NUT',
    code: '6017',
    qualifier: '0',
  },
  {
    name: 'KIWI FRUIT FUZZY',
    code: '6018',
    qualifier: '0',
  },
  {
    name: 'CACTUS PEAR',
    code: '6028',
    qualifier: '0',
  },
  {
    name: 'CARAMBOLA (JALEA)',
    code: '6029',
    qualifier: '0',
  },
  {
    name: 'CHERIMOYA',
    code: '6030',
    qualifier: '0',
  },
  {
    name: 'KIWI FRUIT HARDY',
    code: '6068',
    qualifier: '0',
  },
  {
    name: 'JUJUBE (CHINESE DATE)',
    code: '6504',
    qualifier: '0',
  },
  {
    name: 'BEVERAGE CROP',
    code: '7000',
    qualifier: '0',
  },
  {
    name: 'HERB, SPICE',
    code: '8000',
    qualifier: '0',
  },
  {
    name: 'ANISE',
    code: '8004',
    qualifier: '0',
  },
  {
    name: 'ANISE SEED',
    code: '8004',
    qualifier: '4',
  },
  {
    name: 'SWEET BASIL',
    code: '8006',
    qualifier: '0',
  },
  {
    name: 'DILL',
    code: '8015',
    qualifier: '0',
  },
  {
    name: 'GINGER',
    code: '8019',
    qualifier: '0',
  },
  {
    name: 'HOPS',
    code: '8020',
    qualifier: '0',
  },
  {
    name: 'OREGANO',
    code: '8026',
    qualifier: '0',
  },
  {
    name: 'ROSEMARY',
    code: '8032',
    qualifier: '0',
  },
  {
    name: 'SAGE',
    code: '8035',
    qualifier: '0',
  },
  {
    name: 'TARRAGON',
    code: '8041',
    qualifier: '0',
  },
  {
    name: 'THYME',
    code: '8042',
    qualifier: '0',
  },
  {
    name: 'PEPPER SPICE',
    code: '8050',
    qualifier: '0',
  },
  {
    name: 'PEPPER SPICE SEED',
    code: '8050',
    qualifier: '4',
  },
  {
    name: 'LEMONGRASS',
    code: '8522',
    qualifier: '0',
  },
  {
    name: 'STEVIA',
    code: '8523',
    qualifier: '0',
  },
  {
    name: 'CANTALOUPE',
    code: '10002',
    qualifier: '0',
  },
  {
    name: 'WATERMELON',
    code: '10008',
    qualifier: '0',
  },
  {
    name: 'CUCUMBER',
    code: '10010',
    qualifier: '0',
  },
  {
    name: 'PUMPKIN',
    code: '10011',
    qualifier: '0',
  },
  {
    name: 'SQUASH',
    code: '10012',
    qualifier: '0',
  },
  {
    name: 'SQUASH, SUMMER',
    code: '10013',
    qualifier: '0',
  },
  {
    name: 'SQUASH, WINTER',
    code: '10014',
    qualifier: '0',
  },
  {
    name: 'ZUCCHINI',
    code: '10015',
    qualifier: '0',
  },
  {
    name: 'BITTER MELON',
    code: '10017',
    qualifier: '0',
  },
  {
    name: 'GOURD',
    code: '10026',
    qualifier: '0',
  },
  {
    name: 'CHAYOTE',
    code: '10030',
    qualifier: '0',
  },
  {
    name: 'GOURDS (EDIBLE)',
    code: '10503',
    qualifier: '0',
  },
  {
    name: 'VEGETABLES, FRUITING',
    code: '11000',
    qualifier: '0',
  },
  {
    name: 'EGGPLANT',
    code: '11001',
    qualifier: '0',
  },
  {
    name: 'PEPPER, FRUITING',
    code: '11003',
    qualifier: '0',
  },
  {
    name: 'PEPPER, FRUITING SEED',
    code: '11003',
    qualifier: '4',
  },
  {
    name: 'PIMENTO',
    code: '11004',
    qualifier: '0',
  },
  {
    name: 'PIMENTO SEED',
    code: '11004',
    qualifier: '4',
  },
  {
    name: 'TOMATO',
    code: '11005',
    qualifier: '0',
  },
  {
    name: 'TOMATO SEED',
    code: '11005',
    qualifier: '4',
  },
  {
    name: 'TOMATILLO',
    code: '11008',
    qualifier: '0',
  },
  {
    name: 'VEGETABLES, LEAFY',
    code: '13000',
    qualifier: '0',
  },
  {
    name: 'COLE CROP',
    code: '13004',
    qualifier: '0',
  },
  {
    name: 'BROCCOLI',
    code: '13005',
    qualifier: '0',
  },
  {
    name: 'BROCCOLI PROC',
    code: '13005',
    qualifier: '89',
  },
  {
    name: 'BROCCOLI SEED',
    code: '13005',
    qualifier: '4',
  },
  {
    name: 'BRUSSEL SP SEED',
    code: '13006',
    qualifier: '4',
  },
  {
    name: 'BRUSSEL SPROUT',
    code: '13006',
    qualifier: '0',
  },
  {
    name: 'CABBAGE',
    code: '13007',
    qualifier: '0',
  },
  {
    name: 'CABBAGE SEED',
    code: '13007',
    qualifier: '4',
  },
  {
    name: 'CAULIFLOWER',
    code: '13008',
    qualifier: '0',
  },
  {
    name: 'CAULIFLOWER PROC',
    code: '13008',
    qualifier: '89',
  },
  {
    name: 'CAULIFLOWER SEED',
    code: '13008',
    qualifier: '4',
  },
  {
    name: 'COLLARD',
    code: '13009',
    qualifier: '0',
  },
  {
    name: 'COLLARD SEED',
    code: '13009',
    qualifier: '4',
  },
  {
    name: 'CHINESE CABBAGE (NAPA, WONG BOK, CELERY CABBAGE)',
    code: '13010',
    qualifier: '0',
  },
  {
    name: 'KALE',
    code: '13011',
    qualifier: '0',
  },
  {
    name: 'KALE SEED',
    code: '13011',
    qualifier: '4',
  },
  {
    name: 'KOHLRABI',
    code: '13012',
    qualifier: '0',
  },
  {
    name: 'KOHLRABI SEED',
    code: '13012',
    qualifier: '4',
  },
  {
    name: 'CORN SALAD',
    code: '13013',
    qualifier: '0',
  },
  {
    name: 'DANDELION GREEN',
    code: '13014',
    qualifier: '0',
  },
  {
    name: 'ENDIVE ESC SEED',
    code: '13015',
    qualifier: '4',
  },
  {
    name: 'ENDIVE ESCAROLE',
    code: '13015',
    qualifier: '0',
  },
  {
    name: 'GARDEN CRESS',
    code: '13017',
    qualifier: '0',
  },
  {
    name: 'ARTICHOKE',
    code: '13018',
    qualifier: '0',
  },
  {
    name: 'ARTICHOKE SEED',
    code: '13018',
    qualifier: '4',
  },
  {
    name: 'MUSTARD GREENS',
    code: '13021',
    qualifier: '0',
  },
  {
    name: 'PARSLEY',
    code: '13022',
    qualifier: '0',
  },
  {
    name: 'PARSLEY SEED',
    code: '13022',
    qualifier: '4',
  },
  {
    name: 'RHUBARB',
    code: '13023',
    qualifier: '0',
  },
  {
    name: 'SPINACH',
    code: '13024',
    qualifier: '0',
  },
  {
    name: 'SPINACH SEED',
    code: '13024',
    qualifier: '4',
  },
  {
    name: 'SWISS CHARD',
    code: '13025',
    qualifier: '0',
  },
  {
    name: 'TURNIP GREENS',
    code: '13026',
    qualifier: '0',
  },
  {
    name: 'WATERCRESS',
    code: '13027',
    qualifier: '0',
  },
  {
    name: 'LETTUCE, LEAF',
    code: '13031',
    qualifier: '0',
  },
  {
    name: 'LETTUCE, LEAF SEED',
    code: '13031',
    qualifier: '4',
  },
  {
    name: 'LETTUCE, ROMAINE',
    code: '13031',
    qualifier: '10',
  },
  {
    name: 'CARDOON',
    code: '13032',
    qualifier: '0',
  },
  {
    name: 'AMARANTH, EDIBLE (CHINESE SPINACH)',
    code: '13033',
    qualifier: '0',
  },
  {
    name: 'BURDOCK (LEAFY VEGETABLE)',
    code: '13035',
    qualifier: '0',
  },
  {
    name: 'RAPE (LEAFY VEGETABLE)',
    code: '13036',
    qualifier: '0',
  },
  {
    name: 'WATER CONVOLVULUS',
    code: '13037',
    qualifier: '0',
  },
  {
    name: 'UPLAND CRESS',
    code: '13039',
    qualifier: '0',
  },
  {
    name: 'LEAFY VEGETABLES (CRUCIFERS)',
    code: '13042',
    qualifier: '0',
  },
  {
    name: 'VEGETABLE, STEM',
    code: '13043',
    qualifier: '0',
  },
  {
    name: 'SPINACH, NEW ZEALAND',
    code: '13044',
    qualifier: '0',
  },
  {
    name: 'LETTUCE, HEAD',
    code: '13045',
    qualifier: '0',
  },
  {
    name: 'LETTUCE, HEAD SEED',
    code: '13045',
    qualifier: '4',
  },
  {
    name: 'SHUNGIKU',
    code: '13046',
    qualifier: '0',
  },
  {
    name: 'CACTUS LEAF',
    code: '13048',
    qualifier: '0',
  },
  {
    name: 'BAMBOO SHOOTS',
    code: '13051',
    qualifier: '0',
  },
  {
    name: 'RAPINI',
    code: '13052',
    qualifier: '0',
  },
  {
    name: 'CHINESE BROCCOLI (GAI LON)',
    code: '13053',
    qualifier: '0',
  },
  {
    name: 'MUSTARD CABBAGE (PAK CHOI)',
    code: '13054',
    qualifier: '0',
  },
  {
    name: 'CHERVIL',
    code: '13055',
    qualifier: '0',
  },
  {
    name: 'ARUGULA',
    code: '13056',
    qualifier: '0',
  },
  {
    name: 'CHRYSANTHEMUM, EDIBLE LEAVED',
    code: '13058',
    qualifier: '0',
  },
  {
    name: 'PURSLANE, GARDEN',
    code: '13061',
    qualifier: '0',
  },
  {
    name: 'CILANTRO',
    code: '13501',
    qualifier: '0',
  },
  {
    name: 'BOK CHOY LOOSE LEAF',
    code: '13502',
    qualifier: '0',
  },
  {
    name: 'CHINESE CHIVES',
    code: '13503',
    qualifier: '0',
  },
  {
    name: 'MIZUNA',
    code: '13504',
    qualifier: '0',
  },
  {
    name: 'SAVOY CABBAGE',
    code: '13505',
    qualifier: '0',
  },
  {
    name: 'GAI CHOY LOOSE LEAF',
    code: '13509',
    qualifier: '0',
  },
  {
    name: 'TAT SOI (SPINACH MUSTARD)',
    code: '13515',
    qualifier: '0',
  },
  {
    name: 'SPINACH, MALABAR',
    code: '13516',
    qualifier: '0',
  },
  {
    name: 'SPINACH, CEYLON',
    code: '13517',
    qualifier: '0',
  },
  {
    name: 'INDIAN AMARANTH (HINN CHOY)',
    code: '13519',
    qualifier: '0',
  },
  {
    name: 'CHRYSANTHEMUM, GARLAND',
    code: '13523',
    qualifier: '0',
  },
  {
    name: 'RADICCHIO',
    code: '13524',
    qualifier: '0',
  },
  {
    name: 'BROCCOFLOWER',
    code: '13525',
    qualifier: '0',
  },
  {
    name: 'GAI LON',
    code: '13903',
    qualifier: '0',
  },
  {
    name: 'CHINESE GREENS',
    code: '13999',
    qualifier: '0',
  },
  {
    name: 'CHINESE GREENS SEED',
    code: '13999',
    qualifier: '4',
  },
  {
    name: 'CELERIAC',
    code: '14004',
    qualifier: '0',
  },
  {
    name: 'CHIVE',
    code: '14005',
    qualifier: '0',
  },
  {
    name: 'CHIVE SEED',
    code: '14005',
    qualifier: '4',
  },
  {
    name: 'GARLIC',
    code: '14007',
    qualifier: '0',
  },
  {
    name: 'LEEK',
    code: '14010',
    qualifier: '0',
  },
  {
    name: 'ONION SEED',
    code: '14011',
    qualifier: '4',
  },
  {
    name: 'ONION, DRY',
    code: '14011',
    qualifier: '0',
  },
  {
    name: 'PARSNIP',
    code: '14012',
    qualifier: '0',
  },
  {
    name: 'PARSNIP SEED',
    code: '14012',
    qualifier: '4',
  },
  {
    name: 'POTATO',
    code: '14013',
    qualifier: '0',
  },
  {
    name: 'POTATO SEED',
    code: '14013',
    qualifier: '4',
  },
  {
    name: 'RADISH',
    code: '14014',
    qualifier: '0',
  },
  {
    name: 'RADISH SEED',
    code: '14014',
    qualifier: '4',
  },
  {
    name: 'RUTABAGA',
    code: '14015',
    qualifier: '0',
  },
  {
    name: 'SHALLOT',
    code: '14017',
    qualifier: '0',
  },
  {
    name: 'SWEET POTATO',
    code: '14018',
    qualifier: '0',
  },
  {
    name: 'YAM',
    code: '14021',
    qualifier: '0',
  },
  {
    name: 'BURDOCK (ROOT CROP)',
    code: '14022',
    qualifier: '0',
  },
  {
    name: 'DAIKON',
    code: '14023',
    qualifier: '0',
  },
  {
    name: 'JICAMA',
    code: '14024',
    qualifier: '0',
  },
  {
    name: 'JICAMA SEED',
    code: '14024',
    qualifier: '4',
  },
  {
    name: 'LOTUS ROOT',
    code: '14025',
    qualifier: '0',
  },
  {
    name: 'BEAN, DRIED',
    code: '15001',
    qualifier: '0',
  },
  {
    name: 'BEAN, DRIED SEED',
    code: '15001',
    qualifier: '4',
  },
  {
    name: 'BEAN SUC SEED',
    code: '15003',
    qualifier: '4',
  },
  {
    name: 'BEAN, LIMA',
    code: '15003',
    qualifier: '10',
  },
  {
    name: 'BEAN, SUCCULENT',
    code: '15003',
    qualifier: '0',
  },
  {
    name: 'SOYBEAN GRAIN',
    code: '15010',
    qualifier: '0',
  },
  {
    name: 'BEAN SPROUT',
    code: '15013',
    qualifier: '0',
  },
  {
    name: 'OKRA',
    code: '15015',
    qualifier: '0',
  },
  {
    name: 'ALFALFA SPROUT',
    code: '15021',
    qualifier: '0',
  },
  {
    name: 'SOYBEAN SPROUT',
    code: '15029',
    qualifier: '0',
  },
  {
    name: 'GARBANZO BEAN',
    code: '15032',
    qualifier: '0',
  },
  {
    name: 'ASPARAGUS',
    code: '16002',
    qualifier: '0',
  },
  {
    name: 'ASPARAGUS SEED',
    code: '16002',
    qualifier: '4',
  },
  {
    name: 'MUSHROOM',
    code: '16003',
    qualifier: '0',
  },
  {
    name: 'ONION, GREEN',
    code: '16004',
    qualifier: '0',
  },
  {
    name: 'ONION, GREEN SEED',
    code: '16004',
    qualifier: '4',
  },
  {
    name: 'FIBER CROP',
    code: '21000',
    qualifier: '0',
  },
  {
    name: 'RAMIE',
    code: '21005',
    qualifier: '0',
  },
  {
    name: 'FORAGE HAY/SILAGE',
    code: '22000',
    qualifier: '0',
  },
  {
    name: 'SORGHUM (FORAGE - FODDER)',
    code: '22004',
    qualifier: '0',
  },
  {
    name: 'CORN (FORAGE - FODDER)',
    code: '22005',
    qualifier: '0',
  },
  {
    name: 'OAT (FORAGE - FODDER)',
    code: '22006',
    qualifier: '0',
  },
  {
    name: 'WHEAT (FORAGE - FODDER)',
    code: '22007',
    qualifier: '0',
  },
  {
    name: 'BARLEY (FORAGE - FODDER)',
    code: '22008',
    qualifier: '0',
  },
  {
    name: 'SUDANGRASS',
    code: '22011',
    qualifier: '0',
  },
  {
    name: 'SUDANGRASS SEED',
    code: '22011',
    qualifier: '4',
  },
  {
    name: 'PASTURE (FORAGE - FODDER)',
    code: '22013',
    qualifier: '0',
  },
  {
    name: 'BERMUDAGRASS',
    code: '22017',
    qualifier: '0',
  },
  {
    name: 'BERMUDAGRASS SEED',
    code: '22017',
    qualifier: '4',
  },
  {
    name: 'LOVEGRASS (FORAGE - FODDER)',
    code: '22023',
    qualifier: '0',
  },
  {
    name: 'ORCHARDGRASS',
    code: '22028',
    qualifier: '0',
  },
  {
    name: 'TIMOTHY',
    code: '22029',
    qualifier: '0',
  },
  {
    name: 'RYEGRASS',
    code: '22035',
    qualifier: '0',
  },
  {
    name: 'LEGUME (FORAGE - FODDER)',
    code: '23000',
    qualifier: '0',
  },
  {
    name: 'ALFALFA',
    code: '23001',
    qualifier: '0',
  },
  {
    name: 'ALFALFA SEED',
    code: '23001',
    qualifier: '4',
  },
  {
    name: 'CLOVER',
    code: '23003',
    qualifier: '0',
  },
  {
    name: 'CLOVER SEED',
    code: '23003',
    qualifier: '4',
  },
  {
    name: 'COTTON (FORAGE - FODDER)',
    code: '23005',
    qualifier: '0',
  },
  {
    name: 'PEAS (FORAGE - FODDER)',
    code: '23008',
    qualifier: '0',
  },
  {
    name: 'SUGARBEET (FORAGE - FODDER)',
    code: '23009',
    qualifier: '0',
  },
  {
    name: 'VETCH',
    code: '23010',
    qualifier: '0',
  },
  {
    name: 'TREFOIL, BIRDSFOOT',
    code: '23011',
    qualifier: '0',
  },
  {
    name: 'CARROT (FORAGE - FODDER)',
    code: '23025',
    qualifier: '0',
  },
  {
    name: 'BEET (FORAGE - FODDER)',
    code: '23036',
    qualifier: '0',
  },
  {
    name: 'TURNIP (FORAGE - FODDER)',
    code: '23501',
    qualifier: '0',
  },
  {
    name: 'CORN, GRAIN',
    code: '24002',
    qualifier: '0',
  },
  {
    name: 'TRITICALE',
    code: '24011',
    qualifier: '0',
  },
  {
    name: 'WILD RICE',
    code: '24013',
    qualifier: '0',
  },
  {
    name: 'QUINOA',
    code: '24505',
    qualifier: '0',
  },
  {
    name: 'SUGARCANE',
    code: '25003',
    qualifier: '0',
  },
  {
    name: 'HONEY',
    code: '25501',
    qualifier: '0',
  },
  {
    name: 'TOBACCO',
    code: '26003',
    qualifier: '0',
  },
  {
    name: 'INDUSTRIAL HEMP',
    code: '26010',
    qualifier: '0',
  },
  {
    name: 'GUAYULE',
    code: '26015',
    qualifier: '0',
  },
  {
    name: 'CANNABIS (ALL OR UNSPECIFIED)',
    code: '26017',
    qualifier: '0',
  },
  {
    name: 'OILSEED',
    code: '27000',
    qualifier: '0',
  },
  {
    name: 'CASTORBEAN',
    code: '27001',
    qualifier: '0',
  },
  {
    name: 'CASTORBEAN SEED',
    code: '27001',
    qualifier: '4',
  },
  {
    name: 'COTTONSEED OIL',
    code: '27003',
    qualifier: '0',
  },
  {
    name: 'SOYBEAN OIL',
    code: '27010',
    qualifier: '0',
  },
  {
    name: 'RAPE OIL',
    code: '27017',
    qualifier: '0',
  },
  {
    name: 'JOJOBA BEAN',
    code: '27018',
    qualifier: '0',
  },
  {
    name: 'ALOE VERA',
    code: '27502',
    qualifier: '0',
  },
  {
    name: 'AVOCADO',
    code: '28000',
    qualifier: '0',
  },
  {
    name: 'BEAN, UNSPECIFIED',
    code: '28001',
    qualifier: '0',
  },
  {
    name: 'BEAN, UNSPECIFIED SEED',
    code: '28001',
    qualifier: '4',
  },
  {
    name: 'COCONUT',
    code: '28004',
    qualifier: '0',
  },
  {
    name: 'FENNEL',
    code: '28008',
    qualifier: '0',
  },
  {
    name: 'FLAX',
    code: '28009',
    qualifier: '0',
  },
  {
    name: 'FLAX SEED',
    code: '28009',
    qualifier: '4',
  },
  {
    name: 'HORSERADISH',
    code: '28011',
    qualifier: '0',
  },
  {
    name: 'HORSERADISH SEED',
    code: '28011',
    qualifier: '4',
  },
  {
    name: 'MINT',
    code: '28012',
    qualifier: '0',
  },
  {
    name: 'OLIVE',
    code: '28014',
    qualifier: '0',
  },
  {
    name: 'SESAME SEED',
    code: '28018',
    qualifier: '0',
  },
  {
    name: 'SOYBEAN',
    code: '28023',
    qualifier: '0',
  },
  {
    name: 'SOYBEAN SEED',
    code: '28023',
    qualifier: '4',
  },
  {
    name: 'VEGETABLE',
    code: '28024',
    qualifier: '0',
  },
  {
    name: 'VEGETABLE SEED',
    code: '28024',
    qualifier: '4',
  },
  {
    name: 'CHICORY',
    code: '28034',
    qualifier: '0',
  },
  {
    name: 'PASTURELAND',
    code: '28035',
    qualifier: '0',
  },
  {
    name: 'MILLET (ALL OR UNSPEC)',
    code: '28044',
    qualifier: '0',
  },
  {
    name: 'RANGELAND',
    code: '28045',
    qualifier: '0',
  },
  {
    name: 'TARO ROOT',
    code: '28047',
    qualifier: '0',
  },
  {
    name: 'BURDOCK',
    code: '28048',
    qualifier: '0',
  },
  {
    name: 'CANOLA (RAPE)',
    code: '28051',
    qualifier: '0',
  },
  {
    name: 'FAVA BEAN',
    code: '28059',
    qualifier: '0',
  },
  {
    name: 'VEGETABLE, ROOT',
    code: '28061',
    qualifier: '0',
  },
  {
    name: 'RYE',
    code: '28064',
    qualifier: '0',
  },
  {
    name: 'RYE SEED',
    code: '28064',
    qualifier: '4',
  },
  {
    name: 'GRASS SEED',
    code: '28066',
    qualifier: '0',
  },
  {
    name: 'ALFALFA-GRASS MIXTURE',
    code: '28069',
    qualifier: '0',
  },
  {
    name: 'RICE',
    code: '28072',
    qualifier: '0',
  },
  {
    name: 'GRAIN',
    code: '28078',
    qualifier: '0',
  },
  {
    name: 'PEPPERMINT',
    code: '28080',
    qualifier: '0',
  },
  {
    name: 'SALSIFY',
    code: '28504',
    qualifier: '0',
  },
  {
    name: 'ORCHARD FLOOR',
    code: '28509',
    qualifier: '94',
  },
  {
    name: 'EDIBLE FLOWER',
    code: '29008',
    qualifier: '0',
  },
  {
    name: 'BARLEY',
    code: '29103',
    qualifier: '0',
  },
  {
    name: 'BARLEY SEED',
    code: '29103',
    qualifier: '4',
  },
  {
    name: 'BEET',
    code: '29109',
    qualifier: '0',
  },
  {
    name: 'BEET SEED',
    code: '29109',
    qualifier: '4',
  },
  {
    name: 'CARROT',
    code: '29111',
    qualifier: '0',
  },
  {
    name: 'CARROT PROC',
    code: '29111',
    qualifier: '89',
  },
  {
    name: 'CARROT SEED',
    code: '29111',
    qualifier: '4',
  },
  {
    name: 'CELERY',
    code: '29113',
    qualifier: '0',
  },
  {
    name: 'CELERY SEED',
    code: '29113',
    qualifier: '4',
  },
  {
    name: 'CORN, HUMAN CONSUMPTION',
    code: '29119',
    qualifier: '0',
  },
  {
    name: 'CORN, HUMAN CONSUMPTION SEED',
    code: '29119',
    qualifier: '4',
  },
  {
    name: 'CORN, SWEET',
    code: '29119',
    qualifier: '1',
  },
  {
    name: 'COTTON',
    code: '29121',
    qualifier: '0',
  },
  {
    name: 'COTTON PIMA',
    code: '29121',
    qualifier: '10',
  },
  {
    name: 'COTTON SEED',
    code: '29121',
    qualifier: '4',
  },
  {
    name: 'CASABA MELON',
    code: '29122',
    qualifier: '10',
  },
  {
    name: 'CRENSHAW MELON',
    code: '29122',
    qualifier: '11',
  },
  {
    name: 'HONEYBALL MELON',
    code: '29122',
    qualifier: '12',
  },
  {
    name: 'HONEYDEW MELON',
    code: '29122',
    qualifier: '13',
  },
  {
    name: 'MELON',
    code: '29122',
    qualifier: '0',
  },
  {
    name: 'MELON SEED',
    code: '29122',
    qualifier: '4',
  },
  {
    name: 'MUSKMELON',
    code: '29122',
    qualifier: '14',
  },
  {
    name: 'PERSIAN MELON',
    code: '29122',
    qualifier: '15',
  },
  {
    name: 'SANTA CLARA MELON',
    code: '29122',
    qualifier: '16',
  },
  {
    name: 'SHARLYN MELON',
    code: '29122',
    qualifier: '17',
  },
  {
    name: 'MUSTARD',
    code: '29123',
    qualifier: '0',
  },
  {
    name: 'OAT',
    code: '29125',
    qualifier: '0',
  },
  {
    name: 'OAT SEED',
    code: '29125',
    qualifier: '4',
  },
  {
    name: 'PEANUT',
    code: '29126',
    qualifier: '0',
  },
  {
    name: 'PEAS',
    code: '29127',
    qualifier: '0',
  },
  {
    name: 'PEAS PROCESSING',
    code: '29127',
    qualifier: '89',
  },
  {
    name: 'PEAS SEED',
    code: '29127',
    qualifier: '4',
  },
  {
    name: 'SAFFLOWER',
    code: '29129',
    qualifier: '0',
  },
  {
    name: 'SAFFLOWER SEED',
    code: '29129',
    qualifier: '4',
  },
  {
    name: 'SORGHUM MILO',
    code: '29131',
    qualifier: '0',
  },
  {
    name: 'SORGHUM SEED',
    code: '29131',
    qualifier: '4',
  },
  {
    name: 'SUNFLOWER',
    code: '29133',
    qualifier: '0',
  },
  {
    name: 'SUNFLOWER SEED',
    code: '29133',
    qualifier: '4',
  },
  {
    name: 'SUGARBEET',
    code: '29135',
    qualifier: '0',
  },
  {
    name: 'TOMATO, PROCESSING',
    code: '29136',
    qualifier: '0',
  },
  {
    name: 'TURNIP',
    code: '29137',
    qualifier: '0',
  },
  {
    name: 'TURNIP SEED',
    code: '29137',
    qualifier: '4',
  },
  {
    name: 'WHEAT',
    code: '29139',
    qualifier: '0',
  },
  {
    name: 'WHEAT SEED',
    code: '29139',
    qualifier: '4',
  },
  {
    name: 'GRAPE',
    code: '29141',
    qualifier: '0',
  },
  {
    name: 'GRAPE, RAISIN',
    code: '29141',
    qualifier: '89',
  },
  {
    name: 'GRAPE, WINE',
    code: '29143',
    qualifier: '0',
  },
  {
    name: 'FOREST, TIMBERLAND',
    code: '30000',
    qualifier: '0',
  },
  {
    name: 'CHRISTMAS TREE',
    code: '30005',
    qualifier: '0',
  },
  {
    name: 'SOD FARM (TURF)',
    code: '33002',
    qualifier: '0',
  },
  {
    name: 'GOLF COURSE TURF',
    code: '33007',
    qualifier: '0',
  },
  {
    name: 'TURF/SOD',
    code: '33008',
    qualifier: '0',
  },
  {
    name: 'LOVEGRASS, WEEPING',
    code: '33054',
    qualifier: '0',
  },
  {
    name: 'POPLAR (POPULUS SPP.)',
    code: '35101',
    qualifier: '0',
  },
  {
    name: 'SOIL FUMIGATION/PREPLANT',
    code: '40008',
    qualifier: '0',
  },
  {
    name: 'MUSHROOM SOIL',
    code: '40011',
    qualifier: '0',
  },
  {
    name: 'NURSERY SOIL',
    code: '40501',
    qualifier: '0',
  },
  {
    name: 'DRIED FRUIT',
    code: '43026',
    qualifier: '0',
  },
  {
    name: 'STORAGE AREA/BOX',
    code: '46000',
    qualifier: '0',
  },
  {
    name: 'GRANARY',
    code: '46502',
    qualifier: '0',
  },
  {
    name: 'LIVESTOCK',
    code: '52000',
    qualifier: '0',
  },
  {
    name: 'CATTLE',
    code: '52003',
    qualifier: '0',
  },
  {
    name: 'SHEEP, LAMB',
    code: '52013',
    qualifier: '0',
  },
  {
    name: 'PIG',
    code: '52018',
    qualifier: '0',
  },
  {
    name: 'POULTRY',
    code: '55000',
    qualifier: '0',
  },
  {
    name: 'CHICKEN',
    code: '55001',
    qualifier: '0',
  },
  {
    name: 'DUCK',
    code: '55002',
    qualifier: '0',
  },
  {
    name: 'TURKEY',
    code: '55008',
    qualifier: '0',
  },
  {
    name: 'EGG',
    code: '55501',
    qualifier: '0',
  },
  {
    name: 'HORSE',
    code: '56005',
    qualifier: '0',
  },
  {
    name: 'DAIRY EQUIPMENT',
    code: '60000',
    qualifier: '0',
  },
  {
    name: 'FARM/AG BUILDING',
    code: '61000',
    qualifier: '0',
  },
  {
    name: 'ANIMAL PREMISE',
    code: '61001',
    qualifier: '0',
  },
  {
    name: 'GREENHOUSE FUMIGATION',
    code: '61006',
    qualifier: '0',
  },
  {
    name: 'MUSHROOM HOUSE',
    code: '61007',
    qualifier: '0',
  },
  {
    name: 'BEEHIVE',
    code: '61008',
    qualifier: '0',
  },
  {
    name: 'HOUSEHOLD',
    code: '63000',
    qualifier: '0',
  },
  {
    name: 'LUMBER, TREATED',
    code: '64500',
    qualifier: '0',
  },
  {
    name: 'WATER AREA',
    code: '65000',
    qualifier: '0',
  },
  {
    name: 'SWIMMING POOL',
    code: '65011',
    qualifier: '0',
  },
  {
    name: 'INDUST. WASTE DISPOSAL SYS, WATER',
    code: '65012',
    qualifier: '0',
  },
  {
    name: 'ANIMAL DRINKING WATER',
    code: '65014',
    qualifier: '0',
  },
  {
    name: 'WATER SYSTEMS - HUMAN POTABLE/DRINKING',
    code: '65015',
    qualifier: '0',
  },
  {
    name: 'DITCH BANK',
    code: '65021',
    qualifier: '0',
  },
  {
    name: 'SEWAGE SYSTEM',
    code: '65026',
    qualifier: '0',
  },
  {
    name: 'INDUSTRIAL PROCESSING WATER',
    code: '65029',
    qualifier: '0',
  },
  {
    name: 'WATER (INDUSTRIAL)',
    code: '65503',
    qualifier: '0',
  },
  {
    name: 'WATER WASHER /COOLER / CONDENSER SYSTEMS',
    code: '65505',
    qualifier: '0',
  },
  {
    name: 'WATER FILTER & FILTRATION SYSTEMS',
    code: '65510',
    qualifier: '0',
  },
  {
    name: 'UNCULTIVATED AG',
    code: '66000',
    qualifier: '0',
  },
  {
    name: 'UNCULTIVATED NON-AG',
    code: '67000',
    qualifier: '0',
  },
  {
    name: 'AIRPORT',
    code: '67001',
    qualifier: '0',
  },
  {
    name: 'RECREATION AREA',
    code: '67002',
    qualifier: '0',
  },
  {
    name: 'BUILDINGS/NON-AG OUTDOOR',
    code: '67003',
    qualifier: '0',
  },
  {
    name: 'INDUSTRIAL SITES',
    code: '67009',
    qualifier: '0',
  },
  {
    name: 'RESIDENTIAL',
    code: '68002',
    qualifier: '0',
  },
  {
    name: 'ANIMAL BURROWS',
    code: '68010',
    qualifier: '0',
  },
  {
    name: 'BOAT/PIER',
    code: '69000',
    qualifier: '0',
  },
  {
    name: 'FOOD PROCRESSING PLANT',
    code: '71000',
    qualifier: '0',
  },
  {
    name: 'RESTAURANT',
    code: '72000',
    qualifier: '0',
  },
  {
    name: 'HOSPITAL',
    code: '74000',
    qualifier: '0',
  },
  {
    name: 'COMMERCIAL, INSTITUTIONAL OR INDUSTRIAL AREAS',
    code: '77000',
    qualifier: '0',
  },
  {
    name: 'COMMERCIAL STORAGES OR WAREHOUSES',
    code: '77004',
    qualifier: '0',
  },
  {
    name: 'IRRIGATION LINE (MAINTENANCE)',
    code: '99505',
    qualifier: '0',
  },
  {
    name: 'UNDECLARED COMMODITY',
    code: '99999',
    qualifier: '0',
  },
];

module.exports.findCommodityByCodeAndQualifier = (code, qualifier) => {
  return (
    commoditiesList.find((commodity) => commodity.code === code && commodity.qualifier === qualifier) || {
      name: 'UNKNOWN COMMODITY',
      code: '-1',
      qualifier: '0',
    }
  );
};
