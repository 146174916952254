import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Icon,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { DateHelper } from '@rss/common';
import { mapValues } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ButtonProgress } from '../../components';
import { printPDFFromHTML } from '../../shared/printable.helper';
import { applyFilters, FILTERS } from '../helper/preview.helper';
import PreviewReport from './report/PreviewReport';

const PRINT_SETTINGS = [
  { key: FILTERS.PRIVATE_COMMENTS, label: 'Include Private Comments' },
  { key: FILTERS.NAMES, label: 'Include Names' },
  { key: FILTERS.COMPLIANT_INCIDENTS, label: 'Hide Compliant Incidents' },
  { key: FILTERS.THUMBNAILS, label: 'Include Thumbnails' },
  { key: FILTERS.RESOLUTIONS, label: 'Show Resolutions' },
];
function PrintPreviewDialog({ inspectors, questionnaire, report, responsiblePeople, resolutions, subject, onClose }) {
  const [filters, setFilters] = useState({
    ...mapValues(questionnaire.settings.print, (setting) => (setting === null ? true : setting)),
    header: undefined,
  });
  const [title, setTitle] = useState(questionnaire.settings.print.header || '');
  const [isDownloading, setIsDownloading] = useState(false);
  const [printNode, setPrintNode] = useState(null);

  const filteredResults = applyFilters(report, questionnaire, resolutions, filters);
  return (
    <Dialog open maxWidth="lg" fullWidth scroll="paper">
      <AppBar position="static" className="rounded-t-2xl">
        <Toolbar className="flex w-full justify-between">
          <Typography variant="h1" color="inherit" className="text-20 font-medium">
            Print Preview
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent classes={{ root: 'p-16 sm:p-24' }}>
        {!questionnaire.settings.print.header && (
          <div className="flex w-3/5 pb-16">
            <TextField
              variant="outlined"
              fullWidth
              label="Title"
              placeholder="Title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </div>
        )}

        <div className="grid grid-cols-3 gap-4 pb-16">
          {PRINT_SETTINGS.filter((setting) => questionnaire.settings.print[setting.key] === null).map((setting) => (
            <FormControlLabel
              key={setting.key}
              className="justify-end"
              labelPlacement="start"
              label={setting.label}
              control={
                <Switch
                  disabled={isDownloading || (setting.key === FILTERS.RESOLUTIONS && !resolutions)}
                  checked={filters[setting.key]}
                  onChange={(event) => setFilters({ ...filters, [setting.key]: event.target.checked })}
                />
              }
            />
          ))}
        </div>

        <div className="border-1 border-solid border-gray-600">
          <PreviewReport
            ref={(node) => setPrintNode(node)}
            expandQuestions
            inspectors={filters[FILTERS.NAMES] ? inspectors : []}
            responsiblePeople={filters[FILTERS.NAMES] ? responsiblePeople : []}
            subject={subject}
            report={filteredResults.report}
            resolutions={filteredResults.resolutions}
            questionnaire={filteredResults.questionnaire}
            title={questionnaire.settings.print.header || title}
          />
        </div>
      </DialogContent>
      <DialogActions className="flex-end p-16">
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
        <ButtonProgress
          disabled={!printNode?.outerHTML}
          id="download-pdf"
          color="primary"
          loading={isDownloading}
          startIcon={!isDownloading && <Icon className="text-white">print</Icon>}
          variant="contained"
          onClick={async () => {
            const filename = `${questionnaire.name} - ${subject.name} ${DateHelper.formatDate(new Date())}.pdf`;
            setIsDownloading(true);

            const detailsWC = printNode.querySelector('subject-details');
            if (detailsWC) {
              const div = document.createElement('div');
              div.innerHTML = detailsWC.shadowRoot.innerHTML;
              detailsWC.replaceWith(div);
            }

            await printPDFFromHTML(printNode?.outerHTML, { filename });

            onClose();
          }}>
          Download PDF
        </ButtonProgress>
      </DialogActions>
    </Dialog>
  );
}

PrintPreviewDialog.propTypes = {
  inspectors: PropTypes.arrayOf(PropTypes.any).isRequired,
  questionnaire: PropTypes.objectOf(PropTypes.any).isRequired,
  report: PropTypes.objectOf(PropTypes.any).isRequired,
  responsiblePeople: PropTypes.arrayOf(PropTypes.any).isRequired,
  resolutions: PropTypes.arrayOf(PropTypes.any),
  subject: PropTypes.objectOf(PropTypes.any).isRequired,
  onClose: PropTypes.func.isRequired,
};

PrintPreviewDialog.defaultProps = {
  resolutions: [],
};

export default PrintPreviewDialog;
