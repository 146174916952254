const yup = require('yup');
const RefundedItems = require('./refundedItem');
class Refund {
  constructor(args) {
    Object.assign(this, Refund.schema().cast(args, {
      stripUnknown: true
    }));
  }
  validate() {
    return Refund.schema().validateSync(this, {
      abortEarly: false
    });
  }
  static schema() {
    return yup.object({
      createdDate: yup.date().default(new Date()).required(),
      createdBy: yup.object({
        userId: yup.string().default(null).required(),
        firstName: yup.string().default(null).nullable(),
        lastName: yup.string().default(null).nullable(),
        email: yup.string().default(null).nullable()
      }),
      refundedItems: yup.array().default([]).of(RefundedItems.schema()).required(),
      refundTotal: yup.number().default(0).required()
    });
  }
}
module.exports = Refund;