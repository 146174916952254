import { useApolloClient, useQuery } from '@apollo/client';
import { RELATIONSHIP_LABEL } from '@rss/common';

import { PROFILE_QUERY } from '../graphql/profile.query';

const {
  NODE: {
    BUA_CONFIG,
    ASSESSMENT_TEMPLATE,
    FLEX_CONFIG,
    INCIDENT_QUESTIONNAIRE,
    LAB_HAZARD_CONFIG,
    PESTICIDE_CONFIG,
    PPE_MANAGEMENT,
    QUESTIONNAIRE_SERIES,
    RESPIRATOR_CONFIG,
    STORE,
    WORKSTRONG_FEATURE,
    FITTEST_TEMPLATE,
  },
} = RELATIONSHIP_LABEL;

export default function useProfile({ skip } = { skip: false }) {
  const apolloClient = useApolloClient();

  const { loading, error, data, refetch } = useQuery(PROFILE_QUERY, {
    variables: {
      // FIXME: Remove and fetch all features once RSS mobile specifies just the features it needs
      programFeatures: [
        INCIDENT_QUESTIONNAIRE,
        QUESTIONNAIRE_SERIES,
        STORE,
        FLEX_CONFIG,
        LAB_HAZARD_CONFIG,
        BUA_CONFIG,
        PESTICIDE_CONFIG,
        PPE_MANAGEMENT,
        RESPIRATOR_CONFIG,
        WORKSTRONG_FEATURE,
        ASSESSMENT_TEMPLATE,
        FITTEST_TEMPLATE,
      ],
    },
    skip,
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading,
    error,
    profile: data?.profile,
    rolePermissions: data?.rolePermissions,
    programs: data?.findProgramsByUser,
    centersOfExcellence: data?.findCentersOfExcellenceByUser,
    refetch: () => {
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'profile' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'findProgramsByUser' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'findCentersOfExcellenceByUser' });
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'rolePermissions' });
      refetch();
    },
  };
}
