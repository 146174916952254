import gql from 'graphql-tag';

export const TENANTS_QUERY = gql`
  query getTenants {
    tenants {
      tenantCode
      name
      referrerUrl
      campusList {
        campusCode
        name
        shortName
        displayName
        institution
        idp
        idps
        uri
        idleTimeoutInMinutes
        idleTimeoutWarningInMinutes
        mobileWebView
        dataSourceDescription {
          link
          text
        }
        isHc
        isTrainingCampus
        domains {
          id
          name
          type
        }
      }
    }
  }
`;
