/* eslint-disable max-len */
import { RELATIONSHIP_LABEL } from '@rss/common';

import { BucketTemplate } from './bucket.fq.template';
import { FacilityTemplate } from './facility.fq.template';
import { ApplicatorGroupTemplate } from './applicator-group.fq.template';

const {
  EDGE: {
    ASSESSMENT_ADMIN,
    ASSESSMENT_READ_ONLY,
    BIOSAFETY_ADMIN,
    BIO_HAZARD_CONTACT,
    CLAIMS_ANALYST,
    CLAIMS_ANALYST_READ_ONLY,
    CONFIGURATION_MANAGER,
    CHEMICAL_HAZARD_CONTACT,
    EQUIPMENT_FAILURE_CONTACT,
    RADIATION_HAZARD_CONTACT,
    DELEGATE,
    DISTRICT_ADMIN,
    FLEX_EVALUATOR,
    FLEX_CONSULTANT,
    INSPECTOR,
    INSPECT_ADMIN,
    INVENTORY_MANAGER,
    INVESTIGATOR,
    IPM_COORDINATOR,
    IPMC_MEMBER,
    IBC_MEMBER,
    LAB_HAZARD_ADMIN,
    LHA_READ_ONLY,
    MEMBER,
    MEDICAL_ADMIN,
    NURSE_MANAGER,
    OWNER,
    PPE_COORDINATOR,
    PROGRAM_ADMIN,
    READ_ONLY,
    RESPIRATOR_ADMIN,
    RESPONSIBLE_PERSON,
    SAFETY_COORDINATOR,
    SERVICE_DESK,
    STORE_MANAGER,
    WAIVER_ADMIN,
    WAIVER_OBSERVER,
    WORKSTRONG_COORDINATOR,
    FIT_TESTER,
    RESFIT_ADMIN,
  },
  NODE: {
    BUCKET,
    CAMPUS,
    COLLECTION,
    DOMAIN,
    FLOOR,
    GROUP,
    APPLICATOR_GROUP,
    ROOM,
    ROUTING_LIST,
    ROUTING_GROUP,
    FITTEST_TEMPLATE,
  },
} = RELATIONSHIP_LABEL;

export const profileConfig = {
  COLLECTION: {
    DESCRIPTION: 'Collections allow safety coordinators to view groups and documents within the collection.',
    ADMIN_CREATE_DESCRIPTION:
      'Collections allow safety coordinators to view groups and documents within the collection.',
    SAFETY_COORDINATOR: 'Safety Coordinators will have view access to your group and documents.',
    GROUP: {
      ADD: 'Groups added to collections will be viewable by the Safety Coordinator associated with the collection.',
      REMOVE:
        'Safety Coordinators for this collection will no longer be able to view information regarding following group(s) once removed from the collection.',
    },
    roles: [],
    defaultRole: SAFETY_COORDINATOR,
    includeUnderAccountManagement: true,
    includeUnderRSSTools: false,
    rssToolAccess: [],
    createAccess: false,
    locationType: null,
    relatedOrgType: GROUP,
    memberAlias: 'Safety Coordinator',
    scope: CAMPUS,
  },
  DEPARTMENT: {
    createAccess: false,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
    scope: CAMPUS,
  },
  GROUP: {
    canAdminCreateForUser: true,
    createAccess: true,
    DESCRIPTION: 'Use groups to manage permissions and access to documents and inventories.',
    CREATE_DESCRIPTION:
      'Only Principal Investigators and Responsible Persons should create groups. People added to the group can have access to documents linked to the group.',
    ADMIN_CREATE_DESCRIPTION: 'Create a group on behalf of someone by searching for them and entering a group name.',
    ROLE_DESCRIPTION:
      'The user’s group role will be the default global role for all documents and inventories within a group.',
    CUSTOM_ROLES_DESCRIPTION: 'Use custom roles to set user permissions for each document and inventory.',
    DELEGATE: 'Can add/edit/remove group members and locations.',
    MEMBER: 'Can view group members and locations.',
    OWNER: 'Can manage group',
    roles: [DELEGATE],
    defaultRole: MEMBER,
    includeUnderAccountManagement: true,
    includeUnderRSSTools: true,
    rssToolAccess: [SERVICE_DESK],
    locationType: ROOM,
    relatedOrgType: COLLECTION,
    memberAlias: 'Member',
    hideMetaData: true,
    appList: [
      { code: 'assessment', displayName: 'Assessment' },
      { code: 'chemicals', displayName: 'Chemicals' },
      { code: 'inspect', displayName: 'Inspect' },
      { code: 'radiation', displayName: 'Radiation' },
      { code: 'waste', displayName: 'Waste' },
    ],
    enableTransfer: true,
    enableDocumentsTransfer: true,
    showTrainingLink: true,
    excludeRolesToTransfer: [OWNER],
    excludeTags: true,
    scope: CAMPUS,
  },
  [APPLICATOR_GROUP]: {
    detailsPage: true,
    reportsPage: true,
    detailsForm: [...ApplicatorGroupTemplate],
    canAdminCreateForUser: true,
    createAccess: false,
    DESCRIPTION: 'Use applicator groups to manage permissions and access to documents.',
    CREATE_DESCRIPTION:
      'Only Owner should create applicator groups. People added to the group can have access to documents linked to the group.',
    ADMIN_CREATE_DESCRIPTION:
      'Create a new Applicator Group by entering an Applicator Group Name and searching for the Applicator Group Owner.',
    ROLE_DESCRIPTION:
      'The user’s applicator group role will be the default global role for all documents within a group.',
    CUSTOM_ROLES_DESCRIPTION: 'Use custom roles to set user permissions for each document.',
    DELEGATE: 'Can view the Applicator Group, add/remove Applicator Group Members, and manage Pesticide Use Requests.',
    MEMBER: 'Can view the Applicator Group, edit Pesticide Use Requests, but cannot submit Pesticide Use Requests.',
    OWNER: null,
    roles: [DELEGATE],
    defaultRole: MEMBER,
    includeUnderAccountManagement: true,
    includeUnderRSSTools: true,
    rssToolAccess: [SERVICE_DESK],
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
    hideMetaData: true,
    appList: [{ code: 'assessment', displayName: 'Assessment' }],
    enableTransfer: true,
    hideInProfileIfNoData: true,
    showTrainingLink: false,
    excludeRolesToTransfer: [OWNER],
    scope: CAMPUS,
    enableAssignDocumentAccess: false,
  },
  PROGRAM: {
    icon: 'business',
    DESCRIPTION: 'Can view, manage programs and perform inspection.',
    ADMIN_CREATE_DESCRIPTION: 'Programs are used to manage permissions and access to features in RSS platform.',
    ROLE_DESCRIPTION: 'The user’s program role will be the default global role for all features within a program.',
    CUSTOM_ROLES_DESCRIPTION: 'Use custom roles to set user permissions for each feature.',
    roles: [PROGRAM_ADMIN],
    defaultRole: MEMBER,
    PROGRAM_ADMIN: 'Can manage program members and permissions.',
    MEMBER: 'Can view program details.',
    includeUnderAccountManagement: true,
    includeUnderRSSTools: true,
    rssToolAccess: [CONFIGURATION_MANAGER],
    createAccess: false,
    enableTransfer: false,
    hideInProfileIfNoData: true,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
    documentAlias: 'Feature',
    documentIcon: 'list_alt',
    scope: DOMAIN,
  },
  [ROUTING_LIST]: {
    icon: 'list',
    DESCRIPTION: '',
    includeUnderAccountManagement: true,
    includeUnderRSSTools: true,
    rssToolAccess: [CONFIGURATION_MANAGER],
    createAccess: true,
    enableTransfer: false,
    hideInProfileIfNoData: true,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
    scope: DOMAIN,
  },
  [ROUTING_GROUP]: {
    icon: 'list',
    defaultRole: MEMBER,
    MEMBER: '',
    DESCRIPTION: '',
    includeUnderAccountManagement: false,
    includeUnderRSSTools: false,
    rssToolAccess: [CONFIGURATION_MANAGER],
    createAccess: true,
    enableTransfer: false,
    hideInProfileIfNoData: true,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
  },
  QUESTIONNAIRE_SERIES: {
    INSPECTOR: 'Can perform inspections.',
    INSPECT_ADMIN: 'Can manage questionnaire.',
    roles: [INSPECT_ADMIN],
    defaultRole: INSPECTOR,
  },
  INCIDENT_QUESTIONNAIRE: {
    CLAIMS_ANALYST: 'Can review and submit incident claims.',
    CLAIMS_ANALYST_READ_ONLY: 'Can view incident details.',
    CHEMICAL_HAZARD_CONTACT: 'Staff responsible for chemical hazard follow up can view Incident Details.',
    EQUIPMENT_FAILURE_CONTACT: 'Staff responsible for equipment failure follow up can view Incident Details.',
    BIO_HAZARD_CONTACT: 'Staff responsible for bio hazard follow up can view Incident Details.',
    RADIATION_HAZARD_CONTACT: 'Staff responsible for radiation hazard follow up can view Incident Details.',
    roles: [
      CLAIMS_ANALYST,
      CLAIMS_ANALYST_READ_ONLY,
      CHEMICAL_HAZARD_CONTACT,
      EQUIPMENT_FAILURE_CONTACT,
      BIO_HAZARD_CONTACT,
      RADIATION_HAZARD_CONTACT,
    ],
    defaultRole: MEMBER,
  },
  WORKSTRONG_FEATURE: {
    WORKSTRONG_COORDINATOR: 'Can review and approve WorkStrong referrals.',
    roles: [WORKSTRONG_COORDINATOR],
    defaultRole: MEMBER,
  },
  FACILITY: {
    icon: 'business',
    detailsPage: true,
    detailsForm: [...FacilityTemplate],
    DESCRIPTION: 'Facilities are used to manage permissions.',
    roles: [RESPONSIBLE_PERSON],
    defaultRole: MEMBER,
    RESPONSIBLE_PERSON: 'Responsible for resolving incidents.',
    MEMBER: 'Can view facility members.',
    memberAlias: 'Member',
    includeUnderAccountManagement: true,
    includeUnderRSSTools: true,
    rssToolAccess: [CONFIGURATION_MANAGER],
    createAccess: false,
    hideInProfileIfNoData: true,
    hideMetaData: true,
    scope: CAMPUS,
  },
  DISTRICT: {
    icon: 'business',
    DESCRIPTION: 'Districts are used to manage permissions.',
    roles: [],
    defaultRole: DISTRICT_ADMIN,
    DISTRICT_ADMIN: 'Can manage facilities.',
    memberAlias: 'District Admin',
    includeUnderAccountManagement: true,
    createAccess: false,
    hideInProfileIfNoData: true,
    hideMetaData: true,
    scope: CAMPUS,
  },
  UNIT: {
    DESCRIPTION: 'Units are used to manage permissions and access to relevant documents and inventories.',
    ADMIN_CREATE_DESCRIPTION: 'Units are used to manage permissions and access to relevant documents and inventories.',
    ROLE_DESCRIPTION:
      'The user’s unit role will be the default global role for all documents and inventories within a unit.',
    NURSE_MANAGER: 'Perform assessments, resolve survey/round findings, and completing plans',
    MEMBER: 'Can view unit details',
    roles: [NURSE_MANAGER],
    defaultRole: MEMBER,
    includeUnderAccountManagement: true,
    includeUnderRSSTools: false,
    rssToolAccess: [],
    createAccess: false,
    locationType: FLOOR,
    relatedOrgType: null,
    memberAlias: 'Member',
    scope: CAMPUS,
  },
  LAB_HAZARD: {
    MEMBER: 'Can view Lab Hazard Assessment.',
    DELEGATE: 'Can edit Lab Hazard Assessment.',
    OWNER: 'Owner of the Lab Hazard Assessment can view, edit and submit hazards stated in the assessment.',
    roles: [], // Managed by assessment
    shouldTakeOrgRole: true,
  },
  UNIT_RISK: {
    OWNER: 'Owners of the Unit Risk Assessment can view, edit and submit hazards stated in the assessment.',
    roles: [OWNER],
  },
  BUA: {
    MEMBER: 'Can view BUA.',
    DELEGATE: 'Can edit BUA.',
    OWNER: 'Owner of the BUA can view, edit and submit the assessment.',
    roles: [], // Managed by assessment
    shouldTakeOrgRole: true,
  },
  PESTICIDE_USE: {
    MEMBER: 'Can view Pesticide Application Plan.',
    OWNER: 'Owner of the Pesticide Application Plan can view, edit and submit the application plan.',
    roles: [], // Managed by assessment
    shouldTakeOrgRole: true,
  },
  [BUCKET]: {
    icon: 'business',
    DESCRIPTION:
      'View and manage Buckets for an organization. A Bucket is any grouping of people, locations, or things. Currently, only domain-level buckets with people can be added, edited, or viewed.',
    detailsPage: true,
    detailsForm: [...BucketTemplate],
    roles: [],
    defaultRole: MEMBER,
    includeUnderRSSTools: true,
    includeUnderAccountManagement: false,
    rssToolAccess: [CONFIGURATION_MANAGER],
    createAccess: false,
    enableTransfer: false,
    hideInProfileIfNoData: true,
    locationType: null,
    relatedOrgType: null,
    memberAlias: 'Member',
    scope: DOMAIN,
  },
  BUA_CONFIG: {
    defaultRole: MEMBER,
    roles: [IBC_MEMBER, BIOSAFETY_ADMIN],
  },
  LAB_HAZARD_CONFIG: {
    defaultRole: MEMBER,
    roles: [LAB_HAZARD_ADMIN, LHA_READ_ONLY],
  },
  PPE_MANAGEMENT: {
    defaultRole: MEMBER,
    roles: [PPE_COORDINATOR],
  },
  FLEX_CONFIG: {
    defaultRole: MEMBER,
    roles: [WAIVER_ADMIN, FLEX_EVALUATOR, FLEX_CONSULTANT, WAIVER_OBSERVER],
  },
  PESTICIDE_CONFIG: {
    defaultRole: MEMBER,
    roles: [IPM_COORDINATOR, IPMC_MEMBER],
  },
  RESPIRATOR_CONFIG: {
    defaultRole: MEMBER,
    roles: [RESPIRATOR_ADMIN, MEDICAL_ADMIN],
  },
  ASSESSMENT_TEMPLATE: {
    defaultRole: MEMBER,
    roles: [ASSESSMENT_ADMIN, ASSESSMENT_READ_ONLY],
  },
  INVENTORY: {
    MEMBER: 'Can use inventory.',
    INVENTORY_MANAGER: 'Can manage inventory.',
    OWNER: 'Owner of the Inventory can view, edit and submit the Inventory.',
    roles: [INVENTORY_MANAGER],
    defaultRole: MEMBER,
  },
  STORE: {
    MEMBER: 'Can add items to store inventory and process orders.',
    STORE_MANAGER: 'Can manage the store.',
    roles: [STORE_MANAGER],
    defaultRole: MEMBER,
  },
  FITTEST_TEMPLATE: {
    roles: [FIT_TESTER, RESFIT_ADMIN],
    defaultRole: MEMBER,
  },
};

export const orgTypes = Object.entries(profileConfig)
  .filter(([key, value]) => key !== ROUTING_LIST && value.includeUnderAccountManagement)
  .map(([key]) => key);

export const campusOrgTypes = Object.entries(profileConfig)
  .filter(([_, value]) => value.scope === CAMPUS && value.includeUnderAccountManagement)
  .map(([key]) => key);

export const domainOrgTypes = Object.entries(profileConfig)
  .filter(([_, value]) => value.scope === DOMAIN && value.includeUnderAccountManagement)
  .map(([key]) => key);

export const reminderConfig = {
  APPLICATION_NAME: 'PROFILE',
  GROUP: {
    LABEL_TYPE: 'NEW_GROUP',
    TITLE: 'Review new groups',
  },
};

export const rolesDescription = {
  BIOSAFETY_ADMIN: 'Can view and manage BUAs.',
  IBC_MEMBER: 'Can view and comment on BUAs.',
  LAB_HAZARD_ADMIN: 'Can view and manage Lab Hazard Assessments.',
  LHA_READ_ONLY: 'Can view Lab Hazard Assessments.',
  PPE_COORDINATOR: 'Can view and manage PPE inventories for your campus.',
  UNIT_RISK_ADMIN: 'Unit Risk Admins manage and assign Unit Risk Assessments.',
  UNIT_MANAGER: 'Unit Managers manage and assign Nurse Managers',
  IPM_COORDINATOR: 'Can view and review pesticide use requests.',
  IPMC_MEMBER: 'Can view pesticide use requests.',
  FLEX_EVALUATOR: 'Can review and comment Program Flexibility Applications and Waivers.',
  WAIVER_ADMIN: 'Can view, manage Program Flexibility Applications and Waivers.',
  FLEX_CONSULTANT: 'Can view and comment on Program Flexibility Applications.',
  WAIVER_OBSERVER: 'Can view Program Flexibility Applications and Waivers',
};

export const EXTERNAL_DESCRIPTION = 'To update your name and email address, contact your account manager.';

export const roleTypes = {
  INTERNAL: 'INTERNAL',
  INTERNAL_EXTERNAL: 'INTERNAL-EXTERNAL',
};
