import { CATALOG_TYPE } from '@risk-and-safety/library';
import { toUpper } from 'lodash';
import React, { lazy } from 'react';

const LibraryItemDetailsPage = lazy(() => import('./containers/LibraryItemDetailsPage'));
const NotFound = lazy(() => import('../components/errors/NotFound'));

const routes = {
  settings: {
    helpCategories: ['library'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/library/:catalog/:id',
      component: ({ match: { params } }) => {
        const catalog = toUpper(params.catalog);
        return CATALOG_TYPE[catalog] ? <LibraryItemDetailsPage readOnly /> : <NotFound />;
      },
    },
  ],
};

export default routes;
