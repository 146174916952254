/* eslint-disable import/no-cycle */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FqLabel from './FqLabel';
import FqQuestionFactory from './FqQuestionFactory';
import { getFqClassName, QUESTION_VARIANT } from './Helper';

const FqGroup = ({
  comments,
  canComment,
  canCommentPrivate,
  disabled,
  groupKey,
  label,
  labelVariant,
  variant,
  onComment,
  onDeleteComment,
  onChange,
  privateComments,
  questions,
  required,
  shouldValidate,
  value,
}) => {
  const [key, setKey] = useState({
    [groupKey]: value || {},
  });

  useEffect(() => {
    if (value || groupKey) {
      setKey({ [groupKey]: value });
    }
  }, [value, groupKey]);

  const handleOnChange = (val) => {
    onChange({ [groupKey]: { ...key[groupKey], ...val } });
  };

  return (
    <div className="flex w-full flex-wrap text-black">
      {label && (
        <div className="my-12 w-full text-sm font-normal">
          <FqLabel question={{ label, required }} variant={variant} />
        </div>
      )}

      {questions.map((question) => (
        <div key={`fq${question.key}`} className={getFqClassName(question, 'w-full')}>
          <FqQuestionFactory
            onChange={handleOnChange}
            disabled={disabled || question.disabled}
            question={question}
            questionKey={question.key}
            value={value ? value[question.key] : null}
            label={question.label}
            key={question.key}
            parentResponses={value}
            onComment={(comment, isPrivate) => onComment(comment, isPrivate)}
            onDeleteComment={onDeleteComment}
            comments={comments}
            privateComments={privateComments}
            canComment={canComment}
            canCommentPrivate={canCommentPrivate}
            shouldValidate={shouldValidate}
            labelVariant={labelVariant}
            variant={variant}
            required={required}
          />
        </div>
      ))}
    </div>
  );
};

FqGroup.propTypes = {
  comments: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  canComment: PropTypes.bool,
  canCommentPrivate: PropTypes.bool,
  disabled: PropTypes.bool,
  groupKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelVariant: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  onComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  privateComments: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  questions: PropTypes.arrayOf(PropTypes.any).isRequired,
  shouldValidate: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.bool]),
};

FqGroup.defaultProps = {
  disabled: false,
  comments: null,
  privateComments: null,
  canComment: false,
  canCommentPrivate: false,
  required: false,
  label: '',
  labelVariant: 'default',
  variant: QUESTION_VARIANT.STANDARD,
  onComment: () => null,
  onDeleteComment: () => null,
  shouldValidate: false,
  value: null,
};

export default FqGroup;
