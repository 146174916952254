import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

const initialCart = {
  items: [],
  account: null,
  fund: null,
  receivingType: null,
  deliveryLocation: null,
};

const shoppingCartSlice = createSlice({
  name: 'storeFeature/shoppingCart',
  initialState: {},
  reducers: {
    initializeCart: (state, { payload: { storeId } }) => {
      const cart = cloneDeep(state[storeId] || initialCart);
      return {
        ...state,
        [storeId]: cart,
      };
    },

    addToCart: (state, { payload: { storeId, product, quantity, salesTax } }) => {
      const items = cloneDeep(state[storeId]?.items || []);
      const match = items.find((item) => item._id === product._id);
      if (match) {
        match.quantity += quantity;
      } else {
        items.push({
          _id: product._id,
          name: product.name,
          price: product.price,
          quantity,
          taxExempt: product.taxExempt,
          salesTax,
          catalog: product.catalog,
        });
      }

      return {
        ...state,
        [storeId]: {
          ...state[storeId],
          items,
        },
      };
    },

    removeFromCart: (state, { payload: { storeId, productId } }) => {
      const items = cloneDeep(state[storeId].items || []);
      return {
        ...state,
        [storeId]: {
          ...state[storeId],
          items: items.filter((item) => item._id !== productId),
        },
      };
    },

    updateCart: (state, { payload: { storeId, account, fund, receivingType, deliveryLocation } }) => {
      const cart = cloneDeep(state[storeId]) || initialCart;
      if (account) {
        cart.account = account;
      }
      if (fund) {
        cart.fund = fund;
      }
      if (receivingType) {
        cart.receivingType = receivingType;
      }
      if (deliveryLocation) {
        cart.deliveryLocation = deliveryLocation;
      }

      return {
        ...state,
        [storeId]: {
          ...cart,
        },
      };
    },

    updateCartQuantity: (state, { payload: { storeId, productId, quantity } }) => {
      let items = cloneDeep(state[storeId].items || []);
      const match = items.find((item) => item._id === productId);
      if (match) {
        match.quantity = quantity;
      }

      if (quantity === 0) {
        items = items.filter((item) => item._id !== productId);
      }
      return {
        ...state,
        [storeId]: {
          ...state[storeId],
          items,
        },
      };
    },

    clearCart: (state, { payload: { storeId } }) => {
      return {
        ...state,
        [storeId]: {
          ...state[storeId],
          items: [],
        },
      };
    },

    purge: () => ({}),
  },
});

export const { initializeCart, addToCart, removeFromCart, updateCart, updateCartQuantity, clearCart, purge } =
  shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
