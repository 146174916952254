import { FormControl, makeStyles } from '@material-ui/core';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addYears, isValid } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { QUESTION_VARIANT } from './Helper';
import { getErrorMessage } from './FqDatePicker';

const useStyles = makeStyles({
  textField: {
    '& .MuiOutlinedInput-input': { fontSize: '1.4rem' },
    '& .MuiFormHelperText-root': { fontSize: '1.4rem', marginLeft: '0', letterSpacing: '0' },
  },
  popper: {
    '& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel, & .MuiPickersCalendarHeader-label, & .MuiPickersYear-yearButton':
      { fontSize: '1.4rem' },
  },
});

// FIXME: Should combine with FqDateTimePicker and dynamically determine the component
const defaultMinDate = addYears(new Date(), -120);
const defaultMaxDate = addYears(new Date(), 100);
const parseToDate = (value) => value && new Date(value);

const FqDateTimePicker = ({
  ampm,
  autoFocus,
  dateFormat,
  disableFuture,
  disablePast,
  disabled,
  fullWidth,
  label,
  labelVariant,
  maxDate,
  maxDateMessage,
  minDate,
  minDateMessage,
  onChange,
  propagateInvalid, // Pass invalid dates to onChange for validation outside of FQ
  questionKey,
  required,
  value,
  variant,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(null);

  const errorMessage = useMemo(getErrorMessage(error, maxDateMessage, maxDate, dateFormat, minDateMessage, minDate), [
    error,
  ]);

  const handleOnChange = (val) => {
    if (!val) {
      onChange(null, questionKey);
    } else if (isValid(val)) {
      onChange(val.toISOString(), questionKey);
    } else if (propagateInvalid) {
      onChange(val.toString(), questionKey);
    }
  };

  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      <FormControl fullWidth={fullWidth}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            ampm={ampm}
            autoFocus={autoFocus}
            data-testid={label}
            disableFuture={disableFuture}
            disablePast={disablePast}
            disabled={disabled}
            format={dateFormat}
            id={questionKey}
            label={labelVariant !== 'long' ? label : null}
            maxDate={parseToDate(maxDate) || defaultMaxDate}
            minDate={parseToDate(minDate) || defaultMinDate}
            onChange={handleOnChange}
            onError={setError}
            value={(value && new Date(value)) || null}
            slotProps={{
              textField: { required, helperText: propagateInvalid ? '' : errorMessage, className: classes.textField },
              openPickerButton: { sx: { marginRight: '4px' } },
              openPickerIcon: { sx: { fontSize: '2.4rem', color: 'black' } },
              popper: { className: classes.popper },
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};

FqDateTimePicker.propTypes = {
  ampm: PropTypes.bool,
  autoFocus: PropTypes.bool,
  dateFormat: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDateMessage: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  minDateMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  propagateInvalid: PropTypes.bool,
  questionKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FqDateTimePicker.defaultProps = {
  ampm: true,
  autoFocus: false,
  dateFormat: 'MM/dd/yyyy hh:mm a',
  disableFuture: false,
  disablePast: false,
  disabled: false,
  fullWidth: true,
  label: 'Select a date',
  labelVariant: 'default',
  maxDate: defaultMaxDate,
  maxDateMessage: null,
  minDate: defaultMinDate,
  minDateMessage: null,
  propagateInvalid: false,
  required: false,
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
};

export default FqDateTimePicker;
