import { FqType, US_STATES } from '@rss/common';

export const ApplicatorGroupTemplate = [
  {
    type: FqType.PERMIT_SEARCH,
    key: 'permit',
    label: 'Enter Permit Number',
    required: true,
    validationProps: {
      requiredMessage: 'Permit Number is a required field.',
    },
    questions: [
      {
        type: FqType.LABEL,
        key: 'operator-details',
        label: '### **Operator Details**',
        info: 'Operator/Permit details are provided by CalAgPermits.',
      },
      {
        type: FqType.INPUT,
        key: 'operatorId',
        label: 'Operator ID / Permit number',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.INPUT,
        key: 'operator',
        label: 'Operator (firm name)',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.INPUT,
        key: 'effectiveDate',
        label: 'Effective Date',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.INPUT,
        key: 'expirationDate',
        label: 'Expiration Date',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.LABEL,
        key: 'operator-county',
        label: '### **County Details**',
      },
      {
        type: FqType.INPUT,
        key: 'county',
        label: 'County name',
        required: false,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.INPUT,
        key: 'countyNumber',
        label: 'County number (pick county name first)',
        required: false,
        disabled: true,
        description: 'Select county first, county number populate automatically.',
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.LABEL,
        key: 'operator-address',
        label: '### **Operator Address**',
      },
      {
        type: FqType.INPUT,
        key: 'address',
        label: 'Address',
        required: true,
        disabled: true,
        className: 'w-full',
      },
      {
        type: FqType.INPUT,
        key: 'city',
        label: 'City',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.DROPDOWN,
        key: 'state',
        label: 'State',
        required: true,
        disabled: true,
        valueType: 'string',
        options: US_STATES.StateDropdown,
        defaultValue: 'CA',
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.INPUT,
        key: 'zipCode',
        label: 'Zipcode',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
      {
        type: FqType.INPUT,
        key: 'phone',
        label: 'Phone',
        required: true,
        disabled: true,
        className: 'w-full sm:w-1/2',
      },
    ],
  },
];
