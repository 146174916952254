import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  chip: {
    backgroundColor: '#e9e9ea', // to fixIE11 issue provided fall back color
  },
}));

const ChipLabel = ({ color, classes, title }) => {
  const chipClasses = useStyles();
  return (
    <div className={clsx('flex', classes.root)}>
      <div className={clsx('flex items-center rounded bg-opacity-10 px-6', chipClasses.chip)}>
        <div className="mr-4 h-8 w-8 rounded-lg" style={{ backgroundColor: color }} />
        <Typography variant="caption" title={title} className={classes.chipLabel}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

ChipLabel.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.shape({ root: PropTypes.string, chipLabel: PropTypes.string }),
  title: PropTypes.string.isRequired,
};

ChipLabel.defaultProps = {
  color: 'grey',
  classes: { chipLabel: '', root: '-mx-2' },
};

export default ChipLabel;
