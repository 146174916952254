"use strict";

module.exports = [{
  label: '% wt.',
  value: '% wt.'
}, {
  label: '% vol.',
  value: '% vol.'
}, {
  label: '% (m/v)',
  value: '% (m/v)'
}, {
  label: 'M',
  value: 'M'
}, {
  label: 'μg/mL',
  value: 'μg/mL'
}, {
  label: 'mg/mL',
  value: 'mg/mL'
}, {
  label: 'g/mL',
  value: 'g/mL'
}, {
  label: 'proof',
  value: 'proof'
}, {
  label: 'm',
  value: 'm'
}, {
  label: 'N',
  value: 'N'
}, {
  label: 'U/mg',
  value: 'U/mg'
}, {
  label: 'KU/mg',
  value: 'KU/mg'
}, {
  label: 'mEq/L',
  value: 'mEq/L'
}];