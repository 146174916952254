"use strict";

module.exports = {
  H200: 'Explosives',
  H201: 'Explosives',
  H202: 'Explosives',
  H203: 'Explosives',
  H204: 'Explosives',
  H240: 'Self-Reactives, Organic Peroxides',
  H241: 'Self-Reactives, Organic Peroxides',
  H206: 'Flammables',
  H207: 'Flammables',
  H208: 'Flammables',
  H220: 'Flammables',
  H221: 'Flammables',
  H222: 'Flammables',
  H223: 'Flammables',
  H224: 'Flammables',
  H225: 'Flammables',
  H226: 'Flammables',
  H228: 'Flammables',
  H229: 'Flammables',
  H230: 'Flammables',
  H231: 'Flammables',
  H232: 'Flammables',
  H242: 'Self-Reactives',
  H250: 'Pyrophorics',
  H251: 'Self-Heating',
  H252: 'Self-Heating',
  H260: 'Emits Flammable Gas',
  H261: 'Emits Flammable Gas',
  H270: 'Oxidizers',
  H271: 'Oxidizers',
  H272: 'Oxidizers',
  H280: 'Gases Under Pressure',
  H281: 'Contains refrigerated gas; may cause cryogenic burns or injury',
  H282: 'Chemicals under pressure',
  H283: 'Chemicals under pressure',
  H284: 'Chemicals under pressure',
  H290: 'Corrosive to Metals',
  H314: 'Skin Corrosion/Burns, Eye Damage',
  H318: 'Eye Damage',
  H300: 'Acute Toxicity - Oral',
  H301: 'Acute Toxicity - Oral',
  H310: 'Acute Toxicity - Dermal',
  H311: 'Acute Toxicity - Dermal',
  H330: 'Acute Toxicity - Inhalation',
  H331: 'Acute Toxicity - Inhalation',
  H302: 'Acute Toxicity (harmful)',
  H312: 'Acute Toxicity (harmful)',
  H315: 'Irritant (skin and eye)',
  H317: 'Skin Sensitizer',
  H319: 'Irritant (skin and eye)',
  H332: 'Respiratory Tract Irritant',
  H335: 'Respiratory Tract Irritant',
  H336: 'Narcotic Effects',
  H420: 'Hazardous to Ozone Layer',
  H304: 'Aspiration Toxicity',
  H305: 'Aspiration Toxicity',
  H334: 'Respiratory Sensitizer',
  H340: 'Mutagenicity',
  H341: 'Mutagenicity',
  H350: 'Carcinogen',
  H350i: 'Carcinogen',
  H351: 'Carcinogen',
  H360: 'Reproductive Toxicity',
  H360F: 'Reproductive Toxicity',
  H360D: 'Reproductive Toxicity',
  H360FD: 'Reproductive Toxicity',
  H360Fd: 'Reproductive Toxicity',
  H360Df: 'Reproductive Toxicity',
  H361: 'Reproductive Toxicity',
  H361f: 'Reproductive Toxicity',
  H361d: 'Reproductive Toxicity',
  H361fd: 'Reproductive Toxicity',
  H370: 'Target Organ Toxicity',
  H371: 'Target Organ Toxicity',
  H372: 'Target Organ Toxicity',
  H373: 'Target Organ Toxicity',
  H400: 'Aquatic Toxicity',
  H410: 'Aquatic Toxicity',
  H411: 'Aquatic Toxicity',
  // combination hcodes
  'H300 + H310 + H330': 'Acute Toxicity - Oral, Acute Toxicity - Dermal, Acute Toxicity - Inhalation',
  'H300 + H310': 'Acute Toxicity - Oral, Acute Toxicity - Dermal',
  'H300 + H330': 'Acute Toxicity - Oral, Acute Toxicity - Inhalation',
  'H310 + H330': 'Acute Toxicity - Dermal, Acute Toxicity - Inhalation',
  'H301 + H311 + H331': 'Acute Toxicity - Oral, Acute Toxicity - Dermal, Acute Toxicity - Inhalation',
  'H301 + H311': 'Acute Toxicity - Oral, Acute Toxicity - Dermal',
  'H301 + H331': 'Acute Toxicity - Oral, Acute Toxicity - Inhalation',
  'H311 + H331': 'Acute Toxicity - Dermal, Acute Toxicity - Inhalation',
  'H302 + H312': 'Acute Toxicity (harmful)',
  'H302 + H312 + H332': 'Acute Toxicity (harmful), Respiratory Tract Irritant',
  'H302 + H332': 'Acute Toxicity (harmful), Respiratory Tract Irritant',
  'H312 + H332': 'Acute Toxicity (harmful), Respiratory Tract Irritant',
  'H315 + H320': 'Irritant (skin and eye)',
  'H335 + H336': 'Respiratory Tract Irritant, Narcotic Effects'
};