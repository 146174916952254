import { Fab, Icon, Tooltip, Typography } from '@material-ui/core';
import { Store } from '@risk-and-safety/library';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ProductStockTable from './ProductStockTable';

function ProductStock({ product, store }) {
  const history = useHistory();

  return (
    <>
      {product.stock?.length ? (
        <ProductStockTable stock={product.stock} barcodeRequired={product?.barcodeRequired} store={store} />
      ) : (
        <Typography>No stock for this product</Typography>
      )}
      <Tooltip title="Add" arrow>
        <Fab
          color="primary"
          onClick={() => history.push(`/store/${store._id}/product/${product._id}/stock/add`)}
          aria-label="Add Stock"
          className="absolute bottom-16">
          <Icon>add</Icon>
        </Fab>
      </Tooltip>
    </>
  );
}

ProductStock.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.shape(Store).isRequired,
};

export default ProductStock;
