"use strict";

module.exports = {
  2001: [{
    label: 'B',
    value: 'B',
    sort: 0
  }, {
    label: 'H8',
    value: 'H8',
    sort: 1
  }, {
    label: 'M',
    value: 'M',
    sort: 2
  }, {
    label: 'S',
    value: 'S',
    sort: 2
  }],
  2016: [{
    label: 'A',
    value: 'A',
    sort: 0
  }, {
    label: 'B',
    value: 'B',
    sort: 0
  }, {
    label: 'E',
    value: 'E',
    sort: 0
  }, {
    label: 'F',
    value: 'F',
    sort: 0
  }, {
    label: 'H1',
    value: 'H1',
    sort: 3
  }, {
    label: 'H2',
    value: 'H2',
    sort: 3
  }, {
    label: 'H3',
    value: 'H3',
    sort: 3
  }, {
    label: 'H4',
    value: 'H4',
    sort: 3
  }, {
    label: 'H5',
    value: 'H5',
    sort: 3
  }, {
    label: 'I',
    value: 'I',
    sort: 0
  }, {
    label: 'L',
    value: 'L',
    sort: 1
  }, {
    label: 'M',
    value: 'M',
    sort: 2
  }, {
    label: 'R',
    value: 'R',
    sort: 0
  }, {
    label: 'S',
    value: 'S',
    sort: 2
  }]
};