/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect } from 'react';

const INITIAL_STATE = { value: '', timeoutId: null };
function useBarcodeScanner({ onScan }) {
  let state = INITIAL_STATE;

  const onKeyPress = (evt) => {
    if (evt.target.nodeName !== 'INPUT') {
      clearTimeout(state.timeoutId);

      if (evt.key === 'Enter') {
        if (state.value.length > 0) {
          onScan(state.value);
        }
        state = INITIAL_STATE;
      } else {
        state = {
          value: state.value.concat(evt.key),
          timeoutId: setTimeout(() => {
            state = INITIAL_STATE;
          }, 400),
        };
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keypress', onKeyPress);
    };
  }, [state]);
}

useBarcodeScanner.propTypes = {
  onScan: PropTypes.func,
};

useBarcodeScanner.defaultProps = {
  onScan: () => {},
};

export default useBarcodeScanner;
