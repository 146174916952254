"use strict";

var _require = require('date-fns'),
  format = _require.format,
  isValid = _require.isValid;
var _require2 = require('date-fns-tz'),
  utcToZonedTime = _require2.utcToZonedTime;
var DATE_FORMAT = 'MMM dd, yyyy';
var SHORT_DATE_FORMAT = 'MM/dd/yyyy';
var SHORT_DATE_PLACEHOLDER = 'MM/DD/YYYY';
var DATETIME_FORMAT = 'MMM dd, yyyy h:mm a';
var DATETIME_24H_FORMAT = 'MMM dd, yyyy H:mm';
var SHORT_DATETIME_FORMAT = 'MM/dd/yyyy h:mm a';
var SHORT_DATETIME_24H_FORMAT = 'MM/dd/yyyy H:mm';
var DATETIME_PLACEHOLDER = 'MM/DD/YYYY HH:MM AM/PM';
var DATETIME_24H_PLACEHOLDER = 'MM/DD/YYYY HH:MM';
var TIMEZONE = 'America/Los_Angeles';
module.exports.DATE_FORMAT = DATE_FORMAT;
module.exports.DATETIME_FORMAT = DATETIME_FORMAT;
module.exports.SHORT_DATE_FORMAT = SHORT_DATE_FORMAT;
module.exports.SHORT_DATETIME_FORMAT = SHORT_DATETIME_FORMAT;
module.exports.SHORT_DATE_PLACEHOLDER = SHORT_DATE_PLACEHOLDER;
module.exports.DATETIME_24H_FORMAT = DATETIME_24H_FORMAT;
module.exports.SHORT_DATETIME_24H_FORMAT = SHORT_DATETIME_24H_FORMAT;
module.exports.DATETIME_PLACEHOLDER = DATETIME_PLACEHOLDER;
module.exports.DATETIME_24H_PLACEHOLDER = DATETIME_24H_PLACEHOLDER;
module.exports.formatDate = function (incomingDate) {
  var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMAT;
  var isUTC = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (!incomingDate) {
    return null;
  }
  var date = new Date(incomingDate);
  if (!isValid(date)) {
    return incomingDate;
  }
  if (isUTC) {
    return format(utcToZonedTime(date, TIMEZONE), dateFormat);
  }
  return format(date, dateFormat);
};
module.exports.validateDate = function (date) {
  var year = new Date(date).getFullYear();
  return year && year > 999 && year < 3000;
};
module.exports.maxDate = function () {
  var date = new Date();
  date.setFullYear(date.getFullYear() + 50);
  return date;
};
module.exports.minDate = function () {
  var date = new Date();
  date.setFullYear(date.getFullYear() - 50);
  return date;
};