import { AttachmentHelper } from '@rss/common';
import { useState } from 'react';
import { getGraphClient } from '../incident-mgmt/graphql/helper';

import { GET_PRESIGNED_URLS, GET_SIGNED_URLS, REMOVE_FILES, SCAN_FILES } from '../graphql/attachment.query';

export default function useAttachment(profile) {
  const [loading, setLoading] = useState(false);

  async function checkThumbnailAvailability(url) {
    return fetch(url);
  }

  async function deleteAttachments(attachments, queryParams) {
    if (!attachments.length) {
      return [];
    }
    await getGraphClient(profile, queryParams.feature).mutate({
      mutation: REMOVE_FILES,
      variables: { fileNames: attachments.map((i) => i.name), ...queryParams },
    });

    return attachments;
  }

  async function downloadAttachmentUrl(fileName, queryParams) {
    const {
      data: {
        signedUrls: [{ thumbnailUrl, signedUrl }],
      },
    } = await getGraphClient(profile, queryParams.feature).query({
      query: GET_SIGNED_URLS,
      variables: { fileNames: [fileName], ...queryParams },
      fetchPolicy: 'network-only',
    });
    const res = await checkThumbnailAvailability(thumbnailUrl);
    if (!res.ok) {
      setTimeout(() => checkThumbnailAvailability(thumbnailUrl), 2000);
    }

    return { thumbnailUrl, signedUrl, showThumbnail: res.ok };
  }

  async function uploadAttachment(files, queryParams) {
    const client = getGraphClient(profile, queryParams.feature);
    setLoading(true);
    const {
      data: { preSignedUrls = [] },
    } = await client.query({
      query: GET_PRESIGNED_URLS,
      variables: { fileNames: files.map((f) => f.name), ...queryParams },
      fetchPolicy: 'network-only',
    });
    const s3Responses = await uploadToS3(files, preSignedUrls);
    const {
      data: { scanFiles = [] },
    } = await client.query({
      query: SCAN_FILES,
      variables: { fileNames: s3Responses.map((d) => d.uploaded), ...queryParams },
      fetchPolicy: 'network-only',
    });

    const responses = await AttachmentHelper.mapResponses(preSignedUrls, s3Responses, scanFiles);
    setLoading(false);
    return responses;
  }

  async function uploadToS3(files, presignedUrls) {
    return Promise.all(
      presignedUrls
        .filter((data) => !data.error)
        .map(async (data) => {
          const { file } = files.find((f) => f.name === data.fileMetadata.name);
          const formData = Object.keys(data.fields).reduce((fData, key) => {
            fData.append(key, data.fields[key]);
            return fData;
          }, new FormData());

          formData.append('file', file);
          try {
            await fetch(data.url, {
              method: 'POST',
              body: formData,
            });
            return { uploaded: data.fileMetadata.name };
          } catch (error) {
            return { error: data.fileMetadata.name };
          }
        }),
    );
  }

  return { loading, deleteAttachments, downloadAttachmentUrl, uploadAttachment };
}
