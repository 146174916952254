import PropTypes from 'prop-types';

const { isObject } = require('lodash');

const responseType = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const metaType = {
  id: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.object,
};

const recipientType = {
  email: PropTypes.string,
};

const customObject = (props, propsName, componentName) => {
  if (props[propsName]) {
    return isObject(props[propsName])
      ? null
      : new Error(`${propsName} in ${componentName} is invalid. It requires an object type.`);
  }
  return new Error(`${propsName} in ${componentName} is invalid.`);
};

export { responseType, customObject, metaType, recipientType };
