import { FqType } from '@rss/common';

export const PPELibraryItemTemplate = [
  {
    type: FqType.LABEL,
    key: 'header',
    label: '### **Overview**',
    required: false,
    disabled: null,
  },
  {
    type: FqType.INPUT,
    key: 'name',
    label: 'Name',
    required: true,
    disabled: null,
  },
  {
    type: FqType.MULTISELECT,
    key: 'tags',
    label: 'Type',
    required: true,
    disabled: null,
    valueType: 'string',
    options: ['Body Protection', 'Eye and Face Protection', 'Foot Protection', 'Hand Protection', 'Head Protection'],
  },
];
