import gql from 'graphql-tag';

export const MOVE_DOCUMENTS = gql`
  mutation moveDocuments($fromOrgId: String!, $toOrgId: String!, $usages: [UsageInput]) {
    moveDocuments(fromOrgId: $fromOrgId, toOrgId: $toOrgId, usages: $usages) {
      from {
        id
        label
      }
      to {
        id
        label
      }
    }
  }
`;

export const TRANSFER_ORGANIZATION = gql`
  mutation transferOrganization($orgId: String!, $transferToUser: String!) {
    transferOrganization(orgId: $orgId, transferToUser: $transferToUser) {
      from {
        id
        label
      }
      to {
        id
        label
      }
    }
  }
`;

export const UPSERT_MEMBER_BY_LABEL = gql`
  mutation upsertMemberByLabel($action: String!, $edges: [UpsertInput]) {
    upsertMemberByLabel(action: $action, edges: $edges)
  }
`;

export const REMOVE_MEMBER_BY_LABEL = gql`
  mutation removeMemberByLabel($edges: [UpsertInput]) {
    removeMemberByLabel(edges: $edges)
  }
`;
