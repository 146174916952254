const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const yup = require('yup');
class LibraryItemReference extends RssModel {
  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema(),
      name: yup.string().default(null).nullable(),
      catalog: yup.string().required()
    });
  }
}
module.exports = LibraryItemReference;