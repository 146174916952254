import gql from 'graphql-tag';

export const SEARCH_REGULATION = gql`
  query searchRegulations($searchTerm: String!, $type: String) {
    searchRegulations(searchTerm: $searchTerm, type: $type) {
      id
      regTitle
      abbreviation
      title
      cfr
      regText
      facilityTypes
      exclude
    }
  }
`;

export const FIND_OPERATOR_PERMIT = gql`
  query findOperatorPermit($permitNumber: String!) {
    findOperatorPermit(permitNumber: $permitNumber) {
      id
      operator
      operatorId
      address
      city
      state
      zipCode
      phone
      countyNumber
      effectiveDate
      expirationDate
      county
      permit
      pests
      sites
    }
  }
`;
