import gql from 'graphql-tag';

import { ACCOUNT_FUND_FRAGMENT, ACCOUNT_DELIVERY_LOCATION_FRAGMENT } from './fragments';

// store
export const STORE = gql`
  query store($id: ID!) {
    store(id: $id) {
      _id
      name
      campusCode
      inventoryId
      settings
      salesTax
    }
  }
`;

export const INVENTORY_ITEM_BY_BARCODE = gql`
  query inventoryItemByBarcode($inventoryId: ID!, $barcode: String!) {
    inventoryItemByBarcode(inventoryId: $inventoryId, barcode: $barcode) {
      _id
      barcode
      product {
        _id
        name
        description
        price
        fee
        availableForSale
      }
    }
  }
`;

export const ITEM_BY_BARCODE_OR_SKU = gql`
  query itemByBarcodeSku($storeId: ID!, $inventoryId: ID!, $barcode: String!) {
    inventoryItemByBarcode(inventoryId: $inventoryId, barcode: $barcode) {
      _id
      barcode
      product {
        _id
        name
        description
        price
        fee
      }
    }
    productBySku(storeId: $storeId, sku: $barcode) {
      _id
      name
      description
      sku
      catalog
      price
      fee
    }
  }
`;

export const ACCOUNTS_BY_USER = gql`
  query findAccountsForUser($storeId: String!, $administeredByLabel: String!, $token: String!) {
    findAccountsForUser(storeId: $storeId, administeredByLabel: $administeredByLabel, token: $token) {
      _id
      administeredBy {
        id
        name
      }
      funds {
        ...AccountFund
      }
      deliveryLocations {
        ...AccountDeliveryLocation
      }
    }
  }
  ${ACCOUNT_FUND_FRAGMENT}
  ${ACCOUNT_DELIVERY_LOCATION_FRAGMENT}
`;

export const SUBMIT_ORDER_SELF_CHECKOUT = gql`
  mutation submitOrderSelfCheckout($token: String!, $storeId: String!, $order: OrderInput) {
    submitOrderSelfCheckout(token: $token, storeId: $storeId, order: $order) {
      _id
      status
      createdDate
    }
  }
`;
