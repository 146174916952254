"use strict";

// deprecated, use label.constant.js instead
var _require = require('./label.constant'),
  EDGE = _require.EDGE;
module.exports = EDGE;
module.exports.roleConfig = {
  BIOSAFETY_ADMIN: {
    managedBy: [EDGE.BIOSAFETY_ADMIN]
  },
  IBC_MEMBER: {
    managedBy: [EDGE.BIOSAFETY_ADMIN]
  },
  LAB_HAZARD_ADMIN: {
    managedBy: [EDGE.LAB_HAZARD_ADMIN]
  },
  PPE_COORDINATOR: {
    managedBy: [EDGE.LAB_HAZARD_ADMIN, EDGE.PPE_COORDINATOR]
  },
  UNIT_RISK_ADMIN: {
    managedBy: [EDGE.UNIT_RISK_ADMIN]
  }
};