"use strict";

// https://www.cdpr.ca.gov/docs/county/dataflex/cntycods.htm

var caCounties = [{
  code: '001',
  number: '01',
  name: 'ALAMEDA'
}, {
  code: '003',
  number: '02',
  name: 'ALPINE'
}, {
  code: '005',
  number: '03',
  name: 'AMADOR'
}, {
  code: '007',
  number: '04',
  name: 'BUTTE'
}, {
  code: '009',
  number: '05',
  name: 'CALAVERAS'
}, {
  code: '011',
  number: '06',
  name: 'COLUSA'
}, {
  code: '013',
  number: '07',
  name: 'CONTRA COSTA'
}, {
  code: '015',
  number: '08',
  name: 'DEL NORTE'
}, {
  code: '017',
  number: '09',
  name: 'EL DORADO'
}, {
  code: '019',
  number: '10',
  name: 'FRESNO'
}, {
  code: '021',
  number: '11',
  name: 'GLENN'
}, {
  code: '023',
  number: '12',
  name: 'HUMBOLDT'
}, {
  code: '025',
  number: '13',
  name: 'IMPERIAL'
}, {
  code: '027',
  number: '14',
  name: 'INYO'
}, {
  code: '029',
  number: '15',
  name: 'KERN'
}, {
  code: '031',
  number: '16',
  name: 'KINGS'
}, {
  code: '033',
  number: '17',
  name: 'LAKE'
}, {
  code: '035',
  number: '18',
  name: 'LASSEN'
}, {
  code: '037',
  number: '19',
  name: 'LOS ANGELES'
}, {
  code: '039',
  number: '20',
  name: 'MADERA'
}, {
  code: '041',
  number: '21',
  name: 'MARIN'
}, {
  code: '043',
  number: '22',
  name: 'MARIPOSA'
}, {
  code: '045',
  number: '23',
  name: 'MENDOCINO'
}, {
  code: '047',
  number: '24',
  name: 'MERCED'
}, {
  code: '049',
  number: '25',
  name: 'MODOC'
}, {
  code: '051',
  number: '26',
  name: 'MONO'
}, {
  code: '053',
  number: '27',
  name: 'MONTEREY'
}, {
  code: '055',
  number: '28',
  name: 'NAPA'
}, {
  code: '057',
  number: '29',
  name: 'NEVADA'
}, {
  code: '059',
  number: '30',
  name: 'ORANGE'
}, {
  code: '061',
  number: '31',
  name: 'PLACER'
}, {
  code: '063',
  number: '32',
  name: 'PLUMAS'
}, {
  code: '065',
  number: '33',
  name: 'RIVERSIDE'
}, {
  code: '067',
  number: '34',
  name: 'SACRAMENTO'
}, {
  code: '069',
  number: '35',
  name: 'SAN BENITO'
}, {
  code: '071',
  number: '36',
  name: 'SAN BERNARDINO'
}, {
  code: '073',
  number: '37',
  name: 'SAN DIEGO'
}, {
  code: '075',
  number: '38',
  name: 'SAN FRANCISCO'
}, {
  code: '077',
  number: '39',
  name: 'SAN JOAQUIN'
}, {
  code: '079',
  number: '40',
  name: 'SAN LUIS OBISPO'
}, {
  code: '081',
  number: '41',
  name: 'SAN MATEO'
}, {
  code: '083',
  number: '42',
  name: 'SANTA BARBARA'
}, {
  code: '085',
  number: '43',
  name: 'SANTA CLARA'
}, {
  code: '087',
  number: '44',
  name: 'SANTA CRUZ'
}, {
  code: '089',
  number: '45',
  name: 'SHASTA'
}, {
  code: '091',
  number: '46',
  name: 'SIERRA'
}, {
  code: '093',
  number: '47',
  name: 'SISKIYOU'
}, {
  code: '095',
  number: '48',
  name: 'SOLANO'
}, {
  code: '097',
  number: '49',
  name: 'SONOMA'
}, {
  code: '099',
  number: '50',
  name: 'STANISLAUS'
}, {
  code: '101',
  number: '51',
  name: 'SUTTER'
}, {
  code: '103',
  number: '52',
  name: 'TEHAMA'
}, {
  code: '105',
  number: '53',
  name: 'TRINITY'
}, {
  code: '107',
  number: '54',
  name: 'TULARE'
}, {
  code: '109',
  number: '55',
  name: 'TUOLUMNE'
}, {
  code: '111',
  number: '56',
  name: 'VENTURA'
}, {
  code: '113',
  number: '57',
  name: 'YOLO'
}, {
  code: '115',
  number: '58',
  name: 'YUBA'
}];
module.exports = caCounties;
var getCountyByCode = function getCountyByCode(countyCode) {
  return caCounties.find(function (_ref) {
    var code = _ref.code;
    return code.toUpperCase() === countyCode.toUpperCase();
  });
};
var getCountyByName = function getCountyByName(countyName) {
  return caCounties.find(function (_ref2) {
    var name = _ref2.name;
    return name.toUpperCase() === countyName.toUpperCase();
  });
};
var getCountyByNumber = function getCountyByNumber(countyNumber) {
  return caCounties.find(function (_ref3) {
    var number = _ref3.number;
    return number.toUpperCase() === countyNumber.toUpperCase();
  });
};
module.exports.getCountyCodeByName = function (countyName) {
  var county = getCountyByName(countyName);
  return county ? county.code : null;
};
module.exports.getCountyNameByCode = function (countyCode) {
  var county = getCountyByCode(countyCode);
  return county ? county.name : null;
};
module.exports.getCountyNumberByName = function (countyName) {
  var county = getCountyByName(countyName);
  return county ? county.number : null;
};
module.exports.CountyDropdown = caCounties.map(function (_ref4) {
  var code = _ref4.code,
    name = _ref4.name;
  return {
    label: name,
    value: code
  };
});
module.exports.CountyNameDropdown = caCounties.map(function (_ref5) {
  var name = _ref5.name;
  return {
    label: name,
    value: name
  };
});
module.exports.getCountyByCode = getCountyByCode;
module.exports.getCountyByName = getCountyByName;
module.exports.getCountyByNumber = getCountyByNumber;