/* eslint-disable max-len */
import { useMutation } from '@apollo/client';
import { Divider, Icon, IconButton, Typography } from '@material-ui/core';
import { Store } from '@risk-and-safety/library';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, Redirect, useRouteMatch } from 'react-router-dom';

import { CREATE_STORE_ACCOUNT, FIND_ACCOUNTS_FOR_USER } from '../graphql/query';
import { ButtonProgress } from '../../components';
import { useToast } from '../../hooks';

function AccountCreate({ store }) {
  const history = useHistory();
  const { state } = useLocation();
  const { showError } = useToast();

  const { url } = useRouteMatch(['/shop/:storeId/account', '/store/:storeId/account']);

  const [createStoreAccount, { loading }] = useMutation(CREATE_STORE_ACCOUNT, {
    onCompleted: (data) => {
      !data.createStoreAccount.newAccount &&
        showError('A store account for this inventory has already been created by another user.');
      history.replace(`${url}/${data.createStoreAccount._id}`);
    },
    refetchQueries: [
      {
        query: FIND_ACCOUNTS_FOR_USER,
        variables: { storeId: store._id, administeredByLabel: 'INVENTORY' },
      },
    ],
  });

  if (!state || !state.administeredBy) {
    return <Redirect to={url} />;
  }

  return (
    <div className="flex h-full flex-grow flex-col  items-center">
      <div className="absolute left-48 top-136 flex h-64 items-center">
        <IconButton onClick={() => history.goBack()}>
          <Icon>arrow_back</Icon>
        </IconButton>
      </div>

      <div className="my-136 max-w-640">
        <Typography variant="h5" className="mb-16 text-32 font-light text-primary-500">
          Let&apos;s set up a store account
        </Typography>
        <Typography color="textSecondary" className="mb-48 text-xl font-light">
          To set up a store account for {state?.administeredBy.name}, you need to provide valid financial information.
          If this information is not available, please contact your lab manager or someone responsible for managing
          purchases.
        </Typography>

        <Divider className="my-24" />

        <div className="flex justify-end">
          <ButtonProgress
            loading={loading}
            variant="contained"
            color="primary"
            className="mt-16 w-256"
            onClick={() => {
              createStoreAccount({
                variables: {
                  storeId: store._id,
                  administeredById: state.administeredBy.id,
                  administeredByLabel: state.administeredBy.label,
                  administeredByName: state.administeredBy.name,
                  administeredByOwnerLastName: state.administeredBy.owner?.lastName,
                },
              });
            }}>
            Continue
          </ButtonProgress>
        </div>
      </div>
    </div>
  );
}

AccountCreate.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default AccountCreate;
