import { DateHelper } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

const CommentItemReadOnly = ({ comment }) => {
  return (
    <div key={comment.id} className="py-8 px-0">
      <div className="w-full">
        <div className="flex flex-row">
          <span className="font-medium">{`${comment.author.firstName} ${comment.author.lastName}`}</span>-&nbsp;{' '}
          <span>{DateHelper.formatDate(new Date(comment.createdDate), 'MM/dd/yyyy h:mm a')}</span>
        </div>
        <div className="flex flex-row">
          <span className="max-w-md break-words">{comment.text}</span>
        </div>
      </div>
    </div>
  );
};

CommentItemReadOnly.propTypes = {
  comment: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CommentItemReadOnly;
