import { FqType, US_STATES } from '@rss/common';

export const FacilityTemplate = [
  {
    type: FqType.LABEL,
    key: 'facility-details',
    label: '### **Facility Details**',
  },
  {
    type: FqType.INPUT,
    key: 'name',
    label: 'Name',
    required: true,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'type',
    label: 'Type',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'code',
    label: 'Code',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'license',
    label: 'License',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.LABEL,
    key: 'facility-address',
    label: '### **Address**',
  },
  {
    type: FqType.INPUT,
    key: 'address',
    label: 'Address',
    required: false,
    disabled: true,
    className: 'w-full',
  },
  {
    type: FqType.INPUT,
    key: 'suite',
    label: 'Suite',
    required: false,
    disabled: true,
    className: 'w-full',
  },
  {
    type: FqType.INPUT,
    key: 'city',
    label: 'City',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'county',
    label: 'County',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'zipcode',
    label: 'Zipcode',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.DROPDOWN,
    key: 'state',
    label: 'State',
    required: false,
    disabled: true,
    valueType: 'string',
    options: US_STATES.StateDropdown,
    defaultValue: 'CA',
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'phone',
    label: 'Phone',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
  {
    type: FqType.INPUT,
    key: 'phoneExt',
    label: 'Phone Ext',
    required: false,
    disabled: true,
    className: 'w-full sm:w-1/2',
  },
];
