"use strict";

module.exports = {
  SRMAX: {
    label: 'SR Max',
    value: 'SRMAX'
  },
  SFC: {
    label: 'Shoes for Crews',
    value: 'SFC'
  }
};