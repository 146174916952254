import qs from 'qs';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

function getTabKey(tabs, tab) {
  return tabs[tab] ? tab : Object.keys(tabs).find((tabKey) => tabs[tabKey] === tab);
}

export function useTabsNavigation(tabs, initialTab) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [activeTab, setActiveTab] = useState(getTabKey(tabs, initialTab));
  const queryStringRef = useRef(null);

  useLayoutEffect(() => {
    const { tab, ...queryString } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    setActiveTab(getTabKey(tabs, tab || initialTab));
    queryStringRef.current = queryString;
  }, [history.location.search, initialTab, tabs]);

  const changeTab = useCallback(
    (newTab) => {
      const tab = getTabKey(tabs, newTab);

      setActiveTab(tab);
      history.replace({
        pathname: url,
        state: history.location.state,
        search: qs.stringify({
          tab,
          ...queryStringRef.current,
        }),
      });
    },
    [history, tabs, url],
  );

  return { activeTab, changeTab };
}
