"use strict";

module.exports = {
  DISCUSSION: 'DISCUSSION',
  IN_PROGRESS: 'IN_PROGRESS',
  NO_FURTHER_ACTION: 'NO_FURTHER_ACTION',
  NOT_RESOLVED: 'NOT_RESOLVED',
  READY_FOR_VERIFICATION: 'READY_FOR_VERIFICATION',
  RESOLVED: 'RESOLVED',
  CREATED_TICKET: 'CREATED_TICKET'
};