const {
  PersonReference
} = require('@rss/common');
const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
class Flag {
  constructor(opt) {
    this.id = null;
    this.reviewer = null;
    this.date = null;
    this.type = null;
    this.problem = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this.id = new ObjectId(opt.id);
    this.reviewer = opt.reviewer ? new PersonReference(opt.reviewer) : null;
    this.date = opt.date ? new Date(opt.date) : new Date();
    this.type = opt.type;
    this.problem = opt.problem;
  }
  validate() {
    return tv4.validateMultiple(this, Flag.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'flag.schema.json',
      type: 'object',
      properties: {
        id: {
          type: 'object'
        },
        reviewer: {
          $ref: 'person-reference.schema.json'
        },
        date: {
          type: ['object', 'null']
        },
        type: {
          type: 'string'
        },
        problem: {
          type: 'string'
        }
      },
      required: Object.keys(this)
    };
  }
}
tv4.addSchema(PersonReference.schema());
module.exports = Flag;