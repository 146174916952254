import { useLazyQuery } from '@apollo/client';
import { FamilyReference } from '@risk-and-safety/chemical';
import { ListItem, ListItemText, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce, get, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Search } from '../../components';
import { SEARCH_FAMILIES } from '../graphql/query';
import { useProfile } from '../../hooks';

const ChemicalSearch = ({
  autoFocus,
  clearOnSelect,
  id,
  label,
  placeholder,
  onClear,
  onSelect,
  required,
  value,
  control,
  helperText,
}) => {
  const {
    profile: { campusCode },
  } = useProfile();

  const [searchFamilies, { data = {}, loading }] = useLazyQuery(SEARCH_FAMILIES);
  const searchChemicals = debounce(searchFamilies, 200);

  const results = get(data, 'families', []).map((f) => ({ ...f, key: f._id }));

  const renderInput = useCallback((params) => {
    return <TextField {...params} variant="outlined" label={label} placeholder={label} helperText={helperText} />;
  });

  if (control === 'auto-complete') {
    const options = results?.map((s) => pick(s, ['_id', 'name', 'formNormalize', 'form', 'identifiers']));
    return (
      <Autocomplete
        id="chemical-search"
        options={options}
        value={value || ''}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option?.name)}
        onChange={(_, newValue) => onSelect(newValue)}
        multiple
        onInputChange={(_, newValue) => searchChemicals({ variables: { campusCode, keyword: newValue } })}
        renderInput={renderInput}
        renderOption={(chemical) => {
          console.log('chemical ', chemical);
          return (
            <ListItem>
              <ListItemText
                primary={chemical.name}
                secondary={`Physical State: ${chemical.formNormalize || chemical.form} CAS: ${
                  chemical.identifiers.cas || 'N/A'
                }`}
              />
            </ListItem>
          );
        }}
      />
    );
  }

  return (
    <Search
      id={id}
      isLoading={loading}
      autocomplete
      autoFocus={autoFocus}
      clearOnSelect={clearOnSelect}
      disableUnderline={false}
      value={value}
      variant="outlined"
      openMenuOnFocus={false}
      label={label}
      placeholder={placeholder}
      items={results}
      itemToString={(chem) => get(chem, 'name')}
      onChange={({ inputValue }) => {
        searchChemicals({ variables: { campusCode, keyword: inputValue } });
      }}
      onSelect={onSelect}
      onClear={() => {
        onClear();
      }}
      renderItem={(chemical) => {
        return (
          <ListItem>
            <ListItemText
              primary={chemical.name}
              secondary={
                <Typography component="div" variant="body2" color="textSecondary">
                  <div>{`Physical State: ${chemical.formNormalize || chemical.form}`}</div>
                  <div>{`CAS: ${chemical.identifiers.cas || 'N/A'}`}</div>
                  <div>{`GHS: ${chemical.ghs.length ? chemical.ghs.map((g) => g.hazardCode).join(', ') : 'N/A'}`}</div>
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      required={required}
    />
  );
};

ChemicalSearch.propTypes = {
  autoFocus: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.shape(FamilyReference), PropTypes.arrayOf(PropTypes.shape(FamilyReference))]),
  control: PropTypes.oneOf(['search', 'auto-complete']),
  helperText: PropTypes.string,
};

ChemicalSearch.defaultProps = {
  autoFocus: false,
  clearOnSelect: false,
  id: null,
  onClear: () => null,
  label: 'Search Chemical',
  placeholder: 'Search by cas #, name, GHS, product number',
  required: false,
  value: null,
  control: 'search',
  helperText: '',
};

export default ChemicalSearch;
