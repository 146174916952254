"use strict";

module.exports = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  id: 'fqQuestion.schema.json',
  title: 'Flexible Questionnaire Question',
  type: 'object',
  additionalProperties: true,
  properties: {
    type: {
      type: 'string'
    },
    key: {
      type: 'string'
    },
    label: {
      type: 'string'
    },
    options: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: 'string',
          value: 'string'
        }
      }
    },
    required: {
      type: 'boolean'
    },
    inputType: {
      type: 'string'
    }
  }
};