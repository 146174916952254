const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const yup = require('yup');
const PersonReferenceSchema = require('./person-reference.schema');
const InventoryReference = require('./asset-inventory.reference.schema');
class AssetInventory extends RssModel {
  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema(),
      name: yup.string().trim().min(1, 'Name is Required').required(),
      type: yup.string().default(null).nullable().defined(),
      campusCode: yup.string().required(),
      tenantCode: yup.string(),
      catalogs: yup.array().of(yup.string()).default([]),
      createdDate: yup.date().required(),
      colleagues: yup.array().of(InventoryReference).default([]),
      lastUpdatedDate: yup.date().default(new Date()).required(),
      tags: yup.array().of(yup.string()).default([]),
      lastCertifiedDate: yup.date().default(null).nullable(),
      lastCertifiedBy: PersonReferenceSchema.default(null).nullable(),
      // stockroom is a sharable inventory, it is not a store
      stockroom: yup.bool().default(false).required(),
      orgCodes: yup.array().of(yup.string()).default([]),
      accounts: yup.array().of(yup.object({
        account: yup.string(),
        type: yup.string(),
        default: yup.bool().default(false)
      })).default([]),
      budgetManager: yup.object({
        userId: yup.string(),
        firstName: yup.string(),
        lastName: yup.string(),
        email: yup.string()
      }).default(null).nullable()
    });
  }
}
module.exports = AssetInventory;