import { Icon, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    pointerEvents: 'none',
  },
}));

const RightSidebarMenu = ({ iconAlignment, link, menuItems, onMenuSelect, selectedMenuItem }) => {
  const classes = useStyles();
  const history = useHistory();

  if (!menuItems?.length) {
    return null;
  }

  return (
    <div className="flex-auto border-l-1 border-solid py-8">
      <div className="pl-16">
        <List>
          {menuItems.map((menu) => (
            <ListItem
              key={menu.value}
              button
              disabled={menu?.disabled}
              onClick={() => onMenuSelect(menu)}
              className={clsx('rounded-l-full', selectedMenuItem === menu.value && classes.active)}
              component={link ? HashLink : null}
              to={menu.hash ? { hash: menu.hash, search: history.location.search } : null}>
              {menu?.icon && (
                <Icon
                  className={clsx('mr-16 h-16 w-16 text-16', iconAlignment === 'right' && 'absolute right-4')}
                  color={selectedMenuItem === menu.value ? 'inherit' : 'action'}>
                  {menu.icon}
                </Icon>
              )}
              <ListItemText primary={menu.label} disableTypography />
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

RightSidebarMenu.propTypes = {
  iconAlignment: PropTypes.string,
  link: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMenuItem: PropTypes.string.isRequired,
  onMenuSelect: PropTypes.func,
};

RightSidebarMenu.defaultProps = {
  iconAlignment: 'left',
  link: false,
  onMenuSelect: () => {},
};

export default RightSidebarMenu;
