"use strict";

module.exports = {
  ADDRESS: 'address',
  ADD_ANOTHER: 'addanother',
  ATTACHMENT: 'attachment',
  BUILDING_SEARCH: 'buildingsearch',
  CHECKBOX: 'checkbox',
  DATEPICKER: 'datepicker',
  DATETIMEPICKER: 'datetimepicker',
  DEPARTMENT_SEARCH: 'department-search',
  DEPENDENT: 'dependent',
  DROPDOWN: 'dropdown',
  FACILITY_SEARCH: 'facility-search',
  GROUP: 'group',
  INPUT: 'input',
  LABEL: 'label',
  LIBRARYTYPE: 'librarytype',
  LOCATION: 'location',
  MULTISELECT: 'multiselect',
  OCCUPATION_SEARCH: 'occupation-search',
  PEOPLE: 'people',
  PERMIT_SEARCH: 'permit-search',
  PERSON_SEARCH: 'personsearch',
  RADIO: 'radio',
  REGULATION_SEARCH: 'regulation-search',
  RICHTEXT: 'richtext',
  TEXTAREA: 'textarea',
  TIMEPICKER: 'timepicker',
  SCORE: 'score',
  SWITCH: 'switch',
  YYYY: 'yyyy',
  EXTERNAL_FILE: 'external-file',
  PERMIT_SITE: 'permit-site',
  RECIPIENTS: 'recipients'
};