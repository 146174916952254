/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useQuery } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogContentText, Fab, Icon, Tooltip } from '@material-ui/core';
import { Store, STORE_PERMISSION } from '@risk-and-safety/library';
import PropTypes from 'prop-types';
import { flatten, groupBy, sortBy, values } from 'lodash';
import pluralize from 'pluralize';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import TableWithHeader from './TableWithHeader';
import { STORE_WITH_INVENTORY, INVENTORY_ITEM_COUNT_BY_SUBLOCATION, REMOVE_SUBLOCATION } from '../graphql/query';
import { closeDialog, openDialog } from '../../store/fuse/dialogSlice';
import { ButtonProgress } from '../../components';

const SettingLocation = ({ store }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const { refetch } = useQuery(INVENTORY_ITEM_COUNT_BY_SUBLOCATION, {
    variables: { inventoryId: store.inventoryId },
    skip: true,
  });

  const [removeSublocation, { loading }] = useMutation(REMOVE_SUBLOCATION, {
    refetchQueries: [
      {
        query: STORE_WITH_INVENTORY,
        variables: { id: store._id },
      },
    ],
  });

  const buildings = useMemo(
    () =>
      groupBy(
        sortBy(
          flatten(values(store.inventory.sublocations)),
          (item) => `${item.buildingName}${item.roomNumber}${item.subloctionName}`,
        ),
        'buildingId',
      ),
    [store.inventory.sublocations],
  );
  return (
    <>
      {buildings &&
        Object.keys(buildings).map((key) => (
          <div key={key} className="-mt-14 pb-36">
            <TableWithHeader
              enableCheckbox={store?.permissions?.includes(STORE_PERMISSION.MANAGE)}
              icon="apartment"
              title={buildings[key][0].buildingName}
              columns={[
                { Header: 'Room', accessor: 'roomNumber', className: 'w-256' },
                { Header: 'Sublocation', accessor: 'sublocationName' },
              ]}
              data={buildings[key]}
              onEdit={(value) => {
                history.push(`${url}/${value._id}`);
              }}
              onRemove={async (locations) => {
                const result = await refetch({
                  sublocationIds: locations.map((item) => item._id),
                });

                if (result.data.inventoryItemBySublocation.length > 0) {
                  dispatch(
                    openDialog({
                      children: (
                        <>
                          <DialogContent>
                            <DialogContentText component="div" className="mt-10">
                              The {pluralize('sublocation', result.data.inventoryItemBySublocation.length)} still have
                              inventory associated. Remove the items before removing the sublocation from your store.
                              <table className="mt-10">
                                {result.data.inventoryItemBySublocation.map((item) => {
                                  const match = locations.find((location) => location._id === item._id);
                                  return (
                                    <tr>
                                      <td>
                                        Room {match.roomNumber}: {match.sublocationName}
                                      </td>
                                      <td className="pl-60">{item.count} items</td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              color="primary"
                              autoFocus
                              onClick={() => dispatch(closeDialog())}
                              className="my-10 mx-16">
                              Close
                            </Button>
                          </DialogActions>
                        </>
                      ),
                    }),
                  );
                } else {
                  dispatch(
                    openDialog({
                      children: (
                        <>
                          <DialogContent>
                            <DialogContentText component="div" className="mt-10">
                              Are you sure you would like to remove the following{' '}
                              {pluralize('sublocation', result.data.inventoryItemBySublocation.length)}?
                              <table className="mt-10">
                                {locations.map((location) => (
                                  <tr>
                                    <td>
                                      Room {location.roomNumber}: {location.sublocationName}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              variant="contained"
                              autoFocus
                              onClick={() => dispatch(closeDialog())}
                              className="mb-10 mr-10">
                              Cancel
                            </Button>

                            <ButtonProgress
                              loading={loading}
                              onClick={() => {
                                locations.map((location) =>
                                  removeSublocation({
                                    variables: {
                                      fromLocation: {
                                        _id: location._id,
                                        inventoryId: location.inventoryId,
                                        buildingId: location.buildingId,
                                        buildingName: location.buildingName,
                                        floorName: location.floorName,
                                        floorId: location.floorId,
                                        roomId: location.roomId,
                                        roomNumber: location.roomNumber,
                                        name: location.sublocationName,
                                        barcode: location.barcode,
                                        temperature: location.temperature,
                                        pressure: location.pressure,
                                        isPrivate: location.isPrivate,
                                      },
                                    },
                                  }),
                                );
                                dispatch(closeDialog());
                              }}
                              variant="contained"
                              color="secondary"
                              className="mb-10 mr-16">
                              Remove
                            </ButtonProgress>
                          </DialogActions>
                        </>
                      ),
                    }),
                  );
                }
              }}
            />
          </div>
        ))}

      {store?.permissions?.includes(STORE_PERMISSION.MANAGE) && (
        <Tooltip title="Add" arrow>
          <Fab
            color="primary"
            onClick={() => history.push(`/store/${store._id}/settings/location/add`)}
            aria-label="Edit"
            className="absolute bottom-16">
            <Icon>add</Icon>
          </Fab>
        </Tooltip>
      )}
    </>
  );
};

SettingLocation.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default SettingLocation;
