import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';

import { useReport } from './useReport';

export const REPORT_NAV_SUBPAGE = {
  INCIDENT: {
    name: 'INCIDENT',
    path: 'incident',
  },
  RESOLUTION: {
    name: 'RESOLUTION',
    path: 'resolution',
  },
};

export const REPORT_NAV_TABS = {
  DETAILS: 'Details',
  PEOPLE: 'People',
  QUESTIONNAIRE: 'Questionnaire',
  INCIDENTS: 'Incidents',
};

const PARAMS_SCHEMA = {
  INCIDENT: object({
    id: string().ensure(),
    categoryId: string().required(),
    responseId: string(),
    questionKey: string().required(),
    searchTerm: string(),
    searchCategory: string(),
  }),
  RESOLUTION: object({
    id: string().required(),
    resolutionStatus: string().required(),
  }),
};

function link(ticketUrl) {
  const newWindow = window.open(ticketUrl, '_blank', 'noopener, noreferrer');
  if (newWindow) {
    newWindow.opener = null;
  }
}

export function useReportNavigation({ returnTab = '' } = {}) {
  const history = useHistory();
  const { report, questionnaire } = useReport();
  const routeParams = useMemo(() => qs.parse(history.location.search, { ignoreQueryPrefix: true }), [history]);

  const to = useCallback(
    (location = {}, search = {}) => {
      if (!PARAMS_SCHEMA[location?.name]) {
        throw new Error(`No Sub Page ${location}`);
      }
      const { id, ...navigationParams } = PARAMS_SCHEMA[location.name].validateSync(search, { stripUnknown: true });
      if (!navigationParams.searchTerm) {
        delete navigationParams.searchTerm;
      }

      history.push({
        pathname: `/questionnaire/${questionnaire.seriesId}/report/${report._id}/${location.path}/${id}`,
        search: qs.stringify(
          {
            ...navigationParams,
            returnTab,
          },
          { addQueryPrefix: true },
        ),
      });
    },
    [report._id, questionnaire.seriesId, history, returnTab],
  );

  const back = useCallback(() => {
    const { returnTab: tab, ...navigationParams } = routeParams;
    history.push({
      pathname: `/questionnaire/${questionnaire.seriesId}/report/${report._id}`,
      search: qs.stringify({ ...navigationParams, tab }, { addQueryPrefix: true }),
    });
  }, [report._id, questionnaire.seriesId, history, routeParams]);

  return { back, link, to, routeParams };
}
