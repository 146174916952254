"use strict";

module.exports = [{
  label: 'All',
  value: 'ALL'
}, {
  label: 'Barcoded',
  value: 'BARCODED'
}, {
  label: 'Missing Barcode',
  value: 'NOT_BARCODED'
}];