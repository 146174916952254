import { Checkbox, FormControl, FormControlLabel, Icon, Typography } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

import { getDisplayName } from '../shared/helper';

const {
  EDGE: { LOCATED },
} = RELATIONSHIP_LABEL;

const LocationCheckBox = ({ checked, disabled, onChange, usage: { label, name, multipleGroups } }) => {
  return (
    <FormControl component="fieldset">
      <FormControlLabel
        key={name}
        className="mr-0"
        control={
          <Checkbox
            color="primary"
            disabled={disabled}
            value={LOCATED}
            checked={checked}
            onChange={({ target }) => {
              onChange(target.checked, LOCATED);
            }}
            data-cy={`e2e-checkbox-${LOCATED}`}
          />
        }
        label={
          <>
            {name} <Typography variant="caption" component="span">{`(${getDisplayName(label)})`}</Typography>
            {multipleGroups && (
              <Icon className="text-16" color="action">
                group
              </Icon>
            )}
          </>
        }
      />
    </FormControl>
  );
};

LocationCheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  usage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    multipleGroups: PropTypes.bool.isRequired,
  }).isRequired,
};

LocationCheckBox.defaultProps = {
  disabled: false,
};

export default LocationCheckBox;
