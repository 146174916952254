const { ObjectId } = require('bson');
const tv4 = require('tv4');

class AssessmentTypeConfig {
  // TODO: Clean up removed fields
  constructor(opt = {}) {
    this._id = null;
    this.type = null;
    this.title = null;
    this.url = null;
    this.routeParam = null;
    this.featureKey = null;
    this.adminSearch = null;
    this.adminLeftnav = null;
    this.createDisplay = null;
    this.formCompleteSteps = [];
    this.roleDisplay = {};
    this.statusDisplay = {};
    this.formLabelDisplay = {};
    this.assessmentCompletedStatus = null;
    this.rosterDescription = null;
    this.locationDescription = null;
    this.trainingClassification = null;
    this.permissions = {};
    this.fillObject(opt);
  }

  fillObject(opt) {
    this._id = opt._id ? new ObjectId(opt._id) : null;
    this.type = opt.type;
    this.title = opt.title;
    this.url = opt.url;
    this.createDisplay = opt.createDisplay || '';
    this.routeParam = opt.routeParam || null;
    this.featureKey = opt.featureKey || null;
    this.adminSearch = opt.adminSearch || null;
    this.adminLeftnav = opt.adminLeftnav || [];
    this.formCompleteSteps = opt.formCompleteSteps || [];
    this.roleDisplay = opt.roleDisplay || {};
    this.statusDisplay = opt.statusDisplay || {};
    this.formLabelDisplay = opt.formLabelDisplay || {};
    this.assessmentCompletedStatus = opt.assessmentCompletedStatus || null;
    this.rosterDescription = opt.rosterDescription || '';
    this.locationDescription = opt.locationDescription || '';
    this.trainingClassification = opt.trainingClassification || '';
    this.permissions = opt.permissions || {};
  }

  validate() {
    return tv4.validateMultiple(this, AssessmentTypeConfig.schema());
  }

  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'assessment-display-config.schema.json',
      type: 'object',
      additionalProperties: false,
      properties: {
        _id: { type: 'object' },
        type: { type: 'string' },
        title: { type: 'string' },
        url: { type: 'string' },
        routeParam: { type: 'string' },
        featureKey: { type: ['string', 'null'] },
        adminSearch: { type: ['object', 'null'] },
        adminLeftnav: { type: ['array', 'null'] },
        formCompleteSteps: { type: 'array' },
        roleDisplay: { type: 'object' },
        statusDisplay: { type: 'object' },
        formLabelDisplay: { type: 'object' },
        assessmentCompletedStatus: { type: ['string', 'null'] },
        trainingClassification: { type: 'string' },
        permissions: { type: 'object' },
        locationDescription: { type: 'string' },
      },
      required: Object.keys(new AssessmentTypeConfig()),
    };
  }
}

tv4.addSchema(AssessmentTypeConfig.schema());

module.exports = AssessmentTypeConfig;
