import {
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';

import { sortData } from '../shared/helper';
import EmptyStateMessage from './EmptyStateMessage';
import IconEmpty from './IconEmpty';

const stickyCell = {
  boxShadow: 'rgba(125, 147, 178, 0.25) 4px 0px 4px',
  left: 0,
  minWidth: 200,
  position: 'sticky',
};

const useStyles = makeStyles((theme) => ({
  stickyHeadCell: {
    ...stickyCell,
    zIndex: 10,
  },
  stickyDataCell: {
    ...stickyCell,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

const SortableTable = ({
  columns,
  emptyState,
  hasMore,
  hasFilterFooter,
  loading,
  limitedView,
  link,
  onRowClick,
  tooltip,
  rows: dataRows,
  onLoadMore,
  onSort,
  sortProps
}) => {
  const sortColumn = columns.find((c) => c.defaultSort);
  const [defaultSort, setDefaultSort] = useState(sortColumn ? sortColumn.prop : columns[0].prop);
  const [sortDir, setSortDir] = useState(sortColumn ? sortColumn.defaultSort : 'asc');
  const [rows, setRows] = useState(dataRows || []);

  useEffect(() => {
    setRows(dataRows);
  }, [dataRows]);

  const tableClasses = useStyles();
  const theme = useTheme();
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSort = (event, property) => {
    const isAsc = defaultSort === property && sortDir === 'asc';
    const direction = isAsc ? 'desc' : 'asc';
    setSortDir(direction);
    setDefaultSort(property);
    onSort && onSort({ prop: property, dir: direction });
    sortProps && sortProps({ prop: property, dir: direction });
  };

  const fallBackSort = defaultSort === columns[0].prop ? [] : [columns[0].prop];
  const sortedRows = onSort
    ? rows
    : sortData(rows, defaultSort, sortDir, fallBackSort, columns.find((header) => header.prop === defaultSort)?.type);
  const displayRows = limitedView ? sortedRows.slice(0, 5) : sortedRows;
  const showEmptyStateMessage = !loading && !hasMore && !displayRows.length;

  const getTableHeadCell = (data, index, length) => {
    const { label, prop, icon, sortable = true } = data;
    const headClasses = clsx(
      'whitespace-nowrap truncate',
      columns.length > 4 && 'max-w-160',
      index === 0 && 'pl-10',
      index === length - 1 && 'pr-10',
    );

    if (isEmpty(label)) {
      return <Typography variant="srOnly">No header defined</Typography>;
    }

    if (sortable) {
      return (
        <TableSortLabel
          active={defaultSort === prop}
          direction={defaultSort === prop ? sortDir : 'asc'}
          onClick={(event) => handleSort(event, prop)}>
          <p className={headClasses}>{label}</p>
          {icon}
        </TableSortLabel>
      );
    }

    return (
      <>
        <p className={headClasses}>{label}</p>
        {icon}
      </>
    );
  };

  return (
    <>
      <InfiniteScroll
        pageStart={0}
        initialLoad={false}
        loadMore={onLoadMore}
        hasMore={hasMore}
        loader={<LinearProgress className={clsx('clear-both', hasFilterFooter && 'mb-44')} key={0} />}
        useWindow={false}>
        {loading && <LinearProgress className="clear-both sticky bottom-0 top-64 w-screen" />}
        <Table
          stickyHeader={!limitedView}
          size="medium"
          className={clsx(loading && '-mt-5', !hasMore && hasFilterFooter && 'mb-44')}
          component="div">
          <TableHead component="div">
            <TableRow component="div">
              {columns.map(({ className, label, prop, icon, sortable, sticky, tableHeadMenu, tooltip }, index) => (
                <Tooltip key={`${prop}-header-tooltip`} title={tooltip || ''} arrow>
                  <TableCell
                    key={`${prop}-header`}
                    component="div"
                    align="left"
                    className={clsx(
                      className,
                      limitedView ? 'bg-default py-8' : 'h-64 min-h-64',
                      !isMobileResolution && sticky && tableClasses.stickyHeadCell,
                      'top-0',
                    )}
                    padding="default"
                    sortDirection={defaultSort === prop ? sortDir : false}>
                    {tableHeadMenu
                      ? tableHeadMenu()
                      : getTableHeadCell({ label, prop, icon, sortable }, index, columns.length)}
                  </TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          {displayRows.length ? (
            <TableBody component="div">
              {displayRows.map((row) => {
                const to = (link && link(row)) || row.link || null;
                const isRowClickable = Boolean(to || onRowClick);
                const onClick = onRowClick ? () => onRowClick(row) : null;
                const rowTooltip = tooltip ? tooltip(row) : '';

                return (
                  <Tooltip key={`row-tooltip-${row.key || row._id || row.id}`} title={rowTooltip ?? ''} arrow>
                    <TableRow
                      component="div"
                      className={(isRowClickable && 'align-top cursor-pointer') || ''}
                      key={`row-${row.key || row._id || row.id}`}
                      tooltip={`row-${row.key || row._id || row.id}`}
                      hover={isRowClickable}
                      onClick={onClick}>
                      {columns.map(({ className, renderCell, prop, sticky, disableClick }, index) => {
                        const value = renderCell ? renderCell(row) : row[prop];
                        const isClickable = !disableClick && to;

                        return (
                          <TableCell
                            component={isClickable ? Link : 'div'}
                            role={isClickable ? 'button' : 'cell'}
                            to={to}
                            onClick={(ev) => {
                              if (disableClick) {
                                ev.stopPropagation();
                              } else if (onRowClick) {
                                ev.preventDefault();
                              }
                            }}
                            key={`cell-${prop}-${row.key || row._id || row.id}`}
                            className={clsx(
                              className,
                              row.disabled && 'opacity-70',
                              !isMobileResolution && sticky && tableClasses.stickyDataCell,
                              'break-words text-current',
                              index === 0 && 'pl-20',
                              index === columns.length - 1 && 'pr-10',
                            )}
                            padding="default">
                            {value || <IconEmpty />}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </Tooltip>
                );
              })}
            </TableBody>
          ) : null}
        </Table>
      </InfiniteScroll>
      {showEmptyStateMessage && (
        <div className="sticky left-0">
          <EmptyStateMessage action={emptyState?.action} message={emptyState?.message} />
        </div>
      )}
    </>
  );
};

/* TODO
  - Split out InfiniteScroll from SortableTable.
  - Clean up pages sorting both server and clientside (ex: OrganizationListPage)
  - Split out TableHead?
*/
const SortableColumnShape = PropTypes.shape({
  className: PropTypes.string,
  renderCell: PropTypes.func,
  label: PropTypes.string,
  prop: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any,
  sticky: PropTypes.bool, // sticks on left side of table for horizontal scrolling
  sortable: PropTypes.bool,
  defaultSort: PropTypes.string,
  tableHeadMenu: PropTypes.func,
  tooltip: PropTypes.string,
  type: PropTypes.string, // set date type of the column, i.e. 'date'
});

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(SortableColumnShape).isRequired,
  emptyState: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    action: PropTypes.node,
  }),
  hasFilterFooter: PropTypes.bool,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  limitedView: PropTypes.bool, // only shows 5 items on the table
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  link: PropTypes.func, // handles react links
  onRowClick: PropTypes.func, // handles navigation for linking outside of react router
  tooltip: PropTypes.func,
  onLoadMore: PropTypes.func,
  onSort: PropTypes.func,
  sortProps: PropTypes.func
};

SortableTable.defaultProps = {
  emptyState: { message: '', action: null },
  hasMore: false,
  hasFilterFooter: false,
  limitedView: false,
  loading: false,
  link: null,
  onRowClick: null,
  onLoadMore: () => {},
  onSort: null,
  sortProps: null
};

export default SortableTable;
