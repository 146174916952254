"use strict";

module.exports = {
  IDLE_TIMOUT_DURATION_MINUTES: 1,
  IDLE_WARNING_HEADING: 'Idle Timeout',
  IDLE_WARNING_MESSAGE: function IDLE_WARNING_MESSAGE(remaining) {
    return "You will be signed out in ".concat(remaining, " seconds");
  },
  IDLE_STAY_BUTTON: 'Stay On',
  IDLE_SIGNOUT_BUTTON: 'Sign Out',
  IDLE_SIGNOUT_MESSAGE: 'You have been signed out due to inactivity. To continue your work, please sign back in.'
};