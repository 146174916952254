import { Icon, Link, List, ListItem, ListItemText, ListItemIcon, Typography } from '@material-ui/core';
import { DateHelper } from '@rss/common';
import { isEmpty } from 'lodash';

import PropTypes from 'prop-types';
import React from 'react';

const ExternalFiles = ({ question, values }) => {
  const { baseTemplateUrl } = question;

  return (
    <List className="mx-8 py-0">
      {!isEmpty(values) &&
        values.map((value) => (
          <div key={value.id}>
            <ListItem className="pl-0 text-justify text-14">
              <ListItemIcon>
                <Icon data-cy="Tab_completion_icon" color="primary">
                  launch
                </Icon>
              </ListItemIcon>
              <div>
                <Link
                  role="button"
                  component="a"
                  target="_blank"
                  variant="body1"
                  underline="none"
                  color="primary"
                  rel="noreferrer"
                  href={`${baseTemplateUrl}/${value.item.fileName}`}>
                  {value.item.fileName}
                </Link>
                <ListItemText secondary={`Date Accepted: ${DateHelper.formatDate(value.item.acceptedDate)}`} />
              </div>
            </ListItem>
          </div>
        ))}
      {isEmpty(values) && <Typography color="textSecondary">No Labels</Typography>}
    </List>
  );
};

ExternalFiles.propTypes = {
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.arrayOf(PropTypes.any),
};

ExternalFiles.defaultProps = {
  values: null,
};

export default ExternalFiles;
