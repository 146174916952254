import { Checkbox, FormControl, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { responseType } from './types/prop-types';

const FqCheckbox = React.memo(
  ({ disabled, error, onChange, options, questionKey, value, label, labelVariant, required, itemsDisplay }) => {
    const handleOnChange = (checked, option) => {
      const values = checked
        ? // pick key value from template, not from document which can be outdate by migration
          [...options?.filter((opt) => value.map((v) => v.value).includes(opt.value)), option]
        : [...value].filter((v) => v.value !== option.value); // check specific key , not the whole key: value
      onChange(values, questionKey);
    };

    return (
      <FormControl
        className={`&& m-8 flex ${labelVariant === 'long' && 'ml-24'}`}
        component="fieldset"
        error={error}
        required={required}>
        {labelVariant !== 'long' && (
          <FormLabel
            disabled={disabled}
            className={clsx('pre-line inline-block pb-2 text-black', disabled && 'text-grey-400')}>
            {label}
          </FormLabel>
        )}
        <FormGroup
          className={`${
            (itemsDisplay === 'row' && 'flex flex-1 flex-row sm:flex-row') || 'flex flex-col sm:flex-col'
          } `}>
          {options.map((option) => (
            <FormControlLabel
              classes={{ root: 'pl-8', label: 'pl-8' }}
              key={`${options.value}-${option.label}`}
              className="my-4 items-start"
              disabled={disabled}
              control={
                <Checkbox
                  classes={{ root: 'p-2' }}
                  color="primary"
                  className="-my-2"
                  id={`${option.value}_checkbox`}
                  checked={Array.isArray(value) && value.some((v) => v.value === option.value)}
                  onChange={(event) => handleOnChange(event.target.checked, option)}
                  value={option.value}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  },
);

FqCheckbox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape(responseType)).isRequired,
  onChange: PropTypes.func.isRequired,
  questionKey: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.shape(responseType)).isRequired,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  label: PropTypes.string,
  required: PropTypes.bool,
  itemsDisplay: PropTypes.string,
};

FqCheckbox.defaultProps = {
  disabled: false,
  error: false,
  label: '',
  labelVariant: 'default',
  required: false,
  itemsDisplay: 'col',
};

export default FqCheckbox;
