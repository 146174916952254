/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@material-ui/core';
import { AsYouType } from 'libphonenumber-js';
import { set } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import PhoneFormat from './PhoneFormat';

function initialValue(international, value) {
  if (international) {
    if (!value) {
      return '+1';
    }

    return value.charAt(0) !== '+' ? `+${value}` : value;
  }

  return value;
}
function PhoneNumberInput({ international, value, onChange, ...props }) {
  const phoneNumber = new AsYouType().input(initialValue(international, value));

  return (
    <TextField
      {...props}
      value={phoneNumber}
      variant="outlined"
      onChange={(event) => {
        const internalEvent = international
          ? set(event, 'target.value', new AsYouType().input(event.target.value))
          : event;
        onChange(internalEvent);
      }}
      InputProps={
        international
          ? undefined
          : {
              inputComponent: PhoneFormat,
            }
      }
    />
  );
}

PhoneNumberInput.propTypes = {
  international: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PhoneNumberInput.defaultProps = {
  value: '+1',
  international: false,
};

export default PhoneNumberInput;
