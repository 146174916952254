import React, { memo } from 'react';
import { Link, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  ...theme.mixins.text,
  linkButton: {
    padding: 0,
    textTransform: 'none',
    textAlign: 'left',
  },
  linkDisabled: {
    pointerEvents: 'none',
  },
}));

const Mailto = ({ disabled, email, variant }) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      size="small"
      className={`${classes.linkButton} ${disabled ? classes.linkDisabled : ''}`}
      onClick={(event) => event.stopPropagation()}
      underline="none"
      rel="noreferrer"
      target="_blank"
      variant={variant}
      href={disabled ? '#' : `mailto:${email}`}>
      <Typography align="left" className={`${classes.ellipsis} ${disabled ? classes.disabled : ''}`}>
        {email}
      </Typography>
    </Link>
  );
};

Mailto.propTypes = {
  disabled: PropTypes.bool,
  email: PropTypes.string,
  variant: PropTypes.oneOf(['body1', 'caption']),
};

Mailto.defaultProps = {
  disabled: false,
  email: null,
  variant: 'body1',
};

export default memo(Mailto);
