import { useEffect } from 'react';

const EVENT_NAME = 'idleTimeout';

function useIdleEvent(eventHandler) {
  useEffect(() => {
    window.addEventListener(EVENT_NAME, eventHandler);
    return () => window.removeEventListener(EVENT_NAME, eventHandler);
  });

  const dispatchIdleEvent = () => {
    const event = new Event(EVENT_NAME);
    window.dispatchEvent(event);
  };

  return { dispatchIdleEvent };
}

export default useIdleEvent;
