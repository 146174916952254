/* eslint-disable react/forbid-prop-types */
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

function StoreLocations({ sublocations, setLocation }) {
  const rooms = useMemo(() => {
    return Object.keys(sublocations || {}).reduce((acc, curr) => {
      return acc.concat({
        label: `${sublocations[curr][0].buildingName}, ${sublocations[curr][0].roomNumber}`,
        value: sublocations[curr][0].roomNumber,
        sublocations: sublocations[curr],
      });
    }, []);
  }, [sublocations]);
  const [room, setRoom] = useState(rooms[0]);
  const [sublocation, setSublocation] = useState(room ? room?.sublocations[0] : rooms[0].sublocations[0]);
  useEffect(() => setLocation(sublocation), [setLocation, sublocation]);

  return (
    <>
      <div className="grid flex-1 grid-cols-1 flex-wrap gap-16 sm:grid-cols-2 sm:gap-8">
        <FormControl variant="outlined">
          <InputLabel>Room</InputLabel>
          <Select
            label="Room"
            value={room}
            onChange={({ target: { value } }) => {
              setRoom(value);
              setSublocation(value.sublocations[0]);
            }}
            renderValue={(r) => r.label}>
            {rooms.map((r) => (
              <MenuItem key={r.value} value={r}>
                {r.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" disabled={!room}>
          <InputLabel>Sublocation</InputLabel>
          <Select
            label="sublocation"
            value={sublocation}
            onChange={({ target: { value } }) => setSublocation(value)}
            renderValue={(r) => r.name}>
            {room?.sublocations?.map((s) => (
              <MenuItem key={s._id} value={s}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}

StoreLocations.propTypes = {
  sublocations: PropTypes.objectOf(PropTypes.any).isRequired,
  setLocation: PropTypes.func.isRequired,
};

export default StoreLocations;
