import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import RichTextEditor from '../RichTextEditor';
import { QUESTION_VARIANT } from './Helper';

const FqRichText = ({
  disabled,
  error,
  label,
  variant,
  onChange,
  placeholder,
  questionKey,
  required,
  value,
  labelVariant,
}) => {
  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      {labelVariant !== 'long' && label && (
        <Typography color="textSecondary">
          {label} {required ? <span className="text-danger-500">*</span> : ''}
        </Typography>
      )}
      {error && <div className="text-danger-500">Please enter a response</div>}
      <div className={disabled || !onChange ? 'rounded border border-gray-100 p-8 text-gray-200' : ''}>
        <RichTextEditor
          onChange={disabled || !onChange ? null : (val) => onChange(val, questionKey)}
          placeholder={placeholder}
          value={value}
        />
      </div>
    </div>
  );
};

FqRichText.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  questionKey: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  labelVariant: PropTypes.oneOf(['default', 'long']),
};

FqRichText.defaultProps = {
  disabled: false,
  error: false,
  label: '',
  variant: QUESTION_VARIANT.STANDARD,
  required: false,
  placeholder: null,
  labelVariant: 'standard',
};

export default FqRichText;
