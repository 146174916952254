const tv4 = require('tv4');

const ASSESSMENT_STATUS = require('../constants/assessment-status.constant');

class TemplateApplicationWindow {
  constructor(opt) {
    this.startDate = null;
    this.endDate = null;
    this.limit = null;

    if (opt) {
      this.fillObject(opt);
    }
  }

  fillObject({ startDate = null, endDate = null, limit }) {
    this.startDate = startDate ? new Date(startDate) : startDate;
    this.endDate = endDate ? new Date(endDate) : endDate;
    this.limit = limit || null;
  }

  validate() {
    return tv4.validateMultiple(this, TemplateApplicationWindow.schema());
  }

  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'template.application-window.schema.json',
      additionalProperties: false,
      properties: {
        limit: { type: ['null', 'number'] },
        validateStatuses: { type: 'array', items: { enum: Object.keys(ASSESSMENT_STATUS) } },
        startDate: { type: ['null', 'string', 'object'] },
        endDate: { type: ['null', 'string', 'object'] },
      },
      required: Object.keys(new TemplateApplicationWindow()),
    };
  }
}

tv4.addSchema(TemplateApplicationWindow.schema());

module.exports = TemplateApplicationWindow;
