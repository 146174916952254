"use strict";

module.exports = {
  TRUE: {
    value: true,
    label: 'TRUE'
  },
  FALSE: {
    value: false,
    label: 'FALSE'
  },
  NOT_AVAILABLE: {
    value: 'N/A',
    label: 'N/A'
  },
  SKIP: {
    value: null,
    label: 'SKIP'
  }
};