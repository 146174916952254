import { Tooltip } from '@material-ui/core';
import { DateHelper } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

import { buildTimestamp, isMoreThanAWeekAgo } from '../helper/incident.helper';

export function RelativeTime({ dateString }) {
  const timestamp = buildTimestamp(dateString);
  return isMoreThanAWeekAgo(dateString) ? (
    <span className="italic text-grey-200">{timestamp}</span>
  ) : (
    <Tooltip
      arrow
      title={DateHelper.formatDate(dateString, DateHelper.DATETIME_FORMAT)}
      placement="top"
      leaveDelay={200}>
      <span className="italic text-grey-200">{timestamp}</span>
    </Tooltip>
  );
}

RelativeTime.propTypes = {
  dateString: PropTypes.string.isRequired,
};
