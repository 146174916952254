import { Button, Icon, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const CheckoutConfirmation = ({ onReset }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center py-12">
        <Icon className="text-40 text-success-500">check_circle</Icon>
        <Typography variant="h6" className="mx-12">
          Your payment has been processed.
        </Typography>
      </div>

      <Button size="large" variant="contained" color="primary" className="mt-20" onClick={() => onReset()}>
        Start a new order
      </Button>
    </div>
  );
};

CheckoutConfirmation.propTypes = {
  onReset: PropTypes.func.isRequired,
};

export default CheckoutConfirmation;
