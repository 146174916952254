import { Chip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import ProfileAvatar from './ProfileAvatar';

function CommentItem({ actions, children, condensed, isPrivate, subtitle, title, user }) {
  const defaultTitle = `${user.firstName} ${user.lastName}`;

  return (
    <div
      className="my-24 flex w-full items-start"
      // HACK: Prevents page breaks from cutting content in half when printing
      style={{ breakInside: 'avoid' }}>
      {!condensed && (
        <div className="mr-12">
          <ProfileAvatar user={user} />
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex items-start">
          <div className="mb-8 flex flex-wrap">
            <Typography variant="subtitle2" component="div" className="mr-12">
              {title || defaultTitle}
              {isPrivate && <Chip label="Private" size="small" variant="outlined" className="ml-16" />}
            </Typography>
            {subtitle && (
              <Typography variant="body2" color="textSecondary" className="text-grey-400">
                {subtitle}
              </Typography>
            )}
          </div>
          {actions && (
            <Typography className="ml-12 -mt-14 text-16" component="span">
              {actions}
            </Typography>
          )}
        </div>
        <div className="w-full whitespace-normal break-words">{children}</div>
      </div>
    </div>
  );
}

CommentItem.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
  condensed: PropTypes.bool,
  isPrivate: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

CommentItem.defaultProps = {
  actions: null,
  condensed: false,
  isPrivate: false,
  subtitle: '',
  title: '',
};

export default CommentItem;
