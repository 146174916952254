/* eslint-disable no-shadow */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import BuildingSearch from './BuildingSearch';
import FloorSelect from './FloorSelect';

const BuildingFloorSearch = ({ autoFocus, buildingId, buildingName, floorId, floorName, onChange }) => {
  const [building, setBuilding] = useState({ buildingId, buildingName });
  const [floor, setFloor] = useState({ floorId, floorName });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onChange({ ...building, ...floor }), [building, floor]);

  return (
    <div className="grid flex-1 grid-cols-1 flex-wrap gap-16 sm:grid-cols-2 sm:gap-8">
      <BuildingSearch
        floatingText="Search Building"
        autoFocus={autoFocus}
        onSelect={(selected) => {
          selected && setBuilding({ buildingId: selected.buildingId, buildingName: selected.primaryName });
        }}
        onClear={() => {
          setBuilding({ buildingId: null, buildingName: null });
          setFloor({ floorId: null, floorNumber: null });
        }}
        value={building.buildingName}
        hintText="Search Building"
      />
      <FloorSelect
        buildingId={building.buildingId}
        isDisabled={!building.buildingId && !building.buildingName}
        floatingText="Search Floor"
        onSelect={({ floorId, floorName }) => setFloor({ floorId, floorName })}
        selectedFloor={floor}
      />
    </div>
  );
};

BuildingFloorSearch.propTypes = {
  autoFocus: PropTypes.bool,
  buildingId: PropTypes.string,
  buildingName: PropTypes.string,
  floorId: PropTypes.string,
  floorName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

BuildingFloorSearch.defaultProps = {
  autoFocus: false,
  buildingId: '',
  buildingName: '',
  floorId: '',
  floorName: '',
};

export default BuildingFloorSearch;
