import { ApolloProvider } from '@apollo/client';
import FuseTheme from '@fuse/core/FuseTheme';
import { ServerStyleSheets } from '@material-ui/core/styles';
import { saveAs } from 'file-saver';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import Provider from 'react-redux/es/components/Provider';
import { Router } from 'react-router-dom';

import history from '../../@history/@history';
import { ReadOnlyClient } from '../services/apollo';
import config from '../config';
import store from '../store';

function appCss() {
  return [...document.styleSheets]
    .map((styleSheet) => {
      try {
        return [...styleSheet.cssRules]
          .map((rule) => {
            // This allows us to pull from icon cdn, otherwise the icons are broken.
            if (rule.cssText.includes('icon')) {
              return null;
            }

            return rule.cssText;
          })
          .join('');
      } catch (e) {
        return null;
      }
    })
    .filter(Boolean)
    .join('\n');
}

function template(html, css) {
  return `
    <html lang="en">
      <style>${css}</style>
      <style>${appCss()}</style>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <body>${html}</body>
    </html>`;
}

async function printable(filename, html, css, options = {}) {
  const response = await fetch(`${config.PRINTABLE_URL}/pdf/from/html`, {
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      template: template(html, css),
      options,
    }),
    headers: {
      'content-type': 'application/json',
    },
  });

  const blob = await response.blob();
  saveAs(blob, filename);
}

export function printPDFFromHTML(html, { filename = 'document.pdf', options }) {
  const sheets = new ServerStyleSheets();

  sheets.collect(html);
  const css = sheets.getStyleElement(html);
  return printable(filename, html, css, options);
}

export function printPDFFromComponent(component, { filename = 'document.pdf', options }) {
  const html = ReactDOMServer.renderToStaticMarkup(
    <Provider store={store}>
      <Router history={history}>
        <ApolloProvider client={ReadOnlyClient}>
          <FuseTheme>{component}</FuseTheme>
        </ApolloProvider>
      </Router>
    </Provider>,
  );

  return printPDFFromHTML(html, { filename, options });
}

export function printPDFFromComponentForReview(component) {
  const html = ReactDOMServer.renderToStaticMarkup(
    <Provider store={store}>
      <Router history={history}>
        <ApolloProvider client={ReadOnlyClient}>
          <FuseTheme>{component}</FuseTheme>
        </ApolloProvider>
      </Router>
    </Provider>,
  );

  const sheets = new ServerStyleSheets();

  sheets.collect(html);
  const css = sheets.getStyleElement(html);
  return template(html, css);
}

// convert image to base64
export function toDataURL(url) {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
}
