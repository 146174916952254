import { useMutation } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useDrive } from '@risk-and-safety/drive-client';
import { ResolutionEvent, RESOLUTION_STATUS } from '@risk-and-safety/inspect-v2-common';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { ButtonProgress, Thumbnail, RichTextEditor, Upload } from '../../../components';
import config from '../../../config';
import { useIdleEvent, useProfile } from '../../../hooks';
import { ADD_RESOLUTION_EVENT, GET_INSPECTION_REPORT, GET_RESOLUTIONS } from '../../graphql/query';

const RESOLVE_OPTIONS = {
  [RESOLUTION_STATUS.RESOLVED]: (
    <>
      <Typography className="inline font-bold">{startCase(RESOLUTION_STATUS.RESOLVED.toLowerCase())} </Typography>-
      Accept the response provided by the responsible person(s) for this incident.
    </>
  ),
  [RESOLUTION_STATUS.NOT_RESOLVED]: (
    <>
      <Typography className="inline font-bold">{startCase(RESOLUTION_STATUS.NOT_RESOLVED.toLowerCase())} </Typography>-
      Return the incident to the responsible person(s) for further action.
    </>
  ),
  [RESOLUTION_STATUS.NO_FURTHER_ACTION]: (
    <>
      <Typography className="inline font-bold">
        {startCase(RESOLUTION_STATUS.NO_FURTHER_ACTION.toLowerCase())}{' '}
      </Typography>
      - The incident has not been resolved, but no additional response is required.
    </>
  ),
};

export function ResolutionDialog({
  currentStatus,
  enableStatusOptions,
  reportId,
  resolutionId,
  requiresVerification,
  variant,
  onClose,
}) {
  const { profile, loading: profileLoading } = useProfile();
  const [errors, setErrors] = useState({});
  const [resolution, setResolution] = useState(
    new ResolutionEvent({
      status: variant === 'comment' ? RESOLUTION_STATUS.COMMENT : RESOLUTION_STATUS.RESOLVED,
      createdBy: profile,
    }),
  );
  const [stagedAttachments, setStagedAttachments] = useState([]);

  const { attach, upload } = useDrive('inspect-v2', {
    graphUrl: `${config.GRAPH_URL}/graphql`,
    operations: { presign: 'presignInspectResolutionAttachment' },
  });

  const [addResolutionEvent, { loading }] = useMutation(ADD_RESOLUTION_EVENT, {
    refetchQueries: ({
      data: {
        addResolutionEvent: { status },
      },
    }) => {
      const refetchQueries = [{ query: GET_RESOLUTIONS, variables: { reportId } }];
      if ([RESOLUTION_STATUS.RESOLVED, RESOLUTION_STATUS.NO_FURTHER_ACTION].includes(status)) {
        refetchQueries.push({ query: GET_INSPECTION_REPORT, variables: { id: reportId } });
      }
      return refetchQueries;
    },
    onCompleted: onClose,
    onError: () => setErrors({ ...errors, saveError: 'Please refresh your browser and try again.' }),
  });

  useIdleEvent(() => {
    if (variant !== 'comment') {
      return;
    }
    const validResolution = new ResolutionEvent({
      ...resolution,
      attachments: stagedAttachments.map((attachment) => ({
        name: attachment.key,
        originalName: attachment.file.name,
        type: attachment.file.type.includes('image') ? 'IMAGE' : 'DOCUMENT',
      })),
    });
    addResolutionEvent({ variables: { resolutionId, reportId, event: validResolution } });
  });

  if (profileLoading) return <FuseLoading />;
  return (
    <Dialog open fullWidth onClose={onClose}>
      <AppBar position="static" className="rounded-t-2xl">
        <Toolbar className="flex w-full justify-between">
          <Typography variant="h6" color="inherit">
            {variant === 'comment' ? 'Comment' : 'Resolve Incident'}
          </Typography>

          <IconButton label="Close" color="inherit" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        onSubmit={async (event) => {
          event.preventDefault();

          try {
            const validResolution = new ResolutionEvent({
              ...resolution,
              attachments: stagedAttachments.map((attachment) => ({
                name: attachment.key,
                originalName: attachment.file.name,
                type: attachment.file.type.includes('image') ? 'IMAGE' : 'DOCUMENT',
              })),
            }).validate();

            const isCommentRequired =
              variant === 'comment' && !validResolution.comment && !validResolution.attachments.length;

            if (isCommentRequired) {
              setErrors({ ...errors, saveError: 'Please include a comment or an attachment.' });
              return;
            }

            addResolutionEvent({ variables: { resolutionId, reportId, event: validResolution } });
            await Promise.all(stagedAttachments.map(upload));
          } catch (validationError) {
            setErrors(
              validationError.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
              }, {}),
            );
          }
        }}
      >
        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
          {variant === 'status' && !enableStatusOptions && (
            <div className="mb-16">
              <ul className="list-disc pl-16">
                <li>Add comments or attach files, and select ‘save’ to submit your resolution.</li>
                {requiresVerification && (
                  <li className="my-4">
                    This incident requires verification. If it is not accepted, you will be notified to resolve the
                    incident again.
                  </li>
                )}
              </ul>
            </div>
          )}

          {variant === 'status' && enableStatusOptions && (
            <FormControl component="fieldset" className="mb-8" error={errors?.status}>
              <RadioGroup
                row
                aria-label="resolution-status"
                name="resolution-status"
                className="mb-8 flex flex-1 flex-col sm:flex-row"
                value={resolution.status}
                onChange={(event) => {
                  setResolution({ ...resolution, status: event.target.value });
                  setErrors({});
                }}
              >
                <Typography className="mb-8">
                  Select an incident status from the options below. You may also add comments and attachments.
                </Typography>
                {Object.keys(RESOLVE_OPTIONS)
                  .filter((key) => key !== currentStatus)
                  .map((key) => (
                    <FormControlLabel
                      className="mb-8"
                      key={key}
                      label={RESOLVE_OPTIONS[key]}
                      value={key}
                      control={<Radio className="flex self-start pt-2" />}
                    />
                  ))}
              </RadioGroup>
              {errors?.status && <FormHelperText>Please select one.</FormHelperText>}
            </FormControl>
          )}

          <RichTextEditor
            value={resolution.comment || ''}
            placeholder="Add a comment..."
            onChange={(value) => {
              setResolution({ ...resolution, comment: value });
              setErrors({});
            }}
          />

          {Boolean(stagedAttachments.length) && (
            <div className="flex w-full flex-wrap gap-8">
              {stagedAttachments.map((attachment) => (
                <Thumbnail
                  key={attachment.key}
                  attachment={attachment}
                  onDelete={() => setStagedAttachments(stagedAttachments.filter(({ key }) => key !== attachment.key))}
                />
              ))}
            </div>
          )}
        </DialogContent>

        <DialogActions className="flex-end p-16">
          <div className="flex w-full items-center justify-between px-16">
            <Upload
              disabled={loading}
              variant="icon"
              buttonLabel="Add attachments"
              onUpload={async (files) => {
                const presignedAttachments = await Promise.all(
                  files.map((file) => attach('RESOLUTION_ATTACHMENT', file, { reportId, resolutionId })),
                );

                setStagedAttachments(stagedAttachments.concat(presignedAttachments));
              }}
            />
            <div className="flex flex-row">
              <Button className="mr-5" variant="contained" onClick={onClose}>
                Cancel
              </Button>
              <ButtonProgress type="submit" loading={loading}>
                Save
              </ButtonProgress>
            </div>
          </div>
        </DialogActions>
      </form>
      {errors?.saveError && (
        <Typography className="p-16" color="error">
          {errors.saveError}
        </Typography>
      )}
    </Dialog>
  );
}

ResolutionDialog.propTypes = {
  currentStatus: PropTypes.oneOf(Object.values(RESOLUTION_STATUS)).isRequired,
  enableStatusOptions: PropTypes.bool,
  reportId: PropTypes.string.isRequired,
  resolutionId: PropTypes.string.isRequired,
  requiresVerification: PropTypes.bool,
  variant: PropTypes.oneOf(['comment', 'status']),
  onClose: PropTypes.func.isRequired,
};

ResolutionDialog.defaultProps = {
  enableStatusOptions: false,
  requiresVerification: false,
  variant: 'comment',
};
