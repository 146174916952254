"use strict";

module.exports = {
  // deprecated
  ACCOUNT_MANAGER: 'Account Manager',
  AUTHORIZED_USER: 'Authorized User',
  BIOLIBRARY_ADMIN: 'Bio Library Admin',
  CLI_ADMIN: 'CLI_ADMIN',
  CONTROL_AREA_ADMIN: 'Control Area Admin',
  DELEGATE: 'Delegate',
  DEVELOPER: 'Developer',
  EHS_ADMIN: 'EHS Admin',
  LAB_MANAGER: 'LAB_MANAGER',
  PROCEDURES_ADMIN: 'Procedures Admin',
  PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin',
  AGENDA_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (AGENDA)',
  FIELD_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (FIELD)',
  LOTO_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (LOTO)',
  MEDICAL_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (MEDICAL)',
  SPH_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (SPH)',
  SOP_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (SOP)',
  WPVP_PROCEDURES_TEMPLATE_ADMIN: 'Procedures Template Admin (WPVP)',
  AGENDA_PROCEDURES_ADMIN: 'Procedures Admin (AGENDA)',
  FIELD_PROCEDURES_ADMIN: 'Procedures Admin (FIELD)',
  LOTO_PROCEDURES_ADMIN: 'Procedures Admin (LOTO)',
  MEDICAL_PROCEDURES_ADMIN: 'Procedures Admin (MEDICAL)',
  SPH_PROCEDURES_ADMIN: 'Procedures Admin (SPH)',
  SOP_PROCEDURES_ADMIN: 'Procedures Admin (SOP)',
  WPVP_PROCEDURES_ADMIN: 'Procedures Admin (WPVP)',
  RESPONSIBLE_PERSON: 'Responsible Person',
  AUTHORIZED_PURCHASER: 'AUTHORIZED_PURCHASER',
  SLIP_RESISTANT_ADMIN: 'Slip Resistant Admin',
  GROUP_MANAGER: 'GROUP_MANAGER',
  SERVICE_DESK: 'Service Desk',
  SLIP_RESISTANT_EHS_ADMIN: 'SLIP_RESISTANT_EHS_ADMIN',
  SAFETY_COORDINATOR: 'Safety Coordinator',
  SYSTEM_CONTROL_AREA_ADMIN: 'System Control Area Admin',
  BIOSAFETY_ADMIN: 'Biosafety Admin',
  IBC_MEMBER: 'IBC Member',
  LOCATION_MANAGER: 'Location Manager',
  NFPA_ADMIN: 'NFPA Admin',
  PROFILE_ADMIN: 'Profile Admin',
  BSAS_ADMIN: 'BSAS Admin',
  ANALYTICS_ADMIN: 'Analytics Admin',
  CHEMICAL_ADMIN: 'Chemical Admin',
  CHEMICAL_ADMIN_READ_ONLY: 'Chemical Admin (Read Only)',
  CHEMICAL_INFORMATICS: 'Chemical Informatics',
  COLLECTIONS_ADMIN: 'Collections Admin',
  CORE_SERVICES_ADMIN: 'Core Services Admin',
  EHS_READ: 'EHS Read',
  // deprecated, use LOCATION_MANAGER
  FACILITIES_MANAGER: 'Facilities Manager',
  MEMBER: 'Authorized User',
  PRINCIPAL_INVESTIGATOR: 'Principal Investigator',
  RADIATION_ADMIN: 'Radiation Admin',
  RADIATION_EMAIL_LIST: 'Radiation Email List',
  RADIATION_INSPECTOR: 'Radiation Inspector',
  RADIATION_SAFETY_COMMITTEE_MEMBER: 'Radiation Safety Committee Member',
  ROUTER: 'Router',
  SYSTEM_OPERATIONS: 'System Operations',
  NOTIFICATION_MANAGER: 'Notification Manager',
  INITIAL_REVIEWER: 'Initial Reviewer',
  FINAL_REVIEWER: 'Final Reviewer',
  DRONES_TENANT_ADMIN: 'DRONES_TENANT_ADMIN',
  DRONES_ADMIN: 'DRONES_ADMIN',
  WASTE_ADMIN: 'Waste Admin',
  WASTE_STAFF: 'Waste Yard Staff',
  ACCOUNT_ADMIN: 'Account Admin',
  USER_ADMIN: 'User Admin',
  ROLE_ADMIN: 'Role Admin',
  FACILITY_ADMIN: 'Facility Admin'
};