import { Typography } from '@material-ui/core';
import FuseChipSelect from '@fuse/core/FuseChipSelect';
import { FqType } from '@rss/common';
import { isEmpty, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { convertStringArrayToOptionsArray, hasErrors, QUESTION_VARIANT } from './Helper';
import { recipientType } from './types/prop-types';

const convertRecipientArrayToOptionsArray = (recipients, valueType) => {
  if (valueType === 'email') {
    return uniq(recipients ?? [], 'email')?.map((recipient) => ({
      label: recipient?.email,
      value: recipient?.email,
    }));
  }

  return [];
};

const convertOptionsArrayToRecipients = (selected, valueType) => {
  const array = Array.isArray(selected) ? selected : [selected];

  if (valueType === 'email') {
    return array?.map(({ value }) => ({
      email: value,
    }));
  }

  return [];
};

const FqRecipients = ({
  autoFocus,
  disabled,
  value,
  options,
  label,
  labelVariant,
  limit,
  onChange,
  valueType,
  questionKey,
  required,
  error,
  addNew,
  variant,
  placeholder,
}) => {
  const selectOptions = convertRecipientArrayToOptionsArray(options, valueType);
  const selectedValue = convertStringArrayToOptionsArray(
    convertRecipientArrayToOptionsArray(value, valueType),
    selectOptions,
  )?.filter((v) => Boolean(v?.value));

  // if limit is not provided, means multi-select is true
  const isMulti = !limit || limit > 1;

  const [errors, setErrors] = useState([]);

  const handleChange = (selected) => {
    const selectedParseValues = convertOptionsArrayToRecipients(selected, valueType);
    const validationErrors = hasErrors(selectedParseValues, { type: FqType.RECIPIENTS, valueType });
    setErrors(validationErrors);
    onChange(selectedParseValues, questionKey);
  };

  return (
    <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      <FuseChipSelect
        autoFocus={autoFocus}
        isDisabled={disabled}
        required={required}
        error={error || !isEmpty(errors)}
        aria-label={label}
        value={selectedValue}
        placeholder={placeholder}
        onChange={(val) => handleChange(val)}
        blur={(val) => handleChange(val)}
        textFieldProps={{
          label: labelVariant === 'long' ? null : label,
          disabled,
          InputLabelProps: {
            shrink: true,
          },
          variant: 'outlined',
        }}
        options={selectOptions}
        isMulti={isMulti}
        variant={addNew ? 'create' : 'fixed'}
        isSearchable
        isClearable={!isEmpty(selectedValue)}
        formatCreateLabel={(inputValue) => (
          <span>
            Add <strong>{inputValue}</strong> as email recipient.
          </span>
        )}
      />
      {!disabled && !error && !isEmpty(errors) && (
        <Typography className="mb-8 mt-4" color="error">
          {errors.join(' ')}
        </Typography>
      )}
    </div>
  );
};

FqRecipients.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  limit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(recipientType))]),
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape(recipientType))]).isRequired,
  valueType: PropTypes.oneOf(['email']),
  addNew: PropTypes.bool,
  questionKey: PropTypes.string,
  required: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  placeholder: PropTypes.string,
};

FqRecipients.defaultProps = {
  autoFocus: false,
  disabled: false,
  error: false,
  label: '',
  labelVariant: 'default',
  limit: null,
  value: {},
  valueType: 'email',
  questionKey: null,
  required: false,
  addNew: false,
  variant: QUESTION_VARIANT.STANDARD,
  placeholder: 'Enter email',
};

export default FqRecipients;
