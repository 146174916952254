import { CATALOG_TYPE, ORDER_STATUS } from '@risk-and-safety/library';

export const ORDER_STATUS_MAP = {
  [ORDER_STATUS.ORDER_SUBMITTED]: 'orange',
  [ORDER_STATUS.CANCELED]: 'error',

  [ORDER_STATUS.PROCESSING_ORDER]: 'purple',

  [ORDER_STATUS.READY_FOR_PICK_UP]: 'teal',
  [ORDER_STATUS.READY_FOR_DELIVERY]: 'teal',

  [ORDER_STATUS.PICKED_UP]: 'purple',
  [ORDER_STATUS.DELIVERED]: 'purple',
  [ORDER_STATUS.SELF_CHECKOUT]: 'purple',
  [ORDER_STATUS.IN_STORE]: 'purple',
};

export const BARCODE_SCAN_ERROR = {
  NOT_FOUND: 'Oops! Not a part of this store',
  ENOUGH: 'This product already has enough items',
  WRONG_PRODUCT: 'Does not match any products ordered',
  INVALID_BARCODE: 'Not a valid barcode pattern',
  DUPLICATE: 'Already scanned',
  NOT_AVAILABLE: 'Not available for purchase',
  SCAN_ITEM_BARCODE:
    // eslint-disable-next-line max-len
    'This product has barcoded inventory items. Please scan the specific inventory item barcode instead of the product sku',

  // for scan of purchasing code
  INVALID_PURCHASING_CODE: 'The Purchasing Code you have scanned is no longer valid. Please scan a new code.',
  INVALID_ACCOUNT:
    // eslint-disable-next-line max-len
    "Your account is missing information needed to process this transaction. Please proceed to 'View Accounts' on your own device to add this information",
  INVALID_QUANTITY:
    'There is no available stock to checkout. Please review pending orders to see if any items are on hold.',
};

export const MAX_QUANTITY_SERVICE = 10;
export const SCAN_CHECKOUT_STEPS = ['Scan Items', 'Checkout', 'Order Complete'];

export const checkForValidScannedItem = (inventoryItem, product, barcode, storeSalesTax, items = []) => {
  if (items?.find((i) => i.barcode === barcode)) {
    return { invalidItem: { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.DUPLICATE } };
  }

  if (product?._id) {
    if (product.catalog !== CATALOG_TYPE.SERVICES && product.barcodeRequired === true) {
      return { invalidItem: { barcode: product.sku, error: BARCODE_SCAN_ERROR.SCAN_ITEM_BARCODE } };
    }
    if (product?.availableStock <= 0 && product.barcodeRequired === false) {
      return { invalidItem: { barcode: product.sku, error: BARCODE_SCAN_ERROR.INVALID_QUANTITY } };
    }
    return {
      validItem: {
        barcode: product.sku,
        product: { ...product, salesTax: storeSalesTax },
      },
    };
  }

  if (!inventoryItem?._id) {
    return { invalidItem: { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.NOT_FOUND } };
  }

  if ('availableForSale' in inventoryItem.product && !inventoryItem.product.availableForSale) {
    return { invalidItem: { _id: barcode, barcode, error: BARCODE_SCAN_ERROR.NOT_AVAILABLE } };
  }
  return {
    validItem: {
      ...inventoryItem,
      product: { ...inventoryItem.product, salesTax: storeSalesTax },
    },
  };
};

export const getOrderTax = (order) => {
  const items = order?.items || [];
  return items.reduce((acc, curr) => {
    if (curr.product.taxExempt) {
      return acc;
    }

    return acc + curr.quantity * curr.product.price * curr.salesTax;
  }, 0);
};

export function generateOrderId() {
  const d = new Date();
  return `${(d.getMonth() + 1).toString().padStart(2, '0')}-${d
    .getDate()
    .toString()
    .padStart(2, '0')}-${d.getFullYear()}-${Math.random().toString(16).slice(-6)}`;
}
