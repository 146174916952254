import {
  AppBar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Toolbar,
  Typography,
  ThemeProvider,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { selectMainTheme } from '../store/fuse/settingsSlice';

const DialogTemplate = ({
  color,
  content,
  contentScrollable,
  dividers,
  error,
  loading,
  maxWidth,
  open,
  primaryActions,
  secondaryActions,
  title,
  titleContent,
}) => {
  const [openDialog, setOpenDialog] = useState(open);
  const theme = useTheme();
  const isMobileResolution = useMediaQuery(theme.breakpoints.down('xs'));
  const mainTheme = useSelector(selectMainTheme);

  useEffect(() => setOpenDialog(open), [open]);

  return (
    <ThemeProvider theme={mainTheme}>
      <Dialog
        open={openDialog}
        disableBackdropClick
        disableEscapeKeyDown
        disableRestoreFocus
        fullScreen={isMobileResolution}
        onClose={() => setOpenDialog(false)}
        aria-label={title || 'dialog-title'}
        scroll="paper"
        fullWidth
        maxWidth={maxWidth}
        classes={{
          paper: 'overflow-y-visible',
        }}>
        {title && (
          <AppBar position="static" color={color} elevation={1} className="rounded-t-2xl">
            <Toolbar className="flex w-full justify-between pl-24">
              <Typography variant="h6" color="inherit">
                {title}
              </Typography>
              {loading && <CircularProgress color="inherit" size={25} />}
            </Toolbar>
          </AppBar>
        )}
        {titleContent && <DialogTitle>{titleContent}</DialogTitle>}

        <DialogContent
          classes={{
            root: clsx(content ? 'py-16' : 'py-0', contentScrollable && 'overflow-y-visible'),
          }}
          dividers={dividers}>
          {content}
        </DialogContent>
        <DialogActions className="justify-between p-8 py-16">
          {error && (
            <FuseAnimate delay={500}>
              <Typography variant="body1" className="ml-24 mt-8 mb-16 text-left" color="error">
                {error}
              </Typography>
            </FuseAnimate>
          )}
          <div>{secondaryActions}</div>
          <div className="flex inline-flex space-x-16 px-16">{primaryActions}</div>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

DialogTemplate.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  content: PropTypes.node,
  contentScrollable: PropTypes.bool,
  dividers: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
  open: PropTypes.bool,
  primaryActions: PropTypes.node,
  secondaryActions: PropTypes.node,
  title: PropTypes.string,
  titleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

DialogTemplate.defaultProps = {
  color: 'primary',
  content: null,
  contentScrollable: false,
  dividers: false,
  error: false,
  loading: null,
  maxWidth: 'sm',
  open: false,
  primaryActions: null,
  secondaryActions: null,
  title: null,
  titleContent: null,
};

export default DialogTemplate;
