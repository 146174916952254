import { Icon, Typography } from '@material-ui/core';
import React from 'react';

import FuseAnimate from '@fuse/core/FuseAnimate';

const NotFound = () => (
  <div className="flex flex-1 flex-col items-center justify-center p-16">
    <div className="space-y-16 text-center">
      <FuseAnimate animation="transition.expandIn" delay={100}>
        <Typography variant="h1" className="font-medium text-gray-500">
          <Icon className="-mb-8 text-96" color="error">
            not_listed_location
          </Icon>
          404
        </Typography>
      </FuseAnimate>

      <FuseAnimate delay={500}>
        <Typography variant="h5" color="textSecondary">
          We can&apos;t seem to find the page you&apos;re looking for.
        </Typography>
      </FuseAnimate>

      <FuseAnimate delay={500}>
        <Typography color="textSecondary">
          If you need help, please contact <a href="mailto:service@riskandsafety.com">service@RiskandSafety.com</a>.
        </Typography>
      </FuseAnimate>
    </div>
  </div>
);

export default NotFound;
