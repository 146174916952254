/* eslint-disable react/jsx-boolean-value */
import { useLazyQuery } from '@apollo/client';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Fab, Icon, LinearProgress, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Store, STORE_PERMISSION } from '@risk-and-safety/library';
import { ObjectId } from 'bson';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import numeral from 'numeral';

import HeaderSearch from '../components/HeaderSearch';
import { PRODUCTS } from '../graphql/query';
import { Header } from '../../components';
import config from '../../config';
import { useAdvancedSearch } from '../../hooks';

const SEARCH_DEFAULT = {
  keyword: '',
};
function ProductListPage({ store }) {
  const history = useHistory();

  const { advancedSearch, setAdvancedSearch, advancedSearchQueryString } = useAdvancedSearch(SEARCH_DEFAULT);

  const { control, getValues } = useForm({
    mode: 'onChange',
    defaultValues: { ...SEARCH_DEFAULT, ...advancedSearch },
  });

  const [getProducts, { data, fetchMore, refetch }] = useLazyQuery(PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  });

  // TODO: fix useless effect and lazy query above
  useEffect(() => {
    getProducts({ variables: { ...advancedSearch, storeId: store._id, offset: 0, limit: config.LIMIT } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store._id]);

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns: useMemo(
      () => [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Product Number', accessor: 'productNumber' },
        { Header: 'Price', accessor: 'price', Cell: ({ value }) => numeral(value).format('$0,0.00') },
      ],
      [],
    ),
    data: useMemo(() => data?.products || [], [data]),
  });

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        contentCard: 'overflow-hidden',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">shopping_cart</Icon>}
          title="Products"
          backArrow={{
            link: `../${store._id}`,
            name: 'Store',
          }}
          search={
            <HeaderSearch
              control={control}
              onChange={() => {
                setAdvancedSearch({ ...advancedSearch, ...getValues() });
                refetch({ ...advancedSearch, ...getValues() });
              }}
              placeholder="Search by name, product number"
            />
          }
        />
      }
      content={
        <FuseScrollbars className="flex-grow overflow-x-auto">
          <InfiniteScroll
            hasMore={data?.products?.length > 0 && data?.products?.length % config.LIMIT === 0}
            loadMore={() => fetchMore({ variables: { offset: data?.products?.length } })}
            useWindow={false}
            loader={<LinearProgress className="clear-both" key={0} />}>
            <Table stickyHeader className="min-w-xl" {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow className="h-48 sm:h-64" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell className="p-4 md:p-16" {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className="h-48 cursor-pointer"
                      hover
                      onClick={() => history.push(`product/${row.original._id}/detail?${advancedSearchQueryString}`)}
                      {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell className="p-4 md:p-16" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </InfiniteScroll>
          {store?.permissions?.includes(STORE_PERMISSION.MANAGE) && (
            <Fab
              color="primary"
              onClick={() => history.push(`product/${new ObjectId()}/detail/add?${advancedSearchQueryString}`)}
              aria-label="Add"
              className="absolute bottom-16">
              <Icon>add</Icon>
            </Fab>
          )}
        </FuseScrollbars>
      }
    />
  );
}

ProductListPage.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default ProductListPage;
