const tv4 = require('tv4');
const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const Product = require('./product');
class Substance {
  constructor(opt) {
    this._id = null;
    this.name = null;
    this.vendor = null;
    this.url = null;
    this.products = [];
    this.synonyms = [];
    this.comments = null;
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.name = opt.name || null;
    this.vendor = opt.vendor || null;
    this.url = opt.url || null;
    this.products = Array.isArray(opt.products) ? opt.products.map(product => new Product(product)) : [];
    this.synonyms = opt.synonyms || [];
    this.comments = opt.comments || null;
  }
  validate() {
    return tv4.validateMultiple(this, Substance.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'substance.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: ['object', 'null']
        },
        name: {
          type: ['string', 'null']
        },
        vendor: {
          type: ['string', 'null']
        },
        url: {
          type: ['string', 'null']
        },
        products: {
          type: 'array',
          item: {
            $ref: 'product.schema.json'
          }
        },
        synonyms: {
          type: 'array',
          item: {
            type: 'string'
          }
        },
        comments: {
          type: ['string', 'null']
        }
      },
      required: Object.keys(this)
    };
  }
}
tv4.addSchema(Product.schema());
module.exports = Substance;