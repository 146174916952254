/* eslint-disable react/jsx-props-no-spreading */
// TODO: Component is deprecated and need to remove once all of its instances are replaced by DialogTemplate.
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const CONFIRMED = true;
const CANCELLED = false;

const styles = (theme) => ({
  dialogContent: {
    color: theme.colors.grey[400],
    fontSize: theme.spacing(2),
  },
  actionButton: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    backgroundColor: theme.palette.danger,
    '&:hover': {
      backgroundColor: theme.palette.danger,
    },
  },
});

const ConfirmationDialog = ({ classes, closeAction, onClose, title, message, isDelete, primaryAction, ...other }) => {
  const getActionLabel = () => {
    if (primaryAction) {
      return primaryAction;
    }

    return isDelete ? 'Delete' : 'Confirm';
  };
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" aria-label="alert-dialog-title" {...other}>
      <DialogTitle data-cy="e2e-confirmationDialogTitle" id="confirmation-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent id="alert-dialog-description" className={classes.dialogContent}>
        {message}
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="e2e-alert-cancel-btn"
          variant="contained"
          onClick={() => {
            onClose(CANCELLED);
          }}
          className={classes.actionButton}>
          {closeAction}
        </Button>
        <Button
          data-cy="e2e-alert-confirm-btn"
          onClick={() => onClose(CONFIRMED)}
          autoFocus
          variant="contained"
          color="primary"
          className={`${classes.actionButton} ${isDelete ? classes.deleteButton : ''}`}>
          {getActionLabel()}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isDelete: PropTypes.bool,
  primaryAction: PropTypes.string,
  closeAction: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  message: '',
  isDelete: false,
  primaryAction: '',
  closeAction: 'Cancel',
};

export default withStyles(styles)(ConfirmationDialog);
