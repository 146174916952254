"use strict";

module.exports = {
  COMPLIANT: 'COMPLIANT',
  IN_PROGRESS: 'IN_PROGRESS',
  NA: 'NA',
  NO_FURTHER_ACTION: 'NO_FURTHER_ACTION',
  NOT_OBSERVED: 'NOT_OBSERVED',
  NOT_RESOLVED: 'NOT_RESOLVED',
  READY_FOR_VERIFICATION: 'READY_FOR_VERIFICATION',
  RESOLVED: 'RESOLVED'
};