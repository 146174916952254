import { Avatar, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { useProfile } from '../hooks';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
}));

const ProfileAvatar = ({ user }) => {
  const { profile } = useProfile();
  const classes = useStyles();

  const firstName = user?.firstName || profile.firstName;
  const lastName = user?.lastName || profile.lastName;

  return (
    <Avatar className={classes.avatar}>
      {firstName[0]}
      {lastName[0]}
    </Avatar>
  );
};

ProfileAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

ProfileAvatar.defaultProps = {
  user: {},
};

export default ProfileAvatar;
