import { RelationshipHelper, RELATIONSHIP_LABEL } from '@rss/common';
import React, { lazy } from 'react';

const HazardSignPage = lazy(() => import('./containers/HazardSignPage'));
const BuildingListPage = lazy(() => import('./containers/BuildingListPage'));
const BuildingPage = lazy(() => import('./containers/BuildingPage'));
const RoomSuiteEditPage = lazy(() => import('./containers/RoomSuiteEditPage'));
const RoomSuitesInBuildingPage = lazy(() => import('./containers/RoomSuitesInBuildingPage'));
const RoomSignOptionsPage = lazy(() => import('./containers/RoomSignOptionsPage'));

const hasPermission = (campusCode, relationships) =>
  RelationshipHelper.hasRelationshipWithRole(campusCode, relationships, [
    RELATIONSHIP_LABEL.EDGE.CHEMICAL_ADMIN,
    RELATIONSHIP_LABEL.EDGE.CHEMICAL_ADMIN_READ_ONLY,
  ]);

const routes = {
  settings: {
    helpCategories: ['hazard-sign'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: [
        '/hazard-sign/building/:buildingId/room/:roomId/sign',
        '/hazard-sign/building/:buildingId/suite/:suiteId/sign',
      ],
      exact: true,
      render: () => <HazardSignPage />,
    },
    {
      path: ['/hazard-sign/building/:buildingId/room/:roomId'],
      exact: true,
      render: () => <RoomSignOptionsPage />,
    },
    {
      path: ['/hazard-sign/building/:buildingId/suite/:suiteId'],
      exact: true,
      auth: ({ profile: { campusCode, relationships } }) => hasPermission(campusCode, relationships),
      render: () => <RoomSuiteEditPage />,
    },
    {
      path: ['/hazard-sign/building/:buildingId/suite'],
      exact: true,
      auth: ({ profile: { campusCode, relationships } }) => hasPermission(campusCode, relationships),
      render: () => <RoomSuitesInBuildingPage />,
    },
    {
      path: ['/hazard-sign/building/:buildingId'],
      exact: true,
      auth: ({ profile: { campusCode, relationships } }) => hasPermission(campusCode, relationships),
      render: () => <BuildingPage />,
    },
    {
      path: ['/hazard-sign/building'],
      exact: true,
      auth: ({ profile: { campusCode, relationships } }) => hasPermission(campusCode, relationships),
      render: () => <BuildingListPage />,
    },
  ],
};

export default routes;
