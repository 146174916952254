import { DateHelper } from '@rss/common';
import PropTypes from 'prop-types';
import React from 'react';

const FormattedDate = ({ date, showTime, show24HourTime }) =>
  date && DateHelper.formatDate(date, determineTimeFormat(showTime, show24HourTime));

const determineTimeFormat = (showTime, show24HourTime) => {
  if (!showTime) {
    return DateHelper.DATE_FORMAT;
  }
  if (show24HourTime) {
    return DateHelper.DATETIME_24H_FORMAT;
  }
  return DateHelper.DATETIME_FORMAT;
};

FormattedDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  showTime: PropTypes.bool,
  show24HourTime: PropTypes.bool,
};

FormattedDate.defaultProps = {
  date: null,
  showTime: false,
  show24HourTime: false,
};

export default FormattedDate;
