import gql from 'graphql-tag';

export const STORE_FRAGMENT = gql`
  fragment Store on Store {
    _id
    inventoryId
    name
    description
    campusCode
    catalogs
    salesTax
    hours
    address
    phone
    email
    settings
    permissions
  }
`;

export const PRODUCT_FRAGMENT = gql`
  fragment Product on Product {
    _id
    storeId
    name
    description
    catalog
    sku
    productNumber
    price
    fee
    details
    availableForSale
    taxExempt
    images
  }
`;

export const ORDER_FRAGMENT = gql`
  fragment Order on Order {
    _id
    receivingType
    createdDate
    createdBy {
      userId
      firstName
      lastName
      email
    }
    shopper {
      userId
      firstName
      lastName
      email
    }
    status
    totalCost
  }
`;

export const ACCOUNT_FUND_FRAGMENT = gql`
  fragment AccountFund on AccountFund {
    id
    type
    name
    value
    default
  }
`;

export const ACCOUNT_DELIVERY_LOCATION_FRAGMENT = gql`
  fragment AccountDeliveryLocation on AccountDeliveryLocation {
    id
    buildingId
    buildingName
    floorId
    floorName
    roomId
    roomNumber
    sublocationId
    sublocationName
    default
  }
`;

export const STORE_ACCOUNT_FRAGMENT = gql`
  fragment StoreAccount on StoreAccount {
    _id
    storeId
    funds {
      ...AccountFund
    }
    deliveryLocations {
      ...AccountDeliveryLocation
    }
    newAccount
  }
  ${ACCOUNT_FUND_FRAGMENT}
  ${ACCOUNT_DELIVERY_LOCATION_FRAGMENT}
`;
