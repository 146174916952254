import { lazy } from 'react';

const TripList = lazy(() => import('./containers/TripList'));
const TripPage = lazy(() => import('./containers/TripPage'));

const routes = {
  settings: {
    helpCategories: ['away'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: [
        '/away/trip/manage',
        '/away/trip/:id?/destinations/manage/:destinationId?',
        '/away/trip/:id?/travelers/manage/:travelerId?',
        '/away/trip/:id?',
      ],
      component: TripPage,
    },
    {
      path: '/away',
      component: TripList,
    },
  ],
};

export default routes;
