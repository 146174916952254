/* eslint-disable import/no-cycle */
import { FqHelper, FqType } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import ButtonProgress from './ButtonProgress';
import FqQuestionFactory from './flexible-questionnaire/FqQuestionFactory';
import { QUESTION_VARIANT } from './flexible-questionnaire/Helper';

const REQUIRED_ERROR_MESSAGE = 'Complete required field(s).';

const FollowupQuestions = ({
  canComment,
  canCommentPrivate,
  comments,
  privateComments,
  disabled,
  doneText,
  onChange,
  onComment,
  onDeleteComment,
  onDone,
  question,
  selectedItem,
  shouldValidate,
  variant,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <FqQuestionFactory
        onChange={(val) =>
          onChange({
            ...selectedItem,
            [question.key]: selectedItem[question.key],
            ...val,
            errorMessage: (FqHelper.hasError(question, val) && REQUIRED_ERROR_MESSAGE) || '',
          })
        }
        question={question}
        questionKey={question.key}
        value={selectedItem[question.key]}
        parentResponses={{
          ...selectedItem,
          [question.key]: selectedItem[question.key],
          errorMessage: '',
        }}
        label={question.label}
        labelVariant="long"
        key={question.key}
        disabled={disabled}
        canComment={canComment}
        canCommentPrivate={canCommentPrivate}
        shouldValidate={shouldValidate}
        comments={comments}
        privateComments={privateComments}
        onComment={(comment, isPrivate) => onComment(comment, isPrivate)}
        onDeleteComment={(comment, isPrivate) => onDeleteComment(comment, isPrivate)}
        variant={variant}
      />
      {question.type === FqType.GROUP && (
        <div className="my-16">
          {shouldValidate && selectedItem.errorMessage && (
            <div className="pb-8 text-danger-800">{selectedItem.errorMessage}</div>
          )}
          {!disabled && (
            <ButtonProgress
              id="nested-save-button"
              variant="contained"
              color="primary"
              disabled={disabled}
              loading={loading}
              className="mr-8 print:hidden"
              onClick={() => {
                setLoading(true);
                onDone({ ...selectedItem, [question.key]: selectedItem[question.key] });
              }}>
              {doneText}
            </ButtonProgress>
          )}
        </div>
      )}
    </>
  );
};

FollowupQuestions.propTypes = {
  canComment: PropTypes.bool,
  canCommentPrivate: PropTypes.bool,
  comments: PropTypes.objectOf(PropTypes.any),
  privateComments: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
  doneText: PropTypes.string,
  onDone: PropTypes.func.isRequired,
  onComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
  onChange: PropTypes.func,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedItem: PropTypes.objectOf(PropTypes.any).isRequired,
  shouldValidate: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

FollowupQuestions.defaultProps = {
  canComment: false,
  canCommentPrivate: false,
  comments: {},
  privateComments: {},
  disabled: false,
  doneText: 'Done',
  onComment: () => {},
  onDeleteComment: () => {},
  onChange: () => {},
  shouldValidate: false,
  variant: QUESTION_VARIANT.STANDARD,
};

export default React.memo(FollowupQuestions);
