import { useMutation } from '@apollo/client';
import { Button, DialogContentText, Grid, Icon, TextField } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { object, string } from 'yup';

import { UPSERT_PROGRAM } from '../graphql/relationship.query';
import { PROFILE_QUERY } from '../graphql/profile.query';
import { getDisplayName, inlineErrorMessages, mapYupValidationErrorToErrorMap } from '../shared/helper';
import DomainSelect from './DomainSelect';
import DialogTemplate from './DialogTemplate';

const {
  NODE: { PROGRAM },
} = RELATIONSHIP_LABEL;

const PROGRAM_SCHEMA = object().shape({
  id: string(),
  name: string().required().label('Program Name'),
  domain: string().required().label('Domain'),
});

const ManageProgramDialog = ({ closeDialog, description, isOpen, onSaveComplete, title, tenantCode, campusCode }) => {
  const orgLabel = PROGRAM;

  const [errors, setErrors] = useState(null);
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');

  const domainVariant = campusCode ? 'domain' : 'campus';

  const orgTypeDisplayName = getDisplayName(orgLabel);

  const getRefetchQueries = () => {
    return [{ query: PROFILE_QUERY }];
  };

  const clear = () => {
    setName('');
    setDomain('');
    setErrors(null);
    closeDialog();
  };

  const [upsertProgram, { loading }] = useMutation(UPSERT_PROGRAM, {
    refetchQueries: getRefetchQueries,
    onError: (error) => {
      if (/INACTIVE_ORG_EXISTS/.test(error.message)) {
        return setErrors({
          name: [
            <span>
              The Program Name you entered was previously active. You must enter a unique program name. For assistance
              reactivating the existing program contact the{' '}
              <a href="mailto:service@RiskandSafetySolutions.com">Service Desk</a>.
            </span>,
          ],
        });
      }

      if (/ORG_EXISTS/.test(error.message)) {
        return setErrors({
          name: ['The Program Name you entered already exists. You must enter a unique program name.'],
        });
      }

      return setErrors({
        server: (
          <>
            <Icon className="mr-5">warning</Icon>An error has occured while saving the ${orgTypeDisplayName}.
          </>
        ),
      });
    },
    onCompleted: (data) => {
      clear();
      onSaveComplete(data.upsertProgram);
    },
  });

  const isFormValid = async () => {
    setErrors(null);
    return PROGRAM_SCHEMA.validate({ domain, name }, { abortEarly: false })
      .then(() => true)
      .catch((err) => {
        setErrors(mapYupValidationErrorToErrorMap(err));
        return false;
      });
  };

  return (
    <DialogTemplate
      open={isOpen}
      title={title}
      loading={loading}
      contentScrollable
      content={
        <div className="space-y-16">
          {description && <DialogContentText className="whitespace-pre-wrap">{description}</DialogContentText>}

          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <DomainSelect
                tenantCode={tenantCode}
                campusCode={campusCode}
                value={domain}
                variant={domainVariant}
                required
                onSelect={(e) => setDomain(e.domainId)}
                errors={errors?.domain || []}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="organization-name"
                placeholder={`Enter ${orgTypeDisplayName} Name`}
                label={`${orgTypeDisplayName} Name`}
                type="input"
                variant="outlined"
                autoFocus
                autoComplete="off"
                fullWidth
                required
                value={name || ''}
                onChange={(event) => setName(event.target.value)}
                inputProps={{ maxLength: 250, 'aria-label': `Enter ${orgTypeDisplayName} Name` }}
                data-cy="e2e-org-name-input"
                error={!!errors?.name?.length}
                helperText={inlineErrorMessages(errors?.name)}
              />
            </Grid>
            {errors && errors?.server && (
              <Grid item xs={12}>
                <div className="flex items-center text-danger-800">{errors?.server}</div>
              </Grid>
            )}
          </Grid>
        </div>
      }
      primaryActions={
        <>
          <Button variant="contained" onClick={clear}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async (event) => {
              event.preventDefault();
              if (await isFormValid()) {
                upsertProgram({
                  variables: {
                    domainId: domain,
                    program: {
                      name,
                    },
                  },
                });
              }
            }}>
            Create
          </Button>
        </>
      }
    />
  );
};

ManageProgramDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  description: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onSaveComplete: PropTypes.func,
  title: PropTypes.string.isRequired,
  tenantCode: PropTypes.string.isRequired,
  campusCode: PropTypes.string,
};

ManageProgramDialog.defaultProps = {
  description: null,
  campusCode: null,
  onSaveComplete: () => {},
};

export default ManageProgramDialog;
