"use strict";

var types = {
  AGENDA: 'AGENDA',
  CHEMICAL: 'CHEMICAL',
  FIELD: 'FIELD',
  HAZARD_SIGN: 'HAZARD_SIGN',
  LOTO: 'LOTO',
  MEDICAL: 'MEDICAL',
  SPH: 'SPH',
  WPVP: 'WPVP',
  SOP: 'SOP'
};
module.exports = types;
module.exports.keys = Object.keys(types);