"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var AcknowledgementCard = require('../model/procedure/acknowledgement-card');
var AttendanceCard = require('../model/procedure/attendance-card');
var Card = require('../model/procedure/card');
var CheckboxCard = require('../model/procedure/checkbox-card');
var ChemicalCard = require('../model/procedure/chemical-card');
var ChemicalBandCard = require('../model/procedure/chemical-band-card');
var ConditionCard = require('../model/procedure/condition-card');
var EquipmentCard = require('../model/procedure/equipment-card');
var InspectCard = require('../model/procedure/inspect-card');
var InventoryCard = require('../model/procedure/inventory-card');
var LocationCard = require('../model/procedure/location-card');
var LotoElectricalCard = require('../model/procedure/loto-electrical-card');
var ValueUnitCard = require('../model/procedure/value-unit-card');
var QuestionCard = require('../model/procedure/question-card');
var ReferenceCard = require('../model/procedure/reference-card');
var SelectCard = require('../model/procedure/select-card');
var TextCard = require('../model/procedure/text-card');
var TableCard = require('../model/procedure/table-card');
var BuaCard = require('../model/procedure/bua-card');
var BiosafetyCard = require('../model/procedure/biosafety-card');
var BuaAttendanceCard = require('../model/procedure/bua-attendance-card');
module.exports.createCardFactory = function (type, card) {
  switch (type) {
    case 'ACKNOWLEDGEMENT':
      return new AcknowledgementCard(card);
    case 'ATTENDANCE':
      return new AttendanceCard(card);
    case 'BIOSAFETY':
      return new BiosafetyCard(card);
    case 'BUA':
      return new BuaCard(card);
    case 'BUA_ATTENDANCE':
      return new BuaAttendanceCard(card);
    case 'CHECKBOX':
      return new CheckboxCard(card);
    case 'CHEMICAL':
      return new ChemicalCard(card);
    case 'CHEMICAL_BAND':
      return new ChemicalBandCard(card);
    case 'CONDITION':
      return new ConditionCard(card);
    case 'EQUIPMENT':
      return new EquipmentCard(card);
    case 'INSPECT':
      return new InspectCard(card);
    case 'INVENTORY':
      return new InventoryCard(card);
    case 'LOCATION':
      return new LocationCard(card);
    case 'LOTO_ELECTRICAL':
      return new LotoElectricalCard(card);
    case 'QUESTION':
      return new QuestionCard(card);
    case 'REFERENCE':
      return new ReferenceCard(card);
    case 'SELECT':
      return new SelectCard(card);
    case 'TEXT':
      return new TextCard(card);
    case 'VALUE_UNIT':
      return new ValueUnitCard(card);
    case 'TABLE':
      return new TableCard(card);
    default:
      return _objectSpread(_objectSpread({}, new Card(card)), {}, {
        details: {}
      });
  }
};