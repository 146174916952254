const ObjectId = require('bson').ObjectID;
const clone = require('clone');
const tv4 = require('tv4');
class FamilyBand {
  constructor(opt) {
    this._id = null;
    this.name = null;
    this.class = null;
    this.key = null;
    this.campus = 'GLOBAL';
    this.tenant = 'GLOBAL';
    this.hideFromUser = false;
    this.requireSop = [];
    this.category = '';
    if (opt) {
      this.fillObject(clone(opt));
    }
  }
  fillObject(opt) {
    this._id = new ObjectId(opt._id);
    this.name = opt.name || '';
    this.class = opt.class || null;
    this.key = opt.key || null;
    this.campus = opt.campus || 'GLOBAL';
    this.tenant = opt.tenant || 'GLOBAL';
    this.hideFromUser = !!opt.hideFromUser;
    this.requireSop = opt.requireSop || [];
    this.category = opt.category || '';
  }
  validate() {
    return tv4.validateMultiple(this, FamilyBand.schema());
  }
  static schema() {
    return {
      $schema: 'http://json-schema.org/draft-04/schema#',
      id: 'family-band.schema.json',
      type: 'object',
      properties: {
        _id: {
          type: 'object'
        },
        name: {
          type: 'string'
        },
        class: {
          type: ['string', 'null']
        },
        key: {
          type: ['string', 'null']
        },
        campus: {
          type: 'string'
        },
        tenant: {
          type: 'string'
        },
        hideFromUser: {
          type: 'boolean'
        },
        requireSop: {
          type: 'array',
          item: {
            type: 'string'
          }
        },
        category: {
          type: 'string'
        }
      },
      required: Object.keys(this)
    };
  }
}
module.exports = FamilyBand;