import StorePage from './containers/StorePage';
import LowStockPage from './containers/LowStockPage';
import ProductListPage from './containers/ProductListPage';
import ProductPage from './containers/ProductPage';
import ProductDetail from './components/ProductDetail';
import ProductDetailEdit from './components/ProductDetailEdit';
import ProductStock from './components/ProductStock';
import ProductStockAdd from './components/ProductStockAdd';
import SettingPage from './containers/SettingPage';
import SettingDetail from './components/SettingDetail';
import SettingDetailEdit from './components/SettingDetailEdit';
import SettingLocation from './components/SettingLocation';
import SettingLocationAdd from './components/SettingLocationAdd';
import TransactionPage from './containers/TransactionPage';

import AccountListPage from './containers/AccountListPage';
import AccountDetailPage from './containers/AccountDetailPage';
import AccountDetail from './components/AccountDetail';
import AccountFund from './components/AccountFund';
import AccountDeliveryLocation from './components/AccountDeliveryLocation';
import AccountCreate from './components/AccountCreate';

import PurchasingCode from './components/PurchasingCode';
import ShopPage from './containers/ShopPage';
import ShopHome from './components/ShopHome';
import ShopSearch from './components/ShopSearch';
import ShopProduct from './components/ShopProduct';
import ShoppingCart from './components/ShoppingCart';

import OrderListPage from './containers/OrderListPage';
import OrderPage from './containers/OrderPage';
import OrderDetail from './components/OrderDetail';
import OrderFulfill from './components/OrderFulfill';
import OrderRefundDetails from './components/OrderRefundDetails';

import StoreCheckoutPage from './containers/StoreCheckoutPage';
import SelfCheckoutPage from './containers/SelfCheckoutPage';

const routes = [
  // store management
  {
    path: '/store/:storeId',
    component: StorePage,
    exact: true,
  },
  {
    path: '/store/:storeId/low-stock',
    component: LowStockPage,
    exact: true,
  },
  {
    path: '/store/:storeId/product',
    component: ProductListPage,
    exact: true,
  },
  {
    path: '/store/:storeId/product/:productId/:tab/:panel?',
    component: ProductPage,
    routes: [
      {
        path: '/store/:storeId/product/:productId/detail',
        component: ProductDetail,
        exact: true,
      },
      {
        path: '/store/:storeId/product/:productId/detail/edit',
        component: ProductDetailEdit,
        exact: true,
      },
      {
        path: '/store/:storeId/product/:productId/detail/add',
        component: ProductDetailEdit,
        exact: true,
      },
      {
        path: '/store/:storeId/product/:productId/stock',
        component: ProductStock,
        exact: true,
      },
      {
        path: '/store/:storeId/product/:productId/stock/add',
        component: ProductStockAdd,
        exact: true,
      },
    ],
  },
  {
    path: '/store/:storeId/settings/:tab/:panel?',
    component: SettingPage,
    routes: [
      {
        path: '/store/:storeId/settings/detail',
        component: SettingDetail,
        exact: true,
      },
      {
        path: '/store/:storeId/settings/detail/edit',
        component: SettingDetailEdit,
        exact: true,
      },
      {
        path: '/store/:storeId/settings/location',
        component: SettingLocation,
        exact: true,
      },
      {
        path: '/store/:storeId/settings/location/add',
        component: SettingLocationAdd,
        exact: true,
      },
      {
        path: '/store/:storeId/settings/location/:locationId',
        component: SettingLocationAdd,
        exact: true,
      },
    ],
  },
  {
    path: ['/store/:storeId/order', '/shop/:storeId/order'],
    component: OrderListPage,
    exact: true,
  },
  {
    path: ['/store/:storeId/order/:orderId/:panel?', '/shop/:storeId/order/:orderId/:panel?'],
    component: OrderPage,
    routes: [
      {
        path: ['/store/:storeId/order/:orderId', '/shop/:storeId/order/:orderId'],
        component: OrderDetail,
        exact: true,
      },
      {
        path: '/store/:storeId/order/:orderId/fulfill',
        component: OrderFulfill,
        exact: true,
      },
      {
        path: ['/store/:storeId/order/:orderId/refund'],
        component: OrderRefundDetails,
        exact: true,
      },
    ],
  },
  {
    path: ['/store/:storeId/transaction'],
    component: TransactionPage,
    exact: true,
  },
  {
    path: '/store/:storeId/checkout',
    component: StoreCheckoutPage,
    exact: true,
  },
  {
    path: ['/store/:storeId/account', '/shop/:storeId/account'],
    component: AccountListPage,
    exact: true,
  },
  {
    path: ['/store/:storeId/account/:accountId/:panel?', '/shop/:storeId/account/:accountId/:panel?'],
    component: AccountDetailPage,
    routes: [
      {
        path: ['/store/:storeId/account/create', '/shop/:storeId/account/create'],
        component: AccountCreate,
        exact: true,
      },
      {
        path: ['/store/:storeId/account/:accountId', '/shop/:storeId/account/:accountId'],
        component: AccountDetail,
        exact: true,
      },
      {
        path: ['/store/:storeId/account/:accountId/fund/:fundId?', '/shop/:storeId/account/:accountId/fund/:fundId?'],
        component: AccountFund,
        exact: true,
      },
      {
        path: [
          '/store/:storeId/account/:accountId/location/:locationId?',
          '/shop/:storeId/account/:accountId/location/:locationId?',
        ],
        component: AccountDeliveryLocation,
        exact: true,
      },
    ],
  },

  // shop
  {
    path: '/shop/:storeId/:panel?',
    component: ShopPage,
    routes: [
      {
        path: '/shop/:storeId/search',
        component: ShopSearch,
        exact: true,
      },
      {
        path: '/shop/:storeId/product/:productId',
        component: ShopProduct,
        exact: true,
      },
      {
        path: '/shop/:storeId/purchasing-code',
        component: PurchasingCode,
        exact: true,
      },
      {
        path: '/shop/:storeId/cart',
        component: ShoppingCart,
        exact: true,
      },
      {
        path: '/shop/:storeId',
        component: ShopHome,
        exact: true,
      },
    ],
  },
];

export const storePublicRoutes = [
  {
    path: '/public/shop/:storeId',
    component: SelfCheckoutPage,
    exact: true,
  },
];

export default routes;
