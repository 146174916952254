import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { offsetLimitPagination } from '@apollo/client/utilities';

// eslint-disable-next-line import/no-extraneous-dependencies
import { setContext } from '@apollo/client/link/context';

import config from '../config';
import { useSecurity } from '../hooks';
import { getImpersonateId } from '../shared/helper';

const httpLink = createHttpLink({ uri: `${config.GRAPH_URL}/graphql`, credentials: 'include' });

export const authMiddleware = setContext(() => {
  const context = { headers: {} };

  const userId = getImpersonateId();
  if (userId) {
    context.headers['impersonate-id'] = userId;
  }

  return context;
});

export const errorLink = onError(({ networkError }) => {
  const { browserSecurity } = useSecurity();
  if (networkError && networkError.statusCode === 401) {
    browserSecurity.login();
  }
});

export const MaqClient = new ApolloClient({
  link: from([authMiddleware, createHttpLink({ uri: config.MAQ_GRAPH_URL, credentials: 'include' })]),
  cache: new InMemoryCache(),
});

export const ReadOnlyClient = new ApolloClient({
  link: createHttpLink({ uri: `${config.GRAPH_URL}/read-only`, credentials: 'include' }),
  cache: new InMemoryCache(),
});

export const ReadWriteClient = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          products: offsetLimitPagination(['keyword', 'availableForSaleOnly']),
          transactions: {
            keyArgs: ['storeId', 'keyword', 'startDate', 'endDate'],
            merge(existing, incoming, { args: { offset = 0 } }) {
              return [...(existing || []), ...(incoming || [])];
            },
          },
          lowStockProducts: offsetLimitPagination(),
        },
      },
    },
  }),
});
