import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ErrorMessage } from './errors';

const STATUSES = {
  IDLE: 'idle',
  DONE: 'done',
  FAILED: 'failed',
  LOADING: 'loading',
};

function WebComponent({ children, name, sourceUrl }) {
  const [status, setStatus] = useState(STATUSES.IDLE);

  useEffect(() => {
    async function load() {
      setStatus(STATUSES.LOADING);

      const webComponentURL = `${sourceUrl}/${name}.mjs`

      try {
        await import(/* webpackIgnore: true */ webComponentURL);
        setStatus(STATUSES.DONE);
      } catch (err) {
        setStatus(STATUSES.FAILED);
      }
    }

    if (status === STATUSES.IDLE) {
      load();
    }
  }, [name, sourceUrl, status]);

  switch (status) {
    case STATUSES.LOADING:
      return (
        <div className="m-16 flex items-center justify-center">
          <CircularProgress role="status" aria-label="loading" />
        </div>
      );
    case STATUSES.FAILED:
      return <ErrorMessage message="We were unable to process this request." />;
    case STATUSES.DONE:
      return children;
    default:
      return null;
  }
}

WebComponent.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired,
};

export default WebComponent;
