import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Chip, Icon, Tab, Tabs, Typography } from '@material-ui/core';
import { CATALOG_TYPE } from '@risk-and-safety/library';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Header } from '../../components';
import { ErrorMessage } from '../../components/errors';
import { PRODUCT, STORE_WITH_INVENTORY } from '../graphql/query';

function ProductPage({ route, store }) {
  const { productId, tab, panel } = useParams();
  const history = useHistory();

  const {
    loading,
    data: storeData,
    error,
  } = useQuery(STORE_WITH_INVENTORY, {
    variables: { id: store._id },
  });

  const {
    data,
    loading: productLoading,
    error: productError,
  } = useQuery(PRODUCT, {
    variables: { storeId: store._id, id: productId },
    skip: tab === 'detail' && panel === 'add',
    fetchPolicy: 'cache-and-network',
  });
  if (loading || productLoading) {
    return <FuseLoading />;
  }

  if (error || productError) {
    return <ErrorMessage />;
  }

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        contentCard: 'overflow-hidden',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">shopping_cart</Icon>}
          title={data?.product?.name || 'Product Detail'}
          backArrow={{
            link: history.location?.state?.fromLowStockPage
              ? `/store/${store._id}/low-stock`
              : `/store/${store._id}/product${history.location.search}`,
            name: 'Products',
          }}
        />
      }
      contentToolbar={
        !panel ? (
          <Tabs
            value={tab}
            onChange={(e, value) => history.push(`/store/${store._id}/product/${productId}/${value}`)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ root: 'w-full h-64' }}>
            <Tab className="h-64 normal-case" label="Detail" value="detail" />
            {data?.product?.catalog !== CATALOG_TYPE.SERVICES && (
              <Tab
                className="h-64 normal-case"
                label={
                  <div className="flex flex-row items-center">
                    <Typography>Stock</Typography>
                    <Chip size="small" label={data?.product?.stock?.length || 0} className="ml-12" />
                  </div>
                }
                value="stock"
              />
            )}
          </Tabs>
        ) : (
          <div />
        )
      }
      content={
        <div className="flex-grow p-28">
          {renderRoutes(route.routes, {
            product:
              panel === 'add'
                ? {
                    _id: productId,
                    storeId: store._id,
                    isNew: true,
                    catalog: data?.product?.catalog,
                    barcodeRequired: data?.product?.barcodeRequired,
                  }
                : data?.product,
            store: storeData.store,
            enableEditOption: true,
          })}
        </div>
      }
    />
  );
}

ProductPage.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductPage;
