import { useQuery } from '@apollo/client';
import { FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, Select } from '@material-ui/core';
import { RELATIONSHIP_LABEL } from '@rss/common';
import { isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { SEARCH_ORGANIZATIONS } from '../graphql/relationship.query';
import { useProfile } from '../hooks';
import {
  hasAccountManagerRole,
  hasDistrictAdminRole,
  hasFacilityAdminRole,
  inlineErrorMessages,
} from '../shared/helper';
import ErrorMessage from './errors/ErrorMessage';

const { NODE, EDGE } = RELATIONSHIP_LABEL;

const DistrictSelect = ({ id, value, campusCode, required, errors, onSelect }) => {
  const { profile } = useProfile();
  const [selectedDistrict, setSelectedDistrict] = useState(value);
  const isAccountManager = hasAccountManagerRole(profile);
  const isDistrictAdmin = hasDistrictAdminRole(profile);
  const isFacilityAdmin = hasFacilityAdminRole(profile);

  const userDistricts = sortBy(
    profile?.relationships
      ?.filter(({ node, edge }) => node.label === NODE.DISTRICT && edge.label === EDGE.DISTRICT_ADMIN)
      .map((d) => d.node),
    ['name'],
  );

  const { data, loading, error } = useQuery(SEARCH_ORGANIZATIONS, {
    skip: !isAccountManager && !isFacilityAdmin && isDistrictAdmin,
    variables: { campusCode, organizationType: NODE.DISTRICT, sort: { key: 'name', order: 'ASC' } },
  });

  const districts = data?.searchOrganizations || userDistricts || [];

  const handleSelect = (district) => {
    onSelect(district);
    setSelectedDistrict(district);
  };

  const selectId = `${id}_district`;

  if (error) return <ErrorMessage />;

  return (
    <FormControl variant="outlined" fullWidth required={required} error={Boolean(errors?.length)}>
      <InputLabel htmlFor={selectId}>District</InputLabel>
      <Select
        data-cy="e2e-selectDistrict"
        variant="outlined"
        label="District"
        value={selectedDistrict || ''}
        onChange={(event) => {
          handleSelect(event.target.value);
        }}
        inputProps={{ name: selectId, id: selectId }}>
        {districts &&
          districts.map((d) => (
            <MenuItem data-cy={`e2e-${d.id}`} key={d.id} value={d}>
              {d.name}
            </MenuItem>
          ))}
      </Select>
      {loading && <LinearProgress />}
      {!isEmpty(errors) && <FormHelperText>{inlineErrorMessages(errors)}</FormHelperText>}
    </FormControl>
  );
};

DistrictSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.objectOf(PropTypes.any),
  campusCode: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
};

DistrictSelect.defaultProps = {
  id: '',
  value: {},
  campusCode: '',
  required: false,
  errors: [],
};

export default DistrictSelect;
