import { Icon, IconButton, ThemeProvider, Tooltip, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectContrastMainTheme } from '../store/fuse/settingsSlice';

const IconWithTooltip = ({ className, color, disabled, disableRipple, icon, onClick, placement, size, title }) => {
  const theme = useTheme();
  const contrastTheme = useSelector(selectContrastMainTheme(theme.palette.primary.contrastText));
  return (
    <ThemeProvider theme={contrastTheme}>
      <Tooltip placement={placement} interactive disableFocusListener title={title} arrow>
        <IconButton
          size={size}
          disableRipple
          className={className}
          color={color}
          disabled={disabled}
          aria-label={title}
          onClick={onClick}>
          <Icon>{icon}</Icon>
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

IconWithTooltip.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  disableRipple: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

IconWithTooltip.defaultProps = {
  className: '',
  color: 'inherit',
  disabled: false,
  disableRipple: false,
  onClick: null,
  placement: 'top',
  size: 'medium',
};

export default IconWithTooltip;
