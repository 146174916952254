/* eslint-disable react/jsx-boolean-value */
import { useQuery } from '@apollo/client';
import FusePageSimple from '@fuse/core//FusePageSimple';
import {
  Button,
  Icon,
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Store, STORE_PERMISSION } from '@risk-and-safety/library';
import { SHORT_DATE_FORMAT, SHORT_DATE_PLACEHOLDER } from '@rss/common';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useTable } from 'react-table';

import { TRANSACTION_EXCEL_FILE, TRANSACTION_JOURNAL_FILE, TRANSACTIONS } from '../graphql/query';
import HeaderSearch from '../components/HeaderSearch';
import { Header } from '../../components';
import { AccessDenied } from '../../components/errors';
import config from '../../config';
import { useAdvancedSearch } from '../../hooks';

const SEARCH_DEFAULT = {
  keyword: '',
  startDate: format(new Date(new Date().setDate(1)), SHORT_DATE_FORMAT),
  endDate: format(new Date(new Date().setDate(new Date().getDate() + 1)), SHORT_DATE_FORMAT),
};
function TransactionPage({ store }) {
  const { advancedSearch, setAdvancedSearch } = useAdvancedSearch(SEARCH_DEFAULT);

  const { control, getValues, reset, watch } = useForm({
    defaultValues: { ...SEARCH_DEFAULT, ...advancedSearch },
  });

  const { data, fetchMore } = useQuery(TRANSACTIONS, {
    variables: { ...advancedSearch, storeId: store._id, cursor: '' },
  });

  const { refetch: refetchExcel } = useQuery(TRANSACTION_EXCEL_FILE, {
    variables: { ...advancedSearch, storeId: store._id },
    fetchPolicy: 'network-only',
    skip: true,
  });

  const { refetch: refetchJournal } = useQuery(TRANSACTION_JOURNAL_FILE, {
    variables: { ...advancedSearch, storeId: store._id },
    fetchPolicy: 'network-only',
    skip: true,
  });

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: useMemo(
        () => [
          { Header: 'Transaction Type', accessor: 'transactionType' },
          { Header: 'Order Number', accessor: 'orderId' },
          { Header: 'Order Item', accessor: 'orderItemId' },
          { Header: 'Product Name', accessor: 'productName' },
          { Header: 'Payer', accessor: 'accountName' },
          // Math.abs b/c displaying payee transactions with positive amount
          { Header: 'Amount', accessor: 'amount', Cell: ({ value }) => numeral(Math.abs(value)).format('$0,0.00') },
          { Header: 'Fund Type', accessor: 'fundType' },
          { Header: 'Business Unit', accessor: 'fundValue.businessUnit' },
          { Header: 'Account', accessor: 'fundValue.account' },
          { Header: 'Fund', accessor: 'fundValue.fund' },
          { Header: 'Department', accessor: 'fundValue.departmentId' },
          { Header: 'Project', accessor: 'fundValue.project' },
          { Header: 'Activity Period', accessor: 'fundValue.activityPeriod' },
          { Header: 'Function', accessor: 'fundValue.function' },
          { Header: 'Flex', accessor: 'fundValue.flexField' },
          { Header: 'SpeedType', accessor: 'fundValue.speedType' },
          { Header: 'Purpose Code', accessor: 'productPurposeCode' },
          {
            Header: 'Date',
            accessor: 'createdDate',
            Cell: ({ value }) => format(new Date(value), 'Ppp'),
          },
          { Header: 'Notes', accessor: 'notes', Cell: ({ value }) => value || '', },
        ],
        [],
      ),
      data: useMemo(() => data?.transactions || [], [data]),
    },
    (hooks) => {
      hooks.visibleColumns.push((col) => [
        {
          id: 'selection',
          Header: ({ row, getToggleAllRowsSelectedProps }) => '',
          Cell: ({ row }) => row.index + 1,
          headerClassName: 'sticky left-0 bg-gray-50 -top-1 border-1 z-10',
          className: 'sticky left-0 bg-gray-50',
        },
        ...col,
      ]);
    },
  );

  if (!store.permissions.includes(STORE_PERMISSION.MANAGE)) {
    return <AccessDenied />;
  }

  return (
    <FusePageSimple
      classes={{
        content: 'relative',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        contentWrapper: 'overflow-hidden',
        toolbar: 'bg-gray-50 min-h-36 h-36 py-2 flex border-b-1',
      }}
      header={
        <div className="flex flex-grow px-32">
          <Header
            icon={<Icon className="text-32 sm:w-48 sm:text-5xl">credit_card</Icon>}
            title="Transactions"
            backArrow={{
              link: `../${store._id}`,
              name: 'Store',
            }}
            search={
              <HeaderSearch
                control={control}
                onSubmit={() => setAdvancedSearch({ ...advancedSearch, ...getValues() })}
                onReset={() => {
                  setAdvancedSearch(SEARCH_DEFAULT);
                  reset();
                }}
                placeholder="Search by order number">
                <div className="mt-8 flex gap-24">
                  <Controller
                    name="startDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <KeyboardDatePicker
                        label="From"
                        autoOk
                        disableToolbar
                        fullWidth
                        inputVariant="outlined"
                        variant="inline"
                        format={SHORT_DATE_FORMAT}
                        placeholder={SHORT_DATE_PLACEHOLDER}
                        value={value || null}
                        onChange={onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                  <Controller
                    name="endDate"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <KeyboardDatePicker
                        label="To"
                        autoOk
                        disableToolbar
                        fullWidth
                        inputVariant="outlined"
                        variant="inline"
                        format={SHORT_DATE_FORMAT}
                        placeholder={SHORT_DATE_PLACEHOLDER}
                        value={value || null}
                        onChange={onChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </div>
              </HeaderSearch>
            }
          />
        </div>
      }
      contentToolbar={
        <>
          <div className="flex-grow px-20 ">
            <Typography color="textSecondary">
              Transaction Period: {format(new Date(advancedSearch.startDate), 'PPP')} to{' '}
              {format(new Date(advancedSearch.endDate), 'PPP')}
            </Typography>
          </div>
          <div className="flex content-center px-20">
            <Button
              onClick={async () => {
                const res = await refetchExcel({ ...advancedSearch, storeId: store._id });
                if (res.data?.transactionExcelFile) {
                  saveAs(res.data?.transactionExcelFile.signedUrl, res.data?.transactionExcelFile.fileName);
                }
              }}
              className="font-normal text-gray-300"
              startIcon={<Icon>save_alt</Icon>}>
              Download Excel
            </Button>

            {store.settings.journal && (
              <Button
                onClick={async () => {
                  const res = await refetchJournal({ ...advancedSearch, storeId: store._id, skip: false });
                  if (res.data?.transactionJournalFile) {
                    saveAs(res.data?.transactionJournalFile.signedUrl, res.data?.transactionJournalFile.fileName);
                  }
                }}
                className="font-normal text-gray-300"
                startIcon={<Icon>save_alt</Icon>}>
                Download Journal
              </Button>
            )}
          </div>
        </>
      }
      content={
        <div className="absolute bottom-0 left-0 right-0 top-0 overflow-auto">
          <InfiniteScroll
            initialLoad={false}
            hasMore={data?.transactions?.length > 0 && data?.transactions?.length % config.LIMIT === 0}
            loadMore={() =>
              fetchMore({
                variables: { ...watch(), cursor: data?.transactions[data?.transactions?.length - 1]._id },
              })
            }
            useWindow={false}
            loader={<LinearProgress className="clear-both" key={0} />}>
            <Table stickyHeader size="small" className="-mt-1 border-collapse" {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell
                        className="-top-1 left-auto whitespace-nowrap border-1 bg-gray-50"
                        {...column.getHeaderProps([{ className: column.headerClassName }])}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow hover {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell
                            className="whitespace-nowrap border-1"
                            {...cell.getCellProps([{ className: cell.column.className }])}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </div>
      }
    />
  );
}

TransactionPage.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default TransactionPage;
