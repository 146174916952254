import { lazy } from 'react';

const BandListPage = lazy(() => import('./containers/BandListPage'));
const BandDetailPage = lazy(() => import('./containers/BandDetailPage'));
const CampusBandsPage = lazy(() => import('./containers/CampusBandsPage'));
const CampusBandDetailsPage = lazy(() => import('./containers/CampusBandDetailsPage'));

const routes = {
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/bands/custom-bands/:id',
      component: CampusBandDetailsPage,
    },
    {
      path: '/bands/custom-bands',
      component: CampusBandsPage,
    },
    {
      path: '/bands/:id',
      component: BandDetailPage,
    },
    {
      path: '/bands',
      component: BandListPage,
    },
  ],
};

export default routes;
