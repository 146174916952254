import FuseDialog from '@fuse/core/FuseDialog';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from 'app/AppContext';
import clsx from 'clsx';
import React, { memo, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import FooterLayout1 from './components/FooterLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import { KiwiNavbar } from './KiwiNavbar';
import { HelpChat, HELP_CHAT_STATE } from './HelpChat';
import { Auth } from '../../auth';

const useStyles = makeStyles((theme) => ({
  root: {
    '&.boxed': {
      clipPath: 'inset(0)',
      maxWidth: (props) => `${props.config.containerWidth}px`,
      margin: '0 auto',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    '&.container': {
      '& .container': {
        maxWidth: (props) => `${props.config.containerWidth}px`,
        width: '100%',
        margin: '0 auto',
      },
    },
  },
}));

function Layout1(props) {
  const [enableHelpChat, setEnableHelpChat] = useState(false);
  const [openHelpChat, setOpenHelpChat] = useState(false);

  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const { routes } = useContext(AppContext);
  const classes = useStyles({ ...props, config });

  return (
    <div id="fuse-layout" className={clsx(classes.root, config.mode, 'flex w-full')}>
      <div className="flex min-w-0 flex-auto">
        <main id="fuse-main" className="relative z-10 flex min-h-screen min-w-0 flex-auto flex-col">
          {config.navbar.display && (
            <Auth>
              <KiwiNavbar enableHelpChat={enableHelpChat} onOpenHelpChat={() => setOpenHelpChat(true)} />
            </Auth>
          )}

          <div className="relative z-10 mt-64 flex min-h-0 flex-auto flex-col">
            <FuseDialog />

            <FuseSuspense>{renderRoutes(routes)}</FuseSuspense>

            {props.children}
          </div>

          {config.footer.display && <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'} />}
        </main>

        {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}

        <div className="fixed bottom-4 right-4 z-10">
          <HelpChat
            open={openHelpChat}
            onEndChat={() => {
              setOpenHelpChat(false);
            }}
            onMinimise={() => {
              setOpenHelpChat(false);
            }}
            onStatusChange={(status) => {
              if (status === HELP_CHAT_STATE.DONE) {
                setEnableHelpChat(true);
              }
            }}
          />
        </div>
      </div>

      {config.rightSidePanel.display && <RightSideLayout1 />}
      <FuseMessage />
    </div>
  );
}

export default memo(Layout1);
