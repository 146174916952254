import { Typography } from '@material-ui/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React from 'react';

function formatNumber(international, number) {
  return international ? number.formatInternational() : number.formatNational();
}

function PhoneNumberDisplay({ international, number, variant }) {
  if (!number) {
    return '';
  }

  const phoneNumber = parsePhoneNumberFromString(number);

  return (
    <Typography display="inline" variant={variant}>
      {phoneNumber ? formatNumber(international, phoneNumber) : number}
    </Typography>
  );
}

PhoneNumberDisplay.propTypes = {
  international: PropTypes.bool,
  number: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

PhoneNumberDisplay.defaultProps = {
  international: false,
  variant: 'body1',
};

export default PhoneNumberDisplay;
