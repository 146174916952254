import PropTypes from 'prop-types';

export const PersonShape = PropTypes.shape({
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
});

export const NodeShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
});

export const NodeEdgeShape = PropTypes.shape({
  node: PropTypes.objectOf(PropTypes.any),
  edge: PropTypes.objectOf(PropTypes.any),
});
