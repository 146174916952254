import PropTypes from 'prop-types';
import React from 'react';

import { WebComponent } from '../../../components';
import config from '../../../config';

export function PersonSubjectDetails({ reportId }) {
  return (
    <WebComponent name="subject-details" sourceUrl={config.INSPECTION_TOOL_WC_URL}>
      <subject-details reportId={reportId} graphUrl={`${config.INSPECTION_TOOL_URL}/graphql`} />
    </WebComponent>
  );
}

PersonSubjectDetails.propTypes = {
  reportId: PropTypes.string.isRequired,
};
