import PropsTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import useProfile from '../../hooks/useProfile';

export const HELP_CHAT_STATE = {
  DONE: 'done',
  FAILED: 'failed',
  IDLE: 'idle',
  LOADING: 'loading',
};

const getUrl = () => {
  if (window.location.href.includes('localhost')) return `http://localhost:3304/rss-help-chat.mjs`;
  return `/help-tool/latest/rss-help-chat.mjs`;
};

const componentUrl = getUrl();

export function HelpChat({ open, onEndChat, onMinimise, onStatusChange }) {
  const [status, setStatus] = useState(HELP_CHAT_STATE.IDLE);

  const webComponentRef = useRef();
  const { profile } = useProfile({ skip: true });

  const setProps = () => {
    if (webComponentRef.current) {
      webComponentRef.current.props = {
        user: {
          id: profile?.userId,
          email: profile?.email,
        },
        onEndChat,
        onMinimise,
      };
    }
  };

  useEffect(() => {
    async function load() {
      setStatus(HELP_CHAT_STATE.LOADING);
      try {
        await import(/* webpackIgnore: true */ componentUrl);
        setStatus(HELP_CHAT_STATE.DONE);
        onStatusChange(HELP_CHAT_STATE.DONE);
      } catch (error) {
        const newStatus = HELP_CHAT_STATE.FAILED;
        setStatus(newStatus);
        onStatusChange(newStatus);
      }
    }

    if (status === HELP_CHAT_STATE.IDLE) {
      load();
    }
  }, [status]);

  useEffect(() => {
    if (open) {
      setProps();
    }
  }, [open]);

  if (status !== HELP_CHAT_STATE.DONE) {
    return null;
  }

  return open ? <rss-help-chat ref={webComponentRef} /> : null;
}

HelpChat.propTypes = {
  open: PropsTypes.bool,
  onEndChat: PropsTypes.func.isRequired,
  onMinimise: PropsTypes.func.isRequired,
  onStatusChange: PropsTypes.func.isRequired,
};

HelpChat.defaultProps = {
  open: false,
};
