import gql from 'graphql-tag';

export const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on Attachment {
    _id
    name
    originalName
    type
  }
`;
