import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import FqQuestionFactory from './FqQuestionFactory';
import { QUESTION_VARIANT } from './Helper';

const FqQuestion = ({
  comments,
  disabled,
  labelVariant,
  onChange,
  onComment,
  question,
  shouldValidate,
  values,
  variant,
  nestedErrors,
}) => {
  return (
    <FqQuestionFactory
      onChange={(value, shouldSave, message) => onChange(value, shouldSave, message)}
      question={question}
      questionKey={question.key}
      value={values?.[question.key] || null}
      key={question.key}
      parentResponses={values}
      disabled={disabled}
      onComment={onComment}
      comments={comments}
      shouldValidate={shouldValidate}
      labelVariant={labelVariant}
      inputProps={question.inputProps}
      variant={variant}
      nestedErrors={nestedErrors}
    />
  );
};

FqQuestion.propTypes = {
  comments: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.any]),
  disabled: PropTypes.bool,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  onComment: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  shouldValidate: PropTypes.bool,
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.bool]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  nestedErrors: PropTypes.objectOf(PropTypes.any),
};

FqQuestion.defaultProps = {
  comments: {},
  disabled: false,
  labelVariant: 'default',
  onComment: () => null,
  shouldValidate: false,
  values: null,
  variant: QUESTION_VARIANT.STANDARD,
  nestedErrors: {},
};

export default FqQuestion;
