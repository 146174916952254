/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

const CurrencyFormat = ({ decimalScale, id, inputRef, onChange, placeholder, prefix, ...other }) => (
  <NumberFormat
    {...other}
    id={id}
    thousandSeparator=","
    fixedDecimalScale
    decimalSeparator="."
    prefix={prefix}
    placeholder={placeholder}
    decimalScale={decimalScale}
    onValueChange={(values) => {
      onChange({
        target: {
          id,
          value: values.value,
        },
      });
    }}
  />
);

CurrencyFormat.propTypes = {
  decimalScale: PropTypes.number,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
};

CurrencyFormat.defaultProps = {
  decimalScale: 2,
  placeholder: '0.00',
  prefix: '$',
};

export default CurrencyFormat;
