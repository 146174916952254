const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const yup = require('yup');
const {
  isEmpty
} = require('lodash');
const CATALOG_TYPE = require('../catalog-type.constant');
const LibraryItemReference = require('./library-item.reference');
const InventoryBioDetails = require('./details/bio-details');
const InventoryChemicalDetails = require('./details/chemical-details');
const InventoryPPEDetails = require('./details/inventory.ppe-details');
const PersonReferenceSchema = require('./person-reference.schema');
class InventoryItem extends RssModel {
  constructor(opt = {}) {
    super(opt);
    this.libraryItem = new LibraryItemReference(opt.libraryItem);
    if (!isEmpty(opt.details)) {
      switch (opt.libraryItem.catalog) {
        case CATALOG_TYPE.PPE:
          this.details = new InventoryPPEDetails(opt.details);
          break;
        case CATALOG_TYPE.BIOLOGICAL:
          this.details = new InventoryBioDetails(opt.details);
          break;
        case CATALOG_TYPE.CHEMICIAL:
          this.details = new InventoryChemicalDetails(opt.details);
          break;
        default:
          this.details = opt.details;
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema(),
      inventoryId: new ObjectIdSchema().required(),
      libraryItem: new LibraryItemReference().schema(),
      barcode: yup.string().default(null).nullable(),
      assignedTo: PersonReferenceSchema.default(null).nullable(),
      details: yup.object().when('libraryItem.catalog', catalog => {
        switch (catalog) {
          case CATALOG_TYPE.PPE:
            return new InventoryPPEDetails().schema().required();
          case CATALOG_TYPE.BIOLOGICAL:
            return new InventoryBioDetails().schema().required();
          case CATALOG_TYPE.CHEMICAL:
            return new InventoryChemicalDetails().schema().required();
          default:
            return yup.object().nullable();
        }
      }),
      product: yup.object({
        _id: new ObjectIdSchema().nullable().default(undefined),
        reserved: yup.bool().default(false)
      }),
      location: yup.object({
        buildingId: yup.string().nullable(),
        buildingName: yup.string().nullable(),
        floorId: yup.string().nullable(),
        floorName: yup.string().nullable(),
        roomId: yup.string().nullable(),
        roomNumber: yup.string().nullable(),
        sublocationId: yup.string().nullable(),
        sublocationName: yup.string().nullable()
      }).nullable(),
      createdDate: yup.date().default(new Date()),
      createdByUserId: yup.string().default(null).nullable(),
      lastUpdatedDate: yup.date(),
      lastUpdatedByUserId: yup.string().default(null).nullable(),
      assignedDate: yup.date().default(null).nullable(),
      active: yup.bool().default(true).required(),
      notes: yup.string().default(''),
      meta: yup.object().nullable()
    });
  }
}
module.exports = InventoryItem;