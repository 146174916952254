import { useEffect, useState } from 'react';

const useContainer = () => {
  const [containerRef, setContainerRef] = useState();

  useEffect(() => {
    if (!containerRef) {
      setContainerRef(document.getElementsByTagName('Main')[0]);
    }
  }, [containerRef]);

  return {
    container: containerRef,
    scrollTop: () => {
      try {
        return containerRef.scroll(0, 0);
      } catch {
        // for ie 11
        return true;
      }
    },
  };
};

export default useContainer;
