import { Chip, Icon } from '@material-ui/core';
import clsx from 'clsx';
import { compose, lowerCase, startCase } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * NOTE:
 * This is for tailwind's purging. We need to have the classes fully typed out in order to be included in the css
 * production bundle (rather than something like `bg-${color}`)
 */
const DOT_CLASSES = {
  default: 'bg-default-dark',
  error: 'bg-error',
  success: 'bg-success',
  warning: 'bg-warning',
  inprogress: 'bg-purple-800',
  orange: 'bg-orange-400',
  purple: 'bg-purple-800',
  teal: 'bg-teal-500',
};

function StatusChip({ color, label, variant }) {
  return (
    <Chip
      className={variant === 'compact' ? 'h-20 rounded px-2 text-12' : 'rounded-sm'}
      icon={
        <Icon className={clsx('rounded-full', variant === 'compact' ? 'h-8 w-8' : 'h-10 w-10', DOT_CLASSES[color])} />
      }
      label={compose(startCase, lowerCase)(label)}
      size="small"
    />
  );
}

StatusChip.propTypes = {
  color: PropTypes.oneOf(Object.keys(DOT_CLASSES)).isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['standard', 'compact']),
};

StatusChip.defaultProps = {
  variant: 'standard',
};

export default StatusChip;
