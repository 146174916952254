export async function getBase64(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const { result } = reader;
      resolve(result.split('base64,')[1]);
    };
    reader.readAsDataURL(file);
  });
}
