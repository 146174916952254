import { useMutation, useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button, Paper, Typography } from '@material-ui/core';
import { SHORT_DATE_FORMAT } from '@rss/common';
import React from 'react';
import { format } from 'date-fns';
import QRCode from 'qrcode.react';

import { useProfile } from '../../hooks';
import Logo, { types } from '../../components/logo';
import { ErrorMessage } from '../../components/errors';
import { GET_PURCHASING_CODE, REFRESH_PURCHASING_CODE } from '../graphql/query';

function PurchasingCode() {
  const { profile } = useProfile();

  const { data, error, loading } = useQuery(GET_PURCHASING_CODE, { fetchPolicy: 'network-only' });
  const [refreshPurchasingCode, { loading: refreshLoading, data: refreshData }] = useMutation(REFRESH_PURCHASING_CODE);

  if (loading || refreshLoading) return <FuseLoading />;
  if (error) return <ErrorMessage message={error} />;

  let {
    purchasingCode: { createdDate, token },
  } = data;
  if (refreshData) {
    createdDate = refreshData.refreshPurchasingCode?.createdDate;
    token = refreshData.refreshPurchasingCode?.token;
  }

  return (
    <div className="m-24 flex">
      <div className="mr-36">
        <Paper className="min-w-360 p-32" elevation={5}>
          <div className="flex justify-center">
            <Logo brand={types.RSS_COLORED} alt="RSS" className="w-216" />
          </div>
          <div className="my-32 flex justify-center">
            <QRCode value={token} size={200} renderAs="svg" level="M" />
          </div>
          <div className="flex justify-between">
            <div>
              <Typography className="mb-4">User</Typography>
              <Typography className="font-semibold">
                {profile.firstName} {profile.lastName}
              </Typography>
            </div>

            <div>
              <Typography className="mb-4">Created On</Typography>
              <Typography className="font-semibold">{format(new Date(createdDate), SHORT_DATE_FORMAT)}</Typography>
            </div>
          </div>
        </Paper>
      </div>

      <div className="flex-1 print:hidden">
        <Typography variant="h6" className="mb-10">
          My Purchasing Code
        </Typography>
        <Typography variant="subtitle1" className="mb-20">
          For Self-Checkout and In-Person Purchases
        </Typography>
        <Typography>
          <span className="font-semibold italic text-error-500">Warning: </span>
          This purchasing code is generated for{' '}
          <span className="font-semibold">{`${profile.firstName} ${profile.lastName}`}&apos;s</span> use ONLY. It should
          not be shared or distributed to anyone. At any time, you may invalidate a current code and generate a new one
          if your code may have been compromised.
        </Typography>

        <div className="mt-48 print:hidden">
          <Button
            className="mr-16"
            size="large"
            variant="contained"
            color="primary"
            onClick={() => refreshPurchasingCode()}>
            Generate new code
          </Button>
          <Button size="large" variant="contained" color="primary" onClick={() => window.print()}>
            Print
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PurchasingCode;
