"use strict";

module.exports = {
  ACKNOWLEDGE: 'ACKNOWLEDGE',
  AMEND: 'AMEND',
  COMMENT: 'COMMENT',
  CONTACT: 'CONTACT',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  MEMBER: 'MEMBER',
  OWNER: 'OWNER',
  READ: 'READ',
  REVIEW: 'REVIEW',
  SUBMIT: 'SUBMIT',
  WRITE: 'WRITE'
};