import { lazy } from 'react';

import { hasChemicalAdminRole, hasChemicalAdminReadOnlyRole, hasPpeCoordinatorRole } from '../shared/helper';

const FacilityDetailPage = lazy(() => import('./containers/FacilityDetailPage'));
const FacilityPage = lazy(() => import('./containers/FacilityPage'));
const LaundryPage = lazy(() => import('./containers/LaundryPage'));
const LaundryDetailPage = lazy(() => import('./containers/LaundryDetailPage'));

const routes = {
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    {
      path: '/location-area/facility/:facilityId',
      auth: ({ profile }) => hasChemicalAdminRole(profile) || hasChemicalAdminReadOnlyRole(profile),
      component: FacilityDetailPage,
    },
    {
      path: '/location-area/facility',
      auth: ({ profile }) => hasChemicalAdminRole(profile) || hasChemicalAdminReadOnlyRole(profile),
      component: FacilityPage,
    },
    {
      path: '/location-area/laundry/:laundryId',
      auth: ({ profile }) => hasPpeCoordinatorRole(profile),
      component: LaundryDetailPage,
    },
    {
      path: '/location-area/laundry',
      component: LaundryPage,
    },
  ],
};

export default routes;
