/* eslint-disable react/jsx-boolean-value */
import { useQuery } from '@apollo/client';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Icon, LinearProgress, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Store } from '@risk-and-safety/library';
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router-dom';
import { useTable } from 'react-table';
import numeral from 'numeral';

import { STORE_PAGE_QUERY } from '../graphql/query';
import { Header } from '../../components';
import config from '../../config';

function LowStockPage({ store }) {
  const history = useHistory();

  const count = useRef(0);

  const { data, fetchMore } = useQuery(STORE_PAGE_QUERY, {
    variables: { storeId: store._id, offset: 0, limit: config.LIMIT },
    fetchPolicy: 'cache-and-network',
  });

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns: useMemo(
      () => [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Product Number', accessor: 'productNumber' },
        { Header: 'Price', accessor: 'price', Cell: ({ value }) => numeral(value).format('$0,0.00') },
        { Header: 'Available Stock', accessor: 'availableStock' },
      ],
      [],
    ),
    data: useMemo(() => data?.lowStockProducts || [], [data]),
  });

  const hasMore = data?.lowStockProducts?.length > 0 && data?.lowStockProducts?.length - count.current > 0;

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        contentCard: 'overflow-hidden',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">shopping_cart</Icon>}
          title="Products low on stock"
          backArrow={{
            link: `../${store._id}`,
            name: 'Store',
          }}
        />
      }
      content={
        <FuseScrollbars className="flex-grow overflow-x-auto">
          <InfiniteScroll
            hasMore={hasMore}
            loadMore={() => {
              count.current = data?.lowStockProducts?.length;
              fetchMore({ variables: { offset: data?.lowStockProducts?.length } });
            }}
            useWindow={false}
            loader={<LinearProgress className="clear-both" key={0} />}>
            <Table stickyHeader className="min-w-xl" {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow className="h-48 sm:h-64" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <TableCell className="p-4 md:p-16" {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className="h-48 cursor-pointer"
                      hover
                      onClick={() =>
                        history.push({
                          pathname: `product/${row.original._id}/detail`,
                          state: { fromLowStockPage: true },
                        })
                      }
                      {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <TableCell className="p-4 md:p-16" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </FuseScrollbars>
      }
    />
  );
}

LowStockPage.propTypes = {
  store: PropTypes.shape(Store).isRequired,
};

export default LowStockPage;
