const {
  ObjectIdSchema,
  RssModel
} = require('@rss/common');
const {
  ObjectId
} = require('bson');
const yup = require('yup');
const FUND_TYPE = require('../constants/fund-type.constant');
const {
  ValueSchema
} = require('./fund-reference.schema');
class TransactionEntry extends RssModel {
  // eslint-disable-next-line class-methods-use-this
  schema() {
    return yup.object({
      _id: new ObjectIdSchema().default(() => new ObjectId()).required(),
      seriesId: new ObjectIdSchema().required(),
      storeId: new ObjectIdSchema().required(),
      transactionType: yup.string().required(),
      storeName: yup.string().required(),
      programName: yup.string().required(),
      orderId: yup.string().ensure(),
      orderItemId: new ObjectIdSchema().default(null).nullable().required(),
      productId: new ObjectIdSchema().default(null).nullable().required(),
      productName: yup.string().default(null).nullable().required(),
      quantity: yup.number().default(1).nullable(),
      productPurposeCode: yup.string().default(null).nullable().required(),
      fundType: yup.string().oneOf(Object.values(FUND_TYPE)).required(),
      fundValue: yup.object().when('fundType', fundType => ValueSchema(fundType)),
      accountLabel: yup.string().default(null).nullable().required(),
      accountId: yup.string().default(null).nullable().required(),
      accountName: yup.string().required(),
      accountOwnerName: yup.string().default(null).nullable().required(),
      amount: yup.number().required(),
      createdDate: yup.date().default(new Date()).required(),
      notes: yup.string().default('')
    });
  }
}
module.exports = TransactionEntry;