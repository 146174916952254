const yup = require('yup');
const {
  FUND_TYPE
} = require('../constants');
module.exports.ValueSchema = type => {
  switch (type) {
    case FUND_TYPE.UCSF_CHARTSTRING:
    case FUND_TYPE.RSS_CHARTSTRING:
      return yup.object({
        businessUnit: yup.string().length(5, 'Business unit must be 5 characters').required('Business unit is required'),
        account: yup.string().test('length', 'Account must be 5 characters', value => value?.length === 5 || value?.length === 0),
        fund: yup.string().length(4, 'Fund must be 4 characters').required('Fund is required'),
        departmentId: yup.string().length(6, 'Department Id must be 6 characters').required('Dept Id is required'),
        project: yup.string().length(7, 'Project must be 7 characters').required('Project is required'),
        activityPeriod: yup.string().test('length', 'Activity period must be 2 characters', value => value?.length === 2 || value?.length === 0),
        function: yup.string().test('length', 'Function must be 2 characters', value => value?.length === 2 || value?.length === 0),
        flexField: yup.string().test('length', 'Flex field must be less than or equal to 7 characters', value => value?.length <= 7)
      });
    case FUND_TYPE.UCSF_SPEED_TYPE:
      return yup.object({
        businessUnit: yup.string().length(5, 'Business unit must be 5 characters').required('Business unit is required'),
        speedType: yup.string().required(),
        fund: yup.string().nullable(),
        departmentId: yup.string().nullable(),
        project: yup.string().nullable(),
        function: yup.string().nullable(),
        account: yup.string().nullable(),
        activityPeriod: yup.string().nullable()
      });
    case FUND_TYPE.RSS_SPEED_TYPE:
      return yup.object({
        businessUnit: yup.string().length(5, 'Business unit must be 5 characters'),
        speedType: yup.string().required()
      });
    case FUND_TYPE.UCI_KFS_ACCOUNT:
      return yup.object({
        kfsChartCode: yup.string().length(2, 'Chart code must be 2 characters').required('Chart code is required'),
        kfsAcctNumber: yup.string().length(7, 'Account number must be 7 characters').required('Account number is required'),
        effectiveDate: yup.date().required('Effective date is required')
      });
    case FUND_TYPE.UCI_PS_BUDGET_CODE:
      return yup.object({
        budgetCode: yup.string().required('Budget code is required'),
        accountCode: yup.string().length(6, 'Account code must be 6 characters').required('Account code is required'),
        fundCode: yup.string().length(5, 'Fund code must be 5 characters').required('Fund code is required')
      });
    default:
      return yup.object().nullable();
  }
};
module.exports.Fund = yup.object().default(null).nullable().shape({
  validator: yup.string().required(),
  type: yup.object().when('validator', validator => yup.string().oneOf(Object.keys(FUND_TYPE).filter(t => t.includes(validator?.toUpperCase()))).required()),
  value: yup.object().when('type', type => module.exports.ValueSchema(type))
});
module.exports.SubsidyFund = yup.object().default(null).nullable().shape({
  validator: yup.string().nullable(),
  type: yup.object().when('validator', validator => yup.string().oneOf([null, ...Object.keys(FUND_TYPE).filter(t => t.includes(validator?.toUpperCase()))]).nullable()),
  value: yup.object().when('type', type => module.exports.ValueSchema(type))
});