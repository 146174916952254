import { every } from 'lodash';

export function getCartCount({ items = [] }) {
  return items.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);
}

export function canCheckout(cart, validatedCart = {}) {
  return (
    Boolean(cart.items.length) && Boolean(validatedCart.items?.length) && every(validatedCart.items, 'availableForSale')
  );
}

export function canPlaceOrder(cart, validatedCart = {}, isStoreCheckout, shopper) {
  if (!cart.deliveryLocation?.id) {
    return false;
  }

  const validation =
    Boolean(cart.items?.length) &&
    Boolean(validatedCart.account?._id) &&
    Boolean(validatedCart.fund?.id) &&
    Boolean(cart.receivingType) &&
    validatedCart.isFundValid;

  if (isStoreCheckout) {
    return validation && Boolean(shopper?.userId);
  }

  return validation;
}
