import { combineReducers } from '@reduxjs/toolkit';

import shoppingCart from './shoppingCartSlice';
import rssTools from './rssToolsSlice';

const appReducers = combineReducers({
  shoppingCart,
  rssTools,
});

export default appReducers;
