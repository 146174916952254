import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { get } from 'lodash';
import { authMiddleware, ReadWriteClient } from '../../services/apollo';

import config from '../../config';

const incidentMgmtV2GraphClient = new ApolloClient({
  link: from([authMiddleware, createHttpLink({ uri: `${config.INCIDENT_MGMT_V2_URL}/graphql` })]),
  cache: new InMemoryCache(),
});

export const getGraphClient = (profile, feature) => {
  return (!feature || feature === 'INCIDENT_MGMT') && get(profile, 'features.incidentMgmt.useV2Server.value')
    ? incidentMgmtV2GraphClient
    : ReadWriteClient;
};
