import { makeStyles, Icon, Stepper, Step, StepButton, StepLabel } from '@material-ui/core';
import clsx from 'clsx';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: `${theme.palette.secondary.main}!important`,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    pointerEvents: 'none',
  },
  button: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const VerticalLinearStepper = ({ steps, selectedStep, onStepSelect }) => {
  const classes = useStyles();

  const getIcon = (step, index) => {
    if (step?.completed) {
      return 'done';
    }
    if (step?.error) {
      return <Icon color="error">warning</Icon>;
    }
    return index + 1;
  };

  const activeStep = steps.findIndex((s) => s.label === selectedStep || s.link === selectedStep);

  return (
    <div className="w-full flex-auto">
      <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
        {steps.map((step, index) => {
          return (
            <Step key={step.label} completed={step.completed}>
              <StepButton
                disabled={step?.disabled}
                icon={getIcon(step, index)}
                className={clsx(
                  'rounded-l-full',
                  classes.button,
                  (selectedStep === step.link || selectedStep === step?.label) && classes.active,
                )}
                onClick={() => onStepSelect(step)}>
                <StepLabel className="text-left">
                  <span
                    className={clsx(
                      'flex items-center',
                      step.error && 'font-500 text-black',
                      !step.completed && 'text-black',
                    )}>
                    <span className="flex-grow">{step.label}</span>
                    {step.hasComments && <Icon className="ml-10 text-16 font-normal">message</Icon>}
                  </span>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

VerticalLinearStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      disabled: PropTypes.bool,
      completed: PropTypes.bool,
      hasComments: PropTypes.bool,
      error: PropTypes.bool,
    }),
  ),
  selectedStep: PropTypes.string,
  onStepSelect: PropTypes.func,
};

VerticalLinearStepper.defaultProps = {
  steps: null,
  selectedStep: null,
  onStepSelect: noop,
};

export default VerticalLinearStepper;
