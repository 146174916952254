/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core//FusePageCarded';
import { Icon, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Header } from '../../components';
import { ErrorMessage } from '../../components/errors';
import { STORE_ACCOUNT } from '../graphql/query';

function AccountDetailPage({ route, store }) {
  const { accountId, panel } = useParams();
  const { state } = useLocation();

  const { loading, data, error } = useQuery(STORE_ACCOUNT, {
    variables: { id: accountId, storeId: store?._id },
    skip: accountId === 'create',
  });

  const { url: accountLink } = useRouteMatch(['/shop/:storeId/account', '/store/:storeId/account']);

  if (loading) {
    return <FuseLoading />;
  }

  if (error || (!state && accountId === 'create')) {
    return <ErrorMessage />;
  }

  return (
    <FusePageCarded
      innerScroll
      classes={{
        content: 'flex',
        header: 'min-h-72 h-72 sm:h-136 sm:min-h-136',
        contentCard: 'overflow-hidden',
      }}
      header={
        <Header
          icon={<Icon className="text-32 sm:w-48 sm:text-5xl">settings</Icon>}
          title={data?.storeAccount?.administeredBy?.name || state.administeredBy?.name}
          backArrow={{
            link: state?.fromCart ? `/shop/${store._id}/cart` : accountLink,
            name: state?.fromCart ? 'My Cart' : 'Accounts',
          }}
        />
      }
      contentToolbar={
        <div>
          {!panel && accountId !== 'create' && (
            <Typography variant="subtitle1" color="textSecondary" className="mx-32">
              Account Detail
            </Typography>
          )}
        </div>
      }
      content={<>{renderRoutes(route.routes, { store, account: data?.storeAccount || {} })}</>}
    />
  );
}

AccountDetailPage.propTypes = {
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AccountDetailPage;
