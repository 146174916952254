import { orange, green, red } from '@material-ui/core/colors';
import shadows from '@material-ui/core/styles/shadows';

import { blue, grey, purple, background } from './colors';

const lightText = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

const themesConfig = {
  default: {
    typography: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      htmlFontSize: 10,
      fontSize: 14,
      body1: {
        fontSize: '1.4rem',
      },
      body2: {
        fontSize: '1.4rem',
      },
    },
    colors: {
      blue,
      green: { ...green, background: '#e6ffed' },
      grey,
      orange,
      purple,
      red: { ...red, background: '#ffeef0', main: red[800], default: red[800] },
    },
    palette: {
      type: 'light',
      text: lightText,
      common: {
        black: 'rgb(17, 24, 39)',
        white: 'rgb(255, 255, 255)',
      },
      primary: blue,
      secondary: {
        light: blue[50],
        main: blue[200],
        dark: blue.A700,
      },
      background: {
        paper: '#FFFFFF',
        default: background,
      },
      danger: { ...red, default: red[800], main: red[800] },
      warning: orange,
      subtleWarning: '#fcf38e',
      success: green,
      error: { ...red, default: red[800], main: red[800] },
      black: '#000',
      white: '#FFF',
    },
    status: {
      danger: 'orange',
    },
    overrides: {
      MuiAccordion: {
        root: {
          borderBottom: `1px solid ${grey[50]}`,
          '&$expanded': {
            borderBottom: `1px solid ${grey[100]}`,
            margin: 0,
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          minHeight: 'initial',
          '&$expanded': {
            minHeight: 'initial',
          },
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: 6,
        },
        sizeSmall: {
          borderRadius: 4,
        },
        sizeLarge: {
          borderRadius: 8,
        },
        contained: {
          backgroundColor: grey[25],
          boxShadow: shadows[2],
          '&:hover, &:focus': {
            boxShadow: shadows[2],
          },
        },
        containedSecondary: {
          backgroundColor: red[800],
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: red[700],
          },
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: blue.A700,
          },
        },
      },
      MuiFab: {
        secondary: {
          backgroundColor: blue[100],
        },
        root: {
          right: 20, // it should be to fixIE11 issue
          textTransform: 'none',
        },
      },
      MuiFormLabel: {
        root: {
          color: 'inherit',
          '&$disabled': {
            color: grey[300], // Min ADA contrast for normal font
          },
        },
        asterisk: {
          color: red[800],
          '&$error': {
            color: red[800],
          },
        },
      },
      MuiIcon: {
        colorSecondary: {
          color: 'rgba(0, 0, 0, 0.54)',
        },
        colorError: {
          color: red[800],
        },
      },
      MuiInputBase: {
        root: {
          '&$disabled': {
            color: grey[200], // Min ADA contrast for large font icons
          },
        },
      },
      MuiListSubheader: {
        sticky: {
          backgroundColor: background,
        },
      },
      MuiMenu: {
        paper: {
          color: 'inherit',
          backgroundColor: '#FFFFFF',
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
      MuiOutlinedInput: {
        colorSecondary: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
      MuiSelect: {
        icon: {
          color: lightText.primary,
        },
      },
      MuiSpeedDial: {
        fab: {
          right: '0px !important',
        },
      },
      MuiSpeedDialAction: {
        staticTooltipLabel: {
          width: 'max-content !important',
          color: 'black !important',
        },
        fab: {
          color: 'black !important',
          right: '0px !important',
        },
      },
      MuiStepConnector: {
        vertical: {
          paddingTop: 2,
          paddingBottom: 2,
        },
      },
      MuiStepper: {
        root: {
          background: 'none',
          border: 'none',
        },
      },
      MuiTab: {
        textColorPrimary: {
          '&$selected': {
            color: blue[700], // Min ADA contrast for normal font on gray background
          },
        },
      },
      MuiTableCell: {
        root: {
          padding: 12,
        },
      },
      MuiToggleButton: {
        root: {
          color: 'inherit',
          textTransform: 'none',
          '&$selected': {
            color: 'inherit',
          },
        },
      },
    },
  },
  defaultDark: {
    palette: {
      type: 'dark',
      primary: blue,
      secondary: {
        light: blue[50],
        main: blue[200],
        dark: blue.A700,
      },
      background: {
        paper: grey[800],
        default: grey[900],
      },
      error: red,
    },
    status: {
      danger: 'orange',
    },
  },
};

export default themesConfig;
