import get from 'lodash/get';
import React, { lazy } from 'react';

import { TEMPLATE_TYPE } from './constants/TEMPLATE_TYPE';

const CardOptionPage = lazy(() => import('./containers/CardOptionPage'));
const DocumentListPage = lazy(() => import('./containers/DocumentListPage'));
const DocumentPage = lazy(() => import('./containers/DocumentPage'));
const LandingPage = lazy(() => import('./containers/LandingPage'));
const ManageCardOptionsPage = lazy(() => import('./containers/ManageCardOptionsPage'));
const ManageTemplatesPage = lazy(() => import('./containers/ManageTemplatesPage'));
const RenderAdminComponent = lazy(() => import('./components/RenderAdminComponent'));
const TemplateEditPage = lazy(() => import('./containers/TemplateEditPage'));
const VersionPage = lazy(() => import('./containers/VersionPage'));

const templateRoutes = Object.values(TEMPLATE_TYPE).filter((t) => t.templatePath);
const cardOptionRoutes = Object.values(TEMPLATE_TYPE).filter((t) => t.cardPath);

const cardOptionEditRoute = cardOptionRoutes.map(({ type, cardPath }) => ({
  path: `${cardPath}/:optionId`,
  exact: true,
  render: () => <RenderAdminComponent templateType={type} component={<CardOptionPage templateType={type} />} />,
}));

const cardOptionManageRoutes = cardOptionRoutes.map(({ type, cardPath }) => ({
  path: cardPath,
  exact: true,
  render: () => <RenderAdminComponent templateType={type} component={<ManageCardOptionsPage templateType={type} />} />,
}));

const templateManageRoutes = templateRoutes.map(({ type, templatePath }) => ({
  path: templatePath,
  exact: true,
  render: () => <RenderAdminComponent templateType={type} component={<ManageTemplatesPage templateType={type} />} />,
}));

const templateEditRoutes = templateRoutes.map(({ type, templatePath }) => ({
  path: `${templatePath}/:id`,
  exact: true,
  render: () => <RenderAdminComponent templateType={type} component={<TemplateEditPage templateType={type} />} />,
}));

const versionPageRoutes = Object.values(TEMPLATE_TYPE).map(({ type, url }) => ({
  path: [`${url}/:id/version/:seriesId`, `${url}/inventory/:inventoryId/:id/version/:seriesId`],
  exact: true,
  render: () => <VersionPage templateType={type} />,
}));

const shareDocumentRoutes = Object.values(TEMPLATE_TYPE).map(({ type, url }) => ({
  path: [`${url}/key/:shareKey/clone`, `${url}/inventory/:inventoryId/key/:shareKey/clone`],
  exact: true,
  render: () => <DocumentPage templateType={type} />,
}));

const routes = {
  settings: {
    helpCategories: ['procedures'],
  },
  auth: ({ profile } = {}) => Boolean(profile),
  routes: [
    ...cardOptionEditRoute,
    ...cardOptionManageRoutes,
    ...templateEditRoutes,
    ...templateManageRoutes,
    ...versionPageRoutes,
    ...shareDocumentRoutes,
    {
      path: [
        '/loto/:id',
        '/loto/:shareKey',
        '/loto/inventory/:inventoryId/:id',
        '/medical/plan/sph/:id',
        '/medical/plan/sph/:shareKey',
        '/medical/plan/sph/inventory/:inventoryId/:id',
        '/medical/plan/wpvp/:id',
        '/medical/plan/wpvp/:shareKey',
        '/medical/plan/wpvp/inventory/:inventoryId/:id',
        '/medical/safety/:id',
        '/medical/safety/:shareKey',
        '/medical/safety/inventory/:inventoryId/:id',
        '/sop/:id',
        '/sop/:shareKey',
        '/sop/inventory/:inventoryId/:id',
        '/safety/plan/:id',
        '/safety/plan/:shareKey',
        '/safety/plan/inventory/:inventoryId/:id',
      ],
      exact: true,
      render: () => <DocumentPage />,
    },
    {
      path: ['/loto', '/loto/inventory/:inventoryId'],
      exact: true,
      render: () => <DocumentListPage templateType={TEMPLATE_TYPE.LOTO.type} />,
    },
    {
      path: ['/medical/safety', '/medical/safety/inventory/:inventoryId'],
      exact: true,
      render: () => <DocumentListPage templateType={TEMPLATE_TYPE.MEDICAL.type} />,
    },
    {
      path: ['/medical/plan/sph', '/medical/plan/sph/inventory/:inventoryId'],
      exact: true,
      render: () => <DocumentListPage templateType={TEMPLATE_TYPE.SPH.type} />,
    },
    {
      path: ['/medical/plan/wpvp', '/medical/plan/wpvp/inventory/:inventoryId'],
      exact: true,
      render: () => <DocumentListPage templateType={TEMPLATE_TYPE.WPVP.type} />,
    },
    {
      path: '/procedures',
      exact: true,
      render: () => <LandingPage />,
    },
    {
      path: ['/safety/plan', '/safety/plan/inventory/:inventoryId'],
      exact: true,
      render: () => <DocumentListPage templateType={TEMPLATE_TYPE.FIELD.type} />,
    },
    {
      path: ['/sop', '/sop/inventory/:inventoryId'],
      exact: true,
      auth: ({ profile } = {}) => get(profile, 'features.chemical.SOP.value', false),
      render: () => <DocumentListPage templateType={TEMPLATE_TYPE.SOP.type} />,
    },
  ],
};

export const procedurePublicRoutes = [{ path: '/read-only/document/:shareKey', render: () => <DocumentPage /> }];

export default routes;
