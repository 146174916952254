import { createSlice } from '@reduxjs/toolkit';

const RSS_TOOLS = 'rss:tools';

const rssToolsSlice = createSlice({
  name: 'rssTools',
  initialState: {
    showToolbarSelect: true,
    rssSelectVariant: 'campus', // Pick any of tenant, campus, or domain
    showOnlySelect: false,
    disableCampusSwitch: false,
    tenantCode: JSON.parse(localStorage.getItem(RSS_TOOLS))?.tenantCode || '',
    campusCode: JSON.parse(localStorage.getItem(RSS_TOOLS))?.campusCode || '',
    domainId: JSON.parse(localStorage.getItem(RSS_TOOLS))?.domainId || '',
  },
  reducers: {
    setRssTools: (state, action) => {
      const result = {
        ...state,
        ...action.payload,
      };
      return result;
    },
  },
});

export const { setRssTools } = rssToolsSlice.actions;

export default rssToolsSlice.reducer;
