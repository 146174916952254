import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { take } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { QUESTION_VARIANT } from '../flexible-questionnaire/Helper';
import { SEARCH_BUILDINGS_NEPTUNE } from '../../graphql/relationship.query';
import { useProfile } from '../../hooks';
import Search from '../Search';

const { RELATIONSHIP_LABEL } = require('@rss/common');

const {
  NODE: { BUILDING },
} = RELATIONSHIP_LABEL;

const BuildingSearch = ({
  autoFocus,
  floatingText,
  filter,
  hintText,
  helperText,
  id,
  isDisabled,
  label,
  labelVariant,
  onClear,
  onSelect,
  suggestions,
  showSuggestions,
  suggestionsLimit,
  value,
  variant,
}) => {
  const {
    profile: { campusCode },
  } = useProfile();
  const { pathname } = useLocation();
  const { rssTools } = useSelector(({ app }) => app);
  const isRssTools = pathname?.startsWith('/rss-tools/');

  const [searchBuildings, { called, data, loading }] = useLazyQuery(SEARCH_BUILDINGS_NEPTUNE);

  const { buildings, searchBuildingsNeptune } = data || {};

  let suggestionsList = showSuggestions && suggestions && suggestions.length ? suggestions : [];
  suggestionsList = suggestionsLimit ? take(suggestionsList, suggestionsLimit) : suggestionsList;

  const results = called ? buildings || searchBuildingsNeptune || [] : [];

  const filteredResults = (results.length ? results : suggestionsList)
    .filter((b) => filter.every((buildingId) => buildingId !== b.buildingId))
    .map((b) => ({ key: b.buildingId, ...b }));

  return (
    <Search
      id={id}
      label={floatingText || label || ''}
      labelVariant={labelVariant}
      autoFocus={autoFocus}
      autocomplete
      isLoading={loading}
      placeholder={hintText}
      helperText={helperText}
      items={filteredResults}
      itemToString={(building) => (building ? building.primaryName || building.name : '')}
      minSearchLength={1}
      onChange={({ inputValue }) =>
        searchBuildings({
          variables: {
            campusCode: isRssTools ? rssTools.campusCode : campusCode,
            searchTerm: inputValue,
            maxResults: suggestionsLimit,
          },
        })
      }
      onSelect={({ buildingId, primaryName, address, city, state, postalCode, county }) =>
        onSelect({
          id: buildingId,
          label: BUILDING,
          buildingId,
          name: primaryName,
          address,
          city,
          state,
          postalCode,
          county,
        })
      }
      value={value || ''}
      disabled={isDisabled}
      onClear={onClear}
      resultProps={{ title: showSuggestions && filteredResults.length && !results.length ? 'Suggestions' : null }}
      renderItem={(building) => <Typography variant="body1">{building.primaryName}</Typography>}
      openMenuOnFocus={false}
    />
  );
};

BuildingSearch.propTypes = {
  autoFocus: PropTypes.bool,
  filter: PropTypes.arrayOf(PropTypes.string),
  floatingText: PropTypes.string,
  helperText: PropTypes.string,
  hintText: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf(['default', 'long']),
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  showSuggestions: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  suggestionsLimit: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
};

BuildingSearch.defaultProps = {
  autoFocus: false,
  isDisabled: false,
  filter: [],
  floatingText: null,
  helperText: '',
  hintText: '',
  id: 'building-search',
  label: null,
  labelVariant: 'default',
  onClear: () => {},
  onSelect: () => {},
  showSuggestions: false,
  suggestions: null,
  suggestionsLimit: 20,
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
};

export default BuildingSearch;
