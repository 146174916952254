import { Icon, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const IconLock = ({ className, lockHelperText }) => {
  return (
    <Tooltip title={lockHelperText}>
      <Icon className={className} color="disabled" fontSize="small">
        lock
      </Icon>
    </Tooltip>
  );
};

IconLock.propTypes = {
  className: PropTypes.string,
  lockHelperText: PropTypes.string,
};

IconLock.defaultProps = {
  className: '',
  lockHelperText: '',
};

export default IconLock;
