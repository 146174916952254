import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { SEARCH_REGULATION } from '../../graphql/fq.query';
import { QUESTION_VARIANT } from '../flexible-questionnaire/Helper';
import Search from '../Search';

const RegulationSearch = ({
  clearOnSelect,
  filter,
  disabled,
  autoFocus,
  floatingText,
  hintText,
  helperText,
  id,
  label,
  onClear,
  onSelect,
  question,
  value,
  variant,
  labelVariant,
  selectedFacility,
}) => {
  const [searchRegulation, { called, data, loading }] = useLazyQuery(SEARCH_REGULATION);
  const { searchRegulations: regulation } = data || {};
  const results = (called && regulation) || [];

  const filteredResults = selectedFacility
    ? results.filter(
        (r) =>
          (r?.facilityTypes?.includes(selectedFacility) ||
            r?.facilityTypes?.includes('All') ||
            r?.facilityTypes?.includes('ALL')) &&
          !filter.includes(r?.id),
      )
    : results.filter((v) => !filter.includes(v.id));

  const itemToString = (r) => {
    let item;
    if (r?.title && r?.cfr) {
      item = `${r?.title}, ${r?.cfr}`;
    } else if (!r?.title && r?.cfr) {
      item = r?.cfr;
    } else if (r?.title && !r?.cfr) {
      item = r?.title;
    }
    return item;
  };

  const handleSelected = (val) => {
    if (val) {
      onSelect({ ...val, selectedFacility, value: itemToString(val) }, question.key);
    }
  };

  return (
    <div className={`w-full ${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
      <Search
        disabled={disabled}
        clearOnSelect={clearOnSelect}
        id={id}
        label={labelVariant !== 'long' ? floatingText || label || '' : ''}
        autoFocus={autoFocus}
        autocomplete
        isLoading={loading}
        placeholder={hintText}
        helperText={helperText}
        items={filteredResults}
        minSearchLength={1}
        onChange={({ inputValue }) => {
          const variables = { searchTerm: inputValue };
          if (selectedFacility) {
            variables.type = selectedFacility;
          }
          searchRegulation({ variables });
        }}
        onSelect={handleSelected}
        value={(value && Object.keys(value).length) || ''}
        onClear={onClear}
        itemToString={itemToString}
        renderItem={(r) => <Typography variant="body1">{itemToString(r)}</Typography>}
      />
    </div>
  );
};

RegulationSearch.propTypes = {
  clearOnSelect: PropTypes.bool,
  filter: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  floatingText: PropTypes.string,
  helperText: PropTypes.string,
  hintText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onClear: PropTypes.func,
  onSelect: PropTypes.func,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  labelVariant: PropTypes.oneOf(['default', 'long']),
  selectedFacility: PropTypes.string,
};

RegulationSearch.defaultProps = {
  clearOnSelect: false,
  filter: [],
  disabled: false,
  autoFocus: false,
  floatingText: 'Search Regulation',
  helperText: '',
  hintText: 'Search Regulations by Title or Regulation Number',
  id: 'regulation-search',
  label: null,
  onClear: () => {},
  onSelect: () => {},
  question: null,
  value: null,
  variant: QUESTION_VARIANT.STANDARD,
  labelVariant: 'default',
  selectedFacility: null,
};

export default RegulationSearch;
